import { MenuItem, Select, Switch } from '@material-ui/core'
import React from 'react'

import { useSubscriptions } from '../../../hooks/dataHooks/useSubscriptions'
import { Column } from '../../../uiLayout/Column'
import { Row } from '../../../uiLayout/Row'

import type { SurveyFilterProps } from './types'
import type { NotificationSubscriptionFilterType } from '@tivio/types'


export const SubscriptionFilter = ({ value, onChange }: SurveyFilterProps<NotificationSubscriptionFilterType>) => {
    const { subscriptions } = useSubscriptions()

    return (
        <Row
            spacing={1}
            rowProps={{ alignItems: 'center' }}
        >
            <Select
                value={value?.subscriptionId}
                onChange={(e) => {
                    onChange({
                        subscriptionId: e.target.value as string,
                        isActive: true,
                    })
                }}
            >
                {subscriptions.map((subscription) => (
                    <MenuItem
                        key={subscription.getId}
                        value={subscription.getId}
                    >
                        {subscription.getName}
                    </MenuItem>
                ))}
            </Select>
            <Column>
                <div>{value.isActive ? 'Active' : 'Inactive'}</div>
                <Switch
                    color="primary"
                    checked={value.isActive}
                    onChange={(e) => {
                        onChange({
                            subscriptionId: value?.subscriptionId ?? '',
                            isActive: e.target.checked,
                        })
                    }}
                />
            </Column>
        </Row>
    )
}
