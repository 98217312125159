import { Grid, Paper } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { Ref, useCallback, useRef } from 'react'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import Video from '../../store/Video'
import { percentage, percentToValue } from '../../utils/math.utils'
import { onlyChaptersFilter } from '../../utils/vod.utils'
import PlayerSeekbar, { HandleContainerMouseEvent, PlayerSeekbarProps, SeekbarController } from '../player/PlayerSeekbar'
import { useSeekbarStyles } from '../player/styles.seekbar'

import VideoChapter from './VideoChapter'


interface Props extends PlayerSeekbarProps {
    video: Video
    onSeek: (playingPosition: number) => void
    onSeekbarClick: (marker: MarkerData) => void
    mouseContainerRef?: Ref<SeekbarController>
    disabled?: boolean
}

const VideoSeekbarWrapper = observer((props: Props) => {
    const classesSeekbar = useSeekbarStyles()
    const containerRef = useRef<HTMLDivElement>()

    const chapters = () => {
        const chapters = props.video.getMarkers
            .filter(onlyChaptersFilter)
            .sort((a, b) => a.getFrom - b.getFrom)

        if (chapters.length === 0) {
            return (
                <Grid
                    item
                    xs
                >
                    <Paper className={classesSeekbar.seekbarTile} />
                </Grid>
            )
        }

        return chapters.map(
            marker => {
                const chapterDuration = marker.getTo - marker.getFrom
                const width = percentage(chapterDuration, props.contentDuration)

                return (
                    <VideoChapter
                        key={marker.getId}
                        width={width}
                        marker={marker}
                        onClick={props.onSeekbarClick}
                    />
                )
            },
        )
    }

    const handleSeek: HandleContainerMouseEvent = useCallback(
        (position) => {
            if (!props.isMarkerFormOpen) {
                props.onSeek && props.onSeek(percentToValue(props.contentDuration, position))
            }
        },
        [],
    )

    return (
        <PlayerSeekbar
            containerClassName={classesSeekbar.seekbarContainer}
            contentDuration={props.contentDuration}
            isMarkerFormOpen={props.isMarkerFormOpen}
            markers={props.video.getMarkers}
            onMouseUp={props.onSeekbarClick}
            onMove={handleSeek}
            playingPosition={props.playingPosition}
            ref={props.mouseContainerRef}
            disabled={props.disabled}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                innerRef={containerRef}
                spacing={1}
                wrap="nowrap"
            >
                {chapters()}
            </Grid>
        </PlayerSeekbar>
    )
})

export default VideoSeekbarWrapper
