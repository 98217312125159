import { observer } from 'mobx-react'
import React from 'react'

import { ZoomSlider } from '../common/ZoomSlider'


export interface EpgZoomProps {
    /**
     * Zoom value
     */
    value: number
    /**
     * @param zoomValue - value from 1 to 5; 5 means the most zoom out
     */
    onZoomValueChange: (zoomValue: number) => void
}

export const DEFAULT_ZOOM_VALUE = 3
const MIN_ZOOM_VALUE_FOR_SLIDER = 1
const MAX_ZOOM_VALUE_FOR_SLIDER = 5
const ZOOM_STEP = 1

const PlayerZoom = observer(({ value, onZoomValueChange }: EpgZoomProps) => {

    const handleChange = (value: number) => {
        // Slider component returns values from 1 to 5 but we need 5 to 1
        const newZoomValue = Math.abs(value - (MAX_ZOOM_VALUE_FOR_SLIDER + 1))
        onZoomValueChange(newZoomValue)
    }

    return (
        <ZoomSlider
            onChange={handleChange}
            value={value}
            step={ZOOM_STEP}
            min={MIN_ZOOM_VALUE_FOR_SLIDER}
            max={MAX_ZOOM_VALUE_FOR_SLIDER}
        />
    )
})

export default PlayerZoom
