import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { Skeleton } from '@material-ui/lab'
import { PurchaseStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import User from '../../store/User'
import { Header } from '../common'
import { UserPurchaseIcon } from '../common/UserPurchaseIcon'
import { useDialog } from '../hooks/uiHooks/useDialog'

import { CreatePurchaseDialog } from './createPurchaseDialog/CreatePurchaseDialog'
import { UserPurchaseItem } from './UserPurchaseItem'
import { useStyles } from './userStyles'


type UserPurchasesProps = {
    user: User
}

export type PurchaseStatusDescription = {
    status: PurchaseStatus,
    label: string,
    description: string
}

export const UserPurchases: React.FC<UserPurchasesProps> = observer(({ user }) => {
    const classes = useStyles()
    const [t] = useTranslation()
    const { isDialogOpen, closeDialog, openDialog } = useDialog()

    const purchaseStatusDescriptions: PurchaseStatusDescription[] = useMemo(() => ([
        {
            status: PurchaseStatus.NEW,
            label: t('New'),
            description: t('Purchase is initialized but not paid. It is considered inactive and does not give any access to additional content to the user.'),
        },
        {
            status: PurchaseStatus.ABORTED,
            label: t('Aborted'),
            // eslint-disable-next-line max-len
            description: t('When the user cancels the purchase (e.g. leaves the Qerko app or returns back from Qerko payment page), the purchase status will be set to ABORTED.'),
        },
        {
            status: PurchaseStatus.PAID,
            label: t('Paid'),
            description: t('Purchase is active and gives access to additional content to the user.'),
        },
        {
            status: PurchaseStatus.RENEWED,
            label: t('Renewed'),
            description: t('Used in subscription purchases when subscription is renewed.'),
        },
        {
            status: PurchaseStatus.CANCELLING,
            label: t('Cancelling'),
            description: t('When user manually cancels purchase but still has access to content until expiration date.'),
        },
        {
            status: PurchaseStatus.CANCELLED,
            label: t('Cancelled'),
            description: t('Purchase is inactive because it was either cancelled by user or administrator.'),
        },
        {
            status: PurchaseStatus.EXPIRED,
            label: t('Expired'),
            description: t('If there was a problem with the payment (e.g. card expiration or no money left on the bank account).'),
        },
        {
            status: PurchaseStatus.BLOCKED,
            label: t('Blocked'),
            description: t('Purchase is blocked by administrator due to terms and conditions violation from the user (e.g. restreaming, piracy).'),
        },
        {
            status: PurchaseStatus.ERROR,
            label: t('Error'),
            description: t('Purchase is in error state.'),
        },
    ]), [t])

    const onClose = useCallback(() => {
        closeDialog()
    }, [closeDialog])

    const renderCreatePurchaseDialog = useCallback(() => {
        if (!isDialogOpen) {
            return null
        }

        return (
            <CreatePurchaseDialog
                purchaseStatusDescriptions={purchaseStatusDescriptions}
                user={user}
                onClose={onClose}
            />
        )
    }, [isDialogOpen, onClose, purchaseStatusDescriptions, user])

    return (
        <>
            <Header title={`${t('Purchase status explanation')}`} />
            <Box mb={4}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                >
                    {purchaseStatusDescriptions.map(purchaseStatusDescription => (
                        <Grid
                            key={purchaseStatusDescription.status}
                            item
                        >
                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid item>
                                    <UserPurchaseIcon purchaseStatus={purchaseStatusDescription.status} />
                                </Grid>
                                <Grid item>
                                    {purchaseStatusDescription.label}
                                    <Typography
                                        color="textSecondary"
                                        variant="body2"
                                    >
                                        {purchaseStatusDescription.description}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Header
                title={`${t('Purchases for user')} (${user.email})`}
                actions={{ create: {
                    onCreate: openDialog,
                    title: t('Add purchase'),
                },
                }}
            />
            {user.hasPurchases === null && (
                <>
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                    <Skeleton
                        animation="wave"
                        className={classes.skeletonRow}
                    />
                </>
            )}
            {user.hasPurchases === true ? (
                <Box>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.head}
                    >
                        <Grid
                            item
                            xs={3}
                        >{t('Monetization name (video ID)')}</Grid>
                        <Grid
                            item
                            xs={2}
                        >{t('Type')}</Grid>
                        <Grid
                            item
                            xs={2}
                        >{t('Created / Expires')}</Grid>
                        <Grid
                            item
                            xs={2}
                            className={classes.secondary}
                        >
                            {t('Purchase ID')}
                        </Grid>
                        <Grid
                            item
                            xs={2}
                        >
                            {t('Status')}
                        </Grid>
                        <Grid
                            item
                            xs={1}
                        />
                    </Grid>
                    {user.purchases.map(purchase => (
                        <UserPurchaseItem
                            purchase={purchase}
                            key={purchase.id}
                        />
                    ))}
                </Box>
            ) : (<Box>{t('User has no purchases yet.')}</Box>)
            }
            {renderCreatePurchaseDialog()}
        </>
    )
})
