import { TvChannelDocument } from '@tivio/firebase'
import { useCallback } from 'react'

import { addTvChannel as add, removeTvChannel, updateTvChannel } from '../../../firebase/firestore/tvChannels'
import TvChannel from '../../../store/TvChannel'
import { storeNotReady } from '../index'

import { useOrganization } from './useOrganization'


export const useEpgChannels = () => {
    const { organization } = useOrganization()

    if (!organization) {
        return {
            createTvChannel: storeNotReady,
            channels: [] as TvChannel[],
            removeTvChannel: storeNotReady,
            updateTvChannel: storeNotReady,
        }
    }

    const createTvChannel = useCallback((data: TvChannelDocument) => {
        return add(data)
    }, [organization])

    return {
        channels: organization.tvChannels,
        createTvChannel,
        removeTvChannel,
        updateTvChannel,
    }
}
