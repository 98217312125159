import { createLogger } from '@tivio/common'
import { NotificationDocument } from '@tivio/firebase'
import { NotificationStatus } from '@tivio/types'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../store/Notification'
import history from '../../../utils/history.utils'
import { useAlert } from '../uiHooks/useAlert'

import { useOrganization } from './useOrganization'


const logger = createLogger('useNotifications')

export const useNotifications = () => {
    const { organization } = useOrganization()
    const { showSuccess, showError } = useAlert()
    const [t] = useTranslation()

    useEffect(() => {
        organization?.loadNotifications()
    }, [])

    const addNotification = async (notificationData: NotificationDocument) => {
        try {
            const newNotification = await organization?.addNotification(notificationData)
            showSuccess(t('Notification created'))
            history.push(`/services/${newNotification?.id}`)
        } catch (e) {
            logger.error(e)
            showError(t('Failed to create notification'))
        }
    }

    const removeNotification = async (notification: TivioNotification) => {
        if (notification.lastSent || notification.sentCount > 0 || notification.status === NotificationStatus.SENT) {
            throw new Error(t('Notification cannot be removed because it was already sent'))
        }
        try {
            await organization?.removeNotification(notification)
            showSuccess(t('Notification removed'))
        } catch (e) {
            logger.error(e)
            throw new Error(t('Failed to remove notification'))
        }
    }

    return {
        notifications: organization?.notifications ?? [],
        loading: organization?.notificationsState.loading,
        addNotification,
        removeNotification,
    }
}
