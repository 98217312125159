import { tivio } from '@tivio/core-js'
import { AllStargazeCreatorsWithVoucherSubscriptionsResponse } from '@tivio/types'
import { useEffect, useState } from 'react'


export const useAvailableCreatorsWithVouchers = () => {
    const [isFetching, setIsFetching] = useState(false)
    const [creators, setCreators] = useState<AllStargazeCreatorsWithVoucherSubscriptionsResponse['creators']>([])

    useEffect(() => {
        const fetchCreators = async () => {
            try {
                setIsFetching(true)
                const { creators } = await tivio.getAllStargazeCreatorsWithVoucherSubscriptions()
                setCreators(creators)
            } catch (error) {
                console.error(error)
            } finally {
                setIsFetching(false)
            }
        }
        fetchCreators()
    }, [])

    return {
        creators,
        isFetching,
    }
}
