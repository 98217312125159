import { ChannelDocument } from '@tivio/firebase'

import Widget from '../../store/Widget'
import { FirestoreCollectionReference, getFirestore, loggerFirestore } from '../app'

import { channelsConverter, widgetsConverter } from './converters'

import type firebase from 'firebase/app'


export interface WidgetFirestore {
    name: any,
    widgetId: string
    autoChannels: boolean
    enabled: boolean
    channelsOrder: firebase.firestore.DocumentReference<ChannelDocument>[]
}

const getWidgetsCollection = (): FirestoreCollectionReference<WidgetFirestore> => {
    return getFirestore().collection('widgets').withConverter(widgetsConverter)
}

const getWidgetChannelsCollection = (widgetId: string): FirestoreCollectionReference<ChannelDocument> => {
    return getFirestore().collection(`widget/${widgetId}/channels`).withConverter(channelsConverter)
}

const addWidget = async (widget: WidgetFirestore) => {
    try {
        const docRef = await getWidgetsCollection().add(widget)

        loggerFirestore.info('Widget created with ID: ', docRef.id)

        return docRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add widget: ', e)
        throw new Error(e)
    }
}

const updateWidget = async (widget: Widget, widgetData: Partial<WidgetFirestore>) => {
    try {
        await getWidgetsCollection().doc(widget.getId).update(widgetData)

        loggerFirestore.info('Widget has been updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update widget', e)
        throw new Error(e)
    }
}

const removeWidgetById = async (id: string) => {
    try {
        await getWidgetsCollection().doc(id).delete()

        loggerFirestore.info(`Widget with ID ${id} has been removed`)

        return
    }
    catch (e) {
        loggerFirestore.info(`Failed to remove widget with ID ${id}`)
        throw new Error(e)
    }
}

export {
    addWidget,
    updateWidget,
    getWidgetsCollection,
    getWidgetChannelsCollection,
    removeWidgetById,
}
