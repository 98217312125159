import { tivio } from '@tivio/core-js'
import { IndexedObject } from '@tivio/types'

import type { Hit } from '@algolia/client-search'


export async function loadApplicationsFromAlgoliaHits(
    hits: Hit<IndexedObject>[],
) {
    const organizationIds = hits.map((hit) => {
        if ('organizationPath' in hit) {
            return (hit.organizationPath as string).split('/')[1]
        }
    }).filter(Boolean) as string[]

    await tivio.getTivioProApplicationsByOrganizationIds(organizationIds)
}
