import { IconButton, makeStyles } from '@material-ui/core'
import { Delete, Lock, Menu } from '@material-ui/icons'
import { languages } from '@tivio/types'
import clsx from 'clsx'
import React, { useMemo } from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { ArticleBlock } from '../../../store/ArticleBlock'
import { ParagraphBlock, TranslationInputWrapper } from '../../common/input'

import { HeadingBlock } from './HeadingBlock'
import { ImageBlock } from './ImageBlock'
import { VideoBlock } from './VideoBlock/VideoBlock'

import type { Theme } from '@material-ui/core'
import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'
import { useOrganization } from '../../hooks'


const useStyles = makeStyles<Theme, { hasBorder: boolean, isLocked?: boolean }>((theme) => ({
    wrapper: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    sideAction: {
        flex: 'none',
        width: theme.spacing(4),
        height: theme.spacing(4),
        visibility: ({ isLocked }) => (isLocked ? 'hidden' : 'visible'),
    },
    deleteButton: {
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
        },
    },
}))

interface Props {
    block: ArticleBlock
    dragHandle?: DraggableProvidedDragHandleProps | null
    index: number
    isLocked?: boolean
    onRemove?: () => void
}

export const BlockWrapper = ({ block, index, isLocked, onRemove }: Props) => {
    const classes = useStyles({ hasBorder: true, isLocked })
    const isTranslationBlock = block.isTranslationBlock
    const { organization } = useOrganization()
    const [language, setLanguage] = React.useState(organization?.languages[0] || languages[0])
    const renderBlock = useMemo(() => {
        switch (block.type) {
            case 'PARAGRAPH':
                return <ParagraphBlock
                    language={language}
                    block={block}
                    isLocked={isLocked}
                />
            case 'HEADING':
                return <HeadingBlock
                    language={language}
                    block={block}
                />
            case 'IMAGE':
                return <ImageBlock
                    block={block}
                    isCoverImage={isLocked}
                />
            case 'VIDEO':
                return <VideoBlock block={block} />
            case 'PAYWALL':
                return <span>Paywall</span>
            default:
                return null
        }
    }, [block, language, isLocked])

    const renderRow = (dragHandleProps?: DraggableProvidedDragHandleProps | null, icon?: React.ReactNode) => (
        <div className={classes.wrapper}>
            <IconButton
                {...(dragHandleProps ?? { disabled: true })}
                size="small"
                className={classes.sideAction}
            >
                <Menu
                    color="disabled"
                    fontSize="inherit"
                />
            </IconButton>
            <TranslationInputWrapper
                language={language}
                setLanguage={setLanguage}
                isTranslationBlock={isTranslationBlock}
                translationValue={block.translationValue}
                icon={icon}
            >
                {renderBlock}
            </TranslationInputWrapper>
            <IconButton
                size="small"
                className={clsx(classes.sideAction, classes.deleteButton)}
                onClick={onRemove}
                disabled={isLocked}
            >
                <Delete fontSize="small" />
            </IconButton>
        </div>
    )

    if (!isLocked) {
        return (
            <Draggable
                index={index}
                draggableId={block.id}
            >
                {(draggableProvided) => (
                    <div
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        {renderRow(draggableProvided.dragHandleProps)}
                    </div>
                )}
            </Draggable>
        )
    }

    return renderRow(undefined, <Lock />)
}
