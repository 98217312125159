import {
    Button,
    Dialog,
    Divider,
    IconButton,
    TextField,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { normalizeText } from '@tivio/common'
import { ApplicationScreenField } from '@tivio/firebase'
import React, { useState } from 'react'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { createTranslation } from '../../creator/translation.creator'
import { Application } from '../../store/Application'
import { AppThemeProvider } from '../AppThemeProvider'
import { DialogTitle } from '../screen/RowDialog'
import { Column } from '../uiLayout/Column'

import { DialogDraggableScreensList } from './DialogDraggableScreensList'

import type Organization from '../../store/Organization'
import { getTranslation } from '../../utils/translate.utils'


enum DROPPABLE_ID {
    APPLICATION_SCREENS_DROPPABLE = 'APPLICATION_SCREENS_DROPPABLE',
    SCREENS_DROPPABLE = 'SCREENS_DROPPABLE',
}

const StyledDivider = styled(Divider)`
    margin: 0.5rem 0;
`

const SaveButtonWrapper = styled.div`
    margin-top: 2.5rem;
    margin-bottom: 1rem;
`

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

interface Props {
    application: Application
    organization: Organization
    open: boolean
    onClose: () => void
}

export const ApplicationDialog: React.FC<Props> = ({ application, organization, open, onClose }: Props) => {
    const languages = organization.languages
    const [nameValue, setNameValue] = useState<string>(getTranslation(application.name, languages) ?? '')
    const [applicationScreens, setApplicationScreens] = useState(application.applicationScreens)

    const [t] = useTranslation()

    const screens = organization?.screens ?? []
    // Available screens are screens that are not already used in the application
    const availableScreens = screens.filter(({ id }) => !applicationScreens.some(({ screenRef }) => screenRef.id === id))

    const handleApplicationNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNameValue(event.target.value)
    }

    const handleApplicationScreenNameChange = (index: number, name: string) => {
        setApplicationScreens((prevState) =>
            prevState.map((applicationScreen, i) => (i === index ? { ...applicationScreen, name: createTranslation(name) } : applicationScreen)),
        )
    }

    const handleDragEnd = (result: DropResult) => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        const newApplicationScreens = Array.from(applicationScreens)
        if (destination.droppableId === DROPPABLE_ID.APPLICATION_SCREENS_DROPPABLE) {
            if (source.droppableId === DROPPABLE_ID.SCREENS_DROPPABLE) {
                // User drags item from screens to application screens (ADD)
                const screen = availableScreens[source.index]
                const newApplicationScreen: ApplicationScreenField = {
                    name: screen.name,
                    screenRef: screen.ref,
                    path: normalizeText(getTranslation(screen.name, languages) ?? '', 'kebab-case'),
                    order: applicationScreens.length,
                    showForUserProfileType: {
                        kids: true,
                        adults: true,
                    },
                }

                newApplicationScreens.splice(destination.index, 0, newApplicationScreen)
            } else if (source.droppableId === DROPPABLE_ID.APPLICATION_SCREENS_DROPPABLE) {
                // User drags item within application screens (REORDER)
                const [removed] = newApplicationScreens.splice(source.index, 1)
                newApplicationScreens.splice(destination.index, 0, removed)
            }
        } else if (source.droppableId === DROPPABLE_ID.APPLICATION_SCREENS_DROPPABLE) {
            // User drags item from application screens to screens (REMOVE)
            newApplicationScreens.splice(source.index, 1)
        }

        setApplicationScreens(
            newApplicationScreens.map((applicationScreen, index) => ({
                ...applicationScreen,
                order: index,
            })),
        )
    }

    const handleSave = async () => {
        const name = nameValue.trim()
        if (!name) {
            return
        }

        if (applicationScreens.some(({ name }) => !getTranslation(name ?? '', languages)?.trim())) {
            return
        }

        await application.update({ name: createTranslation(name), applicationScreens })
        onClose()
    }

    return (
        <AppThemeProvider type="light">
            <DragDropContext onDragEnd={handleDragEnd}>
                <Dialog
                    open={open}
                    onClose={onClose}
                    maxWidth="sm"
                >
                    <DialogContent>
                        <CloseIcon onClick={onClose}>
                            <Close/>
                        </CloseIcon>
                        <Column spacing={2}>
                            <Column spacing={4}>
                                <DialogTitle variant="h6">{t('Details')}</DialogTitle>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label={t('Name')}
                                    value={nameValue}
                                    onChange={handleApplicationNameChange}
                                />
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label="ID"
                                    value={application.id}
                                    disabled
                                />
                                <DialogTitle variant="h6">{t('Screens')}</DialogTitle>
                            </Column>

                            <DialogDraggableScreensList
                                droppableId={DROPPABLE_ID.APPLICATION_SCREENS_DROPPABLE}
                                items={applicationScreens.map(({ name, path, screenRef }) => ({
                                    name: getTranslation(name, languages) ?? '',
                                    path,
                                    screenId: screenRef.id,
                                }))}
                                onItemNameChange={handleApplicationScreenNameChange}
                            />

                            <StyledDivider/>

                            {screens ? (
                                <DialogDraggableScreensList
                                    droppableId={DROPPABLE_ID.SCREENS_DROPPABLE}
                                    items={availableScreens.map(({ name, id }) => ({
                                        name: getTranslation(name, languages) ?? '',
                                        path: normalizeText(getTranslation(name, languages) ?? '', 'kebab-case'),
                                        screenId: id,
                                    }))}
                                />
                            ) : null}
                            <SaveButtonWrapper>
                                <Button
                                    fullWidth
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    onClick={handleSave}
                                >
                                    {t('Save')}
                                </Button>
                            </SaveButtonWrapper>
                        </Column>
                    </DialogContent>
                </Dialog>
            </DragDropContext>
        </AppThemeProvider>
    )
}
