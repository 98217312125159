import { Query } from '@tivio/firebase'
import { PaginationInterface } from '@tivio/types'
import firebase from 'firebase'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

import { getFirestore } from '../../../firebase/app'
import { Pagination } from '../../../firebase/pagination'
import Organization from '../../../store/Organization'
import { DEFAULT_SEARCH_OPTIONS, UseSearchOptions, UseSearchResult } from '../../../utils/search'

import { useOrganization } from './useOrganization'


export type UseFirebaseSearchOptions<Collection extends string, Document, Entity> = UseSearchOptions & {
    collectionName: Collection
    attribute: string
    mapper: (
        ref: firebase.firestore.DocumentReference<Document>,
        data: Document,
        organization: Organization,
    ) => Entity
}

/**
 * Does a "starts with" string search on given firebase collection and given document attribute.
 * Does not do a fulltext search (firebase limitation).
 *
 * Context: some entities are too expensive to index (e.g. users) in our search engine provider,
 * thus we do a much cheaper search directly through firebase db.
 *
 * If the collection you are searching through is indexed consider using {@link useIndexedSearch}.
 */
export function useFirebaseSearch<Collection extends string, Document, Entity>({
    collectionName,
    attribute,
    mapper,
    ...options
}: UseFirebaseSearchOptions<Collection, Document, Entity>): UseSearchResult<Entity> {
    const {
        limit,
        minQueryLength,
        defaultQuery,
    } = { ...DEFAULT_SEARCH_OPTIONS, ...options }

    const { organization } = useOrganization()
    const [pagination, setPagination] = useState<PaginationInterface<Entity> | null>(null)
    const [error, setError] = useState<Error | null>(null)

    // Internal state (needed for fetchMore calls)
    const [queryState, setQueryState] = useState<{ query: string, page: number }>({
        query: defaultQuery || '',
        page: 0,
    })

    useEffect(() => {
        if (!organization) {
            setError(new Error('Organization is not loaded yet.'))
            return
        }

        let firestoreQuery = getFirestore()
            .collection(collectionName)
            .where('organizationRef', '==', organization.ref) as Query<Document>

        // empty string is not acceptable for documentId()
        if (attribute !== 'id' || queryState.query) {
            const attributeOrDocumentId = attribute === 'id'
                ? firebase.firestore.FieldPath.documentId()
                : attribute

            firestoreQuery = firestoreQuery
                .where(attributeOrDocumentId, '>=', queryState.query)
                .where(attributeOrDocumentId, '<=', queryState.query + '~') as Query<Document>
        }

        setPagination(new Pagination<Document, Entity>(
            firestoreQuery,
            (ref, data) => mapper(ref, data, organization),
            { limit },
        ))
    }, [attribute, collectionName, limit, mapper, organization, queryState.query])


    // TODO duplication from useIndexedSearch
    const search = useCallback(debounce(async (query: string, forceRefresh = false) => {
        let queryTrimmed = query.trim()

        if (queryTrimmed.length < minQueryLength) {
            queryTrimmed = '' // Short queries are considered empty.
        }
        if (queryTrimmed === queryState.query && !forceRefresh) {
            return // No change in new query compared to previous one.
        }

        setPagination(null)
        setQueryState({ query: queryTrimmed, page: 0 }) // Reset pagination on new query.
    }, 500), [queryState.query])

    return {
        search,
        pagination,
        // TODO PaginationInterface.loading should be used instead
        isLoading: pagination?.loading ?? true,
        // TODO mb remove this, and do this in components
        isEmptyResult: !pagination?.loading && !!pagination?.items && pagination.items.length === 0,
        error,
        lastQuery: queryState.query,
    }
}
