import { cmp, tivio } from '@tivio/core-js'
import { useCallback } from 'react'

import { useObservable } from './useObservable'

/**
 * Web and tv-web only, not suitable for react-native
 */
function useCmp() {
    const [ isNoticeShown ] = useObservable(() => cmp.isNoticeShown)

    const activate = useCallback(() => {
        tivio.activateCmp()
    }, [])

    return {
        isNoticeShown,
        activate,
    }
}

export {
    useCmp,
}
