import { ProcessDocument } from "@tivio/firebase";

export const getMostRecentVideoEncodingProcesses = (encodingProcesses: ProcessDocument[]) => {
    const uniqueVideoQualities: number[] = []

    return encodingProcesses.sort((process, previouseProcess) => (
            previouseProcess.created.toMillis() - process.created.toMillis()
        ))
        .map(process => {
            if (uniqueVideoQualities.indexOf(process.payload.videoTrack) !== -1) {
                return null
            } else {
                uniqueVideoQualities.push(process.payload.videoTrack)

                return process
            }
        })
        .filter(process => process !== null)
        .slice(0, uniqueVideoQualities.length)
}