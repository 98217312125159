import { Link, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { AssetPreset } from '../../store/AssetPreset'
import { TileSize } from '../AppTile'
import { Badge } from '../common'
import { useOrganization } from '../hooks'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { AssetImageInput } from './assetImageInput'
import { AssetUploaderItem } from './assetUploader'

import type { ProgressEvent } from './assetImageInput'


interface Props {
    preset: AssetPreset
    item: AssetUploaderItem
    size?: TileSize
    disabled?: boolean
    mapPresetName?: (presetName: string) => string
}

const AssetTitle = styled(Typography)`
    font-weight: bold;
`

export const AssetRow = ({ preset, item, size, disabled, mapPresetName }: Props) => {

    const [ t ] = useTranslation()
    const organizationId = useOrganization().organization?.id
    const [ progressEvent, setProgressEvent ] = useState<ProgressEvent | null>(null)

    const isAvatar = size === 'avatar'

    return (
        <Row
            data-e2e="asset-row"
            spacing={isAvatar ? 2 : 3}
            rowProps={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                wrap: 'nowrap',
            }}
        >
            {organizationId != null && (
                <AssetImageInput
                    image={item.getAsset(preset.name)}
                    preset={preset}
                    item={item}
                    size={size}
                    onProgress={setProgressEvent}
                    disabled={disabled}
                />
            )}
            <Column>
                <AssetTitle
                    variant={isAvatar ? 'body2' : 'h6'}
                    color="inherit"
                >
                    {mapPresetName ? mapPresetName(preset.name) : preset.name}
                </AssetTitle>
                <Row spacing={1}>
                    {
                        progressEvent?.state === 'uploading' && (
                            <Badge>
                                {t('Uploading') + '...'}
                            </Badge>
                        )
                    }
                    {
                        (progressEvent?.state === 'resizing') && (
                            <Badge>
                                {progressEvent?.message}
                            </Badge>
                        )
                    }
                    {
                        (!progressEvent || progressEvent?.state === 'success') && preset.scaling.map(
                            scale => (
                                <Link
                                    key={scale}
                                    href={item.getAsset(preset.name, scale) ?? undefined}
                                    color="textSecondary"
                                    title={t('Preview')}
                                    variant="caption"
                                    target="_blank"
                                >
                                    {preset.getScaleQualityText(scale)}
                                </Link>
                            ))
                    }

                    <Typography
                        color="textSecondary"
                        component="span"
                        variant="caption"
                    >
                        {preset.description}
                    </Typography>

                    <Typography
                        color="textSecondary"
                        component="span"
                        variant="caption"
                    >
                        ({preset.getPlacementText()})
                    </Typography>
                </Row>
            </Column>
        </Row>
    )
}

// TODO TIV-546 keep lastError on asset object, render some red warning and message (something
// went wrong during last upload of this asset. Please retry.)
