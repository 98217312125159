import { useCountdown } from './useCountdown'


interface Props {
    expiryDate?: Date
    isManuallyBlocked?: boolean
}

/**
 * Tells whether is video is available to be watched, and also returns timer object,
 * which tells how much time is left until video is available (unless it's {@link isManuallyBlocked} afterwards).
 * @param expiryDate - js Date object which {@link useCountdown} timer will be based on
 * @param isManuallyBlocked - tells whether availability is manually blocked
 */
export function useAvailability({
    expiryDate,
    isManuallyBlocked = false,
}: Props) {
    const timer = useCountdown({ expiryDate })

    return {
        timer,
        isAvailable: !expiryDate || (timer.isOver && !isManuallyBlocked),
    }
}
