import { TagDocument, TagTypeDocument } from '@tivio/firebase'
import { TagSimplicityType } from '@tivio/types'

import { createTranslation } from '../creator/translation.creator'
import { firebaseTimestampFromDate } from '../firebase/app'
import { accesssTagTypeRef, indicationTagTypeRef } from '../firebase/firestore/tag'
import { Tag } from '../store/Tag'

import { translate } from './translate.utils'

import type firebase from 'firebase/app'


export const createTagData = (
    tagTypeRef: firebase.firestore.DocumentReference<TagTypeDocument>,
): Omit<TagDocument, 'documentId'> => {
    return {
        tagId: 'DATABASE_GENERATED_ID',
        created: firebaseTimestampFromDate(),
        name: createTranslation('Tag'),
        tagTypeRef,
        assets: {},
        description: translate('Tag description'),
        metadata: [],
        simplicity: TagSimplicityType.SIMPLE,
    }
}

export const filterUtilityTags = (tag: Tag) => {
    return !tag.tagTypeRef || tag.tagTypeRef.id !== accesssTagTypeRef.id && tag.tagTypeRef.id !== indicationTagTypeRef.id
}
