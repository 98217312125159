import { Button, FormControlLabel, Grid, Switch } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as Yup from 'yup'

import Monetization from '../../store/Monetization'
import { getVariantId } from '../../utils/monetization.utils'
import { AppSelect } from '../AppSelect'
import { DurationField } from '../common/formField/DurationField'
import DialogBase from '../dialog/DialogBase'
import { useOrganization } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'

import type { DurationWithUnit } from '@tivio/types'
import type { FormikProps } from 'formik'


interface Props {
    monetizationId: string
    showDuration?: boolean
    open: boolean
    onClose: () => void
    onSubmit: (values: NewVariantFormValues) => void
}

const DEFAULT_DURATION: DurationWithUnit = {
    value: 1,
    unit: 'month',
}

export interface NewVariantFormValues {
    monetizationId: string
    duration?: DurationWithUnit
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 560,
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
    },
}))

const AddNewMonetizationVariantDialog = observer(({ monetizationId, showDuration, open, onClose, onSubmit }: Props) => {
    const classes = useStyles()
    const [ t ] = useTranslation()
    const { showError } = useAlert()
    const { organization } = useOrganization()

    const validationSchema = Yup.object().shape({
        monetizationId: Yup.string().required(t('Related monetization is required')),
    })

    const constructOptionsGroup = (groupName: string, monetizations: Monetization[]) => ({
        groupName,
        options: monetizations
            .filter(mon => mon.getId !== monetizationId)
            .map(
                (mon) => {
                    return {
                        value: mon.getId,
                        optionLabel: mon.getName,
                    }
                },
            ),
    })

    const selectOptions = [
        constructOptionsGroup(t('Transactions'), organization?.transactions ?? []),
        constructOptionsGroup(t('Subscriptions'), organization?.subscriptions ?? []),
    ]

    const handleSubmit = (values: NewVariantFormValues) => {
        const { monetizationId: activatingMonetizationId, duration } = values
        const currentMonetization = organization?.findLoadedMonetization(monetizationId)        

        const foundSameVariant = currentMonetization?.variants.some(
            ({ id }) => id === getVariantId(activatingMonetizationId, duration),
        )

        if (foundSameVariant) {
            showError(t('This variant already exists'))
            return
        }

        onSubmit(values)
    }

    const renderForm = (formikProps: FormikProps<NewVariantFormValues>) => {
        const isDurationVisible = showDuration && formikProps.values.duration !== undefined
        return <Form>
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <AppSelect
                        name="monetizationId"
                        groupedOptions={selectOptions}
                        label={t('Related monetization')}
                        size="medium"

                        //TODO NICE TO HAVE create some wrapper AppSelectFormik that will
                        // handle this 'value' and 'onChange' inside
                        value={formikProps.values.monetizationId}
                        onChange={event => formikProps.setFieldValue('monetizationId', event.target.value)}
                    />
                </Grid>
                {showDuration && <Grid item>
                    <FormControlLabel
                        label={t('Minimum duration')}
                        control={
                            <Switch
                                checked={isDurationVisible}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        formikProps.setFieldValue('duration', DEFAULT_DURATION)
                                    } else {
                                        formikProps.setFieldValue('duration', undefined)
                                    }
                                }}
                                color="primary"
                            />
                        }
                    />
                </Grid>}
                {isDurationVisible && (
                    <Grid item>
                        <DurationField
                            value={formikProps.values.duration!}
                            onChange={(value) => formikProps.setFieldValue('duration', value)}
                        />
                    </Grid>
                )}
                <Grid item>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {t('Save')}
                    </Button>
                </Grid>
            </Grid>
        </Form>
    }

    return (
        <DialogBase
            open={open}
            onCancel={onClose}
            hideActionsButtons
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
            showCloseIcon
        >
            <Formik
                initialValues={{ monetizationId: '' }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
            >
                {renderForm}
            </Formik>
        </DialogBase>
    )
})

export default AddNewMonetizationVariantDialog
