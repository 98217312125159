import { WhereFilterOp } from '@firebase/firestore-types'
import { IconButton, TextField } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import { DateTimePicker } from '@material-ui/pickers'
import { WHERE_FILTER_OPERATORS } from '@tivio/firebase'
import {
    RowFilterField,
    RowFilterWhereField,
    RowWhereFilterFieldPath,
    VideoContentType,
    VideoType,
} from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { firebaseTimestampFromDate } from '../../firebase/app'
import { enumToOptions } from '../../utils/enum.utils'
import { AppSelect, appSelectConverter, AppSelectOption } from '../AppSelect'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useAllTagTypes } from '../settings/tagTypes/hooks'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { DEFAULT_VALUE_MAP, DialogTitle } from './RowDialog'
import { TagsAutocomplete } from './TagsAutocomplete'


const WHERE_FILTER_FIELD_LABELS: { [fieldPath: string]: string } = {
    [RowWhereFilterFieldPath.TAG_TYPE_REF]: 'tag type',
    [RowWhereFilterFieldPath.TYPE]: 'video type',
    [RowWhereFilterFieldPath.CONTENT_TYPE]: 'video content type',
}

const WHERE_FILTER_FIELD_OPTIONS: AppSelectOption[] = enumToOptions(RowWhereFilterFieldPath)
    .map((fieldPath: string) => ({
        value: fieldPath,
        optionLabel: WHERE_FILTER_FIELD_LABELS[fieldPath] || fieldPath,
    })).filter(option => option.value !== RowWhereFilterFieldPath.TRANSCODING_STATUS)

type Props = {
    filter?: RowFilterField
    whereFilter: RowFilterWhereField
    onDelete?: (whereFilter: RowFilterWhereField) => void
    onChange?: (whereFilter: RowFilterWhereField) => void
    onFieldPathChange?: (oldWhereFilter: RowFilterWhereField, newWhereFilter: RowFilterWhereField) => void
}

const RowCondition: React.FC<Props> = (props) => {
    const { whereFilter, filter } = props
    const [t] = useTranslation()
    const { showError } = useAlert()

    const tagTypes = useAllTagTypes()

    const handleUpdateWhereFilterFieldPath = (whereFilterFieldPath: RowWhereFilterFieldPath) => {
        if (filter?.where?.some(someWhereFilter => someWhereFilter.fieldPath === whereFilterFieldPath)) {
            showError(t('Condition already exists'))
            return
        }

        props.onFieldPathChange?.(whereFilter, {
            fieldPath: whereFilterFieldPath,
            opStr: '==',
            value: DEFAULT_VALUE_MAP[whereFilterFieldPath],
        })
    }

    const handleUpdateWhereFilterOperator = (whereFilterOperator: WhereFilterOp) => {
        props.onChange?.({
            ...whereFilter,
            opStr: whereFilterOperator,
        })
    }

    const getFieldPathComponent = (whereFilterFieldPath: RowWhereFilterFieldPath) => {
        const ComponentMap = {
            [RowWhereFilterFieldPath.CREATED]: (
                <DateTimePicker
                    ampm={false}
                    value={whereFilter.value.toDate?.() ?? new Date()}
                    onChange={e => props.onChange?.({
                        ...whereFilter,
                        value: firebaseTimestampFromDate(e?.toDate()),
                    })}
                    fullWidth
                    size="small"
                    showTodayButton
                    variant="dialog"
                    views={['date']}
                    label={t('Date')}
                    inputVariant="outlined"
                />
            ),
            [RowWhereFilterFieldPath.TAGS]: (
                <TagsAutocomplete
                    selectedTags={whereFilter.value}
                    onTagChange={tags => props.onChange?.({
                        ...whereFilter,
                        value: tags.map(tag => tag.getRef),
                    })}
                />
            ),
            [RowWhereFilterFieldPath.TAG_TYPE_REF]: (
                <AppSelect
                    options={tagTypes.map(tagType => ({ optionLabel: tagType.name, value: tagType.id }))}
                    value={whereFilter.value.id}
                    onChange={e => props.onChange?.({
                        ...whereFilter,
                        value: tagTypes.find(tagType => tagType.id === e.target.value)?.ref,
                    })}
                    label={t('Tag type')}
                />
            ),
            // TV-PROGAM (epg) or VIDEO (vod)
            [RowWhereFilterFieldPath.TYPE]: (
                <AppSelect
                    options={Object.values(VideoType).map((value) => ({ optionLabel: value, value: value }))}
                    value={whereFilter.value}
                    onChange={e => props.onChange?.({
                        ...whereFilter,
                        value: e.target.value,
                    })}
                    label={t('Video type')}
                />
            ),
            // FILM or SERIES
            [RowWhereFilterFieldPath.CONTENT_TYPE]: (
                <AppSelect
                    options={Object.values(VideoContentType).map((value) => ({ optionLabel: value, value: value }))}
                    value={whereFilter.value}
                    onChange={e => props.onChange?.({
                        ...whereFilter,
                        value: e.target.value,
                    })}
                    label={t('Video content type')}
                />
            ),
            // Only TvChannel property
            // TODO this is temporary solution for temporary filter, will be replaced with filter by document ID
            [RowWhereFilterFieldPath.CHANNEL_KEY]: (
                <TextField
                    label={t('Channel key')}
                    value={whereFilter.value}
                    variant="outlined"
                    placeholder={t('No filter set')}
                    size="small"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    onChange={e => props.onChange?.({
                        ...whereFilter,
                        value: e.target.value ?? '',
                    })}
                />
            ),
        }

        // @ts-expect-error TODO: Resolve this in some better way. I suppose we don't want to allow filter based on published status and organization ref.
        return ComponentMap[whereFilterFieldPath]
    }

    return (
        <Column spacing={2}>
            <Row
                rowProps={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <DialogTitle>{whereFilter.fieldPath} {t('condition')}</DialogTitle>
                <IconButton
                    size="small"
                    onClick={() => props.onDelete?.(whereFilter)}
                >
                    <Delete fontSize="small" />
                </IconButton>
            </Row>
            <AppSelect
                options={WHERE_FILTER_FIELD_OPTIONS}
                value={whereFilter.fieldPath}
                onChange={e => handleUpdateWhereFilterFieldPath(e.target.value as RowWhereFilterFieldPath)}
                label={t('Field')}
            />
            <AppSelect
                options={appSelectConverter(WHERE_FILTER_OPERATORS)}
                value={whereFilter.opStr}
                onChange={e => handleUpdateWhereFilterOperator(e.target.value as WhereFilterOp)}
                label={t('Operator')}
            />
            {getFieldPathComponent(whereFilter.fieldPath)}
        </Column>
    )
}

export {
    RowCondition,
}
