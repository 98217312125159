import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import algoliasearch, { AlgoliaSearchOptions } from 'algoliasearch/lite'
import { useCallback, useMemo } from 'react'

import type { RequestOptions } from '@algolia/transporter'


function createAlgoliaClient() {
    if (!process.env.REACT_APP_ALGOLIA_APPLICATION_ID) {
        throw new Error('Can\'t get REACT_APP_ALGOLIA_APPLICATION_ID, client will not be instantiated.')
    }

    if (!process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY) {
        throw new Error('Can\'t get REACT_APP_ALGOLIA_SEARCH_API_KEY, client will not be instantiated.')
    }

    return algoliasearch(
        process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
        process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
    )
}

export const useAlgoliaSearch = <T extends ALGOLIA_INDEX_NAME>(name: T) => {

    const indexName = name === ALGOLIA_INDEX_NAME.VIDEOS_FOR_EPG ? ALGOLIA_INDEX_NAME.VIDEOS : name

    const searchClient = useMemo(createAlgoliaClient, [])

    const index = useMemo(() => searchClient.initIndex(indexName), [ searchClient, indexName ])

    const search = useCallback((query: string, requestOptions?: RequestOptions & AlgoliaSearchOptions) => {
        return index.search(query, requestOptions)
    }, [ index ])

    const clearCache = searchClient.clearCache

    return { search, clearCache }
}
