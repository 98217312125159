import { Button, Divider, MuiThemeProvider, TextareaAutosize } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { lightTheme } from '../../static/theme'
import { composeIframeCode } from '../../utils/videoEditor.utils'
import { useOrganization } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'


const IFrameTextarea = styled(TextareaAutosize)`
    width: 100%;
    resize: none;
`
const IFrameDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

type VideoShareDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    videoId: string;
}

export const VideoShareDialog: FC<VideoShareDialogProps> = ({
    isOpen,
    onClose,
    videoId,
}) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const [iFrameMarkup, setIFrameMarkup] = useState(composeIframeCode(videoId, organization?.customerId))
    const { showSuccess } = useAlert()

    const callbackRef = useCallback((inputElement: HTMLTextAreaElement) => {
        if (inputElement) {
            inputElement.focus()
            inputElement.select()
        }
    }, [])

    const handleIFrameTextAreaChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setIFrameMarkup(e.target.value)
    }, [setIFrameMarkup])

    const handleCopyToClipboard = useCallback(async () => {
        await copyTextToClipboard(iFrameMarkup)
        showSuccess(t('Copied!'))
    }, [iFrameMarkup])


    return (
        <MuiThemeProvider theme={lightTheme}>
            <Dialog
                open={isOpen}
                onClose={onClose}
            >
                <DialogTitle>{t('Share iFrame')}</DialogTitle>
                <Divider/>
                <IFrameDialogContent>
                    <IFrameTextarea
                        aria-label="empty textarea"
                        value={iFrameMarkup}
                        onChange={handleIFrameTextAreaChange}
                        minRows={10}
                        wrap="off"
                        ref={callbackRef}
                    />
                </IFrameDialogContent>
                <Divider/>
                <DialogActions>
                    <Button onClick={handleCopyToClipboard}>{t('Copy')}</Button>
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    )
}
