import { makeAutoObservable } from 'mobx'


export interface IDataState<T> {
    /**
     * Some data fetched from DB
     */
    data: T
    /**
     * Errors in case of failed request
     */
    errors: Error[]
    /**
     * Loading state
     */
    loading: boolean
}

/**
 * Contains some data fetched from DB and information about state of their fetching (errors and loading).
 * Provides method to load data.
 */
export class DataState<T> implements IDataState<T> {

    constructor(
        protected _data: T,
        protected _errors: Error[] = [],
        protected _loading = false,
    ) {
        makeAutoObservable(this)
    }

    /**
     * Loads data by given loadDataFunction and save them to {@link _data}.
     * Handles loading state and possible errors. {@link _loading} field is defined status of loading data themselves,
     * so when onSuccess/onError is called, loading is always false.
     *
     * @param loadDataFunction function that loads and returns data
     * @param onSuccess function that will be executed after successful request
     * @param onError function that will be executed after failed request
     */
    loadData = async (loadDataFunction: () => Promise<T>, onSuccess?: (data: T) => Promise<void>, onError?: (error: Error) => Promise<void>) => {
        this._loading = true
        try {
            this._data = await loadDataFunction()
            this._loading = false
            await onSuccess?.(this._data)
        } catch (error) {
            this._errors = [ error ]
            this._loading = false
            await onError?.(error)
        }
    }

    get data(): T {
        return this._data
    }

    set data(data: T) {
        this._data = data
    }

    get errors(): Error[] {
        return this._errors
    }

    get loading(): boolean {
        return this._loading
    }

}
