import { alpha, CircularProgress, makeStyles, Typography } from '@material-ui/core'
import Error from '@material-ui/icons/Error'
import ImageIcon from '@material-ui/icons/Image'
import { ARTICLE_BLOCK_IMAGE, ARTICLE_COVER_IMAGE, AssetPresetPlacement } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AssetResizeResult } from '../../../firebase/firestore/types'
import { ArticleBlock } from '../../../store/ArticleBlock'
import { AssetPreset } from '../../../store/AssetPreset'
import { ProgressEvent } from '../../asset/assetImageInput'
import { AssetUploaderItem } from '../../asset/assetUploader'
import { ClickableFileInput } from '../../common/input'
import { useAssetResize } from '../../hooks/dataHooks/useAssetResize'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { useImageUpload } from '../../hooks/uiHooks/useImageUpload'
import { useValidateFile } from '../../hooks/uiHooks/useValidateFile'
import { useAllAssetPresets } from '../../settings/presets/hooks'
import { ContentPlaceholderButton } from '../components/ContentPlaceholderButton'

import { ChangeButton } from './ChangeButton'

import type { Theme } from '@material-ui/core'


const useStyles = makeStyles<Theme, { aspectRatio?: number }>((theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        aspectRatio: (props) => props.aspectRatio,
    },
    placeholder: {
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: theme.shape.borderRadius,
    },
    loading: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        backgroundColor: alpha(theme.palette.common.black, 0.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    error: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        padding: theme.spacing(1),
        backgroundColor: alpha(theme.palette.error.dark, 0.85),
        borderRadius: theme.shape.borderRadius,
        color: theme.palette.common.white,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}))

interface Props {
    block: ArticleBlock
    isCoverImage?: boolean
    aspectRatio?: number
}

export const ImageBlock = observer(({ block, isCoverImage, aspectRatio = 16 / 9 }: Props) => {
    const [t] = useTranslation()
    const classes = useStyles({ aspectRatio })
    const clickableUploadRef = React.useRef<HTMLInputElement>(null)
    const { showSuccess } = useAlert()
    const [progressEvent, setProgressEvent] = React.useState<ProgressEvent>()
    const assetPresets = useAllAssetPresets({ placement: AssetPresetPlacement.CONTENT })
    const preset = assetPresets.find((preset) => (isCoverImage ? preset.name === ARTICLE_COVER_IMAGE : preset.name === ARTICLE_BLOCK_IMAGE))!

    const article = block.article

    const item: AssetUploaderItem = {
        keepOriginal: true,
        quality: 100,
        getAsset: article.getAsset,
        getTempFilePath: (preset: AssetPreset) => `${article.getDocumentPath}/${preset.name}-${block.id}.jpeg`,
        getDocumentPath: () => article.getDocumentPath,
        updateItem: async (uris: AssetResizeResult[], name: string) => {
            setProgressEvent({
                state: 'saving',
                message: t('Saving image into article'),
            })
            if (isCoverImage) {
                await article.updateAsset(uris, name)
            } else {
                await block.updateImageUrl(uris[0].url)
            }
            showSuccess(t('Image has been saved successfully'))
        },
    }

    const { resizeAsset } = useAssetResize({
        preset,
        item,
        onProgress: setProgressEvent,
    })

    const validateFile = useValidateFile(preset)

    const { error, image, onFiles } = useImageUpload({
        image: block.imageUrl,
        onFile: resizeAsset,
        validateFile,
    })

    const openUploadDialog = () => clickableUploadRef.current?.click()

    const isWholeClickable = !block.imageUrl && !progressEvent
    const errorMessage = error?.message ?? progressEvent?.error
    return (
        <ClickableFileInput
            ref={clickableUploadRef}
            onFiles={onFiles}
            disabled={!isWholeClickable}
        >
            <div className={classes.container}>
                {isWholeClickable ? (
                    <ContentPlaceholderButton
                        height="100%"
                        onClick={openUploadDialog}
                    />
                ) : (
                    <div
                        className={classes.placeholder}
                        style={{ backgroundImage: `url(${image})` }}
                    />
                )}
                {progressEvent && ['uploading', 'resizing'].includes(progressEvent.state) && (
                    <div className={classes.loading}>
                        <CircularProgress />
                        <Typography variant="caption">{progressEvent.message}</Typography>
                    </div>
                )}
                {errorMessage && (
                    <div className={classes.error}>
                        <Error />
                        {errorMessage}
                    </div>
                )}
                {!isWholeClickable && (
                    <ChangeButton
                        startIcon={<ImageIcon />}
                        onClick={openUploadDialog}
                    >
                        {t('Change image')}
                    </ChangeButton>
                )}
            </div>
        </ClickableFileInput>
    )
})
