import { alpha, makeStyles } from '@material-ui/core'
import { LangCode, Translation } from '@tivio/types'
import clsx from 'clsx'
import React, { PropsWithChildren } from 'react'

import { LanguageLegend } from './LanguageLegend'

import type { Theme } from '@material-ui/core'


const BORDER_BASE_COLOR = '#ffffff'

const useStyles = makeStyles<Theme, { hasBorder: boolean }>((theme) => ({
    fieldsetBlock: {
        marginTop: '-1em',
    },
    blockContent: ({ hasBorder }) => ({
        flex: 1,
        flexBasis: 768,
        ...(hasBorder && {
            padding: theme.spacing(2),
            border: `1px solid ${alpha(BORDER_BASE_COLOR, 0.23)}`,
            borderRadius: theme.spacing(0.5),
            '&:hover': {
                borderColor: alpha(BORDER_BASE_COLOR, 0.87),
            },
        }),
    }),
    blockIcon: {
        position: 'absolute',
        top: theme.spacing(-1.25),
        right: theme.spacing(7),
        '& svg': {
            fontSize: '0.95rem',
            color: BORDER_BASE_COLOR,
        },
    },
}))

interface Props {
    icon?: React.ReactNode
    language: LangCode
    isTranslationBlock?: boolean
    translationValue?: Translation
    setLanguage: (newLanguage: LangCode) => void
}

export const TranslationInputWrapper = ({ icon, isTranslationBlock, language, translationValue, setLanguage, children }: PropsWithChildren<Props>) => {
    const classes = useStyles({ hasBorder: true })

    return (
        <fieldset className={clsx(classes.blockContent, isTranslationBlock && classes.fieldsetBlock)}>
            {icon && <div className={classes.blockIcon}>{icon}</div>}
            {isTranslationBlock && (
                <legend>
                    <LanguageLegend
                        language={language}
                        translation={translationValue}
                        onChange={setLanguage}
                    />
                </legend>
            )}
            {children}
        </fieldset>
    )
}
