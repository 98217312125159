import { Button, ButtonGroup, makeStyles } from '@material-ui/core'
import { LangCode, languages, Translation } from '@tivio/types'
import clsx from 'clsx'
import React from 'react'
import { useOrganization } from '../../hooks'


const useStyles = makeStyles((theme) => ({
    button: {
        color: theme.palette.text.disabled,
        padding: '2px 4px',
        fontSize: '0.75rem',
        minWidth: 0,
        borderRadius: '2px',
        opacity: 0.85,
        '&:not(:last-child), &:not(:first-child)': {
            border: 'none',
            borderRadius: '2px',
        },
    },
    buttonActive: {
        fontWeight: 'bold',
        opacity: 1,
    },
    buttonFilled: {
        color: theme.palette.primary.main,
    },
}))

interface Props {
    language: LangCode
    translation?: Translation
    onChange: (newLanguage: LangCode) => void
}

export const LanguageLegend = ({ language, translation, onChange }: Props) => {
    const classes = useStyles()
    const { organization } = useOrganization()
    return (
        <ButtonGroup
            variant="text"
            size="small"
        >
            {(organization?.languages ?? languages).map((lang) => (
                <Button
                    key={lang}
                    onClick={() => onChange(lang)}
                    className={clsx(classes.button, lang === language && classes.buttonActive, translation?.[lang]?.length && classes.buttonFilled)}
                >
                    {lang}
                </Button>
            ))}
        </ButtonGroup>
    )
}
