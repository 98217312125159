export type SemVer = number[]

// eslint-disable-next-line
export const SEM_REGEXP = /(0|[1-9]\d*)[\._](0|[1-9]\d*)[\._](0|[1-9]\d*)(-[a-zA-Z\d][-a-zA-Z.\d]*)?(\+[a-zA-Z\d][-a-zA-Z.\d]*)?/

export function semVerComparer (a: SemVer, b: SemVer): number {
    if (a[0] === b[0]) {
        if (a[1] === b[1]) {
            return b[2] - a[2]
        }
        return b[1] - a[1]
    }
    return b[0] - a[0]
}

export function toSemVer (versionString: string): SemVer | null {
    if (typeof versionString !== 'string') {
        return null
    }

    const matched = versionString.match(SEM_REGEXP)
    if (matched === null) {
        return null
    }
    const result = [parseInt(matched[1], 10), parseInt(matched[2], 10), parseInt(matched[3], 10)]
    return result
}

export function isValidSemVer (semVer: SemVer | null): semVer is SemVer {
    if (semVer === null || !Array.isArray(semVer) || semVer.length !== 3) {
        return false
    }
    if (semVer.some(isNaN)) {
        return false
    }
    return true
}
