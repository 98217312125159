import { makeStyles } from '@material-ui/core'

/**
 * Hover styles for items in lists.
 */
export const useListItemStyles = makeStyles(theme => ({
    root: {
        borderRadius: '4px',
        color: theme.palette.text.primary,
        textDecoration: 'none',
        transition: 'background-color 200ms',

        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    showOnHover: {
        visibility: 'hidden',
        opacity: 0,
        transition: 'visibility 0s 200ms, opacity 200ms',

        '&.isHover': {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s, opacity 200ms',
        },
    },
}))
