import { createLogger } from '@tivio/common'
import { ScreenDocumentCreation } from '@tivio/firebase'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useAlert } from '../uiHooks/useAlert'

import { useOrganization } from './useOrganization'


const logger = createLogger('useScreens')

const useScreens = () => {
    const { organization } = useOrganization()
    const { showSuccess, showError } = useAlert()
    const [ t ] = useTranslation()

    useEffect(() => {
        organization?.loadScreens()
    }, [])

    const addScreen = (screenData: ScreenDocumentCreation) => {
        try {
            organization?.addScreen(screenData)
            showSuccess(t('Screen created'))
        }
        catch (e) {
            logger.error(e)
            showError(t('Failed to create screen'))
        }
    }

    return {
        screens: organization?.screens ?? [],
        loading: organization?.screensState.loading,
        addScreen,
    }
}

export {
    useScreens,
}
