import { MenuItem, Select } from '@material-ui/core'
import { TvStreamType } from '@tivio/types'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Text } from '../common'


const StyledText = styled(Text)`
    margin-right: 20px;
`

interface Props {
    selected: TvStreamType | null
    onSelect?: (tvStreamType: TvStreamType) => void
}

export const TvStreamTypeSelect = (props: Props) => {
    const [ t ] = useTranslation()

    const [selected, setSelected] = useState(props.selected)

    const onChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
        const type = event.target.value as TvStreamType

        setSelected(type)
        props.onSelect?.(type)
    }, [props.onSelect])

    return (
        <div>
            <StyledText>{t('TV programme timing')}:</StyledText>
            <Select
                value={selected}
                onChange={onChange}
            >
                <MenuItem
                    value={TvStreamType.ALL}
                >
                    {t('all types')}
                </MenuItem>
                <MenuItem
                    value={TvStreamType.TIMESHIFT}
                    title={t('Watching a past TV programme')}
                >
                    {t('timeshift')}
                </MenuItem>
                <MenuItem
                    value={TvStreamType.STARTOVER}
                    title={t('Re-watching a live TV programme from the beginning')}
                >
                    {t('startover')}
                </MenuItem>
                <MenuItem
                    value={TvStreamType.LIVE}
                    title={t('TV programme broadcasted at this moment')}
                >
                    {t('live TV')}
                </MenuItem>
            </Select>
        </div>
    )
}
