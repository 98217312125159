import { CustomerId, PLATFORM } from '@tivio/types'
import React, { createContext } from 'react'


export type CustomerContextValue = { customer: CustomerId, platform: PLATFORM, paletteCustomerId?: CustomerId }

export const CustomerContext = createContext<CustomerContextValue | null>(null)

/**
 * Defines which tivio and app configs will be used for app build
 */
export const CustomerProvider: React.FC<CustomerContextValue & {
    children?: React.ReactNode
}> = (props) => {
    const { customer, platform, paletteCustomerId } = props

    return (
        <CustomerContext.Provider value={{ customer, platform, paletteCustomerId }}>
            {props.children}
        </CustomerContext.Provider>
    )
}
