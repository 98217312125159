import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Application } from '../../store/Application'
import { useOrganization } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { ApplicationDialog } from './ApplicationDialog'
import { ApplicationsItem } from './ApplicationsItem'


interface Props {
    applications: Application[]
}

export const ApplicationsList: React.FC<Props> = ({ applications }) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()
    const { showSuccess, showError } = useAlert()
    const [application, setApplication] = useState<Application | null>(null)

    useEffect(() => {
        organization?.loadScreens().catch((error) => {
            console.error(error)
            showError(t('Failed to load application screens'))
        })
    }, [])

    const handleEditClick = (application: Application) => {
        setApplication(application)
    }

    const handleDeleteClick = (application: Application) => {
        confirmAction(() => {
            try {
                organization?.removeApplication(application)
                showSuccess(t('Application deleted'))
            } catch (e) {
                console.error(e)
                showError(t('Failed to delete application'))
            }
        })
    }

    return (
        <>
            {application && organization && <ApplicationDialog
                application={application}
                organization={organization}
                open={true}
                onClose={() => setApplication(null)}
            />}
            {applications.length === 0 || !organization ? (
                <Typography color="textSecondary">{t('No applications yet')}</Typography>
            ) : (
                applications.map((applicationItem) => (
                    <ApplicationsItem
                        key={applicationItem.id}
                        application={applicationItem}
                        languages={organization.languages}
                        onEdit={handleEditClick}
                        onDelete={handleDeleteClick}
                    />
                ))
            )}
        </>
    )
}
