import { FormHelperText } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import { isInThePast } from '@tivio/common'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { GeneralDialog } from '../../common/dialog/GeneralDialog'
import { ItemSearch } from '../../common/ItemSearch'
import { Column } from '../../uiLayout/Column'


const TimePickerError = styled(FormHelperText)`
    margin-top: -4px;
    margin-left: 4px;
`

type Props = {
    defaultTime: Dayjs
    open: boolean
    onClose: () => void
    title: string
    onAdd: (itemPath: string, addItemTime: Dayjs) => void
}

export const AddEpgScheduleItemDialog = ({ defaultTime, open, onClose, title, onAdd }: Props) => {
    const [ t ] = useTranslation()
    const defaultAddItemTime = isInThePast(defaultTime) ? dayjs() : defaultTime
    /**
     * Time on which "add" button was clicked
     */
    const [ addItemTime, setAddItemTime ] = useState<Dayjs>(defaultAddItemTime)
    const [ timePickerError, setTimePickerError ] = useState(null)

    const handleTimeChange = (time: Dayjs | null) => {
        if (time) {
            if (isInThePast(time)) {
                setTimePickerError(t('Please choose time in future'))
            } else {
                setTimePickerError(null)
            }
            setAddItemTime(prevTime => {
                // keep the same date and only set chosen time
                return prevTime.clone()
                    .set('h', time.get('h'))
                    .set('m', time.get('m'))
                    .set('s', time.get('s'))
            })
        }
    }

    const onItemAdd = (itemPath: string) => {
        onAdd(itemPath, addItemTime)
        onClose()
    }

    return (
        <GeneralDialog
            open={open}
            onClose={onClose}
            title={title}
        >
            <Column spacing={1}>
                <TimePicker
                    ampm={false}
                    value={addItemTime}
                    format="DD MMM HH:mm"
                    onChange={handleTimeChange}
                    variant="dialog"
                    label={t('Program time')}
                    inputVariant="outlined"
                    error={!!timePickerError}
                />
                <TimePickerError error={true}>{timePickerError}</TimePickerError>
                <ItemSearch
                    autoFocus
                    onAdd={onItemAdd}
                    buttonText={t('Add')}
                    // @ts-expect-error
                    searchIndex={ALGOLIA_INDEX_NAME.VIDEOS_FOR_EPG}
                    filterType="epg"
                    disabled={!!timePickerError}
                />
            </Column>
        </GeneralDialog>
    )
}
