import {
    Button,
    Dialog,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Add from '@material-ui/icons/Add'
import Close from '@material-ui/icons/Close'
import { ORDER_BY_DIRECTIONS, SCREEN_ROW_TYPES, ScreenRowDocument, ScreenRowType } from '@tivio/firebase'
import {
    BannerItemComponent,
    ItemComponent,
    RowComponent,
    RowFilterCollection,
    RowFilterField,
    RowFilterWhereField,
    RowItemComponent,
    RowOrderByFieldPath,
    RowWhereFilterFieldPath,
    Translation,
} from '@tivio/types'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as Yup from 'yup'

import { TivioRow } from '../../store/TivioRow'
import { enumToOptions } from '../../utils/enum.utils'
import { getInputProps } from '../../utils/formik.utils'
import { defaultTvChannelCollectionWhereFilter, defaultVideosCollectionWhereFilter } from '../../utils/row.utils'
import { CopiableContent } from '../ads/CopiableContent'
import { AppSelect, appSelectConverter } from '../AppSelect'
import { AppThemeProvider } from '../AppThemeProvider'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { Column } from '../uiLayout/Column'

import { RowCondition } from './RowCondition'
import { TranslationForm } from './TranslationForm'


const BANNER_COMPONENT_ITEMS_COUNT = 1

interface RowDialogProps {
    row: TivioRow
    open: boolean
    onClose?: () => void
}

interface ScreenRowForm {
    name: Translation
    rowComponent: RowComponent
    itemComponent: ItemComponent
    description?: Translation
    filter?: RowFilterField,
    type: ScreenRowDocument['type'],
    numberOfLines?: number
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const DialogTitle = styled(Typography)`
    font-weight: bold;
    text-transform: capitalize;
`

const DEFAULT_VALUE_MAP = {
    [RowWhereFilterFieldPath.CREATED]: new Date(),
    [RowWhereFilterFieldPath.TAGS]: [],
    [RowWhereFilterFieldPath.TAG_TYPE_REF]: '',
    [RowWhereFilterFieldPath.TYPE]: '',
    [RowWhereFilterFieldPath.CONTENT_TYPE]: '',
    // TODO this is temporary solution for temporary filter, will be replaced with filter by document ID
    [RowWhereFilterFieldPath.CHANNEL_KEY]: '',
    [RowWhereFilterFieldPath.PUBLISHED_STATUS]: '',
    [RowWhereFilterFieldPath.ORGANIZATION_REF]: '',
    [RowWhereFilterFieldPath.TRANSCODING_STATUS]: '',
}

const RowIdTypography = styled(Typography)`
    cursor: pointer;
    width: 250px;
`

const MIN_NUMBER_OF_LINES = 1
const MAX_NUMBER_OF_LINES = 4

const collectionToTag = (componentName: string) => {
    return componentName.split('/').pop() ?? ''
}

const RowDialog: React.FC<RowDialogProps> = observer((props) => {
    const { row } = props

    const [t] = useTranslation()
    const { showError } = useAlert()
    const validationSchema = useMemo(() => Yup.object().shape({
        filter: Yup.object().shape({
            limit: Yup.number().integer()
                .min(BANNER_COMPONENT_ITEMS_COUNT, t(`Must be equal or greater than ${BANNER_COMPONENT_ITEMS_COUNT}!`))
                .typeError(t('Must be a whole number!')),
        }),
        numberOfLines: Yup.number().min(MIN_NUMBER_OF_LINES).max(MAX_NUMBER_OF_LINES),
    }), [])

    const form = useFormik<ScreenRowForm>({
        initialValues: {
            description: row.descriptionTranslation,
            itemComponent: row.rowItemComponent,
            filter: row.filter,
            name: row.nameTranslation,
            rowComponent: row.rowComponent,
            type: row.type,
            numberOfLines: row.numberOfLines,
        },
        onSubmit: async (values) => {
            const { numberOfLines, ...restFormValues } = values
            try {
                const rowCanHaveMultipleLines = values.itemComponent === RowItemComponent.ROW_ITEM_LANDSCAPE || values.rowComponent === RowComponent.BANNER
                await row.updateAndFetch({
                    ...restFormValues,
                    ...(rowCanHaveMultipleLines && numberOfLines && { numberOfLines }),
                })
                props.onClose?.()
            } catch (e) {
                console.error(e)
            }
        },
        validationSchema,
    })

    const handleUpdateRowType = (rowType: ScreenRowType) => {
        form.setFieldValue('type', rowType)
        // TODO Now we fake data in Favorites and TopWatched where we fill filter which we do not need.
        // It need refactor model of ScreenRow
    }

    const handleUpdateRowItemType = (rowItemType: RowFilterCollection) => {
        let collection: string = rowItemType
        let where: RowFilterWhereField[] = []

        if (rowItemType === RowFilterCollection.TAGS) {
            collection = `${row.organization.path}/tags`
        }

        if (rowItemType === RowFilterCollection.VIDEOS) {
            where = where.concat(defaultVideosCollectionWhereFilter(row.organization.ref))
        }

        if (rowItemType === RowFilterCollection.TV_CHANNELS) {
            where = where.concat(defaultTvChannelCollectionWhereFilter(row.organization.ref))
        }

        form.setFieldValue('filter', {
            collection,
            where: where.filter(whereFilter => whereFilter.fieldPath !== RowWhereFilterFieldPath.TRANSCODING_STATUS),
            orderBy: form.values.filter?.orderBy,
        } as RowFilterField)
    }

    const handleCreateWhereFilter = () => {
        const fieldPath = enumToOptions(RowWhereFilterFieldPath).find(
            fieldPath => {
                return form.values.filter?.where?.every(whereFilter => whereFilter.fieldPath !== fieldPath)
            },
        ) as RowWhereFilterFieldPath | undefined

        if (!fieldPath) {
            showError(t('Maximum amount of conditions reached'))
            return
        }

        form.setFieldValue('filter', {
            ...form.values.filter,
            where: [
                ...form.values.filter?.where ?? [],
                {
                    fieldPath: fieldPath,
                    opStr: '==',
                    value: DEFAULT_VALUE_MAP[fieldPath],
                } as RowFilterWhereField,
            ],
        })

        handleAddWhereFilter({
            fieldPath: fieldPath,
            opStr: '==',
            value: DEFAULT_VALUE_MAP[fieldPath],
        } as RowFilterWhereField)
    }

    const handleAddWhereFilter = (whereFilter: RowFilterWhereField) => {
        form.setFieldValue('filter', {
            ...form.values.filter,
            where: [
                ...form.values.filter?.where ?? [],
                whereFilter,
            ],
        })
    }

    const handleRemoveWhereFilter = (whereFilter: RowFilterWhereField) => {
        form.setFieldValue('filter', {
            ...form.values.filter,
            where: form.values.filter?.where?.filter(filterWhereFilter => filterWhereFilter.fieldPath !== whereFilter.fieldPath),
        })
    }

    const handleFieldPathChange = (oldWhereFilter: RowFilterWhereField, newWhereFilter: RowFilterWhereField) => {
        form.setFieldValue('filter', {
            ...form.values.filter,
            where: form.values.filter?.where
                ?.filter(filterWhereFilter => filterWhereFilter.fieldPath !== oldWhereFilter.fieldPath)
                .concat([newWhereFilter]),
        })
    }

    const handleUpdateWhereFilter = (whereFilter: RowFilterWhereField) => {
        form.setFieldValue('filter', {
            ...form.values.filter,
            where: form.values.filter?.where?.map(
                where => {
                    if (where.fieldPath === whereFilter.fieldPath) {
                        return whereFilter
                    }

                    return where
                },
            ),
        } as RowFilterField)
    }

    useEffect(() => {
        const isBanner = form.values.rowComponent === RowComponent.BANNER

        form.setValues({
            ...form.values,
            itemComponent: isBanner ? BannerItemComponent.CLASSIC : RowItemComponent.ROW_ITEM_LANDSCAPE,
            filter: {
                ...form.values.filter,
                limit: row.filter?.limit ? row.filter.limit : (isBanner ? BANNER_COMPONENT_ITEMS_COUNT : ''),
            } as RowFilterField,
        })
    }, [form.values.rowComponent])

    const handleChangeFilterLimit = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue('filter', {
            ...form.values.filter,
            limit: form.values.rowComponent === RowComponent.BANNER ? BANNER_COMPONENT_ITEMS_COUNT : Number(e.target.value),
        } as RowFilterField)
    }

    const numberOfLinesInputProps = {
        step: 1,
        min: MIN_NUMBER_OF_LINES,
        max: MAX_NUMBER_OF_LINES,
    }

    const handleChangeNumberOfLines = (e: React.ChangeEvent<HTMLInputElement>) => {
        form.setFieldValue('numberOfLines', e.target.valueAsNumber)
    }

    const showCustomRowText = form.values.type === 'custom'
        && (
            form.values.filter?.collection === RowFilterCollection.VIDEOS
            || (form.values.filter && collectionToTag(form.values.filter.collection) === RowFilterCollection.TAGS)
        )
    const isTagsRow = form.values.filter && collectionToTag(form.values.filter.collection) === RowFilterCollection.TAGS
    const showFilter = form.values.type === 'filter'
    const showRowItemType = form.values.type !== 'topWatched'

    return (
        <AppThemeProvider type="light">
            <Dialog
                open={props.open}
                onClose={props.onClose}
            >
                <form onSubmit={form.handleSubmit}>
                    <StyledDialogContent>
                        <CloseIcon onClick={props.onClose}>
                            <Close />
                        </CloseIcon>
                        <Column spacing={4}>
                            <Column spacing={2}>
                                <DialogTitle variant="h6">
                                    {t('Details')}
                                </DialogTitle>
                                <CopiableContent
                                    as={RowIdTypography}
                                    text={row.rowId}
                                />
                                <TranslationForm
                                    label={t('Row Name')}
                                    form={form}
                                    name="name"
                                />
                                <TranslationForm
                                    label={t('Row description')}
                                    form={form}
                                    name="description"
                                />
                            </Column>
                            <Column spacing={2}>
                                <AppSelect
                                    options={appSelectConverter(SCREEN_ROW_TYPES)}
                                    label={t('Row type')}
                                    name="rowType"
                                    onChange={(e) => handleUpdateRowType(e.target.value as ScreenRowType)}
                                    value={form.values.type}
                                />
                                {
                                    showRowItemType && <AppSelect
                                        options={appSelectConverter(enumToOptions(RowFilterCollection))}
                                        label={t('Row item type')}
                                        name="rowItemType"
                                        onChange={(e) => handleUpdateRowItemType(e.target.value as RowFilterCollection)}
                                        value={form.values.filter && collectionToTag(form.values.filter.collection)}
                                    />
                                }
                            </Column>

                            {
                                showRowItemType && <Column spacing={2}>
                                    <DialogTitle variant="h6">
                                        {t('Conditions')}
                                    </DialogTitle>
                                    {
                                        showCustomRowText && (
                                            <Typography>
                                                {isTagsRow ? t('Select tags manually') : t('Select videos manually')}
                                            </Typography>
                                        )
                                    }
                                    {
                                        showFilter && form.values.filter?.where
                                            ?.filter(
                                                whereFilter => !(
                                                    whereFilter.fieldPath === 'organizationRef' as RowWhereFilterFieldPath ||
                                                    whereFilter.fieldPath === 'transcodingStatus' as RowWhereFilterFieldPath ||
                                                    whereFilter.fieldPath === 'publishedStatus' as RowWhereFilterFieldPath
                                                ),
                                            )
                                            ?.map(
                                                whereFilter => {
                                                    return (
                                                        <RowCondition
                                                            filter={form.values.filter}
                                                            key={whereFilter.fieldPath}
                                                            whereFilter={whereFilter}
                                                            onChange={handleUpdateWhereFilter}
                                                            onDelete={handleRemoveWhereFilter}
                                                            onFieldPathChange={handleFieldPathChange}
                                                        />
                                                    )
                                                },
                                            )
                                    }
                                    {showFilter && (
                                        <Button
                                            startIcon={<Add />}
                                            size="small"
                                            onClick={handleCreateWhereFilter}
                                        >
                                            {t('Add condition')}
                                        </Button>
                                    )}
                                </Column>
                            }
                            {showFilter && (
                                <>
                                    <Column spacing={2}>
                                        <DialogTitle variant="h6">
                                            {t('Order')}
                                        </DialogTitle>
                                        <AppSelect
                                            options={appSelectConverter(enumToOptions(RowOrderByFieldPath))}
                                            label={t('Field')}
                                            value={form.values.filter?.orderBy?.fieldPath}
                                            name="fieldPath"
                                            onChange={e => form.setFieldValue('filter', {
                                                ...form.values.filter,
                                                orderBy: {
                                                    ...form.values.filter?.orderBy,
                                                    fieldPath: e.target.value,
                                                },
                                            } as RowFilterField)}
                                        />
                                        <AppSelect
                                            options={appSelectConverter(ORDER_BY_DIRECTIONS)}
                                            label={t('Direction')}
                                            value={form.values.filter?.orderBy?.directionStr}
                                            name="directionStr"
                                            onChange={e => form.setFieldValue('filter', {
                                                ...form.values.filter,
                                                orderBy: {
                                                    ...form.values.filter?.orderBy,
                                                    directionStr: e.target.value,
                                                },
                                            } as RowFilterField)}
                                        />
                                    </Column>
                                    <Column spacing={2}>
                                        <DialogTitle variant="h6">
                                            {t('Limit')}
                                        </DialogTitle>
                                        <TextField
                                            label={t('Row items count limit')}
                                            variant="outlined"
                                            placeholder={t('No limit set')}
                                            size="small"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            {...getInputProps(form, 'filter', 'limit')}
                                            type="number"
                                            onChange={handleChangeFilterLimit}
                                        />
                                    </Column>
                                </>
                            )}
                            <Column spacing={2}>
                                <DialogTitle variant="h6">
                                    {t('Components')}
                                </DialogTitle>
                                <AppSelect
                                    options={appSelectConverter(enumToOptions(RowComponent))}
                                    label={t('Row component')}
                                    value={form.values.rowComponent}
                                    name="rowComponent"
                                    onChange={form.handleChange}
                                />
                                <AppSelect
                                    options={appSelectConverter(enumToOptions(
                                        form.values.rowComponent === RowComponent.ROW ? RowItemComponent : BannerItemComponent,
                                    ))}
                                    label={t('Row item component')}
                                    value={form.values.itemComponent}
                                    name="itemComponent"
                                    onChange={form.handleChange}
                                />
                                {
                                    (form.values.itemComponent === RowItemComponent.ROW_ITEM_LANDSCAPE || form.values.rowComponent === RowComponent.BANNER) && (
                                        <TextField
                                            label={t('Number of lines')}
                                            variant="outlined"
                                            placeholder={t('Number of lines not set')}
                                            size="small"
                                            type="number"
                                            fullWidth
                                            inputProps={numberOfLinesInputProps}
                                            InputLabelProps={{ shrink: true }}
                                            {...getInputProps(form, 'numberOfLines')}
                                            onChange={handleChangeNumberOfLines}
                                        />
                                    )
                                }
                            </Column>
                            <Button
                                fullWidth
                                color="primary"
                                type="submit"
                                variant="contained"
                            >
                                {t('Save')}
                            </Button>
                        </Column>
                    </StyledDialogContent>
                </form>
            </Dialog>
        </AppThemeProvider>
    )
})

export {
    RowDialog,
    DEFAULT_VALUE_MAP,
    DialogTitle,
}
