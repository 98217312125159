import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

import Video from '../../../store/Video'

import { VideoAnalyticsDashboard } from './VideoAnalyticsDashboard'
import { VideoAnalyticsPlayer } from './VideoAnalyticsPlayer'


const useStyles = makeStyles((theme) => ({
    player: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
    },
    dashboard: {
        marginLeft: theme.spacing(6),
        marginRight: theme.spacing(6),
    },
}))

interface Props {
    video: Video
    isLive: boolean
}

export const VideoAnalytics = ({ video }: Props) => {
    const classes = useStyles()

    return (
        <>
            <Box className={classes.player}>
                <VideoAnalyticsPlayer video={video} />
            </Box>
            <Box className={classes.dashboard}>
                <VideoAnalyticsDashboard video={video} />
            </Box>
        </>
    )
}
