import { useT } from '@tivio/common'
import { getSkipButtonData, useAd } from '@tivio/core-react'
import React, { FC } from 'react'
import styled from 'styled-components'

import { SkipButton, SkipButtonWeb } from './SkipButton'


const StyledLabel = styled.div`
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 22px;
`

interface ButtonProps {
    count?: number
    order?: number
    secToSkip: number
    onClick?: () => any
    isSkippable: boolean
    className?: string
}

export const SkipButtonComponent: FC<ButtonProps> = (props) => {
    const t = useT()
    const [ ad ] = useAd()
    const { text, label, enabled } = getSkipButtonData(t, ad)

    return (
        <SkipButton
            enabled={enabled}
            onClick={props.onClick}
            text={text}
        >
            {!enabled && (
                <StyledLabel>{label}</StyledLabel>
            )}
        </SkipButton>
    )
}

const StyledLabelWeb = styled.div`
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 5px;
`

export const SkipButtonComponentWeb: FC<ButtonProps> = (props) => {
    const t = useT()
    const [ ad ] = useAd()
    const { text, label, enabled } = getSkipButtonData(t, ad)

    return (
        <SkipButtonWeb
            enabled={enabled}
            onClick={props.onClick}
            text={text}
            className={props.className}
        >
            {!enabled && (
                <StyledLabelWeb>{label}</StyledLabelWeb>
            )}
        </SkipButtonWeb>
    )
}
