import { makeStyles } from '@material-ui/core'
import { ArticleBlockType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { Article } from '../../../store/Article'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { AddBlockDivider } from '../AddBlockDivider/AddBlockDivider'
import { BlockWrapper } from '../blocks/BlockWrapper'

import type { DropResult } from 'react-beautiful-dnd'


const useStyles = makeStyles((theme) => ({
    container: {
        padding: `${theme.spacing(4)}px 0`,
        maxWidth: 800,
        margin: '0 auto',
    },
}))

interface Props {
    article: Article
}

export const ArticleEditor = observer(({ article }: Props) => {
    const classes = useStyles()
    const { confirmAction } = useConfirmAction()
    const [isDragging, setIsDragging] = useState(false)
    const handleNewBlockAtIndex = (blockType: ArticleBlockType, index: number) => article.addBlockAtIndex(blockType, index)
    const handleRemoveBlockAtIndex = (index: number) => confirmAction(() => article.removeBlockAtIndex(index), 'Are you sure you want to remove this block?')

    const handleDragStart = () => {
        setIsDragging(true)
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        article.switchBlocks(result.source.index, result.destination.index)
        setIsDragging(false)
    }

    const renderBlocks = (startIndex: number, endIndex?: number) => {
        const isLocked = startIndex === 0
        return article.blocks.slice(startIndex, endIndex).map((block, index) => (
            <>
                <BlockWrapper
                    key={block.id}
                    block={block}
                    index={index}
                    isLocked={isLocked}
                    onRemove={() => handleRemoveBlockAtIndex(index)}
                />
                <AddBlockDivider
                    isDisabled={isDragging || index + startIndex < 2}
                    onAddNewBlock={(blockType) => handleNewBlockAtIndex(blockType, isLocked ? 0 : index + 1)}
                />
            </>
        ))
    }

    return (
        <DragDropContext
            onBeforeDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <div className={classes.container}>
                {renderBlocks(0, 3)}
                <Droppable
                    droppableId={article.id}
                    type="BLOCK"
                >
                    {(droppableProvided) => (
                        <div
                            {...droppableProvided.droppableProps}
                            ref={droppableProvided.innerRef}
                        >
                            {renderBlocks(3)}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </DragDropContext>
    )
})
