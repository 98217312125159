import { FirebaseUser, getAuth } from './app'


const firebaseAuth = getAuth()

/**
 * Observes for authorization actions
 */
const observeAuthorizationActions = (setAuthorized: (authorized: boolean) => void) => {
    return firebaseAuth.onAuthStateChanged((user) => {
        if (user) {
            return setAuthorized(true)
        }

        return setAuthorized(false)
    })
}

/**
 * Get authorization status on applications start
 */
const authorizeUserOnStart = async (): Promise<FirebaseUser | null> => {
    return new Promise((resolve) => {
        firebaseAuth.onAuthStateChanged((user) => {
            resolve(user)
        })
    })
}

/**
 * Check if user is logged in
 */
const isAuthorized = (): boolean => {
    return !!firebaseAuth.currentUser
}

/**
 * Get current user
 */
const getCurrentUser = (): FirebaseUser => {
    const { currentUser } = firebaseAuth

    if (!currentUser) {
        throw new Error('Failed to get user')
    }

    return currentUser
}

export {
    authorizeUserOnStart,
    observeAuthorizationActions,
    isAuthorized,
    getCurrentUser,
}
