import {
    Button,
    Dialog,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { PurchaseStatus } from '@tivio/types'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { getCurrentUser } from '../../../firebase/auth'
import { createLogger } from '../../../logger'
import User from '../../../store/User'
import { AppThemeProvider } from '../../AppThemeProvider'
import { useLoading } from '../../hooks/general'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { Column } from '../../uiLayout/Column'
import { PurchaseStatusDescription } from '../UserPurchases'

import { MonetizationAutocomplete } from './MonetizationAutocomplete'
import { PurchaseDateTimePicker } from './PurchaseDateTimePicker'
import { CreatePurchaseForm } from './types'
import { createPurchase } from './util'
import { VideoAutocomplete } from './VideoAutocomplete'

import type { FormikProps } from 'formik'


const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const DialogTitle = styled(Typography)`
    font-weight: bold;
`

const StyledSelect = styled(Select)`
    width: 100%;
`

const logger = createLogger('CreatePurchaseDialog')

interface Props {
    user: User
    onClose: () => void,
    purchaseStatusDescriptions: PurchaseStatusDescription[]
}

export const CreatePurchaseDialog: React.FC<Props> = ({
    user,
    onClose,
    purchaseStatusDescriptions,
}) => {
    const [ t ] = useTranslation()
    const { showSuccess, showError } = useAlert()
    const { loading: isSubmitting, startLoading: startSubmitting, stopLoading: stopSubmitting } = useLoading()
    const onSubmit = useCallback(async (values: CreatePurchaseForm) => {
        const currentUserEmail = getCurrentUser().email
        const isSubscription = values.monetization?.getType === 'subscription'

        if (!values.monetization || !currentUserEmail) {
            return showError('Failed to add purchase. No monetization selected or user not logged in.')
        }

        startSubmitting()
        await createPurchase({
            userId: user.id,
            status: values.status,
            monetization: values.monetization,
            videoRef: values.video?.ref,
            createdBy: currentUserEmail,
            startDate: isSubscription ? values.startDate : null,
            endDate: isSubscription ? values.endDate : null,
        })
            .catch(logger.error)

        await user
            .loadPurchases()
            .finally(stopSubmitting)

        showSuccess(t('Purchase created'))
        onClose()
    }, [onClose, startSubmitting, stopSubmitting, user])

    const formik: FormikProps<CreatePurchaseForm> = useFormik<CreatePurchaseForm>({
        validate: (values) => {
            const errors: Partial<Record<keyof CreatePurchaseForm, string>> = {}
            const isSubscription = values.monetization?.getType === 'subscription'
            const isTransaction = values.monetization?.getType === 'transaction'

            if (!values.monetization) {
                errors.monetization = t('Monetization required')
            }
            if (!values.status) {
                errors.status = t('Status required')
            }
            if (isTransaction && !values.video) {
                errors.video = t('Video required')
            }
            if (isSubscription && values.startDate > values.endDate) {
                errors.endDate = t('Please choose time in future')
            }
            if (isSubscription && !values.endDate) {
                errors.endDate = t('Date required')
            }
            if (isSubscription && !values.startDate) {
                errors.startDate = t('Date required')
            }

            return errors
        },
        validateOnChange: false,
        initialValues: {
            status: PurchaseStatus.PAID,
            monetization: null,
            startDate: dayjs().toDate(),
            endDate: dayjs().add(1, 'day').toDate(),
            video: null,
        },
        onSubmit,
    })

    const statuses = useMemo(() => {
        const items = purchaseStatusDescriptions.map(p => {
            return <MenuItem
                key={p.status}
                value={p.status}
            >
                {p.label}
            </MenuItem>
        })

        return (
            <>
                <InputLabel id="status-label-input">{t('Purchase status')}</InputLabel>
                <StyledSelect
                    required
                    labelId="status-label-input"
                    autoWidth
                    label={t('Purchase status')}
                    defaultValue={PurchaseStatus.PAID}
                    {...formik.getFieldProps('status')}
                >
                    {items}
                </StyledSelect>
            </>
        )
    }, [formik, purchaseStatusDescriptions, t])

    const fields = useMemo(() => {
        switch (formik.values.monetization?.getType) {
            case 'transaction':
                return <VideoAutocomplete
                    key={formik.values.monetization.getRef.id}
                    formik={formik}
                />
            case 'subscription':
                return <PurchaseDateTimePicker formik={formik} />
        }

        return null
    }, [formik])

    return (
        <AppThemeProvider type="light">
            <Dialog
                open={true}
                onClose={onClose}
            >
                <StyledDialogContent>
                    <CloseIcon onClick={onClose}>
                        <Close/>
                    </CloseIcon>
                    <form onSubmit={formik.handleSubmit}>
                        <Column spacing={4}>
                            <DialogTitle variant="h6">
                                {t('Add purchase')}
                            </DialogTitle>
                            <MonetizationAutocomplete
                                formik={formik}
                            />
                            {fields}
                            {statuses}
                            <Button
                                fullWidth
                                color="primary"
                                type="submit"
                                variant="contained"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? t('Loading...') : t('Add')}
                            </Button>
                        </Column>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
}

