import { Container, Divider } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'

import { TivioNotification } from '../../../store/Notification'
import { Template } from '../../../store/Template'

import { EmailInfo } from './info/EmailInfo'
import { Targeting } from './targeting/Targeting'
import { TargetingFilters } from './targetingFilters/TargetingFilters'
import { TemplatePicker } from './TemplatePicker'


interface Props {
    templates: Template[]
    notification: TivioNotification
}

export const NotificationSettings = observer(({ templates, notification }: Props) => {
    const disabled = !templates.length
    return (
        <Container maxWidth="lg">
            <TemplatePicker
                templates={templates}
                notification={notification}
            />
            <Divider />
            <EmailInfo
                notification={notification}
                disabled={disabled}
            />
            {notification.triggerType !== 'manual' && notification.triggerType !== 'scheduled' ? (
                <Targeting
                    notification={notification}
                    disabled={disabled}
                />
            ) : (
                <TargetingFilters notification={notification} />
            )}
        </Container>
    )
})
