import { User, UserAuthStatus } from '@tivio/core-js'
import { useContext } from 'react'

import { UserContext } from '../context'


// TODO This hook is only for hotfix, normally useUser hook should be used, but we need fix it by right way (see https://jira.nangu.tv/browse/TIV-2649)
export const useUserContext = () => {
    const { user } = useContext(UserContext)

    return {
        user,
        // we want to receive actual value after each change in UserContext
        isSignedIn: User.getUserAuthStatus() === UserAuthStatus.SIGNED_IN && !User.currentUser?.isAnonymous,
    }
}
