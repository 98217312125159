import { EventEmitter } from '@fingerartur/ts-event-emitter'
import { Logger } from '@tivio/common'
import {
    PlayerEngineConfig,
    PlayerEngineEvent,
    PlayerEngineInterface,
    PlayerEngineListener,
    PlayerEngineOptions,
    PlayerState,
} from '@tivio/types'

import { isHtmlEvent } from './utils'


const logger = new Logger('BaseEngine')

/**
 * Custom debounce function because debounce from lodash does not work on tv-web
 */
function debounce(functionToCall: Function, timeout = 400) {
    let timerId: NodeJS.Timeout
    return (...args: any) => {
        clearTimeout(timerId)
        timerId = setTimeout(() => functionToCall(...args), timeout)
    }
}

const eventTransforms: { [key in PlayerEngineEvent]?: Function } = {
    'durationchange': (e: any, videoElement: HTMLVideoElement) => videoElement.duration * 1000,
    'fullscreenchange': () => !!document.fullscreenElement,
    'volumechange': (e: any, videoElement: HTMLVideoElement) => ({ muted: videoElement.muted, volume: videoElement.volume }),
}

export class BaseEngine extends EventEmitter<PlayerEngineEvent> implements PlayerEngineInterface {
    protected modifiedListenersMap = new Map<Function, Function>()
    private localListeners: {
        event: keyof HTMLMediaElementEventMap | 'webkitendfullscreen'
        listener: EventListenerOrEventListenerObject
    }[] = []
    protected _seekTo = (ms: number) => {
        this._seekToMs(ms)
    }
    private state: PlayerState = 'idle'
    private readonly fullScreenListener: () => void
    /**
     * Promise from this.videoElement.play() call.
     *
     * Used to prevent https://developer.chrome.com/blog/play-request-was-interrupted on chrome and
     * analogous error on Safari - "AbortError: The operation was aborted".
     */
    private playPromise: Promise<void> | null = null

    constructor(
        protected readonly videoElement: HTMLVideoElement,
        private options?: PlayerEngineOptions,
    ) {
        super()
        this.configureSeeking()

        this.localListeners = [
            {
                event: 'play',
                listener: () => {
                    this.changeState('playing')
                },
            },
            {
                event: 'playing',
                listener: () => {
                    this.changeState('playing')
                },
            },
            {
                event: 'pause',
                listener: () => {
                    this.changeState('paused')
                },
            },
            {
                // Note: at the end of playback the player pauses and then `ended` is fired
                event: 'ended',
                listener: () => {
                    this.changeState('idle')
                },
            },
            {
                // iOS support
                event: 'webkitendfullscreen',
                listener: () => {
                    this.triggerEvent('togglefullscreen', false)
                },
            },
        ]

        this.localListeners.forEach(({ event, listener }) => {
            this.videoElement.addEventListener(event, listener)
        })

        this.fullScreenListener = () => {
            const isFull = document.fullscreenElement === this.videoElement
            this.triggerEvent('togglefullscreen', isFull)
        }

        document.addEventListener('fullscreenchange', this.fullScreenListener)
    }

    get lastQuality(): number | null {
        return null
    }

    get isSeeking(): boolean {
        return this.videoElement.seeking
    }

    getState(): PlayerState {
        return this.state
    }

    isIdle(): boolean {
        return this.state === 'idle'
    }

    mute() {
        this.videoElement.muted = true
    }

    unmute() {
        this.videoElement.muted = false
    }

    async load(config: PlayerEngineConfig) {
        this.resetState()

        this.videoElement.src = config.url
        await this.pause()
    }

    async play(config: PlayerEngineConfig) {
        this.resetState()

        this.videoElement.src = config.url
        await this.unpause()
    }

    async pause() {
        this.triggerEvent('busychange', true)

        try {
            await this.playPromise
        } catch (error) {
            // Can happen when seeking after autoplay fail - in this case play is not called again so playPromise is still rejected
            // from the initial autoplay attempt.
            logger.info('Ignoring rejected play promise and pausing anyway.')
        }
        this.videoElement.pause()

        this.triggerEvent('busychange', false)
    }

    async unpause() {
        this.triggerEvent('busychange', true)

        this.playPromise = this.videoElement.play()
        await this.playPromise

        this.triggerEvent('busychange', false)
    }

    async stop() { }

    addEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        if (isHtmlEvent(event)) {
            this.addHtmlEventListener(event, callback)
        } else {
            super.addEventListener(event, callback)
        }
    }

    removeEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        if (isHtmlEvent(event)) {
            this.removeHtmlEventListener(event, callback)
        } else {
            super.removeEventListener(event, callback)
        }
    }

    /**
     * @throws when video does not support fullscreen on iOS
     * see https://developer.apple.com/documentation/webkitjs/htmlvideoelement/1628805-webkitsupportsfullscreen
     */
    goFullScreen() {
        /**
         * iOS support
         */
        // @ts-expect-error
        if (this.videoElement.webkitEnterFullscreen) {
            // @ts-expect-error
            this.videoElement.webkitEnterFullscreen()
            this.triggerEvent('togglefullscreen', true)

            return Promise.resolve()
        }

        return this.videoElement.requestFullscreen()
    }

    /**
     * @param {number} value in [0,1]
     */
    changeVolume(value: number) {
        if (value > 1 || value < 0) {
            throw new Error(`volume value invalid "${value}"`)
        }

        this.videoElement.volume = value
    }

    async destroy() {
        this.resetState()

        this.localListeners.forEach(({ event, listener }) => {
            this.videoElement.removeEventListener(event, listener)
        })
        this.localListeners = []

        document.removeEventListener('fullscreenchange', this.fullScreenListener)
    }

    getDuration() {
        return this.videoElement.duration
    }

    getDurationMs() {
        return this.videoElement.duration * 1000
    }

    getVolume() {
        return this.videoElement.volume
    }

    isMuted() {
        return this.videoElement.muted || this.videoElement.volume === 0
    }

    seekTo(ms: number) {
        this._seekTo(ms)
    }

    isPaused() {
        return this.videoElement.paused
    }

    getVideoElement() {
        return this.videoElement
    }

    setPlaysInline(value: boolean) {
        this.videoElement.playsInline = value
    }

    setControlsList(value: string[]) {
        if ((this.videoElement as any).controlsList) {
            (this.videoElement as any).value = value.join(' ')
        }
    }

    private addHtmlEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        let _callback = callback
        const transform = eventTransforms[event]

        if (event === 'timeupdate') {
            _callback = () => {
                const ms = this.videoElement.currentTime * 1000

                callback(ms as any)
            }
        } else if (transform) {
            _callback = (event) => callback(transform(event, this.videoElement))
        }

        if (_callback !== callback) {
            this.modifiedListenersMap.set(callback, _callback)
        }

        this.videoElement.addEventListener(event, _callback as any)
    }

    private removeHtmlEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        let _callback = this.modifiedListenersMap.get(callback)
        if (_callback == null) {
            _callback = callback
        }

        this.videoElement.removeEventListener(event, _callback as any)
    }

    private configureSeeking() {
        const seekDebounceMs = this.options?.seekDebounceMs
        if (seekDebounceMs != null && seekDebounceMs > 0) {
            this._seekTo = debounce((ms: number) => {
                this._seekToMs(ms)
            }, seekDebounceMs)
        }
    }

    protected changeState(state: PlayerState) {
        if (this.state === state) {
            return
        }

        this.state = state
        this.triggerEvent('statechange', state)
    }

    protected resetState() {
        this.changeState('idle')
    }

    protected addCustomEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        super.addEventListener(event, callback)
    }

    protected removeCustomEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        super.removeEventListener(event, callback)
    }

    protected _seekToMs(ms: number) {
        this.videoElement.currentTime = ms / 1000
    }
}
