import { merge } from '@fingerartur/ts-merge-objects'
import { createTheme, ThemeOptions } from '@material-ui/core'

import { COLORS } from './enum'


const common: ThemeOptions = {
    palette: {
        primary: {
            main: COLORS.PRIMARY,
        },
        background: {
            default: COLORS.BACKGROUND,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1380,
            xl: 1664,
        },
    },
    typography: {
        htmlFontSize: 16,
        fontSize: 14, // TODO: should be 16 according to Zeplin
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: '.85em',
                textAlign: 'center',
            },
        },
    },
}

const lightTheme = createTheme(common)

const darkThemeOptions = merge({
    palette: {
        type: 'dark',
        text: {
            primary: 'rgba(255, 255, 255, 0.87)',
            secondary: 'rgba(255, 255, 255, 0.45)',
        },
    },
}, common) as ThemeOptions

const darkTheme = createTheme(darkThemeOptions)

export {
    lightTheme,
    darkTheme,
}
