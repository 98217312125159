import Button from '@material-ui/core/Button'
import React from 'react'
import styled from 'styled-components'


const StyledSubmitButton = styled(Button)`
    font-weight: 600;
    margin-top: 30px;
`

interface Props {
    children: React.ReactNode
    onClick?: () => void
    // MUI Button props
    [key: string]: any
}

export const SubmitButton = ({ children, onClick, ...buttonProps }: Props) => {
    return (
        <StyledSubmitButton
            variant="contained"
            color="primary"
            fullWidth
            onClick={onClick}
            {...buttonProps}
        >
            {children}
        </StyledSubmitButton>
    )
}
