import {
    Box,
    Fade,
    Grid,
    IconButton,
    Switch,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/icons/Menu'
import React, { ChangeEvent } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'

import { onDragEndUtil } from '../../utils/order.utils'


export type Provider = {
    id: string
    name: string
    selected: boolean
    development?: boolean
}

const useStyles = makeStyles(() => ({
    providerName: {
        fontSize: '1rem',
    },
    providerNameDev: {
        fontSize: '1rem',
        color: '#f5f58b',
        opacity: 0.8,
    },
}))

export type AdvertisementProvidersProps = {
    providers: Provider[]
    onEdit: (providers: Provider[]) => Promise<void> | void
}

const AdsProviders: React.FC<AdvertisementProvidersProps> = ({ providers, onEdit }) => {
    const classes = useStyles()
    const [t] = useTranslation()

    const handleToggle = (provider: Provider) => (_e: ChangeEvent<HTMLInputElement>, value: boolean) => {
        const providerId = provider.id

        const indexOfChangedProvider = providers.findIndex((provider) => provider.id === providerId)
        const changedProviders = [...providers]
        changedProviders[indexOfChangedProvider] = {
            ...providers[indexOfChangedProvider],
            selected: value,
        }
        onEdit(changedProviders)
    }

    const onDragEnd = async (result: DropResult) => {
        const sortedProviders = onDragEndUtil(result, providers)
        if (sortedProviders) {
            onEdit(sortedProviders)
        }
    }

    const renderProvidersRows = () => providers.map((provider, index) => {
        const {id, name, selected, development } = provider

        return (
            <Draggable
                draggableId={id}
                key={id}
                index={index}
            >
                {provided => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <Fade in={true}>
                            <Box
                                pt={0}
                                pb={0}
                            >
                                <Grid
                                    container
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Grid item>
                                        <IconButton>
                                            <Menu/>
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs>
                                        <Box
                                            my={1}
                                            title={
                                                development ? ('Used only for development/demo purposes,'
                                                + ' only available for super-admin') : undefined
                                            }
                                        >
                                            <Typography
                                                className={development ? classes.providerNameDev : classes.providerName}
                                            >
                                                {name} {development && '(dev)'}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Grid
                                            container
                                            direction="row"
                                            spacing={2}
                                        >
                                            <Grid item>
                                                <Switch
                                                    size="small"
                                                    checked={selected}
                                                    onChange={handleToggle(provider)}
                                                    color="primary"
                                                    name="selectedProvider"
                                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Fade>
                    </div>
                )}
            </Draggable>
        )
    })

    const renderProvidersList = () => {
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="providers">
                    {provided => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {renderProvidersRows()}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    return (
        <>
            <Typography variant="h6">
                {t('Advertisement providers')}
            </Typography>
            <Grid
                container
                direction="column"
            >
                {renderProvidersList()}
            </Grid>
        </>
    )
}

export default AdsProviders
