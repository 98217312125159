import { Divider, makeStyles } from '@material-ui/core'
import React from 'react'

import { COLORS } from '../../static/enum'


const useStyles = makeStyles({
    divider: {
        backgroundColor: COLORS.DRAWER_BORDER,
    },
})

const MenuDivider = () => {
    const classes = useStyles()

    return (
        <Divider className={classes.divider}/>
    )
}

export default MenuDivider
