import { tivio } from '@tivio/core-js'
import { QerkoCancellationInfo, UseCancelSubscription } from '@tivio/types'
import { useCallback, useState } from 'react'


export const useCancelSubscription: UseCancelSubscription = (subscriptionId: string) => {
    const [cancellationInfo, setCancellationInfo] = useState<QerkoCancellationInfo | null>(null)
    const [error, setError] = useState<string | null>(null)

    const cancelSubscription = useCallback(async () => {
        setError(null)

        if (!tivio.cancelSubscriptionWithQerko) {
            throw new Error('cancelSubscriptionWithQerko is not in bundle')
        }

        try {
            const result = await tivio.cancelSubscriptionWithQerko(subscriptionId)
            setCancellationInfo(result)
        } catch (e) {
            if (e instanceof Error) {
                setError(e.message)
            }
        }

    }, [subscriptionId])

    return {
        cancelSubscription,
        cancellationInfo,
        error,
    }
}
