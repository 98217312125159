import { Checkbox, FormControlLabel, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MonetizationRecoveryField } from '@tivio/firebase'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { Column } from '../uiLayout/Column'


const useStyles = makeStyles(() => ({
    inputWide: {
        width: '42rem',
    },
}))

interface Props {
    recovery?: MonetizationRecoveryField
    handleChangeRecoveryPaymentUrl: (e: React.ChangeEvent<HTMLInputElement>) => void
    updateManualActivationRecoveryCallback: (checked: boolean) => void
}

export const RecoveryPaymentUrl: React.FC<Props> = (props) => {
    const { recovery, handleChangeRecoveryPaymentUrl, updateManualActivationRecoveryCallback } = props
    const classes = useStyles()
    const [t] = useTranslation()
    const { confirmAction } = useConfirmAction()

    const handleManualActivationRecovery = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        const updateCallback = () => updateManualActivationRecoveryCallback(checked)

        if (checked) {
            confirmAction(
                updateCallback,
                t('This action changes user purchase flow. If provided URL is incorrect, users will not be able to make purchases. Are you sure?'),
            )
        } else {
            updateCallback()
        }
    }

    return (
        <Column spacing={1}>
            <Typography variant="h6">
                {t('Recovery')}
            </Typography>

            <Typography
                component="p"
                color="textSecondary"
            >
                {t('In case of a default payment gateway outage (Qerko), users will be redirected to the given URL to make their payments there.')}
            </Typography>

            <TextField
                value={recovery?.recoveryPaymentGatewayLink ?? ''}
                className={classes.inputWide}
                label={t('Recovery Payment URL')}
                variant="outlined"
                margin="dense"
                onChange={handleChangeRecoveryPaymentUrl}
            />

            {recovery?.recoveryPaymentGatewayLink && (
                <>
                    <Typography
                        component="p"
                        color="textSecondary"
                    >
                        {t('If checked, users will be redirected to the given URL by default, regardless if there is default payment gateway outage. Use with caution.')}
                    </Typography>
                    <FormControlLabel
                        label={t('Manual Activation')}
                        control={
                            <Checkbox
                                checked={recovery?.manualActivation}
                                onChange={handleManualActivationRecovery}
                                name="enabled"
                                color="primary"
                            />
                        }
                    />
                </>
            )}
        </Column>
    )
}
