import { tivio } from '@tivio/core-js'
import { BannerAdVariantField, DisplayAdMonetizationField, MonetizationDocument } from '@tivio/firebase'
import { useEffect, useState } from 'react'


interface BannerAdverts {
    monetization: MonetizationDocument | null
    loading: boolean
    error: Error | null
    displayAd: BannerAdVariantField | undefined
}

interface Props {
    rowMonetizationPath?: string
    windowWidth: number
}

export const useBannerAdverts = ({ rowMonetizationPath, windowWidth }: Props): BannerAdverts => {
    const [monetization, setMonetization] = useState<MonetizationDocument | null>(null)
    const [deviceType, setDeviceType] = useState<BannerAdVariantField>()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (rowMonetizationPath) {
                    const monetization = await tivio.getMonetizationByPath(rowMonetizationPath)
                    if (monetization?.type === 'display') {
                        const variants = monetization?.variants as DisplayAdMonetizationField[]
                        const variant = variants?.find(v => v?.type === 'default')
                        const deviceType = variant?.deviceType[windowWidth < 479 ? 'mobile' : 'desktop']
                        setMonetization(monetization)
                        setDeviceType(deviceType)
                    }
                    setLoading(false)
                } else {
                    throw new Error('Row monetization path is not provided.')
                }
            } catch (error) {
                setError(error)
            }
        }
        fetchData()
    }, [rowMonetizationPath, windowWidth])

    return { monetization, loading, error, displayAd: deviceType }
}
