(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("@tivio/core-js"), require("@tivio/common"), require("mobx"), require("mobx-react"));
	else if(typeof define === 'function' && define.amd)
		define("library", ["react", "@tivio/core-js", "@tivio/common", "mobx", "mobx-react"], factory);
	else if(typeof exports === 'object')
		exports["library"] = factory(require("react"), require("@tivio/core-js"), require("@tivio/common"), require("mobx"), require("mobx-react"));
	else
		root["library"] = factory(root["react"], root["@tivio/core-js"], root["@tivio/common"], root["mobx"], root["mobx-react"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__4269__, __WEBPACK_EXTERNAL_MODULE__2169__, __WEBPACK_EXTERNAL_MODULE__5314__, __WEBPACK_EXTERNAL_MODULE__7707__) {
return 