import React, { memo } from 'react'
import styled from 'styled-components'

import type { Node } from 'reactflow'


const Node = styled.div`
  color:#fff;
`
const ColumnName = styled.span`
    font-size: 16px;
`

export default memo(({ data }: Node) => {
    return (
        <Node>
            <ColumnName>{data.label}</ColumnName>
        </Node>
    )
})
