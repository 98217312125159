import { IconButton } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'


const AddButton = styled(IconButton)`
    width: 32px;
    height: 32px;
    background-color: ${COLORS.PRIMARY};

    &:hover {
        background-color: ${COLORS.PRIMARY_HOVER};
    }
`

type Props = {
    onClick: () => void
}

/**
 * Button for adding schedule item.
 */
export const AddScheduleItemButton = ({ onClick }: Props) => {
    return (
        <AddButton onClick={onClick}>
            <Add/>
        </AddButton>
    )
}
