import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Delete from '@material-ui/icons/Delete'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory } from 'react-router-dom'

import { LinkedVideo } from '../../store/Video'
import { useStore } from '../hooks/dataHooks/useStore'

import { BlankVideoCover, VideoCover } from './VideoCover'


interface Props {
    linkedVideo: LinkedVideo
    onRemove?: (videoId: string) => void
    disabled: boolean
}

const useStyles = makeStyles((theme) => ({
    infoCol: {
        flex: '1 1 auto',
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    videoLinkItem: {
        marginBottom: theme.spacing(2),
    },
    videoLinkItemHover: {
        cursor: 'pointer',
        borderRadius: '8px',
        '&:hover': {
            transition: 'background-color 200ms ease-in-out',
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    disabledVideoLinkItem: {
        cursor: 'default',
    },
}))

export const VideoLinkItem: React.FC<Props> = observer(({ linkedVideo, onRemove, disabled }) => {
    if (typeof linkedVideo === 'undefined') {
        console.error('VideoLinkedVideosField: video is undefined.')
        return null
    }

    const { store } = useStore()
    const [t] = useTranslation()
    const classes = useStyles()
    const { push } = useHistory()
    const video = linkedVideo.video

    const handleLinkRemove = (videoId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        if (onRemove) {
            onRemove(videoId)
        }
    }

    const handleLinkClick = () => {
        if (video?.getId) {
            push(`/videos/${video?.getId}`)
        }
    }

    const getVideoType = () => {
        if (linkedVideo.type === 'CHILD') {
            return 'Parent video'
        }
        if (linkedVideo.type === 'VIRTUAL_PROGRAM') {
            return linkedVideo.video?.tags.find(tag => store.globalDistributionTags.some(globalTag => globalTag.tagId === tag.tagId))?.getName
        }
        return linkedVideo.type.toLowerCase()
    }

    return (
        <Grid
            container
            spacing={2}
            alignItems="center"
            className={clsx(classes.videoLinkItem, video?.getId && classes.videoLinkItemHover, disabled && classes.disabledVideoLinkItem)}
            onClick={!disabled ? handleLinkClick : undefined}
        >
            <Grid item>{video?.getCover ? <VideoCover cover={video?.getCover} /> : <BlankVideoCover />}</Grid>
            <Grid
                item
                xs
            >
                {video?.getName ? (
                    <Typography variant="h6">{video?.getName}</Typography>
                ) : (
                    <Grid
                        container
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <Typography
                                variant="body2"
                                color="textSecondary"
                            >
                                {t('This linked video has been deleted.')}
                            </Typography>
                        </Grid>
                        {video === null && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={handleLinkRemove(linkedVideo.id)}
                                >
                                    {t('Remove this link manually')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                )}
                {video && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                    >
                        <Grid
                            container
                            spacing={2}
                        >
                            {typeof video.getDuration === 'number' && (
                                <Grid item>
                                    {Math.round(video.getDuration / 1000)} {t('sec')}
                                </Grid>
                            )}
                        </Grid>
                    </Typography>
                )}
            </Grid>
            {video && (
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                    >
                        <Grid item>
                            <span className={classes.capitalize}>
                                {getVideoType()}
                            </span>
                        </Grid>
                        {onRemove && linkedVideo.type !== 'CHILD' && linkedVideo.type !== 'VIRTUAL_PROGRAM' && !disabled && (
                            <Grid item>
                                <IconButton
                                    size="small"
                                    onClick={handleLinkRemove(linkedVideo.id)}
                                    title={t('Remove link to another video')}
                                >
                                    <Delete fontSize="small" />
                                </IconButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
})
