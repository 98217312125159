import { Chip } from '@material-ui/core'
import { capitalizeText } from '@tivio/common'
import { NotificationStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'

import { TivioNotification } from '../../../store/Notification'


const getBackgroundColor = ({ status }: { status: NotificationStatus }): 'primary' | 'default' | 'secondary' => {
    switch (status) {
        case NotificationStatus.PLANNED:
            return 'secondary'
        case NotificationStatus.AUTOMATIC:
        case NotificationStatus.RUNNING:
            return 'primary'
        case NotificationStatus.SENT:
        case NotificationStatus.DRAFT:
        case NotificationStatus.WAITING:
            return 'default'
    }
    return 'default'
}

interface Props {
    notification: TivioNotification
}

export const NotificationStatusBadge = observer(({ notification }: Props) => {
    return <Chip
        size="small"
        label={capitalizeText(notification.status)}
        color={getBackgroundColor(notification)}
    />
})
