import { TextField } from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select/Select'
import { makeStyles } from '@material-ui/core/styles'
import { VideoSourceField } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'


const useStyles = makeStyles(() => ({
    select: {
        padding: '6.5px',
    },
    outlined: {
        paddingRight: '56px !important',
    },
}))

interface Props {
    selectedSource?: VideoSourceField
    groupedSources: Record<string, VideoSourceField[]>
    onChange: (source?: VideoSourceField) => void
    variant: SelectProps['variant'],
    withEmptyValue?: boolean
    disableUnderline?: boolean
}

export const SourceSelect = ({ selectedSource, onChange, variant, withEmptyValue, disableUnderline, groupedSources }: Props) => {
    const [t] = useTranslation()
    const classes = useStyles()

    const groupedItems = Object.entries(groupedSources).map(([category, sources]) => {
        const items = sources.map((source, index) => {
            const languages = source.languages ?? []
            const protocol = source.protocol
            const encryption = source.encryption
            const meta = [...languages, protocol, encryption]?.join(', ')
            const stringifiedSource = JSON.stringify(source)

            return (
                <option
                    key={stringifiedSource}
                    value={stringifiedSource}
                >
                    Stream {index} {meta && `(${meta})`}
                </option>
            )
        })

        return (
            <optgroup label={category}>
                {items}
            </optgroup>
        )
    })

    withEmptyValue && groupedItems.unshift(
        <option
            key="empty"
            value=""
        >{t('All sources')}</option>,
    )

    const onSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        if (!event.target.value) {
            return onChange(undefined)
        }

        const source = JSON.parse(event.target.value as string) as VideoSourceField
        const selected = Object.values(groupedSources)
            .reduce((acc, curr) => [...(acc ?? []), ...curr ?? []], [])
            .find((s) => s.url === source.url
            && s.protocol === source.protocol
            && s.encryption === source.encryption)

        onChange(selected)
    }

    const inputProps: any = variant === 'standard' ? { disableUnderline } : undefined

    return (
        <>
            <TextField
                select
                SelectProps={{ native: true, displayEmpty: true, classes: { root: classes.select } }}
                fullWidth
                placeholder="Select a source"
                value={selectedSource ? JSON.stringify(selectedSource) : ''}
                onChange={onSelect}
                variant={variant}
                required
                InputProps={inputProps}
            >
                {groupedItems}
            </TextField>
        </>
    )
}
