import { Button, IconButton, makeStyles, Typography } from '@material-ui/core'
import Menu from '@material-ui/icons/Menu'
import { Skeleton } from '@material-ui/lab'
import { BannerItemComponent, RowComponent } from '@tivio/types'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Article } from '../../store/Article'
import { Tag } from '../../store/Tag'
import { TivioRow } from '../../store/TivioRow'
import TvChannel from '../../store/TvChannel'
import Video from '../../store/Video'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { Row } from '../uiLayout/Row'
import UpdatableTypography from '../UpdatableTypography'

import { Banner } from './Banner'
import { Carousel } from './Carousel'
import { DraggableCarousel } from './DraggableCarousel'
import { RowDialog } from './RowDialog'

import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'


const TypographyStyled = styled(Typography)`
    margin-left: 48px;
`

const EmptyRowSkeleton = styled(Skeleton)`
    padding: 2em;
    border-radius: 10px;
`

interface Props {
    row: TivioRow
    dragHandle?: DraggableProvidedDragHandleProps
}

const useStyles = makeStyles(() => ({
    rowContainer: {
        margin: '1.5rem 0',
    },
    rowContainerLoading: {
        opacity: 0.8,
        position: 'relative',
        '&::before': {
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            content: '""',
            zIndex: 1,
        },
    },

}))

const ScreenRow: React.FC<Props> = observer((props) => {
    const { row, dragHandle } = props
    const [t] = useTranslation()
    const { closeDialog, isDialogOpen, openDialog } = useDialog()

    const items = (() => {
        switch (row.type) {
            // CUSTOM ROWS
            case 'custom': {
                return row.customItems
            }
            // TOP WATCHED videos in last 7 days from organization analytics
            case 'topWatched': {
                return row.topWatchedVideos
            }
            // FILTER ROWS
            default: {
                return row.itemsPagination?.items ?? []
            }
        }
    })()

    const [loading, setLoading] = useState(false)
    const { showError } = useAlert()
    const classes = useStyles(loading)
    const isBanner = row.rowComponent === RowComponent.BANNER

    useEffect(
        () => {
            if (row.isNew) {
                openDialog()
                row.isNew = false
            }
        },
        [row],
    )

    const handleItemsChange = async (items: (Video | Tag | Article)[]) => {
        setLoading(true)
        try {
            await row.setCustomItems(items)
        } catch (e) {
            showError(t('An error occurred while updating the row.'))
        } finally {
            setLoading(false)
        }
    }

    const handleItemRemove = async (itemPath: string) => {
        setLoading(true)
        try {
            await row.removeCustomItem(itemPath)
        } catch (e) {
            showError(t('An error occurred while updating the row.'))
        } finally {
            setLoading(false)
        }
    }

    const handleItemAdd = async (itemPath: string) => {
        setLoading(true)
        try {
            await row.addCustomItem(itemPath)
        } catch (e) {
            showError(t('An error occurred while updating the row.'))
        } finally {
            setLoading(false)
        }
    }

    return (
        <>
            {isDialogOpen && (
                <RowDialog
                    row={row}
                    open={isDialogOpen}
                    onClose={closeDialog}
                />
            )}
            <div className={clsx(classes.rowContainer, { [classes.rowContainerLoading]: loading })}>
                <div>
                    <Row rowProps={{ justify: 'space-between', alignItems: 'center' }}>
                        <Row rowProps={{ alignItems: 'center' }}>
                            <IconButton {...dragHandle}>
                                <Menu color="disabled" />
                            </IconButton>
                            <UpdatableTypography
                                onDelete={row.delete}
                                typographyProps={{
                                    variant: 'h5',
                                }}
                            >
                                {row.nameTranslation.en ?? t('No name')}
                            </UpdatableTypography>
                        </Row>
                        <Button onClick={openDialog}>
                            {t('Edit row')}
                        </Button>
                    </Row>
                </div>
                {isBanner && (
                    <Banner
                        // @ts-expect-error
                        cover={items[0]?.banner ?? ''}
                        text={items[0]?.getName}
                        height={430}
                        imageWidth={row.rowItemComponent === BannerItemComponent.SPLIT ? '50%' : '100%'}
                    />
                )}
                {!isBanner && row.type === 'custom' && (
                    <DraggableCarousel
                        items={items as (Video | Tag | Article)[]}
                        row={row}
                        onItemsChange={handleItemsChange}
                        onItemRemove={handleItemRemove}
                        onItemAdd={handleItemAdd}
                    />
                )}
                {!isBanner && row.type === 'filter' && (
                    <Carousel
                        items={items as (Video | Tag | TvChannel)[]}
                        row={row}
                    />
                )}
                {!isBanner && row.type === 'continueToWatch' && (
                    <TypographyStyled>{t('Continue to watch')}</TypographyStyled>
                )}
                {!isBanner && row.type === 'favourites' && (
                    <TypographyStyled>{t('Favourites')}</TypographyStyled>
                )}
                {!isBanner && row.type === 'topWatched' && (
                    items.length
                        ? (<Carousel
                            items={items as Video[]}
                            row={row}
                        />)
                        : (<EmptyRowSkeleton
                            width="100%"
                            height={180}
                            variant="rect"
                            animation={false}
                        >
                            {t('No items yet')}
                        </EmptyRowSkeleton>)
                )}
            </div>
        </>
    )
})

export {
    ScreenRow,
}
