/**
 * @param duration - in seconds
 */
const toDurationString = (duration: number) => {
    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor((duration - (hours * 3600)) / 60)

    return `${hours ? `${hours}h ` : ''}${minutes}m`
}

export {
    toDurationString,
}
