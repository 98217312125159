import { Chip } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'


export interface ChipsArrayProps {
    items: ChipsArrayItem[]
    selectedItem: ChipsArrayItem | undefined
    addButtonText?: string
    onSelectItem: (item: ChipsArrayItem) => void
    onAddItem: () => void
    onDeleteItem: (item: ChipsArrayItem) => Promise<void> | void
}

export interface ChipsArrayItem {
    value: string
    label: string
    editable: boolean
}

const itemsAreEqual = (item1: ChipsArrayItem | undefined, item2: ChipsArrayItem | undefined) =>
    item1?.value === item2?.value


const StyledChip = styled(Chip)<{ active: boolean }>`
    margin-right: 8px;
    background-color: ${props => props.active ? COLORS.ACTIVE_CHIP : COLORS.INACTIVE_CHIP};
    color: ${props => props.active ? COLORS.ACTIVE_CHIP_TEXT : COLORS.INACTIVE_CHIP_TEXT};

    &:hover {
        background-color: ${COLORS.ACTIVE_CHIP};
    }

    &:focus {
        background-color: ${props => props.active ? COLORS.ACTIVE_CHIP : COLORS.INACTIVE_CHIP};
        color: ${props => props.active ? COLORS.ACTIVE_CHIP_TEXT : COLORS.INACTIVE_CHIP_TEXT};
    }
`

const StyledAddChip = styled(Chip)`
    margin-right: 8px;
    background-color: ${COLORS.ADD_CHIP};
    color: ${COLORS.ACTIVE_CHIP_TEXT};

    &:hover {
        background-color: ${COLORS.ADD_CHIP};
    }

    &:focus {
        background-color: ${COLORS.ADD_CHIP};
        color: ${COLORS.ACTIVE_CHIP_TEXT};
    }

`

/**
 * Array of chips. Chips can be selected, added or deleted.
 */
const ChipsArray = ({items, selectedItem, addButtonText, onSelectItem, onAddItem, onDeleteItem}: ChipsArrayProps) => {
    const [ t ] = useTranslation()

    const handleDelete = async (item: ChipsArrayItem) => {
        let newSelectedItem
        if (itemsAreEqual(item, selectedItem)) {
            const deletedIndex = items.indexOf(item)
            newSelectedItem = items?.[deletedIndex === 0 ? 0 : deletedIndex - 1]
        }
        await onDeleteItem(item)

        if (newSelectedItem) {
            onSelectItem(newSelectedItem)
        }
    }

    return (
        <>
            {items.map(item => (
                <StyledChip
                    size="small"
                    key={item.value}
                    label={item.label}
                    active={itemsAreEqual(item, selectedItem)}
                    onClick={() => onSelectItem(item)}
                    onDelete={item.editable ? () => handleDelete(item) : undefined}
                />
            ))}
            <StyledAddChip
                size="small"
                key="addChip"
                avatar={<Add/>}
                label={t(addButtonText ?? 'Add')}
                onClick={onAddItem}
            />
        </>
    )
}

export default ChipsArray
