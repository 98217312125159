import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    Switch,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { MonetizationPlacementField, MonetizationRecoveryField } from '@tivio/firebase'
import { MONETIZATION_DURATION, MONETIZATION_FREQUENCY, TvStreamType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'

import { COLORS } from '../../../static/enum'
import { DEFAULT_VARIANT_ID } from '../../../store/Monetization'
import { alertSuccess } from '../../../utils/alert.utils'
import { PlacementDialog } from '../../ads/PlacementDialog'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { MultiTextFieldItem, multiTextFieldItemToTranslation, translationToMultiTextFieldItem } from '../../common/formField/MultiTextField'
import { useMemberships } from '../../hooks/dataHooks/useMemberships'
import { useMonetization } from '../../hooks/dataHooks/useMonetization'
import { useOrganization } from '../../hooks/dataHooks/useOrganization'
import { useVariantPrices } from '../../hooks/dataHooks/useVariantPrices'
import { useLoading } from '../../hooks/general'
import { useDialog } from '../../hooks/uiHooks/useDialog'
import { useUpdatedEntity } from '../../hooks/uiHooks/useUpdatedEntity'
import MonetizationPageHeader from '../../monetization/MonetizationPageHeader'
import { MonetizationPageTitle } from '../../monetization/MonetizationPageTitle'
import { MonetizationPlacementsList } from '../../monetization/MonetizationPlacementsList'
import PaidMonetizationVariantsList from '../../monetization/PaidMonetizationVariantsList'
import { RecoveryPaymentUrl } from '../../monetization/RecoveryPaymentUrl'
import SimplePriceInput from '../../monetization/SimplePriceInput'
import SubscriptionBenefits from '../../monetization/SubscriptionBenefits'
import { StyledMonetizationPageContainer } from '../../styled/Container'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { UserLayoutParams } from '../layout/UserLayout'


const useStyles = makeStyles((theme) => ({
    labelColor: {
        color: COLORS.SECONDARY,
    },
}))

export const SubscriptionPage: FC = observer(() => {
    const [t] = useTranslation()
    const { updateProxy, isUpdated, resetIsUpdated } = useUpdatedEntity()
    const { loading, startLoading, stopLoading } = useLoading()
    const { monetizationId } = useParams<UserLayoutParams>()
    const { monetization: subscription, notFound } = useMonetization(monetizationId)
    const { memberships } = useMemberships()
    const classes = useStyles()

    const {
        variantPrices,
        updatePrices,
        handlePricesChange,
        constructVariantsWithUpdatedPrices,
    } = useVariantPrices(subscription, updateProxy)

    const [title, setTitle] = useState<string>('')
    const [benefits, setBenefits] = useState<MultiTextFieldItem[][]>([]) // it's array of arrays because we have many benefits of many translations
    const [placements, setPlacements] = useState<MonetizationPlacementField | null>(null)
    const [frequency, setFrequency] = useState<MONETIZATION_FREQUENCY>()
    const [duration, setDuration] = useState<MONETIZATION_DURATION>()
    const [isPurchasableAsVoucher, setIsPurchasableAsVoucher] = useState(false)
    const [purchaseDisabled, setPurchaseDisabled] = useState<boolean>(false)
    const [subscriptionIsHidden, setSubscriptionIsHidden] = useState<boolean>(false)
    const [membershipId, setMembershipId] = useState<string | undefined>(undefined)
    const [recovery, setRecovery] = useState<MonetizationRecoveryField>()

    const { isDialogOpen, closeDialog, openDialog } = useDialog()
    const { organization } = useOrganization()

    useEffect(
        () => {
            if (subscription) {
                setTitle(subscription.getName)
                setPlacements(subscription.getPlacements)
                setFrequency(subscription.getFrequency)
                setDuration(subscription.getDurationDays)
                setMembershipId(subscription.getMembershipRef?.id)
                setBenefits(subscription.getBenefits.map(benefit => translationToMultiTextFieldItem(benefit)))
                setIsPurchasableAsVoucher(subscription?.isPurchasableAsVoucher ?? false)
                setPurchaseDisabled(subscription?.purchaseDisabled ?? false)
                setSubscriptionIsHidden(subscription?.subscriptionIsHidden ?? false)
                setRecovery(subscription.recovery)

                // prices are set in useVariantPrices hook
            }
        },
        [subscription],
    )

    const handleSave = async () => {
        if (subscription) {
            startLoading()

            await subscription.updateName(title)
            await subscription.updateBenefits(benefits.map((benefit: MultiTextFieldItem[]) => multiTextFieldItemToTranslation(benefit)))
            await subscription.updateFrequency(frequency)
            await subscription.updateIsPurchasableAsVoucher(isPurchasableAsVoucher)
            await subscription.updatePurchaseDisabled(purchaseDisabled)
            await subscription.updateSubscriptionIsHidden(subscriptionIsHidden)

            await updatePrices()

            const recoveryToSave = frequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT ? recovery : undefined
            await subscription.updateRecovery(recoveryToSave)
            const durationToSave = frequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT ? duration : undefined
            await subscription.updateDurationDays(durationToSave)

            const membershipRef = memberships.find(m => m.getId === membershipId)?.getRef
            await subscription.updateMembershipRef(membershipRef ?? null)
            setBenefits(benefits.filter(benefit => Object.values(benefit).some(lang => lang.textValue !== ''))) // remove empty translations

            alertSuccess(t('Subscription has been saved'))
            stopLoading()
            resetIsUpdated()

            return
        }

        throw new Error('subscription not found')
    }

    const handleChangeRecoveryPaymentUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateProxy(() => {
            setRecovery({
                recoveryPaymentGatewayLink: e.target.value,
                manualActivation: recovery?.manualActivation ?? false,
            })
        })
    }

    const updateManualActivationRecoveryCallback = (checked: boolean) => updateProxy(() => {
        setRecovery(prev => ({
            recoveryPaymentGatewayLink: prev?.recoveryPaymentGatewayLink ?? '',
            manualActivation: checked,
        }))
    })

    const handleFrequencyChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const frequencyValue = event.target.value as MONETIZATION_FREQUENCY
        updateProxy(() => setFrequency(frequencyValue))
        if (frequencyValue === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT && !duration) {
            updateProxy(() => setDuration(MONETIZATION_DURATION.UNLIMITED))
        }
    }

    const setPlacement = async (
        monetizationId: string,
        checkedTvChannels: string[],
        checkedVodChannels: string[],
        checkedSections: string[],
        checkedVideos: string[],
        tvStream: TvStreamType,
    ) => {
        if (!organization) {
            return
        }

        await organization.setMonetizationPlacement(
            monetizationId,
            checkedTvChannels,
            checkedVodChannels,
            checkedSections,
            checkedVideos,
            tvStream,
        )

        closeDialog()
    }

    const PURCHASE_DISABLED_MESSAGE = t(`This subscription has disabled the possibility to be purchased: it can be used by users
    who already have bought this subscription and they can even prolong it but it will not be
    shown in the selection of subscriptions to buy.`)

    const purchaseDisabledTooltip = () => {
        if (purchaseDisabled) {
            return PURCHASE_DISABLED_MESSAGE
        }
        return t('This subscription is enabled.')

    }

    const subscriptionIsHiddenTooltip = () => {
        if (subscriptionIsHidden) {
            return t('This subscription is listed in the list of subscriptions to buy.')
        }
        return t('This subscription is not listed in the list of subscriptions to buy.')
    }

    if (notFound) {
        return <AppNotFoundTemplate title={t('Transaction not found')} />
    }

    if (!subscription || !placements || !variantPrices) {
        return null
    }

    return (
        <>
            <PlacementDialog
                monetization={subscription}
                placements={subscription.getPlacements}
                isOpen={isDialogOpen}
                onClose={closeDialog}
                onSubmit={(checkedTvChannels, checkedVodChannels, checkedSections, checkedVideos, tvStream) => setPlacement(
                    subscription.getId,
                    checkedTvChannels,
                    checkedVodChannels,
                    checkedSections,
                    checkedVideos,
                    tvStream,
                )}
            />

            <MonetizationPageHeader
                onSave={handleSave}
                disabled={!isUpdated || loading}
            />

            <StyledMonetizationPageContainer>
                <MonetizationPageTitle onUpdateName={(text) => updateProxy(() => setTitle(text))}>
                    {title}
                </MonetizationPageTitle>

                <Column spacing={5}>

                    {
                        subscription.purchaseDisabled && <Typography variant="button">
                            {PURCHASE_DISABLED_MESSAGE}
                        </Typography>
                    }

                    <Column spacing={1}>
                        <Typography variant="h6">
                            {t('General')}
                        </Typography>
                        <Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid
                                    item
                                    md={3}
                                    className={classes.labelColor}
                                >{t('Enabled')}
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                >
                                    <Tooltip title={purchaseDisabledTooltip()}>
                                        <Switch
                                            color="primary"
                                            checked={!purchaseDisabled}
                                            onChange={(e) => updateProxy(() => setPurchaseDisabled(!e.target.checked))}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid
                                    item
                                    md={3}
                                    className={classes.labelColor}
                                >{t('Subscription is hidden')}
                                </Grid>
                                <Grid
                                    item
                                    md={1}
                                >
                                    <Tooltip title={subscriptionIsHiddenTooltip()}>
                                        <Switch
                                            color="primary"
                                            checked={subscriptionIsHidden}
                                            onChange={(e) => updateProxy(() => setSubscriptionIsHidden(e.target.checked))}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Grid
                                    item
                                    md={3}
                                    className={classes.labelColor}
                                >{t('Is purchasable as voucher')}</Grid>
                                <Grid
                                    item
                                    md={1}
                                >
                                    <Tooltip
                                        title={isPurchasableAsVoucher
                                            ? t('Users can buy this monetization as a voucher (e.g. to send it as a gift to a friend)')
                                            : t('Users cannot buy this monetization as a voucher')
                                        }
                                    >
                                        <Switch
                                            color="primary"
                                            checked={isPurchasableAsVoucher}
                                            onChange={(e) => updateProxy(() => setIsPurchasableAsVoucher(e.target.checked))}
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Column>
                    <Column spacing={1}>
                        <Typography variant="h6">{t('Benefits')}</Typography>
                        <Typography
                            component="p"
                            color="textSecondary"
                        >
                            {t('Benefits describe the subscription, it is visible to end users.')}
                        </Typography>
                        <Grid
                            container
                            direction="column"
                            spacing={1}
                        >
                            <Grid item>
                                <SubscriptionBenefits
                                    benefits={benefits}
                                    onChange={(benefits) => updateProxy(() => setBenefits(benefits))}
                                />
                            </Grid>
                        </Grid>
                    </Column>
                    <Column spacing={1}>
                        <Typography variant="h6">
                            {t('Settings')}
                        </Typography>

                        <SimplePriceInput
                            value={variantPrices[DEFAULT_VARIANT_ID] ?? {}}
                            onChange={newVariantPrices => handlePricesChange(DEFAULT_VARIANT_ID, newVariantPrices)}
                        />
                    </Column>
                    <Column spacing={4}>
                        <Typography variant="h6">
                            {t('Variants')}
                        </Typography>
                        <PaidMonetizationVariantsList
                            monetizationId={monetizationId}
                            monetizationType="subscription"
                            variants={constructVariantsWithUpdatedPrices(subscription.variants)}
                            onPricesChange={handlePricesChange}
                        />
                    </Column>


                    <Row spacing={1}>
                        <Column spacing={1}>
                            <Typography variant="h6">
                                {t('Frequency')}
                            </Typography>


                            <Box width={200}>
                                <FormControl fullWidth>
                                    <Select
                                        value={frequency}
                                        onChange={handleFrequencyChange}
                                    >
                                        <MenuItem value={MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT}>{t('One time')}</MenuItem>
                                        <MenuItem value={MONETIZATION_FREQUENCY.DAILY}>{t('Daily')}</MenuItem>
                                        <MenuItem value={MONETIZATION_FREQUENCY.WEEKLY}>{t('Weekly')}</MenuItem>
                                        <MenuItem value={MONETIZATION_FREQUENCY.MONTHLY}>{t('Monthly')}</MenuItem>
                                        <MenuItem value={MONETIZATION_FREQUENCY.HALF_ANNUALLY}>{t('Half annually')}</MenuItem>
                                        <MenuItem value={MONETIZATION_FREQUENCY.ANNUALLY}>{t('Annually')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Column>
                        {
                            frequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT &&
                            (<Column spacing={1}>
                                <Typography variant="h6">
                                    {t('Duration')}
                                </Typography>


                                <Box width={200}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={duration}
                                            onChange={(e) => updateProxy(() => setDuration(e.target.value as MONETIZATION_DURATION))}
                                        >
                                            <MenuItem value={MONETIZATION_DURATION.UNLIMITED}>{t('Unlimited')}</MenuItem>
                                            <MenuItem value={MONETIZATION_DURATION.DAY}>{t('Day')}</MenuItem>
                                            <MenuItem value={MONETIZATION_DURATION.WEEK}>{t('Week')}</MenuItem>
                                            <MenuItem value={MONETIZATION_DURATION.MONTH}>{t('Month')}</MenuItem>
                                            <MenuItem value={MONETIZATION_DURATION.HALF_YEAR}>{t('Half year')}</MenuItem>
                                            <MenuItem value={MONETIZATION_DURATION.YEAR}>{t('Year')}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Column>
                            )
                        }
                    </Row>

                    <Column spacing={1}>
                        <Typography variant="h6">
                            {t('Membership')}
                        </Typography>

                        <Box width={200}>
                            <FormControl fullWidth>
                                <Select
                                    value={membershipId}
                                    onChange={(e) => updateProxy(() => setMembershipId(e.target.value as string))}
                                    displayEmpty
                                >
                                    <MenuItem value={undefined}>{t('None')}</MenuItem>
                                    {memberships.map(m => (
                                        <MenuItem
                                            key={m.getId}
                                            value={m.getId}
                                        >{m.getName}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Column>

                    <Column spacing={1}>
                        <Row
                            rowProps={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6">
                                {t('Subscription placement')}
                            </Typography>
                            <Button
                                onClick={openDialog}
                                startIcon={<Add />}
                            >
                                {subscription.hasPlacements ? t('Edit placements') : t('Add placement')}
                            </Button>
                        </Row>
                        <MonetizationPlacementsList
                            monetization={subscription}
                            onClick={openDialog}
                        />

                    </Column>

                    {
                        frequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT &&
                        (
                            <RecoveryPaymentUrl
                                recovery={recovery}
                                handleChangeRecoveryPaymentUrl={handleChangeRecoveryPaymentUrl}
                                updateManualActivationRecoveryCallback={updateManualActivationRecoveryCallback}
                            />
                        )
                    }

                    <Divider />

                    <Typography color="textSecondary">
                        ID: {monetizationId}
                    </Typography>
                </Column>
            </StyledMonetizationPageContainer>
        </>
    )
})

export default SubscriptionPage

SubscriptionPage.displayName = 'SubscriptionPage'
