import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    ThemeProvider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Close from '@material-ui/icons/Close'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { lightTheme } from '../../static/theme'

import type { DialogProps } from '@material-ui/core/Dialog/Dialog'
import type { DialogContentProps } from '@material-ui/core/DialogContent/DialogContent'


export interface TDialogProps<T> extends DialogProps {
    cancelBtnText?: string
    confirmBtnText?: string
    onCancel?: () => any
    onConfirm?: (args: T) => any
    text?: string
    title?: string
    hideActionsButtons?: boolean
    paperClassName?: string
    contentProps?: DialogContentProps
    showCloseIcon?: boolean
}

const useStyles = makeStyles({
    backdrop: {
        backdropFilter: 'blur(10px)',
        opacity: .4,
    },
    closeIcon: {
        position: 'absolute',
        zIndex: 1000,
        right: 24,
        top: 24,
    },
})

const getMaterialProps = (props: TDialogProps<void>) => {
    const {
        cancelBtnText,
        confirmBtnText,
        onCancel,
        onConfirm,
        text,
        title,
        hideActionsButtons,
        paperClassName,
        contentProps,
        showCloseIcon,
        ...materialProps
    } = props

    return materialProps
}

/**
 * Dialog base component
 * Contains basic dialog methods
 * Consumes custom dialog content
 */
const DialogBase = (props: TDialogProps<void>) => {
    const classes = useStyles()
    const [ t ] = useTranslation()

    const handleConfirm = () => {
        props.onConfirm?.()
    }

    const handleCancel = () => {
        props.onCancel?.()
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <Dialog
                {...getMaterialProps(props)}
                open={props.open}
                onClose={handleCancel}
                BackdropProps={{
                    classes: {
                        root: classes.backdrop,
                    },
                }}
            >
                {props.title && <DialogTitle>{props.title}</DialogTitle>}

                <DialogContent {...props.contentProps}>
                    {props.showCloseIcon && (
                        <IconButton
                            className={classes.closeIcon}
                            onClick={handleCancel}
                        >
                            <Close/>
                        </IconButton>
                    )}
                    {props.text && (
                        <DialogContentText>
                            {props.text}
                        </DialogContentText>
                    )}
                    {props.children}
                </DialogContent>

                {
                    props.hideActionsButtons
                        ? null
                        : (
                            <DialogActions>
                                <Button
                                    onClick={handleCancel}
                                    color="primary"
                                >
                                    {props.cancelBtnText ? props.cancelBtnText : t('Cancel')}
                                </Button>
                                <Button
                                    onClick={handleConfirm}
                                    color="primary"
                                    variant="contained"
                                >
                                    {props.confirmBtnText ? props.confirmBtnText : t('Confirm')}
                                </Button>
                            </DialogActions>
                        )
                }
            </Dialog>
        </ThemeProvider>
    )
}

export default DialogBase
