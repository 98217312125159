import { LangCode, Translation } from '@tivio/types'

/**
 *
 * @param text
 * @param langCodes if provided, will only set translated text for given language codes. All other codes will be set to empty string.
 */
const createTranslation = (text: string, langCodes?: LangCode[]): Translation => {
    return Object
        .values(LangCode)
        .reduce<Translation>((result, langCode) => {
            if (langCodes) {
                result[langCode] = langCodes.includes(langCode) ? text : ''
                return result
            }

            result[langCode] = text
            return result
        }, {})
}

export {
    createTranslation,
}
