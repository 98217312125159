import { Chip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'

import Video from '../../store/Video'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'
import { VideoCover } from './VideoCover'


interface Props {
    video: Video
    buttons?: React.ReactNode[]
    progress?: number
    hideProgress?: boolean
}

const useStyles = makeStyles((theme) => ({
    description: {
        display: 'block',
    },
    tag: {
        fontSize: '0.7rem',
        height: '18px',
        '& .MuiChip-labelSmall': {
            paddingLeft: '4px',
            paddingRight: '4px',
        },
    },
    created: {
        opacity: 0.8,
    },
}))


export const VideoItem: React.FC<Props> = observer((props) => {
    const { video, progress, hideProgress } = props
    const showProgress = !!progress && !hideProgress
    const classes = useStyles()

    const trimmedDescription = useCallback(() => {
        if (!video.getDescription) {
            return ''
        }
        const description = video.getDescription.substring(0, 330)
        return description + (video.getDescription.length > 330 ? '...' : '')
    }, [ video.getDescription ])

    return (
        <>
            <Row
                rowProps={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
                itemProp={{
                    index: 0,
                    props: {
                        xs: true,
                    },
                }}
            >
                <Row
                    rowProps={{ alignItems: 'center', spacing: 2 }}
                    itemProp={{
                        index: 1,
                        props: {
                            xs: true,
                        },
                    }}
                >
                    <VideoCover
                        videoType={video.getType}
                        cover={video.cover}
                        progress={progress}
                        showProgress={showProgress}
                        publishedStatus={video.getPublishedStatus}
                        showBadges
                    />
                    <Column spacing={1}>
                        <Typography variant="h6">
                            {video.getName}
                        </Typography>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={classes.description}
                        >
                            {trimmedDescription()}
                            <div className={classes.created}>{video.getCreated.toLocaleString()}</div>
                        </Typography>
                        <Row spacing={1}>
                            {video.tags.map(
                                tag => (
                                    <Chip
                                        key={tag.id}
                                        label={tag.getNameTranslation.en}
                                        size="small"
                                        className={classes.tag}
                                    />
                                ),
                            )}
                        </Row>
                    </Column>
                </Row>
                <Row
                    spacing={2}
                    rowProps={{ alignItems: 'center' }}
                >
                    <Row
                        spacing={1}
                        rowProps={{ alignItems: 'center' }}
                    >
                        {props.buttons}
                    </Row>
                </Row>
            </Row>
        </>
    )
})
