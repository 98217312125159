import { globalsFirstRestAlphabeticallyComparator } from '@tivio/common'
import React, { useState } from 'react'

import { TagType } from '../../../store/TagType'
import { Listing } from '../../common/listing/Listing'
import { ListingItem } from '../../common/listing/ListingRow'
import { ListingRowNameWithColor } from '../../common/listing/ListingRowNameWithColor'
import { useDialogState } from '../../hooks'

import { EditTagTypeDialog } from './EditTagTypeDialog'
import { useTagTypesCrud } from './hooks'

import type { TagTypeDocument } from '@tivio/firebase'


interface Props {
    tagTypes: TagType[]
}

const tagTypeToListingItemConverter = (tagType: TagType): ListingItem => ({
    getId: tagType.id,
    getName: tagType.name,
    extraColumns: [
        tagType.description ?? '',
        `ID ${tagType.tagTypeId}`,
        // TODO TIV-692 compute tagsCount
        // tagType.tagsCount ? (tagType.tagsCount + ' ' + translate('Tags')) : '',
    ],
    extraProps: {
        tagType,
        color: tagType.color,
    },
    renderName: (item: ListingItem) => <ListingRowNameWithColor item={item}/>,
})

export const TagTypesList = ({tagTypes}: Props) => {
    const { isDialogOpen, openDialog, closeDialog } = useDialogState()
    const [ editedTagType, setEditedTagType ] = useState<TagType>()
    const { update, remove } = useTagTypesCrud()

    const items = tagTypes
        .sort(globalsFirstRestAlphabeticallyComparator)
        .map(tagTypeToListingItemConverter)

    const handleEditDialogConfirm = (tagType: TagTypeDocument) => {
        update(editedTagType?.id ?? '', tagType)
    }

    const handleUpdateClick = (item: ListingItem) => {
        setEditedTagType(item.extraProps?.tagType)
        openDialog()
    }

    const handleDeleteClick = (item: ListingItem) => {
        remove(item.getId, item.getName)
    }

    return (
        <>
            <EditTagTypeDialog
                open={isDialogOpen}
                onCancel={closeDialog}
                tagType={editedTagType}
                onConfirm={handleEditDialogConfirm}
            />
            <Listing
                items={items}
                onClick={handleUpdateClick}
                onDeleteClick={handleDeleteClick}
                onUpdateClick={handleUpdateClick}
            />

        </>
    )
}
