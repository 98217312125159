import { Box, CircularProgress, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Warning from '@material-ui/icons/Warning'
import React, { Ref, useState } from 'react'

import { COLORS } from '../../static/enum'

import { AppPlayer, AppPlayerProps, PlayerController } from './Player'


export interface PlayerContainerProps extends AppPlayerProps {
    error: {error: boolean, errorText?: string | null}
    playerRef?: Ref<PlayerController>
    onClick?: () => void
    isPlaying?: boolean
}

/**
 * Size 864x486 is exactly 0.45 times the size of Full HD 1920x1080.
 */
const useStyles = makeStyles((theme) => ({
    playerContainer: {
        alignItems: 'center',
        maxWidth: 864,
        // fixed height so it does not "jump" or has zero height when no video is present
        height: 486,
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        margin: 'auto',
        position: 'relative',
    },
    player: {
        cursor: 'pointer',
    },
    errorContainer: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    errorIcon: {
        margin: 'auto',
        fontSize: theme.spacing(8),
        opacity: .7,
    },
}))

const PlayerContainer = (props: PlayerContainerProps) => {
    const classes = useStyles()
    const [ buffering, setBuffering ] = useState(false)
    const { error, errorText } = props.error

    return (
        <div className={classes.playerContainer}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                className={classes.errorContainer}
            >
                {error && (
                    <Grid
                        item
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Warning className={classes.errorIcon}/>
                        {errorText && <p>{errorText}</p>}
                    </Grid>
                )}
                {!error && buffering && (
                    <Grid item>
                        <CircularProgress color="inherit"/>
                    </Grid>
                )}
            </Grid>
            <Box
                onClick={props.onClick}
                className={classes.player}
            >
                <AppPlayer
                    ref={props.playerRef}
                    onTimeUpdate={props.onTimeUpdate}
                    onPause={props.onPause}
                    onPlay={props.onPlay}
                    onBuffering={setBuffering}
                    onDuration={props.onDuration}
                />
            </Box>
        </div>
    )
}

export default PlayerContainer
