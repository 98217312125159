import React from 'react'

import { AssetResizeResult } from '../../../../firebase/firestore/types'
import { AssetPreset } from '../../../../store/AssetPreset'
import { AssetRow } from '../../../asset/assetRow'
import { AssetUploaderItem } from '../../../asset/assetUploader'
import { useAllAssetPresets } from '../../../settings/presets/hooks'

import { SubstitutionProps } from './types'


export const ImageSubstitution = ({ substitution }: SubstitutionProps) => {
    const allPresets = useAllAssetPresets() // placement - email?
    const presetName = substitution.presetName!

    const preset = allPresets.find((preset) => preset.name === presetName)

    const item: AssetUploaderItem = {
        keepOriginal: true,
        quality: 75,
        getAsset: () => substitution.value ?? null,
        getTempFilePath: (preset: AssetPreset) => `${substitution.notificationPath}/${preset.name}-${substitution.notificationId}.jpeg`,
        getDocumentPath: () => substitution.notificationPath,
        updateItem: async (uris: AssetResizeResult[], name: string) => {
            console.log('updateItem', uris, name)
            substitution.value = uris[0].url
        },
    }

    return preset ? (
        <AssetRow
            preset={preset}
            item={item} // item path - /organizationId/notificationId/presetId
        />
    ) : (
        <div>asset preset not found</div>
    )
}
