import { makeAutoObservable, runInAction } from 'mobx'

import { createTranslation } from '../creator/translation.creator'
import { getOrganizationVideoById } from '../firebase/firestore/video'


import type Organization from './Organization'
import type { ContentDocument, VideoContentDocument, VideoDocument } from '@tivio/firebase'
import type { Translation } from '@tivio/types'
import type firebase from 'firebase/app'


// TODO: Inheritance isn't possible here because of Omit when extending ContentDocument
export class Post {
    private _videoDocument: VideoDocument | undefined
    private _videoDocumentId: string | undefined

    constructor(
        private _ref: firebase.firestore.DocumentReference<ContentDocument>,
        private organization: Organization,
        private data: ContentDocument,
    ) {
        makeAutoObservable(this)

        if (data.type === 'VIDEO') {
            void this.initVideo()
        }
    }

    async initVideo() {
        const videoDoc = await getOrganizationVideoById(
            (this.data as unknown as VideoContentDocument).videoRef.id,
            this.organization.ref,
        )

        if (!videoDoc) {
            return
        }

        runInAction(() => {
            this._videoDocument = videoDoc.data
            this._videoDocumentId = videoDoc.ref.id
        })
    }

    get id() {
        return this._videoDocumentId ?? this._ref.id
    }

    getVideoFieldWithFallback(fieldName: keyof VideoDocument) {
        const field = this._videoDocument?.[fieldName] as
            | Translation
            | string
            | undefined
        return field
            ? typeof field === 'string'
                ? createTranslation(field, this.organization.languages)
                : field
            : null
    }

    get name() {
        return this.getVideoFieldWithFallback('name') ?? this.data?.name ?? {}
    }

    get description() {
        return this.getVideoFieldWithFallback('description') ??
            this.data?.description ?? {}
    }

    get cover() {
        return this._videoDocument?.cover ??
            this.data?.assets?.article_cover_image?.['@1']?.background
    }

    get type() {
        return this.data.type
    }
}
