import { makeAutoObservable } from 'mobx'

import type { MembershipDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export class Membership {
    private readonly ref: firebase.firestore.DocumentReference<MembershipDocument>
    private firestoreData: MembershipDocument

    constructor(
        ref: firebase.firestore.DocumentReference<MembershipDocument>,
        firestoreData: MembershipDocument,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData

        makeAutoObservable(this)
    }

    async update(data: Partial<MembershipDocument>) {
        await this.ref.update(data)
        this.firestoreData = {
            ...this.firestoreData,
            ...data,
        }
        return this.ref
    }

    delete() {
        return this.ref.delete()
    }

    get getRef() {
        return this.ref
    }

    get getId() {
        return this.ref.id
    }

    get getName() {
        return this.firestoreData.name
    }

    get getCreated() {
        return this.firestoreData.created
    }
}
