import dayjs, { Dayjs, UnitType } from 'dayjs'
import { useObserver } from 'mobx-react'

import { TvProgram } from '../../../store/TvProgram'
import { useOrganization } from '../index'



export const TV_PROGRAM_DEFAULT_HEIGHT_COEFFICIENT = 2
export const ITEMS_GAP_PX = 4

/**
 * Returns functions needed for EPG UI.
 * (functions for conversions between time and pixels, and for computing top and height for EPG items).
 */
export const useEpgUI = () => {
    const { organization } = useOrganization()
    const itemHeightCoefficient = useObserver(() => {
        return organization?.epgConfiguration?.editor?.itemHeightCoefficient ?? TV_PROGRAM_DEFAULT_HEIGHT_COEFFICIENT
    })

    const updateItemHeightCoefficient = (value: number) => {
        organization?.updateItemHeightCoefficient(value)
    }

    /**
     * Converts time of given datetime to pixels.
     * Date is always ignored here, so only time (Xh Ym Zs) is used for computation.
     * Result is a duration from date start (00:00:00) to given time, multiplied by some configured coefficient.
     *
     * @param datetime datetime which time will be converted to pixels
     * @param ceilUnit unit to which time will be ceiled
     */
    const convertTimeToPx = (datetime: Dayjs, ceilUnit: UnitType = 'minute'): number => {
        const ceiledDatetime = datetime.startOf(ceilUnit)
        const dayStart = datetime.startOf('day')
        return ceiledDatetime.diff(dayStart, 'minute') * itemHeightCoefficient
    }

    /**
     * Converts pixels to datetime.
     * In the result object date is a given baseDate, time is computed from pixels.
     *
     * @param px pixels to be converted to time
     * @param baseDate date of the result dayjs object
     */
    const convertPxToTime = (px: number, baseDate: Dayjs = dayjs()): Dayjs => {
        const minutes = px / itemHeightCoefficient
        return baseDate.startOf('day').add(minutes, 'minute')
    }

    /**
     * Computes 'top' value for absolute positioning of EPG item.
     *
     * @param item EPG item
     * @param date date of column where this item is shown (needed for case of item over midnight)
     */
    const computeTopForItem = (item: TvProgram, date: Dayjs): number => {
        return item.overflownTo(date) ? 0 : convertTimeToPx(item.from.startOf('minute'))
    }

    /**
     * Computes height for EPG item.
     * Each item's height is decreased by {@link ITEMS_GAP_PX} to not take any additional "minutes" space for gaps
     * (so consecutive items can rightly fit to time space and also have some gaps between them).
     *
     * @param item EPG item
     * @param date date of column where this item is shown (needed for case of item over midnight)
     */
    const computeHeightForItem = (item: TvProgram, date: Dayjs) => {
        return item.getDurationAtDate(date, 'minute', { ignoreSmallerUnits: true }) * itemHeightCoefficient - ITEMS_GAP_PX
    }

    return {
        itemHeightCoefficient,
        updateItemHeightCoefficient,
        convertTimeToPx,
        convertPxToTime,
        computeTopForItem,
        computeHeightForItem,
    }
}
