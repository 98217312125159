import { Button } from '@material-ui/core'
import styled from 'styled-components'


const BigButton = styled(Button)`
    padding: 1rem 0;
    margin-top: 2rem;
`

export {
    BigButton,
}
