import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { goAnalyticsPage } from '../utils/history.utils'

import AppLogo from './AppLogo'


interface TProps {
    title: string
    button?: {
        text: string
        action: () => void
    }
}

const useStyles = makeStyles((theme) => ({
    page: {
        height: '70vh',
    },
    text: {
        padding: theme.spacing(4),
        color: theme.palette.common.white,
    },
}))

/**
 * App not found template component
 * Default "not found thing" layout
 */
const AppNotFoundTemplate = (props: TProps) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleButtonClick = () => {
        if (props.button) {
            props.button.action()
            return
        }

        goAnalyticsPage()
    }

    return (
        <Grid
            className={classes.page}
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
        >
            <Grid item>
                <AppLogo/>
            </Grid>
            <Grid item>
                <Typography
                    className={classes.text}
                    variant="body1"
                >
                    {props.title}
                </Typography>
            </Grid>
            <Grid item>
                <Grid
                    container
                    justifyContent="center"
                >
                    <Grid item>
                        <Button
                            onClick={handleButtonClick}
                            color="primary"
                            variant="contained"
                        >
                            {props.button ? props.button.text : t('Go to dashboard')}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AppNotFoundTemplate
