import { useCallback, useEffect, useState } from 'react'
import { useTimer } from 'react-timer-hook'


const CLOSE_OSD_DELAY = 3 // seconds

function newTimer() {
    const date = new Date()
    date.setSeconds(date.getSeconds() + CLOSE_OSD_DELAY)
    return date
}

function usePlayerOsdTimer() {
    const [ isOpen, setIsOpen ] = useState(false)

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    const open = useCallback(() => {
        setIsOpen(true)
    }, [])

    const toggle = useCallback(() => {
        setIsOpen(!isOpen)
    }, [ isOpen ])


    const { pause: pauseTimer, restart } = useTimer({ expiryTimestamp: newTimer(), onExpire: close })

    const restartTimer = useCallback(() => {
        restart(newTimer())
        // restart function changes on every call for some reason, we cannot add it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isOpen) {
            restartTimer()
        }
    }, [isOpen, restartTimer])

    return {
        isOpen,
        close,
        open,
        toggle,
        pauseTimer,
        restartTimer,
    }
}

export {
    usePlayerOsdTimer,
}
