import Logger from '../../logger'
import { TvProgram } from '../../store/TvProgram'
import {
    FirebaseDocumentData,
    firebaseTimestampFromDayjs,
    FirestoreDocumentSnapshot,
    getFirestore,
    loggerFirestore,
} from '../app'
import { Pagination } from '../pagination'

import type { DocumentReference, Query, Timestamp, TvProgramDocument } from '@tivio/firebase'


export type TvProgramQueryFilters = {
    /**
     * Id of organization to which tvPrograms belong
     */
    organizationId: string
    /**
     * Min start date of tvProgram
     */
    minFrom: Timestamp
    /**
     * Max start date of tvProgram
     */
    maxFrom?: Timestamp
    /**
     * Ids of tvChannels to which tvPrograms belong. If are empty, then programs from all organization channels are queried.
     */
    tvChannelIds?: string[]
}

const logger = new Logger('firestore.tvProgram')

export const getTvProgramCollection = () => getFirestore()
    .collection('tvPrograms')
    .withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TvProgramDocument => {
            return snapshot.data() as TvProgramDocument
        },
        toFirestore: (tvProgramFirestore: TvProgramDocument): FirebaseDocumentData => {
            return tvProgramFirestore
        },
    })

/**
 * Get query for tvPrograms.
 *
 * @param filters filters
 */
export const getTvProgramsQuery = (filters: TvProgramQueryFilters): Query<TvProgramDocument> => {
    const { organizationId, minFrom, maxFrom, tvChannelIds } = filters
    let query = getTvProgramCollection()
        .where('organizationRef', '==', getFirestore().doc(`/organizations/${organizationId}`))
        .where('from', '>=', minFrom)

    if (maxFrom) {
        query = query.where('from', '<=', maxFrom)
    }

    if (tvChannelIds?.length) {
        const tvChannelRefs = tvChannelIds.slice(0, 10).map(id => getFirestore().doc(`tvChannels/${id}`))
        if (tvChannelIds.length > 10) {
            logger.warn('getTvProgramsQuery - tvChannelIds are sliced to 10 items to avoid increasing limit of \'in\' query filter')
        }

        query = query.where('tvChannelRef', 'in', tvChannelRefs)
    }

    return query.orderBy('from')
}

/**
 * Creates new tvProgram document with given data.
 *
 * @param data tv program document data
 */
export const createTvProgram = async (data: TvProgramDocument): Promise<DocumentReference<TvProgramDocument>> => {
    try {
        console.log('createTvProgram - ', data)
        const tvProgramRef = await getTvProgramCollection().add(data)

        loggerFirestore.info('Tv program was created', data)
        return tvProgramRef
    } catch (e) {
        loggerFirestore.error('Failed to create tv program. Error:', e)
        throw new Error(e)
    }
}

/**
 * Removes tvProgram with given id.
 *
 * @param tvProgramId id of tv program to be deleted
 */
export const removeTvProgram = async (tvProgramId: string) => {
    try {
        const tvProgramRef = getTvProgramCollection().doc(tvProgramId)
        await tvProgramRef.delete()

        loggerFirestore.info('Tv program was removed', tvProgramId)
    } catch (e) {
        loggerFirestore.error('Failed to remove tv program. Error:', e)
        throw new Error(e)
    }
}

/**
 * Shifts next programs after adding new program if needed.
 * If there is not enough space for adding new program (e.g. adding new program between 2 consecutive programs),
 * then programs after new program should be shifted until space is enough.
 *
 * @param newProgram program newly added to EPG
 * @param overlappedItem new program is placed in the middle of this item.
 * It's difficult to find this item by query, because we need to filter by 'to' and 'from' fields at once, but this is not possible due to firebase limitations.
 */
export const updateEpgAfterNewProgramCreating = async (newProgram: TvProgram, overlappedItem?: TvProgram) => {
    const organization = newProgram.organization

    const nextProgramsQuery = getTvProgramsQuery({
        organizationId: organization.id,
        minFrom: firebaseTimestampFromDayjs(newProgram.from),
        tvChannelIds: [ newProgram.tvChannelRef.id ],
    })

    const pagination = new Pagination<TvProgramDocument, TvProgram>(
        nextProgramsQuery,
        (ref, data) => new TvProgram(ref, data, organization),
        {
            // we need to wait for data fetch
            noAutoFetch: true,
        },
    )
    await pagination.fetchMore()

    const programsToUpdate: { ref: DocumentReference<TvProgramDocument>, from: Timestamp, to: Timestamp}[] = []

    let index = 0
    let endTime = newProgram.to
    let nextProgram = pagination.items[index]

    const originallyAddedProgram = newProgram

    while (nextProgram && endTime.isAfter(nextProgram.from)) {
        // ignore the added program itself
        if (nextProgram.id !== originallyAddedProgram.id) {
            const nextProgramDuration = nextProgram.getDuration('ms')
            const nextProgramNewEnd = endTime.add(nextProgramDuration, 'ms')

            programsToUpdate.push({
                ref: nextProgram.ref,
                from: firebaseTimestampFromDayjs(endTime),
                to: firebaseTimestampFromDayjs(nextProgramNewEnd),
            })

            if (!overlappedItem) {
                endTime = nextProgramNewEnd
            }
        }

        index++
        if (index >= pagination.items.length) {
            await pagination.fetchMore()
        }

        nextProgram = pagination.items[index]
    }

    const batch = getFirestore().batch()
    await Promise.all(
        programsToUpdate.map(({ ref, ...data }) => batch.update(ref, data)),
    )
    await batch.commit()
}

