import { TagTypeDocument } from '@tivio/firebase'
import i18n from 'i18next'
import { useObserver } from 'mobx-react'
import { useCallback } from 'react'

import { createTagType, removeTagType, updateTagType } from '../../../firebase/firestore/tagType'
import store from '../../../store'
import { useOrganization } from '../../hooks'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'


export const useTagTypes = () => {
    const own = useOwnTagTypes()

    // TODO TIV-692 show own + global for super admin

    return own
}

export const useTagType = (id: string) => {
    const tagTypes = useAllTagTypes()

    return tagTypes.find(tagType => tagType.id === id)
}


export const useOwnTagTypes = () => {
    return useObserver(() => {
        const organization = store.getMember?.getCurrentOrganization
        return organization?.tagTypes ?? []
    })
}

export const useAllTagTypes = () => {
    const own = useOwnTagTypes()

    const global = useObserver(() => {
        return store.globalTagTypes ?? []
    })

    return own.concat(global)
}

const emptyCrud = {
    create: (tagType: TagTypeDocument) => {},
    update: (id: string, tagType: TagTypeDocument) => {},
    remove: (tagTypeId: string, tagTypeName: string) => {},
}

// TODO TIV-692 NICE TO HAVE move to useTagTypes
export const useTagTypesCrud = (): typeof emptyCrud => {
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()

    if (!organization) {
        return emptyCrud
    }

    const create = useCallback((tagType: TagTypeDocument) => {
        // TODO TIV-692 add createGlobalTagType
        createTagType(organization.id, tagType)
    }, [organization])

    const update = useCallback((id: string, tagType: TagTypeDocument) => {
        // TODO TIV-692 add updateGlobalTagType
        updateTagType(organization.id, id, tagType)
    }, [organization])

    const remove = useCallback((tagTypeId: string, tagTypeName: string) => {
        // TODO TIV-692 add removeGlobalTagType
        confirmAction(
            () => {
                removeTagType(organization.id, tagTypeId)
            },
            i18n.t('Do you really wish to remove tag type "{{name}}"? It will also delete ' +
                'all tags of this type.', { name: tagTypeName }),
        )
    }, [organization])

    return {
        create,
        update,
        remove,
    }
}
