import { Grid, IconButton } from '@material-ui/core'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Menu from '@material-ui/icons/Menu'
import { DateTimePicker } from '@material-ui/pickers'
import { VideoDocument } from '@tivio/firebase'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { createTranslation } from '../../creator/translation.creator'
import Video from '../../store/Video'
import { secondsToFirebaseTimestamp } from '../../utils/time.utils'
import AppTile from '../AppTile'
import MultiTextField, { multiTextFieldItemToTranslation, translationToMultiTextFieldItem } from '../common/formField/MultiTextField'
import { Row } from '../uiLayout/Row'
import VideoResolutions from '../video/VideoResolutions'

import type { AppTileProps, TGetContentPlace } from '../AppTile'


export interface ElementInterface extends VideoDocument {
    id: string
}

export interface SectionElementInterfaceProps extends AppTileProps {
    element: ElementInterface,
    onChange?: (elementInterface: ElementInterface) => any
    inPlaylist: boolean
    onDelete?: (elementInterface: ElementInterface) => any
    onDetailClick?: () => any
    videoInstance?: Video
}

export const getContentPlace: TGetContentPlace = (cover) => {
    if (cover) {
        return {
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
        }
    }

    return {
        alignItems: 'center',
        justifyContent: 'center',
    }
}

/**
 * Section element interface component
 * Serves for preparing content data (name, description etc.) for saving to database
 */
const SectionElementInterface = observer((props: SectionElementInterfaceProps) => {
    const [element, setElement] = useState(props.element)
    const [t] = useTranslation()

    useEffect(
        () => {
            if (element !== props.element) {
                props.onChange?.(element)
            }
        },
        [
            element.name,
            element.description,
            element.created,
        ],
    )

    const handleDelete = () => {
        props.onDelete?.(element)
    }

    const handleDateChange = (date: Dayjs | null) => {
        if (date) {
            setElement({
                ...element,
                created: secondsToFirebaseTimestamp(date.unix()),
            })
        }
    }

    return (
        <Grid
            container
            alignItems="center"
            direction="row"
            justifyContent="flex-start"
            wrap="nowrap"
            spacing={2}
        >
            {props.inPlaylist && (
                <Grid
                    item
                >
                    <IconButton>
                        <Menu />
                    </IconButton>
                </Grid>
            )}
            <Grid
                item
            >
                <AppTile
                    contentPlace={getContentPlace(element.cover)}
                    cover={element.cover}
                    loading={props.loading}
                    progress={props.progress}
                    size="small"
                >
                    {/* TODO TIV-546 enable input here, which will use this image for all asset presets */}
                    {/* <ClickableFileInput>
                        <IconButton component="span">
                            <AddPhotoAlternate/>
                        </IconButton>
                    </ClickableFileInput> */}
                </AppTile>
            </Grid>
            <Grid
                item
                xs
                zeroMinWidth
            >
                <Grid
                    container
                    direction="column"
                    spacing={1}
                >
                    <Grid item>
                        <MultiTextField
                            items={translationToMultiTextFieldItem(typeof element.name === 'string'
                                ? createTranslation(element.name)
                                : element.name,
                            )}
                            onChange={(items) => setElement({
                                ...element,
                                name: multiTextFieldItemToTranslation(items),
                            })}
                            typographyVariant="h6"
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <MultiTextField
                            items={translationToMultiTextFieldItem(typeof element.description === 'string'
                                ? createTranslation(element.description)
                                : element.description,
                            )}
                            onChange={(items) => setElement({
                                ...element,
                                description: multiTextFieldItemToTranslation(items),
                            })}
                            typographyVariant="caption"
                            fullWidth
                            multiline
                        />
                    </Grid>
                    {
                        props.videoInstance && (
                            <Grid item>
                                <VideoResolutions resolutions={props.videoInstance.resolutions} />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            <Grid item>
                <Row
                    rowProps={{ alignItems: 'center' }}
                    spacing={2}
                >
                    {
                        !props.inPlaylist && (
                            <DateTimePicker
                                ampm={false}
                                value={element.created.toDate()}
                                onChange={handleDateChange}
                                showTodayButton
                                variant="dialog"
                                label={t('Created date')}
                                inputVariant="outlined"
                            />
                        )
                    }
                    {
                        (props.onDetailClick && !props.inPlaylist) && (
                            <IconButton onClick={props.onDetailClick}>
                                <Create />
                            </IconButton>
                        )
                    }
                    {
                        props.onDelete && (
                            <IconButton onClick={handleDelete}>
                                <Delete />
                            </IconButton>
                        )
                    }
                </Row>
            </Grid>
        </Grid>
    )
})

// @ts-ignore
SectionElementInterface.displayName = 'SectionElementInterface'

export default SectionElementInterface
