import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { createInvite, deleteInvite, updateInvite } from '../firebase/firestore/invite'
import { updateMemberRole, updateOrganization } from '../firebase/firestore/organization'
import { uploadFile } from '../firebase/storage'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { formOrganizationCoverName } from '../utils/channels.utils'
import { translateRoleValueToString } from '../utils/roles.utils'

import Invite from './Invite'
import MemberAdmin from './MemberAdmin'

import type { TCreateInviteInput } from '../firebase/firestore/invite'
import type { TMemberFirestore } from '../firebase/firestore/member'
import type { TOrganizationMember } from '../firebase/firestore/organization'
import type { DocumentReference, OrganizationDocument, OrganizationMemberRoles } from '@tivio/firebase'
import type firebase from 'firebase/app'


const logger = new Logger('OrganizationAdmin')

class OrganizationAdmin {
    private readonly ref: firebase.firestore.DocumentReference<OrganizationDocument>
    private firestoreData: OrganizationDocument
    private members: MemberAdmin[] = []
    private invites: Invite[] = []

    constructor(
        ref: firebase.firestore.DocumentReference<OrganizationDocument>,
        firestoreData: OrganizationDocument,
        members: MemberAdmin[],
        invites: Invite[],
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.members = members
        this.invites = invites

        makeAutoObservable(this)
    }

    async updateName(name: string) {
        try {
            this.firestoreData.name = name

            await updateOrganization(this, { name })

            alertSuccess(i18n.t('Organization name updated'))
        }
        catch (e) {
            alertError(i18n.t('Failed to update organization name'))
            logger.error(e)
        }
    }

    async updateCover(file: File) {
        try {
            const cover = await uploadFile(file, formOrganizationCoverName(this))

            this.firestoreData.cover = cover

            await updateOrganization(this, { cover })

            alertSuccess(i18n.t('Organization cover image updated'))
        }
        catch (e) {
            alertError(i18n.t('Failed to update organization cover image'))
        }
    }

    async createInvite(inviteInput: TCreateInviteInput) {
        try {
            const inviteRef = await createInvite(inviteInput)
            const inviteSnapshot = await inviteRef.get()
            const inviteData = inviteSnapshot.data()

            if (inviteData) {
                this.setInvites = this.getInvites.concat([new Invite(inviteRef, inviteData)])
            }

            alertSuccess(i18n.t('Invite created'))
        }
        catch (e) {
            alertError(i18n.t(e.message))
        }
    }

    async deleteInvite(inviteToDelete: Invite) {
        try {
            await deleteInvite(inviteToDelete)
            this.setInvites = this.getInvites.filter(invite => inviteToDelete.getId !== invite.getId)

            alertSuccess(i18n.t('Invite deleted'))
        }
        catch (e) {
            alertError(i18n.t('Failed to delete invite'))
            logger.error(e)
        }
    }

    async deleteMember(memberToDelete: MemberAdmin) {
        try {
            const membersFirestore: TOrganizationMember = {}

            Object.keys(this.firestoreData.members).map(
                memberUid => {
                    if (memberUid !== memberToDelete.getUid) {
                        membersFirestore[memberUid] = this.getMemberByUid(memberUid)
                    }
                },
            )

            await updateOrganization(this, {
                members: membersFirestore,
            })

            this.setMembers = this.getMembers.filter(member => member.getId !== memberToDelete.getId)

            alertSuccess(i18n.t('Member deleted'))
        }
        catch (e) {
            alertError(i18n.t('Failed to delete member'))
            logger.error(e)
        }
    }

    updateInviteRole = async (inviteToUpdate: Invite, role: OrganizationMemberRoles) => {
        try {
            await updateInvite(inviteToUpdate, { role })

            this.setInvites = this.getInvites.map(invite => {
                if (invite.getId === inviteToUpdate.getId) {
                    invite.setRole = role

                    return invite
                }

                return invite
            })
        }
        catch (e) {
            logger.error(e)
        }
    }

    updateMemberRole = async (member: MemberAdmin, role: OrganizationMemberRoles) => {
        try {
            await updateMemberRole(this, member, role)

            this.setMembers = this.getMembers.map(memberItem => {
                if (memberItem.getUid === member.getUid) {
                    const memberFirestore: TMemberFirestore = {
                        email: member.getEmail,
                        fName: member.getFName,
                        lName: member.getLName,
                        uid: member.getUid,
                        avatar: member.getAvatar,
                    }
                    return new MemberAdmin(memberItem.getRef, memberFirestore, role)
                }

                return memberItem
            })
            const roleString = translateRoleValueToString(role)
            alertSuccess(i18n.t('Role of the member with email {{email}} has been changed to {{roleString}}', {
                email: member.getEmail,
                roleString,
            }))
        }
        catch (e) {
            logger.error(e)
        }
    }

    getMemberByUid(uid: string) {
        return this.firestoreData.members[uid]
    }

    get getInvites(): Invite[] {
        return this.invites
    }

    get getInvitesCount(): number {
        return this.invites.length
    }

    get id(): string {
        return this.ref.id
    }

    get getName(): string {
        return this.firestoreData.name
    }

    get getCover(): string | undefined {
        return this.firestoreData.cover
    }

    get getMembersCount(): number {
        return Object.keys(this.firestoreData.members).length
    }

    get getRef(): DocumentReference<OrganizationDocument> {
        return this.ref
    }

    get getMembers(): MemberAdmin[] {
        return this.members
    }

    get profilePhoto(): string | undefined {
        return this.firestoreData.assets?.profile_photo?.['@1']?.background
    }

    set setName(name: string) {
        this.firestoreData.name = name
    }

    set setInvites(invites: Invite[]) {
        this.invites = invites
    }

    set setMembers(members: MemberAdmin[]) {
        this.members = members
    }
}

export default OrganizationAdmin
