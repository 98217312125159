import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import {
    Field,
    FieldArray,
    FieldArrayRenderProps,
    FieldProps,
    FormikProvider,
    FormikState,
} from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Column } from '../../uiLayout/Column'


interface Props {
    /**
     * Field name
     */
    name: string
    /**
     * Formik's form (needed for FormikProvider to make FieldArray working)
     */
    form: any // TODO type
    /**
     * Render function for single field
     */
    renderSingleField?: (index: number, fieldProps: FieldProps) => React.ReactNode
    /**
     * Default value that will be added to array when create
     */
    defaultValue?: any
    /**
     * Text for add button
     */
    addButtonText?: string
    /**
     * Disables add button
     */
    disableAddButton?: boolean
}

/**
 * Handles array of single fields whuch are rendered by renderSingleField prop. Allows add new fields (TODO allow delete fields)
 */
export const MultiFormikField = ({name, form, renderSingleField, defaultValue, addButtonText = 'Add item', disableAddButton}: Props) => {
    const [ t ] = useTranslation()

    const values = form.values[name]

    return (
        <FormikProvider value={form}>
            <FieldArray name={name}>
                {(arrayHelpers: FieldArrayRenderProps) => (
                    <Column spacing={2}>
                        {values?.map((value: any, index: number) => (
                            <Field name={`${name}[${index}]`} key={`${name}[${index}]`}>
                                {(fieldProps: FieldProps) => renderSingleField?.(index, fieldProps)}
                            </Field>
                        ))}
                        {!disableAddButton && (
                            <Button
                                variant="text"
                                onClick={() => arrayHelpers.push(defaultValue)}
                                startIcon={<Add/>}
                                size="small"
                            >
                                {t(addButtonText)}
                            </Button>
                        )}
                    </Column>
                )}
            </FieldArray>
        </FormikProvider>
    )
}

