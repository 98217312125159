import React from 'react'

import { ZoomSlider } from '../../common/ZoomSlider'
import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'


const ZOOM_MIN = 1
const ZOOM_MAX = 10
const ZOOM_STEP = 1

const ZOOM_SLIDER_WIDTH_PX = 100

export const EpgScheduleEditorZoom = () => {
    const { itemHeightCoefficient, updateItemHeightCoefficient } = useEpgUI()

    return (
        <ZoomSlider
            min={ZOOM_MIN}
            max={ZOOM_MAX}
            step={ZOOM_STEP}
            value={itemHeightCoefficient}
            onChange={updateItemHeightCoefficient}
            zoomSliderWidthPx={ZOOM_SLIDER_WIDTH_PX}
        />
    )
}
