import {
    Button,
    Dialog,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { ScreenType } from '@tivio/types'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { createTranslation } from '../../creator/translation.creator'
import { TivioScreen } from '../../store/TivioScreen'
import { CopiableContent } from '../ads/CopiableContent'
import { AppThemeProvider } from '../AppThemeProvider'
import { Column } from '../uiLayout/Column'
import UpdatableTypography from '../UpdatableTypography'


type Props = {
    screen: TivioScreen
    dialogProps: DialogProps
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const ScreenIdTypography = styled(Typography)`
    cursor: pointer;
    width: 270px;
`

const ScreenDialog: React.FC<Props> = (props) => {
    const { dialogProps, screen } = props

    const [t] = useTranslation()

    const statuses = useMemo(() => {
        const items = Object.values(ScreenType).map(type => {
            return <MenuItem
                key={type}
                value={type}
            >
                {type}
            </MenuItem>
        })

        return (
            <>
                <Select
                    required
                    autoWidth
                    label={t('Type')}
                    value={screen.type}
                    defaultValue={ScreenType.DEFAULT}
                    onChange={event => screen.update({ type: event.target.value as ScreenType })}
                >
                    {items}
                </Select>
            </>
        )
    }, [screen.type, t])

    return (
        <AppThemeProvider type="light">
            <Dialog {...dialogProps}>
                <StyledDialogContent>
                    <CloseIcon onClick={() => props.dialogProps.onClose?.({}, 'backdropClick')}>
                        <Close/>
                    </CloseIcon>
                    <Column spacing={4}>
                        <Column spacing={2}>
                            <UpdatableTypography
                                onTextChange={text => screen.update({ name: createTranslation(text) })}
                                editOnClick
                            >
                                {screen.name.en ?? t('No name')}
                            </UpdatableTypography>
                            <UpdatableTypography
                                onTextChange={text => screen.update({ description: createTranslation(text) })}
                                editOnClick
                                typographyProps={{
                                    color: 'textSecondary',
                                    variant: 'caption',
                                }}
                            >
                                {screen.description?.en ?? t('No description')}
                            </UpdatableTypography>
                            <CopiableContent
                                as={ScreenIdTypography}
                                text={screen.screenId}
                            />
                            {statuses}
                        </Column>
                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            onClick={() => props.dialogProps.onClose?.({}, 'backdropClick')}
                        >
                            {t('Save')}
                        </Button>
                    </Column>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
}

export {
    ScreenDialog,
}
