import { GetVideoAnalyticsResult, VideoSourceField } from '@tivio/types'
import dayjs from 'dayjs'

import User from '../../../store/User'

import { PercentageBadgeVariant, VideoAnalyticsUser } from './types'


export const ANALYTICS_POLLING_INTERVAL = 60_000 as const
const MAX_DATA_POINTS = 28 as const


export function toVideoAnalyticsUser(user: User): VideoAnalyticsUser {
    return { id: user.id, email: user.email }
}

export function getPercentageBadgeVariant(percentage: number): PercentageBadgeVariant {
    if (percentage > 0) {
        return 'positive'
    }
    if (percentage < 0) {
        return 'negative'
    }

    return 'neutral'
}

export function getViewersPercentage(previousViewers?: number, currentViewers?: number) {
    return currentViewers && previousViewers
        ? Math.round((currentViewers - previousViewers) / previousViewers * 100)
        : 0
}

export function getPercentageToString(percentage: number) {
    return percentage >= 0
        ? `+${percentage}%`
        : `${percentage}%`
}

export function toChartData(metrics?: GetVideoAnalyticsResult) {
    if (!metrics || !Object.keys(metrics).length) {
        return emptyChartData()
    }

    const categories: string[] = []

    const patchedMetrics = getPatchedTimelineAndTrimmedMetrics(metrics)
    const occurrences = getPossibleMetricValueOccurrences(patchedMetrics)
    const occurrenceMap = createOccurrencesMap(occurrences)
    const timestamps = Object.keys(patchedMetrics).sort((a, b) => Number(a) - Number(b))

    for (const timestamp of timestamps) {
        occurrences.forEach(occurrence => {

            const value = patchedMetrics[timestamp].values.find(r => r.value === occurrence)
            occurrenceMap[occurrence].push(value ? Math.round(value.percentage) : 0)
        })

        categories.push(timestamp)
    }

    fillMapToBeOfMinimumLength(occurrenceMap)
    fillArrayToBeOfMinimumLength(categories, MAX_DATA_POINTS, (_, i) => {
        const lastMinute = Number(categories[categories.length - 1])
        const nextMinute = dayjs(lastMinute).add(i + 1, 'minute').toDate().getTime()

        return String(nextMinute)
    })
    const series = Object.keys(occurrenceMap).map(key => ({ name: key, data: occurrenceMap[key] }))

    return { series, categories }
}

export function reduceToGroupedSources(acc: Record<string, VideoSourceField[]>, curr: { name: string, sources: VideoSourceField[] | undefined }) {
    if (!curr?.sources) {
        return acc
    }

    acc[curr.name] = curr.sources
    return acc
}

function emptyChartData() {
    return {
        series: [{ name: 'No data', data: Array.from({ length: 28 }, () => 0) }],
        categories: Array.from({ length: 28 }, (_, i) => dayjs().add(i, 'minute').toDate().getTime()),
    }
}

function getPossibleMetricValueOccurrences(metrics: GetVideoAnalyticsResult) {
    return Object.values(metrics).reduce((acc, curr) => {
        curr.values.forEach(r => acc.add(r.value))
        return acc
    }, new Set<string>())
}

function createOccurrencesMap(occurrences: Set<string>) {
    return Array.from(occurrences)
        .reduce((acc: Record<string, number[]>, occurrence ) => {
            acc[occurrence] = []
            return acc
        }, {})
}

function fillMapToBeOfMinimumLength(occurrenceMap: Record<string, number[]>) {
    const value = 0

    Object.values(occurrenceMap).forEach(array => fillArrayToBeOfMinimumLength(array, MAX_DATA_POINTS, () => value))
}

function fillArrayToBeOfMinimumLength(array: unknown[], minArrayLength: number, mapFn: (value: unknown, index: number) => unknown) {
    if (array.length < minArrayLength) {
        const missingLength = minArrayLength - array.length
        array.push(...Array.from({ length: missingLength }, mapFn))
    }
}

function getPatchedTimelineAndTrimmedMetrics(metrics: GetVideoAnalyticsResult): GetVideoAnalyticsResult {
    const timestamps = Object.keys(metrics)
    const patchedMetrics: GetVideoAnalyticsResult = {}

    const startTimestamp = Number(timestamps[0])
    const endTimestamp = Number(timestamps[timestamps.length - 1])

    for (let currentTimestamp = endTimestamp, counter = 0;
        currentTimestamp >= startTimestamp && counter < MAX_DATA_POINTS;
        currentTimestamp -= 60_000, counter++) {
        const timestampKey = currentTimestamp.toString()
        if (!metrics[timestampKey]) {
            patchedMetrics[timestampKey] = { totalDuration: 0, values: [] }
            continue
        }

        patchedMetrics[timestampKey] = metrics[timestampKey]
    }

    return patchedMetrics
}
