import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { createTranslation } from '../../creator/translation.creator'
import MultiRowField from '../common/formField/MultiRowField'
import MultiTextField, { MultiTextFieldItem, translationToMultiTextFieldItem } from '../common/formField/MultiTextField'


interface SubscriptionBenefitsProps {
    benefits: MultiTextFieldItem[][],
    onChange: (benefits: MultiTextFieldItem[][]) => void,
}

const emptyBenefit = (): MultiTextFieldItem[] => translationToMultiTextFieldItem(createTranslation('')) // for new benefit adding
const useStyles = makeStyles({
    input: {
        width: '25rem',
    },
})

export const SubscriptionBenefits: React.FC<SubscriptionBenefitsProps> = ({ benefits, onChange }) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleAddOrRemove = (newBenefits: MultiTextFieldItem[][]) => {
        onChange(newBenefits)
    }

    const handleBenefitChange = (newTranslation: MultiTextFieldItem[], index: number) => {
        const newBenefits: MultiTextFieldItem[][] = [...benefits]
        newBenefits[index] = newTranslation
        onChange(newBenefits)
    }

    return (
        <>
            <MultiRowField
                items={benefits}
                itemTemplate={emptyBenefit}
                onAddOrRemove={handleAddOrRemove}
                addButtonText={t('Add Benefit')}
                render={(value: MultiTextFieldItem[], index: number) => (
                    <>
                        <MultiTextField
                            className={classes.input}
                            items={value}
                            onChange={(newTranslation) => handleBenefitChange(newTranslation, index)}
                            defaultIndex={2}
                        />
                    </>
                )}
            />

        </>
    )
}

export default SubscriptionBenefits

SubscriptionBenefits.displayName = 'SubscriptionBenefits'
