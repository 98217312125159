import { Chip, TextField } from '@material-ui/core'
import { Autocomplete, AutocompleteGetTagProps } from '@material-ui/lab'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next/hooks'


interface Props {
    options: any[]
    getOptionLabel: (option: any) => string
    defaultValue: any[]
    onChange: (event: ChangeEvent<any>, value: any[]) => void
}

/**
 * Wrapper for MaterialUI's Autocomplete component
 */
export const AutocompleteField = ({options, getOptionLabel, defaultValue, onChange}: Props) => {

    const [ t ] = useTranslation()

    const renderInput = (params: any) => {
        return (
            <TextField
                {...params}
                size="small"
                placeholder={t('Start typing')}
                variant="outlined"
            />
        )
    }

    const renderTags = (value: any[], getTagProps: AutocompleteGetTagProps) => {
        return (
            value.map((option, index) => (
                <Chip
                    variant="outlined"
                    size="small"
                    label={getOptionLabel(option)}
                    {...getTagProps({ index })}
                />
            ))
        )
    }


    return (
        <Autocomplete
            options={options}
            openOnFocus
            multiple
            filterSelectedOptions
            disableCloseOnSelect
            defaultValue={defaultValue}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            renderInput={renderInput}
            renderTags={renderTags}
        />
    )
}
