import { List } from '@material-ui/core'
import { ProcessDocument } from '@tivio/firebase'
import { VideoEncodingProcessLoading } from './VideoEncodingProcessLoading'


interface Props {
    encodingProcesses: ProcessDocument[]
}

export const VideoEncodingProcess = (props: Props) => {
    return (
        <List>
            {props.encodingProcesses.map((process, key) => (
                <VideoEncodingProcessLoading 
                    encodingProcess={process} 
                    key={key}
                />
            ))}
        </List>
    )
}