import { Logger } from '@tivio/common'
import { ALGOLIA_INDEX_NAME, UseSearchOptions } from '@tivio/types'
import { useFormik } from 'formik'
import { useEffect, useRef } from 'react'
import * as Yup from 'yup'

import Translator from '../../services/Translator'

import { useConfig } from './useConfig'
import { useSearch } from './useSearch'


const logger = new Logger('useSearchScreen')

const MIN_QUERY_LENGTH = 3
const DEBOUNCE_TIME_MS = 250

const validationSchema = Yup.object()
    .shape({
        query: Yup.string().min(MIN_QUERY_LENGTH, Translator.t('Query must at least {{length}} characters long', { length: MIN_QUERY_LENGTH })),
    })

function useSearchScreen(options: UseSearchOptions) {
    const { search } = useConfig()
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null)
    const {
        pagination: videosPagination,
        isLoading: videosLoading,
        error: videosError,
        search: searchVideos,
        lastQuery: videosLastQuery,
    } = useSearch<ALGOLIA_INDEX_NAME.VIDEOS>(ALGOLIA_INDEX_NAME.VIDEOS, options)

    const {
        pagination: tagsPagination,
        isLoading: tagsLoading,
        error: tagsError,
        search: searchTags,
        lastQuery: tagsLastQuery,
    } = useSearch<ALGOLIA_INDEX_NAME.TAGS>(ALGOLIA_INDEX_NAME.TAGS, options)
    const { values, errors, submitForm, setValues, resetForm, handleChange } = useFormik<{ query: string }>({
        validationSchema,
        initialValues: {
            query: '',
        },
        onSubmit: (values) => {
            if (values.query.length >= MIN_QUERY_LENGTH) {
                logger.info('search for', values.query)

                if (!search?.disableSearchInTags) {
                    searchTags(values.query)
                }

                searchVideos(values.query)
            }
        },
    })

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current)
        }
        debounceTimeoutRef.current = setTimeout(() => submitForm(), DEBOUNCE_TIME_MS)
    }, [submitForm, values.query])

    const setQuery = (query: string) => {
        setValues({ query })
    }

    return {
        videosPagination,
        videosError,
        videosLastQuery,
        tagsPagination,
        tagsError,
        tagsLastQuery,
        isLoading: videosLoading || tagsLoading,
        input: {
            errors,
            setQuery,
            handleChange,
            resetForm,
            query: values.query,
        },
    }
}

export {
    useSearchScreen,
}
