import firebase from 'firebase/app'

import Program from '../../store/Program'
import TvChannel from '../../store/TvChannel'
import { Interval } from '../../utils/epg.utils'
import { millisecondsToSeconds, secondsToMilliseconds } from '../../utils/time.utils'
import { getFirestore, loggerFirestore } from '../app'

import { epgConverter } from './converters'


export interface EpgProgramFirestore {
    id: string
    channelKey: string
    startTimestamp: firebase.firestore.Timestamp
    endTimestamp: firebase.firestore.Timestamp
    programName: string
}

export interface EpgProgram {
    id: string
    channelKey: string
    playTime: number
    name: string
    timeline: Interval
}

const getEpgCollection = () => {
    return getFirestore().collection('epg').withConverter(epgConverter)
}

export const twoWeeksAgo = (from?: number) => new Date(secondsToMilliseconds(millisecondsToSeconds(from ?? Date.now()) - 60 * 60 * 24 * 7 * 2))
// @TODO This shit is just super temporary. At least I hope. :D
export const oneWeekAgo = (from?: number) => new Date(secondsToMilliseconds(millisecondsToSeconds(from ?? Date.now()) - 60 * 60 * 24 * 7))

const listPrograms = async (tvChannel: TvChannel) => {
    loggerFirestore.info('Fetching epg programs for channel ', tvChannel.getName)

    try {
        const programsSnapshots = await getEpgCollection()
            .where('channelKey', '==', tvChannel.getChannelKey)
            .where('startTimestamp', '>=', oneWeekAgo())
            .get()

        return programsSnapshots.docs.map(
            programSnapshot => {
                const programData = programSnapshot.data()
                return new Program(programSnapshot.ref, programData, tvChannel)
            },
        )
    }
    catch (e) {
        throw new Error(e)
    }
}

export {
    getEpgCollection,
    listPrograms,
}
