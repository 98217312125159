import { LangCode, languages } from '@tivio/types'
import React, { useState } from 'react'

import Video from '../../store/Video'
import { ParagraphBlock, TranslationInputWrapper } from '../common/input'


interface Props {
    video: Video
    disabled?: boolean
}

export const VideoRichDescription = ({ video, disabled }: Props) => {
    const [language, setLanguage] = useState<LangCode>(languages[0])

    return (
        <TranslationInputWrapper
            language={language}
            isTranslationBlock
            translationValue={video.descriptionRich}
            setLanguage={setLanguage}
        >
            <ParagraphBlock
                block={video}
                language={language}
                isDisabled={disabled}
            />
        </TranslationInputWrapper>
    )
}
