import { Collapse } from '@material-ui/core'
import React, { ReactNode } from 'react'


interface Props {
    shown: boolean
    children: ReactNode | undefined
}

/**
 * Component allowing to hide content.
 *
 * @param children children
 * @param shown if children are shown
 */
const Content = ({ children, shown }: Props) =>
    <Collapse in={shown} timeout={0}>
        {children}
    </Collapse>


export default Content
