import { makeAutoObservable, onBecomeObserved, onBecomeUnobserved } from 'mobx'

import { getEnvironmentsQuery } from '../firebase/firestore/environment'
import { addVersionRef, getSecretsQuery, removeVersionRef } from '../firebase/firestore/secrets'
import { addVersion, getVersion, removeVersion } from '../firebase/firestore/versions'

import Environment from './Environment'
import Target from './Target'

import type Organization from './Organization'


const TARGETS = ['core-react-dom', 'core-js']

class Deploy {
    organization: Organization
    readyState: 'loading' | 'ready' = 'loading'
    environments: Environment[] = []
    targets: Target[] = []

    constructor (organization: Organization) {
        this.organization = organization
        makeAutoObservable(this)
        onBecomeObserved(this, 'readyState', this.resume.bind(this))
        onBecomeUnobserved(this, 'readyState', this.suspend.bind(this))
    }

    async resume () {
        const environmentDocs = await getEnvironmentsQuery(this.organization.ref)
        this.environments = await Promise.all(environmentDocs.map(async (doc) => {
            const environment = new Environment()
            environment.fromFirestore(doc)
            return environment
        }))
        const secretsDocs = await getSecretsQuery(this.organization.ref)

        // group secrets by a target
        const targets = secretsDocs.reduce((acc, secretDoc) => {
            const secret = secretDoc
            const data = secret.data()

            if (!acc.has(data.target)) {
                acc.set(data.target, [secret])
            } else {
                acc.get(data.target).push(secret)
            }

            return acc
        }, new Map())

        this.targets = await Promise.all(TARGETS.map(async (name) => {
            const target = new Target(this)
            await target.fromFirestore(name, targets.get(name) ?? [])
            return target
        }))

        this.readyState = 'ready'
    }

    async suspend () {
        this.readyState = 'loading'
    }

    async addRelease (targetName: string, bundle: string) {
        const organizationId = this.organization.id
        const versionId = bundle.replaceAll('.', '_')

        const secretIds = this.targets
            .find(({ name }) => name === targetName)
            ?.secrets.map((secretData) => secretData.id)

        if (!secretIds) {
            throw new Error('Data inconsistency')
        }

        await Promise.all(secretIds.map(async (secretId) => {
            const data = {
                name: '-',
                description: '-',
                percentage: 0,
            }
            const ref = await addVersion(versionId, organizationId, secretId, data)
            await addVersionRef(organizationId, secretId, ref)
        }))

        await this.resume()
    }

    async removeBundle (target: Target, bundle: string) {
        const organizationId = this.organization.id
        const versionId = bundle.replaceAll('.', '_')
        const secretIds = target.secrets.map((secretData) => secretData.id)

        await Promise.all(secretIds.map(async (secretId) => {
            const ref = await getVersion(versionId, organizationId, secretId)
            await removeVersionRef(organizationId, secretId, ref)
            await removeVersion(versionId, organizationId, secretId)
        }))

        await this.resume()
    }
}

export default Deploy
