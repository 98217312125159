import { useEffect, useRef } from 'react'


export const useHorizontalScroll = <T extends HTMLElement>() => {
    const ref = useRef<T>(null)

    useEffect(() => {
        const element: T | null = ref.current

        if (element) {
            const onWheel = (evt: WheelEvent) => {
                if (evt.deltaY === 0) {
                    return
                }
                if (
                    !(element.scrollLeft === 0 && evt.deltaY < 0) &&
                    !(element.scrollWidth - element.clientWidth - Math.round(element.scrollLeft) === 0 && evt.deltaY > 0)
                ) {
                    evt.preventDefault()
                }
                const delta = Math.abs(evt.deltaY * 0.5) > 100 ? 100 : evt.deltaY * 0.5

                element.scrollTo({
                    left: element.scrollLeft + delta,
                    behavior: 'auto',
                })
            }
            element.addEventListener('wheel', onWheel)

            return () => element.removeEventListener('wheel', onWheel)
        }
    }, [ref])

    return ref
}
