import { AvailableSeason } from '@tivio/types'


export const mapAvailableSeasonLabel = (translationFunc: (key: string) => string) => (season: AvailableSeason) => {
    const label = season.seasonName
        ? season.seasonName
        : `${translationFunc('Season')} ${season.seasonNumber}`

    return { id: season.seasonNumber, label }
}
