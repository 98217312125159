export const formatPadStart = (value: string | number, targetLength: number, padString: string) => {
    if (typeof value === 'number') {
        return String(value).padStart(targetLength, padString)
    } else if (typeof value === 'string') {
        return value.padStart(targetLength, padString)
    }
}

export const isString = (value: unknown): value is string => {
    return typeof value === 'string'
}
