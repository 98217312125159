import { Chip, Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'

import type { Resolution } from '../../store/Video'


interface VideoResolutionsProps {
    resolutions: Resolution[]
}

const resolutionCompare = (a: Resolution, b: Resolution) => {
    const aResolution = parseInt(a.resolution)
    const bResolution = parseInt(b.resolution)

    if (aResolution > bResolution) {
        return 1
    } else if (aResolution < bResolution) {
        return -1
    }

    return 0
}

const VideoResolutions: React.FC<VideoResolutionsProps> = observer(({ resolutions }: VideoResolutionsProps) => {
    if (!resolutions.length) {
        return null
    }

    const orderedResolutions = resolutions.sort(resolutionCompare)

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            spacing={1}
        >
            { orderedResolutions.map((resolution) => (
                <Grid item key={resolution.resolution}>
                    <Chip
                        label={resolution.resolution}
                        variant={resolution.progress === 100 ? 'default' : 'outlined'}
                        color={resolution.progress === 100 ? 'primary' : undefined}
                        size="small"
                    />
                </Grid>
            )) }
        </Grid>
    )
})

VideoResolutions.displayName = 'VideoResolutions'

export default VideoResolutions
