import { Container, MenuItem, Select } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import styled from 'styled-components'

import { Template } from '../../../store/Template'
import UpdatableTypography from '../../UpdatableTypography'
import { NotificationImage } from '../shared/common'

import { EmailNotificationForm } from './EmailNotification'

import type { FormikProps } from 'formik'
import { TivioNotification } from '../../../store/Notification'
import { observer } from 'mobx-react'
import { NotificationStatusBadge } from '../shared/NotificationStatusBadge'


export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 2rem 0;
`

const StatusWrapper = styled.div`
    padding: 0.5rem 0;
`

const InputWrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-width: 400px;
    margin: 0;

    &:not(:first-child) {
        margin-top: 1rem;
    }
`

interface Props {
    notification: TivioNotification
    templates: Template[]
}

export const TemplatePicker = observer(({ notification, templates }: Props) => {
    const [t] = useTranslation()
    const { template } = notification
    return (
        <Wrapper>
            <NotificationImage src={template.thumbnailUrl} />
            <InputWrapper>
                <StatusWrapper>
                    <NotificationStatusBadge notification={notification} />
                </StatusWrapper>
                <UpdatableTypography
                    onTextChange={(text: string) => {
                        notification.name = text
                    }}
                    editOnClick
                >
                    {notification.name}
                </UpdatableTypography>
                <Select
                    id="templateId"
                    name="templateId"
                    label={t('Template')}
                    defaultValue={template.templateId}
                    value={notification.template.templateId}
                    onChange={(e) => {
                        notification.templateId = e.target.value as string
                    }}
                >
                    {templates?.map((template) => (
                        <MenuItem
                            key={template.templateId}
                            value={template.templateId}
                        >
                            {template.name}
                        </MenuItem>
                    ))}
                </Select>
            </InputWrapper>
        </Wrapper>
    )
})
