import {
    Box,
    Grid,
    IconButton,
    makeStyles,
    Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { LangCode } from '@tivio/types'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'
import { Application } from '../../store/Application'
import { getTranslation } from '../../utils/translate.utils'
import { useListItemStyles } from '../common/styles'


interface Props {
    application: Application
    languages: LangCode[]
    onEdit: (application: Application) => void
    onDelete: (application: Application) => void
}

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
    },
    applicationMain: {
        fontSize: '1rem',
    },
    applicationInfo: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    blockIcon: {
        display: 'block',
    },
    tooltip: {
        fontSize: '0.75rem',
        backgroundColor: 'white',
        color: COLORS.DRAWER_BORDER,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}))

export const ApplicationsItem: React.FC<Props> = ({ application, languages, onEdit, onDelete }) => {
    const listItemClasses = useListItemStyles()
    const classes = useStyles()
    const [t] = useTranslation()
    const [isHovering, setIsHovering] = useState(false)

    const handleMouseEnter = () => {
        setIsHovering(true)
    }

    const handleMouseLeave = () => {
        setIsHovering(false)
    }

    const handleEditClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onEdit(application)
    }

    const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onDelete(application)
    }

    const screenCount = application.applicationScreens.length
    const tooltipTitle = application.applicationScreens.map((screen) => getTranslation(screen.name, languages)).join(', ')

    return (
        <Box my={2}>
            <Grid
                container
                alignItems="center"
                spacing={2}
                component="a"
                justifyContent="space-between"
                className={clsx(listItemClasses.root, classes.root)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleEditClick}
                title={t('Edit application')}
            >
                <Grid
                    item
                    className={classes.applicationMain}
                    xs={5}
                >
                    {application.isTivioProApplication ? 'Stargaze' : getTranslation(application.name, languages)}
                </Grid>
                <Grid
                    item
                    className={classes.applicationInfo}
                    xs={3}
                >
                    ID {application.id}
                </Grid>
                <Grid
                    item
                    className={classes.applicationInfo}
                    xs={2}
                >
                    <Tooltip
                        title={tooltipTitle}
                        placement="bottom"
                        classes={{
                            tooltip: classes.tooltip,
                        }}
                    >
                        <span>
                            {screenCount} {screenCount === 1 ? t('Screen') : t('Screens')}
                        </span>
                    </Tooltip>
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        className={clsx(listItemClasses.showOnHover, { isHover: isHovering })}
                    >
                        <Grid item>
                            <IconButton
                                size="small"
                                title={t('Edit application')}
                                onClick={handleEditClick}
                                className={classes.blockIcon}
                            >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                size="small"
                                disabled={application.isTivioProApplication}
                                title={t('Delete application')}
                                onClick={handleDeleteClick}
                                className={classes.blockIcon}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
