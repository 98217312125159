import { notEmptyFilter } from '@tivio/common'
import { AssetPresetPlacement } from '@tivio/firebase'
import React, { ChangeEvent } from 'react'

import { AssetPreset } from '../../../store/AssetPreset'
import { AutocompleteField } from '../../common/formField/AutocompleteField'
import { useOrganization } from '../../hooks'
import { useAllAssetPresets } from '../presets/hooks'

import type { AssetPresetDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


interface Props {
    defaultValue: firebase.firestore.DocumentReference<AssetPresetDocument>[]
    onChange: (event: ChangeEvent<any>, value: firebase.firestore.DocumentReference<AssetPresetDocument>[]) => void
}

export const AssetPresetsAutocomplete = ({defaultValue, onChange}: Props) => {
    const assetPresets = useAllAssetPresets({placement: AssetPresetPlacement.TAG})

    const { organization } = useOrganization()

    const value = defaultValue
        .map(assetPresetRef => assetPresets.find(preset => preset.id === assetPresetRef.id))
        .filter(notEmptyFilter)

    const handleChange = (event: ChangeEvent<any>, value: AssetPreset[]) => {
        // TODO TIV-692 NICE TO HAVE change to preset.ref when it will contains ref
        onChange(event, value.map(preset => preset.getRef(organization?.id) as firebase.firestore.DocumentReference<AssetPresetDocument>))
    }

    return (
        <AutocompleteField
            options={assetPresets}
            getOptionLabel={option => option.name}
            defaultValue={value}
            onChange={handleChange}
        />
    )
}
