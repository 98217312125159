import {
    Button,
    ButtonGroup,
    OutlinedInput,
    Theme,
    TypographyVariant,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
    LangCode,
    languages,
    LanguageType,
    Translation,
    VideoUrlNames,
} from '@tivio/types'
import clsx from 'clsx'
import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import type { OutlinedInputProps } from '@material-ui/core'
/**
 * Every item in MultiTextField needs label visible to user,
 * keyName as unique identifier for each item
 * and textValue as value of text field.
 */
export type MultiTextFieldItem = {
    /**
     * Label of the switch button.
     */
    label: string,
    /**
     * Unique identifier of the item.
     */
    keyName: string,
    /**
     * Value of the text field.
     */
    textValue: string,
}

/**
 * Own type validation object for multi text input
 */
export type MultiTextFieldValidationSchema = {
    [key in LangCode]?: boolean;
};


/**
 * React properties of MultiTextField.
 */
interface MultiTextFieldProps extends Omit<OutlinedInputProps, 'onChange'> {
    /**
     * Array of items to be displayed in MultiTextField.
     */
    items: MultiTextFieldItem[]
    /**
     * Function to be called when the state of MultiTextField changes.
     */
    onChange: (newVariant: MultiTextFieldItem[]) => void
    /**
     * An index of the item to be displayed as active. First item is used when no default is provided.
     */
    defaultIndex?: number
    /**
     * CSS class name of the input field.
     */
    className?: string

    /**
     * If true, textarea is used instead of the standard input element.
     */
    multiline?: boolean

    /**
     * Maximum length of a string of a single translation.
     */
    maxLength?: number

    /**
     * Font style overrides for the input.
     */
    typographyVariant?: TypographyVariant

    /**
     * Object with flag which lang codes are valid
     */
    isValidFlags?: MultiTextFieldValidationSchema
}

/**
 * Transforms translation object to MultiTextFieldItem.
 */
export const translationToMultiTextFieldItem = (translation: Translation, availableLanguages?: string[]): MultiTextFieldItem[] => {
    return languages
        .filter((translationKey: keyof Translation) => 
            !availableLanguages || availableLanguages.includes(translationKey),
        )
        .map((translationKey: keyof Translation): MultiTextFieldItem => ({
            label: translationKey.toUpperCase(),
            keyName: translationKey,
            textValue: translation[translationKey] ?? '',
        }))
}

/**
 * Transforms MultiTextFieldItem to translation object.
 */
export const multiTextFieldItemToTranslation = (items: MultiTextFieldItem[]): Translation => {
    const translation: Translation = items.reduce<Translation>((acc, item: MultiTextFieldItem): Translation => {
        acc[item.keyName as LanguageType] = item.textValue
        return acc
    }, {} as Translation)

    return translation
}


/**
 * Transforms urlName object to MultiTextFieldItem.
 */
export const urlNameToMultiTextFieldItem = (urlName: VideoUrlNames): MultiTextFieldItem[] => {
    return languages.map((urlNameKey: keyof VideoUrlNames): MultiTextFieldItem => ({
        label: urlNameKey.toUpperCase(),
        keyName: urlNameKey,
        textValue: urlName[urlNameKey]?.[0] ?? '',
    }))
}

/**
 * Transforms MultiTextFieldItem to urlName object.
 */
export const multiTextFieldItemToUrlName = (items: MultiTextFieldItem[]): VideoUrlNames => {
    const urlName: VideoUrlNames = items.reduce<VideoUrlNames>((acc, item: MultiTextFieldItem): VideoUrlNames => {
        acc[item.keyName as LanguageType] = [item.textValue]
        return acc
    }, {} as VideoUrlNames)

    return urlName
}

/**
 * Transforms labels to one string. We need this to set the width of the legend element.
 */
const labelsToString = (items: MultiTextFieldItem[]) => {
    return '-' + items.map(item => item.label).join('--') + '-'
}


type TypographyCssKey = 'fontSize' | 'fontWeight'

/**
 * Maps CSS key name to themed typography variant CSS value
 */
const mapTypographyCss = (theme: Theme, key: TypographyCssKey): (props: MultiTextFieldProps) => any => {
    return ({ typographyVariant }) => typographyVariant ? theme.typography[typographyVariant][key] : undefined
}

const useStyles = makeStyles<Theme, MultiTextFieldProps>((theme) => ({
    root: {
        display: 'inline-block',
        position: 'relative',
    },
    fullWidth: {
        display: 'block',
    },
    legend: {
        textTransform: 'uppercase',
        fontSize: '16px',
    },
    label: {
        position: 'absolute',
        top: '-13px',
        left: '13px',
        zIndex: 1,
    },
    button: {
        color: theme.palette.text.disabled,
        padding: '2px 4px',
        fontSize: '0.75rem',
        minWidth: 0,
        borderRadius: '2px',
        opacity: 0.85,
        '&:not(:last-child), &:not(:first-child)': {
            border: 'none',
            borderRadius: '2px',
        },
    },
    buttonActive: {
        fontWeight: 'bold',
        opacity: 1,
    },
    buttonFilled: {
        color: theme.palette.primary.main,
    },
    buttonError: {
        color: theme.palette.error.main,
    },
    textField: {
        fontSize: mapTypographyCss(theme, 'fontSize'),
        fontWeight: mapTypographyCss(theme, 'fontWeight'),
    },
}))

/**
 * One text field with multiple values like language translations, currencies, etc. Text field has a switcher to edit one value at a time.
 */
const MultiTextField: React.FC<MultiTextFieldProps> = (props) => {
    const { items, defaultIndex, onChange, className, multiline, typographyVariant, maxLength, isValidFlags, ...restProps } = props
    const initVariant = defaultIndex || 0 // if no defaultVariant is set we use first item
    const [currentIndex, setIndex] = useState<number>(initVariant) // current active index
    const legendString = labelsToString(items)
    const classes = useStyles(props)
    const inputElement = useRef<HTMLInputElement>(null)

    // TODO: Temporary fix for cursor jumping to the end when typing
    // Use solution from HeadingBlock / BlockWrapper, as it doesn't use a hack
    // The bug of cursor jumping to the end happens when the value is changed asynchronously in another tick from the actual input change
    // that way react can't track the cursor position and it jumps to the end, so by having a temp value we can avoid this
    const inputValue = useMemo(() => items[currentIndex].textValue, [items, currentIndex])
    const [tempInputValue, setTempInputValue] = useState<string>(inputValue)

    useEffect(() => {
        setTempInputValue(inputValue)
    }, [inputValue])

    const handleVariantChange = (keyName: string) => {
        setIndex(items.findIndex(item => item.keyName === keyName))
        inputElement.current?.focus()
    }

    const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newVariant: MultiTextFieldItem[] = [...items]
        let newValue = event.target.value
        if (maxLength && newValue.length > maxLength) {
            newValue = newValue.slice(0, maxLength)
        }
        newVariant[currentIndex].textValue = newValue
        setTempInputValue(newValue)
        onChange?.(newVariant)
    }

    const getButtonClassNames = (item: MultiTextFieldItem, index: number) => {
        const applyClassForFilledButton = !!item.textValue && isValidFlags?.[item.keyName as LangCode] !== false
        return clsx(
            classes.button,
            {
                [classes.buttonFilled]: applyClassForFilledButton,
                [classes.buttonActive]: index === currentIndex,
                [classes.buttonError]: isValidFlags?.[item.keyName as LangCode] === false,
            })
    }

    const getIsInputInError = () => {
        if (isValidFlags) {
            return Object.values(isValidFlags).some(isLangError => isLangError === false)
        }
        return false
    }

    return (
        <div
            className={clsx({
                [classes.root]: true,
                [classes.fullWidth]: restProps.fullWidth,
            })}
        >
            <ButtonGroup
                className={classes.label}
                variant="text"
                size="small"
            >
                {items.map((item, index) => (
                    <Button
                        key={item.keyName}
                        onClick={() => handleVariantChange(item.keyName)}
                        className={getButtonClassNames(item, index)}
                    >
                        {item.label}
                    </Button>
                ))}
            </ButtonGroup>

            <OutlinedInput
                classes={{
                    root: `${className}  ${classes.textField}`,
                    notchedOutline: classes.legend,
                }}
                value={tempInputValue}
                placeholder={items[currentIndex].textValue === '' ? items[initVariant].textValue : undefined}
                onChange={handleFieldChange}
                margin="dense"
                notched={true}
                inputRef={inputElement}
                label={legendString}
                fullWidth
                multiline={multiline}
                error={getIsInputInError()}
                {...restProps}
            />
        </div>
    )
}

export default MultiTextField
