import { Typography } from '@material-ui/core'
import React, { FC } from 'react'
import styled from 'styled-components'

import type { BannerPropsPartial, CoverPadding } from '@tivio/types'


const Wrapper = styled.div<{ coverPadding?: CoverPadding }>`
    width: 100%;
    ${({ coverPadding }) => coverPadding?.top && `padding-top: ${coverPadding?.top}px`};
    ${({ coverPadding }) => coverPadding?.bottom && `padding-bottom: ${coverPadding?.bottom}px`};
    ${({ coverPadding }) => coverPadding?.left && `padding-left: ${coverPadding?.left}px`};
    ${({ coverPadding }) => coverPadding?.right && `padding-right: ${coverPadding?.right}px`};
`

const Body = styled.div<BannerPropsPartial>`
    position: relative;
    ${({ height }) => `height: ${height ? `${height}px` : '100%'};`}
    border-radius:  ${({ borderRadius }) => borderRadius ?? '15px'}

    box-sizing: border-box;
    transition: all .2s ease-in-out;
    // Content alignment
    display: flex;
    align-items: center;
    padding: 30px 75px;
    // Focus
    ${({ theme, focused }) => focused && `
        border: 5px solid ${theme.palette.primary.main};
    `}
    &:hover {
        border: 5px solid ${props => props.theme.palette.primary.main};
        cursor: pointer
    }
`

const Text = styled(Typography)`
    color: #FFFFFF;
`

const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: baseline;
    padding-top: 20px;
    z-index: 1;
`

const Image = styled.div<{cover?: string | null, overlay?: boolean, width?: string}>`
    width: ${props => props.width};
    height: 100%;

    position: absolute;
    right: 0;
    bottom: 0;

    // Background
    background-image: linear-gradient(#2C2C2C, #141414);
    ${({ cover, overlay }) => `background:
    ${overlay ? 'linear-gradient(to right, rgba(0, 0, 0, 1.0), rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),' : ''}
    ${cover && `url(${cover}) no-repeat center center`}`};
    background-color: #2B2B2B;
    background-size: cover;
    background-clip: padding-box;
`

// TODO Duplicates same component in @tivio/core-react-dom. Remove this when integrating core-react-dom into admin.
export const Banner: FC<BannerPropsPartial & {imageWidth: string}> = ({
    height = 490,
    overlay,
    cover,
    onClick,
    text,
    focused,
    isLoading,
    borderRadius = '15px',
    imageWidth = '100%',
}) => {
    return (<Wrapper {...{ onClick, isLoading }}>
        <Body {...{ height, focused, borderRadius }}>
            <Image cover={cover ?? undefined} overlay={overlay} width={imageWidth}/>
            <InfoWrapper>
                <Text variant="h3">
                    {text}
                </Text>
            </InfoWrapper>
        </Body>
    </Wrapper>)
}
