import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { PercentageBadgeVariant } from './types'


enum VARIANT_TEXT_COLORS {
    POSITIVE = '#6dd400',
    NEGATIVE = '#ea3500',
    NEUTRAL = '#D8D8D8'
}

enum VARIANT_BG_COLORS {
    POSITIVE = 'rgba(109, 212, 0, .25)',
    NEGATIVE = 'rgba(234, 53, 0, .25)',
    NEUTRAL = 'rgba(98, 104, 120, .25)'
}

const BadgeText = styled.span<{ variant: PercentageBadgeVariant }>`
    width: 2.313rem;
    height: 1.25rem;
    font-size: 0.75rem;
    text-align: center;
    color: ${props => {
        if (props.variant === 'positive') {
            return VARIANT_TEXT_COLORS.POSITIVE
        }
        if (props.variant === 'negative') {
            return VARIANT_TEXT_COLORS.NEGATIVE
        }

        return VARIANT_TEXT_COLORS.NEUTRAL
    }};
`

const BadgeBody = styled.span<{ variant: PercentageBadgeVariant }>`
        display: inline-block;
        border-radius: 3px;
        padding: 0.1rem;
        background-color: ${props => {
        if (props.variant === 'positive') {
            return VARIANT_BG_COLORS.POSITIVE
        }
        if (props.variant === 'negative') {
            return VARIANT_BG_COLORS.NEGATIVE
        }

        return VARIANT_BG_COLORS.NEUTRAL
    }};
`

export const Badge = ({ variant, children }: { variant: PercentageBadgeVariant } & PropsWithChildren) => {
    return(
        <BadgeBody variant={variant}>
            <BadgeText variant={variant}>{children}</BadgeText>
        </BadgeBody>
    )
}
