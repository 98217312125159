import { ButtonBase } from '@material-ui/core'
import React from 'react'

import { goVideoPage } from '../../utils/history.utils'
import { useVideo } from '../hooks/dataHooks/useVideo'

import { useStyles } from './userStyles'


interface Props {
    videoId: string
}

export const UserPurchaseItemVideo = ({ videoId }: Props) => {
    const { video } = useVideo(videoId)
    const classes = useStyles()

    if (!video) {
        return null
    }

    const imageSrc = video.getCover

    return (
        <ButtonBase
            className={classes.videoItem}
            onClick={() => goVideoPage(videoId)}
        >
            {imageSrc && <img
                className={classes.videoItemImage}
                src={video.getCover}
                alt={video?.getName}
            />}
            <p>{video?.getName}</p>
        </ButtonBase>
    )
}
