import { Grid, InputAdornment, OutlinedInput } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Currency } from '@tivio/types'
import React, { ChangeEvent, useMemo } from 'react'

import { COLORS } from '../../static/enum'
import { useOrganization } from '../hooks'
import { notEmptyFilter } from '../../utils/array.utils'


const CURRENCIES = [
    {
        code: 'CZK',
        glyph: 'Kč',
        label: 'CZK',
    },
    {
        code: 'EUR',
        glyph: '€',
        label: 'EUR',
    },
    {
        code: 'USD',
        glyph: '$',
        label: 'USD',
    },
]

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'uppercase',
    },
    input: {
        width: '14rem',
        '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
        },
        '& input': {
            MozAppearance: 'textfield',
        },
    },
    icon: {
        fontSize: '1rem',
    },
    label: {
        marginRight: theme.spacing(2),
        color: COLORS.SECONDARY,
    },
}))

type SimplePriceInputProps = {
    onChange: (price: { [currency: string]: number | null }) => Promise<void> | void
    value: { [currency: string]: number | null }
}

export const SimplePriceInput: React.FC<SimplePriceInputProps> = ({ value, onChange }) => {
    const { organization } = useOrganization()
    const classes = useStyles()

    const prices = CURRENCIES
        .reduce<{ [currency: string]: string }>((acc, { code }) => {
            acc[code] = `${value[code] ?? ''}`
            return acc
        }, {})

    const updateValue = (prices: { [currency: string]: string }) => {
        const updatedValue = CURRENCIES
            .reduce<{ [currency: string]: number | null }>((acc, { code }) => {
                const price = parseFloat(prices[code])
                acc[code] = isNaN(price) ? null : price
                return acc
            }, {})

        onChange(updatedValue)
    }

    const handleChangePrice = (code: string) => (event: ChangeEvent<HTMLInputElement>) => {
        const re = /^\d+(?:[.,]\d*)?$/
        const value = event.target.value.trim()
        if (value === '') {
            const updatedPrices = { ...prices }
            delete updatedPrices[code]
            updateValue(updatedPrices)
        } else if (re.test(value) && !isNaN(parseFloat(value))) {
            const updatedPrices = {
                ...prices,
                [code]: event.target.value,
            }
            updateValue(updatedPrices)
        }
    }

    const currenciesInputsList = useMemo(() => {
        return organization ? organization.currencies.map((code) => CURRENCIES.find((item) => item.code === code)).filter(notEmptyFilter) : CURRENCIES
    }, [organization])

    return (
        <Grid
            container
            direction="column"
            spacing={1}
        >
            {currenciesInputsList.map(({ code, glyph, label }) => (
                <Grid
                    key={code}
                    item
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        <Grid
                            item
                            xs={1}
                            className={classes.label}
                        >
                            {label}
                        </Grid>
                        <Grid item>
                            <OutlinedInput
                                value={prices[code]}
                                type="number"
                                className={classes.input}
                                margin="dense"
                                startAdornment={
                                    <InputAdornment position="start">
                                        {glyph}
                                    </InputAdornment>
                                }
                                onChange={handleChangePrice(code)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    )
}

export default SimplePriceInput
