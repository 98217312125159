import { PlayerCapability, PurchaseStatus, VideoSourceCodec, VideoSourceEncryption, VideoSourceProtocol } from '@tivio/types'

import Purchase from '../../store/Purchase'
import User from '../../store/User'
import { getFirestore, loggerFirestore } from '../app'

import { purchaseConverter } from './converters'


const purchasesCollection = (userId: string) => {
    const db = getFirestore()
    return db.collection('users').doc(userId).collection('purchases').withConverter(purchaseConverter)
}

/**
 * Loads all purchases of a user and creates stores of them.
 * @param user User store.
 * @returns Purchases or undefined if user has no purchase.
 */
const loadPurchases = async (user: User) => {
    try {
        // TODO: load from DB
        const snapshot = await purchasesCollection(user.id).orderBy('created', 'desc').get()

        if (snapshot.empty) {
            loggerFirestore.info('No purchase made by this user')
            return // No purchases
        }

        const allPurchases = snapshot.docs.map((purchaseSnapshot) => {
            const purchaseData = purchaseSnapshot.data()
            return new Purchase(purchaseSnapshot.ref, purchaseData, user)
        })

        return allPurchases
    } catch (e) {
        loggerFirestore.error('Failed to load purchases', e)
        throw new Error(e)
    }
}

/**
 * Sets given purchase status to the purchase.
 *
 * @param purchase Purchase store.
 * @param status Purchase status to set to the purchase.
 * @throws If there was an error during DB update.
 */
const changePurchaseStatus = async (purchase: Purchase, status: PurchaseStatus) => {
    const purchaseRef = purchase.ref
    try {
        loggerFirestore.info(`Setting ${purchaseRef.id} purchase status to ${status}`)
        const fieldsToUpdate = {
            status,
            updated: new Date(),
            ...(status === PurchaseStatus.CANCELLED &&
                purchase.type === 'subscription' && {
                expired: new Date(0),
            }),
        }

        await purchaseRef.update(fieldsToUpdate)
        return fieldsToUpdate
    } catch (e) {
        loggerFirestore.error(`Failed to set ${purchaseRef.id} purchase status to ${status}`, e)
        throw new Error(e)
    }
}

export const nonDrmSources: PlayerCapability[] = [
    {
        codec: VideoSourceCodec.H264,
        encryption: VideoSourceEncryption.NONE,
        protocol: VideoSourceProtocol.DASH,
    },
    {
        codec: VideoSourceCodec.H264,
        encryption: VideoSourceEncryption.NONE,
        protocol: VideoSourceProtocol.HLS,
    },
]

export { loadPurchases, changePurchaseStatus }
