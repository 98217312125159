import { chunkArray } from '@tivio/common'
import firebase from 'firebase/app'
import { nanoid } from 'nanoid'

import { TivioRow } from '../../store/TivioRow'
import { TivioScreen } from '../../store/TivioScreen'
import {
    FirebaseDocumentData,
    FirestoreDocumentSnapshot,
    FirestoreQuery,
    getFirestore,
    loggerFirestore,
} from '../app'

import type { ScreenRowDocument, ScreenRowDocumentCreation } from '@tivio/firebase'
import type { RowFilterField, RowFilterWhereField } from '@tivio/types'


export const getRowsCollection = (organizationId: string) => getFirestore()
    .collection(`organizations/${organizationId}/rows`)
    .withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): ScreenRowDocument => {
            return snapshot.data() as ScreenRowDocument
        },
        toFirestore: (rowFirestore: ScreenRowDocument): FirebaseDocumentData => {
            return rowFirestore
        },
    })

/**
 * Returns rows belonging to given screen.
 *
 * @param screen screen store
 */
export const getRowsByScreen = async (screen: TivioScreen) => {
    const rowsSnapshot = await getRowsCollection(screen.organization.id)
        .where(
            'screenRef',
            '==',
            screen.ref,
        )
        .orderBy('order', 'asc')
        .get()

    return rowsSnapshot.docs.map(
        rowsSnapshot => (
            new TivioRow(
                rowsSnapshot.ref,
                rowsSnapshot.data(),
                screen,
            )
        ),
    )
}

/**
 * Creates new row (firebase document) with generated rowId for organization with provided id.
 * Returns constructed TivioRow entity.
 *
 * @param organizationId id of organization
 * @param screen screen to which will be added new row
 * @param rowData new row data
 */
export const addTivioRow = async (
    organizationId: string,
    screen: TivioScreen,
    rowData: ScreenRowDocumentCreation,
): Promise<TivioRow> => {
    const newRowData = { ...rowData, rowId: `row-${nanoid()}` }
    const ref = await addRowToFirestore(organizationId, newRowData)

    return new TivioRow(ref, newRowData, screen, true)
}

/**
 * Adds new row to rows collection of organization (firebase document) with provided id.
 * Returns reference to created row.
 *
 * @param organizationId id of organization
 * @param rowData new row data
 */
const addRowToFirestore = async (
    organizationId: string,
    rowData: ScreenRowDocument,
): Promise<firebase.firestore.DocumentReference<ScreenRowDocument>> => {
    const ref = await getRowsCollection(organizationId).add(rowData)

    loggerFirestore.info(`row with id ${ref.id} created`)
    return ref
}

export const getQueryByWhereFilters = <T>(filters: RowFilterWhereField[], collection: FirestoreQuery<T>): FirestoreQuery<T> => {
    return filters.reduce(
        (previousValue, currentValue) => {
            return (Array.isArray(currentValue.value) && currentValue.value.length === 0)
                ? previousValue
                : previousValue.where(currentValue.fieldPath, currentValue.opStr, currentValue.value)
        },
        collection,
    )
}

export const getQueryByFilter = <T>(filter: RowFilterField, collection: FirestoreQuery<T>): FirestoreQuery<T> => {
    let query = getQueryByWhereFilters(filter.where ?? [], collection)

    if (filter.orderBy) {
        query = query.orderBy(filter.orderBy.fieldPath, filter.orderBy.directionStr)
    }

    return query
}

/**
 * Returns documents with given ids.
 * Fetch them in bathes of 10 ids due to limit of 'in' filter.
 *
 * @param collection documents collection
 * @param ids documents ids
 */
export const getDocumentsByIds = async (collection: string, ids: string[]) => {
    const chunks = chunkArray(ids, 10)

    const result = await Promise.all(chunks.map(idsChunk =>
        getFirestore().collection(collection)
            .where(firebase.firestore.FieldPath.documentId(), 'in', idsChunk)
            .get(),
    ))

    return result.flat()
}
