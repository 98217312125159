import {
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
} from '@material-ui/core'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { useField } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import type { OutlinedInputProps } from '@material-ui/core/OutlinedInput/OutlinedInput'


type TProps = {
    name: string,
    secured?: boolean,
    dataE2e?: string
} & OutlinedInputProps

/**
 * App input field component
 * Serves for handling user text input
 *
 * NOTE: Use only inside Formik component
 */
function AppInput(props: TProps) {
    const [ t ] = useTranslation()
    const [ securedText, setSecuredText ] = useState<boolean>(false)
    const [ field, meta ] = useField(props.name)

    const getTextFieldProps = () => {
        return props.multiline
            ? {
                multiline: true,
                rows: 6,
            } :
            {}
    }

    const handleClickShowPassword = () => {
        setSecuredText(!securedText)
    }

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault()
    }

    const getInputLabel = () => {
        return (
            <InputLabel>
                {props.label}
            </InputLabel>
        )
    }

    const getInputComponent = () => {
        return props.secured
            ? <OutlinedInput
                {...field}
                {...props}
                type={securedText ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {securedText ? <Visibility/> : <VisibilityOff/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            : <OutlinedInput
                {...field}
                {...props}
                {...props.multiline
                    ? getTextFieldProps()
                    : {}
                }
            />
    }

    const getInputHelperText = () => {
        return isError() && (
            <FormHelperText
                variant="outlined"
            >
                {t(meta.error)}
            </FormHelperText>
        )
    }

    const isError = (): boolean => {
        return meta.touched && !!meta.error
    }

    return (
        <FormControl
            data-e2e={props.dataE2e}
            variant="outlined"
            fullWidth
            error={isError()}
        >
            {getInputLabel()}
            {getInputComponent()}
            {getInputHelperText()}
        </FormControl>
    )
}

export default AppInput
