import i18n from 'i18next'
import { makeAutoObservable, runInAction } from 'mobx'

import { loadPurchases } from '../firebase/firestore/user'
import Logger from '../logger'
import { alertError } from '../utils/alert.utils'

import type Organization from './Organization'
import type Purchase from './Purchase'
import type { UserDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const logger = new Logger('User')

export default class User {
    /**
     * Raw data from Firestore.
     */
    private _data: UserDocument
    /**
     * Reference to document in Firestore.
     */
    private _ref: firebase.firestore.DocumentReference<UserDocument>
    private _organization: Organization
    private _purchases: Purchase[] = []
    private _purchasesLoading = false
    /**
     * If this user has any purchases. You must load purchases with `loadPurchases` to see this.
     * Otherwise it's null (purchases weren't loaded yet).
     */
    public hasPurchases: boolean | null = null

    constructor(userRef: firebase.firestore.DocumentReference<UserDocument>, userData: UserDocument, organization: Organization) {
        this._data = userData
        this._ref = userRef
        this._organization = organization

        makeAutoObservable(this)
    }

    get id(): string {
        return this._ref.id
    }

    get externalId(): string {
        return this._data.externalUserId
    }

    get authId(): string {
        return this._data.authUserId
    }

    get email(): string {
        return this._data.email
    }

    get purchases(): Purchase[] {
        return this._purchases
    }

    get organization(): Organization {
        return this._organization
    }

    get disabled(): boolean {
        return this._data.disabled ?? false
    }

    async loadPurchases() {
        if (this._purchasesLoading) {
            return
        }

        try {
            const purchases = await loadPurchases(this)
            runInAction(() => {
                if (purchases) {
                    this._purchases = purchases
                    this.hasPurchases = true
                } else {
                    this.hasPurchases = false
                }
            })
        } catch (e) {
            alertError(i18n.t('Failed to load purchases'))
            logger.error(e)
        } finally {
            this._purchasesLoading = false
        }
    }

    async disableUser() {
        const userPartial = { disabled: true }
        await this._ref.update(userPartial)
        this._data = { ...this._data, ...userPartial }
    }
}
