import { getCurrentTimeInSeconds } from './time.utils'


export const STREAM_DELAY_SECONDS = 5 * 60

export enum StreamStatus {
    LOADING = 'LOADING',
    /**
     * If fully or partly available
     */
    FULLY_AVAILABLE = 'FULLY_AVAILABLE',
    PARTLY_AVAILABLE = 'PARTLY_AVAILABLE',
    FETCH_FAILED = 'FETCH_FAILED',
    NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE'
}

export const getLatestStreamAvailableTime = () => getCurrentTimeInSeconds() - STREAM_DELAY_SECONDS

export const isStreamAvailable = (status: StreamStatus) => status === StreamStatus.FULLY_AVAILABLE || status === StreamStatus.PARTLY_AVAILABLE


