export type PlayerAlertType = 'device-limit' | 'geo-blocking' | 'generic-error' | 'paid-section'

export interface PlayerAlertProps {
    title?: string
    caption: string
    button: {
        title: string
    }
}

/**
 * Maps alert type to alert props (e.g. title, button parameters).
 * @param alertType - alert type which will be mapped to alert props
 * @param t - translation function from end app
 */
export function getAlertPropsByAlertType(alertType: PlayerAlertType, t: (key: string) => string): PlayerAlertProps {
    if (alertType === 'geo-blocking') {
        return {
            caption: t('Video is not accessible in your country'),
            button: {
                title: t('Back'),
            },
        }
    }

    if (alertType === 'device-limit') {
        return {
            caption: t('You are watching on another device'),
            button: {
                title: t('Continue here'),
            },
        }
    }

    return {
        title: t('Something went wrong'),
        caption: t('Try again later'),
        button: {
            title: t('Retry'),
        },
    }
}
