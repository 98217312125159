import {
    Box,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { Skeleton } from '@material-ui/lab'
import { UserDocument } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import User from '../../store/User'
import { useOrganization } from '../hooks'
import { useFirebaseSearch, UseFirebaseSearchOptions } from '../hooks/dataHooks/useFirebaseSearch'
import useBottomScrollListener from '../hooks/uiHooks/useBottomScrollListener'

import { UsersItem } from './UsersItem'
import { useStyles } from './userStyles'


type SearchByAttribute = 'email' | 'id' | 'externalUserId'

const useBottomScrollOptions = {
    offset: 500,
    triggerOnNoScroll: false,
    debounce: 1000,
}

const USE_FIREBASE_SEARCH_OPTIONS: UseFirebaseSearchOptions<'users', UserDocument, User> = {
    collectionName: 'users',
    attribute: 'email',
    mapper: (ref, data, organization) => organization.newUserEntity(ref, data),
    limit: 30,
}

export const UsersList = observer(() => {
    const { organization } = useOrganization()
    const [t] = useTranslation()
    const classes = useStyles()
    const [searchText, setSearchText] = useState(organization?.lastUserSearch ?? '')
    const [searchByAttribute, setSearchByAttribute] = useState<SearchByAttribute>('email')

    // TODO is empty result is weird
    const { pagination, isLoading, search, error, isEmptyResult } = useFirebaseSearch<'users', UserDocument, User>({
        ...USE_FIREBASE_SEARCH_OPTIONS,
        attribute: searchByAttribute,
        defaultQuery: searchText,
    })

    const handleBottomScroll = () => {
        if (pagination?.hasNextPage && !isLoading) {
            pagination?.fetchMore()
        }
    }

    useBottomScrollListener(handleBottomScroll, useBottomScrollOptions)

    const handleSearchChange = (evt?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchText = evt?.target?.value ?? ''
        setSearchText(searchText)
        search(searchText)
        if (organization) {
            organization.lastUserSearch = searchText
        }
    }

    const handleResetSearch = () => {
        handleSearchChange()
    }

    return (
        <>
            <Box
                mb={2}
                className={classes.searchTextFieldContainer}
            >
                <TextField
                    onChange={handleSearchChange}
                    value={searchText}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        'startAdornment': (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        'endAdornment': searchText ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    title={t('Clear search')}
                                    onClick={handleResetSearch}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    }}
                    placeholder={t('Search in users')}
                    className={classes.searchTextField}
                />
                <RadioGroup
                    name="searchByAttribute"
                    value={searchByAttribute}
                    className={classes.searchByRadioGroup}
                    onChange={({ target: { value } }) => setSearchByAttribute(value as SearchByAttribute)}
                >
                    <FormControlLabel
                        value="email"
                        control={<Radio color="primary" />}
                        label={t('Email')}
                    />
                    <FormControlLabel
                        value="id"
                        control={<Radio color="primary" />}
                        label={t('ID')}
                    />
                    <FormControlLabel
                        value="externalUserId"
                        control={<Radio color="primary" />}
                        label={t('External ID')}
                    />
                </RadioGroup>
            </Box>
            <Box mb={8}>
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Grid
                        item
                        className={classes.head}
                        xs={4}
                    >
                        {t('Email')}
                    </Grid>
                    <Grid
                        item
                        className={classes.head}
                        xs={2}
                    >
                        {t('ID')}
                    </Grid>
                    <Grid
                        item
                        className={classes.head}
                        xs={2}
                    >
                        {t('External ID')}
                    </Grid>
                </Grid>
                {pagination?.items?.map(user => (
                    <UsersItem
                        user={user}
                        key={user.id}
                    />
                ))}
                {isLoading && (
                    <>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                        <Skeleton
                            animation="wave"
                            className={classes.skeletonRow}
                        ></Skeleton>
                    </>
                )}
                {isEmptyResult && (
                    <Box
                        mt={2}
                        textAlign="center"
                    >
                        <Typography variant="body1">{t('No users found.')}</Typography>
                    </Box>
                )}
                {error && (
                    <Box
                        mt={2}
                        textAlign="center"
                    >
                        <Typography
                            variant="body1"
                            color="error"
                        >
                            {error.message}
                        </Typography>
                    </Box>
                )}
            </Box>
        </>
    )
})
