import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Video from '../../store/Video'
import { Header } from '../common'
import { ContentAvailability } from '../content/ContentAvailability'
import { ContentOtherSettings } from '../content/ContentOtherSettings'
import { ContentPublishStatus } from '../content/ContentPublishStatus'


interface Props {
    video: Video
    disabled: boolean
}

export const VideoPublish: React.FC<Props> = observer(({ video, disabled }) => {
    const [t] = useTranslation()

    return (
        <>
            <Header title={t('Publish')} />
            <ContentPublishStatus
                content={video}
                disabled={disabled}
                isVideo
            />
            <ContentAvailability
                showManualControl={true}
                content={video}
                disabled={disabled}
            />
            <ContentOtherSettings
                content={video}
                disabled={disabled}
            />
        </>
    )
})
