import { PlayerWrapper } from '@tivio/core-js'
import { PlayerContext } from '@tivio/core-react'
import { BufferPercentChunk, LangCode } from '@tivio/types'
import { useObserver } from 'mobx-react'
import { useCallback, useContext, useEffect, useState } from 'react'


export const useAudioTracks = () => {
    const { video } = useContext(PlayerContext)
    const [audioTracks, setAudioTracks] = useState<LangCode[]>([])
    const [currentAudioTrack, setCurrentAudioTrack] = useState<LangCode | undefined>(undefined)

    useEffect(() => {
        const loadedListener = () => {
            setAudioTracks(video.engine?.getAudioTracks?.() ?? [])
            setCurrentAudioTrack(video.engine?.getActiveAudioTrack?.() ?? undefined)
        }

        if (video.engine?.getAudioTracks || video.engine?.getActiveAudioTrack) {
            video.engine?.addEventListener('loadeddata', loadedListener)

            return () => {
                video.engine?.removeEventListener('loadeddata', loadedListener)
            }
        }
    }, [video.engine])

    useEffect(() => {
        const tracksChangedListener = () => {
            setCurrentAudioTrack(video.engine?.getActiveAudioTrack?.() ?? undefined)
        }

        if (video.engine?.getActiveAudioTrack) {
            video.engine?.addEventListener('trackschanged', tracksChangedListener)

            return () => {
                video.engine?.removeEventListener('trackschanged', tracksChangedListener)
            }
        }
    }, [video.engine])

    return {
        audioTracks,
        currentAudioTrack,
        changeCurrentAudioTrack: video.engine?.selectAudioTrack ?? ((language: LangCode) => {}),
    }
}

export const useSubtitles = () => {
    const { video } = useContext(PlayerContext)
    const [subtitles, setSubtitles] = useState<LangCode[]>([])
    const [currentSubtitles, setCurrentSubtitles] = useState<LangCode | null>(null)

    useEffect(() => {
        const loadedListener = () => {
            setSubtitles(video.engine?.getSubtitles?.() ?? [])
            setCurrentSubtitles(video.engine?.getActiveSubtitles?.() ?? null)
        }

        if (video.engine?.getSubtitles || video.engine?.getActiveSubtitles) {
            video.engine?.addEventListener('loadeddata', loadedListener)

            return () => {
                video.engine?.removeEventListener('loadeddata', loadedListener)
            }
        }
    }, [video.engine])

    useEffect(() => {
        const tracksChangedListener = () => {
            setCurrentSubtitles(video.engine?.getActiveSubtitles?.() ?? null)
        }

        if (video.engine?.getActiveSubtitles) {
            video.engine?.addEventListener('trackschanged', tracksChangedListener)

            return () => {
                video.engine?.removeEventListener('trackschanged', tracksChangedListener)
            }
        }
    }, [video.engine])

    const selectSubtitles = (language: LangCode | null) => {
        if (currentSubtitles === language) {
            return
        }

        if (video.engine?.selectSubtitles) {
            video.engine?.selectSubtitles(language)
        }
    }

    return {
        subtitles,
        currentSubtitles,
        selectSubtitles,
    }
}

export const useBufferedInfo = () => {
    const { video } = useContext(PlayerContext)
    const [chunks, setChunks] = useState<BufferPercentChunk[]>([])

    useEffect(() => {
        // TODO see why this situation occurs (e.g. in apps/web/) and prevent it
        // if (!video.engine)
        //     throw new Error('video engine missing')
        // }

        video.engine?.addEventListener('bufferedinfochange', setChunks)

        return () => {
            // TODO see why this situation occurs (e.g. in apps/web/) and prevent it
            // if (!video.engine) {
            //     throw new Error('video engine missing')
            // }

            video.engine?.removeEventListener('bufferedinfochange', setChunks)
        }
    }, [])

    return chunks
}

/**
 * Example of player state hook
 * Production implementation is in libs/core-react-dom/src/playerHooks.tsx
 */
export const usePlayerState = (player: PlayerWrapper) => {
    return useObserver(() => player.state)
}

export const usePlayerStateHistory = (player: PlayerWrapper) => {
    const [states, setStates] = useState<string[]>([])
    const state = usePlayerState(player)

    useEffect(() => {
        setStates([...states, state])
    }, [state])

    return states
}

const AUTO_QUALITY_TITLE = 'auto'

export const useQualities = () => {
    const { video } = useContext(PlayerContext)
    const [qualities, setQualities] = useState<string[]>([])
    const [currentQuality, setCurrentQuality] = useState<string | null>(AUTO_QUALITY_TITLE)

    const refreshQualities = useCallback(() => {
        const qualities = video.engine?.getQualities?.() ?? []
        const isAuto = video.engine?.isAdaptationEnabled?.() ?? false
        const currentAudioTrack = video.engine?.getActiveAudioTrack?.() ?? undefined

        const qualitiesToDisplay = qualities
            .filter((quality) => quality.language === currentAudioTrack)
            .sort((a, b) => b.height - a.height)
            .map(quality => quality.height.toString())

        qualitiesToDisplay.unshift(AUTO_QUALITY_TITLE)

        if (isAuto) {
            setCurrentQuality(AUTO_QUALITY_TITLE)
        } else {
            setCurrentQuality(video.engine?.getCurrentQuality?.(currentAudioTrack)?.height.toString() ?? null)
        }

        setQualities(qualitiesToDisplay)
    }, [video.engine])

    const selectQuality = useCallback((qualityString: string) => {
        const currentAudioTrack = video.engine?.getActiveAudioTrack?.() ?? undefined

        if (qualityString === AUTO_QUALITY_TITLE) {
            video.engine?.enableAdaptation?.(true)
            refreshQualities()
        } else {
            const qualities = video.engine?.getQualities?.() ?? []
            const qualityToSelect = qualities.find((quality) => {
                return quality.language === currentAudioTrack && quality.height.toString() === qualityString
            })

            if (qualityToSelect) {
                video.engine?.selectQuality?.(qualityToSelect, { force: true })
            }

            refreshQualities()
        }
    }, [video.engine, refreshQualities])

    useEffect(() => {
        if (video.engine?.getQualities || video.engine?.isAdaptationEnabled || video.engine?.getCurrentQuality) {
            refreshQualities()
        }

        video.engine?.addEventListener('loadeddata', refreshQualities)

        return () => {
            video.engine?.removeEventListener('loadeddata', refreshQualities)
        }
    }, [video.engine, refreshQualities])

    return {
        qualities,
        selectQuality,
        currentQuality,
    }
}

interface SeekCallbacks {
    onSeeking?: () => any
    onSeeked?: () => any
}

export const useSeekInfo = (callbacks?: SeekCallbacks) => {
    const [seeking, setSeeking] = useState(false)
    const { video } = useContext(PlayerContext)

    useEffect(
        () => {
            const onSeeking = () => {
                setSeeking(true)
                callbacks?.onSeeking?.()
            }

            const onSeeked = () => {
                setSeeking(false)
                callbacks?.onSeeked?.()
            }

            video.engine?.addEventListener('seeking', onSeeking)
            video.engine?.addEventListener('seeked', onSeeked)

            return () => {
                video.engine?.removeEventListener('seeked', onSeeking)
                video.engine?.removeEventListener('seeking', onSeeked)
            }
        },
        [callbacks],
    )

    return {
        seeking,
    }
}

const PLAYBACK_SPEEDS = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2]

export const usePlaybackSpeeds = () => {
    const { video } = useContext(PlayerContext)
    const [currentPlaybackSpeed, setCurrentPlaybackSpeed] = useState<number>(1)

    const selectPlaybackSpeed = useCallback((playbackSpeed: number) => {
        setCurrentPlaybackSpeed(playbackSpeed)
        video.engine?.selectPlaybackSpeed?.(playbackSpeed)
    }, [video.engine])

    return {
        currentPlaybackSpeed,
        selectPlaybackSpeed,
        playbackSpeeds: PLAYBACK_SPEEDS,
    }
}
