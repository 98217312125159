import { Interval, TimelineProgramType } from '../utils/epg.utils'


import type { EpgProgram } from '../firebase/firestore/epg'
import type TvChannel from './TvChannel'
import type firebase from 'firebase/app'


export interface ProgramInterface {
    getFrom: number
    getTo: number
    getTimeline: Interval
    getPlaytime: number
    getName: string
    getId: string
    getContentId: string
    getType: TimelineProgramType
}

class Program implements ProgramInterface{
    private readonly ref: firebase.firestore.DocumentReference<EpgProgram>
    private readonly firestoreData: EpgProgram
    private readonly tvChannel: TvChannel
    private readonly type: TimelineProgramType = 'program'

    constructor(
        ref: firebase.firestore.DocumentReference<EpgProgram>,
        firestoreData: EpgProgram,
        tvChannel: TvChannel,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.tvChannel = tvChannel
    }

    get getFrom() {
        return this.firestoreData.timeline.from
    }

    get getTo() {
        return this.firestoreData.timeline.to
    }

    get getTimeline() {
        return this.firestoreData.timeline
    }

    get getPlaytime() {
        return this.firestoreData.playTime
    }

    get getName() {
        return this.firestoreData.name
    }

    get getId() {
        return this.ref.id
    }

    get getContentId() {
        return this.firestoreData.id
    }

    get getType() {
        return this.type
    }
}

export default Program
