import ShopTwoIcon from '@material-ui/icons/ShopTwo'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { goArticlesPage, goFeedPage, goVideosPage } from '../../utils/history.utils'

import { MenuDropdown, MenuDropdownItem } from './MenuDropdown'


/**
 * Menu Content component
 */
const MenuContent = observer(() => {
    const [t] = useTranslation()
    const menuItems: MenuDropdownItem[] = [
        {
            name: t('Video'),
            action: goVideosPage,
            id: 'video',
        },
        {
            name: t('Articles'),
            action: goArticlesPage,
            id: 'articles',
        },
        {
            name: t('Feed'),
            action: goFeedPage,
            id: 'feed',
        },
    ]

    return (
        <MenuDropdown
            icon={<ShopTwoIcon />}
            name={t('Content')}
            items={menuItems}
        />
    )
})

export default MenuContent
