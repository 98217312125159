import { auth, DocumentReference, PromotionDocument } from '@tivio/firebase'
import { PatreonCreatorData, PatreonUserResponse } from '@tivio/types'

import { getFirestore, getFunctions, loggerFirestore } from '../app'


export const updatePromotion = async (promotionId: string, organizationId: string, data: Partial<PromotionDocument>) => {
    try {
        const promotionRef = getFirestore().doc(`/organizations/${organizationId}/promotions/${promotionId}`)

        await promotionRef.update(data as DocumentReference, { merge: true })

        loggerFirestore.info('Promotion updated')
    } catch (e) {
        loggerFirestore.error('Failed to update Promotion', e)
        throw new Error(e)
    }
}

export const createPromotion = async (organizationId: string, data: Partial<PromotionDocument>) => {
    try {
        const promotionCollection = getFirestore().collection(`/organizations/${organizationId}/promotions`)

        await promotionCollection.add(data)

        loggerFirestore.info('Promotion created')
    } catch (e) {
        loggerFirestore.error('Failed to create Promotion', e)
        throw new Error(e)
    }
}

export const deletePromotion = async (promotionId?: string, organizationId?: string) => {
    try {
        const promotionDoc = getFirestore().collection(`/organizations/${organizationId}/promotions`).doc(promotionId)

        await promotionDoc.delete()

        loggerFirestore.info('Promotion deleted')
    } catch (e) {
        loggerFirestore.error('Failed to delete Promotion', e)
        throw new Error(e)
    }
}

export const connectPatreonCreator = async (data: auth.PatreonSignInPayload) => {
    const connectPatreonAccount = getFunctions().httpsCallable('connectPatreonCreator')
    return await connectPatreonAccount(data) as unknown as Promise<PatreonCreatorData>
}

export const getPatreonCreatorData = async (data: { accessToken: string }) => {
    const getPatreonCreatorData = getFunctions().httpsCallable('getPatreonCreatorData')
    return await getPatreonCreatorData(data) as unknown as Promise<{ data: PatreonCreatorData }>
}

export const getPatreonUserData = async (data: { accessToken: string }) => {
    const getPatreonUserData = getFunctions().httpsCallable('getPatreonUserData')
    return await getPatreonUserData(data) as unknown as Promise<{ data: PatreonUserResponse }>
}