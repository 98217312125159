import { Logger } from '@tivio/common'
import { ReactableContent } from '@tivio/types'
import { useCallback, useEffect, useState } from 'react'

import { useUser } from './useUser'


const logger = new Logger('useIsFavorite')

export const useIsFavorite = (item?: ReactableContent, onUnauthorized?: () => void) => {
    const [isFavorite, setIsFavorite] = useState(item?.isFavorite ?? false)
    const [isLoading, setIsLoading] = useState(false)
    const { user, isSignedIn } = useUser()

    const toggleFavorite = useCallback(async () => {
        if (!item) {
            logger.warn('No item provided')
            return
        }

        if (!user || !isSignedIn) {
            onUnauthorized?.()
            return
        }

        const wasFavorite = item.isFavorite ?? false
        setIsFavorite(!wasFavorite) // Optimistic update
        setIsLoading(true)

        try {
            if (wasFavorite) {
                await item.removeFromFavorites?.()
            } else {
                await item.addToFavorites?.()
            }
        } catch (error) {
            setIsFavorite(wasFavorite) // Revert on error
            logger.error('Error toggling favorite state', error)
        } finally {
            setIsLoading(false)
        }
    }, [item, user, isSignedIn, onUnauthorized])

    useEffect(() => {
        setIsFavorite(item?.isFavorite ?? false)
    }, [item?.isFavorite, setIsFavorite])

    return { isFavorite, isLoading, toggleFavorite }
}