import { ApplicationType } from '@tivio/types'
import firebase from 'firebase'
import { makeAutoObservable } from 'mobx'

import { alertError } from '../utils/alert.utils'
import { translate } from '../utils/translate.utils'

import type { ApplicationDocument } from '@tivio/firebase'


export class Application {
    constructor(
        private _ref: firebase.firestore.DocumentReference<ApplicationDocument>,
        private data: ApplicationDocument,
    ) {
        makeAutoObservable(this)
    }

    async update(data: Partial<ApplicationDocument>) {
        try {
            this.data = {
                ...this.data,
                ...data,
            }
            await this._ref.update(data)
        } catch (e) {
            alertError(translate('Failed to update application'))
            console.error(e)
        }
    }

    get ref() {
        return this._ref
    }

    get name() {
        return this.data.name
    }

    get id() {
        return this._ref.id
    }

    get applicationScreens() {
        return this.data.applicationScreens ?? []
    }

    get isEnabled() {
        return this.data.isEnabled ?? false
    }

    get type() {
        return this.data.type
    }

    get isTivioProApplication() {
        return this.type === ApplicationType.TIVIO_PRO
    }
}
