import { Logger } from '@tivio/common'
import {
    Chapter,
    Marker,
    MarkerType,
    PlayerWrapper,
    tivio,
    VodTivioSource,
} from '@tivio/core-js'
import { LangCode, PlayerError, SourcePlayMode } from '@tivio/types'
import { useObserver } from 'mobx-react'
import React, { createContext, FC, useContext, useMemo } from 'react'

import type {
    AdExternal,
    AdSegment,
    AdSourceInterface,
    PlayerSource,
    PlayerState,
} from '@tivio/types'


const logger = new Logger('playerHooks')

export const PlayerDataContext = createContext<{ player: PlayerWrapper | null }>({ player: null })


export const PlayerDataContextProvider: FC<{id: string, doNotSaveWatchPosition?: boolean, children?: React.ReactNode }> = (
    { id, doNotSaveWatchPosition, children },
) => {
    const player = useMemo(() => tivio.getPlayerWrapper(id, doNotSaveWatchPosition), [])

    return (
        <PlayerDataContext.Provider value={{ player }}>
            {children}
        </PlayerDataContext.Provider>
    )
}

// Usage:
// withPlayerContext(playerId)

// hoc factory
export function withPlayerContext(id: string, doNotSaveWatchPosition?: boolean) {
    // hoc
    return function withPlayerContext<P>(WrappedComponent: React.ComponentType<P>) {
        // component
        return function withPlayerContext(props: P & JSX.IntrinsicAttributes) {
            return (
                <PlayerDataContextProvider
                    id={id}
                    doNotSaveWatchPosition={doNotSaveWatchPosition}
                >
                    <WrappedComponent {...props} />
                </PlayerDataContextProvider>
            )
        }
    }
}

export function usePlayerWrapper(): [PlayerWrapper | null] {
    const { player } = useContext(PlayerDataContext)

    if (!player) {
        // TODO here is a great problem, a race condition where due to the remote nature of sdk-react
        // the hook can be rendered before its context, even though it is wrapped in sdk-react
        // context provider. That's why the player returned from this hook can be null
        logger.warn('Component using this hook must be wrapped in <PlayerDataContext.Provider/>')
    }

    return [player]
}

export function createHookFromTivioInstance<T>(
    getValue: (_tivio: typeof tivio) => T,
) {
    // hook
    return function (): [T] {
        const value = useObserver<T>(() => getValue(tivio))
        return [value]
    }
}

/**
 * @deprecated, use createHookFromMobxContextSingleValue instead
 */
export function createHookFromMobxContext<T>(
    getValue: (player: PlayerWrapper | null) => T,
) {
    // hook
    return function (): [T] {
        const [player] = usePlayerWrapper()

        const value = useObserver<T>(() => getValue(player))
        return [value]
    }
}

/**
 * Seems to be better idea than createHookFromMobxContext
 */
export function createHookFromMobxContextSingleValue<T>(
    getValue: (player: PlayerWrapper | null) => T,
) {
    // hook
    return function (): T {
        const [player] = usePlayerWrapper()
        const value = useObserver<T>(() => getValue(player))
        return value
    }
}

export const useCurrentTime = createHookFromMobxContextSingleValue<number|null>(
    (player) => player?.source?.currentTime ?? null,
)

interface ProgressMetadata {
    currentTimeMs: number
    durationMs: number
    percent: number
    remainingMs: number
    type: 'original' | 'cutout'
}

/**
 * Returns player progress percentage and related time metadata
 */
export const useProgress = createHookFromMobxContextSingleValue<ProgressMetadata | null>(
    (player) => player?.progressMetadata ?? null,
)

export const useDurationMs = createHookFromMobxContextSingleValue<number>(
    (player) => player?.durationMs ?? 0,
)

export const useMarkers = createHookFromMobxContext<Marker[]>(
    (player) => player?.source?.markers ?? [],
)

export const useIntroMarker = createHookFromMobxContext<Marker|null>(
    (player) => player?.source?.markers.find(marker => marker.type === MarkerType.INTRO) ?? null,
)

export const usePlayerState = createHookFromMobxContext<PlayerState>(
    (player) => player?.state ?? 'idle',
)

export const useIsBuffering = createHookFromMobxContext<boolean>(
    (player) => player?.isBuffering ?? false,
)

export const useIsIdle = createHookFromMobxContext<boolean>(
    (player) => player?.isIdle ?? true,
)

export const useIsPaused = createHookFromMobxContextSingleValue(
    (player) => player?.isPaused,
)

export const useIsPlaying = createHookFromMobxContextSingleValue(
    (player) => player?.isPlaying,
)

export const useIsMuted = createHookFromMobxContextSingleValue(
    (player) => player?.isMuted,
)

export const useIsPlaybackStarted = createHookFromMobxContextSingleValue(
    (player) => player?.isPlaybackStarted,
)

export const usePlaybackError: () => PlayerError | null | undefined = createHookFromMobxContextSingleValue(
    (player) => player?.error,
)

/**
 * @returns {number} in [0,1]
 */
export const useVolume = createHookFromMobxContextSingleValue(
    (player) => player?.volume,
)

interface Intro {
    marker: Marker
    skip: () => void
}

export const useIntro = createHookFromMobxContext<Intro|null>(
    (player) => player?.intro ?? null,
)

export const useCurrentMarker = createHookFromMobxContext<Marker | null>(
    (player) => player?.source?.currentMarker ?? null,
)

export const useChapters = createHookFromMobxContext<Chapter[]>(
    (player) => {
        if (player?.source instanceof VodTivioSource) {
            return player?.source.chapters
        }

        return []
    },
)

export const useCurrentChapter = createHookFromMobxContext<Chapter | null>(
    (player) => {
        if (player?.source instanceof VodTivioSource) {
            return player?.source.currentChapter
        }

        return null
    },
)

export const useAd = createHookFromMobxContext<AdExternal | null>(
    (player) => {
        if (!player?.ad) {
            return null
        }

        const { ad } = player

        return {
            canSeek: ad.canSeek,
            canSkip: ad.canSkip,
            durationMs: ad.durationMs,
            isSkippable: ad.isSkippable,
            order: ad.order,
            secondsToSkippable: ad.secondsToSkippable,
            secondsToEnd: ad.secondsToEnd,
            skipDelayMs: ad.skipDelayMs,
            totalCount: ad.totalCount,
            click: () => ad.click(),
            skip: () => ad.skip(),
        }
    },
)

export const useSource = createHookFromMobxContext<PlayerSource | null>(
    (player) => player?.source ?? null,
)

export const useRequestedSource = createHookFromMobxContextSingleValue<PlayerSource | null>(
    (player) => player?.requestedSource ?? null,
)

export const useAdSegment = createHookFromMobxContextSingleValue<AdSegment | null>(
    (player) => player?.adSegment ?? null,
)

export const useCanSeek = createHookFromMobxContext<boolean>(
    (player) => Boolean(player?.source?.canSeek),
)

export const useIsLive = createHookFromMobxContext<boolean>(
    (player) => Boolean(player?.source?.isLive),
)

export const useIsHybrid = createHookFromMobxContext<boolean>(
    // TODO mb define getter in source for this
    (player) => Boolean(player?.source?.sourcePlayMode === SourcePlayMode.HYBRID),
)

export const useCanReplay = createHookFromMobxContext<boolean>(
    (player) => player?.canReplay ?? false,
)

/**
 * Returns metadata of ad handled by IMA SDK. These ads have their own player UI,
 * so our player UI should be hidden or minimized when these ads are playing
 */
export const useImaSdkAd = createHookFromMobxContextSingleValue<AdSourceInterface | null>(
    (player) => player?.ad?.adType === 'metadata-only' ? player?.ad : null,
)

export const useLanguage = createHookFromMobxContextSingleValue<LangCode | null>(
    (player) => player?.language ?? null,
)

export const useAvailableLanguages = createHookFromMobxContextSingleValue<LangCode[]>(
    (player) => player?.availableLanguages ?? [],
)
