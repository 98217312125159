import i18n from 'i18next'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import Logger from '../../../logger'


const logger = new Logger('')

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Text = styled.span`
    font-size: 1.5em;
    padding: 60px;
    color: red;
`

const ErrorMessage = () => {
    return (
        <Container>
            <Text>{`⚠️ ${i18n.t('Something went wrong')}`}</Text>
        </Container>
    )
}

type Props = PropsWithChildren

/**
 * Simple static error boundary
 */
export class ErrorBoundary extends React.Component<Props, { hasError: boolean }> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error: any, errorInfo: any) {
        logger.error(error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <ErrorMessage />
        }

        return this.props.children
    }
}

// HOC
export const withErrorBoundary = <T extends {}>(WrappedComponent: any) => {
    // component
    return function withErrorBoundary(props: T) {
        return (
            <ErrorBoundary>
                <WrappedComponent {...props} />
            </ErrorBoundary>
        )
    }
}
