import { SectionDocument } from '@tivio/firebase'

import Channel from '../../store/Channel'
import Section from '../../store/Section'
import { getSectionPath } from '../../utils/id.utils'
import { getFirestore, loggerFirestore } from '../app'

import { channelSectionsConverter } from './converters'

import type firebase from 'firebase/app'


const getSectionsCollection = (channelId: string): firebase.firestore.CollectionReference<SectionDocument> => {
    return getFirestore().collection(`channels/${channelId}/sections`).withConverter(channelSectionsConverter)
}

const addSection = async (channel: Channel, sectionData: SectionDocument) => {
    try {
        const docRef = await getSectionsCollection(channel.getId).add(sectionData)

        loggerFirestore.info('Section written with ID: ', docRef.id)

        return docRef
    }
    catch (e) {
        loggerFirestore.error('Failed to create new section', e)
        throw new Error(e)
    }
}

const deleteSection = async (section: Section) => {
    try {
        const {
            channelId,
            sectionId,
        } = getSectionPath(section)

        await getSectionsCollection(channelId).doc(sectionId).delete()

        loggerFirestore.info('Section deleted')
    }
    catch (e) {
        loggerFirestore.error('Failed to delete channel', e)
        throw new Error(e)
    }
}


export {
    addSection,
    deleteSection,
    getSectionsCollection,
}
