import { ChannelDocument } from '@tivio/firebase'

import Channel from '../../store/Channel'
import Organization from '../../store/Organization'
import { getFirestore, loggerFirestore } from '../app'
import { RefsPagination } from '../pagination'

import { channelsConverter } from './converters'
import { DEFAULT_VOD_CHANNELS_LIMIT } from './organization'

import type firebase from 'firebase/app'


const getChannelsCollection = (): firebase.firestore.CollectionReference<ChannelDocument> => {
    return getFirestore().collection('channels').withConverter(channelsConverter)
}

const addChannel = async (channel: ChannelDocument) => {
    try {
        const docRef = await getChannelsCollection().add(channel)

        loggerFirestore.info('Channel created with ID: ', docRef.id)

        return docRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add channel: ', e)
        throw new Error(e)
    }
}

const updateChannel = async (channel: Channel, channelData: Partial<ChannelDocument>) => {
    try {
        await getChannelsCollection().doc(channel.getId).update(channelData)

        loggerFirestore.info('Channel updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update channel', e)
        throw new Error(e)
    }
}

const createAllVodChannelsPagination = async (organization: Organization) => {
    try {
        const channelsSnapshots = await getChannelsCollection().get()
        const channelsRefs = channelsSnapshots.docs.map(channelSnapshot => channelSnapshot.ref)

        organization.allChannelsPagination = new RefsPagination(
            channelsRefs,
            (ref, data) => {
                return new Channel(
                    ref,
                    data,
                    organization,
                )
            },
            true,
            30,
        )
    }
    catch (error) {
        console.error(error)
        return null
    }
}

const createOrganizationChannelsPagination = (organization: Organization) => {
    const channelsRefs = organization.channelsRefs
    const channelsPagination = new RefsPagination(
        channelsRefs,
        (ref, data) => {
            return new Channel(
                ref,
                data,
                organization,
            )
        },
        true,
        DEFAULT_VOD_CHANNELS_LIMIT,
    )

    organization.channelsPagination = channelsPagination
}

export {
    createAllVodChannelsPagination,
    createOrganizationChannelsPagination,
    addChannel,
    updateChannel,
    getChannelsCollection,
}
