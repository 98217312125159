import React from 'react'

import jojLogo from '../images/joj-logo.png'

import { WebLandingScreenConfig } from './appsConfig'


// TODO TIV-1243 do it in some other ticket - move uploadAssets script and config from core-react-dom to core-react
const JOJ_LANDING_BANNER = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-banner%2F%402.png?alt=media'
const JOJ_LANDING_IMAGE_1 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-1.png?alt=media'
const JOJ_LANDING_IMAGE_2 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-2.png?alt=media'
const JOJ_LANDING_IMAGE_3 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-3.png?alt=media'
const JOJ_LANDING_IMAGE_4 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-4.png?alt=media'
const JOJ_LANDING_IMAGE_5 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-5.png?alt=media'
const JOJ_LANDING_IMAGE_6 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-6.png?alt=media'
const JOJ_LANDING_IMAGE_7 = 'https://firebasestorage.googleapis.com/v0/b/tivio-production.appspot.com/o/core-react-assets%2Fjoj-landing-page%2Fjoj-landing-page-7.png?alt=media'


// TODO solve text localization here
export const JOJ_WEB_LANDING_SCREEN: WebLandingScreenConfig = {
    banner: {
        title: 'Objav JOJPLAY, miesto ako žiadne iné!',
        subtitle: 'Bav sa pri tisíckach hodín filmov, seriálov a relácií v jazyku, ktorému rozumieš. Sleduj naživo naše TV stanice. V profile si vyber balík FREE alebo PREMIUM (porovnaj balíky+). Vytvor si účet a začni pozerať!',
        backgroundImage: JOJ_LANDING_BANNER,
        logo: jojLogo,
    },
    rows: [
        {
            title: 'Pozeraj skôr ako v TV',
            subtitle: 'Dopraj si exkluzívne predpremiéry nových filmov, seriálov a relácií s balíkom PREMIUM.',
            image: JOJ_LANDING_IMAGE_1,
            imageSide: 'right',
        },
        {
            title: 'Sleduj na čom chceš',
            subtitle: 'Bav sa online cez prehliadač aj v natívnych aplikáciách cez mobil, tablet a smart TV vždy v najvyššej kvalite obrazu a zvuku.',
            image: JOJ_LANDING_IMAGE_2,
            imageSide: 'left',
        },
        {
            title: 'Slobodne a bez záväzkov',
            subtitle: 'JOJPLAY môžeš sledovať bezplatne alebo v prémiovom balíku a svoje rozhodnutie môžeš kedykoľvek meniť.',
            image: JOJ_LANDING_IMAGE_3,
            imageSide: 'right',
        },
        {
            title: 'Zábava pre celú rodinu',
            subtitle: 'Vytvor si profily pre všetkých členov domácnosti vrátane detí. Detské profily poskytujú bezpečné prostredie pre najmenších divákov.',
            image: JOJ_LANDING_IMAGE_4,
            imageSide: 'left',
        },
        {
            title: 'Radosť pozerať',
            subtitle: 'Vychutnaj si pokročilé funkcie pre pohodlné sledovanie. V prémiovom balíku môžeš pozerať čo chceš súčasne až na 4 zariadeniach.',
            image: JOJ_LANDING_IMAGE_5,
            imageSide: 'right',
        },
        {
            title: 'Sleduj živé vysielanie',
            subtitle: 'Sleduj televíziu JOJ, JOJ Plus, WAU, JOJ 24, JOJ Šport, Jojko, JOJ Cinema, CS FILM, CS HISTORY, CS MYSTERY, CS HORROR na JOJPLAY.',
            image: JOJ_LANDING_IMAGE_6,
            imageSide: 'left',
        },
        {
            title: 'Zober si svoju obľúbenú televíziu všade so sebou',
            subtitle: 'Pridaj sa do našej stále rastúcej komunity a užívaj si aj Ty všetky výhody JOJPLAY.',
            image: JOJ_LANDING_IMAGE_7,
            imageSide: 'right',
        },
    ],
    FAQ: [
        {
            id: 1,
            summary: 'Prečo si mám vybrať JOJPLAY?',
            details: (
                <p>
                    JOJPLAY je prvá streamovacia platforma, ktorú môžeš sledovať zadarmo. Ak hľadáš oddych a zábavu pre celú rodinu, JOJPLAY Ti ponúka tisícky
                    hodín exkluzívnych filmov, seriálov a show pre deti aj dospelých v slovenskom alebo českom jazyku. Pohodlne môžeš sledovať aj živé
                    vysielanie našich staníc JOJ, JOJ Plus, WAU, JOJ 24, JOJ Šport, Jojko, JOJ Cinema, CS FILM, CS HISTORY, CS MYSTERY, CS HORROR. Všetko v
                    jednom nájdeš na webe jojplay.sk alebo cez appky v mobile, tablete a smart TV v najvyššej kvalite obrazu a zvuku.
                </p>
            ),
        },
        {
            id: 2,
            summary: 'Koľko stojí JOJPLAY mesačne?',
            details: (
                <div>
                    <p>V súčasnosti si môžeš vybrať z dvoch balíkov:</p>
                    <p><strong>FREE</strong></p>
                    <ul>
                        <li>
                            <p>bezplatný balík</p>
                        </li>
                        <li>
                            <p>formáty z produkcie TV JOJ</p>
                        </li>
                        <li>
                            <p>obsahuje reklamu</p>
                        </li>
                        <li>
                            <p>výber živých kanálov: JOJ, JOJ Plus, WAU, JOJ 24</p>
                        </li>
                        <li>
                            <p>sledovanie na 1 zariadení súčasne</p>
                        </li>
                    </ul>
                    <p><strong>0,- EUR mesačne navždy</strong></p>
                    <p><strong>PREMIUM</strong></p>
                    <ul>
                        <li>
                            <p>prémiový balík</p>
                        </li>
                        <li>
                            <p>kompletný obsah</p>
                        </li>
                        <li>
                            <p>prémiové filmy a seriály</p>
                        </li>
                        <li>
                            <p>predpremiéry našej tvorby</p>
                        </li>
                        <li>
                            <p>bez reklamy</p>
                        </li>
                        <li>
                            <p>všetky živé kanály: JOJ, JOJ Plus, WAU, JOJ 24, JOJ Šport, Jojko, JOJ Cinema, CS FILM, CS HISTORY, CS MYSTERY, CS HORROR</p>
                        </li>
                        <li>
                            <p>sledovanie až na 4 zariadeniach súčasne</p>
                        </li>
                    </ul>
                    <p><strong>5,99 EUR mesačne bez viazanosti</strong></p>
                    <p>Môžeš si vybrať aj výhodnejšie ročné predplatné, či zakúpiť darčekovú poukážku pre Tvojich najmilších.</p>
                </div>
            ),
        },
        {
            id: 3,
            summary: 'Aká je kvalita streamovania?',
            details: (
                <p>
                    Streamujeme vo FULL HD. Naše technológie neustále vylaďujeme pre Tvoj dokonalý zážitok z najvyššej možnej kvality obrazu a zvuku.
                </p>
            ),
        },
        {
            id: 4,
            summary: 'Na koľkých zariadeniach súčasne je možné sledovať JOJPLAY?',
            details: (
                <p>
                    V balíku FREE môžeš sledovať obsah na jednom zariadení. PREMIUM umožňuje zábavu až na štyroch zariadeniach súčasne.
                </p>
            ),
        },
        {
            id: 5,
            summary: 'Môžem sledovať JOJPLAY aj v zahraničí?',
            details: (
                <div>
                    <p>
                        Služba JOJPLAY je určená výlučne pre používateľov žijúcich a sledujúcich obsah v SR. Väčšina titulov má licenčné obmedzenia len pre náš
                        trh a preto nevieme zaručiť dostupnosť našej služby v plnom rozsahu pre užívateľov mimo SR. Na druhej strane, JOJPLAY spĺňa všetky
                        požiadavky na dočasnú cezhraničnú prenositeľnosť služby v rámci EÚ. Pre využitie služby mimo územia SR je potrebné dôveryhodne preukázať
                        trvalý pobyt na Slovensku.
                    </p>
                </div>
            ),
        },
        {
            id: 6,
            summary: 'Ako môžem zrušiť predplatné?',
            details: (
                <div>
                    <p>
                        Predplatné sa dá kedykoľvek jednoducho zrušiť na webe jojplay.sk. Stačí sa prihlásiť do používateľského profilu, ktorý sa nachádza v pravom
                        hornom rohu. Po prihlásení sa zobrazí používateľský profil, kde sa nachádza kategória „Vaše predplatné“ s informáciou o aktuálnom
                        predplatnom. Po kliknutí vpravo na ikonu „zmeniť“ stačí vybrať z ponuky FREE balíček.
                    </p>
                    <p>
                        Týmto spôsobom sa predplatné balíka PREMIUM zruší.
                    </p>
                </div>
            ),
        },
        {
            id: 7,
            summary: 'Je JOJPLAY vhodné pre deti?',
            details: (
                <p>
                    Áno, JOJPLAY poskytuje bezpečné prostredie pre deti. Stačí im vytvoriť detský profil (0-12 rokov). V tomto profile majú deti prístup do
                    detskej zóny, ktorá obsahuje obľúbené rozprávky, seriály a relácie pre deti z našej aj zahraničnej produkcie v slovenskom, prípadne českom
                    jazyku. Tento obsah neustále rozširujeme.
                </p>
            ),
        },
        {
            id: 8,
            summary: 'Ponúka táto služba aj športový program?',
            details: (
                <p>
                    Áno, s JOJPLAY PREMIUM môžeš sledovať živé športové vysielanie kanálu JOJ ŠPORT, aby Ti žiaden športový zážitok neutiekol.
                </p>
            ),
        },
        {
            id: 9,
            summary: 'Ako funguje predplatné JOJPLAY?',
            details: (
                <div>
                    <p>
                        Mesačné predplatné JOJPLAY je nastavené na 30 kalendárnych dní a s jeho aktiváciou je vždy zadaná opakovaná platba. K strhnutiu ďalšej
                        platby teda už dochádza automaticky v momente vypršania aktuálneho predplateného obdobia. Predplatné sa uhrádza prostredníctvom
                        platobnej brány bankovými debetnými alebo kreditnými kartami.
                    </p>
                    <p>
                        Automatické obnovenie platí aj pre ročné predplatné, pokiaľ nedôjde k jeho zrušeniu, bude automaticky obnovené v momente vypršania
                        predplatného obdobia.
                    </p>
                </div>
            ),
        },
        {
            id: 10,
            summary: 'Aké tituly môžem sledovať?',
            details: (
                <p>
                    Na JOJPLAY nájdeš tisíce hodín filmov a seriálov v slovenskom alebo českom dabingu. Patria medzi ne aj obľúbené seriály z obrazoviek TV JOJ,
                    ktoré si môžeš pozrieť s prémiovým balíkom aj skôr ako v televízii. K dispozícii je špeciálna sekcia rozprávok, seriálov a programov pre
                    deti. Môžeš sledovať živé vysielanie staníc JOJ, JOJ Plus, WAU, JOJ 24, JOJ Šport, Jojko, JOJ Cinema, CS FILM, CS HISTORY, CS MYSTERY, CS
                    HORROR. Aktuálnu ponuku nájdeš po prihlásení sa do svojho profilu v službe JOJPLAY.
                </p>
            ),
        },
        {
            id: 11,
            summary: 'Ako fungujú používateľské profily?',
            details: (
                <div>
                    <p>
                        Vo svojom účte môžeš vytvárať neobmedzené množstvo používateľských profilov. Profily umožňujú oddeľovať históriu sledovania a každý
                        používateľ si tak vie vytvárať svoj vlastný zoznam na pozeranie. Aj odporúčania sú vďaka tomu oveľa presnejšie.
                    </p>
                    <p>
                        Detské profily (0-12 rokov) vytvárajú bezpečné prostredie pre deti. V tomto profile majú deti prístup do detskej zóny, ktorá obsahuje
                        obľúbené rozprávky, seriály a relácie pre deti z našej aj zahraničnej produkcie v slovenskom, prípadne českom jazyku.
                    </p>
                </div>
            ),
        },
    ],
}
