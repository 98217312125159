import { ThemeProvider as MuiThemeProvider, PaletteType } from '@material-ui/core'
import React, { PropsWithChildren, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'

import { darkTheme, lightTheme } from '../static/theme'


const getTheme = (type: PaletteType) => {
    switch (type) {
        case 'dark':
            return darkTheme
        case 'light':
            return lightTheme
        default:
            return darkTheme
    }
}

const useTheme = (type: PaletteType) => {
    return useMemo(
        () => {
            return getTheme(type)
        },
        [ type ],
    )
}

interface Props extends PropsWithChildren {
    type: PaletteType
}

const AppThemeProvider: React.FC<Props> = (props) => {
    const theme = useTheme(props.type)

    return (
        <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
                {props.children}
            </ThemeProvider>
        </MuiThemeProvider>
    )
}

export {
    AppThemeProvider,
}
