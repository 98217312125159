import { List } from '@material-ui/core'
import { Map } from '@material-ui/icons'
import Settings from '@material-ui/icons/Settings'
import { OrganizationMemberRoles } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import store from '../../store'
import { goRoadmapPage, goSettingsPage } from '../../utils/history.utils'

import { MenuLink } from './MenuLink'


const MenuSettings = observer(() => {
    const [t] = useTranslation()
    const { getMember: member } = store

    if (!member) {
        return null
    }

    if (member.getCurrentRole < OrganizationMemberRoles.ADMIN) {
        return null
    }

    return (
        <List>
            <MenuLink
                onClick={goRoadmapPage}
                Icon={Map}
                caption={t('Roadmap')}
            />
            <MenuLink
                onClick={goSettingsPage}
                Icon={Settings}
                caption={t('Settings')}
            />
        </List>
    )
})

export default MenuSettings
