import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

import { Header } from '../../common'

import { DragItem } from './DragItem'
import { MenuDropdown } from './MenuDropdown'


interface Props {
    title: string;
    menuTitle: string
    droppableId: string;
    possibleValues: string[];
    values: string[];
    getLabel: (value: string) => string;
    onChange: (values: string[]) => void;
}

export const DragSection = ({ menuTitle, title, droppableId, possibleValues, values, getLabel, onChange }: Props) => {
    const handleItemDelete = (value: string) => {
        const newValues = values.filter((item) => item !== value)
        if (newValues.length === 0) {
            return
        }

        onChange(newValues)
    }

    const handleDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return
        }

        const items = Array.from(values)
        const [reorderedItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, reorderedItem)

        onChange(items)
    }

    const renderItems = () =>
        values.map((value, index) => (
            <Draggable
                draggableId={value}
                key={value}
                index={index}
            >
                {(draggableProvided) => (
                    <div
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <DragItem
                            dragHandle={draggableProvided.dragHandleProps}
                            key={value}
                            value={value}
                            label={getLabel(value)}
                            onDelete={handleItemDelete}
                        />
                    </div>
                )}
            </Draggable>
        ))

    return (
        <>
            <Header
                title={title}
                rightContent={<MenuDropdown
                    title={menuTitle}
                    values={values}
                    possibleValues={possibleValues}
                    getLabel={getLabel}
                    onChange={onChange}
                />}
            />
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId={droppableId}>
                    {(droppableProvided) => (
                        <div
                            ref={droppableProvided.innerRef}
                            {...droppableProvided.droppableProps}
                        >
                            {renderItems()}
                            {droppableProvided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    )
}
