import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    MuiThemeProvider,
    Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { lightTheme } from '../../../static/theme'
import history, { goBack } from '../../../utils/history.utils'
import { useConsent } from '../../hooks/dataHooks/useConsent'


const StyledDialogContent = styled(DialogContent)`
    padding: 2.5rem !important;
`

const ContentWrapper = styled.div`
    height: min(50vh, 450px);
    overflow: hidden;
    overflow-y: auto;
`

export const TOSConsentPage = () => {
    const [t] = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const { consent, nextConsent, approveConsent } = useConsent()

    // useEffect(() => {
    //     if (!consent) {
    //         goBack()
    //     }
    // }, [consent])

    // TODO: Small refactor for redirect logic
    const approveConsentHandler = async () => {
        setIsLoading(true)
        const result = await approveConsent(consent?.id ?? '')
        setIsLoading(false)

        if (!nextConsent && !result) {
            const { redirect } = history.location.state as { redirect?: string } ?? {}
            if (redirect) {
                history.push(redirect)
            } else {
                goBack()
            }
        }
    }

    if (!consent) {
        return null
    }

    return (
        <MuiThemeProvider theme={lightTheme}>
            <Dialog
                open={true}
                maxWidth="sm"
            >
                <StyledDialogContent>
                    <Typography variant="h5">{consent?.name}</Typography>
                    <ContentWrapper key={consent?.id}>
                        <Typography variant="body1">
                            <div dangerouslySetInnerHTML={{ __html: consent?.text ?? '' }} />
                        </Typography>
                    </ContentWrapper>
                    <DialogActions>
                        <Button
                            onClick={approveConsentHandler}
                            disabled={isLoading}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            {isLoading ? <CircularProgress size={24} /> : t('Confirm terms & conditions').toUpperCase()}
                        </Button>
                    </DialogActions>
                </StyledDialogContent>
            </Dialog>
        </MuiThemeProvider>
    )
}
