import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    font-family: sans-serif;
    width: 374px;
    display: flex;
    flex-direction: column;
`

const StyledButton = styled.div<{ enabled: boolean }>`
    height: 90px;
    border-radius: 8px;
    background-color: ${p => p.enabled ? '#fff': '#333'};
    color: ${p => p.enabled ? '#000': '#fff'};
    cursor: ${p => p.enabled ? 'pointer' : 'not-allowed'};
    font-size: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
`

interface Props {
    enabled: boolean
    onClick?: () => any
    text: string
    children?: React.ReactNode
}

export const SkipButton: FC<Props> = ({ enabled, onClick, text, children }) => {
    return (
        <Container>
            { children }
            <StyledButton
                enabled={enabled}
                onClick={enabled ? onClick : undefined}
            >
                { text }
            </StyledButton>
        </Container>
    )
}


const ContainerWeb = styled.div`
    font-family: sans-serif;
    min-width: 220px;
    display: flex;
    flex-direction: column;
`

const StyledButtonWeb = styled.div<{ enabled: boolean }>`
    height: 40px;
    border-radius: 2px;
    background-color: ${p => p.enabled ? '#fff': '#333'};
    color: ${p => p.enabled ? '#000': '#fff'};
    ${p => p.enabled && 'cursor: pointer;'}
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 20px;
`

interface Props {
    enabled: boolean
    onClick?: () => any
    text: string
    className?: string
}

export const SkipButtonWeb: FC<Props> = ({ enabled, onClick, text, children, className }) => {
    return (
        <ContainerWeb className={className}>
            { children }
            <StyledButtonWeb
                enabled={enabled}
                onClick={enabled ? onClick : undefined}
            >
                { text }
            </StyledButtonWeb>
        </ContainerWeb>
    )
}
