import { IconButton, makeStyles } from '@material-ui/core'
import Done from '@material-ui/icons/Done'
import { LangCode } from '@tivio/types'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo } from 'react'
import ReactQuill, { ReactQuillProps } from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import './quill.css'
import { ArticleBlock } from '../../../store/ArticleBlock'
import Video from '../../../store/Video'

import type { Theme } from '@material-ui/core'


const useStyles = makeStyles<Theme, { doneButtonSpacingOffsetX?: number }>((theme) => ({
    container: {
        position: 'relative',
    },
    containerNotEditing: {
        '&, & .ql-editor > *': {
            cursor: 'pointer',
        },
    },
    preview: {
        padding: theme.spacing(2, 1),
        margin: theme.spacing(-2, -1),
        '& p': {
            margin: 0,
        },
        '& p + p': {
            marginTop: theme.spacing(2),
        },
    },
    doneButton: {
        position: 'absolute',
        right: ({ doneButtonSpacingOffsetX }) => theme.spacing(doneButtonSpacingOffsetX || 1),
        top: theme.spacing(-5.5),
        backgroundColor: theme.palette.primary.main,
        borderRadius: '50%',
    },
}))

interface Props {
    language: LangCode
    block: ArticleBlock | Video
    isLocked?: boolean
    /**
     * If true, the block will be rendered as disabled, and the user will not be able to edit it.
     */
    isDisabled?: boolean
    onStopEditing?: (language: LangCode) => void
}

export const ParagraphBlock = observer(({ language, block, isLocked, isDisabled, onStopEditing }: Props) => {
    const [isEditing, setIsEditing] = React.useState(false)
    const editorRef = React.useRef<ReactQuill>(null)
    const classes = useStyles({ doneButtonSpacingOffsetX: isLocked ? 4 : 1 })

    const handleStartEditing = useCallback((event: React.MouseEvent) => {
        event.stopPropagation()
        event.preventDefault()
        if (isDisabled) {
            return
        }
        setIsEditing(true)
    }, [isDisabled])

    useEffect(() => {
        if (isEditing) {
            editorRef.current?.focus()
        }
    }, [isEditing, language])

    const value = (() => {
        if (block instanceof ArticleBlock) {
            return block.htmlContent?.[language] || ''
        } else if (block instanceof Video) {
            return block.descriptionRich?.[language] || block.getDescriptionTranslations?.[language] || ''
        }
    })()

    const handleOnChangeEvent = useCallback(
        (content: string) => {
            if (block instanceof ArticleBlock) {
                block.updateTranslationField('htmlContent', language, content)
            } else if (block instanceof Video) {
                block.updateTranslationField('descriptionRich', language, content)
            }
        },
        [block, language],
    )

    const quillProps = useMemo<Partial<ReactQuillProps>>(
        () =>
            isEditing
                ? {
                    modules: {
                        toolbar: [
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ align: [] }],
                            ['bold', 'italic', 'underline', 'code-block', 'blockquote', 'link', { list: 'ordered' }, { list: 'bullet' }],
                            ['clean'],
                        ],
                        clipboard: {
                            matchVisual: false,
                        },
                    },
                    onChange: handleOnChangeEvent,
                }
                : {
                    readOnly: true,
                    modules: {
                        toolbar: false,
                    },
                },
        [isEditing, handleOnChangeEvent],
    )

    const handleStopEditing = useCallback(() => {
        setIsEditing(false)
        onStopEditing?.(language)
    }, [language, onStopEditing])
    return (
        <div
            className={clsx(classes.container, {
                [classes.containerNotEditing]: !isEditing,
            })}
            onClick={isEditing ? undefined : handleStartEditing}
        >
            <ReactQuill
                key={`${language}-${isEditing}`}
                ref={editorRef}
                theme="snow"
                value={value}
                {...quillProps}
            />
            {isEditing && (
                <div className={classes.doneButton}>
                    <IconButton
                        size="small"
                        onClick={handleStopEditing}
                    >
                        <Done />
                    </IconButton>
                </div>
            )}
        </div>
    )
})
