import { TvChannelType } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router-dom'

import Organization from '../../../store/Organization'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import EpgMarkerEditor from '../../epg/EpgMarkerEditor'
import { EpgScheduleEditor } from '../../epg/schedule/EpgScheduleEditor'
import { UserLayoutParams } from '../layout/UserLayout'


interface EpgChannelPageProps {
    organization: Organization
}

const EpgChannelPage = observer((props: EpgChannelPageProps) => {
    const params = useParams<UserLayoutParams>()
    const [ t ] = useTranslation()
    const tvChannel = props.organization.tvChannels.find(tvChannel => tvChannel.getId === params.epgChannelId)

    if (!tvChannel) {
        return <AppNotFoundTemplate title={t('TV channel not found')} />
    }

    const tabs = [
        ...(tvChannel.type === TvChannelType.VIRTUAL ? [ {
            title: t('EPG'),
            description: t('EPG programs editor'),
            component: <EpgScheduleEditor/>,
        } ] : []),
        {
            title: t('Player'),
            description: t('EPG marker editor'),
            component: <EpgMarkerEditor tvChannel={tvChannel}/>,
        },
    ]

    return (
        <>
            <DefaultPageHeader title={tvChannel.getName} />
            <DefaultPageTabs tabs={tabs} />
        </>
    )
})

export default EpgChannelPage
