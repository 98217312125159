import { Button, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Membership } from '../../../store/Membership'
import { Listing } from '../../common/listing/Listing'
import { ListingItem } from '../../common/listing/ListingRow'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useMemberships } from '../../hooks/dataHooks/useMemberships'
import { useSubscriptions } from '../../hooks/dataHooks/useSubscriptions'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { MembershipDialog, MembershipDialogProps } from '../../monetization/MembershipDialog'


const membershipsConverter = (membership: Membership): ListingItem => ({
    getId: membership.getId,
    getName: membership.getName,
})

export const MembershipsPage = observer(() => {
    const { memberships, addMembership, removeMembership } = useMemberships()
    const { subscriptions } = useSubscriptions()
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()
    const { showError, showSuccess } = useAlert()

    const [ isDialogOpen, setDialogOpen] = useState<boolean>(false)
    const [ selectedMembership, setSelectedMembership ] = useState<Membership | null>(null)

    const onAddMembership = () => {
        setDialogOpen(true)
    }

    const selectMembership = (id: string) => {
        const membership = memberships.find(m => m.getId === id)

        if (!membership) {
            throw new Error(`membership with ${id} not found`)
        }

        setSelectedMembership(membership)
        setDialogOpen(true)
    }

    const onSubmit: MembershipDialogProps['onSubmit'] = async (values) => {
        const updatingMembership = !!selectedMembership
        const membershipData = { name: values.name }
        try {
            const membershipRef = await (
                updatingMembership
                    ? selectedMembership.update(membershipData)
                    : addMembership(membershipData)
            )

            if (!membershipRef) {
                throw new Error('Failed to get membership reference')
            }

            // TODO not efficient
            values.subscriptionsDiff.forEach(async (sd) => {
                const subscription = subscriptions.find(s => s.getId === sd.id)
                if (subscription) {
                    await subscription.updateMembershipRef(sd.setMembershipRef ? membershipRef : null)
                } else {
                    throw new Error(`Could not find subscription with id ${sd.id}`)
                }
            })
            showSuccess(updatingMembership ? t('Membership updated') : t('Membership added'))
            onClose?.()
        } catch (e) {
            showError(updatingMembership ? t('Failed to update membership') : t('Failed to add membership'))
            console.error(e)
        }
    }

    const onClose = () => {
        setSelectedMembership(null)
        setDialogOpen(false)
    }

    const onDeleteMembership = (id: string, name: string) => {
        confirmAction(
            () => {
                removeMembership(id)
                subscriptions.forEach(async (s) => {
                    if (s.getMembershipRef?.id === id) {
                        await s.updateMembershipRef(null)
                    }
                })
                showSuccess(t('Membership removed'))
            },
            t(`Are you sure you want to delete the membership "${name}"?`),
        )
    }

    return (
        <DefaultPageContainer
            title={t('Memberships')}
            buttons={[
                <Button
                    key="button-add-membership"
                    startIcon={<Add/>}
                    component="span"
                    size="small"
                    onClick={() => onAddMembership()}
                >
                    {t('Add membership')}
                </Button>,
            ]}
        >
            {isDialogOpen && (
                <MembershipDialog
                    membership={selectedMembership}
                    memberships={memberships}
                    subscriptions={subscriptions}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            )}
            {memberships.length === 0 && (
                <Typography color="textSecondary">{t('No memberships yet')}</Typography>)
            }
            <Listing
                items={memberships.map(membershipsConverter)}
                onClick={membership => selectMembership(membership.getId)}
                onDeleteClick={membership => onDeleteMembership(membership.getId, membership.getName)}
            />
        </DefaultPageContainer>
    )
})
