import { Box, CircularProgress, FormControlLabel, Switch } from '@material-ui/core'
import React, { useState } from 'react'


interface Props {
    label: string
    isChecked: boolean
    onCheck: (isChecked: boolean) => Promise<void>
    isDisabled?: boolean
}

export const SwitchWithLoading = ({ label, isChecked, onCheck, isDisabled }: Props) => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <Box
            display="flex"
            alignItems="center"
        >
            <FormControlLabel
                label={label}
                control={
                    <Switch
                        checked={isChecked}
                        onChange={async () => {
                            setIsLoading(true)
                            await onCheck(!isChecked)
                            setIsLoading(false)
                        }}
                        color="primary"
                        disabled={isDisabled || isLoading}
                    />
                }
            />
            {isLoading && <CircularProgress size={16} />}
        </Box>
    )
}