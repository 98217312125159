import { useContext } from 'react'

import { ConfirmActionContext } from '../../context/ConfirmActionContext'


const useConfirmAction = () => {
    const { setActionToConfirm } = useContext(ConfirmActionContext)

    const confirmAction = (
        action: () => any,
        actionMessage?: string, 
        actionTitle?: string,
        confirmButtonMessage?: string,
        cancelButtonMessage?: string,
    ) => {
        setActionToConfirm(
            action, 
            actionMessage, 
            actionTitle,
            confirmButtonMessage,
            cancelButtonMessage,
        )
    }

    return {
        confirmAction,
    }
}

export {
    useConfirmAction,
}
