import { IconButton } from '@material-ui/core'
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate'
import React from 'react'
import styled from 'styled-components'

import AppTile, { TileSize } from '../../AppTile'
import { useImageUpload } from '../../hooks/uiHooks/useImageUpload'
import { ClickableFileInput } from '../input'

import { Error } from './error'

import type { ContentPlace } from '../../AppTile'
import type { FileValidationResult } from '../../hooks/uiHooks/useValidateFile'


interface Props {
    image?: string | null
    /**
     * Emits uploaded file if it passed validation
     */
    onFile?: (file: File) => any
    /**
     * @returns {string[]} array of errors
     */
    validateFile?: (file: File) => Promise<FileValidationResult>
    size?: TileSize
    disabled?: boolean
}

const getContentPlace = (isCenter: boolean): ContentPlace => {
    if (isCenter) {
        return {
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    return {
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    }
}

const AddPhotoAlternateStyled = styled(AddPhotoAlternate)`
    color: ${props => props.theme.palette.common.white};
`

/**
 * Component that shows an image input for a single image
 */
export const ImageInput = (props: Props) => {
    const { onFile, validateFile, size = 'mini', disabled = false } = props

    const { error, image, onFiles } = useImageUpload({
        image: props.image,
        onFile,
        validateFile,
    })

    return (
        <ClickableFileInput
            onFiles={onFiles}
            disabled={Boolean(image) || disabled}
        >
            <ClickableFileInput
                onFiles={onFiles}
                disabled={disabled}
            >
                <AppTile
                    contentPlace={getContentPlace(Boolean(error) || !image)}
                    cover={image}
                    // TODO TIV-546 maybe loading progress for upload
                    // loading={props.loading}
                    // progress={props.progress}
                    size={size}
                >
                    {error && <Error />}
                    {!error && !disabled && (!image || size !== 'avatar') && (
                        <IconButton component="span">
                            <AddPhotoAlternateStyled />
                        </IconButton>
                    )}
                </AppTile>
            </ClickableFileInput>
        </ClickableFileInput>
    )
}
