import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as yup from 'yup'

import { getCurrentUser } from '../../firebase/auth'
import { getOrganizationsCollection } from '../../firebase/firestore/organization'
import MemberAdmin from '../../store/MemberAdmin'
import OrganizationAdmin from '../../store/OrganizationAdmin'
import OrganizationInvite from '../organization/OrganizationInvite'
import OrganizationMembersList from '../organization/OrganizationMembersList'

import DialogBase from './DialogBase'

import type { TDialogProps } from './DialogBase'


interface TProps extends TDialogProps<void> {
    organization: OrganizationAdmin
}

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    paper: {
        width: 560,
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
}))

const schema = yup.object().shape({
    email: yup.string().required('Email required').email('Email must be valid'),
    firstName: yup.string().required('First name required'),
    lastName: yup.string().required('Last name required'),
})

// TODO: Use Formik
const DialogEditOrganization = observer((props: TProps) => {
    const [ t ] = useTranslation()
    const { organization } = props
    const [ organizationName, setOrganizationName ] = useState(props.organization.getName)
    const [ email, setEmail ] = useState('')
    const [ firstName, setFirstName ] = useState('')
    const [ lastName, setLastName ] = useState('')
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(false)
    const classes = useStyles()

    const handleCreateInvite = async () => {
        setLoading(true)

        try {
            const values = await schema.validate({
                email,
                firstName,
                lastName,
            })

            await organization.createInvite({
                email: values.email,
                organizationId: organization.id,
                firstName: values.firstName,
                lastName: values.lastName,
            })

            setError('')
            setEmail('')
            setFirstName('')
            setLastName('')
        }
        catch (e) {
            setError(t(e.message))
        }
        finally {
            setLoading(false)
        }
    }

    const handleOrganizationUpdate = async (e: React.FormEvent) => {
        e.preventDefault()

        if (organizationName !== props.organization.getName) {
            await props.organization.updateName(organizationName)
        }

        if (email) {
            await handleCreateInvite()
        }

        await updateMembers()
    }

    const updateMembers = async () => {
        const organizationSnapshot = await getOrganizationsCollection().doc(organization.id).get()
        const organizationData = organizationSnapshot.data()
        const members: MemberAdmin[] = []

        if (!organizationData) {
            return
        }

        const memberPromises = Object.keys(organizationData.members).map(async memberId => {
            const memberRef = organizationData?.members[memberId].memberRef
            if (memberRef) {
                const memberSnapshot = await memberRef.get()
                const memberData = memberSnapshot.data()
                if (memberData && memberData.email !== getCurrentUser().email) {
                    members.push(new MemberAdmin(memberSnapshot.ref, memberData, organizationData?.members[memberId].role))
                }
            }
        })

        await Promise.all(memberPromises)
        organization.setMembers = members
    }

    return (
        <DialogBase
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            open={props.open}
            showCloseIcon={true}
            hideActionsButtons={true}
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <form onSubmit={handleOrganizationUpdate}>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Organization')}
                        </Typography>
                        <TextField
                            label={t('Name')}
                            fullWidth
                            variant="outlined"
                            onChange={(e) => setOrganizationName(e.target.value)}
                            value={organizationName}
                        />
                    </Grid>
                    <Grid item>
                        <OrganizationInvite
                            error={error}
                            loading={loading}
                            onEmailChange={setEmail}
                            onFirstNameChange={setFirstName}
                            onLastNameChange={setLastName}
                            email={email}
                            firstName={firstName}
                            lastName={lastName}
                        />
                    </Grid>
                    <Grid item>
                        <OrganizationMembersList organization={props.organization}/>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            disabled={loading}
                            fullWidth
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogBase>
    )
})

export default DialogEditOrganization
