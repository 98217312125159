import { Avatar, CircularProgress, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { Check } from '@material-ui/icons'
import { ProcessDocument, ProcessStatus } from '@tivio/firebase'


interface Props {
    encodingProcess: ProcessDocument
}

export const VideoEncodingProcessLoading = (props: Props) => {
    if (props.encodingProcess.progress !== undefined) {
        let statusText = ''

        if (props.encodingProcess.status !== ProcessStatus.FAILED) {
            statusText = props.encodingProcess.progress !== 100 ? `${props.encodingProcess.progress}%` : 'Done'
        } else {
            statusText = 'Failed'
        }

        return (
            <ListItem>
                <ListItemAvatar>
                    <Avatar style={{ backgroundColor: 'unset', border: (props.encodingProcess.progress !== 100 ? 'none' : '3px solid #0190c8') }}>
                        {
                            props.encodingProcess.progress !== 100
                                ? <CircularProgress value={props.encodingProcess.progress} variant='determinate' />
                                : <Check color='primary' />
                        }
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary={`${props.encodingProcess.payload.videoTrack}p`}
                    secondary={statusText}
                />
            </ListItem>
        )
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <CircularProgress />
            </ListItemAvatar>
            <ListItemText 
                primary={`${props.encodingProcess.payload.videoTrack}p`}
                secondary={'Waiting...'} 
            />
        </ListItem>
    )
}