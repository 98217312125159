import React from 'react'

import { GeneralDialog } from './dialog/GeneralDialog'
import { ItemSearch, ItemSearchProps } from './ItemSearch'


type ItemSearchDialogProps = ItemSearchProps & {
    open: boolean
    onClose: () => void
    title: string
}

export const ItemSearchDialog: React.FC<ItemSearchDialogProps> = (props) => {
    const { open, onClose, title, ...restProps } = props

    const itemSearchProps = {
        ...restProps,
        onAdd: (itemPath: string) => {
            restProps.onAdd(itemPath)
            onClose()
        },
    }

    return (
        <GeneralDialog
            open={open}
            onClose={onClose}
            title={title}
        >
            <ItemSearch {...itemSearchProps}/>
        </GeneralDialog>
    )
}
