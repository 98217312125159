import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AssetPreset } from '../../store/AssetPreset'
import Video from '../../store/Video'
import { AssetUploader } from '../asset/assetUploader'
import { Header } from '../common'


interface VideoAssetsProps {
    video: Video
    disabled: boolean
}

export const VideoAssets: React.FC<VideoAssetsProps> = ({ video, disabled }) => {
    const [ t ] = useTranslation()

    return (
        <>
            <Header title={t('Assets')} />
            <AssetUploader
                disabled={disabled}
                item={{
                    getAsset: video.getAsset,
                    getTempFilePath: (preset: AssetPreset) => `${video.getDocumentPath}/${preset.name}.jpeg`,
                    getDocumentPath: () => video.getDocumentPath,
                    updateItem: async (resizeResult) => {
                        video.setCover = resizeResult
                    },
                }}
            />
        </>
    )
}
