import { Button, Typography } from '@material-ui/core'
import { fade } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
import { RowFilterCollection, RowOrderByFieldPath } from '@tivio/firebase'
import { RowComponent, RowItemComponent } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'
import styled from 'styled-components'


import { createTranslation } from '../../../creator/translation.creator'
import { alertError } from '../../../utils/alert.utils'
import { onDragEndUtil } from '../../../utils/order.utils'
import { defaultVideosCollectionWhereFilter } from '../../../utils/row.utils'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { StyledContainer } from '../../DefaultPageContainer'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { useScreen } from '../../hooks/dataHooks/useScreen'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { Repeater } from '../../Repeater'
import { ScreenRow } from '../../screen/ScreenRow'
import { BigButton } from '../../styled'
import { Column } from '../../uiLayout/Column'


const NoRowsContainer = styled.div`
    border: 1px dashed ${props => fade(props.theme.palette.common.white, 0.12)};
    border-radius: .5rem;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`

const RowZeroState = styled(Skeleton)`
    height: 250px;
    margin-top: -50px;
`

const ROW_ZERO_STATES_COUNT = 6

const ScreenPage = observer(() => {
    const { screenId } = useParams<any>()
    const { screen, loading, errors } = useScreen(screenId)
    const { showError } = useAlert()
    const [t] = useTranslation()

    if (errors?.length) {
        return (
            <AppNotFoundTemplate title={t('Failed to load screen')} />
        )
    }

    if (!screen) {
        return null
    }

    const handleCreateRow = async () => {
        try {
            const rowsCount = screen?.rows.length ?? 0

            await screen.addRow({
                order: rowsCount + 1,
                name: createTranslation(`${t('New row')} ${rowsCount}`),
                assets: {},
                rowComponent: RowComponent.ROW,
                itemComponent: RowItemComponent.ROW_ITEM_LANDSCAPE,
                screenRef: screen.ref,
                tags: [],
                description: createTranslation(t('New row description')),
                filter: {
                    collection: RowFilterCollection.VIDEOS,
                    orderBy: {
                        fieldPath: RowOrderByFieldPath.CREATED,
                        directionStr: 'desc',
                    },
                    where: defaultVideosCollectionWhereFilter(screen.organization.ref),
                },
                type: 'filter',
            })
        } catch (e) {
            showError(t('Failed to create new row'))
            console.error(e)
        }
    }

    const handleDragEnd = async (result: DropResult) => {
        try {
            const newRows = onDragEndUtil(result, screen?.rows ?? [])

            if (!newRows) {
                return
            }

            screen.rows = newRows
            await Promise.all(
                newRows.map(
                    (row, index) => row.update({
                        order: index + 1,
                    }),
                ),
            )
        } catch (e) {
            alertError(t('Failed to update sections order'))
            console.error(e)
        }
    }

    return (
        <>
            <DefaultPageHeader title={screen.name.en} />
            <StyledContainer
                maxWidth="lg"
                $bottomGutter={true}
            >
                {loading ? (
                    <Repeater count={ROW_ZERO_STATES_COUNT}>
                        <RowZeroState animation="wave"/>
                    </Repeater>
                ) : (
                    <>
                        {screen.rows.length === 0 && (
                            <NoRowsContainer onClick={handleCreateRow}>
                                <Column
                                    spacing={1}
                                    columnProps={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography variant="h6">{t('The screen is blank')}</Typography>
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        {t('The screen is blank')}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        {t('Add rows')}
                                    </Button>
                                </Column>
                            </NoRowsContainer>
                        )}
                        {screen.rows.length > 0 && (
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="sections">
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {
                                                screen?.rows.map(
                                                    (row, index) => (
                                                        <Draggable
                                                            draggableId={row.id}
                                                            key={row.id}
                                                            index={index}
                                                        >
                                                            {provided => (
                                                                <div
                                                                    {...provided.draggableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    <ScreenRow
                                                                        row={row}
                                                                        dragHandle={provided.dragHandleProps ?? undefined}
                                                                    />
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ),
                                                )
                                            }
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        )}
                        <BigButton
                            fullWidth
                            variant="outlined"
                            onClick={handleCreateRow}
                        >
                            {t('Add new row')}
                        </BigButton>
                    </>
                )}
            </StyledContainer>
        </>
    )
})

export {
    ScreenPage,
}
