import { Dayjs } from 'dayjs'
import { useEffect } from 'react'

import { firebaseTimestampFromDayjs } from '../../../firebase/app'
import { getTvProgramsQuery } from '../../../firebase/firestore/tvProgram'
import { TvProgram } from '../../../store/TvProgram'

import { useOrganization } from './useOrganization'


export type UseTvProgramsOptions = {
    from: Dayjs
    to: Dayjs
    tvChannelIds?: string[]
    /**
     * If true, then related videos will be loaded inside TvProgram store entities.
     */
    loadVideos?: boolean
}

let disposer: () => void

/**
 * Returns tvPrograms based on given options
 */
export const useTvPrograms = (options: UseTvProgramsOptions): { tvPrograms: TvProgram[]} => {
    const { organization } = useOrganization()

    useEffect(() => {
        if (organization) {
            disposer?.()
            disposer = getTvProgramsQuery({
                organizationId: organization.id,
                minFrom: firebaseTimestampFromDayjs(options.from),
                maxFrom: firebaseTimestampFromDayjs(options.to),
                tvChannelIds: options.tvChannelIds,
            })
                .onSnapshot({
                    next: (snapshots) => {
                        (async () => {
                            if (organization) {
                                const tvPrograms = snapshots.docs.map(doc => new TvProgram(doc.ref, doc.data(), organization))
                                if (options.loadVideos) {
                                    await Promise.all(tvPrograms.map(tvProgram => tvProgram.loadVideo()))
                                }
                                organization.tvPrograms = tvPrograms
                            }
                        })()
                    },
                })
        }


        return () => disposer?.()
    }, [options.from.toISOString(), options.to.toISOString, JSON.stringify(options.tvChannelIds)])

    return {
        tvPrograms: organization?.tvPrograms ?? [],
    }
}

