import { AdProfileAdProviderField, OnlineSkippability, OrganizationDocument, StreamSkippability } from '@tivio/firebase'

import { getFirestore, loggerFirestore } from '../app'

import { adProfilesConverter } from './converters'

import type { AdProfileDocumentWithId } from './converters'
import type { AdProfileDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export type AdProfileFirestoreForForm = Omit<AdProfileDocument, 'organizationRef' | 'id'>
    // TODO solve OnlineAdProfileDocument VS StreamAdProfileDocument, which has no adProviders (or maybe set its type to empty array [])
    & {adProviders: AdProfileAdProviderField[]}

const getMonetizationDocument = (id: string) => {
    return getFirestore()
        .doc(`adProfiles/${id}`)
        .withConverter(adProfilesConverter)
}

const getAdProfilesCollection = () => getFirestore()
    .collection('adProfiles')
    .withConverter(adProfilesConverter)

const getAdProfilesOfOrganization = async (organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>) => {
    const result = await getAdProfilesCollection()
        .where('organizationRef', '==', organizationRef)
        .orderBy('name', 'asc')
        .get()

    return result.docs.map((document) => ({
        ...document.data(),
        id: document.id,
    })) as AdProfileDocumentWithId[]
}

const getAdProfileById = async (id: string) => {
    const result = await getMonetizationDocument(id).get()

    if (result.exists) {
        return {
            id,
            ...result.data(),
        } as AdProfileDocumentWithId
    } else {
        throw new Error(`Monetization profile id: ${id} not found`)
    }
}

const createAdProfile = async (adProfileInput: AdProfileDocument) => {
    try {

        const docRef = await getAdProfilesCollection().add({
            ...adProfileInput,
        } as any)

        loggerFirestore.info(`Monetization profile written with ID: ${docRef.id}, data:`, adProfileInput)

        return docRef
    } catch (e) {
        loggerFirestore.error('Failed to create new monetization profile', e)
        throw new Error(e)
    }
}

const updateAdProfile = (id: string, adProfileInput: AdProfileFirestoreForForm) => {
    return getMonetizationDocument(id).set(adProfileInput as any, { merge: true })
}

const deleteAdProfile = (id: string) => {
    return getMonetizationDocument(id).delete()
}

export {
    getAdProfilesOfOrganization,
    getAdProfileById,
    createAdProfile,
    updateAdProfile,
    deleteAdProfile,
    OnlineSkippability,
    StreamSkippability,
}
