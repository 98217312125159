import { MuiThemeProvider, Tab, Tabs } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { MonetizationPlacementConditionField, RowWhereFilterFieldPath } from '@tivio/firebase'
import { TvStreamType } from '@tivio/types'
import React, { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { updateMonetization } from '../../firebase/firestore/monetization'
import { lightTheme } from '../../static/theme'
import Monetization from '../../store/Monetization'
import { alertSuccess } from '../../utils/alert.utils'
import { useEpgChannels } from '../hooks/dataHooks/useEpgChannels'
import { useOrganizationVodChannel } from '../hooks/dataHooks/useOrganizationVodChannel'
import { useOrganizationVodChannelsPagination } from '../hooks/dataHooks/useOrganizationVodChannelsPagination'

import { PlacementCondition } from './placements/PlacementCondition'
import { SubmitButton } from './placements/SubmitButton'
import { PlacementSection } from './PlacementSection'
import { TvStreamTypeSelect } from './TvStreamTypeSelect'

import type { MonetizationPlacementField } from '@tivio/firebase'
import type firebase from 'firebase/app'


type MonetizationPlacementType = 'tvChannelRefs' | 'channelRefs' | 'sectionRefs' | 'videoRefs'

const StyledDialogContent = styled(DialogContent)`
    position: relative;
    width: 560px;
    padding: 3.5rem !important;
`

const PlacementTvChannels: FC<{
    checkedTvChannels: string[]
    changeCheckedTvChannels: (checkedTvChannels: string[]) => void
    onTvStreamChange?: (tvStream: TvStreamType) => void
    tvStream?: TvStreamType
}> = ({
    checkedTvChannels,
    changeCheckedTvChannels,
    onTvStreamChange,
    tvStream,
}) => {
    const [ t ] = useTranslation()
    const { channels } = useEpgChannels()

    return (
        <div>
            <PlacementSection
                label={t('TV channels')}
                elements={channels}
                checkedElements={checkedTvChannels}
                changeCheckedElements={changeCheckedTvChannels}
            />
            <TvStreamTypeSelect
                selected={tvStream ?? null}
                onSelect={onTvStreamChange}
            />
        </div>
    )
}

const PlacementChannels: FC<{
    checkedVodChannels: string[]
    changeCheckedVodChannels: (checkedVodChannels: string[]) => void
    renderChecked: (id: string) => ReactNode
    expandByDefault: boolean
}> = ({
    checkedVodChannels,
    changeCheckedVodChannels,
    renderChecked,
    expandByDefault,
}) => {
    const [ t ] = useTranslation()
    const { channels } = useOrganizationVodChannelsPagination()

    return (
        <PlacementSection
            label={t('Channels')}
            elements={channels ?? []}
            checkedElements={checkedVodChannels}
            changeCheckedElements={changeCheckedVodChannels}
            renderChecked={renderChecked}
            expandByDefault={expandByDefault}
        />
    )
}

const PlacementSections: FC<{
    channelId: string
    checkedSections: string[]
    changeCheckedSections: (checkedSections: string[]) => void
}> = ({
    channelId,
    checkedSections,
    changeCheckedSections,
}) => {
    const [ t ] = useTranslation()
    const { channel } = useOrganizationVodChannel(channelId)

    return (
        <PlacementSection
            label={t('Sections')}
            elements={channel?.getSections ?? []}
            checkedElements={checkedSections}
            changeCheckedElements={changeCheckedSections}
            transformId={(sectionId) => `/channels/${channelId}/sections/${sectionId}`}
            uncheckOnlyNested={true}
        />
    )
}

const PlacementVideos: FC<{
    channelId: string
    checkedVideos: string[]
    changeCheckedVideos: (checkedVideos: string[]) => void
}> = ({
    channelId,
    checkedVideos,
    changeCheckedVideos,
}) => {
    const [ t ] = useTranslation()
    const { channel } = useOrganizationVodChannel(channelId)

    return (
        <PlacementSection
            label={t('Videos')}
            elements={channel?.getVideos ?? []}
            checkedElements={checkedVideos}
            changeCheckedElements={changeCheckedVideos}
            uncheckOnlyNested={true}
        />
    )
}

export type PlacementChangeHandler = (
    checkedTvChannels: string[],
    checkedVodChannels: string[],
    checkedSections: string[],
    checkedVideos: string[],
    tvStream: TvStreamType
) => void

interface PlacementDialogProps {
    monetization: Monetization
    placements?: MonetizationPlacementField
    isOpen: boolean
    onClose?: () => void
    onSubmit?: PlacementChangeHandler
}

export const PlacementDialog: FC<PlacementDialogProps> = ({ monetization, placements, isOpen, onClose, onSubmit }) => {
    const [ t ] = useTranslation()

    const extractIdsFromPlacement = (placementName: MonetizationPlacementType, onItem: (item: firebase.firestore.DocumentReference) => string) => {
        const {
            [placementName]: placement = [],
        } = placements ?? {}

        return placement.map((ref) => onItem(ref))
    }

    const [ selectedTab, setSelectedTab ] = useState(0)

    const [ checkedTvChannels, setCheckedTvChannels ] = useState<string[]>(() => extractIdsFromPlacement('tvChannelRefs', (ref) => ref.id))
    const [ tvStream, setTvStream ] = useState<TvStreamType>(placements?.tvStreamType as TvStreamType ?? TvStreamType.ALL)
    const [ checkedVodChannels, setCheckedVodChannels ] = useState<string[]>(() => extractIdsFromPlacement('channelRefs', (ref) => ref.id))
    const [ checkedSections, setCheckedSections ] = useState<string[]>(() => extractIdsFromPlacement('sectionRefs', (ref) => `/${ref.path}`))
    const [ checkedVideos, setCheckedVideos ] = useState<string[]>(() => extractIdsFromPlacement('videoRefs', (ref) => ref.id))

    const renderChannelVideosTab = () => (
        <>
            <PlacementTvChannels
                checkedTvChannels={checkedTvChannels}
                changeCheckedTvChannels={(newCheckedTvChannels) => setCheckedTvChannels(newCheckedTvChannels)}
                onTvStreamChange={setTvStream}
                tvStream={tvStream}
            />
            <PlacementChannels
                checkedVodChannels={checkedVodChannels}
                changeCheckedVodChannels={(newCheckedVodChannels) => setCheckedVodChannels(newCheckedVodChannels)}
                renderChecked={(channelId) => (
                    <>
                        <PlacementSections
                            channelId={channelId}
                            checkedSections={checkedSections}
                            changeCheckedSections={(newCheckedSections) => setCheckedSections(newCheckedSections)}
                        />
                        <PlacementVideos
                            channelId={channelId}
                            checkedVideos={checkedVideos}
                            changeCheckedVideos={(newCheckedVideos) => setCheckedVideos(newCheckedVideos)}
                        />
                    </>
                )}
                expandByDefault={Boolean(checkedSections.length || checkedVideos.length)}
            />
            <SubmitButton
                onClick={() => {
                    onSubmit?.(
                        checkedTvChannels,
                        checkedVodChannels,
                        checkedSections,
                        checkedVideos,
                        tvStream,
                    )
                    onClose?.()
                }}
            >
                {t('Add')}
            </SubmitButton>
        </>
    )

    /**
     * Submit of conditionVideosTab is handled here in dialog (not use prop.onSubmit) for simplicity now,
     * because we expect, that channelsVideosTab will be also reworked as conditions
     */
    const handleSubmitPlacementConditionTab = async (condition: MonetizationPlacementConditionField) => {
        // TODO TIV-806 now the whole condition is deleted if tags are empty
        //  delete this workaround, when condition placements list will be shown with edit/delete action on each
        const filter = condition.filters?.[0]
        const conditionContainsEmptyTags = filter?.fieldPath === RowWhereFilterFieldPath.TAGS && filter?.value?.length === 0
        const conditions = conditionContainsEmptyTags ? [] : [condition]

        await updateMonetization(monetization, {
            placements: {
                ...monetization.getPlacements,
                conditions,
            },
        })
        alertSuccess('Placements updated')
        onClose?.()
    }

    const renderPlacementConditionTab = () => (
        // TODO TIV-806 when multiple conditions will be supported, use new empty condition as condition, and open
        // dialog with this component, when edit condition
        <PlacementCondition
            onSubmit={handleSubmitPlacementConditionTab}
            condition={placements?.conditions?.[0]}
        />
    )

    return (
        <MuiThemeProvider theme={lightTheme}>
            <Dialog
                open={isOpen}
                onClose={onClose}
            >
                <StyledDialogContent>
                    <Tabs
                        indicatorColor="primary"
                        variant="fullWidth"
                        value={selectedTab}
                        onChange={(event, value) => setSelectedTab(value)}
                    >
                        <Tab label={t('Channels/TV channels')}/>
                        <Tab label={t('Conditions')}/>
                    </Tabs>
                    {selectedTab === 0 ? renderChannelVideosTab() : renderPlacementConditionTab()}
                </StyledDialogContent>
            </Dialog>
        </MuiThemeProvider>
    )
}
