import { Menu, MenuItem, Typography } from '@material-ui/core'
import { VideoDocument, VideoLinkedVideosTypeField } from '@tivio/firebase'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getFirestore } from '../../firebase/app'
import Video from '../../store/Video'
import { Header } from '../common'
import { ItemSearchDialog } from '../common/ItemSearchDialog'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../hooks/uiHooks/useDialog'

import { VideoLinkItem } from './VideoLinkItem'

import type firebase from 'firebase/app'


interface Props {
    video: Video
    disabled: boolean
}

export const VideoLinks: React.FC<Props> = observer(({ video, disabled }) => {
    const [t] = useTranslation()
    const hasLinks = !!video.linkedVideosRaw?.length
    const { showSuccess, showError } = useAlert()
    const { confirmAction } = useConfirmAction()
    const { openDialog, isDialogOpen, closeDialog } = useDialog()
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
    const [selectedLinkType, setSelectedLinkType] = useState<VideoLinkedVideosTypeField>('TRAILER')

    const handleVideoLinkRemove = (videoId: string) => {
        confirmAction(
            async () => {
                const returnStatus = await video.removeLinkedVideo(videoId)
                if (returnStatus) {
                    showSuccess(t('Video link removed.'))
                } else {
                    showError(t('An error occured while removing video link.'))
                }
            },
            t('Are you sure you want to remove this video link?'),
        )
    }

    const handleAddLink = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget)
    }

    const handleAddLinkClose = () => {
        setAnchorElement(null)
    }

    const handleAddLinkDialog = (type: VideoLinkedVideosTypeField) => () => {
        setSelectedLinkType(type)
        handleAddLinkClose()
        openDialog()
    }

    const handleVideoLinkSelected = async (videoPath: string) => {
        if (selectedLinkType) {
            const videoRef = getFirestore().doc(videoPath) as firebase.firestore.DocumentReference<VideoDocument>
            await video.linkVideo(videoRef, selectedLinkType)
            showSuccess(t('New link has been created.'))
        }
        closeDialog()
    }


    useEffect(() => {
        const run = async () => {
            await video.loadLinkedVideos()
        }
        run()
    }, [video.linkedVideosRaw])

    return (
        <>
            <Header
                title={t('Linked videos')}
                actions={{
                    create: !disabled ? {
                        title: t('Link video'),
                        onCreate: handleAddLink,
                    } : undefined,
                }}
            />
            <Menu
                anchorEl={anchorElement}
                keepMounted
                open={!!anchorElement}
                onClose={handleAddLinkClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            >
                <MenuItem onClick={handleAddLinkDialog('TRAILER')}>Trailer</MenuItem>
                <MenuItem onClick={handleAddLinkDialog('TASTING')}>Tasting</MenuItem>
                <MenuItem onClick={handleAddLinkDialog('TEASER')}>Teaser</MenuItem>
                <MenuItem onClick={handleAddLinkDialog('BONUS')}>Bonus</MenuItem>
            </Menu>
            <ItemSearchDialog
                searchIndex={ALGOLIA_INDEX_NAME.VIDEOS}
                open={isDialogOpen}
                onClose={closeDialog}
                onAdd={handleVideoLinkSelected}
                title={t('Link new video')}
                buttonText={t('Link')}
            />
            {video.linkedVideos?.map(linkedVideo => (
                <VideoLinkItem
                    linkedVideo={linkedVideo}
                    key={linkedVideo.id}
                    onRemove={handleVideoLinkRemove}
                    disabled={disabled}
                />
            ))}
            {!hasLinks && !video.isCut && (
                <Typography color="textSecondary">
                    {t('There are no linked videos.')}
                </Typography>
            )}
        </>
    )
})
