import React, { Ref } from 'react'

import { createFairplayEngine, createShakaEngine } from '../engines/factory'

import { VideoPlayer, VideoPlayerProps } from './VideoPlayer'


type Props = Omit<VideoPlayerProps, 'createShakaEngine' | 'createFairplayEngine'> & { ref?: Ref<any> | undefined }

/*
 * This component is a wrapper for VideoPlayer component used by tv-web, admin and SDK.
 * The main reason for it is to avoid dynamic import in `factory.web.tsx`.
 */
export const VideoPlayerCommon = (props: Props) => (
    <VideoPlayer
        {...props}
        createShakaEngine={createShakaEngine}
        createFairplayEngine={createFairplayEngine}
    />
)
