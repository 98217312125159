import { Logger } from '@tivio/common'
import { tivio } from '@tivio/core-js'
import { ReactableContent, ReactionEnum, UseReactions } from '@tivio/types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useUser } from './useUser'


const logger = new Logger('useReacions')

const getContentReactionsCount = (content: ReactableContent, reactionType: ReactionEnum) => {
    return content.reactions?.[reactionType] ?? 0
}

export const useReactions: UseReactions = ({ content, reactionType, onUnauthorized, onError }) => {
    const { user, isSignedIn } = useUser()
    const timeoutRef = useRef<number | null>(null)
    const prevContentPathRef = useRef<string | null>(null)
    const lastSuccessfulStateRef = useRef({
        count: getContentReactionsCount(content, reactionType),
        hasReacted: false,
    })
    const [hasReacted, setHasReacted] = useState(false)

    useEffect(() => {
        if (!user || !isSignedIn || prevContentPathRef.current === content.path) {
            return
        }

        prevContentPathRef.current = content.path

        tivio.getUserReactionBySubjectPath(user.id, content.path).then((reactionDoc) => {
            const newHasReacted = reactionDoc?.data?.reaction === reactionType
            setHasReacted(newHasReacted)
            lastSuccessfulStateRef.current = {
                count: getContentReactionsCount(content, reactionType),
                hasReacted: newHasReacted,
            }
        })
    }, [content, reactionType, user, isSignedIn])

    const react = useCallback(async () => {
        if (!user || !isSignedIn) {
            logger.info('User is not signed in, calling onUnauthorized')
            onUnauthorized?.()
            return
        }

        const newHasReacted = !hasReacted
        setHasReacted(newHasReacted)
        content.updateReactionCount(reactionType, getContentReactionsCount(content, reactionType) + (newHasReacted ? 1 : -1))

        try {
            await new Promise<void>((resolve) => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current)
                }

                timeoutRef.current = window.setTimeout(() => {
                    resolve()
                }, 500)
            })
            if (lastSuccessfulStateRef.current.hasReacted === newHasReacted) {
                return
            }
            await tivio.reactToContent({
                action: newHasReacted ? 'add' : 'remove',
                contentPath: content.path,
                reaction: reactionType,
            })
            tivio.getUserReactionBySubjectPath(user.id, content.path, true)
            lastSuccessfulStateRef.current = {
                count: getContentReactionsCount(content, reactionType),
                hasReacted: newHasReacted,
            }
        } catch (error) {
            logger.error('Error while reacting to content', error)
            setHasReacted(lastSuccessfulStateRef.current.hasReacted)
            content.updateReactionCount(reactionType, lastSuccessfulStateRef.current.count)
            onError?.(error)
        }
    }, [content, hasReacted, isSignedIn, onError, onUnauthorized, reactionType, user])

    return {
        count: getContentReactionsCount(content, reactionType),
        hasReacted,
        react,
    }
}
