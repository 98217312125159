import { analytics, Logger, NewVoucher, tivio } from '@tivio/core-js'
import {
    AnalyticsConversionStatus,
    MONETIZATION_FREQUENCY,
    PurchaseStatus,
    QerkoPaymentInfo,
    User,
} from '@tivio/types'

import Translator from '../../../services/Translator'

import { validateVoucher } from './utils/qerko.utils'


const logger = new Logger('purchaseSubscription')

interface Options {
    onPaid?: () => void
    onError?: () => void
    email?: string
}

interface ProcessPaymentStatusProps {
    paymentInfo?: QerkoPaymentInfo | null
    voucher?: NewVoucher
    user?: User | null
    options?: Options
    monetizationId: string
}

interface FindPurchaseStatusProps {
    voucher?: NewVoucher
    user?: User | null
    paymentInfo?: QerkoPaymentInfo | null
}

interface PurchaseSubscription {
    paymentInfo?: QerkoPaymentInfo | null
    caption?: string
    paymentError?: string
}

interface PurchaseSubscriptionProps {
    monetizationId: string
    user?: User | null
    voucher?: NewVoucher
    options?: Options
}

export async function purchaseSubscription({
    monetizationId,
    user,
    voucher,
    options,
}: PurchaseSubscriptionProps): Promise<PurchaseSubscription> {
    try {
        validateVoucher(voucher)
        const paymentInfo = await tivio.purchaseSubscriptionWithQerko(monetizationId, voucher, options?.email)
        const caption = await generateCaption(paymentInfo, monetizationId)
        await processPaymentStatus({
            paymentInfo,
            voucher,
            user,
            options,
            monetizationId,
        })

        return { paymentInfo, caption }
    } catch (error) {
        logger.error('Error purchasing subscription:', error)
        return { paymentError: error.message }
    }
}

async function processPaymentStatus({
    paymentInfo,
    voucher,
    user,
    options,
    monetizationId,
}: ProcessPaymentStatusProps): Promise<void> {
    const purchaseStatus = findPurchaseStatus({ voucher, user, paymentInfo })
    if (purchaseStatus === PurchaseStatus.PAID) {
        options?.onPaid?.()
        analytics.completeMonetizationPurchase(AnalyticsConversionStatus.SUCCESS, monetizationId)
    } else if (purchaseStatus === PurchaseStatus.ERROR) {
        options?.onError?.()
        analytics.completeMonetizationPurchase(AnalyticsConversionStatus.FAILED, monetizationId)
    }
}

async function generateCaption(paymentInfo: QerkoPaymentInfo, monetizationId: string): Promise<string> {
    const monetization = await getMonetization(monetizationId)
    if (!monetization || !paymentInfo) {
        return ''
    }

    const frequencyString = monetization.frequency !== MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT
        ? ` / ${Translator.t(monetization.frequency)}`
        : ''

    return `${monetization.name} - ${paymentInfo.amount} ${paymentInfo.currency}${frequencyString}`
}

async function getMonetization(monetizationId: string) {
    if (!tivio.organization) {
        logger.warn('Organization is not set, cannot get monetization')
        return null
    }
    const monetizations = await tivio.organization.getSubscriptions()
    return monetizations.find(item => item.id === monetizationId)
}

function findPurchaseStatus({
    voucher,
    user,
    paymentInfo,
}: FindPurchaseStatusProps): PurchaseStatus | undefined {
    const purchases = voucher ? user?.allPurchases : user?.purchases
    const purchase = purchases?.find(purchase => purchase.id === paymentInfo?.purchaseId)
    return purchase?.status ? purchase?.status : undefined
}


