import { useContext } from 'react'

import { ConfigContext } from '../context'


const useConfig = () => {
    const { config, tivioConfig, assets, search } = useContext(ConfigContext)

    if (!config || !tivioConfig || !assets) {
        throw new Error('Config not found')
    }

    return {
        config,
        tivioConfig,
        assets,
        search,
    }
}

export {
    useConfig,
}
