import { NewVoucher } from '@tivio/core-js'


function validateVoucher(voucher?: NewVoucher) {
    if (voucher) {
        if (voucher.expirationDate instanceof Date && !isNaN(voucher.expirationDate.getTime())) {
            voucher.expirationDate = voucher.expirationDate.getTime()
        }
        else if (isNaN(Number(voucher.expirationDate))) {
            throw new Error('Invalid voucher expiration date. Must be Date or number (date in miliseconds).')
        }
    }
}

export {
    validateVoucher,
}
