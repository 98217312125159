import { makeAutoObservable, runInAction } from 'mobx'

import { type TVersionFirestore, updateVersion } from '../firebase/firestore/versions'
import { alertError } from '../utils/alert.utils'

import type Device from './Device'


class Version {
    device: Device
    id: string | null = null
    secretId: string | null = null
    name: string | null = null
    description: string | null = null
    percentage: number | null = null

    constructor(device: Device | Device) {
        this.device = device
        makeAutoObservable(
            this,
            { },
            { autoBind: true },
        )
    }

    async fromFirestore(id: string, secretId: string, doc: TVersionFirestore) {
        this.id = id
        this.secretId = secretId
        this.name = doc.name ?? null
        this.description = doc.description ?? null

        const percentage: number | null = typeof doc.percentage === 'string' ?
            parseInt(doc.percentage, 10) : doc.percentage

        if (!isNaN(percentage as number) && percentage !== null && percentage > 0) {
            this.percentage = percentage
        } else {
            this.percentage = 0
        }
    }

    get getPercentageLabel() {
        if (this.percentage === null) {
            return null
        }
        return `${this.percentage}%`
    }

    get getVerbosity() {
        if (this.percentage === 100) {
            return 'success'
        } else if (this.percentage === 0) {
            return 'info'
        } else {
            return 'warning'
        }
    }

    get isDeployed() {
        return this.percentage === 100
    }

    get isRolledBack() {
        return this.percentage === 0
    }

    set setPercentage(percentage: number) {
        this.percentage = percentage
    }

    async updatePercentage(percentage = 100) {
        runInAction(async () => {
            const organizationId = this.device.target.deploy.organization.id
            if (!this.id || !this.secretId) {
                alertError('Secret or Version no longer exists')
                return
            }

            await updateVersion(organizationId, this.secretId, this.id, { percentage })
            this.percentage = percentage
        })
    }
}

export default Version
