import { useState } from 'react'


export const useDialog = <T>(isOpen = false) => {
    const [isDialogOpen, setIsDialogOpen] = useState(isOpen)
    const [data, setData] = useState<T | null>(null)

    const openDialog = (data?: T) => {
        setIsDialogOpen(true)
        setData(data ?? null)
    }

    const closeDialog = () => {
        setIsDialogOpen(false)
        setData(null)
    }


    return {
        data,
        isDialogOpen,
        openDialog,
        closeDialog,
    }
}
