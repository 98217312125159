import React from 'react'

import { OverviewCard } from '../OverviewCard'

import { ViewsCountDetail } from './types'


interface Props {
    viewsCountDetail: ViewsCountDetail
    gainInterval: string
}

export const ViewsDetailCard: React.FC<Props> = (props) => {
    const { viewsCountDetail, gainInterval } = props

    return (
        <OverviewCard
            title="Views"
            value={viewsCountDetail.currentPeriodViewCount}
            gain={viewsCountDetail.gainViewCount}
            gainInterval={gainInterval}
            key="views"
        />
    )
}
