import { MuiThemeProvider } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { lightTheme } from '../static/theme'

import { ConfirmActionContext } from './context/ConfirmActionContext'


const ConfirmDialog = () => {
    const {
        action,
        actionTitle,
        actionMessage,
        confirmButtonMessage,
        cancelButtonMessage,
        closeDialog,
        isOpened,
    } = useContext(ConfirmActionContext)
    const [ t ] = useTranslation()

    const handleConfirmAction = () => {
        action()
        closeDialog()
    }

    return (
        <MuiThemeProvider theme={lightTheme}>
            <Dialog
                open={isOpened}
                onClose={closeDialog}
            >
                <DialogTitle>{actionTitle ?? t('Confirm action')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {actionMessage ?? t('Are you sure you want to perform this action?')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={closeDialog}
                        color="primary"
                    >
                        {cancelButtonMessage ?? t('Cancel')}
                    </Button>
                    <Button
                        onClick={handleConfirmAction}
                        color="primary"
                        autoFocus
                        variant="contained"
                    >
                        {confirmButtonMessage ?? t('Confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiThemeProvider>
    )
}

export {
    ConfirmDialog,
}
