import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { type FirebaseUser, getAuth } from '../firebase/app'
import { listenGlobalAssetPresets } from '../firebase/firestore/assetPresets'
import { getCustomClaims, getMemberByUid } from '../firebase/firestore/member'
import { initOrganization } from '../firebase/firestore/organization'
import { listenGlobalTagTypes } from '../firebase/firestore/tagType'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { goAnalyticsPage } from '../utils/history.utils'
import { removeCurrentOrganizationId, setCurrentOrganizationId } from '../utils/organization.utils'

import Admin from './Admin'
import Alert from './Alert'

import { getGlobalAccessTags } from '../firebase/firestore/tag'

import type { AssetPreset } from './AssetPreset'
import type Member from './Member'
import type Organization from './Organization'
import type { Tag } from './Tag'
import type { TagType } from './TagType'


const firebaseAuth = getAuth()
const logger = new Logger('Store')

class Store {
    private alert: Alert = new Alert()
    private member: Member | null = null
    private _globalAssetPresets: AssetPreset[] = []
    private _globalTagTypes: TagType[] = []
    private _globalDistributionTags: Tag[] = []

    constructor() {
        makeAutoObservable(this)

        listenGlobalAssetPresets(presets => {
            this._globalAssetPresets = presets
        })
        listenGlobalTagTypes(tagTypes => {
            this._globalTagTypes = tagTypes
        })

        this.initDistributionTags()
    }

    switchOrganization = async (organization: Organization) => {
        try {
            if (this.member) {
                this.member.getCurrentOrganization?.dispose()

                this.member.setCurrentOrganization = await initOrganization(organization, this.member.getUid)
                this.member.setCurrentRole = organization.firestoreData.members[this.member.getUid].role

                const admin = new Admin(this.member)
                await admin.listOrganizations(this.member)
                this.member.setAdmin = admin

                setCurrentOrganizationId(organization.id)

                goAnalyticsPage()

                alertSuccess(i18n.t('Organization switched'))
            }
        }
        catch (e) {
            alertError(i18n.t('Failed to switch organization'))
            logger.error(e)
        }
    }

    initMember = async (user: FirebaseUser) => {
        try {
            const claims = await getCustomClaims(user)
            this.setMember = await getMemberByUid(user.uid, claims.tivioUserId)
        }
        catch (e) {
            throw new Error(e)
        }
    }

    initDistributionTags = async () => {
        getGlobalAccessTags().then((tags) => {
            this._globalDistributionTags = tags
        })
    }

    login = async (email: string, password: string) => {
        try {
            await firebaseAuth.signInWithEmailAndPassword(email, password)
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    signInWithCustomToken = async (token: string) => {
        try {
            await firebaseAuth.signInWithCustomToken(token)
        } catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    logout = async () => {
        try {
            await firebaseAuth.signOut()

            this.setMember = null

            removeCurrentOrganizationId()
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    recoverPassword = async (email: string) => {
        try {
            await firebaseAuth.sendPasswordResetEmail(email, {
                url: process.env.REACT_APP_FIREBASE_RESET_PASSWORD_CONTINUE_URL,
            })
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    get globalAssetPresets() {
        return this._globalAssetPresets
    }

    get globalTagTypes() {
        return this._globalTagTypes
    }

    get globalDistributionTags() {
        return this._globalDistributionTags
    }

    get getAlert() {
        return this.alert
    }

    get getMember() {
        return this.member
    }

    set setMember(member: Member | null) {
        this.member = member
    }
}

export const store = new Store()

export default store
