import firebase from 'firebase/app'

import { getStorage, loggerStorage } from './app'

import type { BucketUri } from './app'


interface Options {
    bucketUri?: BucketUri
    onProgress?: (progress: number) => any
    metadata?: firebase.storage.SettableMetadata
}

/**
 * Upload video to storage
 * @param file - file to upload
 * @param filePath - file path
 * @param options - see {@link Options}
 */
const uploadFile = async (
    file: File,
    filePath: string,
    options?: Options,
): Promise<string> => {
    const storageRef = getStorage(options?.bucketUri).ref()
    const fileRef = storageRef.child(filePath)
    const onProgress = options?.onProgress

    try {
        const uploadTask = fileRef.put(file)

        if (onProgress) {
            uploadTask.on('state_changed', (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                onProgress(progress)
            })
        }

        const snapshot = await uploadTask

        const metadata = options?.metadata

        if (metadata) {
            try {
                await fileRef.updateMetadata(metadata)
            } catch (error) {
                loggerStorage.warn('File metadata update failed', filePath, error, metadata)
            }
        }

        const downloadLink = await snapshot.ref.getDownloadURL()

        loggerStorage.info(`File '${filePath}' uploaded. Download link:`, downloadLink)

        return downloadLink
    }
    catch (e) {
        loggerStorage.error('Failed to upload file', e)
        throw new Error(e)
    }
}

export {
    uploadFile,
}
