import { tivio } from '@tivio/core-js'
import {
    ForbiddenOnCallError,
    ForbiddenReason,
    SourceParams,
    TvChannel,
    Video as VideoInterface,
} from '@tivio/types'
import { useCallback, useEffect, useState } from 'react'

import { SourceParamsFactory } from './sourceParamsFactory'
import { UseInputSourceOptions } from './types'


export type VideoPath = string
export type ChannelPath = string

/**
 * Returns source and additional related data (fetched video etc.)
 *
 * @param source video source (object or video/channel path)
 * @param options used to set continue to watch position in milliseconds and/or to use audio only URL
 */
export const useInputSource = (
    source: SourceParams | VideoPath | ChannelPath | null,
    options?: UseInputSourceOptions,
) => {
    const [loading, setLoading] = useState(false)
    const [state, setState] = useState<{
        video?: VideoInterface | null
        channel?: TvChannel | null
        source?: SourceParams | null
        error?: Error | null
        errorType: ForbiddenReason | null
    }>({
        channel: null,
        video: null,
        source: null,
        error: null,
        errorType: null,
    })

    const [forceRefresh, setForceRefresh] = useState({})

    const reset = useCallback(() => {
        setState({
            video: null,
            source: null,
            error: null,
            errorType: null,
        })
    }, [])

    useEffect(() => {
        let active = true
        setLoading(true)

        const fetchSourceParams = async () => {
            if (!source) {
                reset()
                return
            }

            if (!tivio.organization) {
                setState(s => ({ ...s, error: new Error('Organization not found.') }))
                return
            }

            const {
                params,
                error,
                video,
                channel,
            } = await new SourceParamsFactory(
                source,
                options,
            ).create()

            if (!active) {
                return
            }

            setLoading(false)
            setState({
                source: params,
                errorType: (error as ForbiddenOnCallError)?.details?.reason ?? null,
                channel,
                error,
                video,
            })
        }

        fetchSourceParams()
            .finally(() => {
                setLoading(false)
            })

        return () => {
            active = false
        }
    }, [source, forceRefresh])

    return {
        ...state,
        loading,
        refresh: () => setForceRefresh({}),
    }
}
