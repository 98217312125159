import { FormControl, InputLabel, makeStyles, OutlinedInput } from '@material-ui/core'
import { DurationWithUnit } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AppSelect, AppSelectOption } from '../../AppSelect'
import { Row } from '../../uiLayout/Row'


type SelectType = AppSelectOption & { value: DurationWithUnit['unit'] };

const options: SelectType[] = [
    { value: 'day', optionLabel: 'Day' },
    { value: 'week', optionLabel: 'Week' },
    { value: 'month', optionLabel: 'Month' },
]

const useStyles = makeStyles({
    rowItem: {
        flex: 1,
    },
})

interface Props {
    value: DurationWithUnit
    onChange: (value: DurationWithUnit) => void
}

export const DurationField = ({ value, onChange }: Props) => {
    const [t] = useTranslation()
    const classes = useStyles()
    return (
        <Row
            spacing={1}
            itemProps={{
                className: classes.rowItem,
            }}
        >
            <FormControl
                variant="outlined"
            >
                <InputLabel>{t('Duration length')}</InputLabel>
                <OutlinedInput
                    label={t('Duration length')}
                    margin="dense"
                    type="number"
                    inputProps={{
                        min: 1,
                    }}
                    value={value.value}
                    onChange={(e) => onChange({ ...value, value: Number(e.target.value) })}
                />
            </FormControl>
            <AppSelect
                label={t('Duration unit')}
                options={options}
                className={classes.rowItem}
                value={value.unit}
                onChange={(e) => onChange({ ...value, unit: e.target.value as DurationWithUnit['unit'] })}
            />
        </Row>
    )
}
