import { MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import type { Track } from 'shaka-player'


const useStyles = makeStyles(() => ({
    select: {
        padding: '6.5px',
    },
    outlined: {
        paddingRight: '56px !important',
    },
}))

interface Props {
    selectedTrack: Track
    tracks: Track[]
    onChange: (source: Track) => void
}

export const TrackSelect = ({ selectedTrack, tracks, onChange }: Props) => {
    const classes = useStyles()

    const items = tracks.map((track) => (
        <MenuItem
            key={track.id}
            value={track.id}
            dense
        >
            {track.height}, {track.language}
        </MenuItem>
    ))

    const onSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        onChange(tracks.find(q => q!.id === event.target.value as number)!)
    }

    return (
        <>
            <Select
                value={selectedTrack?.id}
                onChange={onSelect}
                variant="outlined"
                required
                autoWidth
                classes={{ root: classes.select, outlined: classes.outlined }}
            >
                {items}
            </Select>
        </>
    )
}
