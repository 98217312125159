import memoizeOne from 'memoize-one'
import { makeAutoObservable, onBecomeObserved } from 'mobx'
import { useObserver } from 'mobx-react-lite'

import { listenAllTvChannels } from '../../firebase/firestore/tvChannels'
import TvChannel from '../TvChannel'

/**
 * This is a class just because onBecomeObserved on
 * an observable object gave me type error for some reason
 */
class TvChannels {
    all: TvChannel[] = []

    constructor() {
        makeAutoObservable(this)

        onBecomeObserved(this, 'all', memoizeOne(() => {
            listenAllTvChannels(channels => {
                this.all = channels
            })
        }))
    }
}

export const tvChannels = new TvChannels()

export const useAllTvChannels = () => {
    return useObserver(() => tvChannels.all)
}

export const useAllTvChannelFilters = () => {
    const allTvChannels = useAllTvChannels()
    return allTvChannels
        .map(tvChannel => tvChannel.filters)
        .flat()
        .sort()
}
