import { Grid, IconButton, Typography } from '@material-ui/core'
import Create from '@material-ui/icons/Create'
import Send from '@material-ui/icons/Send'
import { OrganizationMemberRoles } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import store from '../../../store'
import OrganizationAdmin from '../../../store/OrganizationAdmin'
import { goOrganizationSettingsPage } from '../../../utils/history.utils'
import DialogEditOrganization from '../../dialog/DialogEditOrganization'
import OrganizationTile from '../../organization/OrganizationTile'


const StyledTitle = styled(Typography)`
    cursor: pointer;
`

interface TProps {
    organization: OrganizationAdmin
}

const SettingsOrganization = observer((props: TProps) => {
    const { getMember } = store
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [t] = useTranslation()
    const { organization } = props

    const goToDetail = async () => {
        const member = getMember
        if (member?.getCurrentRole === OrganizationMemberRoles.SUPER_ADMIN) {
            // console.log('looking for organization')
            // console.log(organization.id)
            // console.log('in')
            // console.log(member.getOrganizations.map((organizationItem) => organizationItem.id))
            const foundOrganization = member?.getOrganizations.find((organizationItem) => organizationItem.id === organization.id)
            if (foundOrganization) {
                // TODO: Don't switch here, or do, but don't use ID in url, or do, but switch upon page load
                await store.switchOrganization(foundOrganization)
            } else {
                throw new Error(t('Organization not found in the store'))
            }
        }

        goOrganizationSettingsPage()
    }

    return (
        <>
            <DialogEditOrganization
                hideActionsButtons={true}
                organization={organization}
                onCancel={() => setEditDialogOpen(false)}
                onConfirm={() => setEditDialogOpen(false)}
                open={editDialogOpen}
            />
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Grid
                        container
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-start"
                        wrap="nowrap"
                        spacing={4}
                    >
                        <Grid item>
                            <OrganizationTile organization={organization} />
                        </Grid>
                        <Grid item>
                            <StyledTitle
                                onClick={goToDetail}
                                variant="h6"
                            >
                                {organization.getName}
                            </StyledTitle>
                            <Typography variant="caption">
                                {organization.getMembersCount} {t('Users')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => setEditDialogOpen(true)}>
                        <Send />
                    </IconButton>
                </Grid>
                <Grid item>
                    <IconButton onClick={goToDetail}>
                        <Create />
                    </IconButton>
                </Grid>
            </Grid>
        </>
    )
})

export default SettingsOrganization
