import { makeStyles } from '@material-ui/core'
import React from 'react'

import { useVideo } from '../../../hooks/dataHooks/useVideo'


const useStyles = makeStyles({
    cover: {
        width: '100%',
        aspectRatio: '16 / 9',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
})

interface Props {
    videoId: string
}

export const VideoBlockPreview = ({ videoId }: Props) => {
    const classes = useStyles()
    const { video } = useVideo(videoId)

    return <div
        className={classes.cover}
        style={{ backgroundImage: `url(${video?.cover})` }}
    />
}
