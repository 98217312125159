import React from 'react'
import { Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Column } from '../uiLayout/Column'

import { DialogScreenRow, DialogScreenRowProps } from './DialogScreenRow'


const Container = styled.div<{
    hasItems: boolean
}>`
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    ${({ hasItems }) =>
        !hasItems &&
        `
        min-height: 64px;
        border: dashed 1px #bdbdbd;
    `}
`

const PlaceholderText = styled.div`
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    color: rgba(0, 0, 0, 0.23);
    font-size: 0.875rem;
    user-select: none;
`

interface Props {
    droppableId: string
    items: Omit<DialogScreenRowProps, 'dragHandle' | 'onNameChange'>[]
    onItemNameChange?: (index: number, name: string) => void
    onDragEnd?: (result: DropResult) => void
}

export const DialogDraggableScreensList = ({ droppableId, items, onItemNameChange }: Props) => {
    const [t] = useTranslation()

    const renderItems = () =>
        items.map((screen, index) => (
            <Draggable
                draggableId={screen.screenId}
                key={screen.screenId}
                index={index}
            >
                {(draggableProvided) => (
                    <div
                        {...draggableProvided.draggableProps}
                        ref={draggableProvided.innerRef}
                    >
                        <DialogScreenRow
                            key={screen.screenId}
                            dragHandle={draggableProvided.dragHandleProps}
                            onNameChange={onItemNameChange ? (name) => onItemNameChange(index, name) : undefined}
                            {...screen}
                        />
                    </div>
                )}
            </Draggable>
        ))

    return (
        <Droppable droppableId={droppableId}>
            {(droppableProvided) => (
                <Container
                    ref={droppableProvided.innerRef}
                    {...droppableProvided.droppableProps}
                    hasItems={items.length > 0}
                >
                    {items.length > 0 ? <Column spacing={2}>{renderItems()}</Column> : <PlaceholderText>{t('Drag & drop screens here')}</PlaceholderText>}
                    {droppableProvided.placeholder}
                </Container>
            )}
        </Droppable>
    )
}
