import { Typography } from '@material-ui/core'
import { Dayjs } from 'dayjs'
import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'
import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'


export const TIMELINE_HEIGHT_PX = 2

const Container = styled.div<{ top: number }>`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    top: ${props => props.top}px;
`

const Timeline = styled.div`
    width: 100%;
    height: ${TIMELINE_HEIGHT_PX}px;
    background-color: ${COLORS.EPG_LINE};
    opacity: 0.5;
`

const Text = styled(Typography)`
    margin-left: -44px;
    margin-top: -12px;
    margin-right: 4px;
`

type Props = {
    time: Dayjs
    showText?: boolean
}

/**
 * One line with concrete time (00:00, 01:00 etc.) inside ONE column.
 */
export const ScheduleTimeline = ({ time, showText }: Props) => {
    const { convertTimeToPx } = useEpgUI()
    const formattedTime = time.format('HH:mm')

    const top = convertTimeToPx(time) - TIMELINE_HEIGHT_PX

    return (
        <Container top={top}>
            {showText && <Text>{formattedTime}</Text>}
            <Timeline/>
        </Container>
    )
}
