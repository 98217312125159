import { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import {
    addTag as addTagFirebase,
    getAllTags as getAllTagsFirebase,
    getGlobalTags as getGlobalTagsFirebase,
    getNotComposedTags,
    getTagById,
    getTagsByTagId,
    removeTag as removeTagFirebase,
} from '../../../firebase/firestore/tag'
import { Logger } from '../../../logger'
import { createTagData } from '../../../utils/tag.utils'
import { useAllTagTypes } from '../../settings/tagTypes/hooks'
import { useAlert } from '../uiHooks/useAlert'

import { useOrganization } from './useOrganization'


const logger = new Logger('UseTags')

const useTags = () => {
    const { organization } = useOrganization()
    const [t] = useTranslation()
    const { showSuccess, showError } = useAlert()

    const tagTypes = useAllTagTypes()

    const addTag = useCallback(async () => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            const tag = await addTagFirebase(organization.id, createTagData(tagTypes[0].ref))
            showSuccess(t('Tag created'))
            return tag
        }
        catch (e) {
            showError(t('Failed to create tag'))
            console.error(e)
        }
    }, [organization?.id, showError, showSuccess, t, tagTypes])

    const removeTag = async (id: string) => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            await removeTagFirebase(organization.id, id)
            showSuccess(t('Tag removed'))
        }
        catch (e) {
            showError(t('Failed to remove tag'))
            console.error(e)
        }
    }

    const getAllTags = async () => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            const tags = await getAllTagsFirebase(organization.id)
            return tags
        }
        catch (e) {
            showError(t('Failed to get all tags'))
            console.error(e)
        }
    }

    const getGlobalTags = async () => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }

        try {
            return await getGlobalTagsFirebase()
        } catch (e) {
            showError(t('Failed to get global tags'))
            console.error(e)
        }
    }

    const findTagById = async (id: string) => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            const tag = await getTagById(organization.id, id)
            return tag
        }
        catch (e) {
            showError(t('Failed to get tag by id'))
            console.error(e)
        }
    }

    const findTagsByTagId = async (tagId: string) => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            const tag = await getTagsByTagId(organization.id, tagId)
            return tag
        }
        catch (e) {
            showError(t('Failed to get tags by tagId'))
            console.error(e)
        }
    }

    const findAllNotComposedTags = async () => {
        if (!organization?.id) {
            logger.error('Organization is not defined')
            return
        }
        try {
            const tag = await getNotComposedTags(organization.id)
            return tag
        }
        catch (e) {
            showError(t('Failed to get not composed tags'))
            console.error(e)
        }

    }

    return {
        addTag,
        removeTag,
        findTagById,
        findTagsByTagId,
        findAllNotComposedTags,
        getAllTags,
        getGlobalTags,
    }
}

export {
    useTags,
}
