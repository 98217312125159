// @ts-nocheck
import { ChannelDocument, SectionDocument } from '@tivio/firebase'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { updateChannel } from '../firebase/firestore/channel'
import { initChannel } from '../firebase/firestore/organization'
import { addSection, deleteSection } from '../firebase/firestore/section'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { goChannelPage } from '../utils/history.utils'

import type Organization from './Organization'
import type Video from './Video'

import Section from './Section'

import type firebase from 'firebase/app'


const logger = new Logger('Channel')

class Channel {
    private initialized = false
    private readonly ref: firebase.firestore.DocumentReference<ChannelDocument>
    private firestoreData: ChannelDocument
    private readonly organization: Organization
    private sections: Section[] = []

    constructor(
        ref: firebase.firestore.DocumentReference<ChannelDocument>,
        firestoreData: ChannelDocument,
        organization: Organization,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.organization = organization

        makeAutoObservable(this)
    }

    /**
     * At start channels does not have sections and videos
     * When user will come to channel detail page then it will init channel and fetch all channel data
     */
    initChannel = async () => {
        if (this.initialized) {
            return
        }

        logger.info(`Going to init channel: ${this.getName}`)

        await initChannel(this)
    }

    updateChannelHeader = async (header: string) => {
        try {
            // @ts-expect-error
            await updateChannel(this, { header })

            this.setHeader = header
        }
        catch (e) {
            alertError(i18n.t('Failed to upload channel header'))
            logger.error(e)
        }
    }

    updateChannelLogo = async (headerLogo: string) => {
        try {
            // @ts-expect-error
            await updateChannel(this, { headerLogo })

            this.setHeaderLogo = headerLogo
        }
        catch (e) {
            alertError(i18n.t('Failed to upload channel logo'))
            logger.error(e)
        }
    }

    updateName = async (name: string) => {
        const temp = this.getName

        try {
            this.setName = name

            await updateChannel(this, { name })

            alertSuccess(i18n.t('Channel name updated'))
        }
        catch (e) {
            this.setName = temp
            alertError(i18n.t('Failed to update channel name'))
            logger.error(e)
        }
    }

    updateSectionsOrder = async (sectionsOrder: firebase.firestore.DocumentReference<SectionDocument>[]) => {
        try {
            // @ts-ignore
            await updateChannel(this, { sectionsOrder })

            this.setSectionsOrder = sectionsOrder
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    addSection = async (sectionData: SectionDocument) => {
        try {
            const sectionRef = await addSection(this, sectionData)
            const section = new Section(sectionRef, sectionData, this)

            // @ts-ignore
            await this.updateSectionsOrder(
                // @ts-ignore
                this.getSectionsOrder.concat([sectionRef]),
            )

            this.setSections = this.getSections.concat(section)

            alertSuccess(i18n.t('Section added'))
        }
        catch (e) {
            alertError(i18n.t('Failed to add section'))
            logger.error(e)
        }
    }

    async deleteSection(sectionToDelete: Section) {
        await Promise.all(
            sectionToDelete.getElements.map(sectionToDelete.handleDeleteElement),
        )
        await deleteSection(sectionToDelete)
        await this.updateSectionsOrder(
            // @ts-ignore
            this.getSectionsOrder.filter(
                // @ts-ignore
                sectionRef => sectionRef.id !== sectionToDelete.getId,
            ),
        )

        this.setSections = this.getSections.filter(section => sectionToDelete.getId !== section.getId)
    }

    goChannelPage = () => {
        goChannelPage(this.getId)
    }

    get getId() {
        return this.ref.id
    }

    get getOrganization() {
        return this.organization
    }

    get getName() {
        return this.firestoreData.name
    }

    get getHeader() {
        return this.firestoreData.header
    }

    get getHeaderLogo() {
        return this.firestoreData.headerLogo
    }

    get getSections() {
        return this.sections
    }

    get getSectionsOrder() {
        return this.firestoreData.sectionsOrder || []
    }

    get getVideos() {
        return (this.sections || []).reduce<Video[]>((acc, section) => {
            acc = acc.concat(section.getVideos)
            return acc
        }, [])
    }

    get getElements() {
        return (this.sections || []).reduce<any[]>((acc, section) => {
            acc = acc.concat(section.getElements)
            return acc
        }, [])
    }

    get getRef() {
        return this.ref
    }

    get getMonetizationsObjs() {
        return this.firestoreData.monetizations ?? []
    }

    set setHeader(header: string) {
        this.firestoreData.header = header
    }

    set setHeaderLogo(headerLogo: string) {
        this.firestoreData.headerLogo = headerLogo
    }

    set setName(name: string) {
        this.firestoreData.name = name
    }

    set setSections(sections: Section[]) {
        this.sections = sections
    }

    set setIsInitialized(isInitialized: boolean) {
        this.initialized = isInitialized
    }

    set setSectionsOrder(sectionsOrder: firebase.firestore.DocumentReference<SectionDocument>[]) {
        // @ts-ignore
        this.firestoreData.sectionsOrder = sectionsOrder
    }
}

export default Channel
