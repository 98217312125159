import styled from 'styled-components'


export const NotificationImage = styled.img`
    width: 190px;
    height: 107px;
    object-fit: cover;
    object-position: top;
    border-radius: 8px;
`
