import { Container } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { TivioNotification } from '../../store/Notification'

import { NotificationItem } from './NotificationItem'


export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16px 0;
    width: 100%;
`

export const RowWrapper = styled(Container)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: ${({ theme }) => theme.spacing(1)}px;

    &:hover {
        background-color: ${({ theme }) => theme.palette.action.hover}
    }
`

interface Props {
    notifications: TivioNotification[]
    removeNotification: (notification: TivioNotification) => Promise<void>
}

export const NotificationList = ({ notifications, removeNotification }: Props) => {
    return (
        <div>
            {
                notifications?.map(notification => (
                    <NotificationItem
                        key={notification.id}
                        notification={notification}
                        removeNotification={removeNotification}
                    />
                ))
            }
        </div>
    )
}
