import { useEffect, useState } from 'react'

import { useOrganization } from './useOrganization'
import { TivioNotification } from '../../../store/Notification'


export const useNotification = (id: string) => {
    const { organization } = useOrganization()
    const [, setIsLoaded] = useState(false)

    useEffect(() => {
        organization?.loadNotificationById(id).then(() => setIsLoaded(true))
    }, [ id ])

    return {
        notification: organization?.getNotificationById(id),
        loading: organization?.notificationsState.loading || organization?.notificationsState.data,
        errors: organization?.notificationsState.errors,
    }
}
