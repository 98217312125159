import styled from 'styled-components'


export const ColorCircle = styled.div<{ color?: string }>`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    cursor: ${p => p.onClick ? 'pointer' : 'default'};
    background: ${p => p.color || '#ccc'};
`
