import { toJS } from 'mobx'

import { ASSET_BUCKET_URI, getFunctions } from '../../firebase/app'
import { uploadFile } from '../../firebase/storage'
import Logger from '../../logger'

import type { AssetResizeResult } from '../../firebase/firestore/types'
import type { AssetPreset } from '../../store/AssetPreset'
import type { AssetsField, Scale } from '@tivio/types'


const logger = new Logger('asset.utils')

/**
 * @param imagePath path inside ASSET_BUCKET_URI bucket
 * @param preset asset preset
 * @param scaling to which qualities image will be resized. If not given, then preset.scaling is used
 * @throws
 */
export const resizeImage = async (
    imagePath: string,
    documentPath: string,
    preset: AssetPreset,
    organizationId: string,
    scaling?: Scale[],
    mode: 'fill' | 'fit' | 'inside' | 'stretch' = 'fill',
    outputFormat?: 'png' | 'jpeg',
    quality?: number,
    keepOriginal?: boolean,
) => {
    const resizeAsset = getFunctions().httpsCallable('resizeAsset')

    logger.info(`Requested resizing of ${imagePath} according to preset`, toJS(preset),
        scaling ? ` with overridden scaling: [${scaling.join(', ')}]` : '')

    const uris = await resizeAsset({
        imagePath,
        documentPath,
        organizationId,
        scaling: scaling || preset.scaling,
        presetId: preset.name,
        mode,
        outputFormat: outputFormat || 'jpeg',
        quality: quality,
        width: preset.width,
        height: preset.height,
        keepOriginal,
    })

    logger.info(`Successfully resized image according to ${preset.name}. Uris:`, uris)

    return uris.data as AssetResizeResult[]
}

/**
 * @param destinationPath path inside ASSET_BUCKET_URI bucket
 * @throws
 */
export const uploadImage = (file: File, destinationPath: string) => {
    console.info(`File upload of ${destinationPath} started...`)

    return uploadFile(file, destinationPath, { bucketUri: ASSET_BUCKET_URI })
}

export const getAsset = (assets: AssetsField, presetName: string, scale?: Scale) => {
    const presetAssets = assets?.[presetName]

    if (!presetAssets) {
        return null
    }

    const scaledAsset = scale ?
        presetAssets[scale] : (presetAssets['@1'] ?? presetAssets['@2'] ?? presetAssets['@3'])

    return scaledAsset?.background ?? null
}
