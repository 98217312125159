import { InputAdornment, makeStyles, TextField } from '@material-ui/core'
import { DateRange } from '@material-ui/icons'
import dayjs, { Dayjs } from 'dayjs'
import React from 'react'


const useStyles = makeStyles(() => ({
    textField: {
        '&.MuiInput-underline': {
            '&::after, &::before, &:hover:not(.Mui-disabled)::before': {
                borderBottom: 'none',
            },
        },
    },
}))

interface Props {
    value: string | Dayjs | Date,
    label?: string,
    showIcon?: boolean
    disabled?: boolean
}


export const DateReadOnlyInput: React.FC<Props> = (props) => {
    const { value, label, showIcon = false, disabled = false } = props
    const classes = useStyles()

    return (
        <TextField
            label={label}
            value={dayjs(value).format('DD.MM.YYYY')}
            inputProps={{
                readOnly: true,
            }}
            InputProps={{
                endAdornment: (
                    showIcon && (<InputAdornment position="end">
                        <DateRange />
                    </InputAdornment>)
                ),
                className: classes.textField,
            }}
            disabled={disabled}
        />

    )
}
