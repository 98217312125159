import { useMemo } from 'react'

import { useOrganizationSubscriptions } from './useOrganizationSubscriptions'
import { useUser } from './useUser'


const usePurchasedSubscriptions = () => {
    const { user } = useUser()
    const { subscriptions } = useOrganizationSubscriptions()
    
    const purchasedSubscriptions = useMemo(
        () => {

            if (!user) {
                return []
            }

            return subscriptions
                .filter(
                    subscription => user.purchasedSubscriptions.some(
                        purchasedSubscription => (
                            purchasedSubscription.monetizationId === subscription.id
                        ),
                    ),
                )

        },
        [ subscriptions, user ],
    )
    
    return {
        purchasedSubscriptions,
    }
}

export {
    usePurchasedSubscriptions,
}