import { Container, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../../static/enum'
import Organization from '../../../store/Organization'
import DeployScreenMenu from '../../deploy/DeployScreenMenu'
import DeployTarget from '../../deploy/DeployTarget'
import DialogAddRelease from '../../dialog/DialogAddRelease'


export const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
    },
    dividerHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    divider: {
        height: 1,
    },
    tileContainer: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 272,
        position: 'relative',
        borderRadius: theme.spacing(1),
    },
    badge: {
        padding: 4,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: 'inline-block',
        borderRadius: 4,
    },
    badgeSuccess: {
        backgroundColor: COLORS.SUCCESS,
        color: theme.palette.common.white,
    },
    badgeInfo: {
        backgroundColor: COLORS.PRIMARY,
        color: theme.palette.common.white,
    },
    badgeWarning: {
        backgroundColor: '#ffb400',
        color: theme.palette.common.black,
    },
}))

interface Props {
    organization: Organization
}

/**
 * Dashboard page component
 */
export const DeployPage: FC<Props> = observer((props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const deploy = props.organization.deploy
    const { targets, readyState } = deploy

    const [
        addReleaseDialog,
        setAddReleaseDialog,
    ] = useState<{ opened: boolean | null }>({
        opened: false,
    })

    const handleAddRelease = () => {
        setAddReleaseDialog({ opened: true })
    }

    const handleCloseAddReleaseDialog = () => {
        setAddReleaseDialog({ opened: false })
    }

    if (readyState === 'loading') {
        return (<div>Loading...</div>)
    }

    return (<>
        { addReleaseDialog.opened &&
          <DialogAddRelease
              hideActionsButtons={true}
              deploy={deploy}
              targets={targets}
              onConfirm={() => setAddReleaseDialog({ opened: false })}
              onCancel={() => handleCloseAddReleaseDialog()}
              open={addReleaseDialog.opened}
          />
        }

        <Container
            maxWidth="lg"
            className={classes.container}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs>
                    <Typography variant="h5">
                        {t('Deploy')}
                    </Typography>
                </Grid>
                <Grid item>
                    <DeployScreenMenu
                        onAddRelease={() => handleAddRelease()}
                    />
                </Grid>
            </Grid>
            <Grid item>
                {targets.map((target, i) => (
                    <DeployTarget
                        key={i}
                        target={target}
                        deploy={deploy}
                        hasTopDivider={!i}
                        environments={deploy.environments}
                        visibleEnvironmentsLabels={!i}
                    />
                ))}
            </Grid>
        </Container>
    </>)
})

export default DeployPage
