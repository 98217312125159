import { useEffect, useState } from 'react'

import Monetization from '../../../store/Monetization'

import { useOrganization } from './useOrganization'


export const useMonetization = (monetizationId: string) => {
    const { organization } = useOrganization()
    const [notFound, setNotFound] = useState(false)
    const [monetization, setMonetization] = useState<Monetization | null>(null)

    useEffect(
        () => {
            if (organization) {
                const findMonetization = organization.monetizations.find(
                    monetization => monetization.getId === monetizationId,
                )

                if (findMonetization) {
                    setMonetization(findMonetization)
                }
                else {
                    setNotFound(true)
                }
            }
        },
        [organization?.monetizations],
    )

    return {
        notFound,
        monetization,
    }
}
