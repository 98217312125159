import { IconButton, Typography } from '@material-ui/core'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import React, { PropsWithChildren, ReactElement } from 'react'
import styled from 'styled-components'

import { StyledHover, StylesTransition } from '../../styled/Container'
import { Row } from '../../uiLayout/Row'


interface ListingItem {
    // TODO TIV-692 QUESTION why not "id"?
    getId: string
    // TODO TIV-692 QUESTION why not "name"?
    getName: string
    extraColumns?: string[]
    extraProps?: {[key: string]: any}
    renderName?: (item: ListingItem) => ReactElement
}

interface ListingRowProps {
    onClick: (item: ListingItem) => void
    onDeleteClick?: (item: ListingItem) => void
    onUpdateClick?: (item: ListingItem) => void
}

interface Props extends ListingRowProps {
    item: ListingItem
}

const StyledTypography = styled(Typography)`
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const StyledIconsRowContainer = styled.div`
    opacity: 0;
    ${StylesTransition}
`

const StyledRowContainer = styled(StyledHover)`
    padding: 12px;
    margin: -12px;

    :hover {
        ${StyledIconsRowContainer} {
            opacity: 1;
        }
    }
`

/**
 * @deprecated Please don't use this component anymore. It's too restrictive and force you to use observer.
 * For styling (item hover effect), you can use useListItemStyles() hook from ../styles.ts.
 */
export const ListingRowName: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledTypography variant="body1">
        {children}
    </StyledTypography>
)

/**
 * @deprecated Please don't use this component anymore. It's too restrictive and force you to use observer.
 * For styling (item hover effect), you can use useListItemStyles() hook from ../styles.ts.
 */
const ListingRow: React.FC<Props> = (props) => {
    const { item } = props

    const handleIconClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, action: () => void) => {
        e.stopPropagation()
        action()
    }

    const renderNameColumn = (item: ListingItem) => {
        if (item.renderName) {
            return item.renderName(item)
        }

        return (
            <ListingRowName>
                {item.getName}
            </ListingRowName>
        )
    }

    const renderExtraColumns = (item: ListingItem) => {
        if (!item.extraColumns) {
            return []
        }

        return (
            <Row>
                {
                    item.extraColumns.map(
                        (extraColumn, index) => (
                            <StyledTypography
                                key={`extra-column-${index}`}
                                color="textSecondary"
                                variant="body1"
                            >
                                {extraColumn}
                            </StyledTypography>
                        ),
                    )
                }
            </Row>
        )
    }

    const renderDeleteIcon = () => {
        if (!props.onDeleteClick) {
            return null
        }

        return (
            <IconButton
                size="small"
                onClick={(e) => handleIconClick(e, () => props.onDeleteClick?.(item))}
            >
                <Delete fontSize="small"/>
            </IconButton>
        )
    }

    const renderUpdateIcon = () => {
        if (!props.onUpdateClick) {
            return null
        }

        return (
            <IconButton
                size="small"
                onClick={(e) => handleIconClick(e, () => props.onUpdateClick?.(item))}
            >
                <Create fontSize="small"/>
            </IconButton>
        )
    }

    return (
        <StyledRowContainer>
            <Row
                rowProps={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Row>
                    {renderNameColumn(item)}
                    {renderExtraColumns(item)}
                </Row>
                <StyledIconsRowContainer>
                    <Row spacing={2}>
                        {renderUpdateIcon()}
                        {renderDeleteIcon()}
                    </Row>
                </StyledIconsRowContainer>
            </Row>
        </StyledRowContainer>
    )
}

export {
    ListingRow,
}

export type {
    ListingItem,
    ListingRowProps,
}
