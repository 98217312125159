import { createLogger } from '@tivio/common'
import { ProcessDocument } from '@tivio/firebase'
import { useEffect, useState } from 'react'

import { useError } from '../uiHooks/useError'
import { getFirestore } from '../../../firebase/app'
import Video from '../../../store/Video'


const logger = createLogger('useProcesses')

const useEncodingProcesses = (video: Video) => {
    const [encodingProcesses, setEncodingProcesses] = useState<ProcessDocument[] | null>(null)
    const [encodingProcessesCompleted, setEncodingProcessesCompleted] = useState<boolean>(false)
    const { error, raiseError } = useError()

    const getProcessesQuery = async () => {
        const firestore = getFirestore()

        return firestore.collection('processes')
            .where('payload.contentRef', '==', video.ref)
    }

    const setProcessesState = async () => {
        let processesQuery = await getProcessesQuery()

        return processesQuery.onSnapshot(querySnapshot => {
            const processesDocuments = querySnapshot
                .docs
                .map(processDocument => processDocument.data() as ProcessDocument)
                .filter(processDocument => processDocument !== null)

                if (processesDocuments.length > 0) {
                    setEncodingProcesses(processesDocuments)
                }
        })
    }

    useEffect(() => {
            (async () => {
                try {
                    await setProcessesState()
                } catch (e) {
                    raiseError('failed-to-find-processes')
                    logger.error(e)
                }
            })()
        },
        [video.isTranscoding],
    )

    useEffect(() => {
        if (encodingProcesses !== null) {
            setEncodingProcessesCompleted(
                encodingProcesses.every(encodingProcess => encodingProcess.completed !== undefined)
            )
        }
    }, [encodingProcesses])

    return {
        error,
        encodingProcesses,
        encodingProcessesCompleted,
    }
}

export {
    useEncodingProcesses,
}
