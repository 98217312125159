import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
`

const ItemContainer = styled.div<{width: number}>`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: ${p => p.width}%;
`

const Arrow = styled.div`
    width: 10px;
    height: 10px;
    border-top: 3px solid rgba(255, 255, 255, 0.2);
    border-right: 3px solid rgba(255, 255, 255, 0.2);
`

const LeftArrow = styled(Arrow)`
    transform: rotate(-135deg);
`

const RightArrow = styled(Arrow)`
    transform: rotate(45deg);
`

const StyledItem = styled.div.attrs<{ focused?: boolean }>(({ focused }) => ({
    style: {
        opacity: (focused) ? 1 : 0.5,
        background: (focused) ? '#0078ff' : 'rgba(255, 255, 255, 0)',
    },
})) <{ focused?: boolean }>`
    padding: 12px 12px 8px 12px;
    border-radius: 8px;
    font-size: 20px;
    color: #fff;
    font-family: Helvetica, Arial, sans-serif;
    margin: 0 5px;
`

const Item: FC<{ text: string, focused?: boolean, width: number }> = ({ focused, text, width }) => {
    return (
        <ItemContainer width={width}>
            {focused && <LeftArrow />}
            <StyledItem focused={focused}>{ text }</StyledItem>
            {focused && <RightArrow />}
        </ItemContainer>
    )
}

export const CompleteChaptersBar = () => {
    return (
        <Container>
            <Item text={'Intro'} width={10} />
            <Item text="What's Bitcoin" width={25} />
            <Item text={'Satoshi'} focused={true} width={30} />
            <Item text={'Dogecoin'} width={15} />
            <Item text={'To the moon'} width={20} />
        </Container>
    )
}

export const IncompleteChaptersBar = () => {
    return (
        <Container>
            <Item text={'Intro'} width={10} />
            <Item text="What's Bitcoin" width={25} />
            <Item text={'Satoshi'} focused={true} width={30} />
            <Item text={'Dogecoin'} width={15} />
        </Container>
    )
}
