import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { goRootPage } from '../../../utils/history.utils'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'


/**
 * Default template for non existing page
 */
function NotFoundPage() {
    const [ t ] = useTranslation()

    return (
        <AppNotFoundTemplate
            title={t('Page not found')}
            button={{
                action: goRootPage,
                text: 'Main page',
            }}
        />
    )
}

export default NotFoundPage
