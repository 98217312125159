import { Card, CardContent, Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../static/enum'
import { Column } from '../uiLayout/Column'


const StyledCard = styled(Card) <{ $selectColor?: string, $isClickable: boolean }>`
    background-color:${COLORS.DRAWER_BACKGROUND};
    height: 100%;
    ${props => props.$selectColor && `border-bottom: solid ${props.$selectColor};`}
    ${props => props.$isClickable && 'cursor: pointer;'}
`

const StyledCardContent = styled(CardContent)`
    padding: 24px;
`

interface Props extends PropsWithChildren {
    title?: string
    selectColor?: string
    onClick?: () => void
}

export const AnalyticCard: React.FC<Props> = (props) => {
    const { title, selectColor, children, onClick } = props

    const isClickable = !!onClick

    return (
        <StyledCard
            $selectColor={selectColor}
            onClick={onClick}
            $isClickable={isClickable}
        >
            <StyledCardContent>
                <Column spacing={1}>
                    {title && (
                        <Typography variant="subtitle1">
                            {title}
                        </Typography>
                    )}
                    <>
                        {children}
                    </>
                </Column>
            </StyledCardContent>
        </StyledCard>
    )
}
