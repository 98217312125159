import { OrganizationMemberRoles } from '@tivio/firebase'
import { makeAutoObservable } from 'mobx'

import Admin from './Admin'

import type { TMemberFirestore } from '../firebase/firestore/member'
import type Organization from './Organization'
import type firebase from 'firebase/app'


class Member {
    private ref: firebase.firestore.DocumentReference<TMemberFirestore>
    private firestoreData: TMemberFirestore
    private organizations: Organization[] = []
    private currentOrganization: Organization
    private currentRole: OrganizationMemberRoles = OrganizationMemberRoles.GUEST
    private admin: Admin | null = null

    constructor(
        ref: firebase.firestore.DocumentReference<TMemberFirestore>,
        firestoreData: TMemberFirestore,
        organizations: Organization[],
        currentOrganization: Organization,
        currentRole: OrganizationMemberRoles,
    ) {
        this.firestoreData = firestoreData
        this.ref = ref
        this.organizations = organizations
        this.currentOrganization = currentOrganization
        this.currentRole = currentRole

        makeAutoObservable(this)
    }

    initAdmin = async () => {
        const admin = new Admin(this)
        await admin.listOrganizations(this)

        this.setAdmin = admin
    }

    get getOrganizations() {
        return this.organizations
    }

    get getEmail() {
        return this.firestoreData.email
    }

    get getAvatar() {
        return this.firestoreData.avatar
    }

    get getFName() {
        return this.firestoreData.fName
    }

    get getLName() {
        return this.firestoreData.lName
    }

    get getCurrentRole() {
        return this.currentRole
    }

    get getId() {
        return this.ref.id
    }

    get getPatreon() {
        return this.firestoreData.patreon
    }

    get getUid() {
        return this.firestoreData.uid
    }

    get getCurrentOrganization() {
        return this.currentOrganization
    }

    get getAdmin() {
        return this.admin
    }

    get isAdmin() {
        return Boolean(this.admin)
    }

    get getRef() {
        return this.ref
    }

    set setCurrentRole(role: OrganizationMemberRoles) {
        this.currentRole = role
    }

    set setOrganizations(organizations: Organization[]) {
        this.organizations = organizations
    }

    set setCurrentOrganization(organization: Organization) {
        this.currentOrganization = organization
    }

    set setAdmin(admin: Admin) {
        this.admin = admin
    }
}

export default Member
