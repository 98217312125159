import { Button, MenuItem } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { VideoDocument } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { createVideo } from '../../../creator/video.creator'
import { addVideo } from '../../../firebase/firestore/video'
import { Tag } from '../../../store/Tag'
import { goMultiUploadPage, goVideoPage } from '../../../utils/history.utils'
import { createVideoData } from '../../../utils/videoEditor.utils'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { useTags } from '../../hooks/dataHooks/useTags'
import { MenuButton } from '../../MenuButton'
import { TagsEditor } from '../../screen/TagsEditor'
import { VideosList } from '../../video/VideosList'



const VideosPage = observer(() => {
    const [t] = useTranslation()
    const [createdTag, setCreatedTag] = useState<Tag | null>(null)
    const { addTag } = useTags()
    const { organization } = useOrganization()

    const handleAddVideo = async () => {
        if (!organization) {
            return
        }

        const videoData: VideoDocument = await createVideoData(organization)
        const videoRef = await addVideo(videoData)
        const video = createVideo(videoRef, videoData, organization)
        organization.addVideo(video)
        goVideoPage(video.id)
    }

    const createTag = async () => {
        const tag = await addTag()
        if (!tag) {
            throw new Error('tag-not-found')
        }
        setCreatedTag(tag)
    }

    const closeTagDialog = () => {
        setCreatedTag(null)
    }

    const tabs = [
        {
            title: t('Videos'),
            component: <VideosList />,
            buttons: [
                <MenuButton
                    key="add-videos"
                    label={t('Add video(s)')}
                    size="small"
                    startIcon={<Add />}
                >
                    <MenuItem onClick={handleAddVideo}>{t('Add single video')}</MenuItem>
                    <MenuItem onClick={goMultiUploadPage}>{t('Upload multiple videos')}</MenuItem>
                </MenuButton>,
            ],
        },
        {
            title: t('External videos'),
            component: <VideosList isExternalVideos />,
        },
        {
            title: t('Tags'),
            component: <TagsEditor
                createdTag={createdTag}
                onClose={closeTagDialog}
            />,
            buttons: [
                <Button
                    key="add-tag-button"
                    startIcon={<Add />}
                    component="span"
                    size="small"
                    onClick={() => createTag()}
                >
                    {t('Add tag')}
                </Button>,
            ],
        },
    ]

    return (<DefaultPageTabs tabs={tabs} />)
})

export {
    VideosPage,
}
