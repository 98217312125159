import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'


const Container = styled.div`
    position: relative;
    overflow: hidden;
    color: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    border: 2px solid red;
    border-radius: 10px;
`

const Title = styled.div`
    font-weight: 600;
`

interface Props {
    className?: string
    message?: string
}

export const Error = (props: Props) => {
    const [ t ] = useTranslation()
    const { message, className } = props

    return (
        <Container title={message} className={className}>
            <Title>{t('Error')}</Title>
            <div>{message}</div>
        </Container>
    )
}
