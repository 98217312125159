import {
    Avatar,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useRef, useState } from 'react'

import store from '../../store'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'

import MenuOrganizationsPopup from './MenuOrganizationsPopup'


const useStyles = makeStyles({
    avatarAdmin: {
        border: '2px solid gold',
    },
})

/**
 * Menu user avatar component. Logout on click
 */
const MenuUser = observer(() => {
    const { getMember: member } = store
    const [ openOrganizationsContext, setOpenOrganizationsContext ] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)
    const classes = useStyles()

    const isSuperAdmin = useIsSuperAdmin()

    if (!member) {
        return null
    }

    return (
        <div data-e2e="menu-user">
            <List>
                <ListItem
                    onClick={() => setOpenOrganizationsContext(!openOrganizationsContext)}
                    button
                    innerRef={ref}
                >
                    <ListItemAvatar>
                        <Avatar
                            className={isSuperAdmin ? classes.avatarAdmin : undefined}
                            title={isSuperAdmin ? 'super admin' : undefined}
                        >
                            {member.getEmail.charAt(0)}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        secondary={member.getCurrentOrganization.name}
                        data-e2e="organization-name"
                    >
                        <Typography variant="body2">
                            {member.getEmail}
                        </Typography>
                    </ListItemText>
                </ListItem>
            </List>
            <MenuOrganizationsPopup
                anchorRef={ref}
                member={member}
                onClose={() => setOpenOrganizationsContext(false)}
                open={openOrganizationsContext}
                organization={member.getCurrentOrganization}
            />
        </div>
    )
})

//@ts-ignore
MenuUser.displayName = 'MenuUser'

export default MenuUser
