
import { createLogger } from '../../../logger'

import { useOrganization } from './useOrganization'

import type { MembershipDocument } from '@tivio/firebase'


const logger = createLogger('useMemberships')

export const useMemberships = () => {
    const { organization } = useOrganization()

    const addMembership = async (membershipData: Partial<MembershipDocument>) => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        return await organization.addMembership(organization, membershipData)
    }

    const removeMembership = async (membershipId: string) => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        await organization.removeMembership(organization, membershipId)
    }

    return {
        memberships: organization?.memberships ?? [],
        addMembership,
        removeMembership,
    }
}
