import DashboardIcon from '@material-ui/icons/Dashboard'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { goApplicationsPage, goScreensPage, goWidgetsPage } from '../../utils/history.utils'

import { MenuDropdown, MenuDropdownItem } from './MenuDropdown'


/**
 * Menu Application component
 */
const MenuApplication = observer(() => {
    const [ t ] = useTranslation()
    const menuItems: MenuDropdownItem[] = [
        {
            name: t('Applications'),
            action: goApplicationsPage,
            id: 'applications',
        },
        {
            name: t('Screens'),
            action: goScreensPage,
            id: 'screens',
        },
        {
            name: t('Widgets'),
            action: goWidgetsPage,
            id: 'widgets',
        },
    ]

    return (
        <MenuDropdown
            icon={<DashboardIcon />}
            name={t('Applications')}
            items={menuItems}
        />
    )
})

export default MenuApplication
