import { AssetPresetPlacement } from '@tivio/firebase'
import i18n from 'i18next'
import { useObserver } from 'mobx-react'
import { useCallback } from 'react'

import {
    createAssetPreset,
    createGlobalAssetPreset,
    removeAssetPreset,
    removeGlobalAssetPreset,
    updateAssetPreset,
    updateGlobalAssetPreset,
} from '../../../firebase/firestore/assetPresets'
import store from '../../../store'
import { AssetPreset } from '../../../store/AssetPreset'
import { useOrganization } from '../../hooks/dataHooks'
import { useIsSuperAdmin } from '../../hooks/permissions/permissions'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'


export interface AssetPresetFilter {
    placement?: AssetPresetPlacement
    /**
     * if ids is empty array, then filter also returns empty array
     */
    ids?: string[]
}

export const filterAssetPresets = (assetPresets: AssetPreset[], filter?: AssetPresetFilter) => {
    if (!filter) {
        return assetPresets
    }

    return assetPresets
        .filter(assetPreset =>
            (!filter.placement || assetPreset.placement == filter.placement)
            && (!filter.ids || filter.ids.includes(assetPreset.id)),
        )
}

export const useAssetPresets = (filter?: AssetPresetFilter) => {
    const all = useAllAssetPresets(filter)
    const own = useOwnAssetPresets(filter)
    const isSuperAdmin = useIsSuperAdmin()

    return isSuperAdmin ? all : own
}

export const useOwnAssetPresets = (filter?: AssetPresetFilter) => {
    const own = useObserver(() => {
        const organization = store.getMember?.getCurrentOrganization
        return organization?.assetPresets ?? []
    })

    return filterAssetPresets(own, filter)
}

export const useAllAssetPresets = (filter?: AssetPresetFilter) => {
    const own = useOwnAssetPresets()

    const global = useObserver(() => {
        return store.globalAssetPresets
    })

    const assetPresets = own.concat(global)

    return filterAssetPresets(assetPresets, filter)
}

const emptyCrud = {
    create: (preset: AssetPreset) => {},
    remove: (preset: AssetPreset) => {},
    update: (preset: AssetPreset) => {},
}

export const useCrud = (): typeof emptyCrud => {
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()

    if (!organization) {
        return emptyCrud
    }

    const create = useCallback((preset: AssetPreset) => {
        if (preset.isGlobal) {
            createGlobalAssetPreset(preset)
        } else if (organization) {
            createAssetPreset(organization, preset)
        }
    }, [organization])

    const remove = useCallback((preset: AssetPreset) => {
        confirmAction(
            () => {
                if (preset.isGlobal) {
                    removeGlobalAssetPreset(preset)
                } else {
                    removeAssetPreset(organization, preset)
                }
            },
            i18n.t('Do you really wish to remove preset "{{name}}"? Images'
            + ' already generated using this preset will not be removed.', { name: preset.name }),
        )
    }, [organization])

    const update = useCallback((preset: AssetPreset) => {
        if (preset.isGlobal) {
            updateGlobalAssetPreset(preset)
        } else if (organization) {
            updateAssetPreset(organization, preset)
        }
    }, [organization])

    return {
        create,
        remove,
        update,
    }
}
