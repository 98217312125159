import { Grid } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

import { notEmptyFilter } from '../../utils/array.utils'

import type { GridProps, GridSpacing } from '@material-ui/core'


interface RowProps extends PropsWithChildren {
    itemProp?: {
        index: number,
        props: GridProps
    }
    rowProps?: GridProps
    itemProps?: GridProps
    spacing?: GridSpacing
    onClick?: () => void
}

const Row: React.FC<RowProps> = (props) => {
    const { itemProp, rowProps, itemProps, spacing = 0, onClick, children } = props
    return (
        <Grid
            container
            direction="row"
            spacing={spacing}
            {...rowProps}
            onClick={() => onClick?.()}
        >
            {React.Children.map(
                React.Children.toArray(children).filter(notEmptyFilter),
                (children, index) => (
                    <Grid
                        key={`${index}-children`}
                        item
                        {...itemProps}
                        {...(itemProp?.index === index ? itemProp.props : {})}
                    >
                        {children}
                    </Grid>
                ),
            )}
        </Grid>
    )
}

export {
    Row,
}
