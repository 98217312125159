import { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Logger from '../../../logger'
import { getImageExtension, getImageResolution } from '../../../utils/image.utils'
import { resizeImage, uploadImage } from '../../asset/asset.utils'

import { useOrganization } from './useOrganization'

import type { AssetPreset } from '../../../store/AssetPreset'
import type { ProgressEvent } from '../../asset/assetImageInput'
import type { AssetUploaderItem } from '../../asset/assetUploader'


type Props = {
    preset: AssetPreset
    item: AssetUploaderItem
    onProgress?: (event: ProgressEvent) => unknown
}

const logger = new Logger('AssetImageInput')

// TODO resize will not be here eventually, think about broader name for this
export const useAssetResize = (props: Props) => {
    const organizationId = useOrganization().organization?.id
    const { item, onProgress, preset } = props
    const [t] = useTranslation()

    const resizeAsset = useCallback(async (file: File) => {
        const fileExt = await getImageExtension(file)
        const filePath = item.getTempFilePath(preset, fileExt)
        const documentPath = item.getDocumentPath()
        const keepOriginal = item.keepOriginal
        const quality = item.quality || 75

        if (!organizationId) {
            throw new Error('Organization is not loaded')
        }

        try {
            onProgress?.({ state: 'uploading' })

            const documentPathSplit = documentPath.split('/')
            const collectionName = documentPathSplit.at(-2)

            if (collectionName === 'videos' || collectionName === 'tags') {
                const documentId = documentPathSplit.at(-1)
                if (documentId) {
                    await uploadAssetToCfStorage(documentId, preset.name, file)
                } else {
                    logger.error(`Failed to parse documentId from path for uploadAssetToCfStorage call, documentPath: ${documentPath}`)
                }
            }

            await uploadImage(file, filePath)

            const assetSize = await getImageResolution(file) // original image size
            const scaling = preset.getPossibleScalesToResize(assetSize) // possible scales to resize to

            onProgress?.({ state: 'resizing', message: t('Generating {{count}} qualities', { count: scaling.length }) + '...' })

            const uris = await resizeImage(
                filePath,
                documentPath,
                preset,
                organizationId,
                scaling,
                preset.mode,
                fileExt,
                quality,
                keepOriginal,
            )

            onProgress?.({ state: 'success' })

            // Also save video as video.cover for backward compatibility
            // for apps that expect video.cover
            // TODO TIV-515 remove this code once backward compatibility is not needed anymore
            const cover = uris.find(uri => uri.scale === '@1')?.url

            if (cover) {
                item.updateItem?.(uris, preset.name)
            }
        } catch (err) {
            logger.error(`Failed to upload to storage or resize image ${filePath}. Error`, err)

            onProgress?.({ state: 'error', error: err.message ?? 'Unknown error' })
        }
    }, [preset, organizationId])

    return {
        resizeAsset,
    }
}

/**
 * Uploads asset to cloudflare storage.
 */
async function uploadAssetToCfStorage(
    documentId: string,
    assetPreset: string,
    file: File,
): Promise<void> {
    if (!process.env.REACT_APP_ASSETS_ENDPOINT) {
        logger.error(`${process.env.REACT_APP_ASSETS_ENDPOINT} env variable is not set, deleteAssetFromCfStorage will not be called`)
        return
    }

    if (!process.env.REACT_APP_ASSETS_API_KEY) {
        logger.error(`${process.env.REACT_APP_ASSETS_API_KEY} env variable is not set, deleteAssetFromCfStorage will not be called`)
        return
    }

    try {
        const deleteAssetUrl = `${process.env.REACT_APP_ASSETS_ENDPOINT}/${documentId}/${assetPreset}`
        const response = await fetch(deleteAssetUrl, {
            method: 'POST',
            headers: {
                'x-api-key': process.env.REACT_APP_ASSETS_API_KEY,
                // should be `image/${'jpeg' | 'png' | 'svg+xml' | 'webp'}`
                'Content-Type': file.type,
            },
            body: await file.arrayBuffer(),
        })

        if (!response.ok) {
            logger.error(
                `Got non ok response ${response.status} ${response.statusText} from ${response.url}, response body is:`,
                await response.text(),
            )
        } else {
            logger.info('Upload asset to cloudflare storage ran successfully')
        }
    } catch (error) {
        logger.error('Error while uploading asset to cloudflare storage', error)
    }
}
