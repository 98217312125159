import { useCallback, useRef, useState } from 'react'


type HandleMouseEvent = <T extends AppMouseEvent>(e: T) => void

export interface AppMouseEvent {
    clientX: number
    clientY: number
    stopPropagation: () => void
    preventDefault: () => void
}

const useMouseActions = () => {
    const [ mouseDownPos, setMouseDownPos ] = useState<AppMouseEvent>()
    const [ mouseCurrentPos, setMouseCurrentPos ] = useState<AppMouseEvent>()
    const [ isMouseDownState, setIsMouseDownState ] = useState(false)
    const isMouseDownRef = useRef(isMouseDownState)

    const handleMouseMove = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            e.preventDefault()

            setMouseCurrentPos(e)
        },
        [],
    )

    const isMouseDown = () => {
        return isMouseDownRef.current
    }

    const setIsMouseDown = (isMouseDown: boolean) => {
        isMouseDownRef.current = isMouseDown
        setIsMouseDownState(isMouseDownRef.current)
    }

    const startListenMousePos = () => {
        window.addEventListener(
            'mousemove',
            handleMouseMove,
        )
    }

    const stopListenMousePos = () => {
        window.removeEventListener(
            'mousemove',
            handleMouseMove,
        )
    }

    const handleMouseDown: HandleMouseEvent = (e) => {
        e.stopPropagation()
        e.preventDefault()

        setIsMouseDown(true)

        setMouseDownPos({
            clientX: e.clientX,
            clientY: e.clientY,
            preventDefault: e.preventDefault,
            stopPropagation: e.stopPropagation,
        })

        setTimeout(
            () => {
                if (isMouseDown()) {
                    startListenMousePos()
                }
            },
            100,
        )
    }

    const handleMouseUp: HandleMouseEvent = (e) => {
        e.stopPropagation()
        e.preventDefault()

        setIsMouseDown(false)
        stopListenMousePos()
    }

    const resetInterval = () => {
        setMouseCurrentPos(undefined)
    }

    return {
        handleMouseDown,
        handleMouseUp,
        mouseDownPos,
        mouseCurrentPos,
        resetInterval,
    }
}

export default useMouseActions
