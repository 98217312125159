import firebase from 'firebase/app'

import { getFirestore } from '../app'


type DocumentData = firebase.firestore.DocumentData
type DocumentReference<T = DocumentData> = firebase.firestore.DocumentReference<T>
type DocumentSnapshot<T = DocumentData> = firebase.firestore.DocumentSnapshot<T>

/**
 * Interface for batchGet function with two overloads.
 */
interface BatchGetFn {
    <T extends DocumentData>(
        /**
         * Array of document paths in Firestore.
         */
        documents: string[]
    ): Promise<DocumentSnapshot<T>[]>
    <T extends DocumentData>(
        /**
         * Array of document references.
         */
        documents: DocumentReference<T>[]
    ): Promise<DocumentSnapshot<T>[]>
}

/**
 * Checks if the given item is a DocumentReference.
 *
 * @param item Item to check
 * @returns True if the item is a DocumentReference
 */
const checkReference = (item: string | DocumentReference): item is DocumentReference => {
    return item instanceof firebase.firestore.DocumentReference
}

const documentLoader = (reference: DocumentReference): Promise<DocumentSnapshot> => {
    return reference.get()
}

/**
 * Returns a Promise that resolves to an array after all of the DocumentSnapshots are loaded.
 *
 * @param documents Array of document references or document paths.
 * @returns Document snapshots from Firestore.
 */
export const batchGet: BatchGetFn = async <T extends DocumentData>(documents: (string | DocumentReference<T>)[]) => {
    if (documents.length === 0) {
        return []
    }
    try {
        const refs: DocumentReference<T>[] = documents.map((document) => {
            if (checkReference(document)) {
                return document
            } else {
                return getFirestore().doc(document) as DocumentReference<T>
            }
        })

        const snapshots = await Promise.all(refs.map(documentLoader))
        return snapshots
    } catch (error) {
        throw new Error(error)
    }
}
