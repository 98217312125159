import { debounce, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { DocumentReference, OrganizationDocument } from '@tivio/firebase'
import { LangCode } from '@tivio/types'
import { flatten } from 'lodash'
import React, {
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getOrganizationVideoById, getVideosByName } from '../../../firebase/firestore/video'
import { createLogger } from '../../../logger'
import { getTranslation } from '../../../utils/translate.utils'
import { useOrganization } from '../../hooks'
import { useLoader } from '../../hooks/general/useLoader'

import { CreatePurchaseForm } from './types'

import type { FormikProps } from 'formik'


type Props = {
    formik: FormikProps<CreatePurchaseForm>
}

const DEBOUNCE_FILTER_MS = 150 as const
const logger = createLogger('createPurchaseForm')

export const VideoAutocomplete = memo((props: Props) => {
    const { formik } = props
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const [videoNameOrId, setVideoNameOrId] = useState('')

    const onSetVideoNameOrId = (event: React.ChangeEvent<HTMLInputElement>) => setVideoNameOrId(event.target.value)
    const debounceSetVideoName= useMemo(() => debounce(onSetVideoNameOrId, DEBOUNCE_FILTER_MS), [])
    const getVideos = useCallback( async (nameOrId: string, organizationRef?: DocumentReference<OrganizationDocument>) => {
        if (!organizationRef) {
            return Promise.resolve([])
        }

        const videoById = nameOrId ? await getOrganizationVideoById(nameOrId, organizationRef) : undefined
        const normalizedVideoById = videoById ? { name: getTranslation(videoById.data?.name), ref: videoById.ref } : undefined
        if (normalizedVideoById) {
            return [normalizedVideoById]
        }

        return flatten(await Promise.all([
            getVideosByName(organizationRef, nameOrId),
            getVideosByName(organizationRef, nameOrId, LangCode.CS),
        ]))
    }, [])

    const { result: videos, loading, error } = useLoader(getVideos, [videoNameOrId, organization?.ref])
    if (error) {
        logger.error(error)
    }

    useEffect(() => {
        return () => {
            debounceSetVideoName.clear()
        }
    }, [debounceSetVideoName])

    const fieldProps = formik.getFieldProps('video')

    return (
        <Autocomplete
            id="video-input"
            loading={loading}
            options={videos ?? []}
            getOptionLabel={v => `${v?.name} (${v?.ref?.id})`}
            onChange={(event, value) => {
                formik.setFieldValue('video', value)
            }}
            onInputChange={(event, value, reason) => {
                if (reason === 'clear' || reason === 'reset') {
                    setVideoNameOrId('')
                }
            }}
            value={fieldProps.value}
            onBlur={() => {
                formik.setFieldTouched('video')
            }}
            renderInput={(params) => <TextField
                {...params}
                label={t('Video')}
                variant="outlined"
                error={formik.touched.video && Boolean(formik.errors.video)}
                helperText={formik.touched.video && formik.errors.video}
                onChange={debounceSetVideoName}
            />}
        />
    )
})
