import styled from 'styled-components'


export const Badge = styled.span`
    background: #0190c8;
    color: white;
    text-transform: uppercase;
    padding: 4px 6px;
    border-radius: 4px;
    font-weight: 600;
`
