import { createLogger } from '@tivio/common'
import { UseChannelSource } from '@tivio/types'


const logger = createLogger('useChannelSource')

export const useChannelSource: UseChannelSource = (tvChannelId: string) => {
    return {
        source: `tvChannels/${tvChannelId}`,
        error: null,
    }
}
