import { OrganizationMemberRoles } from '@tivio/firebase'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Admin from '../../../store/Admin'
import { Header } from '../../common'

import type { TActions } from '../../common'


interface TProps {
    admin: Admin
}

export const OrganizationsHeader = (props: TProps) => {
    const [t] = useTranslation()
    const { admin } = props

    const addOrganization = useCallback(() => {
        admin.addOrganization({
            members: {},
            channels: [],
            name: `${t('New organization')} ${admin.getOrganizations.length + 1}`,
            tvChannels: [],
            widgets: [],
            memberships: [],
            monetizations: [],
            secrets: [],
            userManagement: {
                signInFunction: '',
                tenantId: '',
            },
            hostname: '',
            showDurationDays: true,
        })
    }, [admin])

    const actions: TActions | null = useMemo(() => {
        const canCreate = props.admin.getMember.getCurrentRole >= OrganizationMemberRoles.SUPER_ADMIN

        if (!canCreate) {
            return null
        }

        return {
            create: {
                title: t('Add organizations'),
                onCreate: addOrganization,
            },
        }
    }, [admin])

    return <Header
        title={t('Organizations')}
        actions={actions}
    />
}
