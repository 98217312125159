import {
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Switch,
    TextField,
    Typography,
} from '@material-ui/core'
import Menu from '@material-ui/icons/Menu'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'
import styled from 'styled-components'

import Channel from '../../../store/Channel'
import { alertError, alertSuccess } from '../../../utils/alert.utils'
import { notEmptyFilter } from '../../../utils/array.utils'
import { onDragEndUtil } from '../../../utils/order.utils'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { useVodChannelsPagination } from '../../hooks/dataHooks/useVodChannelsPagination'
import { useWidget } from '../../hooks/dataHooks/useWidget'
import useBottomListener from '../../hooks/uiHooks/useBottomListener'
import { useUpdatedEntity } from '../../hooks/uiHooks/useUpdatedEntity'
import MonetizationPageHeader from '../../monetization/MonetizationPageHeader'
import { MonetizationPageTitle } from '../../monetization/MonetizationPageTitle'
import { StyledHover, StyledMonetizationPageContainer } from '../../styled/Container'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { UserLayoutParams } from '../layout/UserLayout'


interface OrderedChannel {
    channel: Channel
    selected: boolean
}

const DividerStyled = styled(Divider)`
    margin: 2rem 0;
`

/**
 * Widget detail page component
 * Serves for displaying widget information
 */
const WidgetPage = observer(() => {
    const { widgetId } = useParams<UserLayoutParams>()
    const { widget, notFound } = useWidget(widgetId)
    const [ widgetShortId, setWidgetId ] = useState('')
    const [ widgetEnabled, setWidgetEnabled ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const { fetchMore, currentChannels, channels } = useVodChannelsPagination()
    const [ t ] = useTranslation()
    const [ orderedChannels, setOrderedChannels ] = useState<OrderedChannel[]>([])
    const channelsRef = useRef(orderedChannels)
    const { isUpdated, updateProxy, resetIsUpdated } = useUpdatedEntity()

    useBottomListener({ onCloseToBottom: fetchMore })

    const isInWidget = (widgetChannels: Channel[], channel: Channel) => {
        return widgetChannels.some(
            someChannel => someChannel.getId === channel.getId,
        )
    }

    /**
     * Combine channels from the widget and the channels from the VOD channels pagination
     * into a single list so we can select and order them
     */
    useEffect(
        () => {
            if (widget) {
                const widgetChannels = widget.getChannels.map(
                    widgetChannel => ({
                        channel: widgetChannel,
                        selected: true,
                    }),
                )

                const otherChannels = channels
                    .map(
                        channel => {
                            if (!isInWidget(widget.getChannels, channel)) {
                                return {
                                    channel,
                                    selected: false,
                                }
                            }
                        },
                    )
                    .filter(notEmptyFilter)

                setWidgetId(widget.getWidgetId)
                setWidgetEnabled(widget.getEnabled)
                setOrderedChannels([
                    ...widgetChannels,
                    ...otherChannels,
                ])
            }
        },
        [ widget ],
    )

    /**
     * Merge more channels with current channels
     */
    useEffect(
        () => {
            const channels = [
                ...orderedChannels,
                ...currentChannels
                    .map(
                        channel => {
                            if (widget && isInWidget(widget.getChannels, channel)) {
                                return null
                            }

                            return {
                                channel,
                                selected: false,
                            }
                        },
                    )
                    .filter(notEmptyFilter),
            ]

            setOrderedChannels(channels)
            channelsRef.current = channels
        },
        [ currentChannels ],
    )

    const handleWidgetUpdate = async () => {
        if (!widget) {
            return
        }

        setLoading(true)

        try {
            await widget.updateId(widgetShortId)
            await widget.updateEnabled(widgetEnabled)
            await widget.updateChannelsOrder(
                orderedChannels
                    .filter(selectedChannel => selectedChannel.selected)
                    .map(selectedChannel => selectedChannel.channel),
            )

            resetIsUpdated()
            alertSuccess(t('Widget updated'))
        }
        catch (e) {
            console.log(e)
            alertError(t('Failed to update widget'))
        }
        finally {
            setLoading(false)
        }
    }

    const onDragEnd = async (result: DropResult) => {
        const channels = onDragEndUtil(result, orderedChannels)

        if (channels) {
            setOrderedChannels(channels)
        }
    }

    const handleChannelRowSwitch = (channel: OrderedChannel) => {
        const updated = orderedChannels.map(
            orderedChannel => {
                if (orderedChannel.channel.getId === channel.channel.getId) {
                    return {
                        ...orderedChannel,
                        selected: !orderedChannel.selected,
                    }
                }
                else {
                    return orderedChannel
                }
            },
        )

        setOrderedChannels(updated)
    }

    const renderChannelRow = (orderedChannel: OrderedChannel, index: number) => {
        return (
            <Draggable
                draggableId={orderedChannel.channel.getId}
                key={orderedChannel.channel.getId}
                index={index}
            >
                {provided => (
                    <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                    >
                        <StyledHover
                            onClick={() => updateProxy(() => handleChannelRowSwitch(orderedChannel))}
                        >
                            <Row
                                rowProps={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Row
                                    rowProps={{
                                        alignItems: 'center',
                                    }}
                                >
                                    <IconButton>
                                        <Menu/>
                                    </IconButton>
                                    <Typography>
                                        {orderedChannel.channel.getName}
                                    </Typography>
                                </Row>
                                <Switch
                                    checked={orderedChannel.selected}
                                    onChange={() => updateProxy(() => handleChannelRowSwitch(orderedChannel))}
                                    color="primary"
                                />
                            </Row>
                        </StyledHover>
                    </div>
                )}
            </Draggable>
        )
    }

    const renderChannels = useMemo(
        () => {
            return (
                <DragDropContext onDragEnd={result => updateProxy(() => onDragEnd(result))}>
                    <Droppable droppableId="sections">
                        {provided => (
                            <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    orderedChannels.map(
                                        (orderedChannel, index) => (
                                            renderChannelRow(orderedChannel, index)
                                        ),
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            )
        },
        [ orderedChannels ],
    )

    if (notFound) {
        return <AppNotFoundTemplate title={t('Monetization not found')}/>
    }

    if (!widget) {
        return null
    }

    return (
        <>
            <MonetizationPageHeader
                onSave={handleWidgetUpdate}
                disabled={!isUpdated && !loading}
            />
            <StyledMonetizationPageContainer>
                <MonetizationPageTitle onUpdateName={(text) => widget?.updateName(text)}>
                    {widget.getName}
                </MonetizationPageTitle>
                <Column spacing={2}>
                    <FormControlLabel
                        label={t('Enabled')}
                        control={
                            <Checkbox
                                checked={widgetEnabled}
                                onChange={() => updateProxy(() => setWidgetEnabled(!widgetEnabled))}
                                name="enabled"
                                color="primary"
                            />
                        }
                    />
                    <Row
                        spacing={2}
                        rowProps={{
                            alignItems: 'center',
                        }}
                    >
                        <Typography>
                            {t('ID')}
                        </Typography>
                        <TextField
                            variant="outlined"
                            size="small"
                            value={widgetShortId}
                            onChange={event => updateProxy(() => setWidgetId(event.target.value))}
                        >
                        </TextField>
                    </Row>
                </Column>

                <DividerStyled/>

                <Typography
                    variant="h6"
                    gutterBottom
                >
                    {t('VOD Channels')}
                </Typography>

                {renderChannels}
            </StyledMonetizationPageContainer>
        </>
    )
})

export default WidgetPage
