import { Container, makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { DefaultPageContainer } from '../../DefaultPageContainer'


const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    title: {
        flex: 'none',
    },
    content: {
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    iframe: {
        width: '100%',
        height: '100%',
        border: 'none',
    },
})

export const RoadmapPage = () => {
    const [t] = useTranslation()
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <DefaultPageContainer
                title={t('Roadmap')}
                className={classes.title}
            />
            <Container
                maxWidth="lg"
                className={classes.content}
            >
                <iframe
                    src="https://portal.productboard.com/sxwknf8cbb6ulsf9s3ugzkyw"
                    className={classes.iframe}
                    height="100vh"
                />
            </Container>
        </div>
    )
}
