import { calendarDateDiff, isInThePast } from '@tivio/common'
import dayjs, { type Dayjs, OpUnitType } from 'dayjs'
import { action, makeAutoObservable } from 'mobx'

import Logger from '../logger'

import Video from './Video'

import type Organization from './Organization'
import type { DocumentReference, TvProgramDocument } from '@tivio/firebase'


const logger = new Logger('TvProgram')

export const TV_PROGRAM_DATE_FORMAT = 'DD.MM.YYYY'

type GetDurationOptions = {
    /**
     * If true, then smaller units than specified unit is ignored during diff computation
     * (e.g. if unit = 'm', then from's and to's seconds are trimmed before computation ('start of unit' is used instead)
     */
    ignoreSmallerUnits: boolean
}

const DEFAULT_GET_DURATION_OPTIONS = {
    ignoreSmallerUnits: false,
}

export class TvProgram {
    video: Video | null = null

    constructor(
        private _ref: DocumentReference<TvProgramDocument>,
        private _data: TvProgramDocument,
        public organization: Organization,
    ) {
        makeAutoObservable(this, {
            loadVideo: action,
        })
    }

    get ref() {
        return this._ref
    }

    get id() {
        return this._ref.id
    }

    get from(): Dayjs {
        return dayjs(this._data.from.toDate())
    }

    get to(): Dayjs {
        return dayjs(this._data.to.toDate())
    }

    get fromDateString(): string {
        return this.from.format(TV_PROGRAM_DATE_FORMAT)
    }

    get toDateString(): string {
        return this.to.format(TV_PROGRAM_DATE_FORMAT)
    }

    get tvChannelRef() {
        return this._data.tvChannelRef
    }

    get name() {
        return this.video?.getName
    }

    get shortName() {
        return this.video?.getShortName
    }

    get image() {
        return this.video?.cover
    }

    getDuration(unit: OpUnitType, options: GetDurationOptions = DEFAULT_GET_DURATION_OPTIONS): number {
        const to = options.ignoreSmallerUnits ? this.to.startOf(unit) : this.to
        const from = options.ignoreSmallerUnits ? this.from.startOf(unit) : this.from

        return to.diff(from, unit)
    }

    /**
     * If program starts one day and ends the next day after midnight.
     * We expect programs over only ONE midnight, not more.
     */
    get isOverMidnight(): boolean {
        return calendarDateDiff(this.to, this.from) == 1
    }

    /**
     * Returns duration of program at specified date (used for programs over midnight).
     * If program is not over midnight, then returns full program duration.
     * If date not match from nor to, then returns 0.
     *
     * Example: program (01.01.2023 23:00:00 - 02.01.2023 02:00:00) will return 1h for 01.01.2023 and 2h for 02.01.2023.
     *
     * @param date date
     * @param unit time unit of duration value
     * @param options additional options
     */
    getDurationAtDate(date: Dayjs, unit: OpUnitType, options: GetDurationOptions = DEFAULT_GET_DURATION_OPTIONS): number {
        if (!this.isOverMidnight) {
            return this.getDuration(unit, options)
        } else if (this.overflownFrom(date)) {
            const from = options.ignoreSmallerUnits ? this.from.startOf(unit) : this.from
            return date.endOf('day').diff(from, unit)
        } else if (this.overflownTo(date)) {
            const to = options.ignoreSmallerUnits ? this.to.startOf(unit) : this.to
            return to.diff(date.startOf('day'), unit)
        } else {
            return 0
        }
    }

    /**
     * If program is "overflown" from given date to next (towards given date) day.
     * So it starts at date and ends at date+1 day.
     *
     * @param date date
     */
    overflownFrom(date: Dayjs): boolean {
        return date.isSame(this.from, 'day') && this.isOverMidnight
    }

    /**
     * If program is "overflown" from previous (towards given date) day to given date.
     * So it starts at date-1 day and ends at date.
     *
     * @param date date
     */
    overflownTo(date: Dayjs): boolean {
        return this.isOverMidnight && date.isSame(this.to, 'day')
    }

    /**
     * Whether this program has ended
     */
    get isInThePast() {
        return isInThePast(this.to)
    }

    /**
     * Loads video document related to this TvProgram
     */
    loadVideo = async () => {
        const videoDoc = await this._data.videoRef.get()
        if (!videoDoc.exists) {
            logger.error(`Video with id ${this._data.videoRef.id} does not exist (it's related to TV program ${this.id}`)
            return
        }

        this.video = new Video(videoDoc.ref, videoDoc.data()!, this.organization)
    }
}
