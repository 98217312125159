import { useEffect, useState } from 'react'

import { createLogger } from '../../../logger'
import Channel from '../../../store/Channel'

import { useOrganizationVodChannelsPagination } from './useOrganizationVodChannelsPagination'


const logger = createLogger('useVodChannel')

const useOrganizationVodChannel = (channelId?: string) => {
    const [ notFound, setNotFound ] = useState(false)
    const [ channel, setChannel ] = useState<Channel | null>(null)
    const { channels, getChannel, channelExistsInOrganization } = useOrganizationVodChannelsPagination()

    useEffect(
        () => {
            (async () => {
                if (!channelId) {
                    return
                }

                if (!channelExistsInOrganization(channelId)) {
                    logger.info('channel not found')

                    setNotFound(true)
                }
                else {
                    const findChannel = channels.find(channel => channel.getId === channelId)

                    if (findChannel) {
                        logger.info('channel in cache')

                        await findChannel.initChannel()
                        setChannel(findChannel)
                    }
                    else {
                        logger.info('channel not in cache ... going to fetch it')
                        const channel = await getChannel(channelId)

                        if (channel) {
                            await channel.initChannel()
                            setChannel(channel)
                        }
                    }
                }
            })()
        },
        [ channelId ],
    )

    return {
        notFound,
        channel,
    }
}

export {
    useOrganizationVodChannel,
}
