import {
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select as MuiSelect,
} from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select/Select'
import i18n from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'


// if both options and groupedOptions (not empty) are used, then options are ignored
type Props = {
    options?: AppSelectOption[]
    groupedOptions?: AppSelectOptionGroup[]
    value?: string
    label: string
    size?: 'small' | 'medium'
} & SelectProps


interface AppSelectOptionGroup {
    groupName: string
    options: AppSelectOption[]
}

export interface AppSelectOption {
    value: string
    optionLabel: string
    disabled?: boolean
}

const appSelectConverter = (options: string[]) => {
    return options.map(option => ({
        value: option,
        optionLabel: i18n.t(option),
    }))
}

const AppSelect: React.FC<Props> = (props) => {
    const [t] = useTranslation()
    const {
        options,
        groupedOptions,
        value,
        label,
        size = 'small',
        ...selectProps
    } = props

    const renderOptions = (opts: AppSelectOption[] | undefined) => opts?.map(
        (option) => (
            <MenuItem
                key={option.value}
                value={option.value}
                disabled={option.disabled}
            >
                {t(option.optionLabel)}
            </MenuItem>
        ),
    )

    return (
        <FormControl
            fullWidth
            size={size}
            variant="outlined"
        >
            <InputLabel>{label}</InputLabel>
            <MuiSelect
                label={label}
                value={value}
                placeholder={label}
                {...selectProps}
            >
                {groupedOptions && groupedOptions?.length > 0
                    ? groupedOptions.map(
                        (group: AppSelectOptionGroup) => (
                            // array is used here, because MuiSelect doesn't work with React fragment as child
                            [
                                <ListSubheader>{group.groupName}</ListSubheader>,
                                renderOptions(group.options),
                            ]
                        ))
                    : renderOptions(options)
                }
            </MuiSelect>
        </FormControl>
    )
}

export {
    AppSelect,
    appSelectConverter,
}
