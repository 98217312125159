const setCurrentOrganizationId = (organizationId: string) => {
    window.localStorage.setItem('organizationId', organizationId)
}

const getCurrentOrganizationId = () => {
    return window.localStorage.getItem('organizationId')
}

const removeCurrentOrganizationId = () => {
    window.localStorage.removeItem('organizationId')
}

export {
    setCurrentOrganizationId,
    removeCurrentOrganizationId,
    getCurrentOrganizationId,
}
