import {
    Box,
    CircularProgress,
    Container,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ReportIcon from '@material-ui/icons/Report'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import { VideoSourceField } from '@tivio/types'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getVideoByAllowedRebalancingVideoRef } from '../../../firebase/firestore/video'
import { getVideoAnalytics, getVideoAnalyticsViews } from '../../../firebase/firestore/videoAnalytics'
import Video from '../../../store/Video'
import { join } from '../../../utils/classname.util'
import { EcommerceWorldMap } from '../../externalComponents/EcommerceWorldMap'
import { useOrganization } from '../../hooks'
import { useLoader } from '../../hooks/general/useLoader'
import { useAlert } from '../../hooks/uiHooks/useAlert'

import { Badge } from './Badge'
import { SourceSelect } from './SourceSelect'
import { FilterState } from './types'
import { UserAutocomplete } from './UserAutocomplete'
import {
    ANALYTICS_POLLING_INTERVAL,
    getPercentageBadgeVariant,
    getPercentageToString,
    getViewersPercentage,
    reduceToGroupedSources,
} from './util'
import { VideoAnalyticsChart } from './VideoAnalyticsChart'
import { VideoAnalyticsErrors } from './VideoAnalyticsErrors'


const useStyles = makeStyles((theme) => ({
    boxContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontSize: '1.25rem',
    },
    filterContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    filter: {
        marginLeft: theme.spacing(2),
    },
    userFilter: {
        minWidth: '16rem',
    },
    tile: {
        backgroundColor: '#0d152f',
        borderRadius: '8px',
        padding: '2.875rem 2.125rem',
        height: '100%',
    },
    tileTitle: {
        marginRight: '0.5rem',
        fontSize: '1.125rem',
    },
    tileValue: {
        fontSize: '2.125rem',
    },
    tileBody: {
        marginTop: theme.spacing(1),
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },
    localities: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    map: {
        display: 'flex',
        maxHeight: '8rem',
    },
    spinner: {
        marginLeft: '0.5rem',
    },
    badge: {
        marginRight: '0.5rem',
    },
    chart: {
        paddingBottom: theme.spacing(1),
    },
    error: {
        display: 'flex',
    },
    badgeBody: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 0.1rem',
        margin: '0 0.25rem',
    },
    badgeIcon: {
        fontSize: '1rem',
    },
    errorCount: {
        marginLeft: '0.25rem',
        fontSize: '1rem',
    },
}))

interface Props {
    video: Video
}

export const VideoAnalyticsDashboard = ({ video }: Props) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const classes = useStyles()
    const [filter, setFilter] = useState<FilterState>({ user: null })
    const { showError } = useAlert()
    const {
        loading: viewsLoading,
        result: viewsResult,
        error: viewsError,
        refresh: refreshViews,
    } = useLoader(getVideoAnalyticsViews, [video.id, filter.source?.url])
    const {
        loading: analyticsLoading,
        result: analyticsResult,
        error: analyticsError,
        refresh: refreshAnalytics,
    } = useLoader(getVideoAnalytics, [video.id, filter.source?.url, filter.user?.id])
    const {
        error: allowedRebalancingVideoError,
        result: allowedRebalancingVideoResult,
    } = useLoader(getVideoByAllowedRebalancingVideoRef, [organization!.ref, video?.ref])

    if (viewsError || analyticsError || allowedRebalancingVideoError) {
        showError(`Error loading analytics: ${(viewsError || analyticsError || allowedRebalancingVideoError)?.message}`)
    }

    useEffect(() => {
        const setTimeoutId = setInterval(() => {
            refreshViews()
            refreshAnalytics()
        }, ANALYTICS_POLLING_INTERVAL)

        return () => clearInterval(setTimeoutId)
    }, [filter.user, filter.source, refreshViews, refreshAnalytics])

    const onSetSource = useCallback((source?: VideoSourceField) => setFilter(state => ({ ...state, source })), [setFilter])

    const hasErrors = analyticsResult?.error?.errors?.length ?? 0
    const currentViews = viewsResult?.currentViews
    const previousViews = viewsResult?.previousViews
    const viewersChangePercentage = getViewersPercentage(previousViews, currentViews)
    const groupedSources = {
        [video.getName]: video.sources,
        ...(allowedRebalancingVideoResult?.reduce(reduceToGroupedSources, {}) ?? {}),
    } as Record<string, VideoSourceField[]>

    return (
        <>
            <Container maxWidth="lg">
                <Box className={classes.boxContainer}>
                    <Box display="flex">
                        <Typography
                            className={classes.title}
                        >
                            {t('LIVE Statistics')}
                            { viewsLoading || analyticsLoading ? <CircularProgress
                                size="1.2rem"
                                className={classes.spinner}
                            /> : null}
                        </Typography>
                    </Box>
                    <Box className={classes.filterContainer}>
                        <Box className={classes.filter}>
                            <SourceSelect
                                selectedSource={filter.source}
                                groupedSources={groupedSources}
                                onChange={onSetSource}
                                variant="standard"
                                withEmptyValue
                                disableUnderline
                            />
                        </Box>
                        <Box className={join(classes.filter, classes.userFilter)}>
                            <UserAutocomplete
                                filter={filter}
                                setFilter={setFilter}
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider className={classes.divider} />
                <Grid
                    container
                    spacing={3}
                >
                    <Grid
                        item
                        xs={6}
                        md={3}
                    >
                        <Box className={classes.tile}>
                            <Typography className={classes.tileTitle}>{t('Current viewers')}</Typography>
                            <Typography className={classes.tileValue}>{viewsResult?.currentViews ?? '-'}</Typography>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >
                                {
                                    previousViews !== null
                                    && previousViews !== undefined
                                    && currentViews !== null
                                    && currentViews !== undefined
                                        ? <span
                                            className={classes.badge}
                                        >
                                            <Badge
                                                variant={getPercentageBadgeVariant(viewersChangePercentage)}
                                            >
                                                <span className={classes.badgeBody}>
                                                    {getPercentageToString(viewersChangePercentage)}
                                                </span>
                                            </Badge>
                                        </span>
                                        : null
                                }
                                {
                                    viewsResult?.currentViews === null || viewsResult?.currentViews === undefined
                                        ? t('No data yet')
                                        : t('Since last 10 minutes')
                                }
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        md={3}
                    >
                        <Box className={classes.tile}>
                            <Typography className={classes.tileTitle}>{t('Total viewers')}</Typography>
                            <Typography className={classes.tileValue}>{viewsResult?.totalViews ?? '-'}</Typography>
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                            >{
                                    viewsResult?.totalViews === null || viewsResult?.totalViews === undefined
                                        ? t('No data yet')
                                        : null
                                }</Typography>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                    >
                        <Box
                            className={join(classes.tile, classes.localities)}
                        >
                            <Box>
                                <Typography className={classes.tileTitle}>{t('Localities')}</Typography>
                                <Typography className={classes.tileValue}>-</Typography>
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                >{t('No data yet')}</Typography>
                            </Box>
                            <Box className={classes.map}>
                                <EcommerceWorldMap markerColor="#0d152f" />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Box
                            className={join(classes.tile, classes.chart)}
                        >
                            <Typography className={classes.tileTitle}>{t('Quality')}</Typography>
                            <VideoAnalyticsChart metrics={analyticsResult?.quality} />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={6}
                    >
                        <Box
                            className={join(classes.tile, classes.chart)}
                        >
                            <Typography className={classes.tileTitle}>{t('State')}</Typography>
                            <VideoAnalyticsChart metrics={analyticsResult?.state} />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <Box className={classes.tile}>
                            <Box className={classes.error}>
                                <Typography className={classes.tileTitle}>
                                    {t('Current errors')}
                                </Typography>
                                <Badge variant={hasErrors ? 'negative' : 'positive'}>
                                    <Box className={classes.badgeBody}>
                                        { hasErrors
                                            ? <ReportIcon fontSize="small" />
                                            : <ThumbUpIcon
                                                className={classes.badgeIcon}
                                                fontSize="small"
                                            />
                                        }
                                        <span className={classes.errorCount}>{analyticsResult?.error?.totalCount ?? 0}</span>
                                    </Box>
                                </Badge>
                            </Box>
                            <Box className={classes.tileBody}>
                                <VideoAnalyticsErrors errors={analyticsResult?.error?.errors} />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
