import {
    Box,
    Button,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { useT } from '@tivio/common'
import { LangCode } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Video from '../../store/Video'
import { mapLangCodeToLanguage } from '../../utils/translate.utils'
import { AppThemeProvider } from '../AppThemeProvider'
import { useFileInput } from '../hooks/dataHooks/useFileInput'
import { useAlert } from '../hooks/uiHooks/useAlert'


const StyledDialogContent = styled(DialogContent)`
    position: relative;
`

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const LanguageFormControl = styled(FormControl)`
    min-width: 260px;
`

const UploadButton = styled(Button)`
    min-width: 260px;
    margin-top: 16px;
`

interface Props {
    opened: boolean
    video: Video
    onClose?: () => void
    onFileSelect: (file: File, language: LangCode) => Promise<void>
    initialLanguage?: LangCode
}

export const VideoSubtitlesDialog: React.FC<Props> = observer((props) => {
    const t = useT()
    const [language, setLanguage] = useState<LangCode | ''>('')
    const [isUploading, setIsUploading] = useState(false)
    const { showSuccess } = useAlert()

    const { selectFile } = useFileInput({
        onFiles: async (files) => {
            if (!files || files.length === 0 || !language) {
                return
            }

            setIsUploading(true)
            await props.onFileSelect(files[0], language)
            showSuccess('Subtitles uploaded.')
            setLanguage('')
            setIsUploading(false)
        },
        multiple: false,
        accept: '.srt',
        type: 'file',
        errorMessage: t('Failed to load subtitles.'),
    })

    useEffect(() => {
        if (props.initialLanguage) {
            setLanguage(props.initialLanguage)
        } else {
            setLanguage('')
        }
    }, [props.initialLanguage])

    const getButtonText = () => {
        if (!language) {
            return t('Please select language')
        } else if (isUploading) {
            return `${t('Uploading')} ...`
        } else {
            return t('Upload subtitles')
        }
    }

    return (
        <AppThemeProvider type="light">
            <Dialog open={props.opened}>
                <StyledDialogContent>
                    <CloseIcon onClick={props.onClose}>
                        <Close/>
                    </CloseIcon>
                    <Box
                        pt={6}
                        pb={4}
                        pl={6}
                        pr={6}
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                    >
                        <LanguageFormControl>
                            <InputLabel id="language-select-label">{t('Language')}</InputLabel>
                            <Select
                                labelId="language-select-label"
                                value={language}
                                onChange={(e) => setLanguage(e.target.value as LangCode)}
                            >
                                {Object.values(LangCode).map(langCode => (
                                    <MenuItem
                                        key={langCode}
                                        value={langCode}
                                    >
                                        {mapLangCodeToLanguage(langCode)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </LanguageFormControl>

                        <UploadButton
                            variant="outlined"
                            color="primary"
                            onClick={selectFile}
                            disabled={!language || isUploading}
                        >
                            {getButtonText()}
                        </UploadButton>
                    </Box>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})
