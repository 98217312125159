import { FormControl, InputLabel, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { getInputProps } from '../../utils/formik.utils'
import MultiTextField, { MultiTextFieldItem, multiTextFieldItemToTranslation, translationToMultiTextFieldItem } from '../common/formField/MultiTextField'
import { Column } from '../uiLayout/Column'


interface Props {
    form: any
    name: string
    label?: string
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    label: {
        marginBottom: theme.spacing(1.25),
        fontWeight: 'bold',
    },
}))

const TranslationForm: React.FC<Props> = ({ form, name, label, disabled = false }) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleChange = (newTranslation: MultiTextFieldItem[]) => {
        form.setFieldValue(name, multiTextFieldItemToTranslation(newTranslation))
    }

    return (
        <FormControl fullWidth>
            {label && <label
                className={classes.label}
                htmlFor={name}
            >{ label }</label>}
            <MultiTextField
                items={translationToMultiTextFieldItem(form.values[name])}
                defaultIndex={2}
                onChange={handleChange}
                data-e2e-input={name}
                fullWidth
                disabled={disabled}
            />
        </FormControl>
    )
}

export {
    TranslationForm,
}
