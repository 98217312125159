import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { createTranslation } from '../../../creator/translation.creator'
import { firebaseTimestampFromDate } from '../../../firebase/app'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useScreens } from '../../hooks/dataHooks/useScreens'
import { Repeater } from '../../Repeater'
import { ScreensList } from '../../screen/ScreensList'
import { BigButton } from '../../styled'


const ScreenZeroState = styled(Skeleton)`
    margin-top: 16px;
    height: 30px;
`

const SCREEN_ZERO_STATES_COUNT = 6


const ScreensPage = observer(() => {
    const [t] = useTranslation()
    const { screens, loading, addScreen } = useScreens()

    const handleCreateScreen = () => {
        const screensCount = screens.length + 1

        addScreen({
            created: firebaseTimestampFromDate(),
            name: createTranslation(`${t('New screen')} ${screensCount}`),
            description: createTranslation(t('Screen description')),
            assets: {},
        })
    }

    return (
        <>
            <DefaultPageContainer
                title={t('Screens')}
                buttons={loading ? [] : [
                    <Button
                        key="button-add-ad"
                        startIcon={<Add/>}
                        component="span"
                        size="small"
                        onClick={handleCreateScreen}
                    >
                        {t('Add screen')}
                    </Button>,
                ]}
            >
                {loading ? (
                    <Repeater count={SCREEN_ZERO_STATES_COUNT}>
                        <ScreenZeroState animation="wave"/>
                    </Repeater>
                ) : (
                    <>
                        <ScreensList screens={screens}/>
                        <BigButton
                            fullWidth
                            variant="outlined"
                            onClick={handleCreateScreen}
                        >
                            {t('Add new screen')}
                        </BigButton>
                    </>

                )}
            </DefaultPageContainer>
        </>
    )
})

export {
    ScreensPage,
}
