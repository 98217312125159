import { useEffect, useState } from 'react'

import { getConversions } from '../../../firebase/firestore/conversions'
import { ConversionDataFromCloudFunction, ConversionFlowData, Statistic, Target } from '../../analytics/conversions/types'

import { useOrganization } from './useOrganization'


interface Props {
    startDate: string
    endDate: string
}

interface UseConversions {
    conversionsData: ConversionFlowData | undefined
    isLoading: boolean
}

export const useConversions = ({ startDate, endDate }: Props): UseConversions => {
    const [conversionsData, setConversionsData] = useState<ConversionFlowData>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { organization } = useOrganization()

    const getInitialConversions = async () => {
        if (!organization?.id) {
            return
        }

        try {
            setIsLoading(true)
            const { id: organizationId } = organization
            const conversionAnalytics = await getConversions({ organizationId, startDate, endDate })
            const aggregated = aggregateDataByTypeAndTechnologyAndOs(conversionAnalytics)

            setConversionsData(aggregated)
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getInitialConversions()
    }, [startDate, endDate])

    return {
        conversionsData,
        isLoading,
    }
}

function mergeTargets(existingTargets: Target[], newTargets: Target[]): Target[] {
    for (const newTarget of newTargets) {
        const existingTarget = existingTargets.find(target =>
            target.targetId === newTarget.targetId &&
            target.path === newTarget.path &&
            target.pathParam === newTarget.pathParam)
        if (existingTarget) {
            existingTarget.successCount += newTarget.successCount
            existingTarget.totalCount += newTarget.totalCount
        } else {
            existingTargets.push(newTarget)
        }
    }
    return existingTargets
}

function mergeStatistics(statistics: Statistic[], newStatistics: Statistic[]): Statistic[] {
    for (const newStat of newStatistics) {
        const existingStat = statistics.find(stat => stat.technology === newStat.technology && stat.os === newStat.os)

        if (existingStat) {
            existingStat.details.successCount += newStat.details.successCount
            existingStat.details.totalCount += newStat.details.totalCount
            existingStat.details.targets = mergeTargets(existingStat.details.targets, newStat.details.targets)
        } else {
            statistics.push(newStat)
        }
    }

    return statistics
}

function aggregateDataByTypeAndTechnologyAndOs(data: ConversionDataFromCloudFunction[]): ConversionFlowData {
    const aggregatedData: ConversionFlowData = {}

    for (const item of data) {
        if (!aggregatedData[item.type]) {
            aggregatedData[item.type] = {
                statistics: [],
            }
        }
        aggregatedData[item.type].statistics = mergeStatistics(aggregatedData[item.type].statistics, item.statistics)
    }

    return aggregatedData
}
