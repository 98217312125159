import { Checkbox } from '@material-ui/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { Text } from '../common'


const StyledCheckbox = styled(Checkbox)`
    margin: -9px 0px;
`

interface Element {
    getId: string
    getName: string
}

interface Props {
    element: Element
    checked: boolean
    onCheck?: (element: Element) => any
    onUncheck?: (element: Element) => any
}

export const PlacementRow: React.FC<Props> = (props: Props) => {
    const { element, checked, onCheck, onUncheck } = props

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked

        if (checked) {
            onCheck?.(element)
        } else {
            onUncheck?.(element)
        }
    }, [element])

    return (
        <div>
            <StyledCheckbox
                checked={checked}
                color="primary"
                onChange={onChange}
                size="small"
            />
            <Text variant="body2">{element.getName}</Text>
        </div>
    )
}
