import { Timestamp } from '@tivio/firebase'
import dayjs from 'dayjs'

import { firebaseTimestampFromDate } from '../firebase/app'

import type { Dayjs } from 'dayjs'


export type Timeout = ReturnType<typeof setTimeout>

export const SECOND_IN_MILLISECONDS = 1000
export const SECOND = 1

/**
 * Converts seconds to time string in format - HH:MM:SS
 * @param seconds
 */
export const secondsToTimeString = (seconds: number, keepLocalTime: boolean) => {
    return dayjs.unix(seconds).utc(keepLocalTime).format('HH:mm:ss')
}

export const hoursToSeconds = (hours: number) => {
    return hours * 3600
}

/**
 * Converts time string to seconds - time string should be in format - HH:MM:SS
 */
export const timeStringToSeconds = (timeString?: string) => {
    if (!timeString) {
        return 0
    }

    const [hours, minutes, seconds] = timeString.split(':')

    const hoursParsed = parseInt(hours) || 0
    const minutesParsed = parseInt(minutes) || 0
    const secondsParsed = parseInt(seconds) || 0

    return (hoursParsed * 3600) + (minutesParsed * 60) + secondsParsed
}

export const secondsToMilliseconds = (seconds: number) => {
    return seconds * SECOND_IN_MILLISECONDS
}

export const secondsToFirebaseTimestamp = (seconds: number) => {
    const milliseconds = secondsToMilliseconds(seconds)
    const date = new Date(milliseconds)

    return firebaseTimestampFromDate(date)
}

export const millisecondsToSeconds = (milliseconds?: number) => {
    if (!milliseconds) {
        return 0
    }

    return milliseconds / SECOND_IN_MILLISECONDS
}

export const getCurrentTimeInSeconds = () => {
    return millisecondsToSeconds(dayjs().valueOf())
}

/**
 * Converts firebase timestamp to date
 * @param timestamp
 */
export const firebaseTimestampToDate = (timestamp?: Timestamp): Dayjs | undefined => {
    return timestamp ? dayjs(timestamp.toDate()) : undefined
}
