import { Box, Button, Container, Grid } from '@material-ui/core'
import { AssetPresetPlacement } from '@tivio/firebase'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as Yup from 'yup'

import { AssetPreset } from '../../../store/AssetPreset'
import { AssetUploader, AssetUploaderItem } from '../../asset/assetUploader'
import { Header } from '../../common'
import { useOrganization } from '../../hooks'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { InputGroup } from '../InputGroup'


interface Form {
    name: string
    description: string
}

const ASSET_NAME_TO_LABEL: Record<string, string> = {
    logo: 'Logo square (transparent)',
    logo_landscape: 'Logo landscape (transparent)',
    organization_banner: 'Banner',
    profile_photo: 'Profile photo',
}

const mapPresetName = (presetName: string) => ASSET_NAME_TO_LABEL[presetName] ?? presetName

export const GeneralTab = observer(() => {
    const { organization } = useOrganization()
    const [t] = useTranslation()

    const { values, errors, setFieldValue, handleSubmit, isSubmitting } = useFormik<Form>({
        initialValues: {
            name: organization?.name ?? '',
            description: organization?.description ?? '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('This field is required')),
            description: Yup.string().required(t('This field is required')),
        }),
        enableReinitialize: false,
        onSubmit: async (values) => {
            await organization?.updateField('name', values.name)
            await organization?.updateField('description', values.description)
        },
    })

    const item: AssetUploaderItem | undefined = useMemo(
        () =>
            organization
                ? {
                    getAsset: organization.getAsset,
                    getTempFilePath: (preset: AssetPreset, ext: string) => `${organization.ref.path}/${preset.name}.${ext}`,
                    getDocumentPath: () => organization.ref.path,
                    updateItem: organization.saveAssetResizeResults,
                }
                : undefined,
        [organization],
    )

    if (!item) {
        return null
    }

    return (
        <Container maxWidth="lg">
            <form onSubmit={handleSubmit}>
                <Header title={t('Details')} />
                <Box maxWidth={430}>
                    <Column spacing={2}>
                        <InputGroup
                            label={t('Name')}
                            value={values.name}
                            error={errors.name}
                            name="name"
                            onChange={(value) => setFieldValue('name', value)}
                        />
                        <InputGroup
                            multiline
                            label={t('Description')}
                            value={values.description}
                            error={errors.description}
                            name="description"
                            onChange={(value) => setFieldValue('description', value)}
                        />
                        <Row
                            rowProps={{
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    {t('Save')}
                                </Button>
                            </Grid>
                        </Row>
                    </Column>
                </Box>
            </form>
            <Header title={t('Assets')} />
            <AssetUploader
                item={item}
                filter={{ placement: AssetPresetPlacement.ORGANIZATION }}
                mapPresetName={mapPresetName}
            />
        </Container>
    )
})
