import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PromotionDocument } from '@tivio/firebase'
import { PatreonCreatorData, PatreonUserResponse } from '@tivio/types'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'

import { connectPatreonCreator, deletePromotion, getPatreonCreatorData, getPatreonUserData } from '../../../firebase/firestore/promotion'
import { COLORS, PAGES } from '../../../static/enum'
import { openPatreonPatronLogin } from '../../../utils/history.utils'
import { useListItemStyles } from '../../common/styles'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useOrganization } from '../../hooks'
import { useMember } from '../../hooks/permissions/permissions'
import { PatreonDialog } from '../../monetization/PatreonDialog'


const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
    },
    applicationMain: {
        fontSize: '1rem',
    },
    applicationInfo: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    blockIcon: {
        display: 'block',
    },
    tooltip: {
        fontSize: '0.75rem',
        backgroundColor: 'white',
        color: COLORS.DRAWER_BORDER,
        padding: theme.spacing(2),
        borderRadius: theme.spacing(1),
    },
}))

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
`

const Link = styled.a`
    text-decoration: none;
    color: #ffffff;
`

const SignOutButton = styled.div`
    cursor: pointer;
`

export const PromotionsPage = observer(() => {
    const listItemClasses = useListItemStyles()
    const classes = useStyles()
    const [t] = useTranslation()
    const { search } = useLocation()
    const { replace } = useHistory()

    const query = useMemo(() => new URLSearchParams(search), [search])

    const [isHovering, setIsHovering] = useState(false)
    const [patreonCampaignLoading, setPatreonCampaignLoading] = useState(false)
    const [creatorUserDataLoading, setCreatorUserDataLoading] = useState(false)
    const [promotionDialogOpened, setPromotionDialogOpened] = useState(false)
    const [creatorUserData, setCreatorUserData] = useState<PatreonUserResponse | undefined>()
    const [promotions, setPromotions] = useState<PromotionDocument[]>([])
    const [promotion, setPromotion] = useState<PromotionDocument | undefined>()
    const [patreonCampaign, setPatreonCampaign] = useState<PatreonCreatorData | undefined>()

    const { organization } = useOrganization()
    const member = useMember()

    const loadPromotions = async () => {
        if (organization) {
            const promos = await organization?.getPromotions()
            setPromotions(promos)
        }
    }

    const handleMouseEnter = () => {
        setIsHovering(true)
    }

    const handleMouseLeave = () => {
        setIsHovering(false)
    }

    const connectPatreonAccount = async () => {
        const code = query.get('code')

        if (query && code && member?.getId) {
            setCreatorUserDataLoading(true)
            try {
                const creatorData = await connectPatreonCreator({
                    data: {
                        code,
                        state: member.getId,
                    },
                    redirectUrl: window.location.origin + window.location.pathname,
                })
                setPatreonCampaign(creatorData)
                replace(PAGES.PROMOTIONS)
            } catch (e) {
                console.warn(e)
            } finally {
                setCreatorUserDataLoading(false)
            }
        }
    }

    const loadPatreonData = async (accessToken: string) => {
        try {
            setPatreonCampaignLoading(true)
            const { data } = await getPatreonCreatorData({
                accessToken,
            })
            setPatreonCampaign(data)
        } catch (e) {
            console.warn(e)
        } finally {
            setPatreonCampaignLoading(false)
        }
    }

    const loadCreatorUserData = async (accessToken: string) => {
        try {
            setCreatorUserDataLoading(true)
            const { data } = await getPatreonUserData({
                accessToken,
            })
            setCreatorUserData(data)
        } catch (e) {
            console.warn(e)
        } finally {
            setCreatorUserDataLoading(false)
        }
    }

    const onClose = async () => {
        setPromotion(undefined)
        setPromotionDialogOpened(false)
        await loadPromotions()
    }

    useEffect(() => {
        loadPromotions()
    }, [])

    useEffect(() => {
        connectPatreonAccount()
    }, [query])

    useEffect(() => {
        if (member?.getPatreon?.access_token) {
            if (promotionDialogOpened || promotion) {
                loadPatreonData(member.getPatreon.access_token)
            }
            if (!creatorUserData) {
                loadCreatorUserData(member.getPatreon.access_token)
            }
        }
    }, [promotion, member, creatorUserData, promotionDialogOpened])

    return (
        <DefaultPageContainer
            title="Promotions"
            buttons={[
                <Button
                    key="button-add-patreon-account"
                    startIcon={<Add />}
                    component="span"
                    size="small"
                    onClick={() => setPromotionDialogOpened(true)}
                >
                    {t('Create new Promotion')}
                </Button>,
            ]}
        >
            {
                promotions.map((promotionItem) => (
                    <Box
                        key={promotionItem.name}
                        my={2}
                    >
                        <Grid
                            container
                            alignItems="center"
                            spacing={2}
                            component="a"
                            justifyContent="space-between"
                            className={clsx(listItemClasses.root, classes.root)}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            title={t('Edit application')}
                        >
                            <Grid
                                onClick={() => setPromotion(promotionItem)}
                                item
                                className={classes.applicationMain}
                                xs={5}
                            >
                                {promotionItem.name}
                            </Grid>
                            <Grid
                                onClick={() => setPromotion(promotionItem)}
                                item
                                className={classes.applicationInfo}
                                xs={3}
                            >
                                CZK {promotionItem.prices['CZK']}
                            </Grid>
                            <Grid
                                item
                                xs={2}
                            >
                                <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    className={clsx(listItemClasses.showOnHover, { isHover: isHovering })}
                                >
                                    <Grid item>
                                        <IconButton
                                            size="small"
                                            title={t('Edit promotion')}
                                            onClick={() => setPromotion(promotionItem)}
                                            className={classes.blockIcon}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <IconButton
                                            size="small"
                                            title={t('Delete promotion')}
                                            onClick={async () => {
                                                await deletePromotion(promotionItem?.id, organization?.id)
                                                await loadPromotions()
                                            }}
                                            className={classes.blockIcon}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                ))
            }
            {(promotionDialogOpened || promotion) && <PatreonDialog
                promotion={promotion}
                loadCreatorCampaign={openPatreonPatronLogin}
                patreonCampaign={patreonCampaign}
                patreonCampaignLoading={patreonCampaignLoading}
                onClose={onClose}
            />}
        </DefaultPageContainer>
    )
})
