import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import { VideoSourceCodec, VideoSourceEncryption, VideoSourceField, VideoSourceProtocol } from '@tivio/types'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as yup from 'yup'

import DialogBase, { TDialogProps } from '../dialog/DialogBase'


interface VideoSourceDialog<T> extends TDialogProps<T> {
    sources: VideoSourceField[]
    index?: number
}

// object from VideoSourceField interface
const initialValues: VideoSourceField = {
    priority: 5,
    protocol: VideoSourceProtocol.HLS,
    encryption: VideoSourceEncryption.NONE,
    codec: VideoSourceCodec.H264,
    url: '',
}

const protocolOptions = Object.values(VideoSourceProtocol)
const encryptionOptions = Object.values(VideoSourceEncryption)
const codecOptions = Object.values(VideoSourceCodec)

export const VideoSourceDialog: React.FC<VideoSourceDialog<VideoSourceField>> = ({
    open,
    onCancel,
    onConfirm,
    sources,
    index }) => {
    const [t] = useTranslation()

    const form = useFormik({
        initialValues: typeof index === 'number' ? { ...sources[index] } : { ...initialValues },
        enableReinitialize: true,
        validateOnChange: false,
        onSubmit: (values) => {
            onConfirm?.(values)
        },
        validationSchema: yup.object().shape({
            priority: yup.number().min(1).max(5).required(),
            protocol: yup.mixed().oneOf(protocolOptions).required(),
            encryption: yup.mixed().oneOf(encryptionOptions).required(),
            codec: yup.mixed().oneOf(codecOptions).required(),
            url: yup.string().url(t('Please enter a valid URL.')).required(t('Please enter a valid URL.')),
        }),
    })

    useEffect(() => {
        form.resetForm()
    }, [open])

    return (
        <DialogBase
            open={open}
            onCancel={onCancel}
            onConfirm={() => { }}
            showCloseIcon
            hideActionsButtons
            maxWidth="sm"
            fullWidth
        >
            <Box
                mx={8}
                my={4}
            >
                <form onSubmit={form.handleSubmit}>
                    <Box>
                        <Box mb={3}>
                            <Typography variant="h5">Source</Typography>
                        </Box>
                        <Box my={4}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                            >
                                <InputLabel id="priorityLabel">Priority</InputLabel>
                                <Select
                                    labelId="priorityLabel"
                                    label="Priority"
                                    id="priority"
                                    name="priority"
                                    value={form.values.priority}
                                    onChange={form.handleChange}
                                    error={form.errors.priority !== undefined}
                                >
                                    <MenuItem value={1}>Priority 1 (highest)</MenuItem>
                                    <MenuItem value={2}>Priority 2</MenuItem>
                                    <MenuItem value={3}>Priority 3</MenuItem>
                                    <MenuItem value={4}>Priority 4</MenuItem>
                                    <MenuItem value={5}>Priority 5 (lowest)</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Box my={4}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                            >
                                <InputLabel id="protocolLabel">Protocol</InputLabel>
                                <Select
                                    labelId="protocolLabel"
                                    id="protocol"
                                    name="protocol"
                                    label="Protocol"
                                    value={form.values.protocol}
                                    onChange={form.handleChange}
                                    error={form.errors.protocol !== undefined}
                                >
                                    {protocolOptions.map(protocolItem => (
                                        <MenuItem
                                            key={protocolItem}
                                            value={protocolItem}
                                        >{protocolItem}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box my={4}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                            >
                                <InputLabel id="encryptionLabel">Encryption</InputLabel>
                                <Select
                                    labelId="encryptionLabel"
                                    id="encryption"
                                    name="encryption"
                                    label="Encryption"
                                    value={form.values.encryption}
                                    onChange={form.handleChange}
                                    error={form.errors.encryption !== undefined}
                                >
                                    {encryptionOptions.map(encryptionItem => (
                                        <MenuItem
                                            key={encryptionItem}
                                            value={encryptionItem}
                                        >{encryptionItem}</MenuItem>
                                    ))}

                                </Select>
                            </FormControl>
                        </Box>
                        <Box my={4}>
                            <FormControl
                                variant="outlined"
                                fullWidth
                                size="small"
                            >
                                <InputLabel id="codecLabel">Codec</InputLabel>
                                <Select
                                    labelId="codecLabel"
                                    id="codec"
                                    name="codec"
                                    label="Codec"
                                    value={form.values.codec}
                                    onChange={form.handleChange}
                                    error={form.errors.codec !== undefined}
                                >
                                    {codecOptions.map(codecItem => (
                                        <MenuItem
                                            key={codecItem}
                                            value={codecItem}
                                        >{codecItem}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box>
                        <Box mb={3}>
                            <Typography variant="h5">Path</Typography>
                        </Box>
                        <Box my={2}>
                            <TextField
                                multiline
                                fullWidth
                                variant="outlined"
                                size="small"
                                id="url"
                                name="url"
                                value={form.values.url}
                                onChange={form.handleChange}
                                error={form.errors.url !== undefined}
                                helperText={form.errors.url}
                            />
                        </Box>
                    </Box>
                    <Box mt={6}>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Box>
                </form>
            </Box>
        </DialogBase>
    )
}
