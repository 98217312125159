import { Error as AuthError } from '@firebase/auth-types'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'

import Translator from '../../../services/Translator'
import { mapFirebaseAuthErrorCodeToMessage } from '../../../utils/auth.utils'
import { useAuth } from '../useAuth'


type LoginForm = {
    email: string,
    password: string
}

type Props = {
    onSubmit: (values: LoginForm) => void
    onSuccess: () => void
    onError: () => void
}

const validationSchema = Yup.object()
    .shape({
        email: Yup.string().required(Translator.t('Login required')).email(Translator.t('Enter valid email')),
        password: Yup.string().required(Translator.t('Password required')),
    })

const useLoginForm = ({ onError, onSuccess, onSubmit }: Partial<Props> = {}) => {
    const { signInWithEmailAndPassword } = useAuth()
    const [loading, setLoading] = useState(false)
    const [loginError, setLoginError] = useState<string | null>(null)
    const { values, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik<LoginForm>({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values) => {
            onSubmit?.(values)

            setLoading(true)
            setLoginError(null)
            try {
                await signInWithEmailAndPassword(values.email, values.password)
                onSuccess?.()
            }
            catch (e) {
                onError?.()
                if (e.code) {
                    const firebaseAuthError = e as AuthError
                    const errorMessage = mapFirebaseAuthErrorCodeToMessage(firebaseAuthError.code)
                    setLoginError(errorMessage)
                }
            }
            finally {
                setLoading(false)
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
    })
    const handleLogin = useCallback(() => {
        handleSubmit()
    }, [handleSubmit])

    return {
        values,
        errors,
        handleLogin,
        touched,
        loginError,
        handleChange,
        handleBlur,
        loading,
    }
}

export {
    useLoginForm,
}
