import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'


const BadgeText = styled.span`
    display: flex;
    font-size: 0.75rem;
    text-align: center;
    margin: auto 0.4rem;
    color: #fffeff;
`

const BadgeBody = styled.span`
    display: inline-block;
    margin-left: 0.5rem;
    border-radius: 12px;
    background-color: ${COLORS.LIVE_BUTTON};
`

export const LiveBadge = ({ children }: PropsWithChildren) => {
    return(
        <BadgeBody>
            <BadgeText>{children}</BadgeText>
        </BadgeBody>
    )
}
