import { createLogger, notEmptyFilter } from '@tivio/common'
import { OrganizationAnalyticsDocumentId } from '@tivio/firebase'
import { PublishedStatus, RowFilterCollection } from '@tivio/types'
import { makeAutoObservable } from 'mobx'

import { createTag } from '../creator/tags.creator'
import { createGlobalVideo, createVideo } from '../creator/video.creator'
import { getFirestore } from '../firebase/app'
import { batchGet } from '../firebase/firestore/batchGet'
import { getDocumentsByIds, getQueryByFilter } from '../firebase/firestore/row'
import { getTagById } from '../firebase/firestore/tag'
import { Pagination } from '../firebase/pagination'
import { alertError } from '../utils/alert.utils'
import { getRowItemType } from '../utils/row.utils'
import { translate } from '../utils/translate.utils'

import TvChannel from './TvChannel'

import { getArticleById } from '../firebase/firestore/content'
import { createArticle } from '../creator/article.creator'

import type { Article } from './Article'
import type { Tag } from './Tag'
import type { TivioScreen } from './TivioScreen'
import type Video from './Video'
import type {
    ArticleDocument,
    OrganizationAnalyticsDocument,
    ScreenRowDocument,
    TagDocument,
    TvChannelDocument,
    VideoDocument,
} from '@tivio/firebase'
import type firebase from 'firebase/app'


const logger = createLogger('TivioRow')
const TOP_WATCHED_LIMIT = 20

type FirebaseRowItem = VideoDocument | TagDocument | TvChannelDocument
type RowItem = Video | Tag | TvChannel

/**
 * Items selected by user in custom row. Currently Video, Tag and Article is supported.
 * Undefined means that referenced entity has been removed from DB.
 */
export type CustomItems = (Video | Tag | Article | undefined)[]

class TivioRow {
    private _tags: Tag[] = []
    private _itemsPagination: Pagination<FirebaseRowItem, RowItem> | null = null
    private _customItems: CustomItems = []
    private _topWatchedVideos: Video[] = []

    constructor(
        private _ref: firebase.firestore.DocumentReference<ScreenRowDocument>,
        private data: ScreenRowDocument,
        private screen: TivioScreen,
        private _isNew: boolean = false,
    ) {
        this.initTags()
        this.fetchItems()

        makeAutoObservable(this)
    }

    initTags = async () => {
        try {
            const tagsDb = await Promise.all(this.tagRefs
                .map(async (tagRef) => await getTagById(this.screen.organization.id, tagRef.id)))
            this.tags = tagsDb.filter(notEmptyFilter) ?? [] as Tag[]
        } catch (e) {
            logger.error(`Failed to load video tags: ${this._ref.path}`, e)
        }
    }

    update = async (data: Partial<ScreenRowDocument>, fetch = false) => {
        try {
            this.data = {
                ...this.data,
                ...data,
            }
            await this.ref.update(this.data)
            if (fetch) {
                await this.fetchItems()
            }
        } catch (e) {
            alertError(translate('Failed to update row'))
            logger.error('Failed to update row', e)
        }
    }

    updateAndFetch = async (data: Partial<ScreenRowDocument>) => {
        this.update(data, true)
    }

    delete = async () => {
        try {
            await this.ref.delete()
            this.screen.rows = this.screen.rows.filter(row => row.id !== this.id)
        } catch (e) {
            alertError(translate('Failed to delete row'))
            logger.error('Failed to delete row', e)
        }
    }

    fetchItems = async () => {
        switch (this.data.type) {
            // CUSTOM ROWS
            case 'custom': {
                return this._fetchCustomItems()
            }
            // TOP WATCHED videos in last interval from organization analytics
            case 'topWatched': {
                return this._fetchTopWatchedItems()
            }
            // FILTER ROWS
            default: {
                return this._fetchFilterItems()
            }
        }
    }

    private _fetchFilterItems = async () => {
        try {
            if (!this.data.filter || !this.filter) {
                return
            }
            const query = getQueryByFilter<FirebaseRowItem>(
                this.data.filter,
                getFirestore().collection(this.data.filter.collection) as firebase.firestore.CollectionReference<FirebaseRowItem>,
            )

            this._itemsPagination = new Pagination<FirebaseRowItem, RowItem>(
                query,
                (ref, data) => {
                    if (this.filter?.collection === RowFilterCollection.TV_CHANNELS) {
                        return new TvChannel(
                            ref as firebase.firestore.DocumentReference<TvChannelDocument>,
                            data as TvChannelDocument,
                        )
                    }

                    if (this.filter?.collection === RowFilterCollection.VIDEOS) {
                        return createGlobalVideo(
                            ref as firebase.firestore.DocumentReference<VideoDocument>,
                            data as VideoDocument,
                            this.screen.organization,
                        )
                    }

                    return createTag(
                        ref as firebase.firestore.DocumentReference<TagDocument>,
                        data as TagDocument,
                    )
                },
                {
                    limit: this.data.filter.limit || 10,
                },
            )
        } catch (e) {
            logger.info('Failed to fetch row items', e)
        }
    }

    private _fetchCustomItems = async () => {
        try {
            let itemSnapshots: firebase.firestore.DocumentSnapshot<VideoDocument | TagDocument | ArticleDocument>[]
            const itemObjects: (Video | Tag | Article)[] = []
            const itemPaths = this.data.customItems!.map(item => item.itemRef.path)

            if (!itemPaths.length) {
                this._customItems = []
                return
            }
            const itemsType = getRowItemType(itemPaths[0]) // videos or tags

            if (itemsType === RowFilterCollection.VIDEOS) {
                itemSnapshots = await batchGet<VideoDocument>(itemPaths)
            } else if (itemsType === RowFilterCollection.TAGS) {
                itemSnapshots = await batchGet<TagDocument>(itemPaths)
            } else if (itemsType === RowFilterCollection.CONTENTS) {
                itemSnapshots = await batchGet<ArticleDocument>(itemPaths)
            } else {
                throw new Error('Unsupported row type')
            }

            if (!itemSnapshots.length) {
                return // no linked videos found
            }

            itemSnapshots.forEach((item) => {
                if (item.exists) {
                    const originalIndex = itemPaths.indexOf(item.ref.path) // Order item by it's original position.
                    if (itemsType === RowFilterCollection.VIDEOS) {
                        itemObjects[originalIndex] = createVideo(
                            item.ref as firebase.firestore.DocumentReference<VideoDocument>,
                            item.data() as VideoDocument,
                            this.screen.organization)
                    } else if (itemsType === RowFilterCollection.TAGS) {
                        itemObjects[originalIndex] = createTag(
                            item.ref as firebase.firestore.DocumentReference<TagDocument>,
                            item.data() as TagDocument)
                    } else if (itemsType === RowFilterCollection.CONTENTS) {
                        itemObjects[originalIndex] = createArticle(
                            item.ref as firebase.firestore.DocumentReference<ArticleDocument>,
                            item.data() as ArticleDocument,
                            this.screen.organization,
                        )
                    }
                }
            })

            this._customItems = itemObjects
        } catch (e) {
            console.error(e)
        }
    }

    private _fetchTopWatchedItems = async () => {
        const organizationPath = this.screen.organization.ref.path
        if (!organizationPath) {
            throw new Error('getTopWatchedVideosPagination Organization not ready')
        }
        const pathOrganizationAnalyticsTopWatched = `${organizationPath}/analytics/${OrganizationAnalyticsDocumentId.TopWatched}`
        try {
            const organizationAnalyticsDocument = await getFirestore().doc(pathOrganizationAnalyticsTopWatched).get()
            const organizationAnalyticsData = organizationAnalyticsDocument.data() as OrganizationAnalyticsDocument
            if (!organizationAnalyticsData) {
                logger.error(`Cannot find organization analytics top watch document with path ${pathOrganizationAnalyticsTopWatched}`)
            }
            if (!organizationAnalyticsData?.videos?.length) {
                this._topWatchedVideos = []
                return
            }
            const videoIds = organizationAnalyticsData.videos.map(videoDetail => videoDetail.itemReference.id)

            const videoResult = await getDocumentsByIds('videos', videoIds)

            this._topWatchedVideos = videoResult.flatMap(videoSnapshots => videoSnapshots.docs)
                .filter(videoSnapshot => (videoSnapshot.data() as VideoDocument)?.publishedStatus === PublishedStatus.PUBLISHED)
                .sort((doc1, doc2) => {
                    // sort according original order in analytics document
                    return videoIds.indexOf(doc1.id) - videoIds.indexOf(doc2.id)
                })
                .slice(0, TOP_WATCHED_LIMIT)
                .map(videoSnapshot =>
                    createVideo(
                        videoSnapshot.ref as firebase.firestore.DocumentReference<VideoDocument>,
                        videoSnapshot.data() as VideoDocument,
                        this.screen.organization),
                )
        } catch (e) {
            console.error(e)
        }
    }

    get id() {
        return this._ref.id
    }

    get rowId() {
        return this.data.rowId
    }

    get assets() {
        return this.data.assets
    }

    get orderBy() {
        return this.data.filter?.orderBy
    }

    /**
     * Row type. Currently supported values are `videos`, `tags`, `contents` and `tvChannels`.
     */
    get rowItemType() {
        if (!this.data.filter) {
            // TODO bad fallback
            return 'videos'
        }
        return getRowItemType(this.data.filter?.collection)
    }

    get filter() {
        return this.data.filter
    }

    get nameTranslation() {
        return this.data.name
    }

    get descriptionTranslation() {
        return this.data.description
    }

    get organization() {
        return this.screen.organization
    }

    get rowItemComponent() {
        return this.data.itemComponent
    }

    get rowComponent() {
        return this.data.rowComponent
    }

    get itemsPagination() {
        return this._itemsPagination
    }

    get ref() {
        return this._ref
    }

    get order() {
        return this.data.order
    }

    get tags() {
        return this._tags
    }

    set tags(tags: Tag[]) {
        this._tags = tags
    }

    get tagRefs() {
        return this.data.tags
    }

    get isNew() {
        return this._isNew
    }

    set isNew(isNew: boolean) {
        this._isNew = isNew
    }

    get isCustom() {
        return this.data.type === 'custom'
    }

    get type() {
        return this.data.type || 'filter'
    }

    /**
     * Items selected by user in custom row. Currently only Video and Tag is supported.
     */
    get customItems(): CustomItems {
        return this._customItems
    }

    get numberOfLines() {
        return this.data.numberOfLines
    }

    /**
     * Top watched videos in last interval from organization analytics
     */
    get topWatchedVideos(): Video[] {
        return this._topWatchedVideos
    }

    /**
     * Replace current custom items with new ones.
     *
     * @param items Array of new items.
     */
    async setCustomItems(items: CustomItems) {
        try {
            const newItems = this._customItemsToRefs(items)
            this._customItems = items
            await this.update({
                customItems: newItems,
            })
        } catch (e) {
            alertError(translate('Failed to update row'))
            console.error(e)
        }
    }

    private _customItemsToRefs = (items: CustomItems) => {
        const validItems = items.filter(item => typeof item !== 'undefined')
        return validItems.map(item => ({ itemRef: item!.getRef }))
    }

    async removeCustomItem(itemPath: string) {
        try {
            const newItems = this._customItems.filter(item => typeof item !== 'undefined' && item.getRef.path !== itemPath)
            this._customItems = newItems
            await this.update({
                customItems: this._customItemsToRefs(newItems),
            })
        } catch (e) {
            alertError(translate('Failed to update row'))
            console.error(e)
        }
    }

    async addCustomItem(itemPath: string) {
        try {
            const parsedPath = itemPath.split('/')
            let newItem: Video | Tag | Article | undefined

            if (parsedPath.length === 2) {
                if (parsedPath[0] === RowFilterCollection.VIDEOS) {
                    newItem = await this.organization.getVideoById(parsedPath[1])
                } else if (parsedPath[0] === RowFilterCollection.CONTENTS) {
                    newItem = await getArticleById(parsedPath[1], this.organization)
                }
            } else if (parsedPath.length === 4) {
                newItem = await getTagById(this.organization.id, parsedPath[3])
            } else {
                throw new Error('Invalid item path ' + itemPath)
            }

            if (newItem) {
                const newItems = [newItem, ...this._customItems] as CustomItems
                console.log('newItems', newItems)
                await this.update({
                    customItems: this._customItemsToRefs(newItems),
                })
                this._customItems = newItems
            } else {
                throw new Error('Video not found ' + parsedPath[1])
            }
        } catch (e) {
            alertError(translate('Failed to update row'))
            console.error(e)
        }
    }
}

export {
    TivioRow,
}
