import { SearchOptions } from '@algolia/client-search'
import algoliarecommend, { RecommendClient, RecommendQueriesResponse, RelatedProductsQuery } from '@algolia/recommend'
import { RequestOptions } from '@algolia/transporter'
import { IndexedObject } from '@tivio/types'
import { useCallback } from 'react'


let algoliaRecommendClient: RecommendClient

function getAlgoliaRecommendClient() {
    if (!algoliaRecommendClient) {
        if (!process.env.ALGOLIA_APPLICATION_ID) {
            throw new Error('Can\'t get ALGOLIA_APPLICATION_ID, recommend client will not be instantiated.')
        }

        if (!process.env.ALGOLIA_SEARCH_API_KEY) {
            throw new Error('Can\'t get ALGOLIA_SEARCH_API_KEY, recommend client will not be instantiated.')
        }

        if (!process.env.ALGOLIA_GATEWAY_HOST) {
            throw new Error('Can\'t get ALGOLIA_GATEWAY_HOST, recommend client will not be instantiated.')
        }

        algoliaRecommendClient = algoliarecommend(
            process.env.ALGOLIA_APPLICATION_ID,
            process.env.ALGOLIA_SEARCH_API_KEY,
            {
                hosts: [ { url: process.env.ALGOLIA_GATEWAY_HOST } ],
            },
        )
    }

    return algoliaRecommendClient
}

export const useAlgoliaRecommend = () => {
    const getRecommendations = useCallback((
        query: RelatedProductsQuery,
        requestOptions?: RequestOptions & SearchOptions,
    ): Readonly<Promise<RecommendQueriesResponse<IndexedObject>>> => {
        return getAlgoliaRecommendClient().getRelatedProducts([ query ], requestOptions)
    }, [])

    return {
        getRecommendations,
    }
}
