import React from 'react'

import { goAdsPage } from '../../../utils/history.utils'
import { AdProfileForm } from '../../adProfile'
import { useOrganization } from '../../hooks/dataHooks/useOrganization'


const AdProfileCreatePage = () => {
    const { organization } = useOrganization()

    return (
        <AdProfileForm
            onSubmit={async (adProfile) => {
                await organization?.addAdProfile(adProfile)
                goAdsPage()
            }}
        />
    )
}

export default AdProfileCreatePage
