import { useTranslation } from 'react-i18next/hooks'

import Channel from '../../../store/Channel'
import { storeNotReady } from '../index'

import { useOrganization } from './useOrganization'


const useOrganizationVodChannelsPagination = () => {
    const { organization } = useOrganization()
    const [ t ] = useTranslation()

    if (!organization) {
        return {
            addChannel: storeNotReady,
            fetchMore: storeNotReady,
            getChannel: storeNotReady,
            channelExistsInOrganization: storeNotReady,
            channels: [] as Channel[],
            channelsRefs: [],
            isLoading: false,
        }
    }

    const addChannel = () => {
        // @ts-expect-error
        return organization.addChannel({
            name: `${t('New channel')} ${organization.channelsPagination.getItemsCount}`,
        })
    }

    const getChannel = async (itemId: string) => {
        return organization.channelsPagination.getItem(itemId)
    }

    const channelExistsInOrganization = (channelId: string) => {
        return organization.channelsRefs.some(channelRef => channelRef.id === channelId)
    }

    return {
        addChannel,
        getChannel,
        channelExistsInOrganization,
        fetchMore: organization.channelsPagination.fetchMore,
        channels: organization.channelsPagination.getItems,
        channelsRefs: organization.channelsPagination.getRefs,
        isLoading: organization.channelsPagination.isLoading,
    }
}

export {
    useOrganizationVodChannelsPagination,
}
