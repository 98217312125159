import { Article } from '../store/Article'
import Organization from '../store/Organization'

import type { ArticleDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const createArticle = (
    ref: firebase.firestore.DocumentReference<ArticleDocument>,
    data: ArticleDocument,
    organization: Organization,
) => {
    return new Article(
        ref,
        data,
        organization,
    )
}

export {
    createArticle,
}
