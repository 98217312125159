import { functions, tivio } from '@tivio/core-js'
import { useEffect } from 'react'

import { useFetchDataState } from './common/useFetchDataState'


/**
 * Returns decoded (and verified) info from referral token.
 *
 * @param referralToken referral user token
 */
export const useReferralInfo = (referralToken: string) => {
    const organizationId = tivio.organization?.id
    const { data, error, loading, setStartFetching, setSuccessResult, setErrorResult } = useFetchDataState<functions.ReferralLinkInfo>()

    useEffect(() => {
        (async () => {
            if (referralToken && organizationId) {
                // TODO this logic can be also moved to some useFetchData (but it must be tested)
                setStartFetching()
                try {
                    const info = await tivio.getReferralInfo(organizationId, referralToken)
                    setSuccessResult(info)
                } catch (e) {
                    setErrorResult(e)
                }
            }
        })()
    }, [organizationId, referralToken, setStartFetching, setSuccessResult, setErrorResult])

    return { data, error, loading }
}
