import { QuerySnapshot } from '@tivio/firebase'
import { LangCode, Translation } from '@tivio/types'


import { getNamesForScreenIds } from '../../../firebase/firestore/conversions'
import { getDocumentsByIds } from '../../../firebase/firestore/row'

import { createConversionNode, createDeviceNode, createPageNode, createTargetNode } from './create.util'
import { ConversionFlowData, PercentageMap, Statistic, Target } from './types'

import type { Edge, Node } from 'reactflow'


interface TargetsWithPagesForEveryDevice {
    [key: string]: Target[]
}

interface OrganizationInput {
    defaultLanguage: LangCode
    organizationId: string
}

interface TargetsWithPages {
    name?: string
    totalCount: number
    successCount: number
    targetId: string
    targets: Target[]
}

interface createDeviceNodesAndEdgesProps {
    conversion: { statistics: Statistic[] }
    percentagePerDevice: PercentageMap
    conversionNode: Node
    conversionKey: string
    nodes: Node[]
    selectedIds: string[]
    edges: Edge[]
}

interface CreatePageNodesAndEdgesProps {
    pages: Target[]
    screenNames: { [key: string]: string }
    targetNode: Node
    targetIndex: number
    conversionKey: string
    pagePercentMap: PercentageMap
    nodes: Node[]
    selectedIds: string[]
    edges: Edge[]
}


export const getTargetWithPages = (statistics: Statistic[]): TargetsWithPages[] => {
    const targetsWithPagesForEveryDevice: TargetsWithPagesForEveryDevice[] = []
    statistics.forEach((device) => {
        const testObjectMapTargetWithPaths: { [key: string]: Target[] } = {}
        device.details.targets.forEach((target) => {
            const { targetId, ...rest } = target
            if (!testObjectMapTargetWithPaths[targetId]) {
                testObjectMapTargetWithPaths[targetId] = []
            }
            testObjectMapTargetWithPaths[targetId].push({ targetId, ...rest })
        })

        targetsWithPagesForEveryDevice.push(testObjectMapTargetWithPaths)
    })

    const targetWithPages = createTargetsWithPages(targetsWithPagesForEveryDevice)
    return sortObjectsByTotalCount(targetWithPages)
}

const createTargetsWithPages = (dataObjects: TargetsWithPagesForEveryDevice[]) => {
    const preparedResult: TargetsWithPagesForEveryDevice = {}
    for (const dataObject of dataObjects) {
        for (const key in dataObject) {
            if (!preparedResult[key]) {
                preparedResult[key] = []
            }

            for (const videoInfo of dataObject[key]) {
                const idIndex = preparedResult[key].findIndex((item) => item.path === videoInfo.path && item.pathParam === videoInfo.pathParam)
                if (idIndex < 0) {
                    preparedResult[key].push(videoInfo)
                } else {
                    preparedResult[key][idIndex].successCount += videoInfo.successCount
                    preparedResult[key][idIndex].totalCount += videoInfo.totalCount
                }
            }
        }
    }

    return preparedResult
}

function sortObjectsByTotalCount(data: TargetsWithPagesForEveryDevice) {
    const result = Object.entries(data).map(([key, values]) => {
        const totalCount = values.reduce((sum, val) => sum + val.totalCount, 0)
        const successCount = values.reduce((sum, val) => sum + val.successCount, 0)
        return { totalCount, successCount, targetId: key, targets: values }
    })
    return result.sort((a, b) => b.totalCount - a.totalCount)
}

export async function createNodes(conversionFlowData: ConversionFlowData, organization: { defaultLanguage: LangCode, organizationId: string }): Promise<{
    nodes: Node[],
    edges: Edge[],
    selectedIds: string[],
    selectedConversionType: string
}> {

    const nodes: Node[] = []
    const edges: Edge[] = []
    const selectedIds: string[] = []
    let selectedConversionType = ''

    for (const conversionKey of Object.keys(conversionFlowData)) {
        const conversionIndex = Object.keys(conversionFlowData).indexOf(conversionKey)
        const conversion = conversionFlowData[conversionKey]

        const { totalCount, successCount } = getConversionCounts(conversion)
        const conversionNode = createConversionNode(conversionIndex, conversionKey, {
            conversionTotalCount: totalCount,
            conversionSuccessCount: successCount,
        })
        nodes.push(conversionNode)

        if (conversionNode.selected) {
            selectedIds.push(conversionNode.id)
            selectedConversionType = conversionNode?.data?.conversionType
        }

        const percentagePerDevice = calculateDevicePercentage(conversion?.statistics, conversionKey)
        // Sorted conversions according total count for device key pair
        const targetsWithPages: TargetsWithPages[] = getTargetWithPages(conversion.statistics)
        createDeviceNodesAndEdges({ conversion, percentagePerDevice, conversionNode, conversionKey, nodes, selectedIds, edges })

        const targetsTotalCount = targetsWithPages.reduce((acc: number, item) => acc + item.totalCount, 0)
        const slicedTargetsWithPages: TargetsWithPages[] = targetsWithPages.slice(0, 10)
        const screenNames = await getNamesForScreenIds(organization.organizationId, organization.defaultLanguage)

        const vidIds: string[] = []
        const tagIds: string[] = []
        slicedTargetsWithPages.forEach((target) => {
            const targetType = target.targets.find((item) => item.targetId === target.targetId)?.targetType
            if (targetType === 'videos' || targetType === 'player') {
                vidIds.push(target.targetId)
            } else if (targetType === 'series') {
                tagIds.push(target.targetId)
            }
        })

        const targetDocs = await getDocuments({ videoIds: vidIds, tagIds, organization })

        appendNameToTargets(slicedTargetsWithPages, targetDocs, organization.defaultLanguage)

        for (let targetIndex = 0; targetIndex < slicedTargetsWithPages.length; targetIndex++) {
            const target = slicedTargetsWithPages[targetIndex]
            const translatedTargetName = screenNames[target.targetId] ? screenNames[target.targetId] : target?.name

            const targetNode = await createTargetNode({
                targetId: target.targetId,
                translatedTargetName,
                targetIndex,
                targetsTotalCount,
                conversionType: conversionKey,
                successCount: target.successCount,
                totalCount: target.totalCount,
            })
            nodes.push(targetNode)

            if (targetNode.selected) {
                selectedIds.push(targetNode.id)
            }

            edges.push(createEdge(conversionNode.id, targetNode.id, conversionKey))

            const firstTenPages = getFirstTenPages(target.targets)
            const { videoIds, tagIds } = getVideoAndTagIds(firstTenPages)
            const docs = await getDocuments({ videoIds, tagIds, organization })
            appendNameToPages(firstTenPages, docs, organization.defaultLanguage)

            const pagePercentMap = createPagePercentMap(firstTenPages, target.totalCount)


            createPageNodesAndEdges({
                pages: firstTenPages,
                targetNode,
                targetIndex,
                nodes,
                edges,
                selectedIds,
                conversionKey,
                screenNames,
                pagePercentMap,
            })
        }
    }

    return { nodes, edges, selectedIds, selectedConversionType }
}

/**
 * Creates device nodes and edges based on the provided parameters.
 *
 * @param {createDeviceNodesAndEdgesProps} params - The parameters for creating device nodes and edges.
 */
function createDeviceNodesAndEdges({
    conversion,
    percentagePerDevice,
    conversionNode,
    conversionKey,
    nodes,
    selectedIds,
    edges,
}: createDeviceNodesAndEdgesProps) {
    for (let deviceIndex = 0; deviceIndex < conversion.statistics.length; deviceIndex++) {
        const statistic = conversion.statistics[deviceIndex]
        const deviceNode = createDeviceNode(statistic, deviceIndex, percentagePerDevice, conversionKey)
        nodes.push(deviceNode)

        if (deviceNode.selected) {
            selectedIds.push(deviceNode.id)
        }

        edges.push(createEdge(deviceNode.id, conversionNode.id, conversionKey))
    }
}


/**
 * Creates page nodes and edges based on the provided parameters.
 * @param pages
 * @param screenNames
 * @param targetNode
 * @param targetIndex
 * @param conversionKey
 * @param pagePercentMap
 * @param nodes
 * @param selectedIds
 * @param edges
 */
function createPageNodesAndEdges({
    pages,
    screenNames,
    targetNode,
    targetIndex,
    conversionKey,
    pagePercentMap,
    nodes,
    selectedIds,
    edges,
}: CreatePageNodesAndEdgesProps) {
    for (let pageIndex = 0; pageIndex < pages.length; pageIndex++) {
        const page = pages[pageIndex]
        const translatedScreenName = screenNames[page.pathParam]
        const pageNode = createPageNode({
            page,
            parentId: targetNode.id,
            targetIndex,
            translatedScreenName,
            conversionType: conversionKey,
            percentMap: pagePercentMap[page.path + '/' + page.pathParam],
        })
        nodes.push(pageNode)

        if (pageNode.selected) {
            selectedIds.push(pageNode.id)
        }

        edges.push(createEdge(targetNode.id, pageNode.id, conversionKey, conversionKey === 'visit'))
    }
}

/**
 * Calculates the total and successful conversion counts from the given conversion statistics.
 *
 * @param {Object} conversion - The conversion object containing statistics.
 * @param {Statistic[]} conversion.statistics - An array of Statistic objects.
 * Each Statistic object should contain a 'details' property with 'totalCount' and 'successCount' properties.
 *
 * @returns {Object} An object with two properties: 'totalCount' and 'successCount'.
 * 'totalCount' is the sum of 'totalCount' properties of all Statistic objects in the input.
 * 'successCount' is the sum of 'successCount' properties of all Statistic objects in the input.
 *
 * @example
 *
 * const conversion = {
 *   statistics: [
 *     { details: { totalCount: 100, successCount: 60 } },
 *     { details: { totalCount: 200, successCount: 120 } }
 *   ]
 * };
 *
 * getConversionCounts(conversion);
 * // Returns: { totalCount: 300, successCount: 180 }
 */
function getConversionCounts(conversion: { statistics: Statistic[] }) {
    const totalCount = conversion.statistics.reduce((acc: number, item) => acc + item.details.totalCount, 0)
    const successCount = conversion.statistics.reduce((acc: number, item) => acc + item.details.successCount, 0)

    return { totalCount, successCount }
}

/**
 * Creates an edge object representing a connection between two nodes.
 *
 * @param {string} source - The ID of the source node.
 * @param {string} target - The ID of the target node.
 * @param {string} conversionType - The type of conversion associated with the edge.
 * @param {boolean} [hidden=false] - Optional. Specifies whether the edge is hidden.
 * @returns {Edge} The created edge object.
 */
function createEdge(source: string, target: string, conversionType: string, hidden = false): Edge {
    return {
        id: `${source}-${target}`,
        source,
        target,
        hidden,
        data: {
            conversionType,
        },
    }
}

/**
 * Retrieves the first ten pages from the given array of targets, sorted in descending order based on the total count.
 *
 * @param {Target[]} targets - The array of targets to retrieve the pages from.
 * @returns {Target[]} An array containing the first ten pages sorted in descending order based on the total count.
 */
function getFirstTenPages(targets: Target[]): Target[] {
    return targets.sort((a, b) => b.totalCount - a.totalCount).slice(0, 10)
}

/**
 * Extracts video IDs and tag IDs from the pages in the given array of targets.
 *
 * @param {Target[]} firstTenPages - The array of targets representing the pages.
 * @returns {{ videoIds: string[], tagIds: string[] }} An object containing the extracted video IDs and tag IDs.
 */
function getVideoAndTagIds(pages: Target[]): { videoIds: string[], tagIds: string[] } {
    const videoIds: string[] = []
    const tagIds: string[] = []

    pages.forEach((page) => {
        if (page.pathParam !== 'unknown') {
            if (page.path === 'video' || page.path === 'videos') {
                videoIds.push(page.pathParam)
            } else {
                tagIds.push(page.pathParam)
            }
        }
    })

    return { videoIds, tagIds }
}

/**
 * Retrieves documents based on the provided video IDs and tag IDs, within the specified organization.
 *
 * @param {Object} params - The parameters for retrieving documents.
 * @param {string[]} params.videoIds - An array of video IDs to retrieve documents for.
 * @param {string[]} [params.tagIds] - Optional. An array of tag IDs to retrieve documents for.
 * @param {OrganizationInput} params.organization - The organization input for retrieving documents.
 * @returns {Promise<QuerySnapshot[][]>} A promise that resolves to an array of retrieved documents.
 */
async function getDocuments({
    videoIds,
    tagIds,
    organization,
}: {
    videoIds: string[],
    tagIds?: string[],
    organization: OrganizationInput
}): Promise<QuerySnapshot[][]> {
    const documentPromises = [
        await getDocumentsByIds('videos', videoIds),
    ]

    if (tagIds) {
        documentPromises.push(
            await getDocumentsByIds(`organizations/${organization.organizationId}/tags`, tagIds),
        )
    }

    return await Promise.all(documentPromises)
}

function isTranslation(name: string | Translation): name is Translation {
    return typeof name === 'object'
}

/**
 * Append the names for the first ten pages in the given array of targets based on the provided documents.
 *
 * @param {Target[]} pages - The array of targets representing the pages.
 * @param {QuerySnapshot[][]} docs - An array of documents to retrieve the names from.
 * @param {LangCode} defaultLanguage - The default language code used to retrieve the name from the documents.
 */
function appendNameToPages(pages: Target[], docs: QuerySnapshot[][], defaultLanguage: LangCode) {
    docs.forEach((docArray) => {
        docArray.forEach((doc) => {
            doc.docs.forEach((innerDoc) => {
                const index = pages.findIndex((page) => page.pathParam === innerDoc.id)

                if (index !== -1) {
                    const name = innerDoc.data().name
                    if (isTranslation(name)) {
                        pages[index].name = name[defaultLanguage]
                    } else {
                        pages[index].name = name
                    }
                }
            })
        })
    })
}

/**
 * Append the names for the first ten targets in the given array of TargetsWithPages based on the provided documents.
 *
 * @param {TargetsWithPages[]} targets - The array of TargetsWithPages representing the targets.
 * @param {any[]} docs - An array of documents to retrieve the names from.
 * @param {LangCode} defaultLanguage - The default language code used to retrieve the name from the documents.
 */
function appendNameToTargets(targets: TargetsWithPages[], docs: any[], defaultLanguage: LangCode) {
    docs.forEach((docArray) => {
        docArray.forEach((doc: any) => {
            doc.docs.forEach((innerDoc: any) => {
                const index = targets.findIndex((target) => target.targetId === innerDoc.id)

                if (index !== -1) {
                    targets[index].name = innerDoc.data().name[defaultLanguage]
                }
            })
        })
    })
}

/**
 * Creates a page percent map object based on the first ten pages and the total count.
 *
 * @param {Target[]} pages - The array of targets representing the pages.
 * @param {number} totalCount - The total count used for calculating the percentage.
 * @returns {PercentageMap} A map object where the keys are page paths concatenated with page parameters,
 * and the values are objects containing the percentage and position information.
 */
function createPagePercentMap(pages: Target[], totalCount: number): PercentageMap {
    return pages.reduce((acc: PercentageMap, item, index) => {
        const percent = (item.totalCount / totalCount) * 100
        const position = index + 1

        const key = `${item.path}/${item.pathParam}`

        acc[key] = {
            value: Math.round(percent),
            position,
        }

        return acc
    }, {})
}

/**
 * Calculates the device percentage for each device in the provided data.
 *
 * @param {Statistic[]} data - The array of statistics data.
 * @param {string} conversionType - The type of conversion.
 * @returns {PercentageMap} An object containing the device percentage information, where the keys are device keys,
 * and the values are objects containing the percentage value and position.
 */
const calculateDevicePercentage = (data: Statistic[], conversionType: string): PercentageMap => {
    const percentageMap: { [key: string]: number } = {}
    const total = data.reduce((acc, item) => acc + item.details.totalCount, 0)
    data.forEach((item, index) => {
        const percentage = Math.round((item.details.totalCount / total) * 100)
        percentageMap[`device-${item.technology}-${index}-${item.os}-${conversionType}`] = percentage
    })

    const sortedKeys = Object.keys(percentageMap).sort((a, b) => percentageMap[b] - percentageMap[a])

    const result: { [key: string]: { value: number; position: number } } = {}

    sortedKeys.forEach((key, index) => {
        result[key] = {
            value: percentageMap[key],
            position: index,
        }
    })
    return result
}

// Might come in handy later when redesigning the diagram
export function calculateStrokeWidths(nodes: Node[]) {
    const totalCountValues = nodes
        .filter((node) => node?.type && ['device', 'target', 'page'].includes(node.type))
        .map((node) => node.data.totalCount)
    const [minValue, maxValue] = [Math.min(...totalCountValues), Math.max(...totalCountValues)]

    return totalCountValues.reduce((acc, val) => {
        acc[val] = getLineWidth(val, minValue, maxValue)
        return acc
    }, {})
}

function getLineWidth(value: number, minValue: number, maxValue: number): number {
    const levels: [number, number][] = [
        [0.9 * maxValue, 8],
        [0.8 * maxValue, 7],
        [0.7 * maxValue, 6],
        [0.5 * maxValue, 5],
        [0.3 * maxValue, 4],
        [0.2 * maxValue, 3],
        [0.1 * maxValue, 2],
        [minValue, 1],
    ]

    for (const [level, lineWidth] of levels) {
        if (value >= level) {
            return lineWidth
        }
    }

    return 1 // default line width if the value is outside the range
}
