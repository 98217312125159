import { useState } from 'react'


const useUpdatedEntity = () => {
    const [ isUpdated, setIsUpdated ] = useState(false)

    const updateProxy = (callback: () => any) => {
        callback()
        !isUpdated && setIsUpdated(true)
    }

    const resetIsUpdated = () => {
        setIsUpdated(false)
    }

    return {
        isUpdated,
        resetIsUpdated,
        updateProxy,
    }
}

export {
    useUpdatedEntity,
}
