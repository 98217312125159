import { Container } from '@material-ui/core'
import { TvStreamType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { useAds } from '../hooks/dataHooks/useAds'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { AddStrategy } from './AddStrategy'
import { Strategy, STRATEGY_NAMES_MAPPING } from './Strategy'

import type { AdProfileDocumentWithId } from '../../firebase/firestore/converters'
import type { MonetizationPlacementField, StrategyName } from '@tivio/firebase'


const StrategyContainer = styled.div`
    margin: 2rem 0 4rem 0;
`

interface StrategiesTabProps {
    adProfiles: AdProfileDocumentWithId[]
}

export const StrategiesTab: FC<StrategiesTabProps> = observer(({ adProfiles }) => {
    const {
        ads,
        addAd,
        addAdSlot,
        changeOrderOfAdSlots,
        removeAd,
        deleteAdSlot,
        deleteAdConfig,
        changeMidRollInterval,
        setPlacement,
        updateAdTitle,
    } = useAds()

    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()

    const handleDeleteStrategy = (strategy: any) => {
        confirmAction(
            () => removeAd(strategy.id),
            `${t('Are you sure you want to delete strategy')} ${strategy.name}?`,
        )
    }

    const handleDeleteAdSlot = (monetizationId: string, variantId: string, name: string, index: number, slotName?: string) => {
        confirmAction(
            () => deleteAdSlot(monetizationId, variantId, name as StrategyName, index),
            `${t('Are you sure you want to delete slot')} ${slotName ?? ''}?`,
        )
    }

    const handleDeleteAdConfig = (monetizationId: string, variantId: string, name: string) => {
        confirmAction(
            () => deleteAdConfig(monetizationId, variantId, name as StrategyName),
            `${t('Are you sure you want to delete all slots in')} ${STRATEGY_NAMES_MAPPING[name] ?? name}?`,
        )
    }

    // TODO rename strategy to adMonetization here, rename adConfig to strategy (need to discuss with Jakub and Ladislav)
    return (
        <Container maxWidth="lg">
            <AddStrategy
                handleAddStrategy={addAd}
            />
            {ads.map((strategy) => {
                const handleSubmitPlacement = (
                    checkedTvChannels: string[],
                    checkedVodChannels: string[],
                    checkedSections: string[],
                    checkedVideos: string[],
                    tvStream: TvStreamType,
                ) => {
                    setPlacement(
                        strategy.getId,
                        checkedTvChannels,
                        checkedVodChannels,
                        checkedSections,
                        checkedVideos,
                        tvStream,
                    )
                }

                return (
                    <StrategyContainer key={strategy.getId}>
                        <Strategy
                            id={strategy.getId}
                            title={strategy.getName}
                            placements={strategy.getPlacements as MonetizationPlacementField}
                            monetization={strategy}
                            handleAddAdSlot={(name, adSlot, variantId) => addAdSlot(strategy.getId, variantId, name as StrategyName, adSlot)}
                            handleChangeAdSlotsOrder={
                                (name, adSlots, variantId) => changeOrderOfAdSlots(strategy.getId, variantId, name as StrategyName, adSlots)
                            }
                            handleDeleteStrategy={() => handleDeleteStrategy(strategy)}
                            handleDeleteAdSlot={
                                (name, index, variantId, slotName) => handleDeleteAdSlot(strategy.getId, variantId, name as StrategyName, index, slotName)
                            }
                            handleDeleteAdConfig={(name, variantId) => handleDeleteAdConfig(strategy.getId, variantId, name as StrategyName)}
                            handleChangeMidRollInterval={(interval, variantId) => changeMidRollInterval(strategy.getId, variantId, interval)}
                            handleEditStrategyTitle={(title) => updateAdTitle(strategy.getId, title)}
                            handleSubmitPlacement={handleSubmitPlacement}
                            adProfiles={adProfiles}
                        />
                    </StrategyContainer>
                )
            })}
        </Container>
    )
})

StrategiesTab.displayName = 'StrategiesTab'
