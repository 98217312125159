import { Button, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'

import { goUsersPage } from '../../../utils/history.utils'
import { Header } from '../../common'
import { StyledContainer } from '../../DefaultPageContainer'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { useUser } from '../../hooks/dataHooks/useUser'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { Column } from '../../uiLayout/Column'
import { UserPurchases } from '../../user/UserPurchases'
import { UserLayoutParams } from '../layout/UserLayout'


export const UserPage = observer(() => {
    const { userId } = useParams<UserLayoutParams>()
    const [t] = useTranslation()
    const { confirmAction } = useConfirmAction()
    const { showError, showSuccess } = useAlert()
    const { user, notFound } = useUser(userId)
    useEffect(() => {
        if (!user || user.hasPurchases) {
            return
        }
        user.loadPurchases()
    }, [user])

    // Redirect back if user not found
    useEffect(() => {
        if (notFound) {
            showError(t('User not found'))
            goUsersPage()
            return
        }
    }, [notFound, showError, t])

    const handleDisableUser = () => {
        confirmAction(
            async () => {
                if (!user) {
                    return
                }
                try {
                    await user.disableUser()
                    showSuccess(t('User disabled'))
                } catch (e) {
                    showError(t('Failed to disable User'))
                }

            },
            t('Are you sure you want to disable user with email {{userEmail}}? This operation is irreversible. All user\'s subscriptions will be cancelled! The user will not be able to log in to any Tivio application.',
                { userEmail: user?.email ?? '' }),
        )
    }

    return (
        <>
            <DefaultPageHeader
                title={user?.email ?? ''}
                buttons={[
                    <Button
                        key="disable-user"
                        component="span"
                        disabled={user?.disabled ?? true}
                        onClick={handleDisableUser}
                    >
                        {t('Disable')}
                    </Button>,
                ]}
            />
            <StyledContainer
                maxWidth="lg"
                $bottomGutter={true}
            >
                {user ? (
                    <>
                        {user.disabled && (
                            <Column spacing={1}>
                                <Header title={t('General')} />
                                <Grid
                                    container
                                    spacing={2}
                                    direction="column"
                                >
                                    <Grid item>
                                        <Typography variant="button"> {t('This user is disabled.')}</Typography>
                                    </Grid>
                                </Grid>
                            </Column>
                        )}
                        <Column spacing={1}>
                            <UserPurchases user={user} />
                        </Column>
                    </>
                ) : (
                    <div>Loading user...</div>
                )}
            </StyledContainer>
        </>
    )
})
