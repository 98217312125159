import { LangCode, ROW_ITEM_TYPES } from '@tivio/types'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { listPrograms } from '../firebase/firestore/epg'
import { addEpgMarker, deleteEpgMarker, MarkerData } from '../firebase/firestore/markerEpg'
import { listMarkers, updateTvChannel } from '../firebase/firestore/tvChannels'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { goEpgChannelPage } from '../utils/history.utils'
import { getVideoCoverAsset } from '../utils/video.utils'

import Marker from './Marker'
import Program from './Program'

import type { FirestoreTimestamp } from '../firebase/app'
import type { TvChannelDocument } from '@tivio/firebase'
import type { Translation } from '@tivio/types'
import type firebase from 'firebase/app'


export class TvChannel {
    public loading = false
    private programsInitialized = false
    private markersInitialized = false
    private programs: Program[] = []
    private markers: Marker[] = []

    constructor(
        private readonly ref: firebase.firestore.DocumentReference<TvChannelDocument>,
        private _data: TvChannelDocument,
    ) {
        makeAutoObservable(this)
    }

    addMarker = async (markerData: MarkerData) => {
        try {
            const markerRef = await addEpgMarker(markerData, this)
            const marker = new Marker(markerRef, markerData, this)

            this.setMarkers = this.getMarkers.concat(marker)

            alertSuccess(i18n.t('Marker added'))
        } catch (e) {
            alertError(i18n.t('Failed to add markerEntity'))
            console.error(e)
        }
    }

    deleteMarker = async (marker: Marker) => {
        try {
            await deleteEpgMarker(this, marker)
            this.setMarkers = this.getMarkers.filter(markerFilter => markerFilter.getId !== marker.getId)

            alertSuccess(i18n.t('MarkerDeleted'))
        } catch (e) {
            alertError(i18n.t('MarkerDeleted'))
            console.error(e)
        }
    }

    updateChannelName = async (name: string) => {
        const temp = this.getName

        try {
            this.setName = name

            await updateTvChannel(this, { name })

            alertSuccess('TV channel name updated')
        } catch (e) {
            this.setName = temp
            alertError('Failed to update TV channel name')
            console.error(e)
        }
    }

    loadMarkers = async () => {
        if (this.markersInitialized) {
            return
        }

        try {
            this.setMarkers = await listMarkers(this)
            this.markersInitialized = true
        } catch (e) {
            alertError(i18n.t('Failed to fetch markers'))
        }
    }

    listPrograms = async () => {
        if (this.programsInitialized) {
            return
        }

        this.loading = true

        try {
            this.setPrograms = await listPrograms(this)
            this.programsInitialized = true
        } catch (e) {
            alertError(i18n.t('Failed to fetch programs'))
            console.error(e)
        } finally {
            this.loading = false
        }
    }

    goChannelPage = () => {
        goEpgChannelPage(this.getId)
    }

    get getPrograms() {
        return this.programs
    }

    get getName() {
        return typeof this._data.name === 'string' ? this._data.name : this._data.name[LangCode.CS] ?? ''
    }

    get getRef() {
        return this.ref
    }

    get getId() {
        return this.ref.id
    }

    get getChannelKey() {
        return this._data.channelKey
    }

    get getPublishedStatus() {
        return this._data.publishedStatus
    }

    get getMarkers() {
        return this.markers
    }

    get assets() {
        return this._data.assets
    }

    get id() {
        return this.ref.id
    }

    get name() {
        return this._data.name
    }

    get getCover(): string {
        return this.getCoverAsset()
    }

    private getCoverAsset(): string {
        return getVideoCoverAsset(this.assets ?? {}) ?? ''
    }

    get description() {
        return this._data.description
    }

    get itemType() {
        return ROW_ITEM_TYPES.TV_CHANNEL
    }

    get created(): FirestoreTimestamp | null {
        return this._data.created ?? null
    }

    get filters(): string[] {
        return this._data.filters ?? []
    }

    get organizationRef(): firebase.firestore.DocumentReference {
        return this._data.organizationRef
    }

    set setName(name: string | Translation) {
        this._data.name = name
    }

    set setPrograms(programs: Program[]) {
        this.programs = programs
    }

    set setMarkers(markers: Marker[]) {
        this.markers = markers
    }

    get type() {
        return this._data.type
    }
}

export default TvChannel
