import { ApprovedConsentDocument, ConsentDocument } from '@tivio/firebase'

import Logger from '../../logger'
import store from '../../store'
import { Consent } from '../../store/Consent'
import Organization from '../../store/Organization'
import { getFirestore, getFunctions, loggerFirestore } from '../app'
import { notEmptyFilter } from '../../utils/array.utils'


const logger = new Logger('consent')

export const getApprovedConsentsCollection = (organization: Organization) => {
    return getFirestore()
        .collection(`${organization.ref.path}/approvedConsents`)
        .withConverter({
            fromFirestore: (snapshot) => {
                return snapshot.data() as ApprovedConsentDocument
            },
            toFirestore: (approvedConsent) => {
                return approvedConsent
            },
        })
}

const getConsentsCollection = () => {
    return getFirestore()
        .collection('consents')
        .withConverter({
            fromFirestore: (snapshot) => {
                return snapshot.data() as ConsentDocument
            },
            toFirestore: (consent) => {
                return consent
            },
        })
}

/**
 * Obtains array of latest consent documents from organizations that are required for the current organization,
 * which have not been approved yet.
 * @param organization Organization entity instance
 */
export const loadRequiredConsents = async (organization: Organization, shouldRequireConsents?: boolean) => {
    const { requiredConsentOrgRefs } = organization
    const consentsCollection = getConsentsCollection()
    const latestConsentDocuments = requiredConsentOrgRefs
        ? await Promise.all(
            requiredConsentOrgRefs.map(async (organizationRef) => {
                const consentSnapshot = await consentsCollection.where(
                    'organizationRef',
                    '==',
                    organizationRef,
                ).orderBy('date', 'desc').limit(1).get()
                if (consentSnapshot.empty) {
                    logger.error(
                        `Consent not found for organization ${organizationRef.path}`,
                    )
                }
                return consentSnapshot.docs[0]
            }),
        )
        : []

    if (latestConsentDocuments.length === 0) {
        return
    }

    const approvedConsentsCollection = getApprovedConsentsCollection(
        organization,
    )
    const approvedConsents = await approvedConsentsCollection.where(
        'consentDocumentRef',
        'in',
        latestConsentDocuments.map((doc) => doc.ref),
    ).get()
    const missingConsents = latestConsentDocuments.filter((doc) =>
        !approvedConsents.docs.some((approvedConsent) =>
            approvedConsent.data().consentDocumentRef.id === doc.id,
        ),
    )

    organization.approvedConsentOrgIds = approvedConsents.docs.map((doc) => {
        const approvedConsent = doc.data()
        const consentDoc = latestConsentDocuments.find((consentDoc) =>
            consentDoc.id === approvedConsent.consentDocumentRef.id,
        )

        return consentDoc?.data().organizationRef.id
    }).filter(notEmptyFilter)

    if (shouldRequireConsents) {
        organization.requiredConsents = missingConsents.map((doc) =>
            new Consent(
                doc.ref,
                doc.data(),
            ),
        )
    }
}

export const approveConsent = async (
    organization: Organization,
    consentId: string,
) => {
    const member = store.getMember

    if (!member) {
        throw new Error('Member not found')
    }

    try {
        const approveConsentCloudFn = getFunctions().httpsCallable(
            'approveConsent',
        )

        const response: { data: { success: boolean, isNewAdmin: boolean } } = await approveConsentCloudFn({
            organizationId: organization.id,
            consentId,
        })

        loggerFirestore.info(
            `Consent with ID ${consentId} approved by ${member.getUid}`,
        )

        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to approve consent', e)
        throw new Error(e)
    }
}
