import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: '1rem',
    },
    text: {
        fontSize: '0.8125rem',
        textTransform: 'uppercase',
    },
    container: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))

interface AddStrategyProps {
    handleAddStrategy: () => void
}

export const AddStrategy: React.FC<AddStrategyProps> = ({ handleAddStrategy }) => {
    const classes = useStyles()
    const [t] = useTranslation()

    return (
        <div className={classes.container}>
            <IconButton
                size="small"
                onClick={handleAddStrategy}
            >
                <Add className={classes.icon} /><span className={classes.text}>{t('Add strategy')}</span>
            </IconButton>
        </div>
    )
}
