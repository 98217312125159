import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'

import { AddScheduleItemButton } from './AddScheduleItemButton'
import { TIMELINE_HEIGHT_PX } from './ScheduleTimeline'


const DEFAULT_AREA_HEIGHT_PX = 16
const DEFAULT_AREA_EXTENSION_PX = 16

const AddItemAreaContent = styled.div<{ show?: boolean }>`
    display: ${props => props.show ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    position: relative;
`

const AddItemArea = styled.div<{height: number, topExtension: number, bottomExtension: number}>`
    width: 100%;
    height: ${props => props.height + props.topExtension + props.bottomExtension}px;
    margin-bottom: -${props => props.bottomExtension}px;
    margin-top: -${props => props.topExtension}px;
    z-index: 2;
    position: relative;

    &:hover ${AddItemAreaContent} {
        display: flex
    }
`

const AddItemAreaLine = styled.div`
    position: absolute;
    width: 100%;
    height: ${TIMELINE_HEIGHT_PX}px;
    background-color: ${COLORS.EPG_LINE};
`

type Props = {
    /**
     * On add button click function
     */
    onButtonClick: () => void
    /**
     * Visible area height.
     * Real height is affected by topExtension/bottomExtension
     */
    height?: number
    /**
     * Used to increase on-hover area on top.
     */
    topExtension?: number
    /**
     * Used to increase on-hover area on bottom.
     */
    bottomExtension?: number
    /**
     * If false, then component has default behavior, so is shown on hover.
     * If true, then is always shown.
     */
    show?: boolean
}

/**
 * Area between items with Add schedule item button.
 */
export const AddScheduleItemArea = ({
    onButtonClick,
    height = DEFAULT_AREA_HEIGHT_PX,
    topExtension = DEFAULT_AREA_EXTENSION_PX,
    bottomExtension = DEFAULT_AREA_EXTENSION_PX,
    show = false,
}: Props) => {
    return (
        <AddItemArea
            height={height}
            topExtension={topExtension}
            bottomExtension={bottomExtension}
        >
            <AddItemAreaContent show={show}>
                <AddItemAreaLine/>
                <AddScheduleItemButton onClick={onButtonClick}/>
            </AddItemAreaContent>
        </AddItemArea>
    )
}
