import { createLogger, notEmptyFilter } from '@tivio/common'
import { makeObservable, observable } from 'mobx'

import type firebase from 'firebase/app'

/**
 * @public
 */
export interface IRefsPagination<FirestoreData, Class> {
    fetchMore: () => Promise<void>
    fetchData: () => Promise<void>
    addItem: (item: Class, ref: firebase.firestore.DocumentReference<FirestoreData>) => void
    getItem: (itemId: string) => Promise<Class | undefined>
    getCurrentItems: Class[]
    getItems: Class[]
    getRefs: firebase.firestore.DocumentReference<FirestoreData>[]
    getHasNextPage: boolean
    getItemsCount: number
}

const logger = createLogger('RefsPagination')

/**
 * T - Item firestore data
 * G - Item application instance
 * usage example - services/apps/admin/src/store/Organization.ts
 * @public
 */
export class RefsPagination<FirestoreData, Class> implements IRefsPagination<FirestoreData, Class> {
    private items: Class[] = []
    private currentItems: Class[] = []
    private currentPage = 0
    private hasNextPage = true
    private loading = false
    private error: Error | null = null

    constructor(
        private refs: firebase.firestore.DocumentReference<FirestoreData>[],
        private creator: (ref: firebase.firestore.DocumentReference<FirestoreData>, data: FirestoreData) => Class,
        private initialized = true,
        private limit = 10,
        fetchInitialData = true,
    ) {
        if (this.limit > 1000) {
            throw new Error('Limit cannot be greater than 1000')
        }

        fetchInitialData && this.fetchData()

        makeObservable<any>(this, {
            items: observable,
            refs: observable,
            loading: observable,
            error: observable,
            hasNextPage: observable,
        })
    }

    fetchMore = async () => {
        if (this.isLoading) {
            return
        }

        this.currentPage++

        await this.fetchData()
    }

    fetchData = async () => {
        if (this.isLoading || !this.hasNextPage) {
            return
        }

        try {
            const start = this.currentPage * this.limit
            const end = start + this.limit

            const currentItems = this.items.slice(start, end)

            if (currentItems.length === 0) {
                const currentRefs = this.refs.slice(start, end)

                if (end >= this.refs.length) {
                    this.hasNextPage = false
                }

                this.startLoading()

                const items = await Promise.all(
                    currentRefs.map(
                        async ref => {
                            const snapshot = await ref.get()
                            const data = snapshot.data()

                            if (!data) {
                                return null
                            }

                            return this.creator(ref, data)
                        },
                    ),
                )

                const itemsFiltered = items.filter(notEmptyFilter)

                this.endLoading()
                this.setCurrentItems = itemsFiltered
                this.setItems = this.items.concat(itemsFiltered)
            } else {
                // TODO - at this we don't have pagination with page tabs
            }
        } catch (error) {
            logger.error(error)
            this.setError = error as Error
        } finally {
            this.endLoading()
        }
    }

    addItemToStart = (item: Class, ref: firebase.firestore.DocumentReference<FirestoreData>) => {
        this.refs = this.refs.concat([ref])
        this.getItems.unshift(item)
    }

    addItem = (item: Class, ref: firebase.firestore.DocumentReference<FirestoreData>) => {
        if (this.refs.length === this.items.length) {
            this.setItems = this.items.concat([item])
        }

        this.refs = this.refs.concat([ref])
    }

    /**
     * @deprecated - bad caching
     * @param itemId
     */
    getItem = async (itemId: string) => {
        const itemRef = this.refs.find(item => item.id === itemId)

        if (itemRef) {
            const itemSnapshot = await itemRef.get()
            const itemData = itemSnapshot.data()

            if (itemData) {
                return this.creator(itemSnapshot.ref, itemData)
            }
        }
        else {
            throw new Error('ref-not-found')
        }
    }

    startLoading() {
        this.setLoading = true
    }

    endLoading() {
        this.setLoading = false
    }

    get getError() {
        return this.error
    }

    get getCurrentItems() {
        return this.currentItems
    }

    get getItems() {
        return this.items
    }

    get getRefs() {
        return this.refs
    }

    get getHasNextPage() {
        return this.hasNextPage
    }

    get isLoading() {
        return this.loading
    }

    get getItemsCount() {
        return this.refs.length
    }

    get isInitialized() {
        return this.initialized
    }

    set setItems(items: Class[]) {
        this.items = items
    }

    set setLoading(loading: boolean) {
        this.loading = loading
    }

    set setCurrentItems(items: Class[]) {
        this.currentItems = items
    }

    set setIsInitialized(isInitialized: boolean) {
        this.initialized = isInitialized
    }

    set setError(error: Error) {
        this.error = error
    }
}
