import { TextField } from '@material-ui/core'
import { numberComparator } from '@tivio/common'
import { AvailableSeason, AvailableSeasonField, TagAvailableSeasonsFieldType } from '@tivio/types'
import React, { useEffect, useState } from 'react'


interface Props {
    metadataField: TagAvailableSeasonsFieldType
    onChange: (value: string) => void
}

/**
 * Tag metadata form field for AVAILABLE_SEASONS type.
 * Editable field with known side effects. Customers trained for it.
 */
export const AvailableSeasonsTagMetadataField = ({ metadataField, onChange }: Props) => {
    const initialValue = metadataField.value
        ?.map((s: AvailableSeasonField) => s.seasonNumber)
        ?.sort(numberComparator)
        ?.join(', ') ?? ''

    const [valueSeasons, setValueSeasons] = useState<string>(initialValue)

    useEffect(() => {
        onChange(valueSeasons)
        // We want to call only on mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event: any) => {
        setValueSeasons(event.target.value.toString())
        onChange(event.target.value.toString())
    }

    return (
        <TextField
            variant="outlined"
            size="small"
            label={metadataField.key}
            fullWidth
            value={valueSeasons}
            onChange={handleChange}
        />
    )
}
