import { createLogger } from '@tivio/common'
import { useEffect, useState } from 'react'

import { getArticleById } from '../../../firebase/firestore/content'
import { Article } from '../../../store/Article'
import { useError } from '../uiHooks/useError'

import { useOrganization } from './useOrganization'


const logger = createLogger('useArticle')

export const useArticle = (articleId: string) => {
    const { organization } = useOrganization()
    const [article, setArticle] = useState<Article | null>(null)
    const { error, raiseError } = useError()

    useEffect(
        () => {
            if (!organization?.ref) {
                logger.error('Organization is not defined')
                return
            }
            (async () => {
                try {
                    const article = await getArticleById(articleId, organization)

                    if (!article) {
                        raiseError('article-not-found')
                        return
                    }

                    setArticle(article)
                } catch (e) {
                    raiseError('article-not-found')
                    logger.error(e)
                }
            })()
        },
        [articleId, organization],
    )

    return {
        error,
        article,
    }
}
