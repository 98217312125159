import DayjsUtils from '@date-io/dayjs'
import { CssBaseline } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useEffect, useState } from 'react'

import packageJson from '../../package.json'
import { authorizeUserOnStart, isAuthorized } from '../firebase/auth'

import AppRouter from './AppRouter'
import { AppThemeProvider } from './AppThemeProvider'
import useTranslator from './hooks/uiHooks/useTranslator'


dayjs.extend(utc)
console.log(`${packageJson.name} - ${packageJson.version}`)

/**
 * App container component
 */
function App() {
    const [loading, setLoading] = useState<boolean>(true)
    const translator = useTranslator()

    /**
     * Authorize user on app start
     */
    useEffect(() => {
        (async () => {
            await translator.init()
            await authorizeUserOnStart()

            setLoading(false)
        })()
    }, [])

    if (loading) {
        return null
    }

    return (
        <div
            data-e2e="app"
            data-e2eloggedin={isAuthorized()}
        >
            <MuiPickersUtilsProvider utils={DayjsUtils}>
                <AppThemeProvider type="dark">
                    <CssBaseline />
                    <AppRouter />
                </AppThemeProvider>
            </MuiPickersUtilsProvider>
        </div>
    )
}

export default App
