import { Box, Button, Container, Grid } from '@material-ui/core'
import { OrganizationCompanyInfoField } from '@tivio/firebase'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as Yup from 'yup'

import { AssetPreset } from '../../store/AssetPreset'
import { AssetUploaderItem } from '../asset/assetUploader'
import { useOrganization } from '../hooks'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { InputGroup } from './InputGroup'


export const PaymentInfoTab = observer(() => {
    const { organization } = useOrganization()
    const [t] = useTranslation()

    const { values, errors, setFieldValue, handleSubmit, isSubmitting } = useFormik<OrganizationCompanyInfoField>({
        initialValues: {
            name: organization?.companyInfo?.name ?? '',
            address: organization?.companyInfo?.address ?? '',
            vat: organization?.companyInfo?.vat ?? '',
            iban: organization?.companyInfo?.iban ?? '',
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('This field is required')),
            address: Yup.string().required(t('This field is required')),
            vat: Yup.string(),
            iban: Yup.string().required(t('This field is required')),
        }),
        enableReinitialize: false,
        onSubmit: async (values) => {
            await organization?.updateCompanyInfo({
                address: values.address,
                iban: values.iban,
                name: values.name,
                vat: values.vat,
            })
        },
    })

    const item: AssetUploaderItem | undefined = useMemo(
        () =>
            organization
                ? {
                    getAsset: organization.getAsset,
                    getTempFilePath: (preset: AssetPreset, ext: string) => `${organization.ref.path}/${preset.name}.${ext}`,
                    getDocumentPath: () => organization.ref.path,
                    updateItem: organization.saveAssetResizeResults,
                }
                : undefined,
        [organization],
    )

    if (!item) {
        return null
    }

    return (
        <Container maxWidth="lg">
            <form onSubmit={handleSubmit}>
                <Box maxWidth={430}>
                    <Column spacing={2}>
                        <InputGroup
                            label={t('Company name')}
                            value={values.name}
                            error={errors.name}
                            name="name"
                            onChange={(value) => setFieldValue('name', value)}
                        />
                        <InputGroup
                            label={t('Address')}
                            value={values.address}
                            error={errors.address}
                            name="address"
                            onChange={(value) => setFieldValue('address', value)}
                        />
                        <InputGroup
                            label={t('VAT (optional)')}
                            value={values.vat ?? ''}
                            error={errors.vat}
                            name="vat"
                            onChange={(value) => setFieldValue('vat', value)}
                        />
                        <InputGroup
                            label={t('IBAN')}
                            value={values.iban}
                            error={errors.iban}
                            name="iban"
                            onChange={(value) => setFieldValue('iban', value)}
                        />
                        <Row
                            rowProps={{
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={isSubmitting}
                                >
                                    {t('Save')}
                                </Button>
                            </Grid>
                        </Row>
                    </Column>
                </Box>
            </form>
        </Container>
    )
})
