import { Container } from '@material-ui/core'
import styled, { css } from 'styled-components'


const StyledMonetizationPageContainer = styled(
    props => (
        <Container
            {...props}
            maxWidth="lg"
        />
    ),
)`
    padding-top: 3rem;
`

const StylesTransition = css`
    transition: all .2s ease-in-out;
`

const StyledHover = styled.div<{ opacity?: number }>`
    cursor: pointer;
    border-radius: .5rem;
    ${StylesTransition}

    :hover {
        background-color: rgba(255, 255, 255, ${p => p.opacity ?? 0.08});
    }
`

export {
    StyledMonetizationPageContainer,
    StyledHover,
    StylesTransition,
}
