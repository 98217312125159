import { MARKER_TYPES } from '../../static/enum'
import Marker from '../../store/Marker'
import TvChannel from '../../store/TvChannel'
import { secondsToFirebaseTimestamp } from '../../utils/time.utils'
import { FirebaseDocumentData, FirestoreTimestamp, getFirestore, loggerFirestore } from '../app'

import { markerEpgConverter } from './converters'


export interface MarkerVodFirestore {
    type: MARKER_TYPES
    fromMs: number
    toMs: number
    name?: string
}

export interface MarkerEpgFirestore {
    type: MARKER_TYPES
    from: FirestoreTimestamp
    to: FirestoreTimestamp
    name?: string
}

export interface MarkerData {
    /**
     * Marker type.
     */
    type: MARKER_TYPES
    /**
     * Marker start time in seconds with three dicimal digits.
     * I.e. `123.456`.
     */
    from: number
    /**
     * Marker end time in seconds with three dicimal digits.
     * I.e. `123.456`.
     */
    to: number
    /**
     * Customized marker name.
     */
    name?: string
    markerEntity?: Marker
}

const getEpgMarkersCollection = (tvChannel: TvChannel) => {
    return getFirestore().collection(`tvChannels/${tvChannel.getId}/markers`).withConverter(markerEpgConverter)
}

const addEpgMarker = async (markerData: MarkerData, tvChannel: TvChannel) => {
    try {
        const markerRef = await getEpgMarkersCollection(tvChannel).add(markerData)

        loggerFirestore.info('Marker added with id: ', markerRef.id)

        return markerRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add markerEntity', e)
        throw new Error(e)
    }
}

const deleteEpgMarker = async (tvChannel: TvChannel, marker: Marker) => {
    try {
        await getEpgMarkersCollection(tvChannel).doc(marker.getId).delete()

        loggerFirestore.info('Marker deleted')
    }
    catch (e) {
        loggerFirestore.error('Failed to delete markerEntity', e)
        throw new Error(e)
    }
}

const updateEpgMarker = async (marker: Marker, markerData: Partial<MarkerData>) => {
    try {
        if (marker.getTvChannel) {
            const updateModel: FirebaseDocumentData = {}

            if (markerData.type) {
                updateModel.type = markerData.type
            }

            if (markerData.from !== undefined) {
                updateModel.from = secondsToFirebaseTimestamp(markerData.from)
            }

            if (markerData.to) {
                updateModel.to = secondsToFirebaseTimestamp(markerData.to)
            }

            if (markerData.name) {
                updateModel.name = markerData.name
            }

            await getEpgMarkersCollection(marker.getTvChannel).doc(marker.getId).update(updateModel)

            loggerFirestore.info('Marker updated')
        }
    }
    catch (e) {
        loggerFirestore.error('Failed to update markerEntity', e)
        throw new Error(e)
    }
}

export {
    addEpgMarker,
    getEpgMarkersCollection,
    deleteEpgMarker,
    updateEpgMarker,
}
