import { Logger } from '@tivio/common'
import { NotificationFilterType, NotificationStatus, NotificationTriggerType, PurchaseStatus } from '@tivio/types'
import { makeAutoObservable, runInAction } from 'mobx'
import * as yup from 'yup'

import { firebaseTimestampFromDate } from '../firebase/app'
import { getTemplateRef } from '../firebase/firestore/emailing'
import { getMembershipRef } from '../firebase/firestore/membership'
import { getMonetizationRef } from '../firebase/firestore/monetization'
import { FieldErrors, transformYupErrorsIntoObject } from '../utils/formik.utils'
import { firebaseTimestampToDate } from '../utils/time.utils'
import { translate } from '../utils/translate.utils'

import { NotificationSubstitution } from './NotificationSubstitution'
import store from './store'
import { Template } from './Template'

import type Organization from './Organization'
import type { EmailSenderField, NotificationDocument, NotificationDurationField, PurchaseStatusChangeField } from '@tivio/firebase'
import type firebase from 'firebase/app'


// const getValidationSchema = (type: NotificationTriggerType) => {
//     switch() {

//     }
// }

const validationSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    sender: yup.object().nullable().required('Sender is required'),
    targeting: yup.object().shape({
        // isActive: yup.boolean().required(),
        // type: yup.string().oneOf(['membership', 'monetization']).required(),
        membershipRef: yup.object().when('type', {
            is: 'membership',
            then: yup.object().required('Membership is required'),
            otherwise: yup.object().nullable(),
        }),
        monetizationRef: yup.object().when('type', {
            is: 'monetization',
            then: yup.object().required('Monetization is required'),
            otherwise: yup.object().nullable(),
        }),
    }).nullable(),//.required('Targeting is required'),
    // duration
})

export class TivioNotification {
    private _substitutions: NotificationSubstitution[] = []
    private _errors: FieldErrors<NotificationDocument> = {}
    private _subscription?: () => void
    private _logger: Logger = new Logger('TivioNotification')

    constructor(
        private _ref: firebase.firestore.DocumentReference<NotificationDocument>,
        public data: NotificationDocument,
        private _template: Template,
        public organization: Organization,
    ) {
        makeAutoObservable(this)

        this.createSubstitutionsFromData()
    }

    save = async () => {
        const { status } = this.data
        if (status === NotificationStatus.RUNNING) {
            throw new Error(translate('Cannot save running notification'))
        }

        if (status === NotificationStatus.SENT) {
            throw new Error(translate('Cannot save sent notification'))
        }

        const newData = {
            ...this.data,
            status: this.getUpdatedNotificationStatus(),
            substitutions: this._substitutions.map((substitution) => substitution.data),
        }

        const isValid = await this.validate(newData)
        if (!isValid) {
            console.error('Validation failed', this._errors)
            throw new Error(Object.values(this._errors).join('\n'))
        }

        this.data = newData
        await this._ref.update(this.data)
    }

    createSubstitutionsFromData = () => {
        this._substitutions = this._template.substitutions.map((templateSubstitutionField) => {
            const notificationSubstitution = this.data.substitutions.find((item) => item.key === templateSubstitutionField.key)
            return new NotificationSubstitution(templateSubstitutionField, notificationSubstitution, this.id, this.ref.path)
        })
    }

    validate = async (newData: NotificationDocument) => {
        try {
            console.log('newData', newData)
            await validationSchema.validate(newData, { abortEarly: false })
            return true
        } catch (error) {
            this._errors = error.errors
            console.log('Validation failed', transformYupErrorsIntoObject<NotificationDocument>(error))
            return false
        }
    }

    /**
     * Subscribe to DB snapshot. Starts listening to changes.
     */
    subscribe = () => {
        if (!this._subscription) {
            this._subscription = this.ref.onSnapshot(data => {
                runInAction(() => {
                    if (!data.exists) {
                        this._logger.error('Notification document does not exist anymore')
                    }
                    this.data = data.data() as NotificationDocument
                })
            })
            this._logger.info('Subscribed from notification', this.id)
        }
    }

    /**
     * Unsubscribe from DB snapshot. Stops listening for changes.
     */
    unsubscribe = () => {
        if (this._subscription) {
            this._subscription()
            this._subscription = undefined
            this._logger.info('Unsubscribed from notification', this.id)
        }
    }

    private getUpdatedNotificationStatus = () => {
        const { lastSent, status, scheduleTime, triggerType } = this.data
        if (lastSent) {
            return status
        }
        if (scheduleTime && triggerType === 'scheduled') {
            return NotificationStatus.PLANNED
        }
        if (triggerType === 'manual') {
            return NotificationStatus.WAITING
        }
        return NotificationStatus.AUTOMATIC
    }

    get created() {
        return firebaseTimestampToDate(this.data.created)?.toDate() ?? new Date()
    }

    get errors() {
        return this._errors
    }

    get id() {
        return this.ref.id
    }

    get ref() {
        return this._ref
    }

    get subject() {
        return this.data.subject ?? this._template.subject
    }

    set subject(subject: string) {
        this.data.subject = subject
    }

    get preHeader() {
        return this.data.preHeader ?? this._template.preHeader ?? ''
    }

    set preHeader(preHeader: string | undefined) {
        this.data.preHeader = preHeader
    }

    get name() {
        return this.data.name
    }

    set name(name: string) {
        this.data.name = name
    }

    get sender() {
        return this.data.sender
    }

    set sender(sender: EmailSenderField | undefined) {
        this.data.sender = sender
    }

    get sentCount() {
        return this.data.sentCount
    }

    get lastSent() {
        return firebaseTimestampToDate(this.data.lastSent)?.toDate()
    }

    get purchaseStatus() {
        return this.data.purchaseStatus
    }

    get filters() {
        return this.data.filters ?? []
    }

    set filters(filters: NotificationFilterType[]) {
        this.data.filters = filters
    }

    set purchaseStatus(purchaseStatus: PurchaseStatus | undefined) {
        this.data.purchaseStatus = purchaseStatus
    }

    get purchaseStatusChange() {
        return this.data.purchaseStatusChange
    }

    set purchaseStatusChange(purchaseStatusChange: PurchaseStatusChangeField | undefined) {
        this.data.purchaseStatusChange = purchaseStatusChange
    }

    get template() {
        return this._template
    }

    set template(template: Template) {
        this._template = template
        this.createSubstitutionsFromData()
    }

    get targeting() {
        return this.data.targeting
        // const { targeting } = this.data
        // if (!targeting) {
        //     return null
        // }

        // const { monetizationRef, from, to, ...rest } = targeting
        // return {
        //     ...rest,
        //     from: firebaseTimestampToDate(from)?.toDate(),
        //     to: firebaseTimestampToDate(to)?.toDate(),
        //     monetizationId: monetizationRef?.id,
        // }
    }

    set targeting(targeting: NotificationDocument['targeting'] | null) {
        this.data.targeting = targeting
    }

    set targetMonetizationId(monetizationId: string | null) {
        const { targeting } = this.data
        if (!monetizationId) {
            this.data.targeting = null
            return
        }
        this.data.targeting = {
            ...targeting,
            isActive: targeting?.isActive ?? true,
            membershipRef: null,
            monetizationRef: getMonetizationRef(monetizationId),
            type: 'monetization',
            isVoucher: targeting?.isVoucher ?? false,
        }
    }

    set targetMembershipId(membershipId: string | null) {
        const { targeting } = this.data
        if (!membershipId) {
            this.data.targeting = null
            return
        }
        this.data.targeting = {
            ...targeting,
            isActive: targeting?.isActive ?? true,
            monetizationRef: null,
            membershipRef: getMembershipRef(membershipId),
            type: 'membership',
            isVoucher: targeting?.isVoucher ?? false,
        }
    }

    get targetIsActive() {
        return this.data.targeting?.isActive ?? false
    }

    set targetIsActive(isActive: boolean) {
        const { targeting } = this.data
        if (!targeting) {
            return
        }
        this.data.targeting = {
            ...targeting,
            isActive,
        }
    }

    get targetIsVoucher() {
        return this.data.targeting?.isVoucher ?? false
    }

    set targetIsVoucher(isVoucher: boolean) {
        const { targeting } = this.data
        if (!targeting) {
            return
        }
        this.data.targeting = {
            ...targeting,
            isVoucher,
        }
    }

    set targetFrom(from: Date | undefined) {
        // const { targeting } = this.data
        // if (!targeting) {
        //     return
        // }
        // const { to } = targeting
        // if (to && from && from > to.toDate()) {
        //     store.getAlert.showErrorAlert(translate('From date must be before to date'))
        //     return
        // }
        // this.data.targeting = {
        //     ...targeting,
        //     from: from ? firebaseTimestampFromDate(from) : undefined,
        // }
    }

    set targetTo(to: Date | undefined) {
        // const { targeting } = this.data
        // if (!targeting) {
        //     return
        // }
        // const { from } = targeting
        // if (from && to && from.toDate() > to) {
        //     store.getAlert.showErrorAlert(translate('To date must be after from date'))
        //     return
        // }
        // this.data.targeting = {
        //     ...targeting,
        //     to: to ? firebaseTimestampFromDate(to) : undefined,
        // }
    }

    get templateId() {
        return this.data.templateRef.id
    }

    set templateId(templateId: string) {
        const template = this.organization.emailTemplates?.find((template) => template.templateId === templateId)
        if (!template) {
            return
        }
        this.data.templateRef = getTemplateRef(template.id)
        this.template = template
    }

    get thumbnailUrl() {
        return this.template.thumbnailUrl
    }

    get triggerType() {
        return this.data.triggerType
    }

    set triggerType(triggerType: NotificationTriggerType) {
        this.data.triggerType = triggerType
    }

    get status() {
        return this.data.status
    }

    get scheduleTime() {
        return firebaseTimestampToDate(this.data.scheduleTime)?.toDate()
    }

    set scheduleTime(scheduleTime: Date | undefined) {
        if (scheduleTime && scheduleTime < new Date()) {
            store.getAlert.showErrorAlert(translate('Schedule time must be in the future'))
            return
        }
        this.data.scheduleTime = scheduleTime ? firebaseTimestampFromDate(scheduleTime) : undefined
    }

    get substitutions() {
        return this._substitutions
    }

    get enabled() {
        return this.data.enabled
    }

    set enabled(enabled: boolean) {
        this.data.enabled = enabled
    }

    get duration() {
        return this.data.duration
    }

    set duration(duration: NotificationDurationField | undefined) {
        this.data.duration = duration
    }
}
