import { makeAutoObservable } from 'mobx'


import { TVersionFirestore } from '../firebase/firestore/versions'
import { getUniqueArray } from '../utils/array.utils'
import { isValidSemVer, SemVer, semVerComparer, toSemVer } from '../utils/semanticVersion.utils'

import Version from './Version'

import type { DeviceFirestore } from '../firebase/firestore/device'
import type { TSecretFirestore } from '../firebase/firestore/secrets'
import type Target from './Target'
import type firebase from 'firebase/app'


class Device {
    ref: firebase.firestore.DocumentReference<DeviceFirestore> | null = null
    target: Target
    id: string | null = null
    name: string | null = null
    description: string | null = null

    // environment agnostic
    versionLabels: string[] = []

    // by environment
    versions: Map<string, Version[]> = new Map()

    secretHashes: Map<string, string> = new Map()

    constructor(target: Target) {
        this.target = target
        makeAutoObservable(this)
    }

    async fromFirestore(
        id: string,
        data: DeviceFirestore,
        secrets?: (TSecretFirestore & { id: string, versions?: (TVersionFirestore & { id: string })[] })[],
    ) {
        this.id = id
        this.name = data.name ?? null
        this.description = data.description ?? null
        let allVersions: { semVer: SemVer | null, ref: TVersionFirestore & { id: string } }[] = []

        await Promise.all((secrets ?? []).map(async (secret) => {
            const envDoc = await secret.environmentRef.get()
            const envId = envDoc.id
            const envData = envDoc.data()
            if (!envData) {
                throw new Error(`Secret ${secret.id} without any environment`)
            }
            const secretVersions = secret.versions

            if (!secretVersions) {
                throw new Error(`Secret ${secret.id} without any versions`)
            }

            // prepare semantic versions
            const semVersions = (await Promise.all(
                secretVersions.map(async (ref) => ({
                    semVer: toSemVer(ref.id),
                    ref,
                })),
            ))

            // sort Versions by semantic rules
            const sortedValidSemVersions = semVersions
                .filter(({ semVer }) => isValidSemVer(semVer))
                .sort((a, b) => semVerComparer(a.semVer!, b.semVer!))

            allVersions = allVersions.concat(sortedValidSemVersions)

            // create nested stores
            const versions = await Promise.all(sortedValidSemVersions.map(async ({ ref }) => {
                const data = ref
                if (!data) {
                    throw new Error()
                }
                const version = new Version(this)
                await version.fromFirestore(ref.id, secret.id, data)
                return version
            }))

            this.versions.set(envId, versions)
            this.secretHashes.set(envId, secret.id)
        }))

        // format as labels
        this.versionLabels = getUniqueArray(allVersions
            .sort((a, b) => semVerComparer(a.semVer!, b.semVer!))
            .map(({ semVer }) => semVer!.join('.')))
    }

    get getBundles () {
        return this.versionLabels
    }

    getSecretHashByEnv (envId: string): string | null {
        return this.secretHashes.get(envId) ?? null
    }

}


export default Device
