import { Box, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'
import { goBack } from '../../utils/history.utils'


const useStyles = makeStyles(() => ({
    header: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
    },
    title: {
        textAlign: 'center',
    },
}))

type THeaderComponentProps = {
    onSave: () => Promise<void> | void
    disabled?: boolean
    title?: string
}

export const MonetizationPageHeader: React.FC<THeaderComponentProps> = (props) => {
    const { onSave, disabled } = props

    const classes = useStyles()
    const [ t ] = useTranslation()

    return (
        <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className={classes.header}
        >
            <Grid
                item
            >
                <Box p={3}>
                    <Button
                        startIcon={<ArrowBackIos/>}
                        onClick={goBack}
                        size="small"
                    >
                        {t('Back')}
                    </Button>
                </Box>
            </Grid>

            {(props.title) && (
                <Grid item xs className={classes.title}>
                    {props.title}
                </Grid>
            )}

            <Grid item>
                <Box p={3}>
                    <Button
                        onClick={onSave}
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                    >
                        {t('Save')}
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}

export default MonetizationPageHeader
