import {
    OrganizationMemberRoles,
    VideoContentDocument,
    VideoDocument,
    VideoEpgProcessingStatus,
    VideoProcessingStatus,
    VideoProcessingType,
} from '@tivio/firebase'
import { PublishedStatus, VideoType } from '@tivio/types'
import firebase from 'firebase'
import i18n from 'i18next'
import * as yup from 'yup'
import { ValidationError } from 'yup'

import { createTranslation } from '../../creator/translation.creator'
import { MARKER_TYPES } from '../../static/enum'
import store from '../../store'
import { Article } from '../../store/Article'
import Organization from '../../store/Organization'
import { Post } from '../../store/Post'
import { Tag } from '../../store/Tag'
import Video from '../../store/Video'
import { ContentInterface } from '../../types/content'
import { firebaseTimestampFromDate, getFirestore, loggerFirestore } from '../app'
import { RefsPagination } from '../pagination'

import { loadRequiredConsents } from './consent'
import { MarkerData } from './markerEpg'
import { addVodMarkers } from './markerVod'
import { getOrganizationRef } from './organization'
import { getVideosCollection } from './video'

import type { ArticleDocument, ArticleDocumentCreation, ContentDocument, DocumentReference, OrganizationDocument } from '@tivio/firebase'


const articleConverter = {
    fromFirestore: (
        snapshot: firebase.firestore.DocumentSnapshot,
    ): ArticleDocument => {
        return snapshot.data() as ArticleDocument
    },
    toFirestore: (
        monetization: ArticleDocument,
    ): firebase.firestore.DocumentData => {
        return monetization
    },
}

const getArticlesCollection = () => {
    return getFirestore().collection('contents').withConverter(articleConverter)
}

export const getArticleById = async (
    id: string,
    organization: Organization,
) => {
    const articleSnapshot = await getFirestore().collection('contents')
        .withConverter(articleConverter).doc(id).get()
    const articleData = articleSnapshot.data()

    if (!articleData) {
        throw new Error(`Article with id ${id} not found`)
    }

    return new Article(articleSnapshot.ref, articleData, organization)
}

const getGlobalArticlesRefs = async (organization: Organization) => {
    const articlesSnapshot = await getArticlesCollection()
        .where('organizationRef', '==', organization.ref)
        .where('type', '==', 'ARTICLE')
        .orderBy('created', 'desc')
        .get()

    return articlesSnapshot.docs.map(
        (document) => document.ref,
    )
}

const getGlobalPostsRefs = async (organization: Organization) => {
    const postsSnapshot = await getArticlesCollection()
        .where('organizationRef', '==', organization.ref)
        .where('type', 'in', ['VIDEO', 'ARTICLE'])
        .where('feedVisible', '==', true)
        .orderBy('created', 'desc')
        .get()

    return postsSnapshot.docs.map(
        (document) => document.ref,
    )
}

export const createGlobalArticlesPagination = async (
    organization: Organization,
) => {
    const articlesRefs = await getGlobalArticlesRefs(organization)

    organization.globalArticlesPagination = new RefsPagination<
        ArticleDocument,
        Article
    >(
        articlesRefs,
        (ref, data) => {
            return new Article(
                ref,
                data,
                organization,
                false,
            )
        },
        true,
        30,
    )
}

export const createGlobalPostsPagination = async (
    organization: Organization,
) => {
    const postsRefs = await getGlobalPostsRefs(organization)

    organization.globalPostsPagination = new RefsPagination<
        ContentDocument,
        Post
    >(
        postsRefs,
        (ref, data) => {
            return new Post(
                ref,
                organization,
                data,
            )
        },
        true,
        30,
    )
}

export const getVideoContentDocByVideoRef = async (
    videoRef: firebase.firestore.DocumentReference,
) => {
    const videoContentSnapshot = await getFirestore().collection('contents')
        .where('videoRef', '==', videoRef).get()

    const videoContentDoc = videoContentSnapshot.docs[0]
    if (!videoContentDoc?.exists) {
        return null
    }

    const data = videoContentDoc.data() as VideoContentDocument
    return {
        ref: videoContentDoc.ref,
        data,
    }
}

export const addArticle = async (data: ArticleDocument) => {
    const articleRef = await getArticlesCollection().add(data)

    loggerFirestore.info(`Article with id ${articleRef.id} created`)

    return articleRef
}

export const createArticleData = (
    organization: Organization,
): ArticleDocumentCreation => {
    return {
        type: 'ARTICLE',
        created: firebaseTimestampFromDate(),
        name: createTranslation('Article name', organization.languages),
        description: createTranslation(
            'Article description',
            organization.languages,
        ),
        assets: {},
        blocks: [],
        organizationRef: organization.ref,
        feedVisible: true,
        publishedStatus: PublishedStatus.DRAFT,
    }
}

export const createArticle = (
    organization: Organization,
    ref: firebase.firestore.DocumentReference<ArticleDocument>,
    data: ArticleDocument,
): Article => {
    return new Article(
        ref,
        data,
        organization,
        false,
    )
}

const hasDistributionConsent = async (
    organization: Organization,
    accessTag: Tag,
) => {
    const organizationRefItem = accessTag.metadata.find((meta) =>
        meta.key === 'organizationRef',
    )
    const organizationRef = organizationRefItem
        ?.value as firebase.firestore.DocumentReference<OrganizationDocument>

    if (organizationRef) {
        const hasApprovedConsent = organization.getHasApprovedConsent(organizationRef.id)
        if (!hasApprovedConsent) {
            if (store.getMember?.getCurrentRole === OrganizationMemberRoles.SUPER_ADMIN) {
                throw new Error(i18n.t('An admin of this organization needs to accept consent before you can distribute this video'))
            }

            await organization.addRequiredConsentFromOrgId(organizationRef.id)
            await loadRequiredConsents(organization, true)
            return false
        }
    }

    return true
}

export const toggleDistribution = async (
    content: ContentInterface,
    organization: Organization,
    accessTag: Tag,
    isActive?: boolean,
) => {
    if (isActive) {
        const hasConsent = await hasDistributionConsent(organization, accessTag)
        if (!hasConsent) {
            return
        }
    }

    await content.updateTags(
        isActive
            ? [...content.tags, accessTag]
            : content.tags.filter((tag) => tag.id !== accessTag.id),
    )
}

const getDistributionValidationSchema = () => yup.object().shape({
    inputFileUrl: yup.string().required(i18n.t('Video upload must be completed before allowing distribution')),
    name: yup.string().required(i18n.t('Video name is required')),
    shortName: yup.string().required(
        i18n.t(
            'Video short name is required. It\'s used in EPG data and must be unique.',
        ),
    ),
    description: yup.string().required(i18n.t('Video description is required')),
    assets: yup.object().shape({
        cover: yup.string().nullable().required(
            i18n.t('Video cover asset is required'),
        ),
        portrait: yup.string().nullable().required(
            i18n.t('Video portrait asset is required'),
        ),
    }),
})

export const validateVideoDistribution = async (video: Video) => {
    const distributonValidationSchema = getDistributionValidationSchema()

    await distributonValidationSchema.validate({
        inputFileUrl: video.inputFileUrl,
        name: video.getName,
        shortName: video.getShortName,
        description: video.getDescription,
        assets: {
            cover: video.getCover,
            portrait: video.getAsset('portrait'),
        },
    }, {
        abortEarly: false,
    })
}

export const toggleVideoDistribution = async (
    video: Video,
    accessTag: Tag,
    isActive: boolean,
) => {
    try {
        if (!isActive) {
            throw new Error(i18n.t('Videos already added to EPG can\'t be removed'))
        }

        // If this schema is outside of this function block, the errors will be shown as 'assets.cover is a required field', etc.
        await validateVideoDistribution(video)

        const hasConsent = await hasDistributionConsent(video.organization, accessTag)
        if (!hasConsent) {
            throw new Error(
                i18n.t('You need to accept the consent to distribute this video'),
            )
        }

        const epgLockedVideosOrgRef = getOrganizationRef(
            process.env.REACT_APP_EPG_VIDEOS_ORG_ID,
        )
        if (!epgLockedVideosOrgRef) {
            throw new Error(
                i18n.t('Organization for content distribution not found'),
            )
        }

        if (video.linkedVideos === null) {
            await video.loadLinkedVideos()
        }

        const linkedEpgVideo = video.linkedVideos?.find((linkedVideo) =>
            linkedVideo.video?.getType === VideoType.VIRTUAL_PROGRAM &&
            linkedVideo.video?.tags.some((tag) => tag.id === accessTag.id),
        )
        if (linkedEpgVideo) {
            throw new Error(i18n.t('This video is already distributed'))
        }

        const batch = getFirestore().batch()

        const newVideoRef = getVideosCollection().doc()
        const { name, shortName, description, assets, created, tags, defaultName } = video.data

        const markers = video.markers.reduce((acc, marker) => {
            if (
                marker.getType === MARKER_TYPES.START ||
                marker.getType === MARKER_TYPES.END
            ) {
                acc.push({
                    name: marker.getName,
                    from: marker.from / 1000,
                    to: marker.to / 1000,
                    type: marker.getType,
                })
            }
            return acc
        }, [] as MarkerData[])

        const newVideoData: VideoDocument = {
            name,
            shortName,
            description,
            assets,
            created: created ?? firebaseTimestampFromDate(),
            organizationRef: epgLockedVideosOrgRef,
            // TODO: Delete after video bot merge to monorepo deployed
            epgProcessingStatus: VideoEpgProcessingStatus.WAITING,
            tags: [...(tags ?? []), accessTag.getRef],
            type: VideoType.VIRTUAL_PROGRAM,
            publishedStatus: PublishedStatus.UNLISTED,
            hide: false,
            defaultName,
            processing: {
                DEPLOY: {
                    status: VideoProcessingStatus.WAITING,
                    target: 'CLOUDFLARE',
                    updatedAt: firebaseTimestampFromDate(),
                    waitingForProcessing: [VideoProcessingType.TRANSCODING],
                },
                TRANSFORMATION: {
                    status: VideoProcessingStatus.WAITING,
                    type: 'DVTV_EXTRA',
                    updatedAt: firebaseTimestampFromDate(),
                },
            },
        }

        batch.set(newVideoRef, newVideoData)

        await addVodMarkers(
            newVideoRef as unknown as FirebaseFirestore.DocumentReference,
            markers,
        )
        await video.linkVideo(newVideoRef, 'VIRTUAL_PROGRAM', batch, newVideoData)
        await batch.commit()
    } catch (error) {
        let errorMessage: string
        if (error instanceof ValidationError) {
            errorMessage = error.errors.join('\n')
        } else {
            errorMessage = error.message
        }

        store.getAlert.showErrorAlert(errorMessage)
    }
}


export const setShowVideoInMagentaTV = async (videoRef: DocumentReference<VideoDocument>, profileId: string) => {
    if (!videoRef) { 
        return
    }
    const db = getFirestore()
    const profileRef = db.collection('profiles').doc(profileId) // magenta tv profile
    const contentRef = videoRef
    
    // const instanceRef = db.collection('instances').doc('r9Z2eMQ9QT4lOQVDDmK6')
    const profileData = (await profileRef.get()).data()
    if (profileData) {
        await Promise.all(Object.keys(profileData.encoding.video)
            .filter(videoTrack => videoTrack !== 'default')
            .map(async (videoTrack) => {
                const processRef = db.collection('processes').doc()
                await processRef.set({
                    'created': firebase.firestore.FieldValue.serverTimestamp(),
                    // 'instanceRef': instanceRef,
                    'payload': {
                        'contentRef': contentRef,
                        'profileRef': profileRef,
                        'videoTrack': videoTrack,
                    },
                    'progress': 0,
                    'status': 'WAITING',
                    'type': 'ENCODING_3.0',
                })
            }))
    }
    
}
