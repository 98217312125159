import { makeStyles } from '@material-ui/core/styles'
import { nanoid } from 'nanoid'
import React, { forwardRef, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import type { PropsWithChildren } from 'react'


interface Props extends PropsWithChildren {
    disabled?: boolean
    accept?: string
    onFiles?: (files: File[]) => any
    multiple?: boolean
}

const useStyles = makeStyles({
    input: {
        display: 'none',
    },
    label: {
        cursor: 'pointer',
    },
})

export const ClickableFileInput = forwardRef<HTMLInputElement, Props>((props, ref) => {
    const classes = useStyles()
    const { onFiles, children, multiple, accept, disabled } = props
    const [t] = useTranslation()

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = e.currentTarget.files
        const files = fileList ? Array.from(fileList) : []

        if (files.length > 0) {
            onFiles?.(files)
        }
    }, [disabled, onFiles])

    const id = useMemo(() => `tivio_ClickableFileInput_${nanoid()}`, [])
    const className = disabled ? '' : classes.label
    const title = disabled ? null : t('Upload')

    return (
        <>
            <input
                ref={ref}
                tabIndex={-1}
                accept={accept ?? 'image/*'}
                className={classes.input}
                id={id}
                multiple={multiple}
                onChange={onChange}
                type="file"
            />
            <label
                htmlFor={disabled ? undefined : id}
                className={className}
                title={title}
            >
                {children}
            </label>
        </>
    )
})

ClickableFileInput.displayName = 'ClickableFileInput'
