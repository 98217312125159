import { Grid, makeStyles, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { AssetPreset } from '../../../store/AssetPreset'
import { ActionButtons, Badge } from '../../common'
import { useDialogState } from '../../hooks'

import { DialogEditAssetPreset } from './dialogEditAssetPreset'
import { useCrud } from './hooks'
import { Silhouette } from './silhouette'

import type { MouseEvent } from 'react'


const StyledBadge = styled(Badge)`
    margin-left: 8px;
`

const StyledBadgeGlobal = styled(Badge)`
    margin-left: 10px;
    background: #444;
    font-size: 9px;
    font-weight: normal;
    position: relative;
    top: -16px;
    left: -8px;
    border-radius: 15px;
`

const StyledLastItem = styled(Grid)`
    margin-right: 20px;
`

interface Props {
    preset: AssetPreset
    onDelete?: (preset: AssetPreset) => any
    presetNames: string[]
}

const useStyles = makeStyles(() => ({
    containerError: {
        background: '#F004',
        borderRadius: 7,
    },
    title: {
        fontWeight: 'bold',
    },
    description: {
        marginTop: 6,
        color: '#FFF6',
        display: 'block',
    },
    descriptionError: {
        color: 'red',
    },
}))


export const PresetItemRow = observer((props: Props) => {
    const { preset, onDelete, presetNames } = props

    const onDel = useCallback((event: MouseEvent) => {
        onDelete?.(preset)
        event.stopPropagation()
    }, [ preset, onDelete ])

    const [ t ] = useTranslation()
    const classes = useStyles(props)

    const { isDialogOpen, openDialog, closeDialog } = useDialogState()
    const { update } = useCrud()

    const onConfirmDialog = useCallback((preset: AssetPreset) => {
        update(preset)
        closeDialog()
    }, [closeDialog])

    const otherNames = useMemo(() => {
        return presetNames.filter(name => name != preset.name)
    }, [preset, presetNames])

    return (
        <>
            <DialogEditAssetPreset
                onCancel={closeDialog}
                onConfirm={onConfirmDialog}
                preset={preset}
                open={isDialogOpen}
                forbiddenNames={otherNames}
            />
            <Grid
                data-e2e="asset-preset-item-row"
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={preset.valid ? undefined : classes.containerError}
                onClick={openDialog}
            >
                <Grid item>
                    <Grid
                        container
                        alignItems="center"
                        direction="row"
                        justifyContent="flex-start"
                        wrap="nowrap"
                        spacing={4}
                    >
                        <Grid item>
                            <Silhouette width={preset.width} height={preset.height} />
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" className={classes.title}>
                                {preset.name}
                                {preset.isGlobal && <StyledBadgeGlobal
                                    title={t('This preset will be applied for all organizations')}
                                >
                                    {t('Global')}
                                </StyledBadgeGlobal>
                                }
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.description}
                                title={
                                    t('Resized to {{count}} qualities', { count: preset.scaling.length })
                                + ` (${preset.getMultiQualityText()})`
                                }
                            >
                                {
                                    `${preset.getQualityText(1, ' ')}`
                                + (preset.scaling.length > 1 ? ` (${preset.scaling.length} qualities)` : '')
                                + `, ${preset.mode}`
                                }
                                <StyledBadge>{preset.getPlacementText()}</StyledBadge>
                            </Typography>
                            <Typography variant="caption" className={classes.description}>
                                {preset.description}
                            </Typography>
                            {!preset.valid &&
                            <Typography variant="h6" className={classes.descriptionError}>
                                ⚠️ {t('Something went wrong. Please delete this preset and create a new one.')}
                            </Typography>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <StyledLastItem item>
                    <ActionButtons
                        onEdit={openDialog}
                        onDelete={onDel}
                        editTitle={t('Edit - does not update images already generated using this preset')}
                        deleteTitle={t('Delete - does not remove images already generated using this preset')}
                    />
                </StyledLastItem>
            </Grid>
        </>
    )
})
