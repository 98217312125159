import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Build from '@material-ui/icons/Build'
import { OrganizationMemberRoles } from '@tivio/firebase'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import store from '../../store'
import { goSecretsPage } from '../../utils/history.utils'


const useStyles = makeStyles((theme) => ({
    item: {
        marginRight: theme.spacing(1),
    },
}))

type TScreenMenuProps = {
    onAddRelease: () => Promise<void> | void
}

const DeployScreenMenu: React.FC<TScreenMenuProps> = ({ onAddRelease }) => {
    const [t] = useTranslation()
    const { getMember: member } = store
    const admin = member?.getAdmin
    const classes = useStyles()

    if (!admin) {
        return null
    }

    return (
        <>
            { admin.getMember.getCurrentRole >= OrganizationMemberRoles.SUPER_ADMIN &&
                (<>
                    <Button
                        className={classes.item}
                        startIcon={<Build />}
                        component="span"
                        size="small"
                        onClick={() => goSecretsPage()}
                    >
                        {t('Secrets')}
                    </Button>

                    <Button
                        startIcon={<Add/>}
                        component="span"
                        size="small"
                        onClick={() => onAddRelease()}
                    >
                        {t('Add release')}
                    </Button>
                </>)
            }
        </>
    )
}

export default DeployScreenMenu
