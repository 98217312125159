import { ApplicationDocument, ApplicationDocumentCreation } from '@tivio/firebase'
import firebase from 'firebase'

import { Application } from '../../store/Application'
import Organization from '../../store/Organization'
import { getFirestore, loggerFirestore } from '../app'


export const getApplicationsCollection = (organization: Organization) => {
    return getFirestore()
        .collection(`${organization.ref.path}/applications`)
        .withConverter({
            fromFirestore: (snapshot) => {
                return snapshot.data() as ApplicationDocument
            },
            toFirestore: (application) => {
                return application
            },
        })
}

/**
 * Loads applications by organization id.
 *
 * @param organization Organization store
 */
export const getApplicationsByOrganization = async (organization: Organization): Promise<Application[]> => {
    const applicationSnapshots = await getApplicationsCollection(organization).get()
    return applicationSnapshots.docs.map(
        (application) => new Application(application.ref, application.data()),
    )
}

/**
 * Creates new application (in firebase) in provided organization.
 * Returns constructed Application entity.
 *
 * @param organization id of organization
 * @param applicationData new application data
 */
export const addApplication = async (
    organization: Organization,
    applicationData: ApplicationDocumentCreation,
): Promise<Application> => {
    const newApplicationData = {
        ...applicationData,
        applicationScreens: [],
    }

    const ref = await addApplicationToFirestore(organization, newApplicationData)
    return new Application(ref, newApplicationData)
}

/**
 * Adds new application to `applications` collection of provided organization (firebase document).
 * Returns reference to created application.
 *
 * @param organization instance of organization
 * @param applicationData new application data
 */
const addApplicationToFirestore = async (
    organization: Organization,
    applicationData: ApplicationDocument,
): Promise<firebase.firestore.DocumentReference<ApplicationDocument>> => {
    const ref = await getApplicationsCollection(organization).add(
        applicationData,
    )

    loggerFirestore.info(`Application with id ${ref.id} created.`)
    return ref
}
