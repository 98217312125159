import { LangCode } from '@tivio/types'
import { makeAutoObservable } from 'mobx'

import type { Article } from './Article'
import type { ArticleBlockField } from '@tivio/firebase'


export class ArticleBlock implements ArticleBlockField {
    constructor(public data: ArticleBlockField, public article: Article) {
        makeAutoObservable(this)
    }

    async update(data: Partial<ArticleBlockField>, throttle = true) {
        this.data = {
            ...this.data,
            ...data,
        }

        if (throttle) {
            await this.article.saveThrottle()
        } else {
            await this.article.save()
        }
    }

    get id() {
        return this.data.id
    }

    get type() {
        return this.data.type
    }

    get textContent() {
        if (this.data.textContent) {
            return this.data.textContent
        }

        throw new Error(`textContent field is not available in block of type ${this.type}`)
    }

    get htmlContent() {
        return this.data.htmlContent
    }

    updateTranslationField = (fieldName: 'textContent' | 'htmlContent', langCode: LangCode, value: string) => {
        this.update({
            [fieldName]: {
                ...this.data[fieldName],
                [langCode]: value,
            },
        })
    }

    get isTranslationBlock() {
        return ['HEADING', 'PARAGRAPH'].includes(this.data.type)
    }

    get translationValue() {
        if (this.isTranslationBlock) {
            return this.data.textContent ?? this.data.htmlContent
        }
    }

    get imageUrl() {
        return this.data.imageUrl
    }

    set imageUrl(imageUrl: string | undefined) {
        this.update({
            imageUrl,
        }, false)
    }

    updateImageUrl = async (imageUrl: string) => this.update({ imageUrl })

    get videoId() {
        return this.data.videoId
    }

    set videoId(videoId: string | undefined) {
        this.update({
            videoId,
        }, false)
    }
}
