import { Tooltip } from '@material-ui/core'
import { TvStreamType } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import Monetization from '../../store/Monetization'
import { Text } from '../common'
import { StyledHover } from '../styled/Container'
import { Row } from '../uiLayout/Row'


interface Props {
    monetization: Monetization
    onClick?: () => void
}

const formatPlacementDescription = (monetization: Monetization) => {
    const description = (
        placements: { id: string, name: string }[], type:
            'video' |
            'section' |
            'tv channel' |
            'channel',
    ) => {
        return `${placements.length > 0 ? `${placements.length} ${type}(s)` : ''}`
    }

    const placements = [
        description(monetization.getVideos, 'video'),
        description(monetization.getSections, 'section'),
        description(monetization.getTvChannels, 'tv channel'),
        description(monetization.getChannels, 'channel'),
        `${monetization.conditionPlacements.length > 0 ? `${monetization.conditionPlacements.length} condition(s)` : ''}`,
    ].filter(Boolean)

    return placements.join(', ')
}

const RowContainer = styled.div`

`

const RowHoverContainer = styled(StyledHover)`
    padding: .5rem;
    margin: -.5rem;
`

const StyledText = styled(Text)`
    margin-left: 1em;
`

const getType = (type: TvStreamType) => {
    if (type === 'all') {
        return 'all stream types'
    }

    return type
}

const TvStreamText = (props: { type?: TvStreamType }) => {
    const { type } = props
    const [t] = useTranslation()

    if (!type) {
        return null
    }

    return (
        <Tooltip title={t('TV Stream type')}>
            <StyledText>({t(getType(type))})</StyledText>
        </Tooltip>
    )
}

const MonetizationPlacementsList: React.FC<Props> = observer((props) => {
    const { monetization } = props

    const {
        getVideos: videos,
        getSections: sections,
        getTvChannels: tvChannels,
        getChannels: channels,
    } = monetization

    if (!monetization.hasPlacements) {
        return null
    }

    const renderPlacementsTooltip = (placements: { id: string, name: string }[], type:
        'video' |
        'section' |
        'tv channel' |
        'channel',
    ) => {
        if (placements.length === 0) {
            return null
        }

        return (
            <Tooltip title={placements.map(({ id, name }) => <div key={id}>{name}</div>)}>
                <span>{placements.length} {type}(s)</span>
            </Tooltip>
        )
    }

    // TODO TIV-806 render as list of clickable rows (with more info, as 'tags contains any someTag1, someTag2)
    //  open edit condition dialog on click
    const renderConditionPlacements = () => {
        if (monetization.conditionPlacements.length === 0) {
            return null
        }

        return (
            <span>
                {monetization.conditionPlacements.length + ' condition(s)'}
            </span>
        )
    }

    const Wrapper = props.onClick ? RowHoverContainer : RowContainer
    const tvStreamType = monetization.getPlacements.tvStreamType

    return (
        <Wrapper onClick={props.onClick}>
            <Row spacing={1}>
                {renderPlacementsTooltip(videos, 'video')}
                {renderPlacementsTooltip(sections, 'section')}
                {tvChannels && <>
                    {renderPlacementsTooltip(tvChannels, 'tv channel')}
                    {tvStreamType && <TvStreamText type={tvStreamType} />}
                </>}
                {renderPlacementsTooltip(channels, 'channel')}
                {renderConditionPlacements()}
            </Row>
        </Wrapper>
    )
})

export {
    MonetizationPlacementsList,
    formatPlacementDescription,
}
