import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ASSET_PRESET_PLACEMENTS, AssetPresetPlacement } from '@tivio/firebase'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as yup from 'yup'

import Logger from '../../../logger'
import { AssetPreset, getPlacementText, getScaling, qualityText } from '../../../store/AssetPreset'
import { getInputProps } from '../../../utils/formik.utils'
import { REGEX_SNAKE_CASE } from '../../../utils/regex.utils'
import DialogBase from '../../dialog/DialogBase'
import { useIsSuperAdmin } from '../../hooks/permissions/permissions'

import type { TDialogProps } from '../../dialog/DialogBase'


interface Props extends TDialogProps<AssetPreset> {
    preset?: AssetPreset
    forbiddenNames: string[]
}

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    paper: {
        width: 840,
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
}))

const Description = styled.div`
    color: grey;
    font-style: italic;
    margin-top: -5px;
`

const Warning = styled.span`
    font-style: italic;
`

const initialValues = {
    id: 'id', // dummy value, will be generated by DB
    name: '',
    description: '',
    width: 10,
    height: 10,
    style: 'fill',
    scalingDouble: false,
    scalingTriple: false,
    placement: AssetPresetPlacement.VIDEO,
    isGlobal: false,
}

type FormValues = typeof initialValues

const logger = new Logger('assetPresetDialog')

const getValues = (preset?: AssetPreset): FormValues => {
    if (preset) {
        return {
            id: preset.id,
            name: preset.name,
            description: preset.description,
            width: preset.width,
            height: preset.height,
            style: preset.mode,
            scalingDouble: preset.scaling.includes('@2'),
            scalingTriple: preset.scaling.includes('@3'),
            placement: preset.placement,
            isGlobal: preset.isGlobal,
        }
    }

    return initialValues
}

export const DialogEditAssetPreset = (props: Props) => {
    const { preset, open, forbiddenNames } = props
    const [ t ] = useTranslation()
    const classes = useStyles()

    const isSuperAdmin = useIsSuperAdmin()

    // some fields will be disabled for edit mode
    const editMode = !!preset

    useEffect(() => {
        if (open) {
            if (preset) {
                form.resetForm({ values: getValues(preset) })

                logger.info('Editing asset preset', preset)
            } else {
                form.resetForm()
            }
        }
    }, [open])

    const form = useFormik({
        initialValues: getValues(preset),
        onSubmit: (values: FormValues) => {
            const preset = new AssetPreset(
                values.id,
                values.name,
                values.description,
                values.width,
                values.height,
                values.style as any,
                getScaling(values.scalingDouble, values.scalingTriple),
                values.placement as AssetPresetPlacement,
                new Date(),
            )

            preset.isGlobal = values.isGlobal

            logger.info('Submitting asset preset', preset)

            if (!preset.valid) {
                throw new Error('Bug: newly created asset preset is invalid, this should '
                    + `never happen. Error ${preset.errors.join(', ')}`)
            }

            props.onConfirm?.(preset)
        },
        validationSchema: yup.object().shape({
            name: yup.string()
                .required('Name required')
                .matches(
                    REGEX_SNAKE_CASE,
                    'Name must contain only lower case letters, numbers or underscore.',
                ).test('test_uniqueness', 'This name already exists.', (value) => {
                    return value != null && !forbiddenNames.includes(value)
                }),
            description: yup.string(),
            width: yup.number().required('Width is required.'),
            height: yup.number().required('Height is required.'),
            style: yup.string().required('Style is required.'),
            scalingDouble: yup.boolean(),
            scalingTriple: yup.boolean(),
            placement: yup.string().required('Placement is required.'),
            isGlobal: yup.boolean(),
        }),
        validateOnBlur: false,
        validateOnChange: false,
    })

    const quality1x = qualityText(form.values.width, form.values.height, 1)
    const quality2x = qualityText(form.values.width, form.values.height, 2)
    const quality3x = qualityText(form.values.width, form.values.height, 3)

    return (
        <DialogBase
            onCancel={props.onCancel}
            open={props.open}
            showCloseIcon
            hideActionsButtons
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <form onSubmit={form.handleSubmit} data-e2e="edit-asset-preset">
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    {preset && <Grid item>
                        <Warning>
                            {t('Images already generated using this preset will'
                            + ' not be updated according to changes here.')}
                        </Warning>
                    </Grid>}
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Placement')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="asset_preset_placement">{t('Placement')}</InputLabel>
                            <Select
                                {...getInputProps(form, 'placement')}
                                label={t('Placement')}
                                labelId="asset_preset_placement"
                                disabled={editMode}
                            >
                                {ASSET_PRESET_PLACEMENTS.map(value =>
                                    <MenuItem value={value}>{getPlacementText(value)}</MenuItem>,
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Preset name')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'name')}
                            label={t('Name')}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'description')}
                            label={t('Description')}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Size')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid
                            container
                            direction="row"
                            spacing={2}
                        >
                            <Grid item xs={6}>
                                <TextField
                                    {...getInputProps(form, 'width')}
                                    inputProps={{ min: 10, max: 10000 }}
                                    type="number"
                                    label={t('Width') + ' px'}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    {...getInputProps(form, 'height')}
                                    inputProps={{ min: 10, max: 10000 }}
                                    type="number"
                                    label={t('Height') + ' px'}
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="resize_mode">{t('Style')}</InputLabel>
                            <Select
                                {...getInputProps(form, 'style')}
                                label={t('Style')}
                                labelId="resize_mode"
                            >
                                <MenuItem
                                    title={t('cover the width x height, keep aspect ratio, cut overflow (preferred option)')}
                                    value="fill"
                                >{t('fill')}</MenuItem>
                                <MenuItem
                                    title={t('fit inside width x height, keep aspect ratio, fill the rest of width x height with black color')}
                                    value="fit"
                                >{t('fit')}</MenuItem>
                                <MenuItem
                                    title={t('stretch to cover width x height, aspect ratio may become distorted')}
                                    value="stretch"
                                >{t('stretch')}</MenuItem>
                                <MenuItem
                                    title={t('fit inside width x height, keep aspect ratio, do not fill the rest of width x height')}
                                    value="inside"
                                >{t('inside')}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={form.values.scalingDouble}
                                onChange={form.handleChange}
                                name="scalingDouble" />
                            }
                            label={`${t('Resize to 2 qualities')} (${quality2x}, ${quality1x})`}
                        />
                        <Description>
                            {t('Minimum uploaded image size must be') + ` ${quality1x}. `
                            + t('Recommended size is') + ` ${quality2x}`}
                        </Description>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox
                                color="primary"
                                checked={form.values.scalingTriple}
                                onChange={form.handleChange}
                                name="scalingTriple" />
                            }
                            label={`${t('Resize to 3 qualities')} (${quality3x}, ${quality2x}, ${quality1x})`}
                        />
                        <Description>
                            {t('Minimum uploaded image size must be') + ` ${quality1x}. `
                            + t('Recommended size is') + ` ${quality3x}`}
                        </Description>
                    </Grid>

                    {!preset && isSuperAdmin && (
                        <>
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    className={classes.title}
                                >
                                    {t('Organization scope')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox
                                        color="primary"
                                        checked={form.values.isGlobal}
                                        onChange={form.handleChange}
                                        name="isGlobal" />
                                    }
                                    label={t('Global')}
                                />
                                <Description>{t('Global presets are applied to all organizations')}</Description>
                            </Grid>
                        </>
                    )}

                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogBase>
    )
}
