import { makeStyles, Paper } from '@material-ui/core'
import React from 'react'

import AuthForgottenPassword from './AuthForgottenPassword'
import AuthLogin from './AuthLogin'
import AuthTabPanel from './AuthTabPanel'


const useStyles = makeStyles({
    root: {
        flexGrow: 1,
        position: 'relative',
    },
})

export enum AUTH_TABS {
    LOGIN = 'login',
    FORGOTTEN_PASSWORD = 'forgotten_password'
}

/**
 * Authorization tabs component
 */
function AuthTabs() {
    const [ currentTab, setCurrentTab ] = React.useState<AUTH_TABS>(AUTH_TABS.LOGIN)
    const classes = useStyles()

    return (
        <Paper elevation={5}>
            <div className={classes.root}>
                <AuthTabPanel
                    currentTabName={currentTab}
                    tabName={AUTH_TABS.LOGIN}
                >
                    <AuthLogin setCurrentTab={setCurrentTab}/>
                </AuthTabPanel>
                <AuthTabPanel
                    currentTabName={currentTab}
                    tabName={AUTH_TABS.FORGOTTEN_PASSWORD}
                >
                    <AuthForgottenPassword setCurrentTab={setCurrentTab}/>
                </AuthTabPanel>
            </div>
        </Paper>
    )
}

export default AuthTabs
