import { Container } from '@material-ui/core'
import { globalsFirstRestAlphabeticallyComparator } from '@tivio/common'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import Logger from '../../../logger'
import { AssetPreset } from '../../../store/AssetPreset'
import { Header, withErrorBoundary } from '../../common'
import { useDialogState } from '../../hooks'
import { StyledHover } from '../../styled/Container'
import { Column } from '../../uiLayout/Column'

import { DialogEditAssetPreset } from './dialogEditAssetPreset'
import { useAssetPresets, useCrud } from './hooks'
import { PresetItemRow } from './presetItemRow'

import type { TActions } from '../../common'


const Description = styled.div`
    color: #FFF8;
    font-style: italic;
`

const logger = new Logger('AssetPresets')

const useLogSummary = (assetPresets: AssetPreset[]) => {
    useEffect(() => {
        if (assetPresets) {
            logger.info('Asset presets:', assetPresets)

            const invalids = assetPresets?.filter(preset => !preset.valid)
            if (invalids.length > 0) {
                logger.error('Some Asset presets are invalid:', invalids)
            }
        }
    }, [assetPresets])
}

export const PresetsTab = withErrorBoundary(() => {
    const [ t ] = useTranslation()
    const { isDialogOpen, openDialog, closeDialog } = useDialogState()
    const assetPresets = useAssetPresets()
    const { create, remove } = useCrud()

    const actions: TActions = useMemo(() => {
        return {
            create: {
                title: t('Add preset'),
                onCreate: openDialog,
            },
        }
    }, [])

    useLogSummary(assetPresets ?? [])

    const onConfirmDialog = useCallback((preset: AssetPreset) => {
        create(preset)
        closeDialog()
    }, [create, closeDialog])

    if (!assetPresets) {
        throw new Error('globalAssetPresets and organization.assetPresets not defined, this should never happen.')
    }

    const names = useMemo(() => {
        return assetPresets.map(preset => preset.name)
    }, [assetPresets])

    const getKey = useCallback((i: number) => {
        return assetPresets[i].id
    }, [assetPresets])

    return (
        <Container maxWidth="lg">
            <DialogEditAssetPreset
                onCancel={closeDialog}
                onConfirm={onConfirmDialog}
                open={isDialogOpen}
                forbiddenNames={names}
            />
            <Header
                title={t('Asset presets')}
                actions={actions}
            />
            {assetPresets.length === 0 && <Description>{t('No asset presets found')}</Description>}
            <Column
                spacing={3}
                getKey={getKey}
            >
                {
                    assetPresets
                        .sort(globalsFirstRestAlphabeticallyComparator)
                        .map(preset => (
                            <StyledHover
                                key={preset.id}
                                opacity={0.04}
                            >
                                <PresetItemRow
                                    presetNames={names}
                                    preset={preset}
                                    onDelete={remove}
                                />
                            </StyledHover>
                        ))
                }
            </Column>
        </Container>
    )
})
