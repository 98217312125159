import { Logger } from '@tivio/common'
import { analytics, PromiseWrapper, tivio } from '@tivio/core-react'
import {
    Drm,
    LangCode,
    PlayerEngineConfig,
    PlayerEngineInterface,
    PlayerEngineOptions,
} from '@tivio/types'

import { BaseEngine } from '../baseEngine'

import {
    base64ToUint8Array,
    concatInitDataIdAndCertificate,
    extractContentIdAndLicenseUrlParams,
    selectKeySystem,
    uint8ArrayToBase64,
} from './utils'


const logger = new Logger('fairplayEngine')

declare class WebKitMediaKeys {
    constructor(keySystem: string)
}

// TODO this whole field is just type approximation based on runtime, find proper types for this
interface KeySession extends EventTarget {
    /**
     * contentId for Fairplay.
     */
    contentId: string | null
}

interface AudioTrack {
    language: LangCode
    enabled: boolean
}

// TODO this whole field is just type approximation based on runtime, find proper types for this
export interface HTMLVideoElementWebKit extends HTMLVideoElement {
    audioTracks: {
        length: number
        [key: number]: AudioTrack
    } & EventTarget
    webkitKeys: any
    webkitSetMediaKeys: (webkitMediaKeys: WebKitMediaKeys) => void
}

// TODO this whole field is just type approximation based on runtime, find proper types for this
interface WebkitNeedKeyEvent extends Event {
    target: HTMLVideoElementWebKit
    // TODO it's either Uint8Array | Uint16Array
    initData?: any
}

interface WebkitTimeEvent extends Event {
    /**
     * Warning, it's in seconds!
     */
    timeStamp: number
}

/**
 * Event listeners for keySession.
 */
function waitForEvent(eventName: string, action: (arg?: any) => void, keySession: KeySession) {
    keySession.addEventListener(eventName, (arg) => action(arg), false)
}

export class FairplayEngine extends BaseEngine implements PlayerEngineInterface {
    private readonly _destroy: () => void
    private currentConfig: PlayerEngineConfig | null = null
    private drmConfiguration: Drm | null = null
    private licenseUrlParams: URLSearchParams | null = null
    private keySession: KeySession | null = null
    /**
     * Loaded certificate for Fairplay.
     */
    private certificate = new PromiseWrapper<Uint8Array>()

    constructor(
        videoElement: HTMLVideoElementWebKit,
        // Seeking too aggressively may cause problems with playback.
        // That's why we have an option to debounce seeks
        // https://github.com/google/shaka-player/issues/2247
        options?: PlayerEngineOptions,
    ) {
        super(videoElement, options)

        const listeners = [
            {
                // Fairplay needed
                subject: this.videoElement as HTMLVideoElementWebKit,
                event: 'webkitneedkey',
                listener: async (event: WebkitNeedKeyEvent) => {
                    const certificate = await this.certificate.value

                    const video = event.target

                    const { contentId, licenseUrlParams } = extractContentIdAndLicenseUrlParams(event.initData)
                    this.licenseUrlParams = licenseUrlParams ?? null

                    const initData = concatInitDataIdAndCertificate(event.initData, contentId, certificate)

                    if (!video.webkitKeys) {
                        video.webkitSetMediaKeys(new WebKitMediaKeys(selectKeySystem()))
                    }

                    if (!video.webkitKeys) {
                        throw new Error('Could not create MediaKeys')
                    }

                    this.keySession = video.webkitKeys.createSession('video/mp4', initData)
                    if (!this.keySession) {
                        throw new Error('Could not create key session')
                    }

                    this.keySession.contentId = contentId

                    waitForEvent('webkitkeymessage', this.licenseRequestReadyFetch, this.keySession)
                    waitForEvent('webkitkeyadded', () => logger.info('Decryption key was added to session.'), this.keySession)
                    waitForEvent('webkitkeyerror', () => logger.error('A decryption key error was encountered'), this.keySession)
                },
            },
            {
                subject: (this.videoElement as HTMLVideoElementWebKit).audioTracks ?? this.videoElement,
                event: 'addtrack',
                listener: () => {
                    // Set default audio track based on Tivio language
                    if (this.getAudioTracks().includes(tivio.language)) {
                        this.selectAudioTrack(tivio.language)
                    }
                },
            },
            {
                subject: this.videoElement,
                event: 'timeupdate',
                listener: (event: WebkitTimeEvent) => {
                    // We don't want to change position when user is seeking
                    if (this.isSeeking) {
                        return
                    }

                    this.triggerEvent('enginetimeupdate', this.videoElement.currentTime * 1000)
                    event.stopPropagation()
                },
            },
            {
                subject: this.videoElement,
                event: 'durationchange',
                listener: (event: WebkitTimeEvent) => {
                    this.triggerEvent('enginedurationchange', this.getDurationMs())
                    event.stopPropagation()
                },
            },
        ]

        listeners.forEach(({ subject, event, listener }) => {
            subject.addEventListener(event, listener as EventListenerOrEventListenerObject)
        })

        this._destroy = () => {
            listeners.forEach(({ subject, event, listener }) => {
                subject.removeEventListener(event, listener as EventListenerOrEventListenerObject)
            })
        }
    }

    private async fetchCertificate(fairplayCertificateUrl: string) {
        if (this.certificate.isResolved) {
            return
        }

        // TODO: certifikát memoizujeme, ale nemůže se během lifecyclu změnit?
        // Jakože v existujícím playeru načtu src od jiný organizace,
        // nebo jedna organizace může mít více certifikátů?
        // Můžeme memoizovat přes Map, kde url certifikátu bude memoize key.
        // TODO this request fails very rarely, but it will be nice to add error handling here
        // TODO (triggetEvent('error') doesn't work for some reason right now, some rework here is needed)
        const response = await fetch(fairplayCertificateUrl)
        const cert = await response.arrayBuffer()

        this.certificate.resolve(new Uint8Array(cert))
    }

    async load(config: PlayerEngineConfig) {
        this.resetState()
        this.setLoading(true)

        try {
            await this._load(config)
            await this.pause()
            this.triggerEvent('loaded')
        } catch (error) {
            this.onLoadFailed(error)
            throw error
        } finally {
            this.setLoading(false)
        }
    }

    async play(config: PlayerEngineConfig) {
        this.resetState()
        this.setLoading(true)

        try {
            await this._load(config)
            await this.unpause()
            this.triggerEvent('loaded')
        } catch (error) {
            this.onLoadFailed(error)
            throw error
        } finally {
            this.setLoading(false)
        }
    }

    async stop() {
        logger.info('stop')

        this.currentConfig = null
        this.resetState()
        this.videoElement.pause()
        this.videoElement.removeAttribute('src')
        this.videoElement.load()
    }

    async destroy() {
        logger.info('destroy')

        await this.stop()
        this._destroy()
    }

    private setLoading(loading: boolean) {
        this.triggerEvent('bufferingchange', loading)
    }

    private async _load(config: PlayerEngineConfig) {
        this.currentConfig = config

        const {
            getDrmConfiguration,
            url,
            positionMs,
        } = this.currentConfig

        this.videoElement.src = url
        this.videoElement.onloadedmetadata = () => {
            this.videoElement.currentTime = positionMs ? positionMs / 1000 : 0
        }

        this.drmConfiguration = await getDrmConfiguration?.() ?? null

        if (this.drmConfiguration?.certificateUrl) {
            await this.fetchCertificate(this.drmConfiguration.certificateUrl)
        }
    }

    private onLoadFailed(error: any) {
        this.triggerEvent('loadfailed')
        // TODO this should not be here, playerAnalytics.ts should call this
        analytics.reportError(error)
        this.resetState()
    }

    /*
        This function assumes the Key Server Module understands the following POST format --
        spc=<base64 encoded data>&assetId=<data>
        ADAPT: Partners must tailor to their own protocol.
    */
    private licenseRequestReadyFetch = async (event: any) => {
        if (!this.drmConfiguration?.licenseUrl) {
            throw new Error('Fairplay keyserver or license not set')
        }

        const licenseUrl = new URL(this.drmConfiguration.licenseUrl)

        for (const [name, value] of Array.from(this.licenseUrlParams?.entries() ?? [])) {
            licenseUrl.searchParams.set(name, value)
        }

        const response = await fetch(licenseUrl, {
            method: 'POST',
            headers: {
                'Content-type': 'application/x-www-form-urlencoded',
                ...this.drmConfiguration.licenseRequestHeaders,
            },
            body: 'spc=' + uint8ArrayToBase64(event.message) + '&assetId=' + encodeURIComponent(this.keySession?.contentId || ''),
        })

        const responseText = await response.text()

        // response can be of the form: '\n<ckc>base64encoded</ckc>\n'
        // so trim the excess:
        let keyText = responseText.trim()
        if (keyText.substr(0, 5) === '<ckc>' && keyText.substr(-6) === '</ckc>') {
            keyText = keyText.slice(5, -6)
        }

        const key = base64ToUint8Array(keyText)

        event.target.update(key)
    }

    /**
     * Returns currently available audio tracks of currently playing video. Should be called when player is in "loaded"
     * state, otherwise it will return an empty array.
     *
     * @returns array of available audio tracks in ISO 639-1 format ('cs', 'en' etc.)
     */
    getAudioTracks() {
        const langCodes: LangCode[] = []
        const { audioTracks } = this.getVideoElement()

        for (let i = 0; i < audioTracks.length; i++) {
            langCodes.push(audioTracks[i].language)
        }

        if (langCodes.length > 0) {
            logger.info('audio tracks:', langCodes)
        }

        return langCodes
    }

    /**
     * Returns currently available subtitles of currently playing video. Should be called when player is in "loaded"
     * state, otherwise it will return an empty array.
     *
     * @returns array of available subtitles in ISO 639-1 format ('cs', 'en' etc.)
     */
    getSubtitles() {
        const langCodes: LangCode[] = []
        const textTracks = this.getVideoElement().textTracks

        for (let i = 0; i < textTracks.length; i++) {
            langCodes.push(textTracks[i].language as LangCode)
        }

        return langCodes
    }

    /**
     * @returns currently selected subtitles track in ISO 639-1 format ('cs', 'en' etc.) or null if not available
     */
    getActiveSubtitles = (): LangCode | null => {
        const textTracks = this.getVideoElement().textTracks

        for (let i = 0; i < textTracks.length; i++) {
            if (textTracks[i].mode === 'showing') {
                return textTracks[i].language as LangCode
            }
        }

        return null
    }

    /**
     * Changes subtitles of currently playing video.
     *
     * @param {LangCode} langCode - subtitles to select in ISO 639-1 format ('cs', 'en' etc.) or null to disable subtitles
     */
    selectSubtitles = (langCode: LangCode | null) => {
        const { textTracks } = this.getVideoElement()

        for (let i = 0; i < textTracks.length; i++) {
            textTracks[i].mode = textTracks[i].language === langCode ? 'showing' : 'hidden'
        }

        this.triggerEvent('trackschanged')
    }

    /**
     * Changes audio track of currently playing video.
     *
     * @param {LangCode} langCode - audio track to select in ISO 639-1 format ('cs', 'en' etc.)
     */
    selectAudioTrack = (langCode: LangCode) => {
        logger.info('Setting audio track:', langCode)

        const { audioTracks } = this.getVideoElement()

        for (let i = 0; i < audioTracks.length; i++) {
            audioTracks[i].enabled = audioTracks[i].language === langCode
        }

        this.triggerEvent('trackschanged')
    }

    /**
     * Changes playback speed of currently playing video.
     *
     * @param {number} playbackSpeed - playback speed to select (1 = normal speed, 2 = twice as fast, etc.)
     */
    selectPlaybackSpeed = (playbackSpeed: number) => {
        logger.info('Setting playback speed:', playbackSpeed)

        this.getVideoElement().playbackRate = playbackSpeed
    }

    /**
     * @returns currently playing audio track in ISO 639-1 format ('cs', 'en' etc.) or null if not available
     */
    getActiveAudioTrack = () => {
        const audioTracks = this.getVideoElement().audioTracks

        for (let i = 0; i < audioTracks.length; i++) {
            if (audioTracks[i].enabled) {
                return audioTracks[i].language
            }
        }

        return null
    }

    getVideoElement() {
        return super.getVideoElement() as HTMLVideoElementWebKit
    }
}
