import { makeAutoObservable } from 'mobx'

import type { FirestoreDocumentReference, FirestoreDocumentSnapshot } from '../firebase/app'
import type { TEnvironmentFirestore } from '../firebase/firestore/environment'


class Environment {
    ref: FirestoreDocumentReference<TEnvironmentFirestore> | null = null
    id: string | null = null
    name: string | null = null
    description: string | null = null

    constructor() {
        makeAutoObservable(this)
    }

    fromFirestore(doc: FirestoreDocumentSnapshot<TEnvironmentFirestore>) {
        const data = doc.data()
        this.ref = doc.ref
        this.id = doc.id
        this.name = data?.name ?? null
        this.description = data?.description ?? null
    }
}

export default Environment
