import { Button } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'

import { Article } from '../../../store/Article'
import { getTranslation } from '../../../utils/translate.utils'
import { ArticleEditor } from '../../articles'
import { ArticleSettings } from '../../articles/ArticleSettings'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs, PageTab } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { useArticle } from '../../hooks/dataHooks/useArticle'
import { VideoListSkeleton } from '../../video/VideoListSkeleton'
import { UserLayoutParams } from '../layout/UserLayout'


export const ArticlePage = observer(() => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { articleId } = useParams<UserLayoutParams>()
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [isSaving, setIsSaving] = React.useState(false)

    const { article, error } = useArticle(articleId)

    const saveDraftButton = (
        <Button
            variant="outlined"
            disabled={isSaving}
            onClick={async () => {
                setIsSaving(true)
                await article?.save()
                setIsSaving(false)
            }}
        >
            {t('Save draft')}
        </Button>
    )

    const pageTags = (article: Article): PageTab[] => [
        {
            title: t('Settings'),
            component: <ArticleSettings article={article} />,
        },
        {
            title: t('Editor'),
            component: <ArticleEditor article={article} />,
        },
    ]

    return (
        <>
            <DefaultPageHeader
                title={article?.name ? getTranslation(article.name, organization?.languages) : ''}
                buttons={[saveDraftButton]}
            />
            {article ? (
                <DefaultPageTabs
                    tabs={pageTags(article)}
                    onChange={(index) => setActiveTabIndex(index)}
                    currentTabIndex={activeTabIndex}
                />
            ) : (
                <VideoListSkeleton />
            )}
        </>
    )
})
