import {
    Box,
    Button,
    Grid,
    IconButton,
    Tooltip,
    Typography,
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import Code from '@material-ui/icons/Code'
import Delete from '@material-ui/icons/Delete'
import Error from '@material-ui/icons/Error'
import { removeAccents } from '@tivio/common'
import { VideoType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { createTranslation } from '../../creator/translation.creator'
import Video from '../../store/Video'
import { getTranslation } from '../../utils/translate.utils'
import MultiTextField, { MultiTextFieldItem, multiTextFieldItemToTranslation, translationToMultiTextFieldItem } from '../common/formField/MultiTextField'
import { useOrganization } from '../hooks'
import { useFileInput } from '../hooks/dataHooks/useFileInput'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { Column } from '../uiLayout/Column'

import VideoResolutions from './VideoResolutions'
import { VideoRichDescription } from './VideoRichDescription'
import { VideoShareDialog } from './VideoShareDialog'

import type { CircularProgressProps } from '@material-ui/core/CircularProgress'
import { useEncodingProcesses } from '../hooks/dataHooks/useEncodingProcesses'
import { VideoEncodingProcess } from './videoEncodingProcess'
import { getMostRecentVideoEncodingProcesses } from '../../utils/processes.utils'
import { ProcessDocument } from '@tivio/firebase'


interface Props {
    video: Video
    buttons?: React.ReactNode[]
    progress?: number
    hideProgress?: boolean
    onUploadRemove: () => Promise<void>
    disabled?: boolean
}

function CircularProgressWithLabel (props: CircularProgressProps & { value: number }) {
    const { value } = props

    return (
        <Box
            position="relative"
            display="inline-flex"
        >
            <CircularProgress
                variant="determinate"
                {...props}
            />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography
                    variant="caption"
                    component="div"
                    color="textSecondary"
                >{`${Math.round(
                        value,
                    )}%`}</Typography>
            </Box>
        </Box>
    )
}

export const VideoEditor: React.FC<Props> = observer(({
    video,
    progress,
    hideProgress,
    onUploadRemove,
    disabled = false,
}) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const {
        isDialogOpen: isVideoShareDialogOpen,
        openDialog: openVideoShareDialog,
        closeDialog: closeVideoShareDialog,
    } = useDialog(false)
    const { encodingProcesses, encodingProcessesCompleted } = useEncodingProcesses(video)

    const { selectFile } = useFileInput({
        onFiles: video.uploadVideo,
        multiple: false,
        accept: 'video/mp4,video/quicktime',
        type: 'file',
        errorMessage: t('Failed to load video.'),
    })

    const isSuperAdmin = useIsSuperAdmin()

    const languages = organization?.languages
    const handleVideoNameChange = useCallback((items: MultiTextFieldItem[]) => {
        const nameTranslations = multiTextFieldItemToTranslation(items)

        video.update({
            name: nameTranslations,
            ...(languages && { defaultName: removeAccents(getTranslation(nameTranslations, languages) ?? '') }),
        })
    }, [video, languages])

    const handleVideoShortNameChange = useCallback((items: MultiTextFieldItem[]) => {
        const shortNameTranslations = multiTextFieldItemToTranslation(items)

        video.update({ shortName: shortNameTranslations })
    }, [video])

    const showUploadButton = (!video.isTranscoding && progress === 0 && !disabled) || encodingProcessesCompleted

    const showUploadProgress = (!video.isTranscoding || encodingProcessesCompleted)
        && !!progress
        && !hideProgress

    const isTrailerOrCut = video.getType ? [VideoType.CUT, VideoType.TRAILER].includes(video.getType) : false

    return (
        <>
            <VideoShareDialog
                isOpen={isVideoShareDialogOpen}
                onClose={closeVideoShareDialog}
                videoId={video.id}
            />

            <Grid
                container
                direction="row"
                spacing={2}
            >
                <Grid
                    item
                    xs
                >
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item>
                            <MultiTextField
                                items={translationToMultiTextFieldItem(video.getNameTranslations ?? createTranslation(video.getName), organization?.languages)}
                                onChange={handleVideoNameChange}
                                fullWidth
                                typographyVariant="h5"
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item>
                            <Column spacing={2}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    {t('Short name')}
                                </Typography>
                                <MultiTextField
                                    items={translationToMultiTextFieldItem(video.getShortNameTranslations ?? createTranslation(''), organization?.languages)}
                                    maxLength={40}
                                    onChange={handleVideoShortNameChange}
                                    fullWidth
                                    typographyVariant="h5"
                                    disabled={disabled}
                                />
                            </Column>
                        </Grid>
                        <Grid item>
                            <Box mt={1}>
                                <MultiTextField
                                    items={translationToMultiTextFieldItem(video.getDescriptionTranslations ?? createTranslation(video.getDescription), organization?.languages)}
                                    onChange={(items) => video.update({
                                        description: multiTextFieldItemToTranslation(items),
                                    })}
                                    fullWidth
                                    multiline
                                    disabled={disabled}
                                />
                            </Box>
                        </Grid>
                        <Grid item>
                            <Column spacing={2}>
                                <Typography
                                    variant="body1"
                                    color="textSecondary"
                                >
                                    {t('Rich text description')}
                                </Typography>
                                <VideoRichDescription
                                    video={video}
                                    disabled={disabled}
                                />
                            </Column>
                        </Grid>
                        <Grid item>
                            <VideoResolutions resolutions={video.resolutions} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={4}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent={encodingProcesses !== null ? 'center' : 'flex-end'}
                        spacing={2}
                    >
                        {showUploadProgress && (
                            <Grid item>
                                <Box textAlign="center">
                                    <CircularProgressWithLabel value={progress ?? 50} />
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                    >{t('Uploading video')}</Typography>
                                </Box>
                            </Grid>
                        )}
                        {video.isTranscoding && (
                            <Grid item>
                                <Box textAlign="center">
                                    {encodingProcesses !== null ? <VideoEncodingProcess 
                                        encodingProcesses={getMostRecentVideoEncodingProcesses(encodingProcesses) as ProcessDocument[]} 
                                    /> : (
                                        <>
                                            <CircularProgress />

                                            <Typography
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {video.isOnHold ? t('In queue for transcoding') : t('Transcoding video')}
                                            </Typography>
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        )}
                        {showUploadButton && (
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={selectFile}
                                    disabled={isTrailerOrCut}
                                >
                                    {video.isUploaded || video.transcodingFailed || encodingProcessesCompleted
                                        ? t('Reupload video')
                                        : t('Upload video')}
                                </Button>
                                {video.transcodingFailed && (
                                    <>
                                        { isSuperAdmin && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                onClick={video.reEncode}
                                            >
                                                {t('Re-encode video')}
                                            </Button>
                                        )}

                                        <Box mt={1}>
                                            <Tooltip title={video.errors?.join(', ') ?? ''}>
                                                <Grid
                                                    container
                                                    justifyContent="space-evenly"
                                                >
                                                    <Error
                                                        fontSize="small"
                                                        color="error"
                                                    />
                                                    <Typography
                                                        variant="body2"
                                                        color="error"
                                                    >
                                                        {t('Transcoding failed')}
                                                    </Typography>
                                                </Grid>
                                            </Tooltip>
                                        </Box>
                                    </>
                                )}
                            </Grid>
                        )}
                        {(video.isUploaded || encodingProcessesCompleted) && (
                            <>
                                <Grid item>
                                    <IconButton
                                        size="small"
                                        onClick={openVideoShareDialog}
                                    >
                                        <Code />
                                    </IconButton>
                                </Grid>
                                {!isTrailerOrCut && !disabled && (<Grid item>
                                    <IconButton
                                        size="small"
                                        key={`${video.id}-remove`}
                                        onClick={onUploadRemove}
                                        title={t('Remove uploaded video file')}
                                    >
                                        <Delete fontSize="small" />
                                    </IconButton>
                                </Grid>)}
                            </>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
})

VideoEditor.displayName = 'VideoEditor'
