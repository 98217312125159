import { VideoDocument } from '@tivio/firebase'
import { TopWatchItem, ViewCountItem } from '@tivio/types'
import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'

import { getDocumentsByIds } from '../../../firebase/firestore/row'
import { getViewsAnalytics } from '../../../firebase/firestore/viewsAnalytic'
import Logger from '../../../logger'
import { ChartSeries } from '../../../types/chart'
import { Period } from '../../../types/date'
import { getChartIntervals } from '../../../utils/chart.utils'
import { getVideoCoverAsset } from '../../../utils/video.utils'
import { useOrganization } from '../../hooks'

import {
    getAggregatedData,
    getDaysForPeriod,
    getSeriesData,
    getViewCountDetails,
    getViewPeakDetails,
    viewCountComparator,
} from './services'
import { DeviceViewCountDetail, TopWatchedVideo, ViewsCountDetail, ViewsPeakDetail } from './types'


interface UseViewsDashboard {
    isLoading: boolean
    periodInDays: number
    dashboardData: {
        viewsCountDetail: ViewsCountDetail
        viewsPeakDetail: ViewsPeakDetail
        chartSeries: ChartSeries[]
        devicesViewCountDetails: DeviceViewCountDetail[]
        topWatchedVideoDetails: TopWatchedVideo[]
    }
}

const DEFAULT_VALUE_VIEWS_COUNT: ViewsCountDetail = { currentPeriodViewCount: 0 }
const DEFAULT_VALUE_VIEWS_PEAK: ViewsPeakDetail = {
    currentPeriodViewsPeak: { date: 0, viewCount: 0 },
    previousPeriodViewsPeak: { date: 0, viewCount: 0 },
}

const logger = new Logger('UseViewsDashboard')

interface Props {
    startDate: string
    endDate: string
    period: Period
}

export const useViewsDashboard = ({ startDate, endDate, period }: Props): UseViewsDashboard => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [topWatchedVideos, setTopWatchedVideos] = useState<TopWatchedVideo[]>([])
    const [deviceViewCountDetails, setDeviceViewCountDetails] = useState<DeviceViewCountDetail[]>([])
    const [chartSeries, setChartSeries] = useState<ChartSeries[]>([])
    const [viewsPeakDetail, setViewsPeakDetail] = useState<ViewsPeakDetail>(DEFAULT_VALUE_VIEWS_PEAK)
    const [viewsCountDetail, setViewsCountDetail] = useState<ViewsCountDetail>(DEFAULT_VALUE_VIEWS_COUNT)
    const [periodInDays, setPeriodInDays] = useState<number>(0)

    const { organization } = useOrganization()

    useEffect(() => {
        if (!organization?.id) {
            return
        }

        getData(organization.id)
        // TODO organization.id or getData method create infinity loop resolve in future
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, period])

    const processTopWatchedVideos = useCallback(async (topWatchedAnalytics: TopWatchItem[]) => {
        const videoIds = topWatchedAnalytics.map(({ videoId }) => videoId)
        const videoResult = await getDocumentsByIds('videos', videoIds)
        const topWatchedVideoDetails: TopWatchedVideo[] = videoResult.flatMap(videoSnapshots => videoSnapshots.docs)
            .map(videoSnapshot => {
                const videoData = videoSnapshot.data() as VideoDocument
                const videoId = videoSnapshot.id
                const coverImage = getVideoCoverAsset(videoData.assets ?? {})
                const viewCount = topWatchedAnalytics.find(topWatchedAnalytic => topWatchedAnalytic.videoId == videoId)!.viewCount
                return {
                    videoId,
                    name: videoData.name,
                    description: videoData.description,
                    coverImage,
                    viewCount,
                } as TopWatchedVideo
            },
            ).sort(viewCountComparator)
        setTopWatchedVideos(topWatchedVideoDetails)
    }, [])

    const processViews = useCallback(async (viewCountAnalytics: ViewCountItem[], endDate: string, periodInDays: number) => {
        const pointsInChart = periodInDays === 1 ? 24 : periodInDays
        const currentPeriodChartIntervals = getChartIntervals(endDate, periodInDays, pointsInChart)
        const prevPeriodChartIntervals = getChartIntervals(dayjs(endDate).subtract(periodInDays, 'day').toISOString(), periodInDays, pointsInChart)
        const currentPeriodViewsAggregationMatrix = getAggregatedData(viewCountAnalytics, currentPeriodChartIntervals)
        const prevPeriodViewsAggregationMatrix = getAggregatedData(viewCountAnalytics, prevPeriodChartIntervals)

        const devicesViewCount = Object.values(currentPeriodViewsAggregationMatrix.devices).sort(viewCountComparator)

        const seriesData = await getSeriesData(currentPeriodViewsAggregationMatrix.chartData, currentPeriodChartIntervals)
        const viewsCount = getViewCountDetails(currentPeriodViewsAggregationMatrix.viewsTotalCount, prevPeriodViewsAggregationMatrix.viewsTotalCount)
        const viewPeak = getViewPeakDetails(currentPeriodViewsAggregationMatrix.viewsPeaks, prevPeriodViewsAggregationMatrix.viewsPeaks)

        setViewsCountDetail(viewsCount)
        setViewsPeakDetail(viewPeak)
        setDeviceViewCountDetails(devicesViewCount)
        setChartSeries(seriesData)

    }, [])

    const getData = useCallback(async (organizationId: string) => {
        try {
            setIsLoading(true)
            const viewsAnalyticData = await getViewsAnalytics({ organizationId, startDate, endDate })
            processTopWatchedVideos(viewsAnalyticData.topWatched)
            const periodInDays = getDaysForPeriod(startDate, endDate, period)
            setPeriodInDays(periodInDays)
            await processViews(viewsAnalyticData.viewCountAnalytics, endDate, periodInDays)
            logger.info('Views dashboard loaded')
        } catch (error) {
            logger.error(`Data load and processing failed with error: ${error}`)
        } finally {
            setIsLoading(false)
        }
    }, [endDate, period, processTopWatchedVideos, processViews, startDate])

    return {
        isLoading,
        periodInDays,
        dashboardData: {
            viewsCountDetail: viewsCountDetail,
            viewsPeakDetail: viewsPeakDetail,
            chartSeries: chartSeries,
            devicesViewCountDetails: deviceViewCountDetails,
            topWatchedVideoDetails: topWatchedVideos,
        },
    }
}
