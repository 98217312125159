import { OrganizationMemberRoles } from '@tivio/firebase'
import { useTranslation } from 'react-i18next/hooks'

import { createLogger } from '../../../logger'
import { PAGES } from '../../../static/enum'
import { useAlert } from '../uiHooks/useAlert'

import { useOrganization } from './useOrganization'
import { useStore } from './useStore'


const logger = createLogger('useConsent')

export const useConsent = () => {
    const [t] = useTranslation()
    const { store } = useStore()
    const { organization } = useOrganization()
    const { showSuccess, showError } = useAlert()

    const consent = organization?.requiredConsents?.[0]
    const nextConsent = organization?.requiredConsents?.[1]

    const approveConsent = async (consentId: string) => {
        try {
            const res = await organization?.approveConsent(consentId)
            showSuccess(t('Your consent has been accepted'))
            if (res?.isNewAdmin && store.getMember) {
                store.getMember.setCurrentRole = OrganizationMemberRoles.ADMIN
                location.href = PAGES.ORGANIZATION_SETTINGS
                return true
            }
            return false
        } catch (e) {
            logger.error(e)
            showError(t('Failed to accept consent'))
        }
    }

    return {
        consent,
        nextConsent,
        approveConsent,
    }
}
