import { formatDuration } from '@tivio/common'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { PREVIOUS_SCROLL_POSITION_KEY } from '../../../static/constants'
import { PAGES } from '../../../static/enum'
import { TvProgram } from '../../../store/TvProgram'
import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'

import { ScheduleItem } from './ScheduleItem'


const TV_PROGRAM_TIME_FORMAT = 'HH:mm'

type Props = {
    item: TvProgram
    columnDate: Dayjs
    onDelete: (item: TvProgram) => void
    onClick?: (item: TvProgram) => void
    isLocked?: boolean
}

/**
 * Represents one TV Program as item in EPG Schedule.
 * Goes to video detail page on item click.
 */
export const EpgScheduleItem = ({ item, columnDate, onDelete, isLocked }: Props) => {
    const { computeHeightForItem } = useEpgUI()
    const { push } = useHistory()

    const title = item.name
    const titleShort = (item.shortName ?? item.name)?.substring(0, 40)
    const description = item.video?.getDescription ?? ''
    const image = item.image ?? ''

    const heightPx = computeHeightForItem(item, columnDate)

    const itemOverflow = (() => {
        if (item.overflownFrom(columnDate)) {
            return 'end'
        } else if (item.overflownTo(columnDate)) {
            return 'start'
        }
        return undefined
    })()

    const upperTitle = (() => {
        // if item is over midnight, then add date of another day
        const overflowFormat = `(DD MMM) ${TV_PROGRAM_TIME_FORMAT}`

        const fromFormat = itemOverflow === 'start' ? overflowFormat : TV_PROGRAM_TIME_FORMAT
        const toFormat = itemOverflow === 'end' ? overflowFormat : TV_PROGRAM_TIME_FORMAT

        const duration = formatDuration(item.getDuration('minute', { ignoreSmallerUnits: true }), 'minute')

        return `${item.from.format(fromFormat)} - ${item.to.format(toFormat)} (${duration})`
    })()

    const onItemDelete = (event?: React.MouseEvent) => {
        // prevent firing onItemClick
        event?.preventDefault()
        event?.stopPropagation()

        onDelete(item)
    }

    const onItemClick = () => {
        const videoId = item.video?.id
        if (videoId) {
            sessionStorage.setItem(PREVIOUS_SCROLL_POSITION_KEY, `${window.scrollY}`)
            push(`${PAGES.VIDEOS}/${videoId}`)
        }
    }

    return (
        <ScheduleItem
            title={title}
            titleShort={titleShort}
            upperTitle={upperTitle}
            description={description}
            image={image}
            heightPx={heightPx}
            overflow={itemOverflow}
            onDelete={isLocked ? undefined : onItemDelete}
            onClick={onItemClick}
        />
    )
}
