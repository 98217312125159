import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { useState } from 'react'

import { Actionable } from '../common/actions/actionable'


interface MenuDropdownItem {
    id: string
    name: string
    title?: string
    onEdit?: () => any
    onDelete?: () => any
    /** on click */
    action?: () => any
}

interface Props {
    icon: React.ReactNode
    name: string
    items: MenuDropdownItem[]
    dropDownAction?: {
        name: string
        action: () => void
    }
}

const MenuDropdown: React.FC<Props> = (props) => {
    const [ collapsed, setCollapsed ] = useState(false)

    const renderDropdownItems = () => {
        return props.items.map(
            item => (
                <ListItem
                    key={item.id}
                    onClick={item.action}
                    button
                >
                    <ListItemIcon/>
                    <ListItemText>
                        <Actionable
                            buttonSize="mini"
                            onEdit={item.onEdit}
                            onDelete={item.onDelete}
                        >
                            <Typography
                                variant="caption"
                                color="textSecondary"
                                title={item.title}
                            >
                                {item.name}
                            </Typography>
                        </Actionable>
                    </ListItemText>
                </ListItem>
            ),
        )
    }

    return (
        <>
            <ListItem
                onClick={() => setCollapsed(!collapsed)}
                button
            >
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText>
                    <Typography variant="body2">
                        {props.name}
                    </Typography>
                </ListItemText>
                {collapsed ? <ExpandLess/> : <ExpandMore/>}
            </ListItem>

            <Collapse
                in={collapsed}
                timeout="auto"
                unmountOnExit
            >
                <List disablePadding>
                    {renderDropdownItems()}
                    {props.dropDownAction && (
                        <ListItem
                            onClick={props.dropDownAction.action}
                            button
                        >
                            <ListItemIcon/>
                            <Box mr={1}>
                                <Add fontSize="small"/>
                            </Box>
                            <ListItemText secondary={props.dropDownAction.name}/>
                        </ListItem>
                    )}
                </List>
            </Collapse>
        </>
    )
}

export {
    MenuDropdown,
}

export type {
    MenuDropdownItem,
}
