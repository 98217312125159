import { Button, IconButton, Typography } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import { PricesField } from '@tivio/firebase'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { ExtendedPaidMonetizationVariant } from '../../store/Monetization'
import { useOrganization } from '../hooks'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import AddNewMonetizationVariantDialog, { NewVariantFormValues } from './AddNewMonetizationVariantDialog'
import SimplePriceInput from './SimplePriceInput'


interface Props {
    monetizationId: string
    monetizationType: 'transaction' | 'subscription'
    variants: ExtendedPaidMonetizationVariant[]
    onPricesChange: (variantId: string, prices: PricesField | null) => void
}

// TODO use margin spacing from theme
const StyledButton = styled(Button)`
    margin-left: -8px;
`

const PaidMonetizationVariantsList = ({monetizationId, monetizationType, variants, onPricesChange}: Props) => {
    const [ t ] = useTranslation()
    const {organization} = useOrganization()
    const {isDialogOpen, closeDialog, openDialog} = useDialog()
    const {confirmAction} = useConfirmAction()


    const handleAddVariant = ({ monetizationId: activatingMonetizationId, duration }: NewVariantFormValues) => {
        organization?.addMonetizationVariant(monetizationId, activatingMonetizationId, monetizationType, duration)
        closeDialog()
    }

    const handleDeleteVariant = (variant: ExtendedPaidMonetizationVariant) => {
        confirmAction(
            () => organization?.removeMonetizationVariant(monetizationId, variant.id ?? ''),
            `${t('Are you sure you want to delete monetization variant')} ${variant.name}?`,
        )
    }

    return (
        <Column spacing={4}>
            {variants
                .filter(variant => variant.type !== 'default')
                .map((variant: ExtendedPaidMonetizationVariant) => (
                    <Row key={variant.id} spacing={2} rowProps={{alignItems: 'center'}}>
                        <div>
                            <Typography>
                                {variant.name}
                            </Typography>
                            {variant.activatingDuration && <Typography variant="caption" color="textSecondary" title="s">
                                {variant.activatingDuration.value} {t(variant.activatingDuration.unit)}
                            </Typography>}
                        </div>
                        <SimplePriceInput
                            value={variant.prices ?? {}}
                            onChange={newVariantPrices =>
                                onPricesChange(variant.id ?? '', newVariantPrices)
                            }
                        />
                        <IconButton
                            size="small"
                            onClick={() => handleDeleteVariant(variant)}
                        >
                            <Delete/>
                        </IconButton>
                    </Row>
                ))}
            <StyledButton onClick={openDialog} startIcon={<Add/>}>
                {t('Add variant')}
            </StyledButton>
            <AddNewMonetizationVariantDialog
                monetizationId={monetizationId}
                showDuration={monetizationType === 'subscription'}
                open={isDialogOpen}
                onClose={closeDialog}
                onSubmit={handleAddVariant}
            />
        </Column>
    )
}

export default PaidMonetizationVariantsList
