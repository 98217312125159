import {
    AdProfileDocument,
    ChannelDocument,
    OrganizationDocument,
    PurchaseDocument,
    SectionDocument,
    UserDocument,
    VideoDataSource,
    VideoDocument,
} from '@tivio/firebase'
import { VideoContentType } from '@tivio/types'

import { millisecondsToSeconds, SECOND_IN_MILLISECONDS, secondsToFirebaseTimestamp } from '../../utils/time.utils'

import type { FirebaseDocumentData, FirestoreDocumentSnapshot } from '../app'
import type { DeviceFirestore } from './device'
import type { TEnvironmentFirestore } from './environment'
import type { EpgProgram, EpgProgramFirestore } from './epg'
import type { TInviteFirestore } from './invite'
import type { MarkerData, MarkerEpgFirestore, MarkerVodFirestore } from './markerEpg'
import type { TMemberFirestore } from './member'
import type { TSecretFirestore } from './secrets'
import type { TVersionFirestore } from './versions'
import type { WidgetFirestore } from './widget'


const markerEpgConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): MarkerData => {
        const markerData = snapshot.data() as MarkerEpgFirestore

        if (!markerData.to) {
            markerData.to = markerData.from
        }

        return {
            from: markerData.from.seconds,
            to: markerData.to.seconds,
            name: markerData.name,
            type: markerData.type,
        }
    },
    toFirestore: (markerData: MarkerData): FirebaseDocumentData => {
        return {
            from: secondsToFirebaseTimestamp(markerData.from),
            to: secondsToFirebaseTimestamp(markerData.to),
            name: markerData.name,
            type: markerData.type,
        }
    },
}

const markerVodConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): MarkerData => {
        const markerFirestore = snapshot.data() as MarkerVodFirestore

        if (!markerFirestore.toMs) {
            markerFirestore.toMs = markerFirestore.fromMs
        }

        return {
            ...markerFirestore,
            from: millisecondsToSeconds(markerFirestore.fromMs),
            to: millisecondsToSeconds(markerFirestore.toMs),
        }
    },
    toFirestore: (element: MarkerData): FirebaseDocumentData => {
        const markerData: MarkerVodFirestore = {
            type: element.type,
            fromMs: element.from * SECOND_IN_MILLISECONDS,
            toMs: element.to * SECOND_IN_MILLISECONDS,
        }

        if (element.name) {
            markerData.name = element.name
        }

        return markerData
    },
}

const epgConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): EpgProgram => {
        const programData = snapshot.data() as EpgProgramFirestore

        const from = programData.startTimestamp.seconds
        const to = programData.endTimestamp.seconds

        return {
            ...programData,
            id: programData.id,
            playTime: to - from,
            timeline: {
                from: from,
                to: to,
            },
            name: programData.programName,
        }
    },
    toFirestore: (epgProgram: EpgProgram): FirebaseDocumentData => {
        return epgProgram
    },
}

const inviteConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TInviteFirestore => {
        return snapshot.data() as TInviteFirestore
    },
    toFirestore: (invite: TInviteFirestore): FirebaseDocumentData => {
        return invite
    },
}

const organizationConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): OrganizationDocument => {
        return snapshot.data() as OrganizationDocument
    },
    toFirestore: (organization: OrganizationDocument): FirebaseDocumentData => {
        return organization
    },
}

const channelsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): ChannelDocument => {
        return snapshot.data() as ChannelDocument
    },
    toFirestore: (channel: ChannelDocument): FirebaseDocumentData => {
        return channel
    },
}

const channelSectionsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): SectionDocument => {
        return snapshot.data() as SectionDocument
    },
    toFirestore: (section: SectionDocument): FirebaseDocumentData => {
        return section
    },
}

const videoConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): VideoDocument => {
        return snapshot.data() as VideoDocument
    },
    toFirestore: (element: VideoDocument): FirebaseDocumentData => {
        return {
            ...element,
            created: element.created || new Date(),
            dataSource: {
                name: VideoDataSource.TIVIO_ADMIN,
            },
            contentType: element.contentType || VideoContentType.VIDEO,
        }
    },
}

const userConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): UserDocument => {
        return snapshot.data() as UserDocument
    },
    toFirestore: (user: UserDocument): FirebaseDocumentData => {
        return user
    },
}

const purchaseConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): PurchaseDocument => {
        return snapshot.data() as PurchaseDocument
    },
    toFirestore: (purhcase: PurchaseDocument): FirebaseDocumentData => {
        return purhcase
    },
}

const memberConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TMemberFirestore => {
        return snapshot.data() as TMemberFirestore
    },
    toFirestore: (member: TMemberFirestore): FirebaseDocumentData => {
        return member
    },
}

const widgetsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): WidgetFirestore => {
        return snapshot.data() as WidgetFirestore
    },
    toFirestore: (widget: WidgetFirestore): FirebaseDocumentData => {
        return widget
    },
}

const secretsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TSecretFirestore => {
        return snapshot.data() as TSecretFirestore
    },
    toFirestore: (secret: TSecretFirestore): FirebaseDocumentData => {
        return secret
    },
}

const versionsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TVersionFirestore => {
        return snapshot.data() as TVersionFirestore
    },
    toFirestore: (version: TVersionFirestore): FirebaseDocumentData => {
        return version
    },
}

const environmentsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TEnvironmentFirestore => {
        return snapshot.data() as TEnvironmentFirestore
    },
    toFirestore: (environment: TEnvironmentFirestore): FirebaseDocumentData => {
        return environment
    },
}

const devicesConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): DeviceFirestore => {
        return snapshot.data() as DeviceFirestore
    },
    toFirestore: (device: DeviceFirestore): FirebaseDocumentData => {
        return device
    },
}

export type AdProfileDocumentWithId = AdProfileDocument & { id: string }

const adProfilesConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot) => {
        return {
            id: snapshot.id,
            ...snapshot.data(),
        } as AdProfileDocumentWithId
    },
    toFirestore: (adProfile: AdProfileDocumentWithId) => {
        return {
            ...adProfile,
            skippability: {
                ...adProfile.skippability,
                ms: (adProfile.skippability.ms)
                    ? adProfile.skippability.ms
                    : null,
            },
        }
    },
}

export {
    organizationConverter,
    channelsConverter,
    channelSectionsConverter,
    videoConverter,
    memberConverter,
    epgConverter,
    inviteConverter,
    markerEpgConverter,
    markerVodConverter,
    widgetsConverter,
    secretsConverter,
    versionsConverter,
    environmentsConverter,
    devicesConverter,
    adProfilesConverter,
    userConverter,
    purchaseConverter,
}
