import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { alertError } from '../../../utils/alert.utils'
import { AdProfilesTab } from '../../adProfile'
import { StrategiesTab } from '../../ads'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs, PageTab } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks/dataHooks/useOrganization'

import type { AdProfileDocumentWithId } from '../../../firebase/firestore/converters'


const AdsPage = () => {
    const [ t ] = useTranslation()
    const { organization } = useOrganization()
    const [ areAdProfilesLoaded, setAreAdProfilesLoaded ] = useState(false)
    const [ adProfiles, setAdProfiles ] = useState<AdProfileDocumentWithId[]>([])

    const loadAdProfile = () => {
        if (organization) {
            organization.getAdProfiles()
                .then((adProfiles) => {
                    setAdProfiles(adProfiles)
                })
                .catch((error) => {
                    alertError(error)
                })
                .finally(() => {
                    setAreAdProfilesLoaded(true)
                })
        }
    }

    const deleteAdProfile = async (id: string) =>{
        if (organization) {
            await organization.deleteAdProfile(id)
            setAdProfiles(adProfiles.filter((adProfile) => adProfile.id !== id))
        }
    }

    useEffect(() => {
        if (!areAdProfilesLoaded) {
            loadAdProfile()
        }
    }, [areAdProfilesLoaded])

    const sectionTabs: PageTab[] =[
        {
            component: <StrategiesTab adProfiles={adProfiles} />,
            title: t('Strategies'),
        },
        {
            component: <AdProfilesTab
                adProfiles={adProfiles}
                handleDeleteAdProfile={deleteAdProfile}
            />,
            title: t('Profiles'),
        },
    ]

    return (
        <>
            <DefaultPageHeader title={t('Advertisement')}/>
            <DefaultPageTabs
                defaultTabIndex={0}
                tabs={sectionTabs}
            />
        </>
    )
}

export default AdsPage
