import { tivio } from '@tivio/core-js'
import { GenericOnCallError } from '@tivio/types'
import { useCallback, useState } from 'react'


/**
 * @see useApplyInviteCode in @tivio/sdk-react
 */
export function useApplyInviteCode() {
    const [applyInviteCodeResult, setApplyInviteCodeResult] = useState<boolean | undefined>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<GenericOnCallError | undefined>()

    const applyInviteCode = useCallback((code: string) => {
        (async () => {
            try {
                setApplyInviteCodeResult(undefined)
                setError(undefined)
                setLoading(true)
                const result = await tivio.applyInviteCode(code)
                setApplyInviteCodeResult(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    const inviteCodeReset = useCallback(() => {
        setApplyInviteCodeResult(undefined)
        setError(undefined)
        setLoading(false)
    }, [])

    return {
        applyInviteCodeResult,
        loading,
        error,
        applyInviteCode,
        inviteCodeReset,
    }
}
