import {
    ClickAwayListener,
    Fade,
    IconButton,
    makeStyles,
    Paper,
    TextareaAutosize,
    Typography,
    TypographyProps,
} from '@material-ui/core'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'


interface Props {
    children: string
    onTextChange?: (text: string) => void
    typographyProps?: TypographyProps
    textarea?: boolean
    editOnClick?: boolean
    onDelete?: () => void
}

const useStyles = makeStyles((theme) => ({
    editorContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    editorInterfaceContainer: {
        position: 'absolute',
        top: '50%',
        right: 0,
        width: 80,
        transform: 'translateY(-50%) translateX(100%)',
    },
    editorCommitActionContainer: {
        backgroundColor: theme.palette.common.white,
        marginTop: theme.spacing(1),
        position: 'absolute',
        zIndex: 1000,
    },
    icon: {
        fontSize: '1rem',
    },
    input: {
        all: 'unset',
        width: '100%',
    },
}))

/**
 * Updatable text component
 * Wraps text. Allows to update wrapped text
 */
const UpdatableTypography = (props: Props) => {
    const [ edit, setEdit ] = useState(false)
    const [ text, setText ] = useState(props.children)
    const [ showInterface, setShowInterface ] = useState(false)
    const classes = useStyles()
    const ref = useRef<HTMLInputElement & HTMLTextAreaElement>(null)

    useEffect(
        () => {
            setText(props.children)
        },
        [ props.children ],
    )

    useEffect(
        () => {
            if (edit) {
                ref.current?.select()
                setShowInterface(false)
            }
        },
        [ edit ],
    )

    const startEdit = (e: React.MouseEvent) => {
        e.stopPropagation()

        setEdit(true)
    }

    const commitUpdate = (e?: React.MouseEvent) => {
        if (e) {
            e.stopPropagation()
        }

        if (text) {
            props.onTextChange?.(text)
        }
        else {
            setText(props.children)
        }

        setEdit(false)
    }

    const cancelUpdate = (e: React.MouseEvent) => {
        e.stopPropagation()

        rollbackChanges()
    }

    const rollbackChanges = () => {
        setText(props.children)
        setEdit(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (e.key === 'Escape') {
            rollbackChanges()
        }

        if (e.key === 'Enter') {
            commitUpdate()
        }
    }

    const baseInputProps = {
        onChange: (e: ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => setText(e.target.value),
        onKeyDown: handleKeyDown,
        className: classes.input,
        ref: ref,
        value: text,
    }

    const onDelete = (e: React.MouseEvent) => {
        e.stopPropagation()

        props.onDelete && props.onDelete()
    }

    return (
        <>
            {!edit && (
                <div
                    className={classes.editorContainer}
                    onMouseEnter={() => setShowInterface(true)}
                    onMouseLeave={() => setShowInterface(false)}
                    onClick={(e) => props.editOnClick && startEdit(e)}
                >
                    <Typography
                        {...props.typographyProps}
                    >
                        {props.children}
                    </Typography>
                    {(showInterface) && (
                        <Fade in={true}>
                            <div className={classes.editorInterfaceContainer}>
                                {props.onTextChange && (
                                    <IconButton onClick={startEdit}>
                                        <Create className={classes.icon}/>
                                    </IconButton>
                                )}
                                {props.onDelete && (
                                    <IconButton onClick={onDelete}>
                                        <Delete className={classes.icon}/>
                                    </IconButton>
                                )}
                            </div>
                        </Fade>
                    )}
                </div>
            )}
            {edit && (
                <ClickAwayListener onClickAway={() => commitUpdate()}>
                    <Typography {...props.typographyProps}>
                        {
                            props.textarea
                                ? (
                                    <TextareaAutosize
                                        {...baseInputProps}
                                        rowsMin={3}
                                    />
                                )
                                : (
                                    <input
                                        {...baseInputProps}
                                        type="text"
                                    />
                                )

                        }
                        <Paper className={classes.editorCommitActionContainer}>
                            <IconButton onClick={commitUpdate}>
                                <CheckCircle color="primary"/>
                            </IconButton>
                            <IconButton onClick={cancelUpdate}>
                                <Cancel color="error"/>
                            </IconButton>
                        </Paper>
                    </Typography>
                </ClickAwayListener>
            )}
        </>
    )
}

export default UpdatableTypography
