import { Divider } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import UpdatableTypography from '../UpdatableTypography'


interface Props {
    onUpdateName: (name: string) => void
    children: string
}

const StyledDivider = styled(Divider)`
    margin: 1rem 0 2rem;
`

const MonetizationPageTitle: React.FC<Props> = (props) => {

    return (
        <>
            <UpdatableTypography
                onTextChange={props.onUpdateName}
                editOnClick={true}
                typographyProps={{
                    variant: 'h5',
                }}
            >
                {props.children}
            </UpdatableTypography>
            <StyledDivider/>
        </>
    )
}

export {
    MonetizationPageTitle,
}
