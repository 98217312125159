import { Grid, IconButton, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import Menu from '@material-ui/icons/Menu'
import { AdProfileType } from '@tivio/firebase'
import clsx from 'clsx'
import React, { FC } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'

import { goAdProfileDetailPage } from '../../utils/history.utils'
import { ColorCircle } from '../color'


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: '1rem',
    },
    strategyType: {
        flexGrow: 1,
    },
    strategyProfileInfo: {
        width: '344px',
    },
    marginRight: {
        marginRight: '10px',
    },
    adSlotRow: {
        padding: '0 22px',
        margin: '5px 0',
        '&:hover': {
            '& .showAdSlotControls': {
                opacity: 1,
            },
        },
    },
    adSlotControls: {
        opacity: 0,
    },
}))

interface StrategyRowAdSlotProps {
    id: string
    name: string
    color?: string
    type: AdProfileType
    index: number
    handleDeleteAdSlot: (id: string) => void
}

export const StrategyRowAdSlot: FC<StrategyRowAdSlotProps> = ({ id, name, color, type, index, handleDeleteAdSlot }) => {
    const classes = useStyles()
    const [ t ] = useTranslation()

    // adSlots can contains duplicated profiles (with the same id), so index is necessary
    const idWithIndex = `${id}-${index}`

    return (
        <Draggable
            draggableId={idWithIndex}
            key={idWithIndex}
            index={index}
        >
            {provided => (
                <Grid
                    item
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className={classes.adSlotRow}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="baseline"
                    >
                        <Grid
                            item
                            className={classes.marginRight}
                        >
                            <Menu className={classes.icon}/>
                        </Grid>
                        <Grid
                            item
                            className={classes.marginRight}
                        >
                            <ColorCircle color={color}/>
                        </Grid>
                        <Grid
                            item
                            className={classes.strategyType}
                        >
                            <Typography variant="subtitle1">{name}</Typography>
                        </Grid>
                        <Grid
                            item
                            className={classes.strategyProfileInfo}
                        >
                            {type === AdProfileType.ONLINE && t('Highest bid')}
                        </Grid>
                        <Grid item className={clsx(classes.adSlotControls, 'showAdSlotControls')}>
                            <IconButton size="small" onClick={() => handleDeleteAdSlot(id)}>
                                <Delete className={classes.icon}/>
                            </IconButton>
                        </Grid>
                        <Grid item className={clsx(classes.adSlotControls, 'showAdSlotControls')}>
                            <IconButton size="small" onClick={() => goAdProfileDetailPage(id)}>
                                <Create className={classes.icon}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Draggable>
    )
}
