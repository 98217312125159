import { Button } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import RedeemIcon from '@material-ui/icons/Redeem'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import YouTubeIcon from '@material-ui/icons/YouTube'
import { QerkoCancelSecret } from '@tivio/firebase'
import { MONETIZATION_FREQUENCY, MonetizationType, PurchaseDocumentGateway, PurchaseStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import Purchase from '../../store/Purchase'
import { useListItemStyles } from '../common/styles'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { PurchaseStatusSelect } from '../common/PurchaseStatusSelect'
import { Column } from '../uiLayout/Column'
import { UserPurchaseItemVideo } from './UserPurchaseItemVideo'
import { useStyles } from './userStyles'
import { UserItemNonDrm } from './UserItemNonDrm'


type PurchaseItemProps = {
    purchase: Purchase
}

const NoName = () => {
    const [t] = useTranslation()
    const classes = useStyles()

    return (
        <i className={classes.noname}>{t('Name could not be loaded')}</i>
    )
}

const TypeIcon = ({ type }: { type: MonetizationType | 'voucherUsage' | 'voucherPurchase' }) => {
    const classes = useStyles()
    let Icon

    switch (type) {
        case 'subscription':
            Icon = SubscriptionsIcon
            break
        case 'transaction':
            Icon = YouTubeIcon
            break
        case 'voucherPurchase':
            Icon = RedeemIcon
            break
        case 'voucherUsage':
            Icon = SystemUpdateAltIcon
            break
        default:
            return null
    }

    return <Icon
        fontSize="small"
        className={classes.typeIcon}
    />
}

const RefundButton = styled(Button)`
    font-size: 12px;
`

export const UserPurchaseItem: React.FC<PurchaseItemProps> = observer(({ purchase }) => {
    const classes = useStyles()
    const listItemClasses = useListItemStyles()
    const [t] = useTranslation()
    const { showSuccess, showError } = useAlert()
    const { confirmAction } = useConfirmAction()
    const [qerkoCancelSecret, setQerkoCancelSecret] = useState<QerkoCancelSecret>()

    useEffect(() => {
        purchase.qerkoCancelSecret.then(setQerkoCancelSecret)
    }, [purchase.qerkoCancelSecret])

    const changePurchaseStatus = async (status: PurchaseStatus) => {
        confirmAction(
            async () => {
                try {
                    await purchase.changeStatus(status)
                    showSuccess(t('Purchase status set to {{status}}', { status }))
                } catch {
                    showError(t('Failed to set purchase status to {{status}}', { status }))
                }
            },
            t('Are you sure you want set purchase {{purchaseId}} status to {{status}}?', { purchaseId: purchase.id, status }),
        )
    }

    const itemIsDisabled = (status: PurchaseStatus) => {
        const STATUSES_DISABLED_FOR_SUBSCRIPTIONS = [
            PurchaseStatus.PAID,
            PurchaseStatus.RENEWED,
        ]
        // Setting same status does not make sense
        if (status === purchase.status) {
            return true
        }

        // For subscriptions, we want to disable setting it to PAID, because we can't:
        // 1. Set appropriate state in Qerko
        // 2. Restore previous expiration date (we set it to past when subscription is CANCELLED)
        if (purchase.type === 'subscription' && STATUSES_DISABLED_FOR_SUBSCRIPTIONS.includes(status)) {
            return true
        }

        return false
    }

    const handleRefundClick = () => {
        confirmAction(async () => {
            try {
                await purchase.refundAndCancel()
                showSuccess(t('Purchase refunded and cancelled.'))
            } catch (err) {
                console.error(err)
                showError(t('An error occurred while cancelling the purchase.'))
            }
        }, t('Are you sure you want to refund and cancel this purchase?'))
    }

    const isOneTimePayment = purchase.type === 'transaction'
        || (purchase.type === 'subscription' && purchase.monetizationFrequency === MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT)

    const canRefund = (
        isOneTimePayment &&
        purchase.gateway === PurchaseDocumentGateway.qerko &&
        purchase.status === PurchaseStatus.PAID &&
        qerkoCancelSecret
    )

    const purchaseType = (() => {
        if (purchase.isVoucherUsage) {
            return 'voucherUsage'
        }

        if (purchase.isVoucherPurchase) {
            return 'voucherPurchase'
        }

        return purchase.type
    })()

    return (
        <Box my={1}>
            <Grid
                container
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
                className={listItemClasses.root}
                wrap="nowrap"
            >
                <Grid
                    item
                    xs={3}
                >
                    <Column spacing={1}>
                        {purchase.monetizationName || <NoName />}
                        {purchase.videoId && <UserPurchaseItemVideo videoId={purchase.videoId} />}
                        <UserItemNonDrm purchase={purchase} />
                    </Column>
                </Grid>
                <Grid
                    item
                    xs={2}
                ><TypeIcon type={purchaseType} />{purchaseType}</Grid>
                <Grid
                    item
                    xs={2}
                >
                    {new Date(purchase.created).toLocaleString()}
                    {purchase.expired && <div>{new Date(purchase.expired).toLocaleString()}</div>}
                </Grid>
                <Grid
                    item
                    xs={2}
                    className={classes.secondary}
                >
                    {purchase.id}
                </Grid>
                <Grid
                    item
                    xs={2}
                >
                    <PurchaseStatusSelect
                        onChange={changePurchaseStatus}
                        value={purchase.status}
                        itemIsDisabled={itemIsDisabled}
                    />
                </Grid>

                <Grid
                    item
                    xs
                >
                    <RefundButton
                        disabled={!canRefund}
                        onClick={handleRefundClick}
                    >{t('Refund & deactivate')}</RefundButton>
                </Grid>
            </Grid>
        </Box>
    )
})
