import { Input, ListSubheader, MenuItem, Select } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Header } from '../common'
import { useSeries } from '../hooks/dataHooks/useSeries'
import { InputWrapper } from '../services/email/shared/InputWrapper'

import type { Article } from '../../store/Article'



const REMOVE_SERIES_KEY = 'clear'

interface Props {
    content: Article
    disabled: boolean
}

export const ContentSeriesConfiguration: React.FC<Props> = observer(({ content, disabled }) => {
    const [t] = useTranslation()
    const { series } = useSeries()

    const handleOnChange = (id: string) => {
        if (id === REMOVE_SERIES_KEY) {
            content.updateSeriesRef(undefined)
            return
        }
        const ref = series.find(serie => serie.ref.id === id)?.ref
        if (ref) {
            content.updateSeriesRef(ref)
        }
    }

    return (
        <>
            <Header
                title={t('Series configuration')}
            />
            <InputWrapper label={t('Series')}>
                <Select
                    value={content.seriesRef?.id}
                    onChange={(e) => {
                        handleOnChange(e.target.value as string)
                    }}
                >
                    <MenuItem
                        key={REMOVE_SERIES_KEY}
                        value={REMOVE_SERIES_KEY}
                    >
                        {t('None')}
                    </MenuItem>
                    <ListSubheader>
                        <span>{t('Series list')}</span>
                    </ListSubheader>
                    {
                        series.map(serie => (
                            <MenuItem
                                key={serie.ref.id}
                                value={serie.ref.id}
                                defaultChecked={content.seriesRef?.id === serie.ref.id}
                            >
                                {serie.name.cs}
                            </MenuItem>
                        ),
                        )
                    }
                </Select>
            </InputWrapper>
            {
                content.seriesRef && (
                    <>
                        <InputWrapper label={t('Season number')}>
                            <Input
                                type="number"
                                value={content.seasonNumber}
                                onChange={(e) => content.updateSeasonNumber(Number(e.target.value))}
                                placeholder={t('Season number')}
                            />
                        </InputWrapper>
                        <InputWrapper label={t('Episode number')}>
                            <Input
                                type="number"
                                value={content.episodeNumber}
                                onChange={(e) => content.updateEpisodeNumber(Number(e.target.value))}
                                placeholder={t('Episode number')}
                            />
                        </InputWrapper>
                    </>
                )
            }
        </>
    )
})
