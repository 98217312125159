import { MenuItem, Select } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useRef } from 'react'

import { TivioNotification } from '../../../../store/Notification'
import { InputWrapper } from '../shared/InputWrapper'

import type { NotificationTriggerType } from '@tivio/types'


const isTriggerWithSurveyOrLocationFilter = (triggerType: NotificationTriggerType) => {
    return triggerType === 'manual' || triggerType === 'scheduled'
}

interface Props {
    notification: TivioNotification
    label: string
    options: Record<NotificationTriggerType, string>
    disabled: boolean
}

export const SendTypeSelect = observer(({ notification, label, options, disabled }: Props) => {
    const prevTriggerType = useRef(notification.triggerType)
    return (
        <InputWrapper label={label}>
            <Select
                label={label}
                value={notification.triggerType}
                onChange={(e) => {
                    const newTriggerType = e.target.value as NotificationTriggerType
                    if (!isTriggerWithSurveyOrLocationFilter(newTriggerType)) {
                        notification.targeting = null
                    }
                    notification.triggerType = newTriggerType
                    prevTriggerType.current = notification.triggerType
                }}
                disabled={disabled}
            >
                {Object.entries(options).map(([triggerType, label]) => (
                    <MenuItem
                        key={triggerType}
                        value={triggerType}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </InputWrapper>
    )
})
