import { Button, ThemeProvider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CalendarToday from '@material-ui/icons/CalendarToday'
import { DateTimePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'

import { lightTheme } from '../../static/theme'
import { Interval } from '../../utils/epg.utils'
import { secondsToMilliseconds } from '../../utils/time.utils'


export interface EpgTimepickerProps {
    onTimeChange: (timeline: Interval) => void
    currentEpg: Interval
}

const useStyles = makeStyles({
    timepicker: {
        display: 'none',
    },
})

const EpgTimepicker = observer((props: EpgTimepickerProps) => {
    const classes = useStyles()
    const [datepickerIsOpen, setDatepickerIsOpen] = useState(false)
    const [selectedDate, setSelectedDate] = React.useState<Dayjs>(dayjs())

    useEffect(
        () => {
            setSelectedDate(dayjs(new Date(secondsToMilliseconds(props.currentEpg.from))))
        },
        [props.currentEpg],
    )

    const handleDateChange = (date: Dayjs | null) => {
        if (date) {
            setSelectedDate(date)

            const startTimestamp = date.unix()

            const endTimestamp = date
                .clone()
                .endOf('day')
                .unix()

            props.onTimeChange({
                from: startTimestamp,
                to: endTimestamp,
            })
        }
    }

    const timelineWindowStartTimestamp = dayjs(new Date(secondsToMilliseconds(props.currentEpg.from)))
    const timelineWindowEndTimestamp = dayjs(new Date(secondsToMilliseconds(props.currentEpg.to)))

    return (
        <>
            <ThemeProvider theme={lightTheme}>
                <DateTimePicker
                    ampm={false}
                    className={classes.timepicker}
                    onChange={handleDateChange}
                    onClose={() => setDatepickerIsOpen(false)}
                    open={datepickerIsOpen}
                    showTodayButton
                    value={selectedDate}
                    variant="dialog"
                />
            </ThemeProvider>
            <Button
                onClick={() => setDatepickerIsOpen(!datepickerIsOpen)}
                size="small"
                startIcon={<CalendarToday />}
            >
                {timelineWindowStartTimestamp.format('DD.MM. HH:mm')} - {timelineWindowEndTimestamp.format('HH:mm')}
            </Button>
        </>
    )
})

export default EpgTimepicker
