import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Membership } from '../../../store/Membership'
import Monetization from '../../../store/Monetization'
import { goSubscriptionPage } from '../../../utils/history.utils'
import { Listing } from '../../common/listing/Listing'
import { ListingItem } from '../../common/listing/ListingRow'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useMemberships } from '../../hooks/dataHooks/useMemberships'
import { useSubscriptions } from '../../hooks/dataHooks/useSubscriptions'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { formatPlacementDescription } from '../../monetization/MonetizationPlacementsList'


const formatDescription = (monetization: Monetization) => {
    const prices = monetization.getPrices
    let description = ''
    if (prices.CZK) {
        description += `${prices.CZK} Kč`
    }
    if (prices.EUR || prices.USD) {
        description += `(${[
            prices.EUR && `${prices.EUR} €`,
            prices.USD && `${prices.USD} $`,
        ].filter((item) => !!item).join(', ')})`
    }
    return description
}

const formatMembershipDescription = (subscription: Monetization, memberships: Membership[]) => {
    if (!subscription.getMembershipRef) {
        return ''
    }
    return memberships.find(m => m.getId === subscription.getMembershipRef?.id)?.getName ?? ''
}

const subscriptionsConverter = (subscription: Monetization, memberships: Membership[]): ListingItem => ({
    getId: subscription.getId,
    getName: subscription.getName,
    extraColumns: [
        formatDescription(subscription),
        formatPlacementDescription(subscription),
        formatMembershipDescription(subscription, memberships),
    ],
})

export const SubscriptionsPage = observer(() => {
    const { memberships } = useMemberships()
    const { subscriptions, addSubscription, removeSubscription } = useSubscriptions()
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()

    const handleDeleteSubscription = (name: string, id: string) => {
        confirmAction(
            () => removeSubscription(id),
            t(`Are you sure you want to delete the monetization "${name}"?`),
        )
    }

    return (
        <DefaultPageContainer
            title={t('Subscriptions')}
            buttons={[
                <Button
                    key="button-add-ad"
                    startIcon={<Add/>}
                    component="span"
                    size="small"
                    onClick={addSubscription}
                >
                    {t('Add subscription')}
                </Button>,
            ]}
        >
            <Listing
                items={subscriptions.map(s => subscriptionsConverter(s, memberships))}
                onClick={subscription => goSubscriptionPage(subscription.getId)}
                onUpdateClick={subscription => goSubscriptionPage(subscription.getId)}
                onDeleteClick={subscription => handleDeleteSubscription(subscription.getName, subscription.getId)}
            />
        </DefaultPageContainer>
    )
})

export default SubscriptionsPage
