import { followOrganization } from '@tivio/firebase'
import { useCallback, useMemo, useState } from 'react'

import { useError } from './useError'
import { useUser } from './useUser'


const actionWithLoadingAndError = async ({
    organizationId,
    action,
    setIsLoading,
    raiseError,
}: {
    organizationId: string
    action: 'follow' | 'unfollow',
    setIsLoading: (isLoading: boolean) => void
    raiseError: (error: Error) => void
}) => {
    setIsLoading(true)

    try {
        await followOrganization({
            organizationId,
            action,
        })
    } catch (error) {
        raiseError(error)
    } finally {
        setIsLoading(false)
    }
}

export const useFollowOrganization = (organizationId: string) => {
    const user = useUser()
    const [isLoading, setIsLoading] = useState(false)
    const { error, raiseError } = useError()

    const isFollowing = useMemo(() =>
        user.user?.followedOrganizationIds?.includes(organizationId) ?? false,
    [user.user?.followedOrganizationIds, organizationId])

    const follow = useCallback(async () => {
        await actionWithLoadingAndError({
            organizationId,
            action: 'follow',
            setIsLoading,
            raiseError,
        })
    }, [organizationId])

    const unfollow = useCallback(async () => {
        await actionWithLoadingAndError({
            organizationId,
            action: 'unfollow',
            setIsLoading,
            raiseError,
        })
    }, [organizationId])

    return {
        follow,
        unfollow,
        isFollowing,
        isLoading,
        error,
    }
}




