import { makeAutoObservable } from 'mobx'

import { addTivioRow, getRowsByScreen } from '../firebase/firestore/row'
import { alertError } from '../utils/alert.utils'
import { translate } from '../utils/translate.utils'

import { DataState } from './common/DataState'

import type Organization from './Organization'
import type { TivioRow } from './TivioRow'
import type { ScreenDocument, ScreenRowDocumentCreation } from '@tivio/firebase'
import type firebase from 'firebase/app'


class TivioScreen {
    private _rowsState: DataState<TivioRow[]> = new DataState<TivioRow[]>([])

    constructor(
        private _ref: firebase.firestore.DocumentReference<ScreenDocument>,
        private data: ScreenDocument,
        private _organization: Organization,
    ) {
        makeAutoObservable(this)
    }

    addRow = async (rowData: ScreenRowDocumentCreation) => {
        const newRow = await addTivioRow(this.organization.id, this, rowData)
        this.rows = this.rows.concat([newRow])
    }

    loadRows = (): Promise<void> => {
        return this._rowsState.loadData(
            () => getRowsByScreen(this),
            () => Promise.resolve(),
            async (e) =>  {
                alertError(translate('Failed to load rows'))
                console.error(e)
            })
    }

    update = async (data: Partial<ScreenDocument>) => {
        try {
            this.data = {
                ...this.data,
                ...data,
            }
            await this._ref.update(data)
        } catch (e) {
            alertError(translate('Failed to update'))
            console.error(e)
        }
    }

    get screenId() {
        return this.data.screenId
    }

    get id() {
        return this._ref.id
    }

    get name() {
        return this.data.name
    }

    get description() {
        return this.data.description
    }

    get ref() {
        return this._ref
    }

    get rowsState() {
        return this._rowsState
    }

    get rows() {
        return this._rowsState.data
    }

    set rows(rows: TivioRow[]) {
        this._rowsState.data = rows
    }

    get organization() {
        return this._organization
    }

    get type() {
        return this.data.type
    }
}

export {
    TivioScreen,
}
