import { OrganizationMemberRoles } from '@tivio/firebase'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { addOrganization, getOrganizationAdmin, listOrganizations } from '../firebase/firestore/organization'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'

import type Member from './Member'

import OrganizationAdmin from './OrganizationAdmin'

import type { OrganizationDocument } from '@tivio/firebase'


const logger = new Logger('Admin')

class Admin {
    private organizations: OrganizationAdmin[] = []
    private readonly member: Member

    constructor(member: Member) {
        this.member = member

        makeAutoObservable(this)
    }

    listOrganizations = async (member: Member) => {
        try {
            if (member.getCurrentRole >= OrganizationMemberRoles.SUPER_ADMIN) {
                this.setOrganizations = await listOrganizations(member)
            }
            else {
                this.setOrganizations = await getOrganizationAdmin(member.getCurrentOrganization, member)
            }
        }
        catch (e) {
            alertError(i18n.t('Failed to fetch admin page data'))
            console.error(e)
        }
    }

    addOrganization = async (organizationData: OrganizationDocument) => {
        try {
            const organizationRef = await addOrganization(organizationData)
            const organizationAdmin = new OrganizationAdmin(organizationRef, organizationData, [], [])

            this.setOrganizations = this.getOrganizations.concat([ organizationAdmin ])

            alertSuccess(i18n.t('Organization added'))
        }
        catch (e) {
            alertError(i18n.t('Failed to add organization'))
            logger.error(e)
        }
    }

    get getOrganizations() {
        return this.organizations
    }

    get getMember() {
        return this.member
    }

    set setOrganizations(organizations: OrganizationAdmin[]) {
        this.organizations = organizations
    }
}

export default Admin
