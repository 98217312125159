import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'


export const VideoListSkeleton = ({ alt }: { alt?: boolean }) => (
    <Box>
        <Grid
            container
            spacing={4}
        >
            <Grid item>
                <Skeleton
                    width={190}
                    height={107}
                    variant="rect"
                    style={{ borderRadius: '.5rem' }}
                />
            </Grid>
            <Grid
                item
                xs
            >
                <Box pt={2}>
                    <Skeleton
                        width={alt ? '40%' : '35%'}
                        height={32}
                    />
                </Box>
                <Box pt={1}>
                    <Skeleton
                        width={alt ? '75%' : '66%'}
                        height={16}
                    />
                </Box>
                <Box pt={0.5}>
                    <Skeleton
                        width="60%"
                        height={16}
                    />
                </Box>
            </Grid>
        </Grid>
    </Box>
)
