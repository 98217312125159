import { VideoDocument } from '@tivio/firebase'

import Organization from '../store/Organization'
import Section from '../store/Section'
import Video from '../store/Video'

import type firebase from 'firebase/app'


const createGlobalVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    organization: Organization,
): Video => {
    return new Video(
        ref,
        data,
        organization,
        {
            source: data.url?.source,
            hls: data.url?.hls,
            dash: data.url?.dash,
        },
    )
}

const createVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    organization: Organization,
): Video => {
    return new Video(
        ref,
        data,
        organization,
    )
}

const createSectionVideo = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    section: Section,
    organization: Organization,
    playlist?: Video,
): Video => {
    return new Video(
        ref,
        data,
        organization,
        {
            source: data.url?.source,
            hls: data.url?.hls,
            dash: data.url?.dash,
        },
        section,
        playlist,
    )
}

const createSectionPlaylist = (
    ref: firebase.firestore.DocumentReference<VideoDocument>,
    data: VideoDocument,
    section: Section,
    organization: Organization,
) => {
    return new Video(
        ref,
        data,
        organization,
        undefined,
        section,
    )
}

export {
    createGlobalVideo,
    createVideo,
    createSectionPlaylist,
    createSectionVideo,
}
