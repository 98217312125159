import { useMemo } from 'react'

import { useEpisodes } from './useEpisodes'
import { useTag } from './useTag'
import { useTaggedArticles } from './useTaggedArticles'
import { useVideo } from './useVideo'


const useSeriesDetailScreen = (tagId: string) => {
    const { tag } = useTag(tagId)
    const { episodes } = useEpisodes(tag?.tagId, tag?.availableSeasons?.[0]?.seasonNumber ?? 1, { limit: 1, fetchTags: false })
    const { pagination: articles } = useTaggedArticles(tag?.tagId)
    const firstEpisode = episodes.find(episode => episode.episodeNumber === 1)
    const { trailer, data: video } = useVideo(firstEpisode?.id, {
        fetchTrailer: true,
    })

    const actors = useMemo(() => {
        if (video?.tags.some(tag => !tag.name)) {
            return []
        }

        return video?.tags.filter(tag => tag.type === 'actor')
    }, [video])

    const genreTagsNames = useMemo(() => {
        if (!video || video.tags.some(tag => !tag.name)) {
            return ''
        }

        return video.tags
            .filter(tag => tag.type === 'genre')
            .map(tag => tag.name)
            .join(', ')
    }, [video])

    const captions = genreTagsNames ? [genreTagsNames] : []
    if (video?.year) {
        captions.unshift(String(video.year))
    }

    return {
        video,
        actors,
        articles,
        trailer,
        captions,
        tag,
    }
}

export {
    useSeriesDetailScreen,
}
