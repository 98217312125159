import { OrganizationMemberRoles } from '@tivio/firebase'
import i18n from 'i18next'

import Member from '../store/Member'


const roles = [
    OrganizationMemberRoles.SUPER_ADMIN,
    OrganizationMemberRoles.ADMIN,
    OrganizationMemberRoles.GUEST,
]

const translateRoleValueToString = (role: OrganizationMemberRoles) => {
    switch (role) {
        case OrganizationMemberRoles.ADMIN:
            return i18n.t('Admin')
        case OrganizationMemberRoles.GUEST:
            return i18n.t('Guest')
        case OrganizationMemberRoles.SUPER_ADMIN:
            return i18n.t('Super admin')
        default:
            return i18n.t('No role')
    }
}

const getRoles = (member: Member) => {
    return roles.filter(role => member.getCurrentRole >= role)
}

export {
    getRoles,
    translateRoleValueToString,
}
