import { useConfig } from './useConfig'


const useAssets = () => {
    const { assets } = useConfig()

    return {
        assets,
    }
}

export {
    useAssets,
}
