export const getFileExtension = (filename: string) => {
    return filename.replace(/^.*\./, '')
}

export const readFileAsDataUri = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = () => {
            if (reader.result && typeof reader.result === 'string') {
                resolve(reader.result)
            } else {
                throw new Error('File read successfully, but has not content')
            }
        }

        reader.onerror = () => {
            reject(new Error('File read error'))
        }

        reader.onabort = () => {
            reject(new Error('File read aborted'))
        }

        reader.readAsDataURL(file)
    })
}
