import store from '../store'


const alertError = (message: string) => {
    store.getAlert.showErrorAlert(message)
}

const alertSuccess = (message: string) => {
    store.getAlert.showSuccessAlert(message)
}

export {
    alertError,
    alertSuccess,
}
