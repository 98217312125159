import { tivio, UserAuthStatus, User as UserEntity } from '@tivio/core-js'
import { User } from '@tivio/types'
import React, { createContext, PropsWithChildren, useEffect, useState } from 'react'


interface UserContextState {
    user: User | null,
    error: any,
    isInitialized: boolean,
    isSignedIn: boolean,
}

export const UserContext = createContext<UserContextState>({
    user: null,
    error: null,
    isInitialized: false,
    isSignedIn: false,
})

export const UserContextProvider: React.FC<PropsWithChildren> = (props) => {
    const [user, setUser] = useState<User | null>(null)
    const [isInitialized, setIsInitialized] = useState(false)
    const [error, setError] = useState<null | string>(null)

    useEffect(() => {
        const disposer = tivio.subscribeToUser(
            (error, user, isUserInitialized) => {
                setIsInitialized(isUserInitialized)

                // TODO: this is never true, check subscribeToUser function
                if (error) {
                    setError(error)
                    return
                }

                setUser(user)
            },
        )

        return () => {
            disposer()
        }
    }, [])

    return (
        <UserContext.Provider
            value={{
                user,
                error,
                isInitialized,
                isSignedIn: UserEntity.getUserAuthStatus() === UserAuthStatus.SIGNED_IN && !UserEntity.currentUser?.isAnonymous,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}
