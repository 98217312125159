import { AssetsField } from '@tivio/types'

import Video from '../store/Video'


export const getVideoCoverAsset = (assets: AssetsField) => {
    const asset = (
        assets['cover']?.['@1'] ??
        assets['cover']?.['@2'] ??
        assets['cover']?.['@3']
    )

    return asset?.background
}

export const hasOktagonLiveTag = (video: Video) => {
    // TIV-1430 TODO remove hardcoded ID!
    // This is an id of "Live stream" tag (organization Oktagon, tivio-production)
    return video?.tags.some((tag) => {
        return tag.id === 'WFW0SHuil6BKndZm6uAx'
    })
}
