import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from '../../static/enum'


const SEEKBAR_MIN_HEIGHT = 84

const useSeekbarStyles = makeStyles((theme) => ({
    seekbarContainer: {
        minHeight: SEEKBAR_MIN_HEIGHT,
        marginLeft: theme.spacing(10),
        marginRight: theme.spacing(10),
        marginBottom: theme.spacing(6),
    },
    seekbarTile: {
        position: 'relative',
        minHeight: SEEKBAR_MIN_HEIGHT,
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        padding: 17.5,
    },
}))

export {
    useSeekbarStyles,
    SEEKBAR_MIN_HEIGHT,
}
