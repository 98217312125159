import { MARKER_TYPES } from '../static/enum'

import Marker, { type IMarker } from './Marker'


type MarkerOverride = Partial<{
    type: MARKER_TYPES,
    name: string,
    setFrom: number, // in seconds
    setTo: number, // in seconds
}>

class VirtualMarker implements IMarker {
    private _from: number
    private _to: number
    private readonly _id: string
    private _type: MARKER_TYPES = MARKER_TYPES.AD
    private _name: string

    constructor(marker: Marker, override?: MarkerOverride) {
        this._from = override?.setFrom ?? marker.getFrom
        this._to = override?.setTo ?? marker.getTo
        this._id = marker.getId
        this._type = override?.type ?? marker.getType
        this._name = 'W'
    }

    get getFrom() {
        return this._from
    }

    get getId() {
        return this._id
    }

    get getName() {
        return this._name
    }

    get getTo() {
        return this._to
    }

    get getType() {
        return this._type
    }

    set setType(type: MARKER_TYPES) {
        this._type = type
    }

    get from() {
        return this._from * 1000 // to ms
    }

    get to() {
        return this._to * 1000 // to ms
    }

    set to(miliseconds: number) {
        this._to = miliseconds / 1000 // to s
    }
}

export default VirtualMarker
