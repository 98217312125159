import { Typography } from '@material-ui/core'
import { truncateText } from '@tivio/common'
import { LangCode, Translation } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { getTranslation } from '../../../utils/translate.utils'
import { useOrganization } from '../../hooks'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { VideoCover } from '../../video/VideoCover'

import { MonetizationIncomePPVDetail, PayPerViewIncomeDetail } from './types'


const StyledSubtitleTypography = styled(Typography) <{ $textAlign?: string }>`
    font-size: 0.75em;
    ${props => props.$textAlign && `text-align: ${props.$textAlign};`}
`

interface Props {
    payPerViewSummaryList: PayPerViewIncomeDetail[]
}

export const PayPerViewSummaryList: React.FC<Props> = (props) => {
    const { payPerViewSummaryList } = props

    const { organization } = useOrganization()
    const [t] = useTranslation()

    const getTrimmedStringWithDots = (translation: Translation | string, stringLengthLimit = 110) => {
        const translatedString = getTranslation(translation, organization?.languages)
        return truncateText(translatedString, stringLengthLimit)
    }

    const getPayPerViewMonetizationSummaryKey = (ppvSummary: PayPerViewIncomeDetail, ppvMonetizationSummary: MonetizationIncomePPVDetail) => {
        return `${ppvSummary.videoId}-${ppvMonetizationSummary.monetizationId}-${ppvMonetizationSummary.purchaseCount}`
    }

    return (
        <Column spacing={2}>
            <Typography variant="h6">
                {t('PPV list')}
            </Typography>
            <Row
                spacing={2}
                itemProps={{ xs: 12 }}
            >
                {payPerViewSummaryList.map(payPerViewSummary => (
                    <Row
                        key={payPerViewSummary.videoId}
                        rowProps={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        itemProps={{ xs: 6 }}
                    >
                        <Row
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 4 }}
                        >

                            <VideoCover cover={payPerViewSummary.coverImage} />
                            <Column spacing={1}>
                                <Typography variant="h6">
                                    {getTrimmedStringWithDots(payPerViewSummary.name ?? '')}
                                </Typography>
                            </Column>
                        </Row>
                        <>
                            {
                                payPerViewSummary.monetizationSummaries.map(payPerViewMonetizationSummary => (
                                    <Row
                                        rowProps={{ alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'center' }}
                                        itemProps={{ xs: 4 }}
                                        key={getPayPerViewMonetizationSummaryKey(payPerViewSummary, payPerViewMonetizationSummary)}
                                    >
                                        <StyledSubtitleTypography
                                            color="textSecondary"
                                            $textAlign="end"
                                        >
                                            {`${payPerViewMonetizationSummary.title} (${payPerViewMonetizationSummary.purchasedPrice}${organization?.currencySymbol})`}
                                        </StyledSubtitleTypography>
                                        <StyledSubtitleTypography
                                            color="textSecondary"
                                            $textAlign="end"
                                        >
                                            {`${payPerViewMonetizationSummary.purchaseCount.toLocaleString('cs-CZ')}  ${t('purchases')}`}
                                        </StyledSubtitleTypography>
                                        <StyledSubtitleTypography
                                            color="textSecondary"
                                            $textAlign="end"
                                        >
                                            {`${payPerViewMonetizationSummary.totalIncome.toLocaleString('cs-CZ')} ${organization?.currencySymbol}`}
                                        </StyledSubtitleTypography>
                                    </Row>
                                ))
                            }
                        </>
                    </Row>
                ))}
            </Row>
        </Column>
    )
}
