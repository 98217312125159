import { Container, Divider, Grid, makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import { getStreamUri } from '../../firebase/firestore/tvChannels'
import Logger from '../../logger'
import { UNIX_HOUR } from '../../static/constants'
import EmptyProgram from '../../store/EmptyProgram'
import Marker from '../../store/Marker'
import Program from '../../store/Program'
import TvChannel from '../../store/TvChannel'
import { createInterval, getDuration, getInitTimeline, Interval } from '../../utils/epg.utils'
import { fillEmptyHoles, filterEpgIntervalElements, validators } from '../../utils/seekbar.utils'
import { getLatestStreamAvailableTime, isStreamAvailable, StreamStatus } from '../../utils/stream.utils'
import { secondsToMilliseconds } from '../../utils/time.utils'
import AppLoading from '../AppLoading'
import { PlayerController } from '../player/Player'
import PlayerContainer from '../player/PlayerContainer'
import PlayerControls from '../player/PlayerControls'
import { PlayerMarkerForm } from '../player/PlayerMarkerForm'
import { SeekbarController } from '../player/PlayerSeekbar'
import PlayerZoom, { DEFAULT_ZOOM_VALUE } from '../player/PlayerZoom'
import { useMarkerEditorStyles } from '../player/styles.markerEditor'

import EpgSeekbarWrapper from './EpgSeekbarWrapper'
import EpgTimepicker from './EpgTimepicker'


interface Props {
    tvChannel: TvChannel
}

export type SeekbarProgram = Program | EmptyProgram

const useStyles = makeStyles((theme) => ({
    divider: {
        marginBottom: theme.spacing(3),
        height: 2,
    },
    controlsContainer: {
        paddingBottom: theme.spacing(4),
    },
}))

const log = new Logger('EpgMarkerEditor')

const useLoadUri = (currentEpg: Interval | undefined, tvChannel: TvChannel) => {
    const [streamStatus, setStreamStatus] = useState<StreamStatus>(StreamStatus.LOADING)
    const [fetchedEpg, setFetchedEpg] = useState<Interval | null>()
    const [uri, setUri] = useState('')

    useEffect(() => {
        if (currentEpg) {
            (async () => {
                try {
                    const latestAvailableTime = getLatestStreamAvailableTime()

                    const isStreamAvailable = currentEpg.from < latestAvailableTime
                    const isStreamFullyAvailable = isStreamAvailable && currentEpg.to < latestAvailableTime

                    if (isStreamAvailable) {
                        const to = isStreamFullyAvailable ? currentEpg.to : latestAvailableTime

                        setStreamStatus(StreamStatus.LOADING)

                        const uri = await getStreamUri(
                            tvChannel.getChannelKey,
                            secondsToMilliseconds(currentEpg.from),
                            secondsToMilliseconds(to),
                        )

                        setFetchedEpg(createInterval(currentEpg.from, currentEpg.to, to))
                        setUri(uri)

                        setStreamStatus(isStreamFullyAvailable
                            ? StreamStatus.FULLY_AVAILABLE
                            : StreamStatus.PARTLY_AVAILABLE)
                    } else {
                        setStreamStatus(StreamStatus.NOT_YET_AVAILABLE)
                        setFetchedEpg(null)
                    }
                }
                catch (e) {
                    setStreamStatus(StreamStatus.FETCH_FAILED)
                    log.error(e)
                }
            })()
        }
    }, [currentEpg, tvChannel])

    return { uri, streamStatus, fetchedEpg }
}

const EpgMarkerEditor = observer((props: Props) => {
    const classes = useStyles()
    const classesMarkerEditor = useMarkerEditorStyles()

    const playerRef = useRef<PlayerController | null>(null)
    const seekbarRef = useRef<SeekbarController | null>(null)
    const [currentEpg, setCurrentEpg] = useState<Interval>()
    const [currentPrograms, setCurrentPrograms] = useState<SeekbarProgram[]>([])
    const [epg, setEpg] = useState<Interval>(getInitTimeline)
    const [isPlaying, setIsPlaying] = useState(false)
    const [marker, setMarker] = useState<MarkerData>()
    const [playingPosition, setPlayingPosition] = useState(0) // in seconds
    const [playingPositionAvailable, setPlayingPositionAvailable] = useState(true)
    const [t] = useTranslation()
    const [zoomValue, setZoomValue] = useState(DEFAULT_ZOOM_VALUE)

    const { uri, streamStatus, fetchedEpg } = useLoadUri(currentEpg, props.tvChannel)

    const playerError = useMemo(
        () => {
            const error = !isStreamAvailable(streamStatus) || !playingPositionAvailable

            let errorText = null
            if (streamStatus === StreamStatus.NOT_YET_AVAILABLE || !playingPositionAvailable) {
                errorText = t('Stream is not available yet')
            } else if (streamStatus === StreamStatus.FETCH_FAILED) {
                errorText = t('Failed to fetch TV stream')
            }

            return { error, errorText }

        }, [streamStatus, playingPositionAvailable],
    )

    useEffect(() => {
        if (fetchedEpg?.latestFetchedTime) {
            const { from, latestFetchedTime } = fetchedEpg

            if (from + playingPosition > latestFetchedTime) {
                setPlayingPositionAvailable(false)
                playerRef.current?.hide()
            } else {
                setPlayingPositionAvailable(true)
                playerRef.current?.show()
            }
        }

    }, [fetchedEpg, playingPosition])

    useEffect(
        () => {
            (async () => {
                await props.tvChannel.listPrograms()
                await props.tvChannel.loadMarkers()
            })()
        },
        [props.tvChannel],
    )

    useEffect(
        () => {
            playerRef.current?.load({
                documentId: props.tvChannel.id,
                documentType: 'tvChannel',
                positionSec: playingPosition,
                source: uri,
            })
        },
        [props.tvChannel.id, uri],
    )

    useEffect(() => {
        !isStreamAvailable(streamStatus) && playerRef.current?.unload()
    }, [streamStatus])

    useEffect(
        () => {
            const startTimestamp = epg.from
            const endTimestamp = epg.from + (UNIX_HOUR * zoomValue)

            setCurrentEpg(createInterval(startTimestamp, endTimestamp))
        },
        [epg],
    )


    useEffect(
        () => {
            if (currentEpg) {
                setEpg({ from: currentEpg.from, to: currentEpg.to })
            }
        },
        [zoomValue],
    )

    useEffect(
        () => {
            if (!props.tvChannel.loading && currentEpg) {
                updateCurrentPrograms(currentEpg)
            }
        },
        [props.tvChannel, props.tvChannel.loading],
    )

    const updateCurrentPrograms = (interval: Interval) => {
        const programsFiltered = filterEpgIntervalElements(
            props.tvChannel.getPrograms,
            interval,
        )

        const createEmptyProgram = (index: number, from: number, to: number) => {
            return new EmptyProgram({
                channelKey: props.tvChannel.getChannelKey,
                id: `empty-program-${index}`,
                name: t('Empty program'),
                timeline: {
                    from,
                    to,
                },
                playTime: to - from,
            })
        }

        setCurrentPrograms(fillEmptyHoles(programsFiltered, interval, createEmptyProgram))
    }

    const handleGoNext = useCallback(
        () => {
            if (currentEpg) {
                const newEndTimestamp = currentEpg.to + UNIX_HOUR * zoomValue

                const newInterval = {
                    from: currentEpg.to,
                    to: newEndTimestamp,
                }

                setCurrentEpg(newInterval)
                updateCurrentPrograms(newInterval)
            }
        },
        [zoomValue, currentEpg],
    )

    const handleGoBefore = useCallback(
        () => {
            if (currentEpg) {
                const newStartTimestamp = currentEpg.from - UNIX_HOUR * zoomValue

                const newInterval = {
                    from: newStartTimestamp,
                    to: currentEpg.from,
                }

                setCurrentEpg(newInterval)
                updateCurrentPrograms(newInterval)
            }
        },
        [zoomValue, currentEpg],
    )

    // TODO not nice ... cannot use hooks below this line
    if (props.tvChannel.loading || !currentEpg) {
        return <AppLoading />
    }

    const handleCreateMarker = async (markerData: MarkerData) => {
        const markerModel: MarkerData = {
            ...markerData,
        }

        markerModel.from = markerData.from + currentEpg.from

        if (markerData.to) {
            markerModel.to = markerData.to + currentEpg.from
        }

        await props.tvChannel.addMarker(markerModel)

        handleCloseMarkerForm()
    }

    const handleIntervalUpdate = (interval: Interval) => {
        if (interval.from && interval.to) {
            seekbarRef.current?.setInterval(interval)
        }
        else {
            seekbarRef.current?.resetInterval()
        }
    }

    const handleCloseMarkerForm = () => {
        setMarker(undefined)
        seekbarRef.current?.resetInterval()
    }

    const handleSeekbarMove = (sec: number) => {
        setPlayingPosition(sec)
        playerRef.current?.seek(sec)
    }

    const handleDeleteMarker = async (marker: Marker) => {
        await props.tvChannel.deleteMarker(marker)
        handleCloseMarkerForm()
    }

    const handleMarkerUpdate = async (marker: Marker, markerData: MarkerData) => {
        const markerModel: MarkerData = {
            ...markerData,
        }

        markerModel.from = markerData.from + currentEpg.from

        if (markerData.to) {
            markerModel.to = markerData.to + currentEpg.from
        }

        await marker.updateMarker(markerModel)

        handleCloseMarkerForm()
    }

    const handleSeekWithControls = (step: number) => {
        const newPlayingPosition = playingPosition + step

        if (
            validators.isEqualToZero(playingPosition) ||
            !validators.isGreaterThanZero(newPlayingPosition) ||
            !validators.isLesserThanDuration(newPlayingPosition, currentEpg.to - currentEpg.from)
        ) {
            return
        }

        handlePlayingPositionChange(newPlayingPosition)
    }

    const handlePlayingPositionChange = (sec: number) => {
        playerRef.current?.seek(sec)
        setPlayingPosition(sec)
    }

    const handleSeekbarClick = (markerData: MarkerData) => {
        setMarker({
            ...markerData,
            from: (markerData.from + currentEpg.from),
            to: (markerData.to + currentEpg.from),
        })
    }

    return (
        <>
            <Container maxWidth="lg">
                <PlayerContainer
                    error={playerError}
                    onPause={() => setIsPlaying(false)}
                    onPlay={() => setIsPlaying(true)}
                    playerRef={playerRef}
                    onTimeUpdate={setPlayingPosition}
                />
            </Container>
            <div className={classesMarkerEditor.container}>
                {marker && (
                    <div className={classesMarkerEditor.addMarkerContainer}>
                        <PlayerMarkerForm
                            contentDuration={getDuration(currentEpg.from, currentEpg.to)}
                            currentEpg={currentEpg}
                            isPlaying={isPlaying}
                            marker={marker}
                            markers={props.tvChannel.getMarkers}
                            onClose={handleCloseMarkerForm}
                            onDelete={handleDeleteMarker}
                            onIntervalChange={handleIntervalUpdate}
                            onMarkerFormSubmit={handleCreateMarker}
                            onMarkerUpdate={handleMarkerUpdate}
                            onPause={() => playerRef.current?.pause()}
                            onPlay={() => playerRef.current?.play()}
                            onPlayingPositionChange={handlePlayingPositionChange}
                            onSeekWithControls={handleSeekWithControls}
                        />
                    </div>
                )}
                <Divider className={classes.divider} />
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.controlsContainer}
                    >
                        <Grid item>
                            <EpgTimepicker
                                currentEpg={currentEpg}
                                onTimeChange={setEpg}
                            />
                        </Grid>
                        <Grid item>
                            <PlayerControls
                                onPause={() => playerRef.current?.pause()}
                                onPlay={() => playerRef.current?.play()}
                                onSeekWithControls={handleSeekWithControls}
                                isPlaying={isPlaying}
                            />
                        </Grid>
                        <Grid item>
                            <PlayerZoom
                                value={zoomValue}
                                onZoomValueChange={setZoomValue}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <EpgSeekbarWrapper
                    contentDuration={getDuration(currentEpg.from, currentEpg.to)}
                    stream={{ epgInterval: fetchedEpg || currentEpg, streamStatus }}
                    isMarkerFormOpen={!!marker}
                    mouseContainerRef={seekbarRef}
                    onGoBefore={() => handleGoBefore()}
                    onGoNext={() => handleGoNext()}
                    onSeekbarClick={handleSeekbarClick}
                    onSeek={handleSeekbarMove}
                    playingPosition={playingPosition}
                    programs={currentPrograms}
                    tvChannel={props.tvChannel}
                />
            </div>
        </>
    )
})

export default EpgMarkerEditor
