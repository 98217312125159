import { Logger } from '@tivio/common'
import { PurchasableMonetization } from '@tivio/core-js'
import { ItemInRow } from '@tivio/types'
import { useCallback } from 'react'

import { SubscriptionPriceBadgeDisplayType, itemIsTvChannel, itemIsVideo, useConfig } from '../..'


const logger = new Logger('useItemPriceLabel')

export const useItemPriceLabel = () => {
    const { tivioConfig, config } = useConfig()

    /**
     * If the most relevant monetization is subscription, then returns subscription name or undefined
     * based on configured {@link SubscriptionPriceBadgeDisplayType}.
     * If it's non-purchased transaction, returns price (e.g. '100 CZK').
     * Returns undefined otherwise.
     */
    const getItemPriceLabel = useCallback((item?: ItemInRow | undefined | null) => {
        if (!item || !(itemIsVideo(item) || itemIsTvChannel(item)) || !item?.purchasableMonetization) {
            return undefined
        }

        const monetization = item.purchasableMonetization

        if (monetization.type === 'subscription') {
            return subscriptionPriceShouldBeShown(monetization, config.subscriptionPriceBadgeDisplayType) ? monetization.name : undefined
        } else if (monetization.type === 'transaction') {
            if (config.subscriptionPriceBadgeDisplayType === SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN) {
                return undefined
            }
            return item.price ? `${item.price} ${tivioConfig.currency}` : undefined
        } else {
            logger.info(`Got unexpected monetization type ${monetization.type}, price label cannot be determined`)
            return undefined
        }
    }, [ tivioConfig.currency, config.subscriptionPriceBadgeDisplayType ])

    return {
        getItemPriceLabel,
    }
}


/**
 * Returns TRUE if subscription price badge should be shown on row item (tvChannel or video), FALSE otherwise.
 * It's computed based on subscription purchases and configured displayType.
 *
 * @param subscription subscription which price will be shown/hidden
 * @param displayType configured subscription price badge display type
 */
const subscriptionPriceShouldBeShown = (subscription: PurchasableMonetization, displayType: SubscriptionPriceBadgeDisplayType) => {
    return displayType !== SubscriptionPriceBadgeDisplayType.ALWAYS_HIDDEN
    && !(displayType === SubscriptionPriceBadgeDisplayType.HIDDEN_IF_PURCHASED && subscription.isPurchased())
}
