import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { TivioNotification } from '../../store/Notification'
import { DefaultPageContainer } from '../DefaultPageContainer'
import { Repeater } from '../Repeater'
import { BigButton } from '../styled'

import { NotificationList } from './NotificationList'


const NOTIFICATION_ZERO_STATES_COUNT = 3
const NotificationZeroState = styled(Skeleton)`
    height: 150px;
`

interface Props {
    handleCreateNotification: () => void
    loading: boolean
    notifications: TivioNotification[]
    removeNotification: (notification: TivioNotification) => Promise<void>
    disabledAddNotification?: boolean
}
export const NotificationListWrapper = ({ handleCreateNotification, loading, notifications, removeNotification, disabledAddNotification }: Props) => {

    const [t] = useTranslation()

    return (
        <DefaultPageContainer>
            {loading ? (
                <Repeater count={NOTIFICATION_ZERO_STATES_COUNT}>
                    <NotificationZeroState animation="wave"/>
                </Repeater>
            ) : (
                <>
                    <NotificationList
                        notifications={notifications}
                        removeNotification={removeNotification}
                    />
                    <BigButton
                        fullWidth
                        variant="outlined"
                        disabled={disabledAddNotification}
                        onClick={handleCreateNotification}
                    >
                        {t('Add new notification')}
                    </BigButton>
                </>

            )}
        </DefaultPageContainer>
    )
}
