import { Chip } from '@material-ui/core'
import { MonetizationPlacementConditionWhereFilterField, RowWhereFilterFieldPath } from '@tivio/firebase'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Tag } from '../../../store/Tag'
import { DialogTitle } from '../../screen/RowDialog'
import { TagsAutocomplete } from '../../screen/TagsAutocomplete'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'

import type { RowFilterWhereField, TagDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const StyledDiv = styled.div`
    margin-top: 14px;
`

interface Props {
    filter: MonetizationPlacementConditionWhereFilterField
    onChange: (condition: MonetizationPlacementConditionWhereFilterField) => void
}

/**
 * Used for filtering videos for monetization placement
 */
export const PlacementFilter = ({ filter, onChange }: Props) => {
    const [ t ] = useTranslation()

    const handleChange = (value: firebase.firestore.DocumentReference<TagDocument>[]) => {
        onChange({ ...filter, value })
    }

    const renderField = () => {
        switch (filter.fieldPath) {
            case 'tags':
                return <TagsAutocomplete selectedTags={filter.value} onTagChange={(tags: Tag[]) => handleChange(tags.map(t => t.getRef))} />
            default:
                throw new Error(`Unknown condition fieldPath ${filter.fieldPath}`)
        }
    }

    // organizationRef is used for allVideos condition, TODO display it as checkbox allVideos: true/false
    return filter.fieldPath === 'organizationRef' ? (
        <StyledDiv>
            <DialogTitle>{t('Monetization is placed to all videos')}</DialogTitle>
        </StyledDiv>
    ) : (
        <StyledDiv>
            <Column spacing={1}>
                <Row spacing={1}>
                    <DialogTitle>{filter.fieldPath}:</DialogTitle>
                    <Chip
                        color="primary"
                        size="small"
                        label={filter.opStr}
                    />
                </Row>
                {renderField()}
            </Column>
        </StyledDiv>
    )
}
