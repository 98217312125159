import Channel from '../../../store/Channel'
import { storeNotReady } from '../index'

import { useOrganization } from './useOrganization'


const useVodChannelsPagination = () => {
    const { organization } = useOrganization()

    if (!organization) {
        return {
            channelExists: storeNotReady,
            fetchMore: storeNotReady,
            getChannel: storeNotReady,
            channels: [] as Channel[],
            currentChannels: [] as Channel[],
            channelsRefs: [],
        }
    }

    const channelExists = (channelId: string) => {
        return organization.allChannelsPagination.getRefs.some(channelRef => channelRef.id === channelId)
    }

    const getChannel = async (itemId: string) => {
        return organization.allChannelsPagination.getItem(itemId)
    }

    return {
        channelExists,
        getChannel,
        fetchMore: organization.allChannelsPagination.fetchMore,
        channels: organization.allChannelsPagination.getItems,
        currentChannels: organization.allChannelsPagination.getCurrentItems,
        channelsRefs: organization.allChannelsPagination.getRefs,
    }
}

export {
    useVodChannelsPagination,
}
