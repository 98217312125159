import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@material-ui/core'
import { GetVideoAnalyticsErrorItem } from '@tivio/types'
import React, { memo } from 'react'
import { useTranslation } from 'react-i18next/hooks'


const useStyles = makeStyles({
    cell: {
        borderBottom: 'none',
    },
    innerTable: {
        marginBottom: '1rem',
    },
    innerTitle: {
        fontWeight: 'bold',
    },
})

interface Props {
    errors?: GetVideoAnalyticsErrorItem[]
}

export const VideoAnalyticsErrors = memo(({ errors }: Props) => {
    const [t] = useTranslation()
    const classes = useStyles()

    if (!errors?.length) {
        return (
            <Typography
                variant="subtitle2"
                color="textSecondary"
            >
                {t('No errors were reported in the last {{minutes}} minutes', { minutes: 10 })}
            </Typography>
        )
    }

    return (
        <TableContainer>
            <Table size="small">
                <TableBody>
                    {
                        errors?.map(error => {
                            const datetime = error?.timestamp && new Date(Number(error?.timestamp)).toLocaleTimeString()

                            return (
                                <TableRow key={error.timestamp}>
                                    <TableCell
                                        className={classes.cell}
                                        component="th"
                                        scope="row"
                                    >
                                        {datetime}
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        {error?.errorMessage}
                                    </TableCell>
                                    <TableCell
                                        className={classes.cell}
                                    >
                                        {error?.count}
                                    </TableCell>
                                    <TableCell className={classes.cell}>{error?.count}x</TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
})

