import { RowFilterWhereField, RowWhereFilterFieldPath } from '@tivio/firebase'
import {
    AssetType,
    BannerItemComponent,
    PublishedStatus,
    RowFilterCollection,
    RowItemComponent,
    VideoTranscodingStatus,
} from '@tivio/types'

import type { OrganizationDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const defaultVideosCollectionWhereFilter = (organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>): RowFilterWhereField[] => [
    {
        fieldPath: 'organizationRef' as RowWhereFilterFieldPath,
        opStr: '==',
        value: organizationRef,
    },
    {
        fieldPath: 'transcodingStatus' as RowWhereFilterFieldPath,
        opStr: '==',
        value: VideoTranscodingStatus.DONE,
    },
    {
        fieldPath: 'publishedStatus' as RowWhereFilterFieldPath,
        opStr: '==',
        value: PublishedStatus.PUBLISHED,
    },
]

const defaultTvChannelCollectionWhereFilter = (organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>): RowFilterWhereField[] => [
    {
        fieldPath: 'organizationRef' as RowWhereFilterFieldPath,
        opStr: '==',
        value: organizationRef,
    },
    {
        fieldPath: 'publishedStatus' as RowWhereFilterFieldPath,
        opStr: '==',
        value: PublishedStatus.PUBLISHED,
    },
]

/**
 * Returns row type for given collection path.
 * E.g. `/videos` -> `RowFilterCollection.VIDEOS`
 *
 * @param collection Collection path in Firestore.
 * @returns Row type ("videos", "tvChannels", "tags") or undefined when collection is not recognized.
 */
const getRowItemType = (collectionPath: string) => {
    const collection = collectionPath.replace(/^\/+|\/+$/g, '').split('/') // regex trims slashes
    if (collection.length <= 2) {
        if (collection[0] === RowFilterCollection.VIDEOS) {
            return RowFilterCollection.VIDEOS
        } else if (collection[0] === RowFilterCollection.TV_CHANNELS) {
            return RowFilterCollection.TV_CHANNELS
        } else if (collection[0] === RowFilterCollection.CONTENTS) {
            return RowFilterCollection.CONTENTS
        }
    } else if (collection.length >= 3 && collection[2] === RowFilterCollection.TAGS) {
        return RowFilterCollection.TAGS
    }
}

const ROW_ITEM_TYPE_TO_ASSET_ID: {
    [key in RowItemComponent | BannerItemComponent] : AssetType
} = {
    [RowItemComponent.ROW_ITEM_PORTRAIT]: 'portrait',
    [RowItemComponent.ROW_ITEM_LANDSCAPE]: 'landscape',
    [RowItemComponent.ROW_ITEM_SQUARE]: 'square',
    [RowItemComponent.ROW_ITEM_CREATORS_SQUARE]: 'square',
    [RowItemComponent.ROW_ITEM_CREATORS_LANDSCAPE]: 'landscape',
    [RowItemComponent.ROW_ITEM_CIRCLED]: 'circled',
    [RowItemComponent.ROW_ITEM_HIGHLIGHTED]: 'landscape',
    [RowItemComponent.ROW_ITEM_PLAIN]: 'landscape',

    // Maybe in future we may want a dedicated 'banner' AssetType value for these.
    [BannerItemComponent.CLASSIC]: 'landscape',
    [BannerItemComponent.SPLIT]: 'landscape',
    [BannerItemComponent.BACKGROUND]: 'landscape',
    [BannerItemComponent.BACKGROUND_BLUR]: 'landscape',
    [BannerItemComponent.ORGANIZATION_BANNER]: 'landscape',
    [BannerItemComponent.CAROUSEL]: 'landscape',
}

function rowItemTypeToAssetType(rowItem: RowItemComponent | BannerItemComponent) {
    return ROW_ITEM_TYPE_TO_ASSET_ID[rowItem]
}

export {
    defaultVideosCollectionWhereFilter,
    defaultTvChannelCollectionWhereFilter,
    getRowItemType,
    rowItemTypeToAssetType,
}
