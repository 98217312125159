import { MenuItem, OutlinedInput, Select } from '@material-ui/core'
import { NotificationLastActivity, NotificationLastActivityFilterType } from '@tivio/types'
import React, { ChangeEvent } from 'react'

import { Row } from '../../../uiLayout/Row'

import { SurveyFilterProps } from './types'



const getMaxDuration = (unit?: NotificationLastActivity['unit']) => {
    switch (unit) {
        case 'WEEK':
            return 52 * 2
        case 'MONTH':
            return 24
        case 'DAY':
        default:
            return 365 * 2
    }
}

const durationUnitOptions = {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
}

export const ActivityFilter = ({ onChange, value }: SurveyFilterProps<NotificationLastActivityFilterType>) => {
    return (
        <Row
            spacing={1}
            rowProps={{
                alignItems: 'center',
            }}
        >
            <OutlinedInput
                margin="dense"
                value={value.value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const valueAsNumber = parseInt(e.target.value)
                    onChange({
                        ...value,
                        value: valueAsNumber,
                    })
                }}
                type="number"
                inputProps={{
                    min: 1,
                    max: getMaxDuration(value.unit),
                }}
            />
            <Select
                value={value.unit}
                onChange={(e) => {
                    onChange({
                        value: Math.min(value.value, getMaxDuration(e.target.value as NotificationLastActivity['unit'])),
                        unit: e.target.value as NotificationLastActivity['unit'],
                    })
                }}
                displayEmpty
                renderValue={(value) => (value as string) ?? 'Select period'}
            >
                {Object.entries(durationUnitOptions).map(([unit, label]) => (
                    <MenuItem
                        key={unit}
                        value={unit}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </Row>
    )
}
