import { IconButton, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { observer } from 'mobx-react'
import React, { FC } from 'react'

import { TivioNotification } from '../../../../store/Notification'
import { useOrganization } from '../../../hooks'
import { useMemberships } from '../../../hooks/dataHooks/useMemberships'
import { useSubscriptions } from '../../../hooks/dataHooks/useSubscriptions'

import { ActivityFilter } from './ActivityFilter'
import { FilterWrapper } from './FilterWrapper'
import { MembershipFilter } from './MembershipFilter'
import { SubscriptionFilter } from './SubscriptionFilter'
import { SurveyFilter } from './SurveyFilter'

import type { SurveyFilterProps } from './types'
import type { NotificationFilterType } from '@tivio/types'


interface Props {
    notification: TivioNotification
}

interface FiltersValue {
    type: string
    value: any
}

export const TargetingFilters = observer(({ notification }: Props) => {
    const { memberships } = useMemberships()
    const { subscriptions } = useSubscriptions()
    const { organization } = useOrganization()

    const availableFilters = [
        {
            type: 'lastActivity',
            label: 'Last active',
            component: ActivityFilter,
            defaultValue: {
                value: 30,
                unit: 'DAY',
            },
        },
        ...(organization?.userProfileConfiguration?.survey ? [{
            type: 'survey',
            label: 'Survey',
            component: SurveyFilter,
            defaultValue: {
                gender: 'all',
                ageFrom: 25,
                ageTo: 34,
            },
        }] : []),
        ...(memberships.length > 0 ? [{
            type: 'membership',
            label: 'Membership',
            component: MembershipFilter,
            defaultValue: {
                membershipId: memberships[0]?.getId ?? '',
                isActive: true,
            },
        }] : []),
        ...(subscriptions.length > 0 ? [{
            type: 'subscription',
            label: 'Subscription',
            component: SubscriptionFilter,
            defaultValue: {
                subscriptionId: subscriptions[0]?.getId ?? '',
                isActive: true,
            },
        }] : []),
    ] as {
        type: NotificationFilterType['type']
        label: string
        component: FC<SurveyFilterProps<any>>
        defaultValue: any
    }[]
    
    const filters = notification.filters
    const setFilters = (filters: FiltersValue[]) => {
        // @ts-expect-error
        notification.filters = filters
    }

    return (
        <div>
            <Typography variant="h6">Targeting filters</Typography>
            {filters.map((filter, index) => (
                <FilterWrapper
                    key={index}
                    availableFilters={availableFilters}
                    type={filter.type}
                    filterProps={{
                        value: filter.value,
                        onChange: (value) => {
                            const newFilters = [...filters]
                            newFilters[index].value = value
                            setFilters(newFilters)
                        },
                    }}
                    onChangeType={(type) => {
                        const newFilters = [...filters]
                        newFilters[index].type = type as NotificationFilterType['type']
                        newFilters[index].value = availableFilters.find((filter) => filter.type === type)?.defaultValue
                        setFilters(newFilters)
                    }}
                    onRemove={() => {
                        const newFilters = [...filters]
                        newFilters.splice(index, 1)
                        setFilters(newFilters)
                    }}
                />
            ))}
            <IconButton
                onClick={() => {
                    const firstFilter = availableFilters[0]
                    setFilters([
                        ...filters,
                        {
                            type: firstFilter.type,
                            value: firstFilter.defaultValue,
                        },
                    ])
                }}
            >
                <Add />
            </IconButton>
        </div>
    )
})
