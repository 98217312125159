import {
    ArticleDocument,
    ChannelDocument,
    ContentDocument,
    TagDocument,
    VideoDocument,
} from '@tivio/firebase'

import { createGlobalVideo } from '../creator/video.creator'
import { RefsPagination } from '../firebase/pagination'
import { Article } from '../store/Article'
import Channel from '../store/Channel'
import Organization from '../store/Organization'
import { Post } from '../store/Post'
import { Tag } from '../store/Tag'
import Video from '../store/Video'

import type firebase from 'firebase/app'


const createEmptyChannelPagination = (organization: Organization) => {
    return new RefsPagination<ChannelDocument, Channel>(
        [],
        (ref, data) => {
            return new Channel(
                ref,
                data,
                organization,
            )
        },
        false,
    )
}

const createEmptyVideoPagination = (organization: Organization) => {
    return new RefsPagination<VideoDocument, Video>(
        [],
        (ref, data) => {
            return createGlobalVideo(
                ref,
                data,
                organization,
            )
        },
        false,
    )
}

const createEmptyRowPagination = (organization: Organization) => {
    return new RefsPagination<VideoDocument | TagDocument, Video | Tag>(
        [],
        (ref, data) => {
            return createGlobalVideo(
                ref as firebase.firestore.DocumentReference<VideoDocument>,
                data as VideoDocument,
                organization,
            )
        },
        false,
    )
}

const createEmptyArticlePagination = (organization: Organization) => {
    return new RefsPagination<ArticleDocument, Article>(
        [],
        (ref, data) => {
            return new Article(
                ref,
                data,
                organization,
                false,
            )
        },
        false,
    )
}

const createEmptyPostsPagination = (organization: Organization) => {
    return new RefsPagination<ContentDocument, Post>(
        [],
        (ref, data) => {
            return new Post(
                ref,
                organization,
                data,
            )
        },
        false,
    )
}

export {
    createEmptyChannelPagination,
    createEmptyVideoPagination,
    createEmptyRowPagination,
    createEmptyArticlePagination,
    createEmptyPostsPagination,
}
