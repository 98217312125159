import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useState } from 'react'

import { DateRange, Period } from '../../../types/date'
import { PeriodPicker } from '../../periodPickers/PeriodPicker'
import { Row } from '../../uiLayout/Row'
import { CenteredLoader } from '../CenteredLoader'

import { DeviceDetailCard } from './DeviceDetailCard'
import { MostViewedVideosList } from './MostViewedVideosList'
import { RealTimeViewsCard } from './RealTimeViewsCard'
import { previousPeriodStringMap } from './types'
import { useViewsDashboard } from './useViewsDashboard'
import { ViewsChartCard } from './ViewsChartCard'
import { ViewsDetailCard } from './ViewsDetailCard'
import { ViewsPeakCard } from './ViewsPeakCard'


dayjs.extend(utc)

const DEFAULT_DATE_RANGE: DateRange = {
    startDate: dayjs().utc().startOf('day').subtract(7, 'day').toISOString(),
    endDate: dayjs().utc().endOf('day').toISOString(),
}

export const ViewsDashboard: React.FC = () => {
    const [startDate, setStartDate] = useState<string>(DEFAULT_DATE_RANGE.startDate)
    const [endDate, setEndDate] = useState<string>(DEFAULT_DATE_RANGE.endDate)
    const [period, setPeriod] = useState<Period>(Period.LAST_SEVEN_DAYS)

    const handlePeriodChange = (startDate: Date, endDate: Date, period: Period) => {
        setStartDate(dayjs(startDate).utc(true).toISOString())
        setEndDate(dayjs(endDate).utc(true).toISOString())
        setPeriod(period)
    }

    const { isLoading, dashboardData, periodInDays } = useViewsDashboard({ startDate, endDate, period })

    const gainInterval = previousPeriodStringMap[period]

    return (
        <>
            <Row
                spacing={2}
                rowProps={{ justifyContent: 'flex-end' }}
                itemProps={{ xs: 12 }}
            >
                <PeriodPicker onChange={handlePeriodChange} />
            </Row>
            {isLoading ?
                (
                    <CenteredLoader />
                ) :
                (
                    <>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 3 }}
                        >
                            <ViewsDetailCard
                                viewsCountDetail={dashboardData.viewsCountDetail}
                                gainInterval={gainInterval}
                            />
                            <ViewsPeakCard
                                viewsPeakDetail={dashboardData.viewsPeakDetail}
                                gainInterval={gainInterval}
                                periodInDays={periodInDays}
                            />
                        </Row>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 12 }}
                        >
                            <ViewsChartCard
                                series={dashboardData.chartSeries}
                                periodInDays={periodInDays}
                            />
                        </Row>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'space-between' }}
                            itemProps={{ xs: 6 }}
                        >
                            <DeviceDetailCard
                                deviceViewCountDetails={dashboardData.devicesViewCountDetails}
                            />
                            <RealTimeViewsCard
                                value={dashboardData.viewsCountDetail.currentPeriodViewCount.toString()}
                            />
                        </Row>
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'stretch', justifyContent: 'space-between' }}
                            itemProps={{ xs: 12 }}
                        >
                            <MostViewedVideosList videos={dashboardData.topWatchedVideoDetails} />
                        </Row>
                    </>
                )
            }
        </>
    )
}
