import { Tooltip } from '@material-ui/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { alertSuccess } from '../../utils/alert.utils'


interface CopiableContentProps {
    /**
     * Render component as this defined component with "all other props" passed to it.
     * (Component Injection pattern, similar to https://formik.org/docs/api/field#as)
     */
    as: React.ComponentType<any>
    /**
     * Text that will be put to clipboard. Used as children if no children provided (or they empty)
     */
    text: string
    /**
     * Text on tooltip
     */
    tooltipText?: string
    /**
     * All other props (...restProps)
     */
    [x: string]: any
}

/**
 * Wrapper for any content, that can be clicked to copy text to clipboard.
 */
export const CopiableContent: React.FC<CopiableContentProps> = (props) => {
    const {
        as: Content,
        children,
        text,
        tooltipText,
        successText,
        ...restProps
    } = props
    const [ t ] = useTranslation()
    const putTextToClipboard = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()

        navigator.clipboard.writeText(text)
        alertSuccess(successText || t('Text copied to clipboard.'))
    }, [ text, successText, t ])

    const childrenIsEmpty = !children || React.Children.count(children) === 0

    return (
        <Tooltip
            title={tooltipText || t('Click to copy text to clipboard')}
            placement="top"
        >
            <Content
                onClick={putTextToClipboard}
                {...restProps}
            >
                {childrenIsEmpty ? text : children}
            </Content>
        </Tooltip>
    )
}
