import {
    FirestoreDocumentReference,
    FirestoreDocumentSnapshot,
    FirestoreQuery,
    FirestoreQueryDocumentSnapshot,
    getFirestore,
    loggerFirestore,
} from '../app'

import { devicesConverter } from './converters'

import type { OrganizationDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export type DeviceFirestore = {
    name: string
    description: string
}

export const addDevice = async (
    organizationId: string,
    data: DeviceFirestore,
): Promise<FirestoreDocumentReference<DeviceFirestore>> => {
    try {
        const organizationRef = getFirestore()
            .doc(`organizations/${organizationId}`) as FirestoreDocumentReference<OrganizationDocument>

        const collection = organizationRef
            .collection('devices')
            .withConverter(devicesConverter)
        const result = await collection.add(data)

        loggerFirestore.info('Device has been created')
        return result
    }
    catch (e) {
        loggerFirestore.error('Failed to create Device', e)
        throw new Error(e)
    }
}

export const removeDevice = async (organizationId: string, deviceId: string)
: Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/devices`)
        await collection.doc(deviceId).delete()
        loggerFirestore.info('Device has been removed')
    }
    catch (e) {
        loggerFirestore.error('Failed to remove Device', e)
        throw new Error(e)
    }
}

export const getDevices = (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
: FirestoreQuery<DeviceFirestore> => {
    return parent.collection('devices')
        .orderBy('name')
        .withConverter(devicesConverter)
}

export const getDeviceDoc = async (organizationId: string, deviceId: string)
: Promise<FirestoreDocumentSnapshot<DeviceFirestore>> => {
    const collection = getFirestore().collection(`organizations/${organizationId}/devices`)
    return collection.doc(deviceId)
        .withConverter(devicesConverter)
        .get()
}

export const getDevicesQuery = async (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
: Promise<FirestoreQueryDocumentSnapshot<DeviceFirestore>[]> => {
    const devicesRefs = await getDevices(parent).get()
    return devicesRefs.docs
}
