import { Theme, useTheme } from '@material-ui/core'
import { ApexOptions } from 'apexcharts'
import React from 'react'

import { ChartSeries } from '../../../types/chart'
import { Chart } from '../../externalComponents/Chart'
import { AnalyticCard } from '../AnalyticCard'


const getChartOptions = (theme: Theme, periodInDays?: number): ApexOptions => {
    return {
        chart: {
            background: 'transparent',
            stacked: true,
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 150,
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 350,
                },
            },
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'solid',
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
        },
        stroke: {
            width: 2,
        },
        theme: {
            mode: 'dark',
        },
        xaxis: {
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            type: 'datetime',
            tooltip: {
                enabled: false,
            },
        },
        tooltip: {
            x: {
                format: periodInDays === 1 ? 'dd.MM. HH:mm' : 'dd.MM. (ddd)',
            },
        },
    }
}

interface Props {
    series: ChartSeries[]
    periodInDays?: number,
}

export const ViewsChartCard: React.FC<Props> = (props) => {
    const { series, periodInDays } = props
    const theme = useTheme()

    return (
        <AnalyticCard>
            <Chart
                height={280}
                options={getChartOptions(theme, periodInDays)}
                series={series}
                type="area"
            />
        </AnalyticCard>
    )
}
