import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import { MARKER_TYPES } from '../../static/enum'
import Marker from '../../store/Marker'
import { preventMouseActions } from '../../utils/mouse.utils'
import { useSeekbarStyles } from '../player/styles.seekbar'


interface VideoChapterProps {
    width: number
    marker: Marker
    onClick?: (chapter: MarkerData) => void
}

const useStyles = makeStyles({
    caption: {
        position: 'absolute',
        left: '50%',
        bottom: -32,
        transform: 'translateX(-50%)',
        textTransform: 'none',
        opacity: .4,
    },
})

const VideoChapter = observer((props: VideoChapterProps) => {
    const { marker, width } = props
    const classes = useStyles()
    const [ t ] = useTranslation()
    const classesSeekbar = useSeekbarStyles()

    const handleChapterClick = () => {
        props.onClick && props.onClick({
            type: marker.getType,
            from: marker.getFrom,
            to: marker.getTo,
            name: marker.getName,
            markerEntity: marker,
        })
    }

    return (
        <Grid
            item
            style={{
                width: `${width}%`,
            }}
        >
            <Paper className={classesSeekbar.seekbarTile}>
                <Button
                    variant="text"
                    size="small"
                    onClick={handleChapterClick}
                    className={classes.caption}
                    onMouseMove={preventMouseActions}
                    onMouseDown={preventMouseActions}
                    onMouseUp={preventMouseActions}
                >
                    <Typography variant="caption">
                        {(marker.getType !== MARKER_TYPES.EMPTY) && (marker.getName || t(marker.getType))}
                    </Typography>
                </Button>
            </Paper>
        </Grid>
    )
})

export default VideoChapter
