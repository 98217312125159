import { analytics, NewVoucher, PurchaseVoucher, tivio } from '@tivio/core-js'
import { AnalyticsConversionStatus, PurchaseStatus, QerkoPaymentInfo, QerkoTransaction } from '@tivio/types'
import { useObserver } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'

import { useUser } from '../useUser'

import { validateVoucher } from './utils/qerko.utils'


type Props = {
    onPaid?: () => void
    onError?: () => void
}

const useTransactionPayment = (videoId: string, monetizationId: string, voucher?: NewVoucher, props: Props = {}): QerkoTransaction => {
    const [paymentInfo, setPaymentInfo] = useState<QerkoPaymentInfo | null>(null)
    const [paymentError, setError] = useState('')
    const { user } = useUser()

    const getPaymentInfo = useCallback(async () => {
        setError('')

        try {
            if (!tivio.purchaseVideoWithQerko) {
                throw new Error('purchaseVideoWithQerko not in bundle')
            }

            validateVoucher(voucher)

            let result: QerkoPaymentInfo | null = null
            if (!videoId) {
                result = await tivio.purchaseSubscriptionWithQerko(monetizationId, voucher as PurchaseVoucher)
                throw new Error('videoId is required')
            } else {
                result = await tivio.purchaseVideoWithQerko(videoId, monetizationId, voucher as PurchaseVoucher)
            }

            setPaymentInfo(result)
        } catch (e: any) {
            setError(e.message)
        }
    }, [monetizationId, videoId, voucher])

    useEffect(() => {
        getPaymentInfo()
    }, [user?.isSignedIn])

    const findPurchase = useCallback((all = false) => {
        const purchases = (all ? user?.allPurchases : user?.purchases) ?? []

        return purchases.find((purchase) => {
            return (
                purchase.id === paymentInfo?.purchaseId
            )
        })
    }, [user, monetizationId, videoId, paymentInfo])


    const paymentStatus = useObserver(() => {
        if (paymentInfo && user) {
            const purchase = findPurchase(true)

            if (purchase) {
                return purchase.status
            }

        }

        return PurchaseStatus.NEW
    })

    const voucherId = useObserver(() => {
        if (paymentInfo && user) {
            const purchase = findPurchase()
            return purchase?.voucherId ?? undefined
        }
    })

    useEffect(() => {
        switch (paymentStatus) {
            case PurchaseStatus.ERROR:
                props.onError?.()
                // It could make false positive, but we resolve later
                analytics.completeMonetizationPurchase(AnalyticsConversionStatus.FAILED, monetizationId)
                break
            case PurchaseStatus.PAID:
                props.onPaid?.()
                analytics.completeMonetizationPurchase(AnalyticsConversionStatus.SUCCESS, monetizationId)
                break
        }
    }, [paymentStatus, props])

    return {
        paymentInfo,
        paymentError,
        paymentStatus,
        voucherId,
        getPaymentInfo,
    }
}

export {
    useTransactionPayment,
}
