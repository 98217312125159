import { makeStyles } from '@material-ui/core/styles'


const useMarkerEditorStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        paddingBottom: theme.spacing(4),
    },
    addMarkerContainer: {
        position: 'absolute',
        left: '50%',
        top: 0,
        zIndex: 10,
        transform: 'translateX(-50%) translateY(-50%)',
    },
}))

export {
    useMarkerEditorStyles,
}
