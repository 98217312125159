import { Typography } from '@material-ui/core'
import { isInThePast, roundWithStep } from '@tivio/common'
import { Dayjs } from 'dayjs'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import styled from 'styled-components'

import { useEpgUI } from '../../hooks/dataHooks/useEpgUI'

import { AddScheduleItemArea } from './AddScheduleItemArea'
import { TIMELINE_HEIGHT_PX } from './ScheduleTimeline'


const ADD_BUTTON_MOVING_STEP_MINUTES = 5

const AddScheduleItemAreaContainer = styled.div<{ top: number }>`
    display: none;
    width: 100%;
    position: absolute;
    z-index: 10;
    height: ${TIMELINE_HEIGHT_PX}px;

    top: ${props => props.top}px;
`

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    &:hover ${AddScheduleItemAreaContainer} {
        display: block;
    }
`

const MouseListenArea = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
`

const Text = styled(Typography)`
    position: absolute;
    top: -20px;
`

type Props = {
    columnDate: Dayjs
    onClick: (time: Dayjs) => void
}

/**
 * Component that is absolutely positioned to the full column and listen mouse moves to show Add button.
 */
export const EpgScheduleColumnAddArea = ({ columnDate, onClick }: Props) => {
    const ref = useRef<HTMLDivElement & AddItemAreaFunctions>()
    const { itemHeightCoefficient, convertPxToTime } = useEpgUI()


    const onMouseMove = (event: React.MouseEvent) => {
        if (event.currentTarget !== event.target) {
            // ignore events on probable children
            return
        }

        const value = roundWithStep(event.nativeEvent.offsetY, ADD_BUTTON_MOVING_STEP_MINUTES * itemHeightCoefficient)

        if (isInThePast(convertPxToTime(value, columnDate))) {
            // don't show add button in the past
            return
        }
        ref.current?.setTop(value)
    }

    const handleClick = (top: number) => {
        onClick(convertPxToTime(top, columnDate))
    }

    return (
        <Container>
            <MouseListenArea onMouseMove={onMouseMove}/>
            <AddItemArea
                ref={ref}
                onClick={handleClick}
            />
        </Container>
    )
}

type AddItemAreaProps = {
    onClick: (top: number) => void
}

type AddItemAreaFunctions = {
    setTop: (top: number) => void
}

/**
 * Area with Add button. Follows mouse move.
 */
const AddItemArea = forwardRef(({ onClick }: AddItemAreaProps, ref) => {
    const [ top, setTop ] = useState(0)
    const { convertPxToTime } = useEpgUI()

    const text = convertPxToTime(top).format('HH:mm')

    /**
     * useImperativeHandle is used to make possible store 'top' state here
     * and avoid unneeded re-renders of parent component on each mouse move.
     */
    useImperativeHandle(ref, () => ({
        setTop,
    }))

    return (
        <AddScheduleItemAreaContainer top={top - TIMELINE_HEIGHT_PX}>
            <Text>{text}</Text>
            <AddScheduleItemArea
                show={true}
                onButtonClick={() => onClick(top)}
                topExtension={0}
                bottomExtension={0}
                height={TIMELINE_HEIGHT_PX}
            />
        </AddScheduleItemAreaContainer>
    )
})
