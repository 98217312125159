import Channel from '../store/Channel'
import OrganizationAdmin from '../store/OrganizationAdmin'
import Video from '../store/Video'


const formOrganizationCoverName = (organization: OrganizationAdmin) => {
    const organizationId = organization.id

    return `organizations/${organizationId}/organization-cover`
}

const formChannelHeaderCoverName = (channel: Channel) => {
    const organizationId = channel.getOrganization.id
    const channelId = channel.getId

    return `organizations/${organizationId}/channels/${channelId}/${channelId}-channel-header`
}

const formChannelLogoName = (channel: Channel) => {
    const organizationId = channel.getOrganization.id
    const channelId = channel.getId

    return `organizations/${organizationId}/channels/${channelId}/${channelId}-channel-logo`
}

const formSectionElementCoverName = (element: Video) => {
    if (!element.section) {
        return
    }

    const organizationId = element.section.getChannel.getOrganization.id
    const channelId = element.section.getChannel.getId
    const sectionId = element.section.getId
    const elementId = element.getId

    return `organizations/${organizationId}/channels/${channelId}/sections/${sectionId}/elements/${elementId}-element-cover`
}

const formSectionVideoName = (ext: string, element: Video) => {
    const organizationId = element.section!.getChannel.getOrganization.id
    const videoId = element.getId

    return `${organizationId}/${videoId}.${ext}`
}

const formSectionVideoFileName = (videoId: string, videoName: string) => {
    return `${videoId}/${videoName}`
}

export {
    formChannelHeaderCoverName,
    formChannelLogoName,
    formSectionElementCoverName,
    formSectionVideoFileName,
    formOrganizationCoverName,
    formSectionVideoName,
}
