import { FormControl, FormLabel, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { TivioNotification } from "../../../../store/Notification";
import { Row } from "../../../uiLayout/Row";
import { NotificationSurveyTargetingField } from "@tivio/firebase";

interface Props {
    notification: TivioNotification;
}

// Select with gender All/Male/Female, input age from, age to (validation that age to is greater than age from) (min 0, max 100)
export const SurveyTargeting = ({ notification }: Props) => {
    const handleChange = (newSurveyValue: Partial<NotificationSurveyTargetingField>) => {
        notification.targeting = {
            ...notification.targeting,
            isActive: false,
            isVoucher: false,
            type: "survey",
            survey: {
                ...notification.targeting?.survey,
                ...newSurveyValue,
            },
        };
    };
    return (
        <Row spacing={1}>
            <FormControl component="fieldset">
                <InputLabel>
                    Gender
                </InputLabel>
                <Select
                    value={notification.targeting?.survey?.gender ?? "all"}
                    onChange={(e) =>
                        handleChange({
                            gender: e.target.value === "all" ? undefined : (e.target.value as NotificationSurveyTargetingField["gender"]),
                        })
                    }
                >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="m">Male</MenuItem>
                    <MenuItem value="f">Female</MenuItem>
                </Select>
            </FormControl>
            <FormControl variant="outlined" margin="dense" style={{
                width: 120,
            }}>
                <InputLabel>Age from</InputLabel>
                <OutlinedInput
                    type="number"
                    value={notification.targeting?.survey?.ageFrom}
                    label="Age from"
                    inputProps={{
                        min: 0,
                        max: 100,
                    }}
                    onChange={(e) =>
                        handleChange({
                            ageFrom: e.target.value === "" ? undefined : parseInt(e.target.value),
                        })
                    }
                />
            </FormControl>
            <FormControl variant="outlined" margin="dense" style={{
                width: 120,
            }}>
                <InputLabel>Age to</InputLabel>
                <OutlinedInput
                    type="number"
                    margin="dense"
                    value={notification.targeting?.survey?.ageTo}
                    label="Age to"
                    inputProps={{
                        min: 0,
                        max: 100,
                    }}
                    onChange={(e) =>
                        handleChange({
                            ageTo: e.target.value === "" ? undefined : parseInt(e.target.value),
                        })
                    }
                />
            </FormControl>
        </Row>
    );
};
