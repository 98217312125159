import { VideoDocument } from '@tivio/firebase'

import Video from '../../store/Video'
import { getFirestore, loggerFirestore } from '../app'

import { videoConverter } from './converters'

import type firebase from 'firebase/app'


const getElementsCollection = (): firebase.firestore.CollectionReference<VideoDocument> => {
    return getFirestore().collection('videos').withConverter(videoConverter)
}

const addVideo = async (videoData: VideoDocument) => {
    try {
        const videoRef = await getElementsCollection().add(videoData)

        loggerFirestore.info('Video written  with id: ', videoRef.id)

        return videoRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add video', e)
        throw new Error(e)
    }
}

const updateElement = async (element: Video, elementData: Partial<VideoDocument>) => {
    try {
        await getElementsCollection().doc(element.getId).update(elementData)

        loggerFirestore.info('Element updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update element', e)
        throw new Error(e)
    }
}

const addPlaylist = async (playlistData: VideoDocument) => {
    try {
        const playlistRef = await getElementsCollection().add(playlistData)

        loggerFirestore.info('Playlist written with id: ', playlistRef.id)

        return playlistRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add playlist', e)
        throw new Error(e)
    }
}

const updatePlaylistVideos = async (playlist: Video, videoRefs: firebase.firestore.DocumentReference<VideoDocument>[]) => {
    try {
        await getElementsCollection().doc(playlist.getId).update({ videos: videoRefs })

        loggerFirestore.info('Playlist videos updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update playlist', e)
        throw new Error(e)
    }
}

const deleteElement = async (element: Video) => {
    try {
        await getElementsCollection().doc(element.getId).delete()

        loggerFirestore.info('Element deleted')
    }
    catch (e) {
        loggerFirestore.error('Failed to delete element: ', e)
        throw new Error(e)
    }
}

export {
    addVideo,
    addPlaylist,
    getElementsCollection,
    updateElement,
    updatePlaylistVideos,
    deleteElement,
}
