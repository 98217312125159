import { Grid, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'


type TProps = {
    className?: string,
}

const useStyles = makeStyles({
    logo: {
        backgroundImage: 'url(/media/tivio@2x.png)',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: 400,
        height: 224,
    },
})

/**
 * App logo component
 */
const AppLogo = (props: TProps) => {
    const classes = useStyles()

    return (
        <Grid
            container
            justifyContent="center"
        >
            <Grid item>
                <div className={clsx(classes.logo, props.className)}/>
            </Grid>
        </Grid>
    )
}

export default AppLogo
