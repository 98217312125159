import i18next from 'i18next'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import './firebase/app'


i18next.init({
    // allow keys to be phrases having `:`
    nsSeparator: false,
})

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root'),
)
