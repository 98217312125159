import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'

import { COLORS } from '../../static/enum'
import { preventMouseActions } from '../../utils/mouse.utils'


interface Props {
    left: number
    onCaretClick?: () => void
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    caret: {
        position: 'absolute',
        top: -theme.spacing(1),
        bottom: -theme.spacing(1),
        pointerEvents: 'none',
        width: 2,
        backgroundColor: theme.palette.common.white,
    },
    addMarkerButton: {
        backgroundColor: COLORS.PRIMARY,
        pointerEvents: 'auto',
        transform: 'translateX(1px) translateX(-50%) translateY(-100%)',
    },
}))

export const PlayerSeekbarCaret = observer((props: Props) => {
    const classes = useStyles()

    const left = useMemo(
        () => {
            if (props.left < 0) {
                return 0
            }

            if (props.left > 100) {
                return 100
            }

            return props.left
        },
        [ props.left ],
    )

    return (
        <div
            className={classes.caret}
            style={{
                left: `${left}%`,
            }}
        >
            {
                !props.disabled && (
                    <IconButton
                        size="small"
                        className={classes.addMarkerButton}
                        onClick={props.onCaretClick}
                        onMouseDown={preventMouseActions}
                        onMouseMove={preventMouseActions}
                        onMouseUp={preventMouseActions}
                    >
                        <Add/>
                    </IconButton>
                )
            }
        </div>
    )
})
