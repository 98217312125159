import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { FC, useRef, useState } from 'react'

import { Modal } from '../modal'

import { ColorCircle } from './ColorCircle'


const colors = [
    '#27d5b3',
    '#16a085',
    '#2ECC71',
    '#27AE60',
    '#3498DB',
    '#196ca3',
    '#9B59B6',
    '#8133a1',
    '#C0392B',
    '#E74C3C',
    '#D35400',
    '#E67E22',
    '#F39C12',
    '#F1C40F',
    '#ECF0F1',
    '#a4aeb4',
    '#788687',
    '#5f696a',
    '#3d5063',
    '#263c52',
]

const useStyles = makeStyles(() => ({
    modalGrid: {
        width: '120px',
        padding: '10px 0',
    },
    modalItem: {
        margin: '5px',
    },
}))

interface ColorPickerProps {
    color: string
    onColorChange: (color: string) => void
}

export const ColorPicker: FC<ColorPickerProps> = ({ color, onColorChange }) => {
    const [ isSelectOpen, setIsSelectOpen ] = useState(false)
    const colorCircleRef = useRef(null)
    const classes = useStyles()

    return (
        <div ref={colorCircleRef}>
            <ColorCircle
                color={color}
                onClick={() => setIsSelectOpen(true)}
            />
            <Modal
                isOpen={isSelectOpen}
                hidePopup={() => setIsSelectOpen(false)}
                anchorEl={colorCircleRef.current}
                placement="bottom-start"
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.modalGrid}
                >
                    {colors.map((color) => (
                        <Grid item key={color} className={classes.modalItem}>
                            <ColorCircle
                                color={color}
                                onClick={() => {
                                    onColorChange(color)
                                    setIsSelectOpen(false)
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Modal>
        </div>
    )
}

export const DEFAULT_COLOR = colors[0]
