import { Currency, DurationWithUnit } from '@tivio/types'

import Monetization from '../store/Monetization'


export function getCurrency(monetization: Monetization): Currency {
    return Object.keys(monetization.getPrices)[0] as Currency ?? 'EUR'
}

export function getPrice(monetization: Monetization): number {
    const currency = getCurrency(monetization)

    return monetization.getPrices[currency] ?? 0
}

export function getVariantId(activatingMonetizationId: string, activatingDuration?: DurationWithUnit): string {
    return `${activatingMonetizationId}${activatingDuration ? `_${activatingDuration.value}_${activatingDuration.unit}` : ''}`
}
