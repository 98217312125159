import { NotificationSubstitutionField, TemplateSubstitutionField } from '@tivio/firebase'
import { makeAutoObservable } from 'mobx'

import { getVideoRef } from '../firebase/firestore/video'


export class NotificationSubstitution {
    public data: NotificationSubstitutionField

    constructor(
        private templateSubstitutionData: TemplateSubstitutionField,
        data: NotificationSubstitutionField | undefined,
        public notificationId: string,
        public notificationPath: string,
    ) {
        makeAutoObservable(this)

        this.data = data ? { ...data } : {
            key: templateSubstitutionData.key,
            value: null,
        }
    }

    get type() {
        return this.templateSubstitutionData.type
    }

    get value() {
        return this.data.value
    }

    get name() {
        return this.templateSubstitutionData.name
    }

    get key() {
        return this.templateSubstitutionData.key
    }

    get presetName() {
        return this.templateSubstitutionData.presetName
    }

    get videoId() {
        return this.data.videoRef?.id
    }

    set value(value: string | null | undefined) {
        this.data.value = value
    }

    set videoId(videoId: string | undefined) {
        this.data.videoRef = videoId ? getVideoRef(videoId) : undefined
    }
}
