import {
    Button,
    CircularProgress,
    fade,
    Grid,
    Grow,
    IconButton,
    Paper,
    TextField,
    ThemeProvider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import { MARKER_TYPES } from '../../static/enum'
import { lightTheme } from '../../static/theme'
import Marker from '../../store/Marker'
import { alertError } from '../../utils/alert.utils'
import { createInterval, DEFAULT_EPG_MARKERS, Interval } from '../../utils/epg.utils'
import { isPointMarker, noChapterMarker, noVirtualMarker } from '../../utils/markers.utils'
import { validators } from '../../utils/seekbar.utils'
import { hoursToSeconds, secondsToTimeString, timeStringToSeconds } from '../../utils/time.utils'
import { DEFAULT_VOD_MARKERS, findParentChapter, onlyChaptersFilter } from '../../utils/vod.utils'

import PlayerControls, { EpgPlayerControlsProps } from './PlayerControls'
import PlayerMarkerTypeSelect from './PlayerMarkerTypeSelect'

import type { ProgressEvent } from '../asset/assetImageInput'


export interface EpgAddMarkerProps extends EpgPlayerControlsProps {
    contentDuration: number
    marker: MarkerData
    markers: Marker[]
    onClose: () => void
    onDelete: (marker: Marker) => void
    onIntervalChange: (interval: Interval) => void
    onMarkerFormSubmit: (markerData: MarkerData) => void
    onMarkerUpdate: (marker: Marker, markerData: MarkerData) => void
    onPlayingPositionChange: (playingPosition: number) => void
    onCoverChange?: (cover: Blob) => void
    currentEpg?: Interval
    isVideoCut?: boolean
    videoElement?: HTMLVideoElement
    coverProgressEvent?: ProgressEvent | null
}

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(3),
        position: 'relative',
        width: 887,
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 0,
        backgroundColor: fade(theme.palette.common.black, .5),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.black, .7),
        },
        transform: 'translateX(50%) translateY(-50%)',
    },
    closeIcon: {
        color: 'white',
        fontSize: '1rem',
    },
    coverWrapper: {
        position: 'absolute',
        top: '-46px',
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    coverButton: {
        '&.Mui-disabled': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            opacity: 0.9,
        },
    },
}))

/**
 * Text of the "save" button.
 */
const markerButtonText = (markerType: MARKER_TYPES): string => {
    switch (markerType) {
        case MARKER_TYPES.CUT:
            return 'Create cut'
        case MARKER_TYPES.TRAILER:
            return 'Create trailer'
        case MARKER_TYPES.TASTING:
            return 'Create tasting'
        default:
            return 'Save'
    }
}

/**
 * If the TextField should be disabled or not. :)
 */
const textFieldDisabledState = (markerType: MARKER_TYPES) => {
    return markerType === MARKER_TYPES.CUT
        || markerType === MARKER_TYPES.TRAILER
}

interface CreateMarkerForm {
    type: MARKER_TYPES
    from: string
    to: string
    name?: string,
}

/**
 * Consumes marker data (from/to in seconds) and content duration, allows to edit marker data before saving
 * if marker data contains marker entity (database object) then it will allows to update / delete marker
 */
export const PlayerMarkerForm = observer((props: EpgAddMarkerProps) => {
    const { marker, videoElement } = props

    const [focusedInput, setFocusedInput] = useState<'from' | 'to'>('from')
    const [t] = useTranslation()
    const fromInputRef = useRef<HTMLInputElement | null>(null)
    const classes = useStyles()
    const form = useFormik<CreateMarkerForm>({
        initialValues: {
            type: marker.markerEntity ? marker.markerEntity.getType : MARKER_TYPES.AD,
            from: secondsToTimeString(marker.from, !!props.currentEpg),
            to: secondsToTimeString(marker.to, !!props.currentEpg),
            name: marker.name || '',
        },
        onSubmit: (values) => {
            const from = timeStringToSeconds(values.from) - getEpgTimePart()
            const to = timeStringToSeconds(values.to) - getEpgTimePart()
            let type = values.type

            if (type === MARKER_TYPES.AD && marker.to) {
                type = MARKER_TYPES.AD_SEGMENT
            }

            if (marker.markerEntity) {
                props.onMarkerUpdate(marker.markerEntity, {
                    from,
                    to,
                    type,
                    name: values.name,
                })
            }
            else {
                const markerData: MarkerData = {
                    from,
                    to,
                    type,
                    name: values.name,
                }

                props.onMarkerFormSubmit(markerData)
            }
        },
        validateOnChange: true,
    })

    useEffect(
        () => {
            if (isPointMarker(form.values.type)) {
                setFocusedInput('from')
                form.getFieldHelpers('to').setValue(form.values.from)
                props.onIntervalChange({ from: 0, to: 0 })
            }

            if (form.values.type === MARKER_TYPES.CHAPTER && !marker.markerEntity) {
                const chapters = props.markers.filter(onlyChaptersFilter)

                if (chapters.length === 0) {
                    form.getFieldHelpers('from').setValue(secondsToTimeString(0, false))
                    form.getFieldHelpers('to').setValue(form.values.from)
                }
                else {
                    const closestMarker = findParentChapter(
                        chapters,
                        timeStringToSeconds(form.values.from),
                        timeStringToSeconds(form.values.to),
                    )

                    if (closestMarker) {
                        form.getFieldHelpers('from').setValue(secondsToTimeString(closestMarker.getFrom, false))
                        form.getFieldHelpers('to').setValue(form.values.from)

                        handlePlayingPositionChange(timeStringToSeconds(form.values.from))
                    }
                    else {
                        alertError(t('Bad chapter interval'))
                    }
                }
            }
        },
        [form.values.type],
    )

    useEffect(
        () => {
            if (form.values.from !== form.values.to) {
                const from = timeStringToSeconds(form.values.from) - getEpgTimePart()
                const to = timeStringToSeconds(form.values.to) - getEpgTimePart()

                props.onIntervalChange(
                    createInterval(
                        from,
                        to,
                    ),
                )
            }
        },
        [form.values.from, form.values.to],
    )

    useEffect(
        () => {
            let playingPosition

            if (focusedInput === 'from') {
                playingPosition = timeStringToSeconds(form.values.from)
            }
            else {
                playingPosition = timeStringToSeconds(form.values.to)
            }

            handlePlayingPositionChange(playingPosition)
        },
        [focusedInput],
    )

    const getEpgTimePart = () => {
        if (!props.currentEpg) {
            return 0
        }

        return hoursToSeconds(dayjs.unix(props.currentEpg.from).utc(true).hour())
    }

    const handlePlayingPositionChange = (playingPosition: number) => {
        let newPlayingPosition = playingPosition

        if (props.currentEpg) {
            newPlayingPosition = playingPosition - getEpgTimePart()
        }

        props.onPlayingPositionChange(newPlayingPosition)
    }

    const handleUpdatePlayingPosition = (step: number) => {
        const fromPosition = timeStringToSeconds(form.values.from)
        const toPosition = timeStringToSeconds(form.values.to)

        if (focusedInput === 'from') {
            const newFromPosition = fromPosition + step

            if (form.values.type === MARKER_TYPES.CHAPTER && !props.marker.markerEntity) {
                const parentChapter = findParentChapter(
                    props.markers.filter(onlyChaptersFilter),
                    fromPosition,
                    toPosition,
                )

                if (parentChapter && (parentChapter.getFrom >= newFromPosition)) {
                    return
                }
            }

            if (
                validators.isEqualToZero(fromPosition) ||
                !validators.isGreaterThanZero(newFromPosition)
            ) {
                return
            }

            if (
                !isPointMarker(form.values.type) &&
                validators.isIntersect(newFromPosition, timeStringToSeconds(form.values.to))
            ) {
                return
            }

            form.getFieldHelpers('from').setValue(secondsToTimeString(newFromPosition, false))
            if (isPointMarker(form.values.type)) {
                form.getFieldHelpers('to').setValue(secondsToTimeString(newFromPosition, false))
            }

            handlePlayingPositionChange(newFromPosition)
        }
        else if (focusedInput === 'to') {
            const newToPosition = toPosition + step

            if (form.values.type === MARKER_TYPES.CHAPTER && !props.marker.markerEntity) {
                const parentChapter = findParentChapter(
                    props.markers.filter(onlyChaptersFilter),
                    fromPosition,
                    toPosition,
                )

                if (parentChapter && (parentChapter.getTo <= newToPosition)) {
                    return
                }
            }

            if (
                validators.isEqualToContentDuration(toPosition, props.contentDuration) ||
                validators.isIntersect(fromPosition, newToPosition)
            ) {
                return
            }

            form.getFieldHelpers('to').setValue(secondsToTimeString(newToPosition, false))
            handlePlayingPositionChange(newToPosition)
        }
    }

    const handleDeleteMarker = async () => {
        if (marker.markerEntity) {
            props.onDelete(marker.markerEntity)
        }
    }

    const handleSelectMarkerType = (e: React.MouseEvent<HTMLDivElement>) => {
        setFocusedInput('from')
        form.handleChange(e)
    }

    const handleCoverClick = useCallback(() => {
        if (typeof props.onCoverChange !== 'function' || !videoElement) {
            return
        }
        const canvas = document.createElement('canvas')

        canvas.height = videoElement.videoHeight
        canvas.width = videoElement.videoWidth

        canvas.getContext('2d')!.drawImage(videoElement, 0, 0)
        canvas.toBlob(
            (blob) => {
                if (!blob) {
                    // TODO: handle error
                    console.error('NO BLOB')
                }
                else {
                    props.onCoverChange?.(blob)
                }
            },
            'image/png',
            1,
        )

    }, [props, videoElement])

    const deleteButton = useMemo(
        () => {
            if (!marker.markerEntity) {
                return
            }

            return (
                <Grid item>
                    <IconButton onClick={handleDeleteMarker}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            )
        },
        [],
    )

    const markerOptions = useMemo(
        () => {
            let markers = props.currentEpg ? DEFAULT_EPG_MARKERS : DEFAULT_VOD_MARKERS

            if (props.marker.markerEntity) {
                if (props.marker.markerEntity.getType === MARKER_TYPES.CHAPTER) {
                    return markers
                }
                else {
                    markers = markers.filter(noChapterMarker)
                }
            }

            if (props.isVideoCut) {
                markers = markers.filter(noVirtualMarker)
            }

            return markers
        },
        [form.values],
    )

    const isSelectDisabled = useMemo(
        () => (marker.markerEntity && marker.markerEntity.getType === MARKER_TYPES.CHAPTER),
        [marker.markerEntity],
    )

    const isToDisabled = useMemo(
        () => isPointMarker(form.values.type),
        [form.values.type],
    )

    const isCoverUploading = props.coverProgressEvent?.state === 'uploading' || props.coverProgressEvent?.state === 'resizing'

    return (
        <ThemeProvider theme={lightTheme}>
            <Grow in={true}>
                <Paper
                    elevation={2}
                    className={classes.paper}
                >
                    {videoElement && props.onCoverChange && (
                        <div className={classes.coverWrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={isCoverUploading ? <CircularProgress
                                    size={16}
                                    color="inherit"
                                /> : <AddAPhotoIcon />}
                                title={t('Create video cover (thumbnail) from this frame.')}
                                onClick={handleCoverClick}
                                disabled={isCoverUploading}
                                className={classes.coverButton}
                            >
                                {isCoverUploading ? t('Processing cover image, please wait...') : t('Create cover image')}
                            </Button>
                        </div>
                    )}
                    <IconButton
                        className={classes.closeButton}
                        onClick={props.onClose}
                        size="small"
                    >
                        <CloseIcon className={classes.closeIcon} />
                    </IconButton>
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item>
                            <PlayerControls
                                isPlaying={props.isPlaying}
                                onPlay={props.onPlay}
                                onPause={props.onPause}
                                onSeekWithControls={handleUpdatePlayingPosition}
                            />
                        </Grid>
                        <Grid item>
                            <form onSubmit={form.handleSubmit}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs
                                    >
                                        <PlayerMarkerTypeSelect
                                            value={form.values.type}
                                            disabled={isSelectDisabled}
                                            name="type"
                                            onClick={handleSelectMarkerType}
                                            options={markerOptions}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                    >
                                        <TextField
                                            autoFocus={!marker.to}
                                            error={!!form.errors.from}
                                            focused={focusedInput === 'from'}
                                            fullWidth
                                            helperText={t(form.errors.from)}
                                            label={t('From')}
                                            name="from"
                                            onFocus={() => setFocusedInput('from')}
                                            ref={fromInputRef}
                                            size="small"
                                            value={form.values.from}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                    >
                                        <TextField
                                            autoFocus={!!marker.to}
                                            disabled={isToDisabled}
                                            error={!!form.errors.to}
                                            focused={focusedInput === 'to'}
                                            fullWidth
                                            helperText={t(form.errors.to)}
                                            label={t('To')}
                                            name="to"
                                            onFocus={() => setFocusedInput('to')}
                                            size="small"
                                            value={form.values.to}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                    >
                                        <TextField
                                            error={!!form.errors.name}
                                            fullWidth
                                            helperText={t(form.errors.name)}
                                            label={t('Title')}
                                            name="name"
                                            onChange={form.handleChange}
                                            size="small"
                                            value={form.values.name}
                                            variant="outlined"
                                            disabled={textFieldDisabledState(form.values.type)}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                        >
                                            {t(markerButtonText(form.values.type))}
                                        </Button>
                                    </Grid>
                                    {deleteButton}
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </Paper>
            </Grow>
        </ThemeProvider>
    )
})
