import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React, { ReactElement, ReactNode, useState } from 'react'

import type { ButtonProps } from '@material-ui/core/Button'


interface Props extends ButtonProps {
    children: ReactElement<typeof MenuItem>[]
    label: ReactNode
}

export const MenuButton = ({ label, children, ...buttonProps }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const cloneChild = (child: ReactElement) => {
        return React.cloneElement(child, {
            onClick: (e: React.MouseEvent<HTMLElement>) => {
                handleClose()
                if (child.props.onClick) {
                    child.props.onClick(e)
                }
            },
        })
    }

    return (
        <div>
            <Button
                {...buttonProps}
                aria-haspopup="true"
                onClick={handleClick}
            >
                {label}
            </Button>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {React.Children.map(children, cloneChild)}
            </Menu>
        </div>
    )
}
