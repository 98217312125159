import { Logger, startPolling } from '@tivio/common'
import { analytics, tivio } from '@tivio/core-js'
import {
    BufferChunk,
    Drm,
    LangCode,
    PlayerEngineConfig,
    PlayerEngineEvent,
    PlayerEngineInterface,
    PlayerEngineListener,
    PlayerEngineOptions,
    VideoSourceEncryption,
} from '@tivio/types'
import {
    ShakaAdaptationEvent,
    net as ShakaNet,
    Player as ShakaPlayer,
    ShakaVariantChangedEvent,
    Track,
    util,
} from 'shaka-player'

import { BaseEngine } from '../baseEngine'

import { shakaEnableHls, shakaInstallPolyfillsOnce } from './shaka.utils'
import {
    chunksEqual,
    chunksToPercent,
    filterDistantChunks,
    isAdaptive,
    isValidQuality,
} from './utils'

import type { ShakaBufferingEvent, ShakaError } from 'shaka-player'


shakaEnableHls()

const logger = new Logger('shakaEngine')

interface ShakaErrorReadable extends ShakaError {
    codeText: string
    categoryText: string
}

const reverseObject = (obj: Record<string, number>) => {
    const result = {} as Record<number, string>

    Object.keys(obj).forEach(key => {
        const value = obj[key]
        result[value] = key
    })

    return result
}

const ERROR_CATEGORIES = reverseObject(util.Error.Category)
const ERROR_CODES = reverseObject(util.Error.Code)

const isShakaError = (error: any) => {
    return 'code' in error && 'category' in error
}

const makeErrorReadable = (error: any) => {
    if (!isShakaError(error)) {
        return error
    }

    const result = error as ShakaErrorReadable

    result.codeText = ERROR_CODES[result.code] ?? ''
    result.categoryText = ERROR_CATEGORIES[result.category] ?? ''

    return error
}

/**
 * Events: https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html
 * Browser support: https://github.com/google/shaka-player
 *
 * Known issues
 * - does not support DASH on iOS https://github.com/google/shaka-player/blob/master/docs/tutorials/faq.md
 * - certain HLS sources that used to be playable using v2.5 are no longer playable
 *  in v3 with a warning `Raw formats are not yet supported. Skipping audio/aac`
 *  e.g. https://d2zihajmogu5jn.cloudfront.net/bipbop-advanced/bipbop_16x9_variant.m3u8
 *  (see https://github.com/google/shaka-player/issues/1083)
 */
export class ShakaEngine extends BaseEngine implements PlayerEngineInterface {
    public readonly shakaPlayer: ShakaPlayer
    private _destroy: () => void
    private currentConfig: PlayerEngineConfig | null = null
    private bufferedInfo: BufferChunk[] = []
    private drmConfiguration: Drm | null = null
    // After the load of MP4 source shaka performs a seek to the requested position,
    // this is not a shaka bug, it's how MP4 works. It cannot be loaded from the middle,
    // it has to be loaded from the beginning and seeked.
    private preventSeekEvent = false
    private isLoading = false
    private activeSubtitles: LangCode | null = null
    private _lastQuality: number | null = null

    private seekRange?: {
        startMs: number
        endMs: number
    }

    private stopSeekRangePolling: () => void = () => {}

    constructor(
        videoElement: HTMLVideoElement,
        // Seeking too aggressively may cause problems with playback.
        // That's why we have an option to debounce seeks
        // https://github.com/google/shaka-player/issues/2247
        options?: PlayerEngineOptions,
    ) {
        super(videoElement, options)

        if (!ShakaPlayer.isBrowserSupported()) {
            // This also happens on android mobile chrome browser when used over
            // CRA dev-server (It is a very specific bug/side effect in shaka-player)
            // TODO report or check why this is happening
            throw new Error('Shaka player is not supported by this browser')
        }

        shakaInstallPolyfillsOnce()

        this.shakaPlayer = new ShakaPlayer(videoElement)

        const networkingEngine = this.shakaPlayer.getNetworkingEngine()

        if (networkingEngine) {
            networkingEngine.registerRequestFilter((type, request) => {
                if (type == ShakaNet.NetworkingEngine.RequestType.LICENSE && this.drmConfiguration) {
                    request.headers = {
                        ...request.headers,
                        ...this.drmConfiguration.licenseRequestHeaders,
                    }
                }
            })
        }


        const listeners = [
            {
                subject: this,
                event: 'error',
                listener: (error: any) => {
                    logger.error('Shaka error', error, 'see https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html')
                },
            },
            {
                subject: this,
                event: 'loaded',
                listener: () => {
                    if (this.isPaused()) {
                        this.changeState('paused')
                    }

                    this.preventSeekEvent = true

                    // Set default audio track based on Tivio language
                    if (this.getAudioTracks().includes(tivio.language)) {
                        this.selectAudioTrack(tivio.language)
                    }
                },
            },
            {
                subject: this.shakaPlayer,
                event: 'variantchanged',
                listener: this.onTracksChanged,
            },
            {
                subject: this.shakaPlayer,
                event: 'adaptation',
                listener: (event: ShakaAdaptationEvent) => {
                    // TODO this should not be here, playerAnalytics.ts should call this
                    analytics.currentSourceQuality = event.newTrack.height
                    this._lastQuality = event.newTrack.height
                },
            },
            /**
             * Shaka emits 'timeupdate' event with offset when playing livestreams (including seeking ones),
             * we use seekRange() to determine it.
             */
            {
                subject: this,
                event: 'timeupdate',
                listener: (ms: number) => {
                    // We don't want to change position when user is seeking
                    if (this.isSeeking) {
                        return
                    }

                    // TODO how to stop propagation of original timeupdate event? ms is just seconds, can't stop it
                    // TODO if we can figure out this, we don't need to define additional event

                    // TODO idea - try subject: this.videoElement, mb we will catch the whole event
                    this.triggerEvent('enginetimeupdate', ms - this.seekingOffset)
                },
            },
            {
                subject: this,
                event: 'timeupdate',
                listener: this.updateBufferingInfo,
            },
            {
                subject: this,
                event: 'ended',
                listener: this.updateBufferingInfo,
            },
            {
                subject: this.shakaPlayer,
                event: 'buffering',
                listener: (event: ShakaBufferingEvent) => {
                    const url = this.currentConfig?.url

                    // TODO wrong buffering reporting of Shaka player

                    // Shaka player reports wrong 'buffering: true', in certain situations. That's why we ignore it
                    if ((
                        /**
                         * no URL
                         */
                        !url
                        /**
                         * for MP4 sources it starts buffering and never stops
                         */
                        || !isAdaptive(url)
                    ) && event.buffering) {
                        return
                    }

                    /**
                     * Shaka player reports wrong 'buffering: false' in certain situations. That's why we ignore it
                     * For some HLS sources (false for multiple seconds, true, false, true, false)
                     * or (false for multiple seconds, true, false)
                    */
                    if (this.isLoading && !event.buffering) {
                        return
                    }

                    this.triggerEvent('bufferingchange', event.buffering)
                },
            },
        ]

        listeners.forEach(({ subject, event, listener }) => {
            subject.addEventListener(event as any, listener as any)
        })

        const onSeeked = () => {
            if (this.preventSeekEvent) {
                this.preventSeekEvent = false
                return
            }

            this.triggerEvent('seeked')
        }
        const onSeeking = () => {
            if (this.preventSeekEvent) {
                return
            }

            this.triggerEvent('seeking')
        }
        super.addEventListener('seeked', onSeeked)
        super.addEventListener('seeking', onSeeking)

        this._destroy = () => {
            this.stopSeekRangePolling?.()
            listeners.forEach(({ subject, event, listener }) => {
                subject.removeEventListener(event, listener as any)
            })

            super.clear()
        }

        logger.info('initialized successfully')
    }

    get lastQuality() {
        return this._lastQuality
    }

    /**
     * @throws if loading source or pausing player failed
     */
    async load(config: PlayerEngineConfig) {
        logger.info('load', config)

        this.triggerEvent('busychange', true)

        this.resetState()
        this.setLoading(true)

        try {
            await this._load(config)
            await this.pause()
            this.triggerEvent('loaded')
        } catch (err) {
            const error = makeErrorReadable(err)
            this.onLoadFailed(error)
            throw error
        } finally {
            this.setLoading(false)
        }

        this.triggerEvent('busychange', false)
    }

    /**
     * @throws if loading source or un-pausing player failed
     */
    async play(config: PlayerEngineConfig) {
        logger.info('play', config)

        this.triggerEvent('busychange', true)
        this.resetState()
        this.setLoading(true)

        try {
            await this._load(config)
            await this.unpause()
            this.triggerEvent('loaded')
        } catch (err) {
            const error = makeErrorReadable(err)
            this.onLoadFailed(error)
            throw error
        } finally {
            this.setLoading(false)
            this.triggerEvent('busychange', false)
        }
    }

    async stop() {
        // logger.info('stop')
        this.triggerEvent('busychange', true)

        this.currentConfig = null
        this.resetState()
        await this.shakaPlayer.unload()

        this.triggerEvent('busychange', false)
    }

    async destroy() {
        // logger.info('destroy')

        await this.stop()
        this._destroy()
    }

    addEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        if (event === 'seeking' || event === 'seeked') {
            super.addCustomEventListener(event, callback)
            return
        }

        if (event === 'error') {
            this.shakaPlayer.addEventListener('error', callback)
        }

        super.addEventListener(event, callback)
    }

    removeEventListener<T>(event: PlayerEngineEvent, callback: PlayerEngineListener<T>) {
        if (event === 'seeking' || event === 'seeked') {
            super.removeCustomEventListener(event, callback)
            return
        }

        if (event === 'error') {
            this.shakaPlayer.removeEventListener('error', callback)
        }

        super.removeEventListener(event, callback)
    }

    /**
     * @returns currently playing audio track in ISO 639-1 format ('cs', 'en' etc.) or null if not available
     */
    getActiveAudioTrack(): LangCode | null {
        const tracks = this.shakaPlayer.getVariantTracks() as Track[]
        const activeTracks = tracks.filter(track => track.active)

        if (activeTracks.length === 1) {
            logger.info('active audio track:', activeTracks[0].language)

            return activeTracks[0].language as LangCode
        }

        return null
    }

    /**
     * @returns currently playing s in ISO 639-1 format ('cs', 'en' etc.) or null if not available
     */
    getActiveSubtitles(): LangCode | null {
        return this.activeSubtitles
    }

    /**
     * Returns currently available audio tracks of currently playing video. Should be called when player is in "loaded"
     * state, otherwise it will return an empty array.
     *
     * @returns array of available audio tracks in ISO 639-1 format ('cs', 'en' etc.)
     */
    getAudioTracks() {
        const audioTracks = this.shakaPlayer.getAudioLanguages() as LangCode[]

        if (audioTracks.length) {
            logger.info('audio tracks:', audioTracks)
        }

        return audioTracks
    }

    /**
     * Returns currently available subtitles of currently playing video. Should be called when player is in "loaded"
     * state, otherwise it will return an empty array.
     *
     * @returns array of available subtitle tracks in ISO 639-1 format ('cs', 'en' etc.)
     */
    getSubtitles() {
        return this.shakaPlayer.getTextLanguages() as LangCode[]
    }

    getQualities() {
        return this.shakaPlayer.getVariantTracks()
            .filter(isValidQuality) as Track[]
    }

    getCurrentQuality(lang?: LangCode) {
        const activeQualities = this.getQualities().filter(track => track.active)

        if (lang && activeQualities.length > 1) {
            return activeQualities.find(track => track.language === lang) ?? null
        }

        return activeQualities[0] ?? null
    }

    /**
     * Changes audio track of currently playing video.
     *
     * @param {LangCode} audioTrack - audio track to select in ISO 639-1 format ('cs', 'en' etc.)
     */
    selectAudioTrack = (audioTrack: LangCode) => {
        logger.info('Setting audio track:', audioTrack)

        this.shakaPlayer.selectAudioLanguage(audioTrack)
    }

    /**
     * Changes subtitles of currently playing video.
     *
     * @param {LangCode} subtitles - subtitles lang code to select in ISO 639-1 format ('cs', 'en' etc.) or null to disable subtitles
     */
    selectSubtitles = (subtitles: LangCode | null) => {
        if (subtitles) {
            this.shakaPlayer.selectTextLanguage(subtitles)
        }

        if (!!subtitles !== !!this.activeSubtitles) {
            this.shakaPlayer.setTextTrackVisibility(!!subtitles)
        }

        this.activeSubtitles = subtitles
        this.triggerEvent('trackschanged')
    }

    /**
     * Changing variants will take effect once the currently buffered
     * content has been played. (see shaka-player)
     */
    selectQuality(track: Track, options?: { force: boolean }) {
        logger.info('Setting quality:', track.height)

        this.enableAdaptation(false)
        this.shakaPlayer.selectVariantTrack(track, options?.force)
    }

    /**
     * Changes playback speed of currently playing video.
     *
     * @param {number} playbackSpeed - playback speed to select (1 = normal speed, 2 = twice as fast, etc.)
     */
    selectPlaybackSpeed = (playbackSpeed: number) => {
        logger.info('Setting playback speed:', playbackSpeed)

        this.shakaPlayer.trickPlay(playbackSpeed)
    }

    enableAdaptation(enabled: boolean) {
        logger.info('setting quality auto adaptation to', enabled)

        this.shakaPlayer.configure({ abr: { enabled } })
    }

    isAdaptationEnabled() {
        return this.shakaPlayer.getConfiguration().abr?.enabled ?? true
    }

    getBufferedInfo(): BufferChunk[] {
        return this.shakaPlayer.getBufferedInfo().video.map(chunk => {
            return {
                startMs: chunk.start * 1000,
                endMs: chunk.end * 1000,
            }
        })
    }

    private setLoading(loading: boolean) {
        this.isLoading = loading

        // We don't want to trigger bufferingchange until it represents real buffering state of shakaPlayer.
        if (loading === this.shakaPlayer.isBuffering()) {
            this.triggerEvent('bufferingchange', loading)
        }
    }

    /**
     * @throws if load failed
     */
    private async _load(config: PlayerEngineConfig) {
        await this.shakaPlayer.unload()

        this.currentConfig = config

        const {
            getDrmConfiguration,
            positionMs,
            url,
        } = this.currentConfig

        this.drmConfiguration = await getDrmConfiguration?.() ?? null

        this.shakaPlayer.configure({
            ...(this.drmConfiguration && {
                drm: {
                    servers: {
                        ...(this.drmConfiguration.encryption === VideoSourceEncryption.PLAYREADY && {
                            'com.microsoft.playready': this.drmConfiguration.licenseUrl,
                        }),
                        ...(this.drmConfiguration.encryption === VideoSourceEncryption.WIDEVINE && {
                            'com.widevine.alpha': this.drmConfiguration.licenseUrl,
                        }),
                    },
                },
            }),
            manifest: {
                // set it to 10s otherwise it may buffer every each and then while playing live stream (happened with JOJ live TV)
                defaultPresentationDelay: 10,
                dash: {
                    // https://jira.nangu.tv/browse/TIV-1376
                    autoCorrectDrift: false,
                    // see https://shaka-player-demo.appspot.com/docs/api/tutorial-faq.html#:~:text=%27manifest.dash.-,ignoreMinBufferTime,-%27%2C%20true
                    ignoreMinBufferTime: true,
                },
            },
            streaming: {
                // Similar to youtube
                bufferingGoal: 60, // 60 sec
            },
        })

        // keep positionForShaka undefined when positionMs is 0, thanks to that "playback will start at the default
        // start time (0 for VOD and liveEdge for LIVE)" (from documentation of Shaka Player load method)
        let positionForShaka
        if (positionMs !== undefined && positionMs !== 0) {
            positionForShaka = positionMs / 1000
        }

        await this.shakaPlayer.load(url, positionForShaka)

        this.startSeekRangePolling()
    }

    private updateBufferingInfo = (ms: number) => {
        const chunks = filterDistantChunks(this.getBufferedInfo(), ms)

        if (!chunksEqual(chunks, this.bufferedInfo)) {
            this.bufferedInfo = chunks
            this.triggerEvent('bufferedinfochange', chunksToPercent(chunks, this.getDurationMs()))
        }
    }

    private onLoadFailed(error: any) {
        logger.error('load failed', error, 'https://shaka-player-demo.appspot.com/docs/api/shaka.util.Error.html')

        this.triggerEvent('loadfailed')
        // TODO this should not be here, playerAnalytics.ts should call this
        analytics.reportError(error)
        this.resetState()
    }

    /**
     * For live streams (including seekable ones) shaka reports nonsensical values for 'durationchange' events.
     *
     * Documentation says that in this case we need to use seekRange() method to determine the duration.
     *
     * Based on https://github.com/shaka-project/shaka-player/issues/1238#issuecomment-359496722, there is no event for this,
     * so we need to poll, unfortunately.
     */
    private startSeekRangePolling = () => {
        this.stopSeekRangePolling()
        this.seekRange = undefined
        const { stop } = startPolling({
            fn: () => {
                const newSeekRange = this.shakaPlayer.seekRange()

                const startMs = newSeekRange.start * 1000
                const endMs = newSeekRange.end * 1000

                const isSameDuration = (endMs - startMs) === ((this.seekRange?.endMs ?? 0) - (this.seekRange?.startMs ?? 0))

                if (this.seekRange?.startMs === startMs && this.seekRange?.endMs === endMs) {
                    return
                }

                this.seekRange = {
                    startMs,
                    endMs,
                }

                if (!isSameDuration) {
                    const newDuration = this.seekRange.endMs - this.seekRange.startMs
                    this.triggerEvent('enginedurationchange', newDuration)
                }
            },
            intervalMs: 1000,
        })

        this.stopSeekRangePolling = stop
    }

    private get seekingOffset(): number {
        return this.seekRange?.startMs ?? 0
    }

    protected _seekToMs(ms: number) {
        const finalMs = ms + this.seekingOffset
        super._seekToMs(finalMs)
        this.updateBufferingInfo(finalMs)
    }

    private onTracksChanged = (track: ShakaVariantChangedEvent) => {
        this.triggerEvent('trackschanged')
    }
}
