import Monetization from '../../store/Monetization'
import Organization from '../../store/Organization'
import { getFirestore, loggerFirestore } from '../app'

import type { MonetizationDocument } from '@tivio/firebase'
import type { Disposer } from '@tivio/types'
import type firebase from 'firebase/app'


const converter = {
    fromFirestore: (snapshot: firebase.firestore.DocumentSnapshot): MonetizationDocument => {
        return snapshot.data() as MonetizationDocument
    },
    toFirestore: (monetization: MonetizationDocument): firebase.firestore.DocumentData => {
        return monetization
    },
}

const getMonetizationsCollection = (): firebase.firestore.CollectionReference<MonetizationDocument> => {
    return getFirestore()
        .collection('monetizations')
        .withConverter(converter)
}

export const getOrganizationMonetizationsQuery = (organization: Organization) => getMonetizationsCollection().where('organizationRef', '==', organization.ref)

export const listenMonetizations = (
    organization: Organization,
    callback: (data: Monetization[]) => void,
): Disposer => {
    return getOrganizationMonetizationsQuery(organization)
        .orderBy('created', 'desc')
        .onSnapshot({
            next: (snapshots) => {
                const monetizations = snapshots.docs.map(doc => {
                    return new Monetization(doc.ref, doc.data(), organization)
                })

                callback(monetizations)
            },
        })
}

export const addMonetization = async (monetization: MonetizationDocument) => {
    try {
        const docRef = await getMonetizationsCollection().add(monetization)

        loggerFirestore.info(`Monetization created (id: ${docRef.id})`, monetization)

        return docRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add monetization: ', e)
        throw new Error(e)
    }
}

export const updateMonetization = async (monetization: Monetization, monetizationData: Partial<MonetizationDocument>) => {
    try {
        await getMonetizationsCollection().doc(monetization.getId).update(monetizationData)

        loggerFirestore.info(`Monetization has been updated (id: ${monetization.getId})`, monetizationData)
    }
    catch (e) {
        loggerFirestore.error(`Failed to update monetization (id: ${monetization.getId})`, e, monetizationData)
        throw new Error(e)
    }
}

export const removeMonetization = async (id: string) => {
    try {
        await getMonetizationsCollection().doc(id).delete()

        loggerFirestore.info(`Monetization with ID ${id} has been removed`)

        return
    }
    catch (e) {
        loggerFirestore.info(`Failed to remove monetization with ID ${id}`)
        throw new Error(e)
    }
}

export const getMonetizationDocumentById = async (id: string) => {
    try {
        const document = await getFirestore().collection('monetizations').doc(id).get()
        return document.data()
    } catch (e) {
        loggerFirestore.error(`Failed to get document with id ${id}`, e)
        throw new Error(e)
    }
}

export const getMonetizationRef = (id: string) => {
    return getFirestore().collection('monetizations').doc(id) as firebase.firestore.DocumentReference<MonetizationDocument>
}
