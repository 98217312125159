import { IconButton, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { isElementOverflown } from '@tivio/common'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { COLORS } from '../../../static/enum'
import { TooltipWrapper } from '../../tooltip'


const ITEM_MIN_HEIGHT_PX = 50
const ITEM_PADDING_BOTTOM_PX = 12
const ITEM_SIDE_PADDING_PX = 12

// we know that video covers should have 9:16 ratio
const IMAGE_SIDES_RATIO = 9 / 16


const UPPER_TITLE_FONT_SIZE_PX = 12
const UPPER_TITLE_LINE_HEIGHT = 1
const UPPER_TITLE_PADDING_TOP_PX = 12
//always one line here
const UPPER_TITLE_HEIGHT_PX = UPPER_TITLE_LINE_HEIGHT * UPPER_TITLE_FONT_SIZE_PX + UPPER_TITLE_PADDING_TOP_PX

const TITLE_LINE_HEIGHT = 1.25
const TITLE_FONT_SIZE_PX = 15
const TITLE_PADDING_TOP_PX = 4
const TITLE_LINE_HEIGHT_PX = TITLE_LINE_HEIGHT * TITLE_FONT_SIZE_PX + TITLE_PADDING_TOP_PX

const DESCRIPTION_FONT_SIZE_PX = 12
const DESCRIPTION_LINE_HEIGHT = 1.5
const DESCRIPTION_LINE_HEIGHT_PX = DESCRIPTION_LINE_HEIGHT * DESCRIPTION_FONT_SIZE_PX
const DESCRIPTION_PADDING_TOP_PX = 4


const DeleteButton = styled(IconButton)`
    width: 32px;
    height: 32px;
    display: none;
    position: absolute;
    right: 9px;
    top: 9px;
`

const Container = styled.div<{ $heightPx: number, $smallMode?: boolean, $itemOverflow?: 'start' | 'end'}>`
    width: 100%;
    height: ${props => props.$heightPx}px;
    border-radius: 8px;
    background-color: ${COLORS.EPG_ITEM_BACKGROUND};

    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding: 0 ${ITEM_SIDE_PADDING_PX}px ${ITEM_PADDING_BOTTOM_PX}px;

    ${props => props.$itemOverflow === 'start' ? css`
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: 1px dashed ${COLORS.PRIMARY};
    ` : ''}

    ${props => props.$itemOverflow === 'end' ? css`
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 1px dashed ${COLORS.PRIMARY};
    ` : ''}

    ${props => props.$smallMode && css`
        flex-direction: row;
        align-items: center;
        gap: 8px;
        padding: 0 0 0 12px;
    `}

    &:hover ${DeleteButton} {
        display: flex;
    }

    &:hover {
        cursor: pointer;
    }
`

const Image = styled.img<{ $itemOverflow?: 'start' | 'end' }>`
    border-radius: 8px 8px 0 0;

    // ignore parent container padding
    margin: 0 -${ITEM_SIDE_PADDING_PX}px;

    ${props => props.$itemOverflow ? css`border-radius: 0;` : ''}
`

const UpperTitle = styled(Typography)<{ $smallMode?: boolean}>`
    line-height: ${UPPER_TITLE_LINE_HEIGHT};
    letter-spacing: 0.4px;
    color: ${COLORS.PRIMARY};
    font-size: ${UPPER_TITLE_FONT_SIZE_PX}px;
    padding-top: ${UPPER_TITLE_PADDING_TOP_PX}px;

    ${props => props.$smallMode && css`
        white-space: nowrap;
        padding: 0;
    `}
`

const OverflownTextStyles = css<{$maxLines?: number}>`
    ${props => props.$maxLines != null && css`
        // Needed to show ellipsis on the last line of multiline text
        display: -webkit-box;
        -webkit-line-clamp: ${props.$maxLines};
        -webkit-box-orient: vertical;
    `}
`

const Title = styled(Typography)<{$smallMode?: boolean}>`
    font-size: ${TITLE_FONT_SIZE_PX}px;
    font-weight: 500;
    padding-top: ${TITLE_PADDING_TOP_PX}px;
    line-height: ${TITLE_LINE_HEIGHT};
    letter-spacing: 0.12px;
    color: ${COLORS.EPG_ITEM_TEXT};
    overflow: hidden;
    flex: none;

    ${props => props.$smallMode && css`
        white-space: nowrap;
        text-overflow: ellipsis;
    `}

    ${props => !props.$smallMode && OverflownTextStyles}
`

const Description = styled(Typography)`
    font-size: ${DESCRIPTION_FONT_SIZE_PX}px;
    line-height: ${DESCRIPTION_LINE_HEIGHT};
    overflow: hidden;
    padding-top: ${DESCRIPTION_PADDING_TOP_PX}px;

    ${OverflownTextStyles}
`

type Props = {
    heightPx: number
    titleShort?: string
    title?: string
    upperTitle?: string
    description?: string
    image?: string
    /**
     * If item is "overflown" to/from other column (so it is split to 2 parts)
     */
    overflow?: 'start' | 'end'
    /**
     * Function called when user clicks on delete button.
     * If not provided, then delete button is never shown on this item.
     */
    onDelete?: () => void
    /**
     * On item click action
     */
    onClick?: () => void
}

type ScheduleItemStyle = {
    showImage: boolean
    titleMaxLines: number
    descriptionMaxLines: number
}

/**
 * Represents abstract item in schedule.
 * Can be shown in 4 variants (depending on height):
 * 1. small item - only upperTitle and title (both titles at one line), smaller paddings
 * 2. normal item without image
 * 3. normal item
 * 4. normal item with description
 */
export const ScheduleItem = ({ title, titleShort, upperTitle, description, image, heightPx, overflow, onDelete, onClick }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const titleRef = useRef<HTMLDivElement>(null)
    const descriptionRef = useRef<HTMLParagraphElement>(null)

    const [itemStyle, setItemStyle] = useState<ScheduleItemStyle>({ showImage: false, titleMaxLines: 0, descriptionMaxLines: 0 })

    const height = Math.max(heightPx, ITEM_MIN_HEIGHT_PX)

    const smallMode = height === ITEM_MIN_HEIGHT_PX
    const showDescription = !smallMode && itemStyle.descriptionMaxLines > 0

    /**
     * Compute if there is space for image/description or it should be hidden.
     */
    useEffect(() => {
        // compute max lines for title
        const titleHeight = (titleRef.current?.offsetHeight ?? 0)
        // Sometimes it can be computed as 0 due to bottom paddings. In this case padding is ignored and at least one line is shown.
        const titleMaxLines = 1

        const newTitleHeight = titleMaxLines * TITLE_LINE_HEIGHT_PX
        const fullTitleHeight = newTitleHeight + UPPER_TITLE_HEIGHT_PX

        // compute if is enough space for image
        const itemHeight = height - ITEM_PADDING_BOTTOM_PX
        const imageWidth = containerRef.current?.offsetWidth ?? 0
        const imageHeight = IMAGE_SIDES_RATIO * imageWidth
        const showImage = imageHeight + TITLE_LINE_HEIGHT_PX + UPPER_TITLE_HEIGHT_PX <= itemHeight

        // compute max lines for description (can be 0 or negative number - description will be hidden)
        const descriptionHeight = itemHeight - fullTitleHeight - (showImage ? imageHeight : 0) - DESCRIPTION_PADDING_TOP_PX
        const descriptionMaxLines = Math.floor(descriptionHeight / DESCRIPTION_LINE_HEIGHT_PX)

        setItemStyle({ showImage, titleMaxLines, descriptionMaxLines })
    }, [height, containerRef.current?.offsetWidth, titleRef.current?.offsetHeight])

    return (
        <Container
            ref={containerRef}
            $heightPx={height}
            $itemOverflow={overflow}
            $smallMode={smallMode}
            onClick={onClick}
        >
            {onDelete && (
                <DeleteButton onClick={onDelete}>
                    <DeleteIcon fontSize="small"/>
                </DeleteButton>
            )}
            {itemStyle.showImage && (
                <Image
                    src={image}
                    $itemOverflow={overflow}
                />
            )}
            <UpperTitle $smallMode={smallMode}>{upperTitle}</UpperTitle>
            <TooltipWrapper title={isElementOverflown(titleRef.current as HTMLElement) ? title : ''}>
                <Title
                    ref={titleRef}
                    $smallMode={smallMode}
                >
                    {titleShort}
                </Title>
            </TooltipWrapper>
            {showDescription && (
                <Description
                    ref={descriptionRef}
                >
                    {description}
                </Description>
            )}
        </Container>
    )
}
