import { DateTimePicker } from '@material-ui/pickers'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Row } from '../../uiLayout/Row'

import { CreatePurchaseForm } from './types'

import type { FormikProps } from 'formik'


const StyledDateTimePicker = styled(DateTimePicker)`
    width: 100%;
`

type Props = {
    formik: FormikProps<CreatePurchaseForm>
}

export const PurchaseDateTimePicker = ({ formik }: Props) => {
    const [t] = useTranslation()

    return <>
        <Row
            spacing={1}
            itemProps={{ xs: true }}
            rowProps={{ alignItems: 'stretch', alignContent: 'stretch' }}
        >
            <StyledDateTimePicker
                ampm={false}
                value={formik.values.startDate}
                format="DD MMM HH:mm"
                required
                onChange={(date) => formik.setFieldValue('startDate', date?.toDate())}
                variant="dialog"
                label={t('Start date')}
                inputVariant="outlined"
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate as string}
            />
            <StyledDateTimePicker
                ampm={false}
                value={formik.values.endDate}
                format="DD MMM HH:mm"
                required
                onChange={(date) => formik.setFieldValue('endDate', date?.toDate())}
                onBlur={() => formik.setFieldTouched('endDate')}
                variant="dialog"
                label={t('End date')}
                inputVariant="outlined"
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate as string}
            />
        </Row>
    </>
}
