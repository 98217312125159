import { Tag } from '../store/Tag'

import type { TagDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const createTag = (
    ref: firebase.firestore.DocumentReference<TagDocument>,
    data: TagDocument,
) => {
    return new Tag(
        ref,
        data,
    )
}

export {
    createTag,
}
