import { tivio } from '@tivio/core-js'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

import { useError } from './useError'

import type { Article, Disposer, PaginationInterface } from '@tivio/types'


export const useTaggedArticles = (tagId?: string) => {
    const [pagination, setPagination] = useState<
        PaginationInterface<Article>
    >({
        fetchMore: () => {},
        items: [],
        hasNextPage: false,
        loading: false,
    })

    const { error, raiseError } = useError()

    useEffect(() => {
        let disposer: Disposer | null = null

        const loadData = async () => {
            try {
                if (!tagId) {
                    return
                }

                const pagination = await tivio.getArticlesByTagId(tagId)

                disposer = autorun(() => {
                    setPagination({
                        fetchMore: pagination.fetchMore,
                        items: pagination.items,
                        hasNextPage: pagination.hasNextPage,
                        loading: pagination.loading,
                    })
                })
            } catch (error) {
                raiseError(error)
            }
        }

        loadData()

        return () => {
            if (disposer) {
                disposer()
            }
        }
    }, [raiseError, tagId])

    return {
        pagination,
        error,
    }
}
