import { Container, Divider, Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { Row } from './uiLayout/Row'


interface StyledContainerProps {
    $bottomGutter?: boolean
}

interface Props extends StyledContainerProps, PropsWithChildren {
    title?: string
    buttons?: React.ReactNode[]
    className?: string
}

const StyledDivider = styled(Divider)`
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const StyledContainer = styled(Container)<StyledContainerProps>`
    padding-top: 4rem;
    padding-bottom: ${props => props.$bottomGutter ? '4rem' : 0};
    position: relative;
`

const TabTitle = styled(Typography)`
    cursor: pointer;
`

const DefaultPageContainer: React.FC<Props> = (props) => {
    const renderHeader = () => {
        if (!props.title) {
            return null
        }

        return (
            <Typography variant="h5">
                {props.title}
            </Typography>
        )
    }

    const renderButtons = () => {
        return (
            <Row>
                {
                    props.buttons
                }
            </Row>
        )
    }


    return (
        <StyledContainer
            maxWidth="lg"
            $bottomGutter={props.$bottomGutter}
            className={props.className}
        >
            <Row
                rowProps={{
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {renderHeader()}
                {renderButtons()}
            </Row>
            {
                props.title && (
                    <StyledDivider />
                )
            }
            {props.children}
        </StyledContainer>
    )
}

export {
    DefaultPageContainer,
    StyledDivider,
    StyledContainer,
    TabTitle,
}
