import dayjs from 'dayjs'
import React from 'react'

import { OverviewCard } from '../OverviewCard'

import { ViewsPeakDetail } from './types'


interface Props {
    viewsPeakDetail: ViewsPeakDetail
    gainInterval: string
    periodInDays: number
}

export const ViewsPeakCard: React.FC<Props> = (props) => {
    const { viewsPeakDetail, gainInterval, periodInDays } = props

    const dateFormat = periodInDays === 1 ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'
    const viewPeak = viewsPeakDetail.currentPeriodViewsPeak
    const subvalue = viewPeak.date ? dayjs(viewPeak.date).utc().format(dateFormat) : undefined

    return (
        <OverviewCard
            title="Views peak"
            value={viewPeak.viewCount}
            subvalue={subvalue}
            gain={viewsPeakDetail.gainViewsPeak}
            gainInterval={gainInterval}
            key="views-peak"
        />
    )
}
