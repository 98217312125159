import {
    Box,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { VideoSourceField } from '@tivio/types'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Header } from '../common'
import { useDialogState } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { VideoSourceDialog } from './VideoSourceDialog'


interface Props {
    sources: VideoSourceField[],
    isUploaded: boolean
    isTranscoding: boolean
    isUploading: boolean
    isCut: boolean
    onUpdate: (sources: VideoSourceField[]) => Promise<void>,
    onPlay?: (index: number) => void
    disabled?: boolean

}

type SortedVideoSource = VideoSourceField & {
    originalIndex: number,
}

type SortedUris = SortedVideoSource[][]

const useStyles = makeStyles({
    sourceName: {
        fontSize: '1rem',
    },
    sourceInfo: {
        opacity: 0.8,
    },
    sourceHeader: {
        opacity: 0.5,
    },
    sourceActions: {
        textAlign: 'right',
    },
})

/**
 * Check if the given source-object is already in the list of sources.
 *
 * @param sources All existing video sources
 * @param newSource New source to add
 * @param editIndex Index of currently edited source
 * @returns True if the new source is already in the list
 */
const checkSourceDuplicity = (sources: VideoSourceField[], newSource: VideoSourceField, editIndex?: number) => {
    const serializedSources = sources.map(source =>
        source.priority
        + source.url
        + source.protocol
        + source.encryption
        + source.codec)

    const serializedNewSource = newSource.priority
        + newSource.url
        + newSource.protocol
        + newSource.encryption
        + newSource.codec

    const foundIndex = serializedSources.indexOf(serializedNewSource)

    return foundIndex > -1 && foundIndex !== editIndex // don't check duplicity of itself
}

const VideoSources: React.FC<Props> = ({ sources, onUpdate, onPlay, isUploaded, isTranscoding, isUploading, isCut, disabled }) => {
    const [t] = useTranslation()
    const classes = useStyles()
    const { isDialogOpen, openDialog, closeDialog } = useDialogState()
    const [editIndex, setEditIndex] = useState<number | undefined>(undefined)
    const { confirmAction } = useConfirmAction()
    const { showError, showSuccess } = useAlert()
    const locked = isUploaded || isTranscoding || isUploading || isCut || disabled // if sources editing is locked or not

    const sortedUrisByPriority = sources.reduce((sortedSources: SortedUris, currentSource: VideoSourceField, currentIndex: number) => {
        const sortedSource: SortedVideoSource = {
            ...currentSource,
            originalIndex: currentIndex,
        }

        if (!sortedSources[sortedSource.priority]) {
            sortedSources[sortedSource.priority] = []
        }

        sortedSource.originalIndex = currentIndex

        sortedSources[sortedSource.priority].push(sortedSource)
        return sortedSources
    }, [] as SortedUris)

    const handleAddSource = () => {
        setEditIndex(undefined)
        openDialog()
    }
    const handleEditSource = (index: number) => {
        setEditIndex(index)
        openDialog()
    }
    const handleDeleteSource = (index: number) => {
        confirmAction(async () => {
            try {
                const newSources: VideoSourceField[] = [...sources]
                newSources.splice(index, 1)
                await onUpdate(newSources)
                showSuccess(t('Video source has been removed.'))
            } catch (e) {
                showError(t('Unknown error occured while removing video source.'))
            }
        }, t('Are you sure you want to delete this video source?'))
    }
    const handleConfirmSource = async (data: VideoSourceField) => {
        try {
            const isEdit = typeof editIndex === 'number'
            const newUris: VideoSourceField[] = [...sources]
            if (checkSourceDuplicity(sources, data, editIndex)) {
                showError(t('Identical video source already exists.'))
                return
            }

            if (isEdit) {
                newUris[editIndex] = data
            } else {
                newUris.push(data)
            }

            await onUpdate(newUris)
            showSuccess(isEdit
                ? t('Video source has been changed.')
                : t('Video source has been added.'),
            )
        } catch (e) {
            showError(t('Unknown error occured while editing video sources.'))
        } finally {
            closeDialog()
        }
    }

    const headerActions = !locked ? {
        create: {
            title: t('Add source'),
            onCreate: handleAddSource,
        },
    } : undefined

    return (
        <Box mb={8}>
            <VideoSourceDialog
                open={isDialogOpen}
                onCancel={closeDialog}
                onConfirm={(data) => {
                    handleConfirmSource(data)
                }}
                sources={sources}
                index={editIndex}
            />
            <Header
                title={t('Sources')}
                actions={headerActions}
            />
            {locked && (
                <Box mb={2}>
                    <Typography color="textSecondary">
                        {isCut
                            ? t('You cannot manually change sources on video cut.')
                            : t('You cannot manually change sources on uploaded video.')
                        }
                    </Typography>
                </Box>
            )}
            {!locked && sources.length === 0 ? (
                t('There are no sources set for this video. Either upload a video file or add sources manually.')
            ) : (
                sortedUrisByPriority?.map((sources: SortedVideoSource[], priority: number) => (
                    <Box
                        mb={5}
                        key={priority}
                    >
                        <Box
                            mt={1}
                            mb={1}
                        >
                            <Grid
                                container
                                spacing={2}
                                alignItems="center"
                            >
                                <Grid
                                    item
                                    xs={4}
                                >
                                    <Typography
                                        variant="h6"
                                        gutterBottom
                                    >Priority {priority}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.sourceHeader}
                                >{t('Protocol')}</Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.sourceHeader}
                                >{t('Encryption')}</Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={classes.sourceHeader}
                                >{t('Codec')}</Grid>
                                <Grid
                                    item
                                    xs={2}
                                    className={clsx(classes.sourceHeader, classes.sourceActions)}
                                ></Grid>
                            </Grid>
                        </Box>
                        {sources.map((source: SortedVideoSource, index: number) => (
                            <Box
                                mt={1}
                                mb={1}
                                key={source.originalIndex}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    alignItems="center"
                                >
                                    <Grid
                                        item
                                        xs={4}
                                        className={classes.sourceName}
                                    >Source {index + 1}</Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className={classes.sourceInfo}
                                    >{source.protocol}</Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className={classes.sourceInfo}
                                    >{source.encryption}</Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className={classes.sourceInfo}
                                    >{source.codec}</Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className={classes.sourceActions}
                                    >
                                        <Box
                                            display="flex"
                                            justifyContent="flex-end"
                                            gridColumnGap="10px"
                                        >
                                            {!locked && (
                                                <>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleEditSource(source.originalIndex)}
                                                        title={t('Edit')}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleDeleteSource(source.originalIndex)}
                                                        title={t('Delete')}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                            {onPlay && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => onPlay(source.originalIndex)}
                                                    title={t('Preview this source')}
                                                >
                                                    <PlayArrowIcon />
                                                </IconButton>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        ))}
                    </Box>
                ))
            )}
        </Box>
    )
}

export {
    VideoSources,
}
