import { MenuItem, Select } from '@material-ui/core'
import dayjs from 'dayjs'
import React, { ChangeEvent, useState } from 'react'

import { Period } from '../../types/date'
import { DateRangeInput } from '../dateRange/DateRangeInput'
import { Row } from '../uiLayout/Row'

import { getDateRangeForPeriod, PERIOD_OPTIONS } from './services'


const LAST_SEVEN_DAYS_START_DAY = dayjs().subtract(7, 'day').startOf('day').toISOString()
const TODAY_END_DAY = dayjs().endOf('day').toISOString()

interface Props {
    onChange: (startDate: Date, endDate: Date, period: Period) => void;
}

export const PeriodPicker: React.FC<Props> = ({ onChange }) => {
    const [period, setPeriod] = useState<Period>(Period.LAST_SEVEN_DAYS)
    const [startDate, setStartDate] = useState<string>(LAST_SEVEN_DAYS_START_DAY)
    const [endDate, setEndDate] = useState<string>(TODAY_END_DAY)

    const handlePeriodChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedPeriod = event.target.value as Period
        const { startDate: innerStartDate, endDate: innerEndDate } = getDateRangeForPeriod(selectedPeriod)
        setPeriod(selectedPeriod)
        setStartDate(innerStartDate.toISOString())
        setEndDate(innerEndDate.toISOString())
        onChange(innerStartDate.toDate(), innerEndDate.toDate(), selectedPeriod)
    }
    const handleDateRangeChange = (startDate: Date, endDate: Date) => {
        onChange(startDate, endDate, period)
    }

    return (
        <Row
            spacing={2}
            rowProps={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
            itemProps={{ xs: 2 }}
        >
            {period === Period.CUSTOM_RANGE_DAYS &&
                <DateRangeInput
                    value={{ startDate: startDate, endDate: endDate }}
                    onChange={handleDateRangeChange}
                />
            }
            <Select
                id="period-picker"
                labelId="period-picker-label"
                value={period}
                onChange={handlePeriodChange}
                fullWidth
            >
                {PERIOD_OPTIONS.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Row>
    )
}
