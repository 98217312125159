import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
} from '@material-ui/core'
import React, { useState } from 'react'
import styled from 'styled-components'

import { AppThemeProvider } from '../../AppThemeProvider'


const StyledDialogContent = styled(DialogContent)`
    width: 560px;
`

interface Props {
    open: boolean
    onSubmit: (email: string) => Promise<void>
    onClose: () => void
}

export const TestSendDialog = ({ open, onSubmit, onClose }: Props) => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSend = async () => {
        setLoading(true)
        await onSubmit(email)
        setEmail('')
        setLoading(false)
        onClose()
    }

    return (
        <AppThemeProvider type="light">
            <Dialog
                maxWidth="sm"
                open={open}
                onClose={onClose}
            >
                <DialogTitle>Send test email</DialogTitle>
                <StyledDialogContent>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                    />
                </StyledDialogContent>
                <DialogActions>
                    <Button
                        onClick={onClose}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSend}
                        color="primary"
                        disabled={loading}
                    >
                        Send
                    </Button>
                </DialogActions>
            </Dialog>
        </AppThemeProvider>
    )
}
