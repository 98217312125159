import { NotificationDocument } from '@tivio/firebase'

import { TivioNotification } from '../../store/Notification'
import Organization from '../../store/Organization'
import { notEmptyFilter } from '../../utils/array.utils'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, getFirestore, loggerFirestore } from '../app'

import type firebase from 'firebase/app'


export const getNotificationCollection = (organizationId: string) =>
    getFirestore()
        .collection(`organizations/${organizationId}/notifications`)
        .withConverter({
            fromFirestore: (snapshot: FirestoreDocumentSnapshot): NotificationDocument => {
                return snapshot.data() as NotificationDocument
            },
            toFirestore: (notificationFirestore: NotificationDocument): FirebaseDocumentData => {
                return notificationFirestore
            },
        })

/**
 * Gets email template for which notification was created.
 * @param notification notification document
 * @param organization Organization class instance
 */
const getEmailTemplateForNotification = (notification: NotificationDocument, organization: Organization) => {
    console.log('getEmailTemplateForNotification')
    console.log(notification)
    console.log(organization.emailTemplates)
    return [...(organization.emailTemplates ?? [])]?.find(
        (template) => template.id === notification.templateRef.id,
    ) ?? undefined
}

/**
 * Creates new notification (in firebase) with generated id for provided organization.
 * Returns constructed Notification entity.
 *
 * @param organization id of organization
 * @param notificationData new notification data
 */
export const addTivioNotification = async (
    organization: Organization,
    notificationData: NotificationDocument,
): Promise<TivioNotification> => {
    const newNotificationData = { ...notificationData }
    const ref = await addNotificationToFirebase(organization.id, newNotificationData)
    const template = getEmailTemplateForNotification(newNotificationData, organization)

    if (!template) {
        throw new Error('Template for notification was not found.')
    }

    return new TivioNotification(ref, newNotificationData, template, organization)
}

/**
 * Adds new notification to notifications collection of organization (firebase document) with provided id.
 * Returns reference to created notification.
 *
 * @param organizationId id of organization
 * @param notificationData new notification data
 */
const addNotificationToFirebase = async (
    organizationId: string,
    notificationData: NotificationDocument,
): Promise<firebase.firestore.DocumentReference<NotificationDocument>> => {
    const ref = await getNotificationCollection(organizationId).add(notificationData)

    loggerFirestore.info(`Notification with id ${ref.id} created`)
    return ref
}

/**
 * Loads notifications by organization id.
 *
 * @param organization organization store
 */
export const getNotificationsByOrganization = async (organization: Organization): Promise<TivioNotification[]> => {
    const notificationsSnapshots = await getNotificationCollection(organization.id)
        .get()
    return notificationsSnapshots.docs.map(
        notificationSnapshot => {
            const notificationData = notificationSnapshot.data()
            const template = getEmailTemplateForNotification(notificationData, organization)
            if (!template) {
                return undefined
            }
            return new TivioNotification(notificationSnapshot.ref, notificationData, template!, organization)
        },
    ).filter(notEmptyFilter).sort(sortNotifications)
}

/**
 * Loads notification by organization id and notification id.
 * Throws error when notification is not found
 *
 * @param organization organization store
 * @param id notification document id
 */
export const getNotificationById = async (organization: Organization, id: string): Promise<TivioNotification> => {
    const notificationSnapshot = await getNotificationCollection(organization.id).doc(id).get()
    const notificationData = notificationSnapshot.data()

    if (!notificationSnapshot.exists || !notificationData) {
        throw Error(`Notification with id ${id} was not found.`)
    }

    const template = getEmailTemplateForNotification(notificationData, organization)
    if (!template) {
        throw Error(`Template for notification with id ${id} was not found.`)
    }    

    return new TivioNotification(notificationSnapshot.ref, notificationData, template, organization)
}

export const sortNotifications = (a: TivioNotification, b: TivioNotification) => {
    return b.created.getTime() - a.created.getTime()
}
