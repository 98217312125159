import { Watermark } from '@tivio/types'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'


type Props = {
    className?: string
    onWatermarkElementChange?: (mutations: MutationRecord[]) => void,
    watermark?: Watermark | null
    children?: React.ReactNode
}

const StyledContainer = styled.div`
    align-items: center;
    background: black;
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 0;
`

const StyledWatermark = styled.p<{ size: Watermark['size'], position: { x: number, y: number } | null }>`
    color: white;
    font-size: ${({ size }) => size === 'medium' ? 8 : 24}pt;
    left: calc(${props => props.position?.x}% - 150px);
    opacity: .5;
    position: absolute;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    top: calc(${props => props.position?.y}% - 20px);
`

export const Watermarker: React.FC<Props> = ({
    children,
    onWatermarkElementChange,
    watermark,
}) => {
    const [ observer, setObserver ] = useState<MutationObserver | null>(null)
    const [ position, setPosition ] = useState<{ x: number, y: number} | null>(null)
    const containerRef = useRef(null)

    useEffect(() => {
        if (onWatermarkElementChange) {
            setObserver(new MutationObserver(onWatermarkElementChange))
        }
    }, [ onWatermarkElementChange ])

    useEffect(() => {
        if (!watermark) {
            observer?.disconnect()
        }

        setPosition({
            x: 10 + Math.round(Math.random() * 80),
            y: 10 + Math.round(Math.random() * 80),
        })
    }, [ watermark ])

    // TODO prevent watermark rerendering, it changes attributes, and invokes onWatermarkElementChange when it shouldn't
    return (
        <StyledContainer ref={containerRef}>
            {children}
            {watermark && (
                <StyledWatermark
                    size={watermark.size}
                    position={position}
                    ref={() => {
                        // TODO mb we want subtree with attributes to detect style changes
                        containerRef.current && observer?.observe(containerRef.current, {
                            attributes: false,
                            childList: true,
                            subtree: true,
                            // characterData: true,
                            // attributeOldValue: true,
                            // attributeFilter: ['class', 'style'],
                        })
                    }}
                >
                    {watermark.caption}
                </StyledWatermark>
            )}
        </StyledContainer>
    )

}
