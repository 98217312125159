import i18n, { InitOptions, Module } from 'i18next'
import { initReactI18next } from 'react-i18next/hooks'

import translationEN from '../../../static/locales/en/translation.json'


const config: InitOptions = {
    resources: {
        en: {
            translation: translationEN,
        },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
}

/**
 * Init translation
 */
const useTranslator = () => {

    const init = () => {
        return i18n
            .use(initReactI18next as Module)
            .init(config)
    }

    return {
        init,
    }
}

export default useTranslator
