import { makeStyles } from '@material-ui/core'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { ArticleBlock } from '../../../../store/ArticleBlock'
import { NotificationSubstitution } from '../../../../store/NotificationSubstitution'
import { ItemSearchDialog } from '../../../common/ItemSearchDialog'
import { useDialogState } from '../../../hooks'
import { ContentPlaceholderButton } from '../../components/ContentPlaceholderButton'
import { ChangeButton } from '../ChangeButton'

import { VideoBlockPreview } from './VideoBlockPreview'


const useStyles = makeStyles({
    container: {
        position: 'relative',
    },
})

interface Props {
    block: ArticleBlock | NotificationSubstitution
    height?: string
}

export const VideoBlock = ({ block, height }: Props) => {
    const classes = useStyles()
    const [t] = useTranslation()

    const { isDialogOpen, openDialog, closeDialog } = useDialogState()

    const handleConfirmVideo = (itemPath: string) => {
        const pathSplit = itemPath.split('/')
        if (pathSplit[0] === 'videos') {
            block.videoId = pathSplit[1]
        }
        closeDialog()
    }

    return (
        <div className={classes.container}>
            {block.videoId ? (
                <>
                    <VideoBlockPreview videoId={block.videoId} />
                    <ChangeButton
                        startIcon={<VideoLibraryIcon />}
                        onClick={openDialog}
                    >
                        {t('Change video')}
                    </ChangeButton>
                </>
            ) : (
                <ContentPlaceholderButton
                    height={height}
                    onClick={openDialog}
                />
            )}

            <ItemSearchDialog
                searchIndex={ALGOLIA_INDEX_NAME.VIDEOS}
                open={isDialogOpen}
                onClose={closeDialog}
                onAdd={handleConfirmVideo}
                title={t('Pick a video')}
                buttonText={t('Pick')}
            />
        </div>
    )
}
