import { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AssetPreset } from '../../../store/AssetPreset'
import { getImageResolution, isSmaller } from '../../../utils/image.utils'


export type FileValidationResult = {
    warnings: string[]
    errors: string[]
}

export const useValidateFile = (preset: AssetPreset) => {
    const [t] = useTranslation()

    const validateFile = useCallback(async (file: File) => {
        const warnings = []
        const errors = []
        const minRequiredSize = preset.getMinRequiredSize()
        const minRecommendedSize = preset.getMinRecommendedSize()

        const imageResolution = await getImageResolution(file)

        if (isSmaller(imageResolution, minRequiredSize)) {
            // TODO: Change to error dialog, now the title is Confirm Action
            errors.push(
                t(
                    'Image does not have sufficient resolution of at least (width: {{width}}px, height: {{height}}px)',
                    { width: minRequiredSize.width, height: minRequiredSize.height },
                ),
            )
        } else if (isSmaller(imageResolution, minRecommendedSize)) {
            warnings.push(
                t(
                    'Image does not have recommended resolution of at least (width: {{width}}px, height: {{height}}px)' +
            ', which may be not sufficient for TV app. Are you sure you want to upload it?',
                    {
                        width: minRecommendedSize.width,
                        height: minRecommendedSize.height,
                    },
                ),
            )
        }

        const validationResult = { warnings, errors }

        return Promise.resolve(validationResult)
    }, [preset])

    return validateFile
}