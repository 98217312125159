import {
    Box,
    Container,
    IconButton,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import { Skeleton } from '@material-ui/lab'
import { ALGOLIA_INDEX_NAME, TagSimplicityType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Tag } from '../../store/Tag'
import { translate } from '../../utils/translate.utils'
import { useIndexedSearch } from '../hooks/dataHooks/useIndexedSearch'
import { useTags } from '../hooks/dataHooks/useTags'
import useBottomScrollListener from '../hooks/uiHooks/useBottomScrollListener'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useAllTagTypes } from '../settings/tagTypes/hooks'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { TagDialog } from './TagDialog'


interface Props {
    createdTag: Tag | null,
    onClose: () => void
}

const StyledTypography = styled(Typography)`
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
const StyledHeadTypography = styled(StyledTypography)`
    font-weight: bold;
`

const StyledRowSkeleton = styled(Row)`
    padding: 10px 8px 10px 8px;
`

const useStyles = makeStyles(() => ({
    skeletonButton: {
        marginRight: '8px',
    },
}))

const TagsEditorSkeleton = ({ alt }: { alt?: boolean }) => {
    const classes = useStyles()
    const lineHeight = '2.5em'
    return (
        <StyledRowSkeleton
            rowProps={{
                justifyContent: 'space-between',
            }}
            itemProp={{
                index: 0,
                props: {
                    xs: true,
                },
            }}
        >
            <StyledTypography variant="body1">
                <Skeleton
                    width={alt ? '8em' : '5em'}
                    height={lineHeight}
                />
            </StyledTypography>
            <StyledTypography variant="body1">
                <Skeleton
                    width="6em"
                    height={lineHeight}
                />
            </StyledTypography>
            <StyledTypography variant="body1">
                <Skeleton
                    width="8em"
                    height={lineHeight}
                />
            </StyledTypography>
            <StyledTypography variant="body1">
                <Skeleton
                    width={alt ? '7em' : '5em'}
                    height={lineHeight}
                />
            </StyledTypography>
            <StyledTypography variant="body1">
                <Row>
                    <Skeleton
                        width="1.3em"
                        height={lineHeight}
                        className={classes.skeletonButton}
                    />
                    <Skeleton
                        width="1.3em"
                        height={lineHeight}
                    />
                </Row>
            </StyledTypography>

        </StyledRowSkeleton>
    )
}
const TagsEditorHeader = () => {
    const [t] = useTranslation()
    return (<Row
        rowProps={{
            justifyContent: 'space-between',
        }}
        itemProp={{
            index: 0,
            props: {
                xs: true,
            },
        }}
    >
        <StyledHeadTypography variant="body1">
            {t('Name')}
        </StyledHeadTypography>
        <StyledHeadTypography
            variant="body1"
        >
            {t('Description')}
        </StyledHeadTypography>
        <StyledHeadTypography
            variant="body1"
        >
            {t('ID')}
        </StyledHeadTypography>
        <StyledHeadTypography
            variant="body1"
        >
            {t('Tag type')}
        </StyledHeadTypography>
        <StyledHeadTypography variant="body1">
            {t('Actions')}
        </StyledHeadTypography>
    </Row>)
}

const TagsEditor = observer(({ createdTag, onClose }: Props) => {
    const { confirmAction } = useConfirmAction()
    const [currentTag, setCurrentTag] = useState<Tag | null>(null)
    const { findTagById, removeTag } = useTags()
    const tagTypes = useAllTagTypes()
    const [t] = useTranslation()
    const [searchText, setSearchText] = React.useState('')
    const search = useIndexedSearch(ALGOLIA_INDEX_NAME.TAGS, { limit: 20 })

    const findTag = async (tagId: string) => {
        const tag = await findTagById(tagId)

        if (!tag) {
            throw new Error('tag-not-found')
        }

        return tag
    }

    const handleBottomScroll = () => {
        if (search?.pagination?.hasNextPage && !search?.isLoading) {
            search.pagination?.fetchMore()
        }
    }
    useBottomScrollListener(handleBottomScroll, { offset: 500, triggerOnNoScroll: true })

    const handleEditClick = async (tagId: string) => {
        const tag = await findTag(tagId)
        setCurrentTag(tag)
    }

    const forceRefreshSearch = useCallback(() => {
        setTimeout(() => {
            search.search(searchText, true)
        }, 1000)
    }, [search, searchText])

    const handleRemoveTag = async (tagId: string) => {
        const tag = await findTag(tagId)
        confirmAction(
            async () => {
                await removeTag(tagId)
                forceRefreshSearch()
            },
            `${t('Remove tag ')} ${tag.getNameTranslation.en}?`,
        )
    }

    useEffect(() => {
        if (createdTag) {
            setCurrentTag(createdTag)
            forceRefreshSearch()
        }
        // We wants to avoid infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createdTag, setCurrentTag])

    const closeDialog = (isSaved = false) => {
        setCurrentTag(null)
        onClose()
        if (isSaved) {
            // The result could be different after the change, so reset the search to prevent weird behavior.
            forceRefreshSearch()
        }
    }

    const getTagTypeId = (isComposed: boolean, tagTypeRefId: string) => {
        //  Tag entities with TagType entities inside
        const tagType = tagTypes.find(type => type.id === tagTypeRefId)

        const tagTypeId = isComposed ? TagSimplicityType.COMPOSED : translate(tagType?.tagTypeId || '')
        return tagTypeId
    }

    const handleSearchChange = (evt?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchText = evt?.target?.value ?? ''
        setSearchText(searchText)
        search.search(searchText)
    }

    return (
        <Container maxWidth="lg">
            <Box mb={2}>
                <TextField
                    onChange={handleSearchChange}
                    value={searchText}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                        'startAdornment': (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                        'endAdornment': searchText ? (
                            <InputAdornment position="end">
                                <IconButton
                                    size="small"
                                    title={t('Clear search')}
                                    onClick={() => handleSearchChange()}
                                >
                                    <ClearIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : undefined,
                    }}
                    placeholder={t('Search in tags')}
                />
            </Box>
            <Column spacing={2}>
                {currentTag && (
                    <TagDialog
                        tag={currentTag}
                        onClose={closeDialog}
                    />
                )}
                <TagsEditorHeader />
                {search?.pagination?.items?.map(tag => (
                    <Row
                        rowProps={{
                            justifyContent: 'space-between',
                        }}
                        itemProp={{
                            index: 0,
                            props: {
                                xs: true,
                            },
                        }}
                        key={tag.id}
                    >
                        <StyledTypography variant="body1">
                            {tag.name.en ?? ''}
                        </StyledTypography>
                        <StyledTypography
                            color="textSecondary"
                            variant="body1"
                        >
                            {tag.description ?? ''}
                        </StyledTypography>
                        <StyledTypography
                            color="textSecondary"
                            variant="body1"
                        >
                            {`ID ${tag.tagId}`}
                        </StyledTypography>
                        <StyledTypography
                            color="textSecondary"
                            variant="body1"
                        >
                            {getTagTypeId(tag.composed, tag.tagTypeRef?.id || '')}
                        </StyledTypography>
                        <StyledTypography variant="body1">
                            <IconButton
                                size="small"
                                key={`${tag.id}-edit`}
                                onClick={() => handleEditClick(tag.id)}
                                title={t('Edit tag')}
                            >
                                <CreateIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                                size="small"
                                key={`${tag.id}-delete`}
                                onClick={() => handleRemoveTag(tag.id)}
                                title={t('Delete tag')}
                            >
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </StyledTypography>
                    </Row>
                ))}
                {search.isLoading && (
                    <>
                        {Array(21).fill(1).map((_, id) => {
                            return (<TagsEditorSkeleton
                                key={`tag-skeleton-${id}`}
                                alt={id % 2 === 0}
                            />)
                        })}
                    </>
                )}
                {search.isEmptyResult && (
                    <Box
                        mt={2}
                        textAlign="center"
                    >
                        <Typography
                            color="textSecondary"
                            variant="body1"
                        >{t('No tags found.')}</Typography>
                    </Box>
                )}
                {search.error && (
                    <Box
                        mt={2}
                        textAlign="center"
                    >
                        <Typography
                            variant="body1"
                            color="error"
                        >{search.error.message}</Typography>
                    </Box>
                )}
            </Column>
        </Container>
    )
})

export {
    TagsEditor,
}
