import {
    Box,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import ClearIcon from '@material-ui/icons/Clear'
import { DateTimePicker } from '@material-ui/pickers'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import Logger from '../../logger'
import { ContentInterface } from '../../types/content'


const Separator = styled('span')`
    display: inline-block;
    margin: 0 10px;
    vertical-align: middle;
    line-height: 56px;
`

const logger = new Logger('ContentAvailability')

interface Props {
    content: ContentInterface,
    showManualControl: boolean
    disabled?: boolean
}

export const ContentAvailability: React.FC<Props> = observer(({ content, disabled = false, showManualControl }) => {
    const [t] = useTranslation()

    const handleDateFromChange = async (date: Dayjs | null) => {
        if (date === null) {
            content.setAvailability(null)
            return
        }
        await content.setAvailability(
            date === null
                ? null
                : { from: date.toDate() },
        )
    }

    const handleDateToChange = async (date: Dayjs | null) => {
        if (!content.availability?.from) {
            logger.error('Availability has no begin date')
            return
        }
        await content.setAvailability({
            to: date?.toDate() ?? null,
        })
    }

    const handleBlockChange = async (evt: React.ChangeEvent<HTMLInputElement>) => {
        await content.setManualBlock(evt.target.checked)

    }

    const handleClearFromClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation()
        handleDateFromChange(null)
    }
    const handleClearToClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation()
        handleDateToChange(null)
    }

    return (
        <>
            <Box mb={3}>
                <Typography variant="h6">Availability</Typography>
                <Typography
                    component="p"
                    color="textSecondary"
                >
                    Content may have limited availability. Unavailable content is visible to viewers,
                    they can see content's name, description, etc., but they cannot play or view the main content.
                    Instead of a video player a countdown is shown.
                </Typography>
                <Box my={2}>
                    <DateTimePicker
                        value={content.availability?.from || null}
                        onChange={handleDateFromChange}
                        showTodayButton
                        variant="dialog"
                        label={t('From')}
                        inputVariant="outlined"
                        disabled={disabled}
                        InputProps={{
                            startAdornment: !disabled && <InputAdornment position="start"><CalendarTodayIcon/></InputAdornment>,
                            endAdornment: !disabled && (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        onClick={handleClearFromClick}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {content.availability?.from && (
                        <>
                            <Separator>-</Separator>
                            <DateTimePicker
                                value={content.availability?.to || null}
                                onChange={handleDateToChange}
                                showTodayButton
                                variant="dialog"
                                label={t('To')}
                                placeholder="Optional"
                                inputVariant="outlined"
                                disabled={disabled}
                                InputProps={{
                                    startAdornment: !disabled && (<InputAdornment position="start"><CalendarTodayIcon/></InputAdornment>),
                                    endAdornment: !disabled && (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={handleClearToClick}
                                            >
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </>
                    )}
                </Box>
                {showManualControl && content.availability?.from && (
                    <>
                        <Typography component="h6">Manual availability override</Typography>
                        <Typography
                            component="p"
                            color="textSecondary"
                        >
                            Manually block content's availability. As long as it's blocked viewers are unable to play/view the content.
                            even if it's available (countdown has ended).
                        </Typography>
                        <div>
                            <FormControlLabel
                                label="Block availability"
                                control={<Checkbox
                                    color="primary"
                                    checked={content.availability?.manual}
                                    onChange={handleBlockChange}
                                    disabled={disabled}
                                />}
                            />
                        </div>
                    </>
                )}
            </Box>
        </>
    )
})
