import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import styled from 'styled-components'

import { TvProgram } from '../../../store/TvProgram'
import { ITEMS_GAP_PX, useEpgUI } from '../../hooks/dataHooks/useEpgUI'

import { AddScheduleItemArea } from './AddScheduleItemArea'
import { EpgScheduleItem } from './EpgScheduleItem'


const Container = styled.div<{ topPx: number }>`
    width: 100%;
    padding: 0 10px;
    position: absolute;
    top: ${props => props.topPx}px;
`

const AddScheduleItemAreaStub = styled.div`
    width: 100%;
    height: 16px;
`

type Props = {
    item: TvProgram
    columnDate: Dayjs
    onAdd: (time: Dayjs) => void
    onDelete: (item: TvProgram) => void
    isLocked?: boolean
}


/**
 * {@link EpgScheduleItem} with {@link AddScheduleItemArea} under it.
 */
export const EpgScheduleItemWithAddArea = ({ item, columnDate, onAdd, onDelete, isLocked }: Props) => {
    const { computeTopForItem } = useEpgUI()

    // const isLocked = item.to.isBefore(dayjs().add(EDITABLE_EPG_DAYS_IN_ADVANCE, 'days'))

    return (
        <Container topPx={computeTopForItem(item, columnDate)}>
            <EpgScheduleItem
                item={item}
                columnDate={columnDate}
                onDelete={onDelete}
                isLocked={isLocked}
            />
            {!item.isInThePast && !isLocked ? (
                <AddScheduleItemArea
                    onButtonClick={() => onAdd(item.to)}
                    height={ITEMS_GAP_PX}
                />
            ) : (
                <AddScheduleItemAreaStub/>
            )}
        </Container>
    )
}
