import { MonetizationPlacementConditionField, MonetizationPlacementConditionWhereFilterField, RowWhereFilterFieldPath } from '@tivio/firebase'
import { useFormik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { PlacementFilter } from './PlacementFilter'
import { SubmitButton } from './SubmitButton'

import type { WhereFilterOp } from '@firebase/firestore-types'
import type { RowFilterWhereField } from '@tivio/firebase'


interface Props {
    onSubmit: (value: MonetizationPlacementConditionField) => Promise<void>
    condition?: MonetizationPlacementConditionField
}

const DEFAULT_FILTER = { fieldPath: 'tags', opStr: 'array-contains-any' as WhereFilterOp, value: [] }

/**
 * Monetization placements for videos based on conditions (now used for videos that are not in channels/sections)
 */
// TODO TIV-806 when multiple filters will be added (using MultiFormikField), then no default filter will be set
export const PlacementCondition = ({ onSubmit, condition = { filters: [DEFAULT_FILTER] } }: Props) => {
    const [t] = useTranslation()

    const form = useFormik({
        initialValues: condition,
        enableReinitialize: true,
        onSubmit,
    })

    return (
        <form onSubmit={form.handleSubmit}>
            {/* TODO TIV-806 use MultiFormikField here*/}
            {form.values.filters.map((filter: MonetizationPlacementConditionWhereFilterField, index: number) => (
                <PlacementFilter
                    key={index}
                    filter={filter}
                    onChange={
                        (value) => form.setFieldValue(`filters[${index}]`, value)
                    }
                />
            ))}
            <SubmitButton type="submit">
                {t('Add')}
            </SubmitButton>
        </form>
    )
}
