import { makeStyles } from '@material-ui/core/styles'
import { ShakaEngine } from '@tivio/player-react'
import { GetSourceUrlRequest, GetSourceUrlResponse, VideoSourceField } from '@tivio/types'
import React, {
    forwardRef,
    Ref,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react'

import { getFunctions } from '../../firebase/app'
import { millisecondsToSeconds, secondsToMilliseconds } from '../../utils/time.utils'
import Content from '../common/Content'

import type { PlayerEngineEvent } from '@tivio/types'
import type { Track } from 'shaka-player'


export interface AppPlayerProps {
    onTimeUpdate?: (sec: number) => void
    onPlay?: () => void
    onPause?: () => void
    onBuffering?: (buffering: boolean) => void
    onDuration?: (sec: number) => void
}

export interface PlayerController {
    play: () => void
    pause: () => void
    toggle: () => void
    seek: (sec: number) => void
    load: (props: {
        // string is for old source format
        source: VideoSourceField | string
        positionSec: number
        documentId: GetSourceUrlRequest['id']
        documentType: GetSourceUrlRequest['documentType']
        sourceHistory?: string[]
    }) => Promise<void>
    unload: () => Promise<void>
    show: () => void
    hide: () => void
    domElement: HTMLVideoElement
    getTracks: () => Track[] | undefined
    selectTrack: (track: Track) => void
    getCurrentTrack: () => Track | undefined
}

const useStyles = makeStyles(() => ({
    video: {
        display: 'block',
        maxWidth: '100%',
        width: '100%',
        maxHeight: '100%',
        height: '100%',
        margin: 'auto',
    },
}))

interface ListenerConfig<T> {
    event: PlayerEngineEvent
    listener: (value?: T) => any
}

async function getSourceUrl({
    documentId,
    documentType,
    source,
    sourceHistory,
}: {
    documentId: GetSourceUrlRequest['id']
    documentType: GetSourceUrlRequest['documentType']
    source: VideoSourceField,
    sourceHistory?: string[]
}) {
    const request: GetSourceUrlRequest = {
        id: documentId,
        documentType,
        capabilities: [{
            codec: source.codec,
            encryption: source.encryption,
            protocol: source.protocol,
        }],
        // TODO first language is enough? should getSourceUrl accept more than 1 language in request body?
        ...source.languages?.[0] && { language: source.languages[0] },
    }

    if (sourceHistory) {
        request.sourceHistory = sourceHistory
    }

    // TODO copied from @tivio/firebase, decide if we want to import from there or not (need instantiate @tivio/firebase lib in admin first)
    const getSourceUrl = getFunctions().httpsCallable('getSourceUrl')
    const { data }: { data: GetSourceUrlResponse } = await getSourceUrl(request)
    return data
}

const _AppPlayer = (props: AppPlayerProps, ref: Ref<PlayerController>) => {
    const classes = useStyles()
    const engineRef = useRef<ShakaEngine | null>(null)
    const cleanupRef = useRef<() => any>(() => null)
    const [state, setState] = useState('')
    const [videoShown, setVideoShown] = useState(true)

    useEffect(() => {
        return function cleanup() {
            engineRef.current?.destroy()
            cleanupRef.current()
        }
    }, [])

    const initVideoEngine = useCallback(
        (videoElement: HTMLVideoElement | null) => {
            if (videoElement) {
                const engine = new ShakaEngine(videoElement, { seekDebounceMs: 200 })

                const listeners: ListenerConfig<any>[] = [
                    {
                        event: 'durationchange',
                        listener: (ms: number) => {
                            props.onDuration?.(millisecondsToSeconds(ms))
                        },
                    },
                    {
                        event: 'timeupdate',
                        listener: (ms: number) => {
                            if (!engine.isPaused()) {
                                props.onTimeUpdate?.(millisecondsToSeconds(ms))
                            }
                        },
                    },
                    {
                        event: 'pause',
                        listener: () => {
                            props.onPause?.()
                        },
                    },
                    {
                        event: 'statechange',
                        listener: setState,
                    },
                    {
                        event: 'play',
                        listener: () => {
                            props.onPlay?.()
                        },
                    },
                    {
                        event: 'seeking',
                        listener: () => {
                            props.onBuffering?.(true)
                        },
                    },
                    {
                        event: 'seeked',
                        listener: () => {
                            props.onBuffering?.(false)
                        },
                    },
                    {
                        event: 'bufferingchange',
                        listener: (isBuffering) => {
                            props.onBuffering?.(isBuffering)
                        },
                    },
                ]

                listeners.forEach(({ event, listener }) => {
                    engine.addEventListener(event, listener)
                })


                engineRef.current = engine

                cleanupRef.current = () => {
                    listeners.forEach(({ event, listener }) => {
                        engine.removeEventListener(event, listener)
                    })
                }
            }
        },
        [],
    )

    useImperativeHandle(
        ref,
        () => ({
            play: () => engineRef.current?.unpause(),
            pause: () => engineRef.current?.pause(),
            toggle: () => engineRef.current?.isPaused()
                ? engineRef.current?.unpause()
                : engineRef.current?.pause(),
            seek: (sec: number) => {
                engineRef.current?.seekTo(secondsToMilliseconds(sec))
            },
            load: async ({
                source,
                positionSec,
                documentId,
                documentType,
                sourceHistory,
            }) => {
                const isOldStringFormat = typeof source === 'string'
                const response = !isOldStringFormat
                    ? await getSourceUrl({ documentId, documentType, source, sourceHistory })
                    : undefined

                await engineRef.current?.load({
                    // TODO rename to url in engine interface
                    url: isOldStringFormat ? source : response!.url,
                    positionMs: secondsToMilliseconds(positionSec),
                    getDrmConfiguration: () => Promise.resolve(response?.drm),
                })
            },
            unload: async () => {
                await engineRef.current?.stop()
            },
            show: () => setVideoShown(true),
            hide: () => setVideoShown(false),
            domElement: engineRef.current?.getVideoElement() as HTMLVideoElement,
            selectTrack: (track: Track) => engineRef.current?.selectQuality(track, { force: true }),
            getTracks: () => engineRef.current?.getQualities(),
            getCurrentTrack: () => engineRef.current?.getCurrentQuality() ?? undefined,
        }),
        [],
    )

    return (
        <Content shown={videoShown}>
            <video
                data-e2e-player-state={state}
                crossOrigin="anonymous"
                ref={initVideoEngine}
                className={classes.video}
            />
        </Content>
    )
}

export const AppPlayer = forwardRef(_AppPlayer)
