import { useCallback, useContext, useEffect, useRef } from 'react'

import { PlayerContext } from '../context/PlayerContext'


const SEEK_STEP_MS = 10000

export const useVideoMethods = () => {
    const context = useContext(PlayerContext)

    const jumpBack = useCallback(() => context.video.controller?.seekBy(-SEEK_STEP_MS), [])
    const jumpForward = useCallback(() => context.video.controller?.seekBy(+SEEK_STEP_MS), [])
    const seekBy = useCallback((ms: number) => context.video.controller?.seekBy(ms), [])
    const toggleMute = useCallback(() => context.video.controller?.toggleMute(), [])
    const togglePause = useCallback(() => context.video.controller?.togglePause(), [])
    const unpause = useCallback(() => context.video.controller?.unpause(), [])
    const pause = useCallback(() => context.video.controller?.pause(), [])
    const replay = useCallback(() => context.video.controller?.replay(), [])
    const goFullScreen = useCallback(() => context.video.controller?.goFullScreen(), [])
    const volumeUp = useCallback(() => context.video.controller?.volumeUp(), [])
    const volumeDown = useCallback(() => context.video.controller?.volumeDown(), [])
    const retry = useCallback(() => context.video.controller?.retry(), [])
    const close = useCallback(() => context.video.controller?.close(), [])

    return {
        jumpBack,
        jumpForward,
        seekBy,
        toggleMute,
        togglePause,
        unpause,
        pause,
        replay,
        goFullScreen,
        volumeUp,
        volumeDown,
        retry,
        close,
    }
}

interface Callbacks {
    onStart?: () => void
    onEnd?: () => void
    onReplay?: () => void
    onLoadFailed?: () => void
    onProgress?: (ms: number) => void
}

// TODO TIV-1296 is this useful? I think it was originally intended for analytics
export const useMainEvents = (callbacks: Callbacks) => {
    const context = useContext(PlayerContext)

    const lastTimeUpdate = useRef(0)
    const STEP_MS = 1000 * 60 * 5 // 5 mins

    useEffect(() => {
        // TODO this gets triggered event if video load fails
        const onPlay = () => {
            callbacks.onStart?.()
        }

        const onTimeUpdate = (ms: number) => {
            if (lastTimeUpdate.current + STEP_MS < ms) {
                lastTimeUpdate.current = ms
                callbacks.onProgress?.(ms)
            }
        }

        const onLoadFailed = () => {
            callbacks.onLoadFailed?.()
        }

        context.video.engine?.addEventListener('play', onPlay)
        context.video.engine?.addEventListener('timeupdate', onTimeUpdate)
        context.video.engine?.addEventListener('loadfailed', onLoadFailed)

        return () => {
            context.video.engine?.removeEventListener('play', onPlay)
            context.video.engine?.removeEventListener('timeupdate', onTimeUpdate)
            context.video.engine?.removeEventListener('loadfailed', onLoadFailed)
        }
    }, [])
}
