
interface WebkitWindow extends Window {
    WebKitMediaKeys: any
}

/**
 * Converts string to Uint16Array.
 */
const stringToUint16Array = (string: string): Uint16Array => {
    const buffer = new ArrayBuffer(string.length * 2) // 2 bytes for each char
    const array = new Uint16Array(buffer)
    for (let i = 0, strLen = string.length; i < strLen; i++) {
        array[i] = string.charCodeAt(i)
    }
    return array
}

/**
 * Converts Uint16Array to string.
 */
const uint16ArrayToString = (array: Uint16Array): string => {
    const uint16array = new Uint16Array(array.buffer)
    return String.fromCharCode.apply(null, uint16array as unknown as number[])
}

/**
 * Converts base64 encoded string to Uint8Array.
 */
export const base64ToUint8Array = (input: string): Uint8Array => {
    const raw = window.atob(input)
    const rawLength = raw.length
    const array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++)
    {
        array[i] = raw.charCodeAt(i)
    }

    return array
}

/**
 * Converts Uint8Array to base64 encoded string.
 */
export const uint8ArrayToBase64 = (input: Uint8Array): string => {
    // TS doesn't recognize Uint16Array as a valid type for String.fromCharCode
    return window.btoa(String.fromCharCode.apply(null, input as unknown as number[]))
}

/**
 * Extracts contentId and extra url params for license request from initData.
 *
 * e.g.:
 * for streamonline - skd://123456789abcdef to 123456789abcdef
 * for insys        - skd://tivio.la.drm.cloud/acquire-license/fairplay?brandGuid=guid-1&KID=guid-2&IV=guid-3 to tivio.la.drm.cloud
 */
export const extractContentIdAndLicenseUrlParams = (initData: Uint16Array): {
    contentId: string
    licenseUrlParams?: URLSearchParams,
} => {
    const contentId: string = uint16ArrayToString(initData)

    if (contentId.indexOf('skd://') === -1) {
        return { contentId }
    }

    const url = new URL(contentId.substring(1).replace('skd://', 'https://'))

    return {
        contentId: url.hostname,
        licenseUrlParams: url.searchParams,
    }
}

export const concatInitDataIdAndCertificate = (initData: Uint8Array, id: string, cert: Uint8Array) => {
    const idArray: Uint16Array = typeof id == 'string'
        ? stringToUint16Array(id)
        : id

    // layout is [initData][4 byte: idLength][idLength byte: id][4 byte:certLength][certLength byte: cert]
    let offset = 0
    const buffer = new ArrayBuffer(initData.byteLength + 4 + idArray.byteLength + 4 + cert.byteLength)
    const dataView = new DataView(buffer)

    const initDataArray = new Uint8Array(buffer, offset, initData.byteLength)
    initDataArray.set(initData)
    offset += initData.byteLength

    dataView.setUint32(offset, idArray.byteLength, true)
    offset += 4

    offset += idArray.byteLength

    dataView.setUint32(offset, cert.byteLength, true)
    offset += 4

    const certArray = new Uint8Array(buffer, offset, cert.byteLength)
    certArray.set(cert)

    return new Uint8Array(buffer, 0, buffer.byteLength)
}

export const selectKeySystem = (): string => {
    const webkitWindow: WebkitWindow = window as unknown as WebkitWindow

    if (webkitWindow.WebKitMediaKeys.isTypeSupported('com.apple.fps.1_0', 'video/mp4')) {
        return 'com.apple.fps.1_0'
    }
    else {
        throw 'Key System not supported'
    }
}
