import { Logger } from '@tivio/common'
import { useEffect, useMemo } from 'react'

import { toDurationString } from '../../utils'

import { useVideo } from './useVideo'

import { useSimilars } from '.'


const logger = new Logger('useVodDetailScreen')

const OKTAGON_LIVE_STREAM_TAG_ID = 'tag-551'

const useVodDetailScreen = (videoIdOrUrlName: string) => {
    const { data: video, trailer } = useVideo(videoIdOrUrlName, {
        fetchTrailer: true,
    })

    const tagNames = useMemo(() => {
        if (!video || video.tags.some(tag => !tag.name)) {
            return ''
        }

        return video.tags
            .filter(tag => tag.type === 'genre')
            .map(tag => tag.name)
            .join(', ')
    }, [ video ])

    const tagIds = useMemo(() => {
        if (video?.tags.some(tag => !tag.tagId)) {
            return []
        }

        return video?.tags.map(item => item.tagId ?? '') ?? []
    }, [ video ])


    const { getSimilars, pagination, error } = useSimilars()

    useEffect(() => {
        if (video?.id) {
            getSimilars({ videoId: video.id })
        }
    }, [ getSimilars, video?.id ])

    useEffect(() => {
        if (error) {
            logger.error(`Error while trying to get similars for video ${video?.id}`, error)
        }
    }, [ video?.id, error ])

    const captions = tagNames ? [tagNames] : []

    // Duration
    const isLive = tagIds.includes(OKTAGON_LIVE_STREAM_TAG_ID)
    if (video && video.duration && !isLive) {
        captions.push(toDurationString(video.duration / 1000))
    }

    // Year
    if (video?.year) {
        captions.push(String(video.year))
    }

    return {
        captions,
        pagination,
        video,
        trailer,
    }
}

export {
    useVodDetailScreen,
}
