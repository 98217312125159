import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import { COLORS } from '../../static/enum'
import { IMarker } from '../../store/Marker'
import { Interval } from '../../utils/epg.utils'
import { isEntityMarker, isVirtualMarker, typeToName } from '../../utils/markers.utils'
import { percentage } from '../../utils/math.utils'
import { preventMouseActions } from '../../utils/mouse.utils'

import { PlayerInterval } from './PlayerInterval'


interface Props {
    marker: IMarker
    duration: number
    epgInterval?: Interval
    onMarkerClick?: (marker: MarkerData, anchorEl: HTMLElement) => void
    disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
    markerContainer: {
        position: 'absolute',
        top: -theme.spacing(1),
        bottom: -theme.spacing(1),
        backgroundColor: COLORS.MARKER_COLOR,
        zIndex: 100,
        width: 1,
        '&:hover': {
            zIndex: 200,
        },
    },
    marker: {
        height: '100%',
    },
    markerTypeChip: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        opacity: .7,
        marginTop: 16,
        transform: 'translateX(-46%)',
    },
    markerCaption: {
        position: 'absolute',
        bottom: -theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%) translateY(100%)',
        opacity: .7,
    },
}))

export const PlayerSeekbarMarker = observer(({ marker, duration, epgInterval, onMarkerClick, disabled }: Props) => {
    const classes = useStyles()
    const [t] = useTranslation()

    const getCaption = () => {
        if (marker.getName) {
            return marker.getName
        }

        return t(marker.getType)
    }

    const getMarkerPosition = (place: number) => {
        if (epgInterval) {
            let newPlace = place

            if (place > epgInterval.to) {
                newPlace = epgInterval.to
            }

            if (place < epgInterval.from) {
                newPlace = epgInterval.from
            }

            return percentage(newPlace - epgInterval.from, duration)
        }

        return percentage(place, duration)
    }

    const handleMarkerClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!isEntityMarker(marker)) {
            return
        }
        let from = marker.getFrom
        let to = marker.getTo

        if (epgInterval) {
            from = marker.getFrom - epgInterval.from
            to = marker.getTo - epgInterval.from
        }

        onMarkerClick && onMarkerClick(
            {
                type: marker.getType,
                from,
                to,
                name: marker.getName,
                markerEntity: marker,
            },
            e.currentTarget,
        )
    }

    const renderIntervalMarker = () => {
        return (
            <PlayerInterval
                from={getMarkerPosition(marker.getFrom)}
                to={getMarkerPosition(marker.getTo)}
                onMarkerClick={(anchorEl) => handleMarkerClick(anchorEl)}
                marker={marker}
            />
        )
    }

    const renderPointMarker = () => {
        return (
            <div
                className={classes.markerContainer}
                onMouseMove={preventMouseActions}
                onMouseUp={preventMouseActions}
                onMouseDown={preventMouseActions}
                style={{
                    left: `${getMarkerPosition(marker.getFrom)}%`,
                }}
            >
                <div className={classes.marker} />
                {isEntityMarker(marker) && (
                    <Chip
                        className={classes.markerTypeChip}
                        onClick={handleMarkerClick}
                        clickable={!disabled}
                        label={marker?.getType}
                    />
                )}
                {isVirtualMarker(marker) && (
                    <div className={classes.markerCaption}>{typeToName(marker.getType)}</div>
                )}
            </div>
        )
    }

    return marker.getTo === marker.getFrom
        ? renderPointMarker()
        : renderIntervalMarker()
})
