import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PAGES } from '../../../static/enum'
import LoginPage from '../pages/login'
import NotFoundPage from '../pages/not-found'
import RootPage from '../pages/root'
import { SignUpPage } from '../pages/signup'


/**
 * Unauthorized user app layout
 */
function GuestLayout() {

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={PAGES.ROOT}
                    component={RootPage}
                />
                <Route
                    exact
                    path={PAGES.LOGIN}
                    component={LoginPage}
                />
                <Route
                    path={PAGES.SIGNUP}
                    component={SignUpPage}
                />
                <Route component={NotFoundPage}/>
            </Switch>
        </>
    )
}

export default GuestLayout
