import {
    ClickAwayListener,
    Divider,
    Grow,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
} from '@material-ui/core'
import { makeStyles, Theme, ThemeProvider } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import React, { MutableRefObject, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Logger from '../../logger'
import { lightTheme } from '../../static/theme'
import store from '../../store'
import Member from '../../store/Member'
import Organization from '../../store/Organization'
import { goLoginPage } from '../../utils/history.utils'
import AppLoading from '../AppLoading'


interface TProps {
    open: boolean
    member: Member
    organization: Organization
    onClose: () => void
    anchorRef: MutableRefObject<HTMLButtonElement | null>
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
    },
    popover: {
        zIndex: 1000,
        maxHeight: '80vh',
        overflowY: 'auto',
    },
    paper: {
        width: 230,
        marginTop: theme.spacing(2),
    },
}))

const logger = new Logger('MenuOrganizationsPopup')

const MenuOrganizationsPopup = (props: TProps) => {
    const { anchorRef, open } = props
    const { logout, switchOrganization } = store
    const [ t ] = useTranslation()
    const [ loading, setLoading ] = useState(false)
    const classes = useStyles()

    const handleClose = () => {
        props.onClose()
    }

    const handleLogout = async () => {
        try {
            await logout()
            goLoginPage()
        }
        catch (e) {
            logger.error(e)
        }
    }

    const handleSwitchOrganization = async (organization: Organization) => {
        if (organization.id !== props.member.getCurrentOrganization.id) {
            setLoading(true)
            handleClose()

            try {
                await switchOrganization(organization)
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    const renderOrganizations = () => {
        return props.member.getOrganizations
            .sort((organizationA, organizationB) => {
                return organizationA.name.localeCompare(organizationB.name)
            })
            .map(
                organization => (
                    <ListItem
                        onClick={() => handleSwitchOrganization(organization)}
                        key={organization.id}
                        button
                    >
                        <ListItemIcon>
                            {props.organization.id === organization.id && <Check color="primary"/>}
                        </ListItemIcon>
                        <ListItemText>
                            {organization.name}
                        </ListItemText>
                    </ListItem>
                ),
            )
    }

    const renderLogout = () => {
        return (
            <ListItem
                onClick={handleLogout}
                button
            >
                <ListItemIcon>
                    <PowerSettingsNew/>
                </ListItemIcon>
                <ListItemText>{t('Logout')}</ListItemText>
            </ListItem>
        )
    }

    return (
        <ThemeProvider theme={lightTheme}>
            {loading && <AppLoading/>}
            <div
                className={classes.root}
                data-e2e="menu-organizations-popup"
            >
                <Popper
                    className={classes.popover}
                    anchorEl={anchorRef.current}
                    disablePortal
                    open={open}
                    role={undefined}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow{...TransitionProps}>
                            <Paper className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <List>
                                        {renderOrganizations()}
                                        <Divider/>
                                        {renderLogout()}
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ThemeProvider>
    )
}

export default MenuOrganizationsPopup
