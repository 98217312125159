import { Typography } from '@material-ui/core'
import React from 'react'

import { EcommerceWorldMap } from '../../externalComponents/EcommerceWorldMap'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { AnalyticCard } from '../AnalyticCard'


interface Props {
    value: string
}

export const RealTimeViewsCard: React.FC<Props> = (props) => {
    const { value } = props

    return (
        <AnalyticCard >
            <Row rowProps={{ alignItems: 'center', justifyContent: 'flex-start' }}>
                <Column>
                    <Typography variant="subtitle1">Real time views</Typography>
                    <Typography variant="h4">{value}</Typography>
                </Column>
                <EcommerceWorldMap markerColor="primary" />
            </Row>
        </AnalyticCard>
    )
}
