import { AgeRange, Translation, User } from '@tivio/types'
import { useState } from 'react'


export type UserProfilesScreenName =
    | 'LIST'
    | 'LIST_EDIT_MODE'
    | 'DETAIL'
    | 'DELETE_CONFIRMATION'
    | 'CREATE_FORM'
    | 'EDIT_FORM'

export function getAgeRangeText(t: (key: string) => string, ageRange: AgeRange) {
    let ageRangeText = ageRange.kidsOnly
        ? `${t('Child')} `
        : ''

    ageRangeText += ageRange.from

    if (ageRange.to) {
        ageRangeText += ` - ${ageRange.to}`
    } else {
        ageRangeText += ' +'
    }

    return ageRangeText
}

interface UseCreateProfileFormOptions {
    user: User | null,
    onFinish: () => void,
    onError: () => void,
}

export const useCreateProfileForm = ({ user, onFinish, onError }: UseCreateProfileFormOptions) => {
    const [name, setName] = useState<string | null>(null)
    const [gender, setGender] = useState<Translation | null>(null)
    const [loading, setLoading] = useState(false)
    const [currentStep, setCurrentStep] = useState(1)

    const handleFormSubmit = async (ageRange: AgeRange) => {
        if (!user) {
            return
        }

        setLoading(true)

        await user.createUserProfile({
            name,
            survey: { gender, age: ageRange },
        }).catch((error) => {
            console.error(error)
            onError()
        })

        onFinish()
    }

    const handleNameSubmit = (name: string) => {
        if (!name) {
            return
        }

        setName(name)
        setCurrentStep(2)
    }

    const handleGenderSubmit = (gender: Translation) => {
        setGender(gender)
        setCurrentStep(3)
    }

    const handleBackButtonClick = () => {
        const newStep = currentStep - 1

        // Close the form when user clicked back all the way to the list
        if (newStep === 0) {
            onFinish()
            return
        }

        setCurrentStep(newStep)
    }

    return {
        loading,
        currentStep,
        onNameSubmit: handleNameSubmit,
        onGenderSubmit: handleGenderSubmit,
        onFormSubmit: handleFormSubmit,
        onBackButtonClick: handleBackButtonClick,
    }
}
