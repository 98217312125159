import { useCallback, useState } from 'react'


type DataState<T> = {
    data: T | null
    loading: boolean
    error: Error | null
}

const EMPTY_DATA_STATE = {
    data: null,
    loading: false,
    error: null,
}

export const useFetchDataState = <T>() => {
    const [ state, setState ] = useState<DataState<T>>(EMPTY_DATA_STATE)

    /**
     * Call on start of fetching data
     */
    const setStartFetching = useCallback(
        () => setState(prevState => ({ ...prevState, loading: true })),
        [ setState ],
    )
    /**
     * Call on error result
     */
    const setErrorResult = useCallback(
        (error: Error) => setState(prevState => ({ ...prevState, loading: false, error })),
        [ setState ],
    )

    /**
     * Call on error result
     */
    const setSuccessResult = useCallback(
        (data: T) => setState({ data, loading: false, error: null }),
        [ setState ],
    )


    return {
        data: state.data,
        loading: state.loading,
        error: state.error,
        setStartFetching,
        setErrorResult,
        setSuccessResult,
    }
}
