import store from '../../../store'


export const useMember = () => {
    const { getMember: member } = store

    return {
        member,
    }
}
