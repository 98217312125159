import { createLogger } from '../../../logger'

import { useOrganization } from './useOrganization'


const logger = createLogger('useSubscriptions')

export const useSubscriptions = () => {
    const { organization } = useOrganization()

    const addSubscription = async () => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        const subscriptionsCount = organization.subscriptions.length + 1
        await organization.adSubscription({
            title: `Subscription ${subscriptionsCount}`,
        })
    }

    const removeSubscription = async (subscriptionId: string) => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        await organization.removeMonetization(subscriptionId)
    }

    return {
        subscriptions: organization?.subscriptions ?? [],
        addSubscription,
        removeSubscription,
    }
}
