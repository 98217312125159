import { TivioConfig, WebConfig } from '@tivio/types'
import React, { createContext, PropsWithChildren, useMemo } from 'react'

import { AppConfig, AssetsConfig, CUSTOMER_APPS_CONFIG, SearchConfig } from '../../static'
import { useCustomer } from '../hooks'


type AppConfigValue = {
    config: AppConfig | null
    tivioConfig: TivioConfig | null
    assets: AssetsConfig | null
    search: SearchConfig | null
    webConfig: WebConfig | null
    setWebConfig: (config: WebConfig) => void
}

const ConfigContext = createContext<AppConfigValue>({
    config: null,
    tivioConfig: null,
    assets: null,
    search: null,
    webConfig: null,
    setWebConfig: () => {},
})

export type ConfigProviderProps = PropsWithChildren

/**
 * Using app config (eg. logos, theming) based on customer value in CustomerProvider
 * @param props
 * @constructor
 */
export const ConfigProvider: React.FC<ConfigProviderProps> = (props) => {
    const { customer, platform } = useCustomer()
    const [ webConfig, setWebConfig ] = React.useState<WebConfig | null>(null)
    const { config, tivioConfig, assets, search } = useMemo(
        () => {
            const customerConfig = CUSTOMER_APPS_CONFIG[customer]
            if (!customerConfig) {
                throw new Error(`No config for customer ${customer}`)
            }

            const appConfig = customerConfig.apps[platform]
            if (!appConfig) {
                throw new Error(`No app config for customer ${customerConfig} and platform ${platform}`)
            }

            return {
                config: {
                    ...appConfig,
                    // screens: screenConfigs,
                    allowProfileSubscription: appConfig.allowProfileSubscription ?? true,
                    allowCmp: appConfig.allowCmp ?? true,
                    allowRegistration: appConfig.allowRegistration ?? true,
                    allowDeleteProfile: appConfig.allowDeleteProfile ?? true,
                },
                tivioConfig: customerConfig.tivio,
                assets: customerConfig.assets,
                search: customerConfig.search ?? null,
            }
        },
        [ customer, platform/*, screenConfigs*/ ],
    )

    if (!config) {
        throw new Error(`Config for customer ${customer} does not exist.`)
    }

    return (
        <ConfigContext.Provider value={{ config, tivioConfig, assets, search, webConfig, setWebConfig }}>
            {props.children}
        </ConfigContext.Provider>
    )
}

export {
    ConfigContext,
}
