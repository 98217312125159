import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { type MarkerData, updateEpgMarker } from '../firebase/firestore/markerEpg'
import { updateVodMarker } from '../firebase/firestore/markerVod'
import { alertError } from '../utils/alert.utils'

import TvChannel from './TvChannel'
import Video from './Video'

import type { MARKER_TYPES } from '../static/enum'
import type firebase from 'firebase/app'


export interface IMarker {
    getType: MARKER_TYPES,
    getId: string,
    getFrom: number,
    getTo: number,
    from: number,
    to: number,
    getName?: string,
    setType: MARKER_TYPES,
}

class Marker implements IMarker {
    private readonly ref: firebase.firestore.DocumentReference<MarkerData>
    private firestoreData: MarkerData
    private tvChannel?: TvChannel
    private element?: Video

    constructor(
        ref: firebase.firestore.DocumentReference<MarkerData>,
        firestoreData: MarkerData,
        tvChannel?: TvChannel,
        sectionElement?: Video,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.tvChannel = tvChannel
        this.element = sectionElement

        makeAutoObservable(this)
    }

    updateMarker = async (markerData: Partial<MarkerData>) => {
        try {
            if (this.tvChannel) {
                await updateEpgMarker(this, markerData)
            }
            if (this.element) {
                await updateVodMarker(this, markerData)
            }

            if (markerData.type) {
                this.setType = markerData.type
            }

            if (markerData.from !== undefined) {
                this.setFrom = markerData.from
            }

            if (markerData.to) {
                this.setTo = markerData.to
            }

            if (markerData.name) {
                this.setName = markerData.name
            }
        }
        catch (e) {
            alertError(i18n.t('Failed to update marker'))
            console.error(e)
        }
    }

    updateChapter = (chapterData: MarkerData) => {
        try {
            console.log('[debug]', chapterData)
        }
        catch (e) {
            alertError(i18n.t('Failed to update chapter'))
            console.error(e)
        }
    }

    set setName(name: string) {
        this.firestoreData.name = name
    }

    set setType(type: MARKER_TYPES) {
        this.firestoreData.type = type
    }

    set setFrom(from: number) {
        this.firestoreData.from = from
    }

    set setTo(to: number) {
        this.firestoreData.to = to
    }

    get getType() {
        return this.firestoreData.type
    }

    get getId() {
        return this.ref.id
    }

    /**
     * Marker start time in seconds with three dicimal digits.
     * I.e. `123.456`.
     *
     * @deprecated Use `from` instead.
     */
    get getFrom() {
        return this.firestoreData.from
    }

    /**
     * Marker end time in seconds with three dicimal digits.
     * I.e. `123.456`.
     *
     * @deprecated Use `to` instead.
     */
    get getTo() {
        return this.firestoreData.to
    }

    get getName() {
        return this.firestoreData.name
    }

    get getTvChannel() {
        return this.tvChannel
    }

    get getSectionElement() {
        return this.element
    }

    /**
     * Marker start time in miliseconds.
     */
    get from() {
        return this.firestoreData.from * 1000 // to ms
    }

    /**
     * Marker end time in miliseconds.
     */
    get to() {
        return this.firestoreData.to * 1000 // to ms
    }

    /**
     * Marker end time in miliseconds.
     */
    set to(miliseconds: number) {
        this.firestoreData.to = miliseconds / 1000 // to s
    }
}

export default Marker
