import { CodeInvite, tivio } from '@tivio/core-js'
import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'


const useGenerateInviteCode = () => {
    const [inviteCode, setInviteCode] = useState<CodeInvite | null>(null)

    const generateCode = useCallback(async () => {
        const code = await tivio.generateCode()

        autorun(() => {
            setInviteCode(code ?? null)
        })
    }, [])

    const formatCode = useCallback((code?: string) => {
        if (code) {
            const codeMiddle = Math.ceil(code.length / 2)

            return `${code.substring(0, codeMiddle)}-${code.substring(codeMiddle, code.length)}`
        }
    }, [])

    useEffect(() => {
        generateCode()
    }, [])

    return {
        inviteCode,
        formatCode,
    }
}

export {
    useGenerateInviteCode,
}
