import { RowItemComponent } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo } from 'react'
import { AutoSizer, CellMeasurer, CellMeasurerCache, Grid as VirtualGrid } from 'react-virtualized'
import { GridCellRenderer, OverscanIndicesGetterParams, ScrollParams } from 'react-virtualized/dist/es/Grid'
import styled from 'styled-components'

import { Tag } from '../../store/Tag'
import { TivioRow } from '../../store/TivioRow'
import TvChannel from '../../store/TvChannel'
import Video from '../../store/Video'

import { CarouselTile, VIDEO_CAPTION_CONTAINER_HEIGHT } from './CarouselTile'


interface Props {
    items: (Video | Tag | TvChannel)[]
    row: TivioRow
    requestVideos?: () => void
    hasNextPage?: boolean
}

const TILES_IN_ROW_COUNT = 4
const TILE_RIGHT_PADDING = 16

const VideosCarouselContainer = styled.div`
    padding: .75rem 0;
`

// @ts-ignore
const VirtualGridStyled = styled(VirtualGrid)`
    outline: none;
    scroll-behavior: smooth;
    scrollbar-width: none;

    ::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }
`

const CarouselContainer = styled.div<{ height: number }>`
    height: ${props => props.height}px;
`

const Carousel: React.FC<Props> = observer((props) => {
    const { items, row } = props
    const videosCount = items.length
    const cacheRef = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100,
        }),
    )

    const {
        VIDEO_COVER_HEIGHT,
        VIDEO_COVER_WIDTH,
    } = useMemo(
        () => {
            console.log('[debug]', row.rowItemComponent)

            switch (row.rowItemComponent) {
                case RowItemComponent.ROW_ITEM_CIRCLED:
                    return {
                        VIDEO_COVER_HEIGHT: 152,
                        VIDEO_COVER_WIDTH: 152,
                    }
                case RowItemComponent.ROW_ITEM_PORTRAIT:
                    return {
                        VIDEO_COVER_HEIGHT: 437,
                        VIDEO_COVER_WIDTH: 270,
                    }
                default:
                    return {
                        VIDEO_COVER_HEIGHT: 152,
                        VIDEO_COVER_WIDTH: 270,
                    }
            }
        },
        [row.rowItemComponent, row.rowComponent],
    )

    const CELL_HEIGHT = VIDEO_COVER_HEIGHT + VIDEO_CAPTION_CONTAINER_HEIGHT
    const CELL_WIDTH = VIDEO_COVER_WIDTH + TILE_RIGHT_PADDING

    const handleScroll = useCallback(
        (params: ScrollParams) => {
            if (params.scrollLeft + params.clientWidth + 400 > params.scrollWidth) {
                props.requestVideos?.()
            }
        },
        [props.requestVideos],
    )

    const renderCell: GridCellRenderer = (cellProps) => {
        const video = items[cellProps.columnIndex]
        return (
            // @ts-ignore
            <CellMeasurer
                key={cellProps.key}
                cache={cacheRef.current}
                parent={cellProps.parent}
                columnIndex={cellProps.columnIndex}
                rowIndex={cellProps.rowIndex}
            >
                <div style={cellProps.style}>
                    <CarouselTile
                        rowItem={video}
                        coverHeight={VIDEO_COVER_HEIGHT}
                        coverWidth={VIDEO_COVER_WIDTH}
                        component={row.rowItemComponent}
                    />
                </div>
            </CellMeasurer>
        )
    }

    return (
        <VideosCarouselContainer>
            <CarouselContainer height={CELL_HEIGHT}>
                {/* @ts-ignore */}
                <AutoSizer>
                    {
                        ({ width }) => (
                            <VirtualGridStyled
                                cellRenderer={renderCell}
                                columnCount={videosCount}
                                columnWidth={CELL_WIDTH}
                                height={CELL_HEIGHT}
                                onScroll={handleScroll}
                                rowCount={1}
                                rowHeight={CELL_HEIGHT}
                                width={width}
                            />
                        )
                    }
                </AutoSizer>
            </CarouselContainer>
        </VideosCarouselContainer>
    )
})

export {
    Carousel,
    TILES_IN_ROW_COUNT,
}
