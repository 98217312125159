import { makeStyles } from '@material-ui/core/styles'
import React from 'react'

import AppLogo from '../AppLogo'


const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        ...theme.mixins.toolbar,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
    },
    logo: {
        height: 120,
        width: 160,
    },
}))

function MenuHeader() {
    const classes = useStyles()

    return (
        <div className={classes.drawerHeader}>
            <AppLogo className={classes.logo}/>
        </div>
    )
}

export default MenuHeader
