import { Button, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { Column } from '../../uiLayout/Column'

import type { GridSpacing } from '@material-ui/core/Grid'


interface Props<T> {
    /**
     * Array of items to display.
     */
    items: T[]
    /**
     * Function that returns empty template for new item.
     */
    itemTemplate: () => T
    /**
     * Callback when length of array has changed. Use this to update state.
     */
    onAddOrRemove: (items: T[]) => void
    /**
     * Render function for a single row.
     */
    render: (value: T, index: number) => React.ReactNode
    /**
     * Text for add button.
     */
    addButtonText?: string
    /**
     * Disables add button.
     */
    disableAddButton?: boolean
    /**
     * Row spacing. Default is 2.
     */
    rowSpacing?: GridSpacing
}

const useStyles = makeStyles((theme) => ({
    iconButton: {
        marginLeft: theme.spacing(1),
    },
}))

/**
 * Renders multiple rows of an item, button for adding new items and delete button for existing ones.
 * This component mutates the array of items and returns new array. It does NOT update the state, though.
 * You need to handle state update by yourself.
 * There is also a Formik variant of this component called MultiFormikField.
 */
// eslint-disable-next-line
const MultiRowField = <T extends unknown>({
    items,
    itemTemplate,
    onAddOrRemove,
    render,
    addButtonText = 'Add item',
    disableAddButton, rowSpacing,
}: Props<T>) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleAddNewItem = () => {
        const newItem = itemTemplate() as T

        const newItems: T[] = [...items, newItem]
        onAddOrRemove(newItems)
    }

    const handleRemoveItem = (index: number) => {
        const newItems: T[] = [...items]
        newItems.splice(index, 1)
        onAddOrRemove(newItems)
    }

    if (render === null) {
        return null
    }

    return (
        <Column spacing={rowSpacing ?? 2}>
            {items?.map((value: T, index: number) => (
                <React.Fragment key={index}>
                    {render(value, index)}
                    <IconButton onClick={() => handleRemoveItem(index)} size="small" className={classes.iconButton}>
                        <Delete />
                    </IconButton>
                </React.Fragment>
            ))}
            {!disableAddButton && (
                <Button
                    variant="text"
                    onClick={handleAddNewItem}
                    startIcon={<Add/>}
                    size="small"
                >
                    {t(addButtonText)}
                </Button>
            )}
        </Column>
    )
}

export default MultiRowField
