import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { SelectProps } from '@material-ui/core/Select/Select'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { MARKER_TYPES } from '../../static/enum'


interface Props extends SelectProps {
    options: MARKER_TYPES[]
}

const PlayerMarkerTypeSelect = (props: Props) => {
    const [ t ] = useTranslation()

    const renderMarkerTypes = () => {
        return props.options.map(
            marker => (
                <MenuItem
                    value={marker}
                    key={marker}
                >
                    {t(marker)}
                </MenuItem>
            ),
        )
    }

    const selectValue = useMemo(
        () => {
            return props.value === MARKER_TYPES.AD_SEGMENT ? MARKER_TYPES.AD : props.value
        },
        [ props.value ],
    )

    return (
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel>{t('Type')}</InputLabel>
            <Select
                label={t('Type')}
                name="type"
                {...props}
                value={selectValue}
            >
                {renderMarkerTypes()}
            </Select>
        </FormControl>
    )
}

export default PlayerMarkerTypeSelect
