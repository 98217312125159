import { TemplateDocument } from '@tivio/firebase'
import { makeAutoObservable } from 'mobx'

import type firebase from 'firebase/app'


export class Template {
    constructor(
        private _ref: firebase.firestore.DocumentReference<TemplateDocument>,
        private data: TemplateDocument,
    ) {
        makeAutoObservable(this)
    }

    get id() {
        return this._ref.id
    }

    get templateId() {
        return this.data.templateId
    }

    get name() {
        return this.data.name
    }

    get subject() {
        return this.data.subject
    }

    get preHeader() {
        return this.data.preHeader
    }

    get thumbnailUrl() {
        return this.data.thumbnailUrl
    }

    get substitutions() {
        return this.data.substitutions
    }
}
