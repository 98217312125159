import Organization from '../../store/Organization'
import { getFirestore, loggerFirestore } from '../app'

import type { MembershipDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export const getMemberships = (organization: Organization): firebase.firestore.CollectionReference<MembershipDocument> => {
    return getFirestore()
        .collection(`${organization.ref.path}/memberships`)
        .withConverter({
            fromFirestore: (snapshot) => {
                return snapshot.data() as MembershipDocument
            },
            toFirestore: (membership) => {
                return membership
            },
        })
}

export const addMembership = async (organization: Organization, membership: MembershipDocument) => {
    try {
        const docRef = await getMemberships(organization).add(membership)

        loggerFirestore.info('Membership created with ID: ', docRef.id)

        return docRef
    } catch (e) {
        loggerFirestore.error('Failed to add membership: ', e)
        throw new Error(e)
    }
}

export const getMembershipRef = (id: string) => {
    return getFirestore().collection('memberships').doc(id) as firebase.firestore.DocumentReference<MembershipDocument>
}
