export const initialLabels = [{
    id: 'column_device',
    type: 'columnLabel',
    data: {
        label: 'Device',
        type: 'column',
    },
    position: {
        x: 0,
        y: -50,
    },
}, {
    id: 'column_conversion',
    type: 'columnLabel',
    data: {
        label: 'Conversion',
        type: 'column',
    },
    position: {
        x: 250,
        y: -50,
    },
}, {
    id: 'column_target',
    type: 'columnLabel',
    data: {
        label: 'Target',
        type: 'column',
    },
    position: {
        x: 500,
        y: -50,
    },
}, {
    id: 'column_page',
    type: 'columnLabel',
    data: {
        label: 'Page',
        type: 'column',
    },
    position: {
        x: 750,
        y: -50,
    },
}]
