import { useObserver } from 'mobx-react'

import store from '../../../store'


export const useOrganization = () => {
    const organization = useObserver(() => {
        return store.getMember?.getCurrentOrganization
    })

    return { organization }
}
