import {
    Box,
    Button,
    Divider,
    Grid,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import PriorityHigh from '@material-ui/icons/PriorityHigh'
import { observer } from 'mobx-react'
import React, { FC, MouseEvent, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'
import DialogAddDevice from '../dialog/DialogAddDevice'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'

import VersionCell from './VersionCell'

import type Deploy from '../../store/Deploy'
import type Device from '../../store/Device'
import type Environment from '../../store/Environment'
import type Target from '../../store/Target'
import type Version from '../../store/Version'



const useStyles = makeStyles((theme) => ({
    deviceHeader: {
        fontSize: '1rem',
        color: COLORS.SECONDARY2,
        fontWeight: 400,
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&:hover': {
            color: COLORS.SECONDARY2_HOVER,
        },
    },
    targetHeader: {
        fontSize: '1.2rem',
        fontWeight: 400,
        textTransform: 'capitalize',
    },
    versionHeader: {
        color: COLORS.SECONDARY2,
        fontSize: '1rem',
    },
    dividerHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    dividerTarget: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    environmentHeader: {
        fontSize: '.98rem',
        textTransform: 'capitalize',
        alignSelf: 'flex-end',
    },
    divider: {
        height: 1,
    },
    addButton: {
        marginBottom: theme.spacing(3),
    },
    deviceWrapper: {
        flexWrap: 'nowrap',
        marginBottom: theme.spacing(5),
    },
}))

const ROW_HEIGHT = '2.8rem'

type Props = {
    target?: Target
    deploy: Deploy
    environments: Environment[]
    visibleEnvironmentsLabels?: boolean
    hasTopDivider: boolean
}

export const DeployTarget: FC<Props> = observer((props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const {
        target,
        deploy,
        environments,
        visibleEnvironmentsLabels,
        hasTopDivider,
    } = props

    // user permissions
    const isSuperAdmin = useIsSuperAdmin()

    // create device popup dialog
    const [
        addDeviceDialog,
        setAddDeviceDialog,
    ] = useState<{ opened: boolean, target: Target | null }>({
        opened: false,
        target: null,
    })

    // admin popup menu - device
    const [deviceMenuAnchorEl, setDeviceMenuAnchorEl] = React.useState<{ [key: number]: Element }>({ })
    const handleOpenDeviceMenu = (event: MouseEvent, idx: number) => {
        setDeviceMenuAnchorEl({ [idx]: event.currentTarget })
    }
    const handleCloseDeviceMenu = () => {
        setDeviceMenuAnchorEl({ })
    }

    // admin popup menu - bundle
    const [bundleMenuAnchorEl, setBundleMenuAnchorEl] = React.useState<{ [key: number]: Element }>({ })
    const handleOpenBundleMenu = (event: MouseEvent, idx: number) => {
        setBundleMenuAnchorEl({ [idx]: event.currentTarget })
    }
    const handleCloseBundleMenu = () => {
        setBundleMenuAnchorEl({ })
    }

    if (!target) {
        return (<>Loading...</>)
    }

    const handleAddDevice = () => {
        setAddDeviceDialog({ opened: true, target })
    }

    const handleRemoveRelease = (bundle: string) => {
        deploy.removeBundle(target, bundle)
    }

    const handleCloseAddDeviceDialog = () => {
        setAddDeviceDialog({ opened: false, target })
    }

    const handleRemoveDevice = (device: Device) => {
        target.removeDevice(device)
    }

    const renderBundles = (device: Device) => {
        const bundles = device.getBundles
        return bundles.map((bundle: string, idx: number) => (
            <Box
                key={bundle}
                height={ROW_HEIGHT}
            >
                <Grid
                    container
                    direction="row"
                >
                    <Menu
                        id="long-menu"
                        anchorEl={bundleMenuAnchorEl[idx]}
                        keepMounted
                        open={!!bundleMenuAnchorEl[idx]}
                        onClose={() => handleCloseBundleMenu()}
                    >
                        {
                            isSuperAdmin && (
                                <MenuItem
                                    key='remove'
                                    onClick={() => handleRemoveRelease(bundle)}
                                >
                                    <ListItemIcon>
                                        <PriorityHigh fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">

                                        {t('Remove release')}
                                    </Typography>
                                </MenuItem>
                            )
                        }
                    </Menu>
                    <Typography
                        className={classes.deviceHeader}
                        onClick={(event) => handleOpenBundleMenu(event, idx)}
                    >
                        { bundle }
                    </Typography>
                </Grid>
            </Box>
        ))
    }

    const renderVersions = (device: Device) => environments.map((environment) => {
        const versions: Version[] = device.versions.get(environment.id as string) ?? []
        return (
            <Grid
                item
                xs
                key={environment?.id}
            >
                { versions.map((version) => (
                    <Box
                        key={version.id}
                        height={ROW_HEIGHT}
                    >
                        <VersionCell
                            version={version}
                            onChangeVersion={version.updatePercentage}
                        />
                    </Box>
                )) }
            </Grid>
        )
    })

    const renderDevices = () => target.getDevices.map((device, idx) => (
        <Box key={idx}>
            <Grid
                container
                spacing={10}
                direction="row"
                key={idx}
            >
                <Grid
                    xs={3}
                    item
                >
                    <Grid
                        container
                        direction="row"
                    >
                        <Grid item xs={4}>
                            <Box
                                height={ROW_HEIGHT}
                            >
                                <Menu
                                    id="long-menu"
                                    anchorEl={deviceMenuAnchorEl[idx]}
                                    keepMounted
                                    open={!!deviceMenuAnchorEl[idx]}
                                    onClose={() => handleCloseDeviceMenu()}
                                >
                                    {
                                        isSuperAdmin && (
                                            <MenuItem
                                                key='remove'
                                                onClick={() => handleRemoveDevice(device)}
                                            >
                                                <ListItemIcon>
                                                    <PriorityHigh fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">

                                                    {t('Remove device')}
                                                </Typography>
                                            </MenuItem>
                                        )
                                    }
                                </Menu>
                                <Typography
                                    onClick={(event) => handleOpenDeviceMenu(event, idx)}
                                    className={classes.deviceHeader}
                                    title={device.description ?? t('No description')}
                                >
                                    { device.name }
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            { renderBundles(device) }
                        </Grid>
                    </Grid>
                </Grid>
                { renderVersions(device) }
            </Grid>
            <Divider className={classes.dividerTarget}/>
        </Box>
    ))

    const renderEnvironments = () => environments.map((environment) => (
        <Grid
            item
            xs
            key={environment?.name}
        >
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <Box
                        minWidth='6rem'
                        display='flex'
                        height={ROW_HEIGHT}
                    >
                        <Typography
                            className={classes.environmentHeader}
                            component="span"
                        >
                            { visibleEnvironmentsLabels ? environment.name ?? (<>&nbsp;</>) : (<>&nbsp;</>) }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    ))

    return (<>
        { hasTopDivider ? (<Divider className={classes.dividerHeader}/>) : '' }

        { addDeviceDialog.opened &&
          <DialogAddDevice
              hideActionsButtons={true}
              target={target}
              onConfirm={() => setAddDeviceDialog({ opened: false, target: null })}
              onCancel={() => handleCloseAddDeviceDialog()}
              open={addDeviceDialog.opened}
          />
        }

        <Grid
            container
            direction="row"
            spacing={10}
        >
            <Grid item xs={3}>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Typography
                            gutterBottom={true}
                            className={classes.targetHeader}
                        >
                            { target.name ?? t('no name') }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            { renderEnvironments() }
        </Grid>

        <Divider className={classes.dividerHeader}/>

        <Grid
            container
            direction="column"
            className={classes.deviceWrapper}
        >
            <Grid
                item
            >
                { renderDevices() }
            </Grid>
            <Grid item>
                <Button
                    className={classes.addButton}
                    startIcon={<Add/>}
                    component="span"
                    size="small"
                    onClick={() => handleAddDevice()}
                >
                    {t('device')}
                </Button>
            </Grid>
        </Grid>
    </>)
})

export default DeployTarget
