import { Chip, Container, Typography } from '@material-ui/core'
import DeleteCopy from '@material-ui/icons/Delete'
import { capitalizeText } from '@tivio/common'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { TivioNotification } from '../../store/Notification'
import { useOrganization } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { RowWrapper, Wrapper } from './NotificationList'
import { NotificationImage } from './shared/common'
import { NotificationStatusBadge } from './shared/NotificationStatusBadge'
import { Row } from '../uiLayout/Row'


export const IconsWrapper = styled.div`
    display: flex;
    gap: 1rem;

    & > * {
        cursor: pointer;
    }
`

interface Props {
    notification: TivioNotification
    removeNotification: (notification: TivioNotification) => Promise<void>
}

export const NotificationItem = ({ notification, removeNotification }: Props) => {

    const { push } = useHistory()
    const { organization } = useOrganization()
    const [t] = useTranslation()
    const { showSuccess, showError } = useAlert()
    const { confirmAction } = useConfirmAction()

    const handleNotificationClick = () => {
        push(`/services/${notification.id}`)
    }

    const handleRemoveNotification = async () => {
        if (!organization) {
            console.error('Organization is missing')
            showError(t('Organization is missing'))
            return
        }
        try {
            confirmAction(
                async () => {
                    try {
                        await removeNotification(notification)
                        showSuccess(t('Notification deleted'))
                    } catch (error) {
                        console.error('An error occurred:', error)
                        showError(t(error.message))
                    }
                },
                `${t('Are you sure you want to delete notification')}?`,
            )
        } catch (error) {
            console.error('An error occurred:', error)
            showError(t('An error occurred while deleting the notification'))
        }
    }

    return (
        <div key={notification.id}>
            <RowWrapper>
                <Wrapper onClick={handleNotificationClick}>
                    <NotificationImage src={notification.thumbnailUrl}/>
                    <Container>
                        <Typography variant="h6">
                            {notification.name}
                        </Typography>
                        <Row spacing={1}>
                            <Chip
                                size="small"
                                label={capitalizeText(notification.triggerType)}
                                color="primary"
                            />
                            <NotificationStatusBadge notification={notification} />
                        </Row>
                    </Container>
                </Wrapper>
                <IconsWrapper>
                    <DeleteCopy onClick={handleRemoveNotification}/>
                </IconsWrapper>
            </RowWrapper>
        </div>
    )
}
