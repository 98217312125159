import {
    Button,
    Grid,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import { Logger } from '@tivio/common'
import { TvChannelDocument } from '@tivio/firebase'
import { PublishedStatus, TvChannelType } from '@tivio/types'
import { useFormik } from 'formik'
import { capitalize } from 'lodash'
import diff from 'lodash/difference'
import ChipInput from 'material-ui-chip-input'
import { toJS } from 'mobx'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as yup from 'yup'

import { useAllTvChannelFilters } from '../../store/globals/tvChannels'
import TvChannel from '../../store/TvChannel'
import { enumToOptions } from '../../utils/enum.utils'
import { getInputProps } from '../../utils/formik.utils'
import { REGEX_SNAKE_CASE } from '../../utils/regex.utils'
import { AppSelect, appSelectConverter } from '../AppSelect'
import DialogBase from '../dialog/DialogBase'
import { useOrganization } from '../hooks'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'

import { ChannelAvailability } from './ChannelAvailability'

import type { TDialogProps } from '../dialog/DialogBase'


const StyledChipInput = styled(ChipInput)`
    margin: 9px;
`

const StyledTitleTypography = styled(Typography)`
    text-transform: uppercase;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    font-weight: bold;
`

const StyledButton = styled(Button)`
    margin-top: 30px;
    font-weight: bold;
`

export interface FormResult {
    // editing sources is now not supporting via admin
    data: Omit<TvChannelDocument, 'sources' | 'created' | 'updated'>
    isNew: boolean
}

interface Props extends TDialogProps<FormResult> {
    tvChannel?: TvChannel | null
}

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 840,
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
}))

const Description = styled.div`
    color: grey;
    font-style: italic;
    margin-top: 5px;
`

interface FormValues {
    type: TvChannelType
    name: string
    channelKey?: string
    isNew: boolean
    filters: string[]
    publishedStatus: PublishedStatus
}

const INITIAL_VALUES: FormValues = {
    type: TvChannelType.CLASSIC,
    name: '',
    channelKey: undefined,
    isNew: true,
    filters: [],
    publishedStatus: PublishedStatus.PUBLISHED,
}

const logger = new Logger('editTvChannel')

const getValues = (channel?: TvChannel | null): FormValues => {
    if (channel) {
        return {
            type: channel.type,
            name: channel.getName,
            isNew: false,
            filters: channel.filters,
            channelKey: channel.getChannelKey,
            publishedStatus: channel.getPublishedStatus,
        }
    }

    return INITIAL_VALUES
}

const useForbiddenChannelFilters = (allowedFilters: string[]) => {
    const allFilters = useAllTvChannelFilters()
    return diff(allFilters, allowedFilters)
}

const getFiltersInputProps = (form: any) => {
    const props = getInputProps(form, 'filters')
    delete props.value

    return {
        ...props,
        defaultValue: form.initialValues.filters,
        onChange: (chips: string[]) => form.setFieldValue('filters', chips),
    }
}

export const DialogEditTvChannel = (props: Props) => {
    const { tvChannel, open } = props
    const [t] = useTranslation()
    const classes = useStyles()
    const { organization } = useOrganization()
    const isSuperAdmin = useIsSuperAdmin()

    useEffect(() => {
        if (open) {
            if (tvChannel) {
                form.resetForm({ values: getValues(tvChannel) })

                logger.info('Editing TV channel', toJS(tvChannel))
            } else {
                form.resetForm()
            }
        }
    }, [open])

    const initialValues = getValues(tvChannel)
    const forbiddenFilterValues = useForbiddenChannelFilters(initialValues.filters)

    const statusOptions = enumToOptions(PublishedStatus).map((type) => ({
        value: type,
        optionLabel: t(capitalize(type)),
    }))

    const form = useFormik({
        initialValues,
        onSubmit: (values: FormValues) => {
            if (!organization) {
                throw new Error('Organization undefined, this should never happen. TV channel will not be saved.')
            }

            const result: FormResult = {
                data: {
                    type: values.type,
                    name: values.name,
                    channelKey: values.channelKey ?? '',
                    filters: values.filters,
                    organizationRef: organization.ref,
                    publishedStatus: values.publishedStatus,
                },
                isNew: values.isNew,
            }

            logger.info('Submitting tv channel', result)

            props.onConfirm?.(result)
        },
        validationSchema: yup.object().shape({
            name: yup.string()
                .required('Name required'),
            channelKey: yup.string(),
            filters: yup.array()
                .test(
                    'test_non_empty',
                    'Filters must not be empty.',
                    value => {
                        return value != null && value.length > 0
                    },
                )
                .test({
                    name: 'test_all_snake_case',
                    exclusive: true,
                    test: function (value) {
                        if (value == null) {
                            return true
                        }

                        const badFilters = value.filter(item => !REGEX_SNAKE_CASE.test(item))

                        if (badFilters.length > 0) {
                            return this.createError({
                                message: `(${badFilters.join(', ')}) Filters must contain only lower case letters,`
                                    + ' numbers or underscore.',
                                path: 'filters',
                            })
                        }

                        return true
                    },
                })
                .test({
                    name: 'test_uniqueness',
                    exclusive: true,
                    test: function (value) {
                        if (value == null) {
                            return true
                        }

                        const badFilters = value.filter(item => forbiddenFilterValues.includes(item))

                        if (badFilters.length > 0) {
                            return this.createError({
                                message: `(${badFilters.join(', ')}) Filters already exist.`,
                                path: 'filters',
                            })
                        }

                        return true
                    },
                }),
        }),
        validateOnBlur: false,
        validateOnChange: false,
    })

    return (
        <DialogBase
            onCancel={props.onCancel}
            open={props.open}
            showCloseIcon
            hideActionsButtons
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <form
                onSubmit={form.handleSubmit}
                data-e2e="edit-tv-channel"
            >
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <StyledTitleTypography
                            variant="body2"
                        >
                            {t('TV Channel')}
                        </StyledTitleTypography>
                    </Grid>
                    <Grid item>
                        <AppSelect
                            options={appSelectConverter(enumToOptions(TvChannelType))}
                            value={form.values.type}
                            onChange={e => form.setFieldValue('type', e.target.value)}
                            label={t('Type')}
                            // disable for edit
                            disabled={!form.values.isNew}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'name')}
                            label={t('Name')}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>

                    {isSuperAdmin && <>
                        <Grid item>
                            <TextField
                                {...getInputProps(form, 'channelKey')}
                                label={t('Channel Key')}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <ChannelAvailability channelKey={form.values.channelKey ?? null} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Description>
                                {t('Channel key can be found in Nangu.TV platform, it is used for loading EPG'
                                    + ' data and video stream from Nangu.TV platform used in TV channel marker editor.')}
                            </Description>
                        </Grid>
                    </>}
                    <Grid item>
                        <AppSelect
                            options={statusOptions}
                            value={form.values.publishedStatus}
                            onChange={e => form.setFieldValue('publishedStatus', e.target.value)}
                            required
                            label={t('Visibility')}
                        />
                    </Grid>
                    {isSuperAdmin && <>
                        <StyledChipInput
                            {...getFiltersInputProps(form)}
                            label={t('Filters')}
                            variant="outlined"
                        />
                    </>}
                    <Grid item>
                        <StyledButton
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            {t('Save')}
                        </StyledButton>
                    </Grid>
                </Grid>
            </form>
        </DialogBase>
    )
}
