import { QueryDocumentSnapshot, TagDocument } from '@tivio/firebase'
import { TagSimplicityType } from '@tivio/types'

import { createTag } from '../../creator/tags.creator'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, getFirestore, loggerFirestore } from '../app'

import type { FirestoreCollectionReference } from '../app'


const GLOBAL_TAG_PATH = 'tags/'

const getTagCollection = (organizationId: string): FirestoreCollectionReference<TagDocument> => {
    return getFirestore().collection(`organizations/${organizationId}/tags`).withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TagDocument => {
            return snapshot.data() as TagDocument
        },
        toFirestore: (organization: TagDocument): FirebaseDocumentData => {
            return organization
        },
    })
}


const addTag = async (organizationId: string, data: Omit<TagDocument, 'documentId'>) => {
    const docRef = getTagCollection(organizationId).doc()
    const tagData: TagDocument = { ...data, documentId: docRef.id, tagId: docRef.id }
    await docRef.set(tagData)

    loggerFirestore.info('Tag written with ID: ', docRef.id)

    const tag = createTag(docRef, tagData)
    return tag
}

const removeTag = async (organizationId: string, tagFirestoreId: string) => {
    const tagRef = getTagCollection(organizationId).doc(tagFirestoreId)

    await tagRef.delete()
    loggerFirestore.info(`Tag with id: ${tagFirestoreId} was deleted`)
}

const removeTagsOfType = async (organizationId: string, tagTypeId: string) => {
    const tagTypeRef = getFirestore().doc(`organizations/${organizationId}/tagTypes/${tagTypeId}`)
    const tagsToDelete = await getTagCollection(organizationId)
        .where('tagTypeRef', '==', tagTypeRef)
        .get()

    tagsToDelete.forEach(tag => removeTag(organizationId, tag.id))
}

const getAllTags = async (organizationId: string) => {
    const tagsSnapshot = await getTagCollection(organizationId).get()
    return tagsSnapshot.docs.map(
        tagSnapshot => createTag(tagSnapshot.ref, tagSnapshot.data()),
    )
}

const getTagById = async (organizationId: string, id: string) => {
    const tagSnapshot = await getFirestore().doc(`organizations/${organizationId}/tags/${id}`).withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TagDocument => {
            return snapshot.data() as TagDocument
        },
        toFirestore: (organization: TagDocument): FirebaseDocumentData => {
            return organization
        },
    }).get()

    if (!tagSnapshot.exists) {
        loggerFirestore.info('Tag not found!', id)
        return
    }

    // we know that data exist
    const tag = createTag(tagSnapshot.ref, tagSnapshot.data()!)
    return tag
}

const getTagsByTagId = async (organizationId: string, tagId: string) => {
    const tagsSnapshot = await getTagCollection(organizationId)
        .where('tagId', '==', tagId)
        .get()
    return tagsSnapshot.docs.map(
        tagSnapshot => createTag(tagSnapshot.ref, tagSnapshot.data()),
    )
}

const getNotComposedTags = async (organizationId: string) => {
    const tagsSnapshot = await getTagCollection(organizationId)
        .where('simplicity', '!=', TagSimplicityType.COMPOSED)
        .get()
    return tagsSnapshot.docs.map(
        tagSnapshot => createTag(tagSnapshot.ref, tagSnapshot.data()),
    )
}

export const getTagByPath = async (tagPath: string) => {
    const tagSnapshot = await getFirestore().doc(tagPath).withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): TagDocument => {
            return snapshot.data() as TagDocument
        },
        toFirestore: (organization: TagDocument): FirebaseDocumentData => {
            return organization
        },
    }).get()

    if (!tagSnapshot.exists) {
        loggerFirestore.info('Tag not found!', tagPath)
        return
    }

    // we know that data exist
    return createTag(tagSnapshot.ref, tagSnapshot.data()!)
}

export const getGlobalTagById = async (tagId: string) => {
    return getTagByPath(GLOBAL_TAG_PATH + tagId)
}

export const getGlobalTags = async () => {
    const globalTagsQuerySnapshots = (
        await getFirestore()
            .collection('tags')
            .get()
    ).docs as QueryDocumentSnapshot<TagDocument>[]

    return globalTagsQuerySnapshots.map(globalTagsQuerySnapshot => createTag(
        globalTagsQuerySnapshot.ref,
        globalTagsQuerySnapshot.data()!,
    ))
}

// Global access tags are stored within Starme organization
const globalTagsOrganizationId = process.env.REACT_APP_GLOBAL_ACCESS_TAGS_ORG_ID
export const accesssTagTypeRef = getFirestore().doc('/globalTagTypes/' + process.env.REACT_APP_ACCESS_TAG_TYPE_ID)
export const getGlobalAccessTags = async () => {
    const tagsSnapshot = await getTagCollection(globalTagsOrganizationId).where('tagTypeRef', '==', accesssTagTypeRef).get()
    return tagsSnapshot.docs.map(tagSnapshot => createTag(tagSnapshot.ref, tagSnapshot.data()))
}

export const indicationTagTypeRef = getFirestore().doc('/globalTagTypes/' + process.env.REACT_APP_INDICATION_TAG_TYPE_ID)

export {
    addTag,
    getAllTags,
    getNotComposedTags,
    getTagById,
    getTagCollection,
    getTagsByTagId,
    removeTag,
    removeTagsOfType,
}
