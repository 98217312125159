import { Container } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Video from '../../store/Video'
import { Timeout } from '../../utils/time.utils'
import { ContentDistributions } from '../content/ContentDistributions'
import { ContentTags } from '../content/ContentTags'
import { useVideoPermissions } from '../hooks/permissions/videoPermissions'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'

import { VideoAssets } from './VideoAssets'
import { VideoEditor } from './VideoEditor'
import { VideoLinks } from './VideoLinks'
import { VideoPublish } from './VideoPublish'
import { VideoSeries } from './VideoSeries'
import { VideoSources } from './VideoSources'
import { VideoSubtitles } from './VideoSubtitles'
import { VideoUrlNames } from './VideoUrlNames'


interface Props {
    video: Video
    onPlay?: (index: number) => void
    isOrganizationOwner?: boolean
}

export const VideoSettings: React.FC<Props> = observer((props) => {
    const { video, onPlay, isOrganizationOwner = true } = props
    const [t] = useTranslation()
    const { confirmAction } = useConfirmAction()
    const progress = video.getProgress
    const [hideProgress, setHideProgress] = useState(false)
    const timeoutRef = useRef<Timeout | null>()
    const { canEditVirtualProgram, isDisabled } = useVideoPermissions(video, isOrganizationOwner)

    const handleUploadRemove = async () => {
        confirmAction(async () => {
            if (video.isUploaded) {
                await video.uploadRemove()
            }
        }, t('This will remove uploaded video file only, you will still be able to edit this video. Are you sure you want to proceed?'))
    }

    useEffect(() => {
        if (progress === 100) {
            timeoutRef.current = setTimeout(() => {
                setHideProgress(true)
                video.setProgress(0)
            }, 1000)
        }

        return () => {
            timeoutRef.current && window.clearTimeout(timeoutRef.current)
        }
    }, [progress])

    return (
        <Container
            // key in root component (Container) is for force update after url force push
            key={`video-settings-${video.id}`}
            maxWidth="lg"
        >
            <VideoEditor
                video={video}
                progress={progress}
                hideProgress={hideProgress}
                onUploadRemove={handleUploadRemove}
                disabled={!canEditVirtualProgram && isDisabled}
            />
            {!isDisabled && <VideoUrlNames video={video} />}
            <ContentTags
                content={video}
                disabled={isDisabled}
            />
            <VideoSeries
                video={video}
            />
            {!isDisabled && <ContentDistributions content={video} />}
            <VideoSubtitles
                video={video}
                disabled={isDisabled}
            />
            <VideoPublish
                video={video}
                disabled={isDisabled}
            />
            <VideoLinks
                video={video}
                disabled={isDisabled}
            />
            <VideoAssets
                video={video}
                disabled={!canEditVirtualProgram && isDisabled}
            />
            <VideoSources
                sources={video.sources}
                onUpdate={video.updateSources}
                onPlay={onPlay}
                isUploaded={video.isUploaded}
                isTranscoding={video.isTranscoding}
                isUploading={progress > 0}
                isCut={video.isCut}
                disabled={isDisabled}
            />
        </Container>
    )
})
