import { notEmptyFilter } from '@tivio/common'
import { tivio } from '@tivio/core-js'
import { ALGOLIA_INDEX_NAME, VideoContentType } from '@tivio/types'


const isVideosIndex = (indexName: ALGOLIA_INDEX_NAME): boolean => {
    return [ALGOLIA_INDEX_NAME.VIDEOS, ALGOLIA_INDEX_NAME.VIDEOSDESC].includes(
        indexName,
    )
}

const getMonetizationAccessFilters = (
    indexName: ALGOLIA_INDEX_NAME,
): string | undefined => {
    if (!isVideosIndex(indexName)) {
        return undefined
    }

    const monetizationFilters = ['monetizationAccessIds:false']
    if (!tivio.user) {
        return monetizationFilters[0]
    }

    return tivio.user.purchases.reduce((acc, { monetizationRef }) => {
        if (!monetizationRef) {
            return acc
        }

        return [...acc, `monetizationAccessIds:${monetizationRef.id}`]
    }, [] as string[]).concat(...monetizationFilters).join(' OR ')
}

export const getFiltersByIndex = (indexName: ALGOLIA_INDEX_NAME): string => {
    const isStargaze = tivio.organization?.application?.organization?.isStargaze
    const monetizationAccessFilters = getMonetizationAccessFilters(indexName)
    return [
        monetizationAccessFilters,
        [// We only want to show items from current organization or organizations with Stargaze content
            isStargaze ? 'isTivioPro:true' : `organizationPath:${tivio.organization?.path}`,
            // We don't want to search through series episodes, we have tags for this
            ...(isVideosIndex(indexName)
                ? [
                    'isDraft:false',
                    'isDuplicate:false',
                    'hide:false',
                    `NOT contentType:${VideoContentType.SERIES}`,
                ]
                : []),
        ].join(' AND '),
    ].filter(notEmptyFilter).join(' AND ')
}