import { Chip, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { ContentInterface } from '../../types/content'
import { filterUtilityTags } from '../../utils/tag.utils'
import { AppThemeProvider } from '../AppThemeProvider'
import { Header } from '../common'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { Row } from '../uiLayout/Row'

import { ContentTagsDialog } from './ContentTagsDialog'


interface Props {
    content: ContentInterface
    disabled: boolean
}

export const ContentTags: React.FC<Props> = observer(({ content, disabled }) => {
    const [t] = useTranslation()
    const { openDialog, isDialogOpen, closeDialog } = useDialog()
    const filteredTags = content.tags.filter(filterUtilityTags)

    return (
        <>
            <ContentTagsDialog
                opened={isDialogOpen}
                onClose={closeDialog}
                onTagChange={content.updateTags}
                content={content}
            />
            <Header
                title={t('Tags')}
                actions={{
                    create: !disabled ? {
                        title: t('Add tags'),
                        onCreate: openDialog,
                    } : undefined,
                }}
            />

            {filteredTags.length === 0 && (<Typography color="textSecondary">{t('No tags')}</Typography>)}
            <AppThemeProvider type="light">
                <Row spacing={1}>
                    {
                        filteredTags.map(
                            tag => (
                                <Chip
                                    disabled={disabled}
                                    key={tag.id}
                                    label={tag.getNameTranslation.en}
                                    onDelete={() => content.updateTags(filteredTags.filter(selectedTag => selectedTag.id !== tag.id))}
                                />
                            ),
                        )
                    }
                </Row>
            </AppThemeProvider>
        </>
    )
})
