import { tivio, Video as VideoEntity } from '@tivio/core-js'
import { Disposer, Video } from '@tivio/types'
import { useEffect, useMemo, useRef, useState } from 'react'


type Options = Partial<{
    fetchTrailer: boolean
    fetchTags: boolean,
    fetchMonetization: boolean,
    optimizeFetchTags: boolean
}>

const DEFAULT_OPTIONS: Options = {
    fetchTrailer: false,
    fetchTags: true,
    fetchMonetization: true,
    optimizeFetchTags: false,
}

/**
 * Use video
 * @param videoIdOrUrlName video id
 * @param options video fetch options, note that options are not reactive
 * @param activeOrganizationId active organization id used for re-fetching video
 */
const useVideo = (videoIdOrUrlName?: string, options: Options = DEFAULT_OPTIONS, activeOrganizationId?: string | null) => {
    const memoizeOptions = useMemo(() => options, [])
    const [error, setError] = useState<string | null>(null)
    const [data, setData] = useState<Video | null>(null)
    const [videoEntity, setVideoEntity] = useState<VideoEntity | null>(null)
    const [trailer, setTrailer] = useState<Video | null>(null)
    const disposerRef = useRef<Disposer | undefined>(undefined)

    useEffect(() => {
        setTrailer(null)
    }, [videoIdOrUrlName])

    useEffect(() => {
        if (!memoizeOptions.fetchTrailer) {
            return
        }

        if (data && (!data?.linkedVideosRaw || data?.linkedVideos?.length !== data?.linkedVideosRaw?.length)) {
            data.loadLinkedVideos()
        }
    }, [data?.loadLinkedVideos, data?.linkedVideosRaw, memoizeOptions.fetchTrailer, data])

    useEffect(() => {
        if (!data || !memoizeOptions.fetchTrailer) {
            return
        }

        setTrailer(data.trailer)
    }, [data, trailer])

    useEffect(
        () => {
            if (!videoIdOrUrlName) {
                setError('Video ID is falsy')
                return
            }

            tivio.subscribeToVideo(
                videoIdOrUrlName,
                (error, data, disposer) => {
                    if (error) {
                        setError(error.message)
                    }

                    if (data) {
                        if (options.fetchTags) {
                            data.tags.forEach(tag => ({
                                tagId: tag.tagId,
                                name: tag.name,
                            }))
                        }

                        if (!options.optimizeFetchTags) {
                            data.tags.forEach(tag => ({
                                type: tag.type,
                                metadata: tag.metadata,
                                color: tag.color,
                            }))
                        }

                        if (options.fetchMonetization) {
                            data.monetizations
                        }

                        if (options.optimizeFetchTags && data.tags.some(tag => !tag.name)) {
                            return
                        }

                        // TODO TIV-1252 Discuss if we can use it as "setData(data)" (if this mapping is not needed as some Mobx hack)
                        // aka whether we should use `setVideoEntity` or `setData`, now we have both
                        setData(data)

                        setVideoEntity(data)
                        setError(null)
                    }

                    disposerRef.current = disposer
                },
            )

            return () => {
                disposerRef.current?.()
            }
        },
        [videoIdOrUrlName, activeOrganizationId, options.fetchTags, options.optimizeFetchTags, options.fetchMonetization],
    )

    return {
        error,
        trailer,
        data,
        videoEntity,
    }
}

function mapVideoToVideoInterface(data: VideoEntity): Video {
    return {
        id: data.id,
        cover: data.cover,
        application: data.application,
        assets: data.assets,
        created: data.created,
        sectionId: data.sectionId,
        channelId: data.channelId,
        duration: data.duration,
        path: data.path,
        tags: data.tags,
        image: data.image,
        itemType: data.itemType,
        isPlayable: data.isPlayable,
        isPaid: data.isPaid,
        transaction: data.transaction,
        subscriptions: data.subscriptions,
        monetizations: data.monetizations,
        isPlaylist: data.isPlaylist,
        name: data.name,
        urlNames: data.urlNames,
        urlName: data.urlName,
        description: data.description,
        descriptionRich: data.descriptionRich,
        price: data.price,
        detailedPrice: data.detailedPrice,
        url: data.url,
        detailBanner: data.detailBanner,
        banner: data.banner,
        linkedVideosRaw: data.linkedVideosRaw,
        linkedVideos: data.linkedVideos,
        contentType: data.contentType,
        year: data.year,
        purchasableMonetization: data.purchasableMonetization,
        getPurchasableMonetizations: data.getPurchasableMonetizations,
        sourceLanguages: data.sourceLanguages,
        reactions: data.reactions,
        // functions
        getTagsOfType: data.getTagsOfType,
        loadLinkedVideos: data.loadLinkedVideos,
        getLinkedVideos: data.getLinkedVideos,
        trailer: data.trailer,
        hasTasting: data.hasTasting,
        isTastingValid: data.isTastingValid.bind(data),
        tasting: data.tasting,
        teaser: data.teaser,
        watchPosition: data.watchPosition,
        isFavorite: data.isFavorite,
        addToFavorites: data.addToFavorites,
        removeFromFavorites: data.removeFromFavorites,
        availability: data.availability,
        episodeNumber: data.episodeNumber,
        adMonetizationId: data.adMonetizationId,
        urlByLanguage: data.urlByLanguage.bind(data),
        getSourceUrl: data.getSourceUrl,
        getSeekingMatrixPreviewByTime: data.getSeekingMatrixPreviewByTime.bind(data),
        getApplicationName: data.getApplicationName.bind(data),
        getApplicationLogo: data.getApplicationLogo.bind(data),
        setApplicationData: data.setApplicationData.bind(data),
        initApplication: data.initApplication.bind(data),
        updateReactionCount: data.updateReactionCount.bind(data),
        linkedVideosInNotLoaded: data.linkedVideosInNotLoaded,
        pipPaths: data.pipPaths,
        geoBlocking: data.geoBlocking,
        init: data.init.bind(data),
    }
}

export {
    useVideo,
}
