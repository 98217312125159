import { makeAutoObservable, runInAction } from 'mobx'

import { addDevice, type DeviceFirestore, removeDevice } from '../firebase/firestore/device'
import { addSecretRefs, removeSecretRef } from '../firebase/firestore/organization'
import { addSecret, removeSecret, type TSecretFirestore, updateSecret } from '../firebase/firestore/secrets'
import { addVersion, getVersions, removeVersion, TVersionFirestore } from '../firebase/firestore/versions'
import { getUniqueArray } from '../utils/array.utils'

import Device from './Device'
import Environment from './Environment'

import type { FirestoreQueryDocumentSnapshot } from '../firebase/app'
import type Deploy from './Deploy'
import type Version from './Version'
import type { SecretOrganizationSecretDocument } from '@tivio/firebase'


type SecretData = TSecretFirestore & {
    id: string
    device?: DeviceFirestore & { id: string }
    versions?: (TVersionFirestore & { id: string })[]
}

class Target {
    deploy: Deploy
    name: string | null = null
    secrets: SecretData[] = []
    devices: Device[] = []

    constructor (deploy: Deploy) {
        this.deploy = deploy
        makeAutoObservable(this)
    }

    async fromFirestore (name: string, secretsDocs: FirestoreQueryDocumentSnapshot<TSecretFirestore>[]) {
        this.name = name ?? null

        // convert to data
        const secrets: SecretData[] = await Promise.all(secretsDocs.map(async (secretDoc) => {
            const id = secretDoc.id
            const secretData = secretDoc.data()

            if (!secretData) {
                throw new Error(`Invalid ref: secret ${id}`)
            }

            let device: DeviceFirestore & { id: string } | undefined
            let versions: (TVersionFirestore & { id: string })[] = []
            if (secretData.deviceRef) {
                const deviceDoc = await secretData.deviceRef.get()
                const deviceData = deviceDoc.data()
                if (!deviceData) {
                    throw new Error(`Invalid secret ${id} ref: deviceRef: ${secretData.deviceRef.id}`)
                }
                device = Object.assign(deviceData, { id: deviceDoc.id })
            }
            if (secretData.versionsRefs) {
                versions = await Promise.all(secretData.versionsRefs.map(async (version) => {
                    const versionDoc = await version.get()
                    const versionData = versionDoc.data()
                    if (!versionData) {
                        throw new Error(`Secret ${secretDoc.id} without versionsRefs attribute`)
                    }
                    return Object.assign(versionData, { id: versionDoc.id })
                }))
            }
            return ({ id, device, versions, ...secretData })
        }))

        // group versions by device
        type SecretsGroupedByDevice = {
            secretGroupByDevice: Map<string, SecretData[]>
            devices: (DeviceFirestore & { id: string })[]
        }

        const { secretGroupByDevice, devices } = secrets.reduce<SecretsGroupedByDevice>((acc, secret: SecretData) => {
            const { secretGroupByDevice, devices } = acc
            if (!secret.device) {
                console.error(`Invalid data: secret ${secret.id} without deviceRef attribute`)
                return acc
            }
            const deviceId = secret.device.id
            if (secretGroupByDevice.has(deviceId)) {
                secretGroupByDevice.get(deviceId)!.push(secret)
            } else {
                secretGroupByDevice.set(deviceId, [secret])
            }

            devices.push(secret.device)
            return acc
        }, { secretGroupByDevice: new Map(), devices: [] })

        // create Devices
        this.devices = await Promise.all(devices.map(async (doc): Promise<Device> => {
            const device = new Device(this)
            const secrets = secretGroupByDevice.get(doc.id)
            await device.fromFirestore(doc.id, doc, secrets)
            return device
        }))

        this.secrets = secrets
    }

    get getDevices () {
        return getUniqueArray(this.devices, (device: Device) => device.id)
            .sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''))
    }

    hasBundle (bundle: string): boolean {
        const versionId = bundle.replaceAll('.', '_')
        return this.secrets.some((secret) => {
            return (secret.versions ?? []).some((ver) => ver.id === versionId)
        })
    }

    async addDevice (name: string, description: string) {
        // for all environments
        // const versionsToCopy =  this.devices[this.devices.length - 1]?.versions

        // create data structure
        const organizationId = this.deploy.organization.id
        const deviceRef = await addDevice(organizationId, { name, description })
        const secrets = await Promise.all(this.deploy.environments.map(async (env: Environment) => {
            if (env.id === null || this.name === null || env.ref === null) {
                throw new Error('Data inconsistency')
            }

            const environmentId = env.id
            const target = this.name

            // fixme: remove
            const versionsBlueprint: Version[] = [] // versionsToCopy?.get(env.id) ?? []
            const secretData: SecretOrganizationSecretDocument = {
                name: '',
                description: '',
                environmentRef: env.ref,
                versionsRefs: [],
                deviceRef,
                target,
            }
            const secretRef = await addSecret(organizationId, environmentId, secretData)
            const secretId = secretRef.id
            const versions = await Promise.all(versionsBlueprint.map(async (blueprint) => {
                const id = blueprint.id!
                const data = {
                    percentage: 0,
                    name: blueprint.name ?? '',
                    description: blueprint.description ?? '',
                }
                const ref = await addVersion(id, organizationId, secretId, data)
                return { ...data, id, ref }
            }))

            await updateSecret(organizationId, secretId, { versionsRefs: versions.map(({ ref }) => ref) })
            const result: TSecretFirestore & { id: string, versions?: (TVersionFirestore & { id: string })[] } = {
                ...secretData,
                id: secretId,
                versions: versions,
            }

            return result
        }))

        const secretIds = secrets.map(({ id }) => id)
        await addSecretRefs(organizationId, secretIds)

        await this.deploy.resume()
    }

    async removeDevice (device: Device) {
        runInAction(async () => {
            if (!device.id) {
                throw new Error('Data inconsistency')
            }

            // remove Device from database
            const organizationId = this.deploy.organization.id
            const secretIds = await Promise.all(this.secrets
                .filter((secret) => secret.device?.id === device.id)
                .map(async (secret) => {
                    // remove nested collection should be on backend side
                    const versions = await getVersions(organizationId, secret.id).get()
                    await Promise.all(versions.docs.map((versionDoc) => {
                        return removeVersion(versionDoc.id, organizationId, secret.id)
                    }))
                    await removeSecret(organizationId, secret.id)
                    return secret.id
                }))

            await removeSecretRef(organizationId, secretIds)
            await removeDevice(organizationId, device.id)

            // update stores
            this.secrets = this.secrets.filter((secret: SecretData) => {
                return secret.device?.id !== device.id
            })

            this.devices = this.devices.filter((item: Device) => {
                return item.id !== device.id
            })
        })
    }
}

export default Target
