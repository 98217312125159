import React, { FC, MouseEventHandler, useCallback, useRef } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    position: relative;
    width: 100%;
`

const LineBackground = styled.div`
    background: #FFF;
    opacity: 0.2;
    height: 8px;
    border-radius: 4px;
`

const DiscontinuousBackgroundContainer = styled.div`
    height: 8px;

    display: flex;
    flex-direction: row;
`

const DiscontinuousBackgroundItem = styled.div<{ width: number, isLast: boolean }>`
    width: ${p => p.width}%;
    margin-right: ${p => p.isLast ? '0' : '10px'};

    background: #FFF;
    opacity: 0.2;
    height: 8px;
    border-radius: 4px;
`

const DiscontinuousBackground: FC<{ duration: number, chapters: { toMs: number, fromMs: number }[] }> = ({
    duration,
    chapters,
}) => {
    let restOfWith = 100

    return (
        <DiscontinuousBackgroundContainer>
            {chapters.map((chapter, index) => {

                const width = Math.round((chapter.toMs - chapter.fromMs) / duration * 100)
                restOfWith -= width

                const isLast = (restOfWith === 0 && index === chapters.length - 1)

                return <DiscontinuousBackgroundItem
                    key={index}
                    width={width}
                    isLast={isLast}
                />
            })}
            {restOfWith > 0 && (
                <DiscontinuousBackgroundItem
                    width={restOfWith}
                    isLast={true}
                />
            )}
        </DiscontinuousBackgroundContainer>
    )
}

const PointerContainer = styled.div.attrs<{ percentage: number }>(({ percentage }) => ({
    style: {
        left: `calc(${percentage}% - 21px)`,
    },
}))<{ percentage: number }>`
    position: absolute;
    top: -17px;
`

const PointerOuter = styled.div`
    width: 42px;
    height: 42px;
    background: #0078FF;
    opacity: 0.4;
    border-radius: 50%;
`

const PointerInner = styled.div`
    width: 20px;
    height: 20px;
    background: #FFF;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 11px;
`

const Worm = styled.div.attrs<{ percentage: number, focus?: boolean }>(({ percentage, focus }) => ({
    style: {
        width: `${percentage}%`,
        background: (focus) ? '#0078FF' : '#FFF',
        opacity: (focus) ? 1 : 0.6,
    },
}))<{ percentage: number, focus?: boolean }>`
    position: absolute;
    background: blue;
    height: 8px;
    top: 0;
    border-radius: 4px;
`

interface Props {
    percentage: number
    focus?: boolean
    duration: number
    chapters: { toMs: number, fromMs: number }[]
    onProgressClick?: (position: number) => void // relative position (percents)
}

export const ProgressBarComponent: FC<Props> = ({ percentage, focus, chapters, duration, onProgressClick }) => {
    const progressBarContainerRef = useRef<HTMLDivElement | null>(null)

    const handleProgressClick: MouseEventHandler<HTMLDivElement> = useCallback(
        (e) => {
            if (progressBarContainerRef.current) {
                const mousePos = e.clientX

                const containerBounds = progressBarContainerRef.current.getBoundingClientRect()
                const mousePosOnContainer = mousePos - containerBounds.left

                onProgressClick?.((mousePosOnContainer / containerBounds.width) * 100)
            }
        },
        [],
    )

    return (
        <Container
            ref={progressBarContainerRef}
            onClick={handleProgressClick}
        >
            {chapters.length
                ? <DiscontinuousBackground
                    duration={duration}
                    chapters={chapters}
                />
                : <LineBackground/>
            }
            <Worm
                percentage={percentage}
                focus={focus}
            />
            {focus && (
                <PointerContainer percentage={percentage}>
                    <PointerOuter/>
                    <PointerInner/>
                </PointerContainer>
            )}
        </Container>
    )
}

export const ProgressBar = () => {

}
