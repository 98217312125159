import { makeAutoObservable } from 'mobx'

import type { TInviteFirestore } from '../firebase/firestore/invite'
import type { OrganizationMemberRoles } from '@tivio/firebase'
import type firebase from 'firebase/app'


class Invite {
    private ref: firebase.firestore.DocumentReference<TInviteFirestore>
    private firestoreData: TInviteFirestore

    constructor(
        ref: firebase.firestore.DocumentReference<TInviteFirestore>,
        firestoreData: TInviteFirestore,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData

        makeAutoObservable(this)
    }

    get getEmail() {
        return this.firestoreData.email
    }

    get getRole() {
        return this.firestoreData.role
    }

    get getId() {
        return this.ref.id
    }

    set setRole(role: OrganizationMemberRoles) {
        this.firestoreData.role = role
    }
}

export default Invite
