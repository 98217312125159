import { OrganizationMemberRoles } from '@tivio/firebase'
import { autorun } from 'mobx'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { Route, Switch } from 'react-router-dom'

import { getCurrentUser } from '../../../firebase/auth'
import Logger from '../../../logger'
import { PAGES } from '../../../static/enum'
import store from '../../../store'
import {
    goAnalyticsPage,
    goLoginPage,
    goTOSConsentPage,
    goUserNotFoundPage,
    isUserNotFoundPage,
} from '../../../utils/history.utils'
import AppLoading from '../../AppLoading'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { ConfirmDialog } from '../../ConfirmDialog'
import { ConfirmActionContextProvider } from '../../context/ConfirmActionContext'
import Menu from '../../menu/Menu'
import { EmailNotification } from '../../services/email/EmailNotification'
import AdProfileCreatePage from '../pages/ad-profile-create'
import AdProfileDetailPage from '../pages/ad-profile-detail'
import AdsPage from '../pages/ads'
import AnalyticsPage from '../pages/analytics'
import { ApplicationsPage } from '../pages/applications'
import { ArticlePage } from '../pages/article'
import { ArticlesPage } from '../pages/articles'
import DeployPage from '../pages/deploy'
import EpgChannelPage from '../pages/epg-channel'
import { FeedPage } from '../pages/feed'
import { MembershipsPage } from '../pages/memberships'
import { MultiUploadPage } from '../pages/multiupload'
import NotFoundPage from '../pages/not-found'
import { OrganizationSettingsPage } from '../pages/organization-settings'
import { PromotionsPage } from '../pages/promotions'
import { RoadmapPage } from '../pages/roadmap'
import RootPage from '../pages/root'
import { ScreenPage } from '../pages/screen'
import { ScreensPage } from '../pages/screens'
import SecretsPage from '../pages/secrets'
import ServicesPage from '../pages/services'
import { SettingsPage } from '../pages/settings'
import SubscriptionPage from '../pages/subscription'
import SubscriptionsPage from '../pages/subscriptions'
import { TOSConsentPage } from '../pages/tos-consent'
import TransactionPage from '../pages/transaction'
import TransactionsPage from '../pages/transactions'
import { UserPage } from '../pages/User'
import { UserEditPage } from '../pages/UserEdit'
import { UsersPage } from '../pages/Users'
import { VideoPage } from '../pages/video'
import { VideoEditPage } from '../pages/VideoEdit'
import { VideosPage } from '../pages/videos'
import WidgetPage from '../pages/widget'
import WidgetsPage from '../pages/widgets'


export interface UserLayoutParams {
    videoId: string
    sectionId: string
    channelId: string
    epgChannelId: string
    playlistId: string
    widgetId: string
    monetizationId: string
    adProfileId: string
    userId: string
    articleId: string
}

const logger = new Logger('UserLayout')

/**
 * Authorized user app layout
 */
const UserLayout = observer(() => {
    const [loading, setLoading] = useState(true)
    const [errorMessage, setErrorMessage] = useState(null)
    const [t] = useTranslation()
    const { getMember: member, logout, initMember } = store

    useEffect(
        () => {
            (async () => {
                const user = getCurrentUser()

                if (user.uid) {
                    try {
                        await initMember(user)

                        if (isUserNotFoundPage()) {
                            goAnalyticsPage()
                        }
                    } catch (e) {
                        goUserNotFoundPage()
                        setErrorMessage(e.message)
                        logger.error(e)
                    } finally {
                        setLoading(false)
                    }
                } else {
                    logger.warn('should have firebase user at this stage')
                }
            })()
        },
        [],
    )

    useEffect(() => {
        const currentOrganization = member?.getCurrentOrganization
        if (currentOrganization) {
            const disposer = autorun(() => {
                const requiresConsent = currentOrganization?.requiresConsent

                if (requiresConsent && window.location.pathname !== PAGES.TOS_CONSENT) {
                    goTOSConsentPage()
                }
            })

            return disposer
        }
    }, [member])

    if (loading) {
        return <AppLoading />
    }

    if (!member || errorMessage) {
        const handleLogout = async () => {
            try {
                await logout()
                goLoginPage()
            } catch (e) {
                logger.error(e)
            }
        }

        return (
            <AppNotFoundTemplate
                title={t(errorMessage)}
                button={{
                    text: t('Logout'),
                    action: handleLogout,
                }}
            />
        )
    }

    return (
        <ConfirmActionContextProvider>
            <ConfirmDialog />
            <Switch>
                <Menu>
                    <Switch>
                        <Route
                            component={RootPage}
                            path={PAGES.ROOT}
                            exact
                        />
                        <Route
                            component={AnalyticsPage}
                            path={PAGES.ANALYTICS}
                            exact
                        />
                        <Route
                            render={() => <EpgChannelPage organization={member.getCurrentOrganization} />}
                            path={PAGES.EPG_CHANNEL}
                        />
                        <Route
                            render={() => <WidgetsPage organization={member.getCurrentOrganization} />}
                            path={PAGES.WIDGETS}
                        />
                        <Route
                            render={() => <WidgetPage />}
                            path={PAGES.WIDGET}
                        />
                        <Route
                            render={() => <VideosPage />}
                            exact
                            path={PAGES.VIDEOS}
                        />
                        <Route
                            render={() => <AdsPage />}
                            path={PAGES.ADS}
                            exact
                        />
                        <Route
                            render={() => <AdProfileCreatePage />}
                            path={PAGES.AD_PROFILE_CREATE}
                        />
                        <Route
                            render={() => <AdProfileDetailPage />}
                            path={PAGES.AD_PROFILE_DETAIL}
                        />
                        <Route
                            component={MembershipsPage}
                            path="/monetization/memberships"
                        />
                        <Route
                            component={SubscriptionPage}
                            path={PAGES.SUBSCRIPTION}
                        />
                        <Route
                            component={ArticlesPage}
                            exact
                            path={PAGES.ARTICLES}
                        />
                        <Route
                            render={() => <ArticlePage />}
                            path={PAGES.ARTICLE}
                        />
                        <Route
                            render={() => <FeedPage />}
                            path={PAGES.FEED}
                        />
                        <Route
                            render={() => <VideoPage />}
                            path={PAGES.VIDEO}
                            exact
                        />
                        <Route
                            render={() => <VideoEditPage />}
                            path={PAGES.VIDEO_EDIT}
                            exact
                        />
                        <Route
                            component={ApplicationsPage}
                            exact
                            path={PAGES.APPLICATIONS}
                        />
                        <Route
                            component={ScreensPage}
                            exact
                            path={PAGES.SCREENS}
                        />
                        <Route
                            component={ScreenPage}
                            exact
                            path={PAGES.SCREEN}
                        />
                        <Route
                            render={() => <SubscriptionsPage />}
                            path={PAGES.SUBSCRIPTIONS}
                        />
                        <Route
                            render={() => <DeployPage organization={member.getCurrentOrganization} />}
                            path={PAGES.DEPLOY}
                            exact
                        />
                        <Route
                            component={TransactionPage}
                            path={PAGES.TRANSACTION}
                        />
                        <Route
                            render={() => <TransactionsPage />}
                            path={PAGES.TRANSACTIONS}
                        />
                        <Route
                            render={() => <PromotionsPage />}
                            path={PAGES.PROMOTIONS}
                        />
                        <Route
                            render={() => <SecretsPage organization={member.getCurrentOrganization} />}
                            path={PAGES.SECRETS}
                            exact
                        />
                        <Route
                            render={() => <UsersPage />}
                            path={PAGES.USERS}
                            exact
                        />
                        <Route
                            render={() => <UserPage />}
                            path={PAGES.USER}
                            exact
                        />
                        <Route
                            render={() => <UserEditPage />}
                            path={PAGES.USER_EDIT}
                            exact
                        />
                        <Route
                            path={PAGES.NOTIFICATION}
                            component={EmailNotification}
                        />
                        <Route
                            path={PAGES.SERVICES}
                            component={ServicesPage}
                        />
                        <Route
                            component={ServicesPage}
                            path={PAGES.SERVICES}
                            exact
                        />
                        <Route
                            component={TOSConsentPage}
                            path={PAGES.TOS_CONSENT}
                            exact
                        />
                        <Route
                            component={MultiUploadPage}
                            path={PAGES.MULTIUPLOAD}
                            exact
                        />
                        <Route
                            component={RoadmapPage}
                            path={PAGES.ROADMAP}
                            exact
                        />
                        {
                            member.getCurrentRole >= OrganizationMemberRoles.ADMIN && (
                                <>
                                    <Route
                                        component={SettingsPage}
                                        exact
                                        path={PAGES.SETTINGS}
                                    />
                                    <Route
                                        component={OrganizationSettingsPage}
                                        exact
                                        path={PAGES.ORGANIZATION_SETTINGS}
                                    />
                                </>
                            )
                        }

                        <Route component={NotFoundPage} />
                    </Switch>
                </Menu>
            </Switch>
        </ConfirmActionContextProvider>
    )
})

export default UserLayout
