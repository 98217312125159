import {
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Delete from '@material-ui/icons/Delete'
import Person from '@material-ui/icons/Person'
import { OrganizationMemberRoles } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'

import { COLORS } from '../../static/enum'
import store from '../../store'
import OrganizationAdmin from '../../store/OrganizationAdmin'
import { getRoles, translateRoleValueToString } from '../../utils/roles.utils'


interface TProps {
    organization: OrganizationAdmin
}

interface TListItem {
    id: string
    fName?: string,
    lName?: string,
    avatar?: string,
    delete: () => void,
    update: (role: OrganizationMemberRoles) => void,
    role: OrganizationMemberRoles
}

const useStyles = makeStyles((theme) => ({
    listItem: {
        padding: 0,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    listItemSecondaryAction: {
        right: -12,
    },
    avatar: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        marginRight: theme.spacing(2),
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    avatarIcon: {
        color: theme.palette.common.white,
        fontSize: theme.spacing(4),
        opacity: .25,
    },
    roleSelectorContainer: {
        position: 'absolute',
        right: 32,
        top: '50%',
        transform: 'translateY(-50%)',
    },
    listItemText: {
        width: 180,
        fontWeight: 'bold',
    },
}))

const OrganizationMembersList = observer((props: TProps) => {
    const { organization } = props
    const { getMember: member } = store
    const classes = useStyles()

    if (!member) {
        return null
    }

    const disableManipulateWithMember = member.getCurrentRole < OrganizationMemberRoles.ADMIN

    const renderListItem = (listItem: TListItem) => {
        const updateRole = (event: React.ChangeEvent<{ value: unknown }>) => {
            listItem.update(event.target.value as OrganizationMemberRoles)
        }

        return (
            <ListItem
                className={classes.listItem}
                key={listItem.id}
            >
                <Select
                    value={listItem.role}
                    className={classes.roleSelectorContainer}
                    disableUnderline
                    onChange={updateRole}
                    disabled={disableManipulateWithMember}
                >
                    {
                        getRoles(member).map(role => (
                            <MenuItem
                                value={role}
                                key={role}
                            >
                                {translateRoleValueToString(role)}
                            </MenuItem>
                        ))
                    }
                </Select>
                <ListItemAvatar>
                    <Avatar
                        className={classes.avatar}
                        src={listItem.avatar}
                    >
                        {!listItem.avatar && <Person className={classes.avatarIcon} />}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText>
                    <Typography
                        variant="body1"
                        component="div"
                        noWrap
                        className={classes.listItemText}
                    >
                        {`${listItem.fName || ''} ${listItem.lName || ''}`}
                    </Typography>
                </ListItemText>
                <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                    <IconButton
                        onClick={listItem.delete}
                        disabled={disableManipulateWithMember}
                    >
                        <Delete />
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    const list = useMemo(
        () => {
            let list: TListItem[] = []

            list = list
                .concat(organization.getMembers.map(member => ({
                    id: member.getId,
                    avatar: member.getAvatar,
                    delete: () => organization.deleteMember(member),
                    fName: member.getFName,
                    lName: member.getLName,
                    role: member.getCurrentRole,
                    update: (role: OrganizationMemberRoles) =>
                        organization.updateMemberRole(member, role),
                })))
                // TODO: Needs to decide how invites will be displayed separately from list of members
                // .concat(organization.getInvites.map(invite => ({
                //     id: invite.getId,
                //     delete: () => organization.deleteInvite(invite),
                //     fName: invite.getEmail,
                //     role: invite.getRole,
                //     update: (role: OrganizationMemberRoles) => organization.updateInviteRole(invite, role),
                // })))

            return list.filter(memberItem =>
                member.getCurrentRole === OrganizationMemberRoles.SUPER_ADMIN ? true : memberItem.role < OrganizationMemberRoles.SUPER_ADMIN,
            )
        },
        [
            organization.getMembers,
            organization.getInvites,
        ],
    )

    return (
        <List>
            {list.map(renderListItem)}
        </List>
    )
})

export default OrganizationMembersList
