import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Deploy from '../../store/Deploy'
import Target from '../../store/Target'
import { isValidSemVer, toSemVer } from '../../utils/semanticVersion.utils'

import DialogBase from './DialogBase'

import type { TDialogProps } from './DialogBase'


interface TProps extends TDialogProps<void> {
    deploy: Deploy | null
    targets: Target[] | null
}

type TStyleProps = {
    size: 'small' | 'large'
}

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    paper: {
        width: 560,
    },
    tabs: {
        marginTop: '-2rem',
        marginBottom: '2rem',
        boxShadow: '0px -1px 0px #d3d3d3 inset',
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
    addButton: {
        marginTop: theme.spacing(8),
    },
    formControl: {
        minWidth: 120,
        width: '100%',
    },
}))

const DialogAddRelease = observer((props: TProps) => {
    const { onConfirm, deploy, targets } = props

    if (!deploy || typeof targets?.[0]?.name !== 'string') {
        return null
    }

    const classes = useStyles({ size: 'small' })
    const [ t ] = useTranslation()

    const [ bundle, setBundle ] = useState<string>('')
    const [ targetName, setTargetName ] = useState<string>(targets[0].name)
    const [ valid, setValid ] = useState(true)

    const handleAdd = async () => {
        await deploy.addRelease(targetName, bundle)
        onConfirm?.()
    }

    useEffect(() => {
        const target = targets.find((i) => i.name === targetName)
        const alreadyExists = target?.hasBundle(bundle)
        const isValid = bundle.length > 0
            && isValidSemVer(toSemVer(bundle))
            && !alreadyExists

        if (isValid !== valid) {
            setValid(isValid)
        }
    }, [bundle])

    return (
        <DialogBase
            onCancel={props.onCancel}
            onConfirm={props.onConfirm}
            open={props.open}
            showCloseIcon={true}
            hideActionsButtons={true}
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <TextField
                        label={t('Version')}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setBundle(e.target.value)}
                        value={bundle}
                    />
                </Grid>
                <Grid item>
                    <FormControl
                        variant="outlined"
                        className={classes.formControl}
                    >
                        <InputLabel id="add-release-target-label">
                            {t('Target')}
                        </InputLabel>
                        <Select
                            value={targetName}
                            labelId="add-release-target-label"
                            onChange={(e) => setTargetName(e.target.value as unknown as string)}
                            label={t('Target')}
                        >
                            {
                                targets.map(({ name }, idx) => (
                                    <MenuItem
                                        value={name!}
                                        key={idx}
                                    >
                                        { name }
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.addButton}
                        color="primary"
                        variant="contained"
                        disabled={!valid}
                        fullWidth
                        onClick={handleAdd}
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </DialogBase>
    )
})

export default DialogAddRelease
