import { once } from '@tivio/common'
import muxjs from 'mux.js'
import { polyfill } from 'shaka-player'


export const shakaEnableHls = () => {
    // Add support for most HLS content to Shaka player. This is the official way
    // see https://github.com/google/shaka-player/issues/2266

    // @ts-ignore
    window.muxjs = muxjs

    if (process.env.STORYBOOK_ON) {
        // @ts-ignore
        window.parent.window.muxjs = muxjs
    }
}

export const shakaInstallPolyfillsOnce = once(() => {
    polyfill.installAll()
})
