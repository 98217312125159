import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory, useLocation } from 'react-router-dom'

import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { GeneralTab } from '../../organization-settings/GeneralTab'
import { IntegrationsTab } from '../../organization-settings/IntegrationsTab/IntegrationsTab'
import { LocalizationTab } from '../../organization-settings/LocalizationTab'
import { PaymentInfoTab } from '../../organization-settings/PaymentInfoTab'


interface TabConfig {
    component: JSX.Element
    title: string
    key: string
}

export const OrganizationSettingsPage: React.FC = observer(() => {
    const { organization } = useOrganization()
    const [t] = useTranslation()
    const location = useLocation()
    const history = useHistory()

    const searchParams = new URLSearchParams(location.search)
    const currentTab = searchParams.get('tab')

    const sectionTabs: TabConfig[] = useMemo(
        () => [
            {
                component: <GeneralTab />,
                title: t('General'),
                key: 'general',
            },
            {
                component: <PaymentInfoTab />,
                title: t('Payment'),
                key: 'payment',
            },
            {
                component: <LocalizationTab />,
                title: t('Localization'),
                key: 'localization',
            },
            {
                component: <IntegrationsTab />,
                title: t('Integrations'),
                key: 'integrations',
            },
        ],
        [t],
    )


    const findTabIndex = (tabKey: string | null): number => {
        return sectionTabs.findIndex(tab => tab.key === tabKey)
    }

    const defaultTabIndex: number = findTabIndex(currentTab) !== -1 ? findTabIndex(currentTab) : 0

    const handleTabChange = (selectedIndex: number): void => {
        const tabKey = sectionTabs[selectedIndex]?.key
        history.push(`${location.pathname}?tab=${tabKey}`)
    }

    return (
        <>
            <DefaultPageHeader title={organization?.name ?? ''} />
            <DefaultPageTabs
                defaultTabIndex={defaultTabIndex}
                tabs={sectionTabs}
                onTabChange={handleTabChange}
            />
        </>
    )
})
