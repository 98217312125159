import { Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { MonetizationPlacementField } from '@tivio/firebase'
import React, { FC } from 'react'

import Monetization from '../../store/Monetization'
import { Text } from '../common'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { MonetizationPlacementsList } from '../monetization/MonetizationPlacementsList'
import MonetizationVariantChipsArray from '../monetization/MonetizationVariantChipsArray'
import UpdatableTypography from '../UpdatableTypography'

import { CopiableContent } from './CopiableContent'
import { PlacementChangeHandler, PlacementDialog } from './PlacementDialog'


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: '1rem',
    },
    id: {
        opacity: 0.8,
        display: 'inline',
        marginLeft: 20,
        marginRight: 20,
        cursor: 'pointer',
    },
}))

interface StrategyHeaderProps {
    id: string
    title: string
    collapse: boolean
    monetization: Monetization
    placements?: MonetizationPlacementField
    toggleCollapse: (collapse: boolean) => void
    handleDeleteStrategy: () => void
    handleEditStrategyTitle: (title: string) => void
    handleSubmitPlacement: PlacementChangeHandler
    selectedVariantId: string
    onVariantSelect: (variant: string) => void
}

export const StrategyHeader: FC<StrategyHeaderProps> = ({
    id,
    title,
    collapse,
    placements,
    toggleCollapse,
    handleDeleteStrategy,
    handleEditStrategyTitle,
    monetization,
    selectedVariantId,
    onVariantSelect,
    handleSubmitPlacement,
}) => {
    const classes = useStyles()
    const { isDialogOpen, closeDialog, openDialog } = useDialog()

    const isSuperAdmin = useIsSuperAdmin()

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="baseline"
            >
                <Grid item>
                    <UpdatableTypography
                        onTextChange={handleEditStrategyTitle}
                        typographyProps={{
                            variant: 'h6',
                        }}
                        editOnClick
                    >{title}</UpdatableTypography>
                    {isSuperAdmin && <CopiableContent as={Text} text={id} className={classes.id}/>}
                    <MonetizationVariantChipsArray
                        monetizationId={id}
                        variants={monetization?.variants ?? []}
                        selectedVariantId={selectedVariantId}
                        onVariantSelect={onVariantSelect}
                    />
                    <MonetizationPlacementsList monetization={monetization}/>
                </Grid>
                <Grid item>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item>
                            <IconButton size="small" onClick={handleDeleteStrategy}>
                                <Delete className={classes.icon}/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => openDialog()}>
                                <Create className={classes.icon}/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={() => toggleCollapse(!collapse)}>
                                {collapse ? <ExpandLess className={classes.icon}/> : <ExpandMore className={classes.icon}/>}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <PlacementDialog
                monetization={monetization}
                placements={placements}
                isOpen={isDialogOpen}
                onClose={closeDialog}
                onSubmit={handleSubmitPlacement}
            />
        </>
    )
}
