import { IconButton } from '@material-ui/core'
import { lighten, makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import MenuIcon from '@material-ui/icons/Menu'
import { ALGOLIA_INDEX_NAME, BannerItemComponent, RowFilterCollection, RowItemComponent } from '@tivio/types'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'
import { Article } from '../../store/Article'
import { Tag } from '../../store/Tag'
import { TivioRow } from '../../store/TivioRow'
import Video from '../../store/Video'
import { onDragEndUtil } from '../../utils/order.utils'
import { rowItemTypeToAssetType } from '../../utils/row.utils'
import { ArticlePickDialog } from '../common/ArticlePickDialog'
import { ItemSearchDialog } from '../common/ItemSearchDialog'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { useHorizontalScroll } from '../hooks/uiHooks/useHorizontalScroll'

import type { DraggableProvided, DropResult } from 'react-beautiful-dnd'


interface DraggableCarouselProps {
    items: (Video | Tag | Article)[]
    row: TivioRow
    onItemsChange: (items: (Video | Tag | Article)[]) => void
    onItemAdd: (itemPath: string) => void
    onItemRemove: (itemPath: string) => void
}

type DraggableItemProps = {
    index: number
    item: Video | Tag | Article
    onRemove: (itemPath: string) => void
    component: RowItemComponent | BannerItemComponent
}

type ItemProps = Omit<DraggableItemProps, 'index'> & {
    provided: DraggableProvided
}

type ItemNewProps = {
    component: RowItemComponent | BannerItemComponent
    onClick: () => void
    isTag: boolean
}


// Padding between two items in row
const ITEM_PADDING = 8
// Width of portrait and landscape items
const RECTANGLE_WIDTH = 270
// Width of circled items
const CIRCLE_WIDTH = 152

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        position: 'relative',
    },
    scrollContainer: {
        overflowX: 'auto',
        overflowY: 'hidden',
        marginLeft: '-100px',
        marginRight: '-100px',
        paddingLeft: '100px',
        paddingRight: '100px',
        '-ms-overflow-style': 'none',  /* IE and Edge */
        'scrollbar-width': 'none',  /* Firefox */

        '&::-webkit-scrollbar': {
            display: 'none',  /* Safari and Chrome */
        },

    },
    arrow: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        width: '100px',
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
    },
    // TODO: arrows should be clickable to scroll the row horizontally
    arrowLeft: {
        left: '-100px',
        background: 'linear-gradient(90deg, rgba(0,9,36,1) 0%, rgba(0,9,36,1) 25%, rgba(0,9,36,0) 100%)',
        justifyContent: 'right',
        '&:hover': {
            // TODO: will work in next iteration
            //background: 'linear-gradient(90deg, rgba(0,9,36,1) 0%, rgba(0,9,36,1) 25%, rgba(0,9,36,0.6) 100%)',
        },
    },
    arrowRight: {
        right: '-100px',
        background: 'linear-gradient(270deg, rgba(0,9,36,1) 0%, rgba(0,9,36,1) 25%, rgba(0,9,36,0) 100%)',
        justifyContent: 'left',
        '&:hover': {
            //background: 'linear-gradient(270deg, rgba(0,9,36,1) 0%, rgba(0,9,36,1) 25%, rgba(0,9,36,0.6) 100%)',
        },

    },
    rowContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: `-${ITEM_PADDING}px`,
        marginRight: `-${ITEM_PADDING}px`,
        //gap: `${ITEM_PADDING * 2}px`, // Currently unsupported by react-beautiful-dnd
    },
    item: {
        marginLeft: `${ITEM_PADDING}px`,
        marginRight: `${ITEM_PADDING}px`,
    },
    itemImg: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
        '&:hover $itemControls': {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0ms, opacity 200ms',
        },
    },
    itemImgPortrait: {
        width: RECTANGLE_WIDTH,
        height: 437,
        borderRadius: '10px',
        '& + $itemName': {
            maxWidth: RECTANGLE_WIDTH,
        },
    },
    itemImgLandscape: {
        width: RECTANGLE_WIDTH,
        height: CIRCLE_WIDTH,
        borderRadius: '10px',
        '& + $itemName': {
            maxWidth: RECTANGLE_WIDTH,
        },
    },
    itemImgCircled: {
        width: CIRCLE_WIDTH,
        height: CIRCLE_WIDTH,
        borderRadius: '50%',
        '& + $itemName': {
            maxWidth: CIRCLE_WIDTH,
        },
        '& $itemControls': {
            justifyContent: 'center',
            gap: theme.spacing(1),
            paddingTop: theme.spacing(2),
        },
    },
    itemControls: {
        display: 'flex',
        justifyContent: 'space-between',
        visibility: 'hidden',
        background: 'linear-gradient(180deg, rgba(0,0,0,0.5) 23%, rgba(0,0,0,0) 100%)',
        transition: 'visibility 0ms 200ms, opacity 200ms 0ms',
        opacity: 0,
        borderRadius: '10px',
        padding: theme.spacing(1),
    },
    itemImgNew: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'background-color .1s ease-in-out',

        '&:hover': {
            backgroundColor: lighten(COLORS.DRAWER_BACKGROUND, 0.07),
        },
    },
    itemName: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
}))

const DraggableItem: React.FC<DraggableItemProps> = (props) => {
    const { index, item, onRemove, component } = props

    return (
        <Draggable
            key={item.id}
            draggableId={item.id}
            index={index}
        >
            {(provided) => (
                <Item
                    item={item}
                    provided={provided}
                    onRemove={onRemove}
                    component={component}
                />
            )}
        </Draggable>
    )
}

const itemComponentToClassName = (itemComponent: RowItemComponent | BannerItemComponent) => {
    switch (itemComponent) {
        case RowItemComponent.ROW_ITEM_CIRCLED:
            return 'itemImgCircled'
        case RowItemComponent.ROW_ITEM_LANDSCAPE:
            return 'itemImgLandscape'
        case RowItemComponent.ROW_ITEM_PORTRAIT:
            return 'itemImgPortrait'
        default:
            return 'itemImgLandscape'
    }
}

const Item: React.FC<ItemProps> = (props) => {
    const { item, provided, component } = props
    const classes = useStyles()
    const [t] = useTranslation()

    const handleRemove = () => {
        props.onRemove(item.getRef.path)
    }

    // @ts-expect-error TODO
    const backgroundImage = item[rowItemTypeToAssetType(component)]

    return (
        <div
            className={classes.item}
            ref={provided.innerRef}
            {...provided.draggableProps}
        >
            <div
                className={clsx(
                    classes.itemImg,
                    classes[itemComponentToClassName(component)],
                )}
                style={{
                    position: 'relative',
                    backgroundImage: `url("${backgroundImage}")`,
                }}
            >
                <div className={classes.itemControls}>
                    <IconButton
                        {...provided.dragHandleProps}
                        title={t('Move item')}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        title={t('Remove item from row')}
                        onClick={handleRemove}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </div>
            <div
                className={classes.itemName}
                title={item.getName}
            >{item.getName}</div>
        </div>
    )
}

const ItemNew: React.FC<ItemNewProps> = (props) => {
    const { component, onClick, isTag } = props
    const classes = useStyles()
    const [t] = useTranslation()

    return (
        <div
            className={classes.item}
            onClick={onClick}
        >
            <div
                className={clsx(
                    classes.itemImg,
                    classes.itemImgNew,
                    classes[itemComponentToClassName(component)],
                )}
                title={isTag ? t('Add tag') : t('Add video')}
            >
                <AddIcon fontSize="large" />
            </div>
        </div>
    )
}


const DraggableCarousel: React.FC<DraggableCarouselProps> = observer((props) => {
    const { items, row, onItemsChange, onItemRemove, onItemAdd } = props
    const classes = useStyles()
    const { closeDialog, isDialogOpen, openDialog } = useDialog()
    const scrollRef = useHorizontalScroll<HTMLDivElement>()
    const [t] = useTranslation()
    const isTag = row.rowItemType === RowFilterCollection.TAGS
    const isContents = row.rowItemType === RowFilterCollection.CONTENTS
    const searchIndex = isTag ? ALGOLIA_INDEX_NAME.TAGS : ALGOLIA_INDEX_NAME.VIDEOS

    const itemWidth = row.rowItemComponent === RowItemComponent.ROW_ITEM_CIRCLED ? CIRCLE_WIDTH : RECTANGLE_WIDTH
    const rowContainerWidth = (itemWidth * (items.length + 1)) + (16 * (items.length + 1))

    const onDragEnd = (result: DropResult) => {
        const newItems = onDragEndUtil<Video | Tag | Article>(result, items)

        if (newItems) {
            onItemsChange?.(newItems)
        }
    }

    const dialogSharedProps = {
        open: isDialogOpen,
        onClose: closeDialog,
        onAdd: onItemAdd,
    }

    return (
        <>
            {isDialogOpen && (
                isContents ?
                    <ArticlePickDialog
                        {...dialogSharedProps}
                        filterIds={items.map((item) => item.getRef.id)}
                    />
                    : <ItemSearchDialog
                        open={isDialogOpen}
                        onClose={closeDialog}
                        onAdd={onItemAdd}
                        title={isTag ? t('Add tag to row') : t('Add video to row')}
                        buttonText={t('Add')}
                        searchIndex={searchIndex}
                    />
            )}
            <DragDropContext onDragEnd={onDragEnd}>
                <div className={classes.mainContainer}>
                    <div className={clsx(classes.arrow, classes.arrowLeft)}>
                        {/* <ChevronLeftIcon fontSize="large" /> TODO: will work in next iteration :) */}
                    </div>
                    <div
                        className={classes.scrollContainer}
                        ref={scrollRef}
                    >
                        <Droppable
                            droppableId="droppable"
                            direction="horizontal"
                        >
                            {(provided) => (
                                <div
                                    className={classes.rowContainer}
                                    style={{ width: `${rowContainerWidth}px` }}
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                >
                                    <ItemNew
                                        component={row.rowItemComponent}
                                        onClick={openDialog}
                                        isTag={isTag}
                                    />
                                    {items.map((item, index) => item && (
                                        <DraggableItem
                                            key={item.id}
                                            index={index}
                                            item={item as (Video | Tag | Article)}
                                            onRemove={onItemRemove}
                                            component={row.rowItemComponent}
                                        />
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </div>
                    <div className={clsx(classes.arrow, classes.arrowRight)}>
                        {/* <ChevronRightIcon fontSize="large" /> TODO: will work in next iteration :) */}
                    </div>
                </div>
            </DragDropContext>
        </>
    )
})

export {
    DraggableCarousel,
}
