import { LinearProgress, Typography } from '@material-ui/core'
import { PLATFORM } from '@tivio/types'
import React from 'react'
import styled from 'styled-components'

import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { AnalyticCard } from '../AnalyticCard'

import { DeviceViewCountDetail } from './types'


const StyledLinearProgress = styled(LinearProgress)`
    width: 100px;
    height: 11px;
    border-radius: 14px;

    > div {
        border-radius: 14px;
    };
`

interface Props {
    deviceViewCountDetails: DeviceViewCountDetail[]
}

export const DeviceDetailCard: React.FC<Props> = (props) => {
    const { deviceViewCountDetails } = props

    const DEVICE_TYPE_MAP = {
        [PLATFORM.MOBILE]: 'Mobile',
        [PLATFORM.TV]: 'TV',
        [PLATFORM.WEB]: 'Web application',
        [PLATFORM.UNKNOWN]: 'Unrecognized',
    }

    const totalViewsCount = deviceViewCountDetails.reduce((sum, deviceDetail) => sum += deviceDetail.viewCount, 0)

    return (
        <AnalyticCard title="Devices">
            <Column spacing={1}>
                {
                    deviceViewCountDetails.map((deviceDetail: DeviceViewCountDetail) => {
                        return (
                            <Row
                                rowProps={{ alignItems: 'center', justifyContent: 'space-between' }}
                                key={deviceDetail.deviceType}
                            >
                                <Typography variant="caption">
                                    {DEVICE_TYPE_MAP[deviceDetail.deviceType]}
                                </Typography>
                                <Row
                                    spacing={2}
                                    rowProps={{ alignItems: 'center', justifyContent: 'flex-start' }}
                                >
                                    <Typography
                                        variant="caption"
                                        color="textSecondary"
                                    >
                                        {deviceDetail.viewCount}
                                    </Typography>
                                    <StyledLinearProgress
                                        value={(deviceDetail.viewCount / totalViewsCount) * 100}
                                        variant="determinate"
                                        color="primary"
                                    />
                                </Row>
                            </Row>
                        )
                    })
                }
            </Column>
        </AnalyticCard>
    )
}
