import { useEffect, useState } from 'react'

import { getFirestore } from '../../../firebase/app'

import { useOrganization } from './useOrganization'

import type { DocumentReference, QuerySnapshot, SeriesDocument } from '@tivio/firebase'


type Series = SeriesDocument & {
    ref: DocumentReference<SeriesDocument>
}

// TODO: Please create a Series entity and work with that. This is a bad example of implementation.
export const useSeries = () => {
    const { organization } = useOrganization()
    const [series, setSeries] = useState<Series[]>([])

    useEffect(() => {
        const fetchSeries = async () => {
            const query = await getFirestore()
                .collection('contents')
                .where('organizationRef', '==', organization?.ref)
                .where('type', '==', 'SERIES')
                .get() as QuerySnapshot<SeriesDocument>

            const series = query.docs.map(doc => {
                return {
                    ...doc.data(),
                    ref: doc.ref,
                }
            })

            setSeries(series)
        }

        fetchSeries()

    }, [organization])



    return {
        series,
    }
}
