import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { ExtendedMonetizationVariant } from '../../store/Monetization'
import ChipsArray, { ChipsArrayItem } from '../common/chips/ChipsArray'
import { useOrganization } from '../hooks'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../hooks/uiHooks/useDialog'

import AddNewMonetizationVariantDialog, { NewVariantFormValues } from './AddNewMonetizationVariantDialog'


interface Props {
    monetizationId: string
    variants: ExtendedMonetizationVariant[]
    selectedVariantId: string | undefined
    onVariantSelect: (variantId: string) => void
}

/**
 * Chips for switching between variants of Monetization. Allows add new variants.
 */
const MonetizationVariantChipsArray = ({monetizationId, variants, selectedVariantId, onVariantSelect}: Props) => {
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()

    const { organization } = useOrganization()
    const { isDialogOpen, closeDialog, openDialog } = useDialog()

    const handleAddItem = async(values: NewVariantFormValues) => {
        const activatingMonetizationId = values.monetizationId
        await organization?.addMonetizationVariant(monetizationId, activatingMonetizationId, 'advertisement')

        closeDialog()
        onVariantSelect(activatingMonetizationId)
    }

    const handleDeleteItem = (item: ChipsArrayItem) => {
        return new Promise<void>(
            resolve => {
                confirmAction(
                    async () => {
                        await organization?.removeMonetizationVariant(monetizationId, item.value)
                        resolve()
                    },
                    `${t('Are you sure you want to delete monetization variant')} ${item.label}?`,
                )
            },
        )
    }


    const items = useMemo(
        () => variants.map(variant => ({
            value: variant.id,
            label: variant.name,
            editable: variant.type !== 'default',
        })),
        [ variants ],
    )

    const selectedItem = useMemo(
        () => items.find(item => item.value === selectedVariantId),
        [ items, selectedVariantId ],
    )

    return (
        <>
            <ChipsArray
                items={items}
                selectedItem={selectedItem}
                addButtonText={'Variant'}
                onSelectItem={item => onVariantSelect(item.value)}
                onAddItem={() => openDialog()}
                onDeleteItem={handleDeleteItem}
            />
            <AddNewMonetizationVariantDialog
                monetizationId={monetizationId}
                open={isDialogOpen}
                onClose={closeDialog}
                onSubmit={handleAddItem}
            />
        </>

    )
}


export default MonetizationVariantChipsArray
