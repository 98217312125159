import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'

import OrganizationAdmin from '../../../store/OrganizationAdmin'

import SettingsOrganization from './SettingsOrganization'


interface TProps {
    organizations: OrganizationAdmin[]
}

const SettingsOrganizations = observer((props: TProps) => {

    const sortedOrganizations = props.organizations.slice().sort((a, b) =>
        a.getName.toLowerCase().localeCompare(b.getName.toLowerCase()),
    )

    return (
        <Grid
            container
            direction="column"
            spacing={3}
        >
            {
                sortedOrganizations.map(organization => (
                    <Grid
                        item
                        key={organization.id}
                    >
                        <SettingsOrganization
                            organization={organization}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
})

export default SettingsOrganizations
