import { makeStyles } from '@material-ui/core'


export const useStyles = makeStyles(theme => ({
    secondary: {
        color: theme.palette.text.secondary,
    },
    item: {
        borderRadius: '4px',

        '&:hover': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    iconCol: {
        flex: '0 0 40px',
        width: '40px',
    },
    textRight: {
        textAlign: 'right',
    },
    head: {
        fontWeight: 'bold',
    },
    skeletonRow: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    noname: {
        opacity: 0.7,
    },
    searchByRadioGroup: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    searchTextField: {
        flexGrow: 1,
    },
    searchTextFieldContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
    },
    typeIcon: {
        verticalAlign: 'middle',
        marginRight: theme.spacing(1),
    },
    videoItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.text.secondary,
        textAlign: 'left',
    },
    videoItemImage: {
        borderRadius: theme.spacing(1),
        width: theme.spacing(8),
        display: 'block',
    },
}))
