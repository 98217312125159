import { BufferChunk, BufferPercentChunk } from '@tivio/types'

import type { Track } from 'shaka-player'


export const isAdaptive = (uri: string) => {
    // strips protocol, host, port, url params and hash.
    // e.g. 'https://test.com/path/src.mpd?param1=1&param2=2#hash' becomes '/path/src.mpd'
    const pathname = new URL(uri).pathname
    return pathname.endsWith('.mpd') || pathname.endsWith('.m3u8')
}

export const isValidQuality = (track: Track) => {
    return track.id != null && track.height != null
}

export const chunksEqual = (chunks1: BufferChunk[], chunks2: BufferChunk[]) => {
    if (chunks1.length !== chunks2.length) {
        return false
    }

    let result = true

    for (let i = 0; i < chunks1.length; i ++) {
        if (!chunkEquals(chunks1[i], chunks2[i])) {
            result = false
            break
        }
    }

    return result
}

const chunkEquals = (chunk1: BufferChunk, chunk2: BufferChunk) => {
    return chunk1.endMs === chunk2.endMs && chunk1.startMs === chunk2.startMs
}

const chunkContains = (chunk: BufferChunk, ms: number) => {
    return chunk.startMs <= ms && chunk.endMs >= ms
}

export const filterDistantChunks = (chunks: BufferChunk[], ms: number) => {
    return chunks.filter(chunk => chunkContains(chunk, ms))
}

export const chunksToPercent = (chunks: BufferChunk[], durationMs: number): BufferPercentChunk[] => {
    return chunks.map(chunk => {
        return {
            ...chunk,
            startPercent: chunk.startMs / durationMs * 100,
            endPercent: chunk.endMs / durationMs * 100,
        }
    })
}
