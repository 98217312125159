import { Button, Container, Grid, Typography } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Organization from '../../../store/Organization'
import { goBack } from '../../../utils/history.utils'
import TargetInfo from '../../deploy/TargetInfo'

import { useStyles } from './deploy'


interface Props {
    organization: Organization
}

/**
 * Dashboard page component
 */
export const SecretPage: FC<Props> = observer((props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const deploy = props.organization.deploy
    const { targets, readyState } = deploy

    if (readyState === 'loading') {
        return (<div>Loading...</div>)
    }

    return (<>
        <Container
            maxWidth="lg"
            className={classes.container}
        >
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Grid item xs>
                    <Typography variant="h5">
                        {t('Secrets')}
                    </Typography>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<ArrowBackIos/>}
                        onClick={goBack}
                    >
                        {t('Back')}
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                {targets.map((target, i) => (
                    <TargetInfo
                        key={i}
                        target={target}
                        deploy={deploy}
                        hasTopDivider={!i}
                        environments={deploy.environments}
                        visibleEnvironmentsLabels={!i}
                    />
                ))}
            </Grid>
        </Container>
    </>)
})

export default SecretPage
