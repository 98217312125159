import { Box, Grid, IconButton, makeStyles } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useListItemStyles } from '../../common/styles'

import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'


interface Props {
    label: string;
    value: string;
    dragHandle?: DraggableProvidedDragHandleProps | null;
    onDelete?: (value: string) => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer',
    },
    dragItemMain: {
        fontSize: '1rem',
    },
    dragItemInfo: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    blockIcon: {
        display: 'block',
    },
}))

export const DragItem: React.FC<Props> = ({ dragHandle, label, value, onDelete }) => {
    const listItemClasses = useListItemStyles()
    const classes = useStyles()
    const [t] = useTranslation()
    const [isHovering, setIsHovering] = useState(false)

    const handleMouseEnter = () => {
        setIsHovering(true)
    }

    const handleMouseLeave = () => {
        setIsHovering(false)
    }

    const handleDeleteClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onDelete?.(value)
    }

    return (
        <Box my={2}>
            <Grid
                container
                alignItems="center"
                spacing={2}
                component="a"
                justifyContent="space-between"
                className={clsx(listItemClasses.root, classes.root)}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <Grid item>
                    <IconButton
                        {...dragHandle}
                        size="small"
                    >
                        <Menu
                            color="disabled"
                            fontSize="inherit"
                        />
                    </IconButton>
                </Grid>
                <Grid
                    item
                    className={classes.dragItemMain}
                    xs={3}
                >
                    {label}
                </Grid>
                <Grid
                    item
                    className={classes.dragItemInfo}
                    xs={2}
                >
                    {value.toUpperCase()}
                </Grid>
                <Grid
                    item
                    xs={6}
                >
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-end"
                        className={clsx(listItemClasses.showOnHover, { isHover: isHovering })}
                    >
                        <Grid item>
                            <IconButton
                                size="small"
                                disabled={!onDelete}
                                title={t('Delete item')}
                                onClick={handleDeleteClick}
                                className={classes.blockIcon}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}
