import { useCallback, useState } from 'react'


/**
 * Hook for displaying errors in UI components
 */
const useError = () => {
    const [ error, setError ] = useState<Error | null>(null)

    const raiseError = useCallback((error: Error) => {
        setError(error)
    }, [])

    const resetError = useCallback(() => {
        setError(null)
    }, [])

    return {
        error,
        raiseError,
        resetError,
    }
}

export {
    useError,
}
