import { Button, Grid, Typography } from '@material-ui/core'
import { generateVideoUrlNames, normalizeUrl } from '@tivio/common'
import { LangCode, VideoUrlNames as VideoUrlNamesType } from '@tivio/types'
import debounce from 'lodash/debounce'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'


import { getVideosIdsByLangUrlName } from '../../firebase/firestore/video'
import Video from '../../store/Video'
import { Header, TActions } from '../common'
import MultiTextField, { MultiTextFieldItem, multiTextFieldItemToUrlName, MultiTextFieldValidationSchema, urlNameToMultiTextFieldItem } from '../common/formField/MultiTextField'
import { useOrganization } from '../hooks'
import { useAlert } from '../hooks/uiHooks/useAlert'


interface Props {
    video: Video
}

export const VideoUrlNames: React.FC<Props> = observer(({
    video,
}) => {
    const { showError, showSuccess } = useAlert()
    const organizationRef = useOrganization().organization?.ref
    const [t] = useTranslation()
    const [urlNames, setUrlNames] = useState<VideoUrlNamesType>({})
    const [urlNameMultiText, setUrlNameMultiText] = useState<MultiTextFieldItem[]>([])
    const [isUrlNamesVisible, setIsUrlNamesVisible] = useState<boolean>(false)
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true)
    const [isValidFlags, setIsValidFlags] = useState<MultiTextFieldValidationSchema>({})

    const createUrlName = useCallback(() => {
        const videoName = video.getNameTranslations
            // we want to avoid errors when user create new video and he does not erase all langs from 'video name'
            ? Object.entries(video.getNameTranslations).reduce((acc, [key, value]) => {
                if (value.toLowerCase() === 'video name') {
                    delete acc[key as LangCode]
                }
                return acc
            }, video.getNameTranslations)
            : video.getName

        return generateVideoUrlNames({
            videoName,
        })
    }, [video.getName, video.getNameTranslations])

    useEffect(
        () => {
            const urlName = video.getUrlName
            if (urlName) {
                setIsUrlNamesVisible(true)
                setUrlNames(urlName)
                setUrlNameMultiText(urlNameToMultiTextFieldItem(urlName))
            }
        },
        // we want to call it only on mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    type ValidationFunctionProp = (urlNameData: string, langCode: LangCode) => Promise<boolean> | boolean

    const validateUrlName = useCallback(async (validationFunction: ValidationFunctionProp, urlName: VideoUrlNamesType) => {
        const incorrectLangCodes: LangCode[] = []

        for (const langCode of Object.values(LangCode)) {
            if (urlName[langCode]?.[0]) {
                const urlNameByCode: string = urlName[langCode]![0]
                const isLangCodeValid = await validationFunction(urlNameByCode, langCode)
                // add only negative validation for simple merging,
                // not existing record implicates that value does not exist or is OK
                if (!isLangCodeValid) {
                    incorrectLangCodes.push(langCode)
                }
            }
        }
        return incorrectLangCodes
    }, [])

    const validateUrlNames = useCallback(async (urlName: VideoUrlNamesType) => {

        // validate that url names do not contain forbidden characters
        const validateOnSpecialChars = (urlNameByCode: string) => (urlNameByCode === normalizeUrl(urlNameByCode))
        const langCodesWithIncorrectUrl = await validateUrlName(validateOnSpecialChars, urlName)

        // validate that url is unique
        const validateOnUniq = async (urlNameByCode: string, langCode: LangCode) => {
            const videoIds = await getVideosIdsByLangUrlName(organizationRef!, langCode, urlNameByCode)
            return videoIds.filter(videoId => videoId != video.id).length === 0
        }
        const langCodesWithDuplicitousUrl = await validateUrlName(validateOnUniq, urlName)

        const isValid = !(langCodesWithIncorrectUrl.length || langCodesWithDuplicitousUrl.length)
        const validationObject: MultiTextFieldValidationSchema = [...langCodesWithIncorrectUrl, ...langCodesWithDuplicitousUrl]
            .reduce((validationObj, langCode: LangCode) => {
                if (validationObj[langCode] == null) {
                    validationObj[langCode] = false
                }
                return validationObj
            }, {} as MultiTextFieldValidationSchema)

        if (!isValid) {
            const errorMessages = [
                langCodesWithIncorrectUrl.length &&
                t('Following URLs are with forbidden characters: {{langCodesWithIncorrectUrl}}. Allowed characters are A to Z, 0 to 9 and dash (-).',
                    { langCodesWithIncorrectUrl: langCodesWithIncorrectUrl.join(', ') },
                ).replace('. ', '.\n'),
                langCodesWithDuplicitousUrl.length &&
                t('Following URLs are not unique: {{langCodesWithDuplicitousUrl}}', { langCodesWithDuplicitousUrl: langCodesWithDuplicitousUrl.join(', ') }),
            ]
            showError(errorMessages.filter(errorMsg => errorMsg).join('\n'))
        }
        setIsValidFlags(validationObject)
        setIsSaveDisabled(!isValid)
    }, [organizationRef, showError, t, validateUrlName, video.id])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedValidation = useCallback(debounce(async (urlName: VideoUrlNamesType) => {
        await validateUrlNames(urlName)
    }, 1000), [validateUrlNames])

    const handleAddUrlNamesClick = useCallback(() => {
        const initUrlNames = createUrlName()
        const isNotNewVideo = Object.values(initUrlNames).flat().length
        if (isNotNewVideo) {
            setUrlNames(initUrlNames)
            setUrlNameMultiText(urlNameToMultiTextFieldItem(initUrlNames))
            validateUrlNames(initUrlNames)
            setIsUrlNamesVisible(true)
            setIsSaveDisabled(true)
        } else {
            showError(t('You need to change the video name first.'))
        }
    }, [createUrlName, validateUrlNames, showError, t])

    const handleUrlNameChange = (urlName: VideoUrlNamesType) => {
        setIsSaveDisabled(true)
        setUrlNames(urlName)
        debouncedValidation(urlName)
    }

    const handleSave = () => {
        if (urlNames) {
            video.update({
                urlName: urlNames,
            })
            showSuccess(t('URL names has been saved'))
        }
    }

    const actions: TActions | null = useMemo(() => {
        if (isUrlNamesVisible) {
            return null
        }

        return {
            create: {
                title: t('Add URL name'),
                onCreate: handleAddUrlNamesClick,
            },
        }
    }, [handleAddUrlNamesClick, isUrlNamesVisible, t])

    return (
        <>
            <Header
                title={t('URL names')}
                actions={actions}
            />
            {isUrlNamesVisible ?
                (<Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid
                        item
                        xs
                    >
                        <Grid
                            container
                            direction="column"
                            spacing={1}
                        >
                            <Grid item>
                                <MultiTextField
                                    items={urlNameMultiText}
                                    onChange={(items) => handleUrlNameChange(multiTextFieldItemToUrlName(items))}
                                    isValidFlags={isValidFlags}
                                    fullWidth
                                    typographyVariant="h5"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs
                    >
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={isSaveDisabled}
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>)
                :
                (<Grid
                    container
                    direction="row"
                    spacing={2}
                >
                    <Grid
                        item
                        xs
                    >
                        <Grid
                            container
                            direction="column"
                            spacing={1}
                        >
                            <Grid item>
                                <Typography color="textSecondary">
                                    {t('No URL name')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>)
            }
        </>
    )
})

VideoUrlNames.displayName = 'VideoUrlNames'
