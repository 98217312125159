import { FirestoreDocumentReference, getFirestore, loggerFirestore } from '../app'

import { versionsConverter } from './converters'

import type { FirestoreQuery, FirestoreQueryDocumentSnapshot } from '../app'
import type { TSecretFirestore } from './secrets'
import type firebase from 'firebase/app'


export type TVersionFirestore = {
    name: string
    description: string | null
    percentage: number | null
}

export const addVersion = async (
    id: string,
    organizationId: string,
    secretId: string,
    data: TVersionFirestore,
): Promise<FirestoreDocumentReference<TVersionFirestore>> => {
    try {
        const path = `/organizations/${organizationId}/secrets/${secretId}`
        const secretRef = getFirestore()
            .doc(path) as FirestoreDocumentReference<TVersionFirestore>

        const collection = secretRef
            .collection('versions')
            .withConverter(versionsConverter)

        await collection.doc(id).set(data, { merge: true })
        const ref = collection.doc(id)

        loggerFirestore.info('Version has been created')

        return ref
    } catch (e) {
        loggerFirestore.error('Failed to create Version', e)
        throw new Error(e)
    }
}

export const removeVersion = async (
    id: string,
    organizationId: string,
    secretId: string,
): Promise<void> => {
    try {
        const path = `/organizations/${organizationId}/secrets/${secretId}`
        const secretRef = getFirestore()
            .doc(path) as FirestoreDocumentReference<TVersionFirestore>

        const collection = secretRef
            .collection('versions')

        await collection.doc(id).delete()

        loggerFirestore.info('Version has been removed', `${path}/${id}`)
    } catch (e) {
        loggerFirestore.error('Failed to remove Version', e)
        throw new Error(e)
    }
}

export const getVersion = async (
    id: string,
    organizationId: string,
    secretId: string,
): Promise<FirestoreDocumentReference<TVersionFirestore>> => {
    try {
        const path = `/organizations/${organizationId}/secrets/${secretId}`
        const secretRef = getFirestore()
            .doc(path) as FirestoreDocumentReference<TVersionFirestore>

        const collection = secretRef
            .collection('versions')
            .withConverter(versionsConverter)

        return collection.doc(id)
    } catch (e) {
        loggerFirestore.error('Failed to get Version', e)
        throw new Error(e)
    }
}

export const getVersions = (
    organizationId: string,
    secretId: string,
): FirestoreQuery<TVersionFirestore> => {
    try {
        const path = `/organizations/${organizationId}/secrets/${secretId}`
        const secretRef = getFirestore()
            .doc(path) as FirestoreDocumentReference<TVersionFirestore>

        return secretRef
            .collection('versions')
            .withConverter(versionsConverter)
    } catch (e) {
        loggerFirestore.error('Failed to get Versions', e)
        throw new Error(e)
    }
}

export const getVersionsCollection = (parent: firebase.firestore.DocumentReference<TSecretFirestore>)
: FirestoreQuery<TVersionFirestore> => {
    return parent.collection('versions')
        .withConverter(versionsConverter)
}

export const getVersionsQuery = async (parent: firebase.firestore.DocumentReference<TSecretFirestore>)
: Promise<FirestoreQueryDocumentSnapshot<TVersionFirestore>[]> => {
    const versionsRefs = await getVersionsCollection(parent).get()
    return versionsRefs.docs
}

export const updateVersion = async (
    organizationId: string,
    secretId: string,
    versionId: string,
    data: Partial<TVersionFirestore>,
): Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/secrets/${secretId}/versions`)
        await collection.doc(versionId).update(data)
        loggerFirestore.info('Version has been updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update Version', e)
        throw new Error(e)
    }
}
