import { MenuItem, Select, Switch } from '@material-ui/core'
import { NotificationTriggerType, PurchaseStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../../store/Notification'
import { PurchaseStatusSelect } from '../../../common/PurchaseStatusSelect'
import { useMemberships } from '../../../hooks/dataHooks/useMemberships'
import { useSubscriptions } from '../../../hooks/dataHooks/useSubscriptions'
import { TargetingTypeEnum } from '../constants'
import { EmailSettingsSection } from '../shared/EmailSettingsSection'
import { InputWrapper } from '../shared/InputWrapper'

import { DurationTargeting } from './DurationTargeting'
import { MembershipOrSubscription } from './MembershipOrSubscription'
import { PurchaseStatusChangeSelect } from './PurchaseStatusChangeSelect'
import { SurveyTargeting } from './SurveyTargeting'


interface Props {
    notification: TivioNotification
    disabled: boolean
}

export const Targeting = observer(({ notification, disabled }: Props) => {
    const [t] = useTranslation()
    const { subscriptions } = useSubscriptions()
    const { memberships } = useMemberships()
    const prevTriggerType = useRef<NotificationTriggerType>(notification.triggerType)
    const { errors } = notification

    const dateLabelPrefix = notification.targeting?.isActive ? 'Subscribed' : 'Expired'

    const [targetType, setTargetType] = useState(() => {
        if (notification.targeting?.monetizationRef) {
            return TargetingTypeEnum.SUBSCRIPTION
        }
        if (notification.targeting?.membershipRef) {
            return TargetingTypeEnum.MEMBERSHIP
        }
        return TargetingTypeEnum.NONE
    })

    useEffect(() => {
        if (notification.triggerType !== prevTriggerType.current && notification.triggerType === 'duration') {
            notification.purchaseStatus = PurchaseStatus.EXPIRED
        }
        prevTriggerType.current = notification.triggerType
    }, [notification])

    const getTargetingSelect = () => {
        switch (targetType) {
            case TargetingTypeEnum.NONE:
                return null
            case TargetingTypeEnum.MEMBERSHIP:
                return (
                    <InputWrapper
                        label={t('Membership')}
                    >
                        <Select
                            value={notification.targeting?.membershipRef?.id}
                            onChange={(e) => {
                                notification.targetMembershipId = e.target.value as string
                            }}
                            disabled={disabled}
                        >
                            {memberships.map((membership) => (
                                <MenuItem
                                    key={membership.getId}
                                    value={membership.getId}
                                >
                                    {membership.getName}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputWrapper>
                )
            case TargetingTypeEnum.SUBSCRIPTION:
                return (
                    <InputWrapper
                        label={t('Subscription')}
                    >
                        <Select
                            value={notification.targeting?.monetizationRef?.id}
                            onChange={(e) => {
                                notification.targetMonetizationId = e.target.value as string
                            }}
                            disabled={disabled}
                        >
                            {subscriptions.map((subscription) => (
                                <MenuItem
                                    key={subscription.getId}
                                    value={subscription.getId}
                                >
                                    {subscription.getName}
                                </MenuItem>
                            ))}
                        </Select>
                    </InputWrapper>
                )
        }
    }
    
    if (notification.triggerType === 'userRegistration') {
        return null
    }

    return (
        <EmailSettingsSection label={t('Targeting')}>
            <MembershipOrSubscription
                value={targetType}
                notification={notification}
                onChange={setTargetType}
            />
            {getTargetingSelect()}

            {notification.triggerType === 'purchaseStatusChange' && targetType !== TargetingTypeEnum.NONE && (
                <PurchaseStatusChangeSelect notification={notification} />
            )}
            {notification.targeting && notification.targeting.type !== 'survey' && notification.targeting.type !== 'location' &&
                <InputWrapper label={t('Is voucher')}>
                    <Switch
                        color="primary"
                        checked={!!notification.targetIsVoucher}
                        onChange={(e) => {
                            notification.targetIsVoucher = e.target.checked
                        }}
                        disabled={disabled}
                    />
                </InputWrapper>
            }
            {notification.triggerType === 'duration' && targetType !== TargetingTypeEnum.NONE && (
                <>
                    <DurationTargeting notification={notification} />
                    <InputWrapper label={t('Purchase status')}>
                        <PurchaseStatusSelect
                            onChange={(value) => {
                                notification.purchaseStatus = value
                            }}
                            value={notification.purchaseStatus ?? PurchaseStatus.EXPIRED}
                        />
                    </InputWrapper>
                </>
            )}
            {targetType === TargetingTypeEnum.SURVEY && (
                <SurveyTargeting notification={notification} />
            )}
        </EmailSettingsSection>
    )
})
