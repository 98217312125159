import { createLogger } from '@tivio/common'
import { PaginationInterface, PaginationOptions } from '@tivio/types'
import { action, makeObservable, observable } from 'mobx'


import type firebase from 'firebase/app'


const logger = createLogger('Pagination')

const DEFAULT_LIMIT = 10

export class Pagination<Data, Entity> implements PaginationInterface<Entity> {
    lastDocumentSnapshot: firebase.firestore.QueryDocumentSnapshot<Data> | null = null
    items: Entity[] = []
    hasNextPage = true
    loading = false
    /**
     * Query documents limit.
     * If null, then no limit is applied (all documents matching query are fetched).
     */
    limit: number | null
    /**
     * To correctly set hasNextPage flag, we should fetch n + 1 items.
     * If document count fetched with extra limit is less then limit, then there is not next page
     *
     * If null, then no limit is applied (all documents matching query are fetched).
     */
    extraLimit: number | null

    private fetchDataPromise: Promise<void> | null = null

    constructor(
        private query: firebase.firestore.Query<Data>,
        private creator: (ref: firebase.firestore.DocumentReference<Data>, data: Data) => Entity,
        private options?: PaginationOptions,
    ) {
        makeObservable(this, {
            items: observable,
            loading: observable,
            hasNextPage: observable,
            setItems: action,
            setHasNextPage: action,
            startLoading: action,
            stopLoading: action,
        })

        if (options?.noLimit) {
            this.limit = null
            this.extraLimit = null
        } else {
            this.limit = this.options?.limit ?? DEFAULT_LIMIT
            this.extraLimit = this.limit + 1
        }

        if (options?.noAutoFetch) {
            return
        }

        this.fetchData()
    }

    fetchMore = async (): Promise<void> => {
        if (!this.fetchDataPromise || (!this.loading && this.hasNextPage)) {
            this.fetchDataPromise = new Promise((resolve, reject) => {
                this.fetchData()
                    .then(resolve)
                    .catch(reject)
            })
        }

        return this.fetchDataPromise
    }

    fetchData = async (): Promise<void> => {
        try {
            this.startLoading()

            const querySnapshot = await this.makeQuery()

            this.lastDocumentSnapshot = this.getLastSnapshotFromQuerySnapshot(querySnapshot)

            const docs = this.getQuerySnapshotDocs(querySnapshot)
            const entities = this.querySnapshotToEntities(docs)
            const newEntities = this.items.concat(entities)

            this.setItems(newEntities)
        }
        catch (e) {
            logger.error(e)
        }
        finally {
            this.stopLoading()
        }
    }

    querySnapshotToEntities = (docs: firebase.firestore.QueryDocumentSnapshot<Data>[]) => {
        return docs.map(document => this.creator(document.ref, document.data()))
    }

    makeQuery = async () => {
        let query = this.query

        if (this.extraLimit) {
            if (!this.lastDocumentSnapshot) {
                query = query.limit(this.extraLimit)
            } else {
                query = query.limit(this.extraLimit).startAfter(this.lastDocumentSnapshot)
            }
        } else {
            this.setHasNextPage(false)
        }

        return query.get()
    }

    getLastSnapshotFromQuerySnapshot = (querySnapshot: firebase.firestore.QuerySnapshot<Data>) => {
        const querySnapshotDocsLength = querySnapshot.size

        this.setHasNextPage(!!this.limit && (querySnapshotDocsLength > this.limit))

        if (this.hasNextPage) {
            const docs = this.getQuerySnapshotDocs(querySnapshot)
            return docs[docs.length - 1]
        }

        return null
    }

    getQuerySnapshotDocs = (querySnapshot: firebase.firestore.QuerySnapshot<Data>) => {
        let docs = [...querySnapshot.docs]

        if (this.hasNextPage) {
            /**
             * remove extra item if has next page
             */
            docs = docs.slice(0, docs.length - 1)
        }

        return docs
    }

    startLoading = () => {
        this.loading = true
    }

    stopLoading = () => {
        this.loading = false
    }

    addItemToEnd = (item: Entity) => {
        if (!this.hasNextPage) {
            this.setItems(this.items.concat([item]))
        }
    }

    addItemToStart = (item: Entity) => {
        this.setItems([item].concat(this.items))
    }

    setItems = (items: Entity[]) => {
        this.items = items
    }

    setHasNextPage = (hasNextPage: boolean) => {
        this.hasNextPage = hasNextPage
    }
}
