import { TagType } from '../../store/TagType'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, getFirestore, loggerFirestore } from '../app'

import { getOrganizationsCollection } from './organization'
import { removeTagsOfType } from './tag'
import { Disposer } from './types'

import type { FirestoreCollectionReference } from '../app'
import type { AssetPresetDocument, TagTypeDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const converter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot): TagTypeDocument => {
        return snapshot.data() as TagTypeDocument
    },
    toFirestore: (tagType: TagTypeDocument): FirebaseDocumentData => {
        return tagType
    },
}


export const getTagTypeCollection = (organizationId: string): FirestoreCollectionReference<TagTypeDocument> => {
    return getFirestore()
        .collection(`organizations/${organizationId}/tagTypes`)
        .withConverter(converter)
}

export const getGlobalTagTypeCollection = (): FirestoreCollectionReference<TagTypeDocument> => {
    return getFirestore()
        .collection('globalTagTypes')
        .withConverter(converter)
}

export const getTagTypes = async (organizationId: string): Promise<TagType[]> => {
    const tagTypesSnapshot = await getTagTypeCollection(organizationId).get()
    return tagTypesSnapshot.docs.map(
        tagSnapshot => new TagType(tagSnapshot.ref, tagSnapshot.data()),
    )
}

export const createTagType = async (organizationId: string, tagType: TagTypeDocument) => {
    try {
        await getTagTypeCollection(organizationId).add(tagType)

        loggerFirestore.info('Tag type created', tagType)
    } catch (e) {
        loggerFirestore.error('Failed to create tag type. Error:', e)
        throw new Error(e)
    }
}

export const updateTagType = async (organizationId: string, tagTypeId: string, tagTypeData: TagTypeDocument) => {
    try {
        // Because update does not use converter automatically
        // https://github.com/firebase/firebase-js-sdk/issues/2842
        const data = converter.toFirestore(tagTypeData)
        await getTagTypeCollection(organizationId).doc(tagTypeId).update(data)

        loggerFirestore.info('Tag type updated', tagTypeData)
    } catch (e) {
        loggerFirestore.error('Failed to update tag type. Error:', e)
        throw new Error(e)
    }
}

export const removeTagType = async (organizationId: string, tagTypeId: string) => {
    try {
        await getTagTypeCollection(organizationId).doc(tagTypeId).delete()

        await removeTagsOfType(organizationId, tagTypeId)

        loggerFirestore.info('Removed tag type ', tagTypeId)
    } catch (e) {
        loggerFirestore.error('Failed to remove tag type. Error:', e)
        throw new Error(e)
    }
}

/**
 * Removes assetPresetRef (if presented there) from refs of TagTypes of concrete organization if organizationId is specified.
 * Otherwise assetPreset is global, so removes assetPresetRef (if presented there) from all existed TagTypes (global, and TagTypes of all organizations)
 */
export const removeAssetPresetRefFromTagTypes = async (assetPresetRef: firebase.firestore.DocumentReference<AssetPresetDocument>, organizationId?: string) => {
    const isGlobalAsset = !organizationId

    if (isGlobalAsset) {
        await removeAssetPresetRefFromTagTypeCollection(assetPresetRef, getGlobalTagTypeCollection())

        const organizationSnapshots = await getOrganizationsCollection().get()

        for (const orgSnapshot of organizationSnapshots.docs) {
            await removeAssetPresetRefFromTagTypeCollection(assetPresetRef, getTagTypeCollection(orgSnapshot.id), organizationId)
        }

    } else {
        await removeAssetPresetRefFromTagTypeCollection(assetPresetRef, getTagTypeCollection(organizationId), organizationId)
    }

}

/**
 * Removes assetPresetRef (if presented there) from refs of TagType from provided tagTypeCollection.
 */
const removeAssetPresetRefFromTagTypeCollection = async (
    assetPresetRef: firebase.firestore.DocumentReference<AssetPresetDocument>,
    tagTypeCollection: FirestoreCollectionReference<TagTypeDocument>,
    organizationId?: string,
) => {
    const tagTypeSnapshots = await tagTypeCollection
        .where('assetPresets', 'array-contains', assetPresetRef)
        .get()


    for (const tagTypeSnapshot of tagTypeSnapshots.docs) {
        const updatedAssetPresets = tagTypeSnapshot
            .get('assetPresets')
            .filter((ref: firebase.firestore.DocumentReference<AssetPresetDocument>)  => ref.id !== assetPresetRef.id)
        await tagTypeSnapshot.ref.update({ assetPresets: updatedAssetPresets })

        loggerFirestore.info(
            `Remove AssetPreset reference ${assetPresetRef} from ${organizationId ? ('organizations/' + organizationId) : 'global'} collection`,
        )
    }
}

type Callback = (data: TagType[]) => any

export const listenTagTypes = (
    organizationId: string,
    callback: Callback,
): Disposer => {
    return getTagTypeCollection(organizationId)
        .orderBy('name')
        .onSnapshot({
            next: (snapshots) => {
                const tagTypes = snapshots.docs.map(tagSnapshot => new TagType(tagSnapshot.ref, tagSnapshot.data()))

                callback(tagTypes)
            },
        })
}

export const listenGlobalTagTypes = (callback: Callback): Disposer => {
    return getGlobalTagTypeCollection().onSnapshot(snapshots => {
        const tagTypes = snapshots.docs.map(tagSnapshot =>
            new TagType(tagSnapshot.ref, { ...tagSnapshot.data(), isGlobal: true }),
        )
        callback(tagTypes)
    })
}
