import firebase from 'firebase'

import { AssetPreset } from '../../store/AssetPreset'
import Organization from '../../store/Organization'
import { getFirestore, loggerFirestore } from '../app'

import { removeAssetPresetRefFromTagTypes } from './tagType'

import type { Disposer } from './types'


const converter = {
    toFirestore(preset: AssetPreset): firebase.firestore.DocumentData {
        return {
            name: preset.name,
            description: preset.description,
            width: preset.width,
            height: preset.height,
            mode: preset.mode,
            scaling: preset.scaling,
            placement: preset.placement,
            updated: firebase.firestore.Timestamp.fromDate(preset.updated),
        }
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
    ): AssetPreset {
        const data = snapshot.data()

        return new AssetPreset(
            snapshot.id,
            data.name,
            data.description,
            data.width,
            data.height,
            data.mode,
            data.scaling,
            data.placement,
            data.updated ? data.updated.toDate() : new Date(),
        )
    },
}

const getGlobalCollection = () => {
    return getFirestore()
        .collection('globalAssetPresets')
        .withConverter(converter)
}

const getCollection = (organization: Organization) => {
    return organization.ref
        .collection('assetPresets')
        .withConverter(converter)
}

export const listenAssetPresets = (
    organization: Organization,
    callback: (data: AssetPreset[]) => any,
): Disposer => {
    return getCollection(organization)
        .orderBy('name')
        .onSnapshot({
            next: (snapshots) => {
                const presets = snapshots.docs.map(doc => doc.data())

                callback(presets)
            },
        })
}

export const createAssetPreset = async (organization: Organization, preset: AssetPreset) => {
    try {
        await getCollection(organization).add(preset)

        loggerFirestore.info('Asset preset created', preset)
    } catch (e) {
        loggerFirestore.error('Failed to create asset preset. Error:', e)
        throw new Error(e)
    }
}

export const createGlobalAssetPreset = async (preset: AssetPreset) => {
    try {
        await getGlobalCollection().add(preset)

        loggerFirestore.info('Global asset preset created', preset)
    } catch (e) {
        loggerFirestore.error('Failed to create global asset preset. Error:', e)
        throw new Error(e)
    }
}

export const removeAssetPreset = async (organization: Organization, preset: AssetPreset) => {
    try {
        const assetPresetRef = getCollection(organization).doc(preset.id)
        await assetPresetRef.delete()

        loggerFirestore.info('Removed asset preset', preset)

        await removeAssetPresetRefFromTagTypes(assetPresetRef, organization.id)
    } catch (e) {
        loggerFirestore.error('Failed to remove asset preset. Error:', e)
        throw new Error(e)
    }
}

export const removeGlobalAssetPreset = async (preset: AssetPreset) => {
    try {
        const assetPresetRef = getGlobalCollection().doc(preset.id)

        await assetPresetRef.delete()

        loggerFirestore.info('Removed global asset preset', preset)

        await removeAssetPresetRefFromTagTypes(assetPresetRef)

    } catch (e) {
        loggerFirestore.error('Failed to remove global asset preset. Error:', e)
        throw new Error(e)
    }
}

export const updateAssetPreset = async (organization: Organization, preset: AssetPreset) => {
    try {
        // Because update does not use converter automatically
        // https://github.com/firebase/firebase-js-sdk/issues/2842
        const data = converter.toFirestore(preset)
        await getCollection(organization).doc(preset.id).update(data)

        loggerFirestore.info('Asset preset updated', preset)
    } catch (e) {
        loggerFirestore.error('Failed to update asset preset. Error:', e)
        throw new Error(e)
    }
}

export const updateGlobalAssetPreset = async (preset: AssetPreset) => {
    try {
        // Because update does not use converter automatically
        // https://github.com/firebase/firebase-js-sdk/issues/2842
        const data = converter.toFirestore(preset)
        await getGlobalCollection().doc(preset.id).update(data)

        loggerFirestore.info('Global asset preset updated', preset)
    } catch (e) {
        loggerFirestore.error('Failed to update global asset preset. Error:', e)
        throw new Error(e)
    }
}

type Callback = (presets: AssetPreset[]) => any

export const listenGlobalAssetPresets = (callback: Callback): Disposer => {
    return getGlobalCollection().onSnapshot(snap => {
        const presets = snap.docs.map(doc => {
            const preset = doc.data()
            preset.isGlobal = true
            return preset
        })

        callback(presets)
    })
}
