import { Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
`

interface Props extends PropsWithChildren {
    label: string
}

export const EmailSettingsSection = ({ label, children }: Props) => {
    return (
        <Wrapper>
            <Typography variant="h6">
                {label}
            </Typography>
            {children}
        </Wrapper>
    )
}
