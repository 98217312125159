import { MenuItem, Select, Switch } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../../store/Notification'
import { notEmptyFilter } from '../../../../utils/array.utils'
import { getSenderId } from '../../utils'
import { EmailSettingsSection } from '../shared/EmailSettingsSection'
import { InputWrapper } from '../shared/InputWrapper'

import { ScheduleDatePicker } from './ScheduleDatePicker'
import { SendTypeSelect } from './SendTypeSelect'

import type { NotificationTriggerType } from '@tivio/types'


interface Props {
    notification: TivioNotification;
    disabled: boolean;
}

export const EmailInfo = observer(({ notification, disabled }: Props) => {
    const { organization } = notification
    const emailConfigs = organization?.emailConfigs

    const [t] = useTranslation()
    const senderId = notification.sender ? getSenderId(notification.sender) : ''
    // In case the sender is not in the list of senders, we add it back to the list so it's displayed in the select
    const sendersWithChosen = emailConfigs?.sender.some((sender) => getSenderId(sender) === senderId)
        ? emailConfigs?.sender
        : [...(emailConfigs?.sender ?? []), notification.sender].filter(notEmptyFilter)

    const triggerOptions = useMemo<Record<NotificationTriggerType, string>>(
        () => ({
            manual: t('Manual'),
            scheduled: t('Scheduled'),
            purchaseStatusChange: t('Purchase status change'),
            duration: t('Duration'),
            userRegistration: t('User registration'),
        }),
        [t],
    )

    return (
        <EmailSettingsSection label="E-mail info">
            {notification.triggerType !== 'manual' && notification.triggerType !== 'scheduled' && <InputWrapper label={t('Enabled')}>
                <Switch
                    color="primary"
                    checked={!!notification.enabled}
                    onChange={(e) => {
                        notification.enabled = e.target.checked
                    }}
                    disabled={disabled}
                />
            </InputWrapper>}
            {emailConfigs && (
                <>
                    <InputWrapper label={t('Sender')}>
                        <Select
                            value={senderId}
                            onChange={(e) => {
                                notification.sender = sendersWithChosen.find((sender) => getSenderId(sender) === e.target.value)
                            }}
                            disabled={disabled}
                        >
                            {sendersWithChosen.map((sender) => {
                                const itemId = getSenderId(sender)
                                return (
                                    <MenuItem
                                        key={itemId}
                                        value={itemId}
                                    >
                                        {itemId}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </InputWrapper>
                </>
            )}
            <SendTypeSelect
                notification={notification}
                label={t('Trigger')}
                options={triggerOptions}
                disabled={disabled}
            />
            {notification.triggerType === 'scheduled' && <ScheduleDatePicker
                notification={notification}
                label={t('Send date')}
                disabled={disabled}
            />}
        </EmailSettingsSection>
    )
})
