import { Container } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import store from '../../../store'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'

import { OrganizationsHeader } from './organizationsHeader'
import SettingsOrganizations from './SettingsOrganizations'


export const OrganizationsTab = observer(() => {
    const [ t ] = useTranslation()
    const { getMember: member } = store

    if (!member || !member.getAdmin) {
        return <AppNotFoundTemplate title={t('Insufficient permissions')}/>
    }

    return (
        <Container maxWidth="lg">
            <OrganizationsHeader admin={member.getAdmin}/>
            <SettingsOrganizations organizations={member.getAdmin.getOrganizations}/>
        </Container>
    )
})
