import { Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { BannerItemComponent, RowItemComponent } from '@tivio/types'
import React from 'react'
import styled, { css } from 'styled-components'

import { Tag } from '../../store/Tag'
import TvChannel from '../../store/TvChannel'
import Video from '../../store/Video'


interface Props extends SizesProps {
    rowItem: Video | Tag | TvChannel
    component: RowItemComponent | BannerItemComponent
}

interface SizesProps {
    coverHeight: number
    coverWidth: number
    fullWidth?: boolean
}

interface CoverProps extends SizesProps {
    component: RowItemComponent | BannerItemComponent
    cover?: string
}

const VIDEO_CAPTION_CONTAINER_HEIGHT = 23

const VideoContainer = styled.div<SizesProps>`
    max-width: ${props => props.coverWidth}px;
`

const VideoCover = styled.div<CoverProps>`
    ${props => css`
        height: ${props.coverHeight}px;
        border-radius: ${props.component === RowItemComponent.ROW_ITEM_CIRCLED ? '50%' : '10px'};
        background-image: url(${props.cover});
        background-position: center;
        background-size: cover;
        position: relative;

        transition: all .2s ease-in-out;

        :hover {
            box-shadow: 0 3px 12px -4px rgba(0, 0, 0, 0.75);
        }
    `}
`

const VideoSkeleton = styled(Skeleton) <{ component: RowItemComponent | BannerItemComponent }>`
    height: 100%;
    width: 100%;
    border-radius: ${props => props.component === RowItemComponent.ROW_ITEM_CIRCLED ? '50%' : '10px'};
`

const VideoCaptionContainer = styled.div<SizesProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: ${VIDEO_CAPTION_CONTAINER_HEIGHT}px;
`

const VideoName = styled(Typography)`
    ${({ theme: t }) => css`
        text-align: center;
        color: ${t.palette.common.white};
        line-height: 1.3;

        text-overflow: ellipsis;
        overflow: hidden;
        padding: 0 2rem;
        white-space: nowrap;
    `}
`

const CarouselTile: React.FC<Props> = (props) => {
    const { rowItem, coverHeight, coverWidth, fullWidth, component } = props
    const getVideoCover = () => {
        const coverImage = rowItem.getCover
        return (
            <VideoCover
                component={component}
                cover={coverImage ?? ''}
                coverHeight={coverHeight}
                coverWidth={coverWidth}
                fullWidth={fullWidth}
            >
                {!coverImage && <VideoSkeleton
                    component={component}
                    animation={false}
                    variant="rect"
                />}
            </VideoCover>
        )
    }
    return (
        <VideoContainer
            coverHeight={coverHeight}
            coverWidth={coverWidth}
            fullWidth={fullWidth}
        >
            {getVideoCover()}
            <VideoCaptionContainer
                coverHeight={coverHeight}
                coverWidth={coverWidth}
                fullWidth={fullWidth}
            >
                <VideoName
                    variant="caption"
                    color="textSecondary"
                >
                    {rowItem.getName}
                </VideoName>
            </VideoCaptionContainer>
        </VideoContainer>
    )
}

export {
    CarouselTile,
    VIDEO_CAPTION_CONTAINER_HEIGHT,
}
