import { tivio } from '@tivio/core-js'
import { Disposer, PaginationInterface, RowFilterField, Video as TVideo } from '@tivio/types'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'

import { useError } from './useError'


export const useVideosInScreen = (
    filters: RowFilterField,
) => {
    const [pagination, setPagination] = useState<
    PaginationInterface<TVideo> | null
  >(null)
    const { error, raiseError } = useError()

    useEffect(
        () => {
            if (filters) {
                let disposer: Disposer | null = null
                const loadData = async () => {
                    try {
                        const pagination = await tivio.getVideosByFilter(filters)

                        disposer = autorun(() => {
                            if (pagination.items.length > 0) {
                                setPagination({
                                    fetchMore: pagination.fetchMore,
                                    hasNextPage: pagination.hasNextPage,
                                    items: pagination.items,
                                    loading: pagination.loading,
                                })
                            }
                        })

                        await Promise.all(pagination.items.map((video) => video.initApplication()))

                    } catch (error) {
                        raiseError(error)
                    }
                }

                loadData()

                return () => {
                    if (disposer) {
                        disposer()
                    }
                }
            }
        },
        [filters],
    )

    return {
        pagination,
        error,
    }
}
