import { ApplicationDocumentCreation } from '@tivio/firebase'
import { useEffect } from 'react'

import { createLogger } from '../../../logger'
import { useAlert } from '../uiHooks/useAlert'

import { useOrganization } from './useOrganization'



const logger = createLogger('useApplications')

export const useApplications = () => {
    const { organization } = useOrganization()
    const { showSuccess, showError } = useAlert()

    useEffect(() => {
        organization?.loadApplications()
    }, [])

    const addApplication = (applicationData: ApplicationDocumentCreation) => {
        try {
            organization?.addApplication(applicationData)
            showSuccess('Application created')
        } catch (e) {
            logger.error(e)
            showError('Failed to create application')
        }
    }

    return {
        applications: organization?.applications ?? [],
        isLoading: organization?.applicationsState.loading,
        addApplication,
    }
}
