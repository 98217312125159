import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    width: 220px;
    height: 220px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PauseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="63" height="68" viewBox="0 0 68 72">
            <g fill="none" fillRule="evenodd">
                <g stroke="#FFF" strokeWidth="4">
                    <g>
                        <g transform="translate(-925 -504) translate(850 430) translate(77.784 76.065)">
                            <rect width="22.337" height="67.869" rx="4"/>
                            <rect width="22.337" height="67.869" x="40.378" rx="4"/>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export const BigPauseButton = () => {
    return (
        <Container>
            <PauseIcon />
        </Container>
    )
}
