import { OrganizationMemberRoles } from '@tivio/firebase'
import { useObserver } from 'mobx-react-lite'

import store from '../../../store'


export const useMember = () => {
    return useObserver(() => store.getMember)
}

export const useRole = () => {
    return useMember()?.getCurrentRole ?? null
}

export const useIsSuperAdmin = () => {
    const role = useRole()
    return !!role && role >= OrganizationMemberRoles.SUPER_ADMIN
}
