import makeStyles from '@material-ui/core/styles/makeStyles'
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon/SvgIcon'
import Block from '@material-ui/icons/Block'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { PurchaseStatus } from '@tivio/types'
import React from 'react'


interface Props {
    purchaseStatus: PurchaseStatus | string | undefined
}

const purchaseStatusToColor = (status: Props['purchaseStatus']): SvgIconTypeMap['props']['color'] => {
    switch (status) {
        case PurchaseStatus.NEW:
            return 'disabled'
        case PurchaseStatus.CANCELLING:
        case PurchaseStatus.PAID:
        case PurchaseStatus.RENEWED:
            return 'primary'
        case PurchaseStatus.ABORTED:
        case PurchaseStatus.BLOCKED:
        case PurchaseStatus.CANCELLED:
        case PurchaseStatus.ERROR:
        case PurchaseStatus.EXPIRED:
            return 'error'
        default:
            return 'inherit'
    }
}

const useStyles = makeStyles({
    icon: {
        display: 'block',
    },
})

export const UserPurchaseIcon: React.FC<Props> = ({
    purchaseStatus,
}) => {
    const classes = useStyles()

    const className = classes.icon
    const color = purchaseStatusToColor(purchaseStatus)

    if (purchaseStatus === PurchaseStatus.BLOCKED) {
        return (
            <Block
                className={className}
                color={color}
            />
        )
    } else {
        return (
            <FiberManualRecordIcon
                className={className}
                color={color}
            />
        )
    }
}
