import { Grid, IconButton, Slider, Theme } from '@material-ui/core'
import { SliderProps } from '@material-ui/core/Slider/Slider'
import { makeStyles } from '@material-ui/core/styles'
import FilterHdr from '@material-ui/icons/FilterHdr'
import React from 'react'

import { DEFAULT_ZOOM_VALUE } from '../player/PlayerZoom'


const useStyles = makeStyles<Theme, { zoomSliderWidth?: number }>((theme) => ({
    zoomOutIcon: {
        fontSize: '1rem',
    },
    zoomSliderRoot: {
        width: (props) => props.zoomSliderWidth ?? 48,
    },
    zoomRail: {
        color: theme.palette.common.white,
    },
    zoomTrack: {
        color: theme.palette.common.white,
    },
    zoomThumb: {
        color: theme.palette.common.white,
        width: 5,
        borderRadius: 2,
        marginLeft: -5,
    },
    zoomIconContainer: {
        marginBottom: 4,
    },
}))


type Props = Omit<SliderProps, 'onChange' | 'onChangeCommitted' | 'value'> & {
    onChange: (value: number) => void
    // make required
    value: number
    zoomSliderWidthPx?: number;
}


export const ZoomSlider = ({ onChange, zoomSliderWidthPx, ...sliderProps }: Props) => {
    const classes = useStyles({ zoomSliderWidth: zoomSliderWidthPx })

    const zoomOut = () => {
        if (sliderProps.value === sliderProps.min || !sliderProps.step) {
            return
        }

        const newZoomValue = sliderProps.value - sliderProps.step

        onChange(newZoomValue)
    }

    const zoomIn = () => {
        if (sliderProps.value === sliderProps.max || !sliderProps.step) {
            return
        }

        const newZoomValue = sliderProps.value + sliderProps.step

        onChange(newZoomValue)
    }

    const handleZoomValueChange = (event: React.ChangeEvent<any>, value: number | number[]) => {
        onChange(value as number)
    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
        >
            {sliderProps.step && (
                <Grid item>
                    <IconButton
                        onClick={zoomOut}
                        className={classes.zoomIconContainer}
                        size="small"
                    >
                        <FilterHdr className={classes.zoomOutIcon}/>
                    </IconButton>
                </Grid>
            )}
            <Grid item>
                <Slider
                    {...sliderProps}
                    defaultValue={DEFAULT_ZOOM_VALUE}
                    classes={{
                        root: classes.zoomSliderRoot,
                        rail: classes.zoomRail,
                        track: classes.zoomTrack,
                        thumb: classes.zoomThumb,
                    }}
                    onChange={handleZoomValueChange}
                    onChangeCommitted={handleZoomValueChange}
                />
            </Grid>
            {sliderProps.step && (
                <Grid item>
                    <IconButton
                        onClick={zoomIn}
                        className={classes.zoomIconContainer}
                        size="small"
                    >
                        <FilterHdr/>
                    </IconButton>
                </Grid>
            )}
        </Grid>
    )
}
