import { RequestOptions } from '@algolia/transporter'
import { ALGOLIA_INDEX_NAME, IndexedObject } from '@tivio/types'
import algoliasearch, { AlgoliaSearchOptions, SearchClient } from 'algoliasearch/lite'
import { useCallback, useMemo } from 'react'


let algoliaSearchClient: SearchClient

function getAlgoliaSearchClient() {
    if (!algoliaSearchClient) {
        if (!process.env.ALGOLIA_APPLICATION_ID) {
            throw new Error('Can\'t get ALGOLIA_APPLICATION_ID, search client will not be instantiated.')
        }

        if (!process.env.ALGOLIA_SEARCH_API_KEY) {
            throw new Error('Can\'t get ALGOLIA_SEARCH_API_KEY, search client will not be instantiated.')
        }

        algoliaSearchClient = algoliasearch(
            process.env.ALGOLIA_APPLICATION_ID,
            process.env.ALGOLIA_SEARCH_API_KEY,
        )
    }

    return algoliaSearchClient
}

export const useAlgoliaSearch = <T extends ALGOLIA_INDEX_NAME>(indexName: T) => {
    const index = useMemo(() => {
        return getAlgoliaSearchClient().initIndex(indexName)
    }, [ indexName ])

    const search = useCallback((query: string, requestOptions?: RequestOptions & AlgoliaSearchOptions) => {
        return index.search<IndexedObject>(query, requestOptions)
    }, [ index ])

    return { search }
}

