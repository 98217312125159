import {
    Button,
    Divider,
    InputAdornment,
    makeStyles,
    TextField,
    Typography,
} from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { MonetizationPlacementField, MonetizationRecoveryField } from '@tivio/firebase'
import { TvStreamType } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { DEFAULT_VARIANT_ID } from '../../../store/Monetization'
import { alertSuccess } from '../../../utils/alert.utils'
import { PlacementDialog } from '../../ads/PlacementDialog'
import AppNotFoundTemplate from '../../AppNotFoundTemplate'
import { useMonetization } from '../../hooks/dataHooks/useMonetization'
import { useOrganization } from '../../hooks/dataHooks/useOrganization'
import { useVariantPrices } from '../../hooks/dataHooks/useVariantPrices'
import { useLoading } from '../../hooks/general'
import { useDialog } from '../../hooks/uiHooks/useDialog'
import { useUpdatedEntity } from '../../hooks/uiHooks/useUpdatedEntity'
import MonetizationPageHeader from '../../monetization/MonetizationPageHeader'
import { MonetizationPageTitle } from '../../monetization/MonetizationPageTitle'
import { MonetizationPlacementsList } from '../../monetization/MonetizationPlacementsList'
import PaidMonetizationVariantsList from '../../monetization/PaidMonetizationVariantsList'
import { RecoveryPaymentUrl } from '../../monetization/RecoveryPaymentUrl'
import SimplePriceInput from '../../monetization/SimplePriceInput'
import { StyledMonetizationPageContainer } from '../../styled/Container'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { UserLayoutParams } from '../layout/UserLayout'


const StyledTextField = styled(TextField)`
    width: 14rem;
`

const useStyles = makeStyles((theme) => ({
    typography: {
        marginRight: theme.spacing(2) - 1,
    },
}))


export const TransactionPage = observer(() => {
    const classes = useStyles()
    const [t] = useTranslation()
    const { monetizationId } = useParams<UserLayoutParams>()
    const { updateProxy, isUpdated, resetIsUpdated } = useUpdatedEntity()
    const { loading, startLoading, stopLoading } = useLoading()
    const { monetization: transaction, notFound } = useMonetization(monetizationId)

    const { variantPrices, updatePrices, handlePricesChange, constructVariantsWithUpdatedPrices } = useVariantPrices(transaction, updateProxy)

    const [title, setTitle] = useState('')
    const [placements, setPlacements] = useState<MonetizationPlacementField | null>(null)
    const [availableFor, setAvailableFor] = useState(0)
    const { organization } = useOrganization()
    const { isDialogOpen, closeDialog, openDialog } = useDialog()
    const [recovery, setRecovery] = useState<MonetizationRecoveryField>()

    useEffect(
        () => {
            if (transaction) {
                setTitle(transaction.getName)
                setAvailableFor(transaction.getDurationDays ? transaction.getDurationDays * 24 : 0)
                setPlacements(transaction.getPlacements)
                setRecovery(transaction.recovery)

                // prices are set in useVariantPrices hook
            }
        },
        [transaction],
    )

    const handleSave = async () => {
        if (transaction) {
            startLoading()

            await transaction.updateName(title)
            await transaction.updateDurationDays(availableFor / 24)

            await updatePrices()

            await transaction.updateRecovery(recovery)

            alertSuccess(t('Purchase has been saved'))
            stopLoading()
            resetIsUpdated()

            return
        }

        throw new Error('no transaction')
    }

    const handleChangePeriod = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const value = event.target.value

        if (!isNaN(parseFloat(value))) {
            setAvailableFor(parseFloat(value))
        }
    }

    const updateManualActivationRecoveryCallback = (checked: boolean) => updateProxy(() => {
        setRecovery(prev => ({
            recoveryPaymentGatewayLink: prev?.recoveryPaymentGatewayLink ?? '',
            manualActivation: checked,
        }))
    })

    const handleChangeRecoveryPaymentUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateProxy(() => {
            setRecovery({
                recoveryPaymentGatewayLink: e.target.value,
                manualActivation: recovery?.manualActivation ?? false,
            })
        })
    }

    if (notFound) {
        return <AppNotFoundTemplate title={t('Transaction not found')} />
    }

    if (!transaction || !variantPrices || !placements) {
        return null
    }

    const setPlacement = async (
        monetizationId: string,
        checkedTvChannels: string[],
        checkedVodChannels: string[],
        checkedSections: string[],
        checkedVideos: string[],
        tvStream: TvStreamType,
    ) => {
        if (!organization) {
            return
        }

        await organization.setMonetizationPlacement(
            monetizationId,
            checkedTvChannels,
            checkedVodChannels,
            checkedSections,
            checkedVideos,
            tvStream,
        )

        closeDialog()
    }

    return (
        <>
            <PlacementDialog
                monetization={transaction}
                placements={transaction.getPlacements}
                isOpen={isDialogOpen}
                onClose={closeDialog}
                onSubmit={(checkedTvChannels, checkedVodChannels, checkedSections, checkedVideos, tvStream) => setPlacement(
                    transaction.getId,
                    checkedTvChannels,
                    checkedVodChannels,
                    checkedSections,
                    checkedVideos,
                    tvStream,
                )}
            />

            <MonetizationPageHeader
                onSave={handleSave}
                disabled={!isUpdated || loading}
            />

            <StyledMonetizationPageContainer>
                <MonetizationPageTitle onUpdateName={(text) => updateProxy(() => setTitle(text))}>
                    {title}
                </MonetizationPageTitle>
                <Column spacing={5}>
                    <Column spacing={1}>
                        <Typography variant="h6">
                            {t('Settings')}
                        </Typography>

                        <Row
                            spacing={2}
                            rowProps={{
                                alignItems: 'center',
                            }}
                            itemProp={{ index: 0, props: { xs: 1, classes: { root: classes.typography } } }}
                        >
                            <Typography>
                                {t('Available')}
                            </Typography>
                            <StyledTextField
                                value={availableFor}
                                variant="outlined"
                                size="small"
                                onChange={e => updateProxy(() => handleChangePeriod(e))}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {t('hours')}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Row>
                        <SimplePriceInput
                            value={variantPrices[DEFAULT_VARIANT_ID] ?? {}}
                            onChange={newVariantPrices => handlePricesChange(DEFAULT_VARIANT_ID, newVariantPrices)}
                        />
                    </Column>
                    <Column spacing={4}>
                        <Typography variant="h6">
                            {t('Variants')}
                        </Typography>
                        <PaidMonetizationVariantsList
                            monetizationId={monetizationId}
                            monetizationType="transaction"
                            variants={constructVariantsWithUpdatedPrices(transaction.variants)}
                            onPricesChange={handlePricesChange}
                        />
                    </Column>
                    <Column spacing={1}>
                        <Row
                            rowProps={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6">
                                {t('Transaction placement')}
                            </Typography>
                            <Button
                                onClick={openDialog}
                                startIcon={<Add />}
                            >
                                {transaction.hasPlacements ? t('Edit placements') : t('Add placement')}
                            </Button>
                        </Row>
                        <MonetizationPlacementsList
                            onClick={openDialog}
                            monetization={transaction}
                        />
                    </Column>

                    <RecoveryPaymentUrl
                        recovery={recovery}
                        handleChangeRecoveryPaymentUrl={handleChangeRecoveryPaymentUrl}
                        updateManualActivationRecoveryCallback={updateManualActivationRecoveryCallback}
                    />

                    <Divider />
                    <Typography color="textSecondary">
                        ID: {monetizationId}
                    </Typography>
                </Column>
            </StyledMonetizationPageContainer>
        </>
    )
})

export default TransactionPage
