import { LangCode } from '@tivio/types'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import ReactFlow, { ReactFlowProvider, useEdgesState, useNodesState, useOnSelectionChange } from 'reactflow'
import styled from 'styled-components'

import { COLORS } from '../../../static/enum'
import { StyledContainer } from '../../DefaultPageContainer'
import { useOrganization } from '../../hooks'
import { useConversions } from '../../hooks/dataHooks/useConversions'
import { PeriodPicker } from '../../periodPickers/PeriodPicker'
import { CenteredLoader } from '../CenteredLoader'

import ColumnNode from './customNodes/ColumnNode'
import ConversionNode from './customNodes/ConversionNode'
import DeviceNode from './customNodes/DeviceNode'
import PageNode from './customNodes/PageNode'
import TargetNode from './customNodes/TargetNode'
import { initialLabels } from './labels'
import { createNodes } from './node.util'

import type { Node } from 'reactflow'

import 'reactflow/dist/style.css'
import { Row } from '../../uiLayout/Row'


type NodeNTypeName = 'device' | 'conversion' | 'target' | 'page' | 'columnLabel'
type NodeTypes = {
    [key in NodeNTypeName]: any
}

const nodeTypes: NodeTypes = {
    device: DeviceNode,
    conversion: ConversionNode,
    target: TargetNode,
    page: PageNode,
    columnLabel: ColumnNode,
}

const ReactFlowStyled = styled(ReactFlow)`
    background-color: ${COLORS.DRAWER_BACKGROUND};
`

interface Props {
    startDate: string
    endDate: string
}

const Component: React.FC<Props> = ({ startDate, endDate }) => {
    const [nodes, setNodes] = useNodesState([])
    const [edges, setEdges] = useEdgesState([])

    const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([])
    const [selectedTarget, setSelectedTarget] = useState<Node>()
    const [selectedConversionNodeType, setSelectedConversionNodeType] = useState<string>('')
    const [flowLoading, setFlowLoading] = useState<boolean>(true)

    const { organization } = useOrganization()
    const { conversionsData, isLoading } = useConversions({
        startDate,
        endDate,
    })

    useEffect(() => {
        if (!organization?.id) {
            return
        }

        const prepareNodes = async () => {
            setFlowLoading(true)

            if (conversionsData) {
                const { nodes, edges, selectedIds, selectedConversionType } = await createNodes(conversionsData, {
                    defaultLanguage: organization?.languages[0] ?? LangCode.EN,
                    organizationId: organization?.id,
                })

                setNodes([...nodes, ...initialLabels])
                setEdges(edges)
                setSelectedNodeIds(selectedIds)
                setSelectedConversionNodeType(selectedConversionType)
                setSelectedTarget(nodes.find(node => node.type === 'target'))
            }
            setFlowLoading(false)
        }

        prepareNodes().then()
    }, [conversionsData, organization?.languages, organization?.id, setEdges, setNodes])

    // Update the state of selected nodes when selectedNodeIds or selectedConversionNodeType change
    useEffect(() => {
        setNodes((nodes) =>
            nodes.map((node) => {
                node.selected = selectedNodeIds.includes(node.id)
                return node
            }),
        )
    }, [selectedNodeIds, setNodes, selectedConversionNodeType])

    // This is change color of edges of the selected nodes
    useOnSelectionChange({
        onChange: () => {
            rerenderNodes()
        },
    })

    const handleNodeClick = (event: React.MouseEvent, node: Node) => {
        if (node.type === 'target') {
            const newSelectedNodes = selectedNodeIds.filter(id => !id.includes('target'))
            newSelectedNodes.push(node.id)
            setSelectedNodeIds(newSelectedNodes)
            setSelectedTarget(node)
        } else if (node.type === 'conversion' && node.data.conversionType !== selectedConversionNodeType) {
            const firstFoundTarget = nodes.find(n => n.type === 'target' && n.data.conversionType === node.data.conversionType)?.id
            const updatedNodes: string[] = nodes.filter(n => {
                if (n.type === 'conversion') {
                    return n.id === node.id
                }
                if (n.type === 'device') {
                    return true
                }
            }).map(n => n.id)
            if (firstFoundTarget) {
                updatedNodes.push(firstFoundTarget)
            }
            setSelectedNodeIds(updatedNodes)
            setSelectedConversionNodeType(node?.data?.conversionType)
        }
    }

    const rerenderNodes = () => {
        const targetToRenderPages = selectedTarget ? selectedTarget : nodes.find(node => node.type === 'target')

        // Hiding or showing pages for the selected target
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.type === 'page') {
                    node.hidden = node.data.parentId !== targetToRenderPages?.id
                }

                node.selected = selectedNodeIds.includes(node.id)

                return node
            }),
        )

        // Hiding or showing edges between pages and targets
        setEdges((edges) =>
            edges.map((edge) => {
                edge.hidden = edge.data?.conversionType !== selectedConversionNodeType

                if (edge.source === targetToRenderPages?.id) {
                    edge.hidden = false
                } else if (edge.source.includes('target')) {
                    edge.hidden = true
                }

                const isSelected = selectedNodeIds.includes(edge.source) && selectedNodeIds.includes(edge.target)
                const isPage = edge.target.includes('page-')
                const strokeColor = isSelected || isPage ? '#FFF' : '#4E5467'

                edge.style = {
                    ...edge.style,
                    stroke: strokeColor,
                }

                return edge
            }),
        )
    }

    useEffect(() => {
        setSelectedTarget(nodes.find(node => node.type === 'target' && node.data.conversionType === selectedConversionNodeType))

        setNodes((nodes) => {
            return nodes.map((node) => {
                if (node.type === 'conversion' || node.type === 'columnLabel') {
                    node.hidden = false
                } else {
                    node.hidden = selectedConversionNodeType !== node.data?.conversionType
                }
                return node
            })
        })
    }, [selectedConversionNodeType])


    return (
        <StyledContainer maxWidth="lg">
            {
                (isLoading || flowLoading) && (<CenteredLoader />)
            }
            <div
                style={{ height: '900px', width: '100%' }}
            >
                <ReactFlowStyled
                    nodes={nodes}
                    edges={edges}
                    nodeTypes={nodeTypes}
                    nodesDraggable={false}
                    zoomOnDoubleClick={false}
                    onNodeClick={handleNodeClick}
                    fitView
                    panOnDrag={true}
                    zoomOnScroll={true}
                >
                </ReactFlowStyled>
            </div>
        </StyledContainer>
    )
}

export const ConversionsDiagram: React.FC = () => {
    const [startDate, setStartDate] = useState<string>(dayjs().subtract(1, 'day').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'))

    const handlePeriodChange = (startDate: Date, endDate: Date) => {
        setStartDate(dayjs(startDate).format('YYYY-MM-DD'))
        setEndDate(dayjs(endDate).format('YYYY-MM-DD'))
    }

    return (
        <ReactFlowProvider>
            <Row
                spacing={2}
                rowProps={{ justifyContent: 'flex-end' }}
                itemProps={{ xs: 12 }}
            >
                <PeriodPicker onChange={handlePeriodChange} />
            </Row>
            {
                startDate && endDate && (
                    <Component
                        startDate={startDate}
                        endDate={endDate}
                    />
                )
            }
        </ReactFlowProvider>
    )
}
