import {
    Box,
    Divider,
    ListItemIcon,
    MenuItem,
    MenuList,
    Popover,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Cached from '@material-ui/icons/Cached'
import Cloud from '@material-ui/icons/Cloud'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { observer } from 'mobx-react'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'

import type Version from '../../store/Version'
import type { MouseEvent as ReactMouseEvent } from 'react'


const useStyles = makeStyles((theme) => ({
    badge: {
        width: '4.8rem',
        textAlign: 'center',
        paddingTop: theme.spacing(.5),
        paddingBottom: theme.spacing(.5),
        borderRadius: 4,
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
    divider: {
        marginTop: 0,
        marginBottom: 0,
    },
    success: {
        backgroundColor: COLORS.SUCCESS,
        color: 'white',
    },
    info: {
        backgroundColor: COLORS.PRIMARY,
        color: 'white',
    },
    warning: {
        backgroundColor: '#ffb400',
        color: 'black',
    },
}))

const PERCENTAGES = [90, 80, 70, 60, 50, 40, 30, 20, 10]

type Props = {
    version: Version
    onChangeVersion: (percentage: number) => void | undefined | Promise<void>
}

const VersionCell: FC<Props> = observer((props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const [popoverEl, setPopoverEl] = useState<Element | null>(null)
    const { version, onChangeVersion } = props

    const handleVersionSettings = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
        setPopoverEl(event.currentTarget)
    }

    const handleClose = () => {
        setPopoverEl(null)
    }

    const handleDeploy = (percentage: number) => {
        onChangeVersion(percentage)
        setPopoverEl(null)
    }

    return (<>
        <div
            className={`${classes.badge}  ${classes[version.getVerbosity]}`}
            onClick={handleVersionSettings}
            title={version.name ?? ''}
        >
            { version.isRolledBack ? t('Deploy') : version.getPercentageLabel ?? 'n/a' }
        </div>
        <Popover
            id={`version_${version.id}`}
            open={!!popoverEl}
            anchorEl={popoverEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <MenuList>
                <MenuItem onClick={() => handleDeploy(100)}>
                    <ListItemIcon>
                        <CloudUpload fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Deploy</Typography>
                </MenuItem>
                {
                    PERCENTAGES.map((percentage) => {
                        return (
                            <Box key={percentage}>
                                <Divider />
                                <MenuItem onClick={() => handleDeploy(percentage)}>
                                    <ListItemIcon>
                                        <Cloud fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">
                                        Roll {percentage}%
                                    </Typography>
                                </MenuItem>
                            </Box>
                        )
                    })
                }
                <Divider />
                <MenuItem onClick={() => handleDeploy(0)}>
                    <ListItemIcon>
                        <Cached fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Rollback</Typography>
                </MenuItem>
            </MenuList>
        </Popover>
    </>)
})

export default VersionCell
