import { createLogger } from '@tivio/common'
import { tivio } from '@tivio/core-js'
import { TvChannel, UseTvChannel } from '@tivio/types'
import { useEffect, useState } from 'react'

import { useError } from './useError'


const logger = createLogger('useTvChannel')

/**
 * Get tv channel by id
 * @param tvChannelId - tv channel firestore id
 */
const useTvChannel: UseTvChannel = (tvChannelId: string) => {
    const { error, resetError, raiseError } = useError()
    const [ tvChannel, setTvChannel ] = useState<TvChannel | null>(null)

    useEffect(
        () => {
            (async () => {
                try {
                    resetError()

                    const tvChannel = await tivio.getTvChannelById(tvChannelId)

                    setTvChannel(tvChannel)
                }
                catch (e) {
                    raiseError(e as Error)
                    logger.error(e)
                }
            })()
        },
        [ tvChannelId ],
    )

    return {
        tvChannel,
        error,
        loading: !tvChannel && !error,
    }
}

export {
    useTvChannel,
}
