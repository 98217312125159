import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { MONETIZATION_FREQUENCY } from '@tivio/types'
import dayjs from 'dayjs'
import React, { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Monetization from '../../../store/Monetization'
import { useOrganization } from '../../hooks'

import { CreatePurchaseForm } from './types'
import { getDayJsUnitFromFrequency } from './util'

import type { FormikProps } from 'formik'


type Props = {
    formik: FormikProps<CreatePurchaseForm>
}

const frequencyMap: { [key in MONETIZATION_FREQUENCY]: string} = {
    [MONETIZATION_FREQUENCY.ANNUALLY]: 'Annually',
    [MONETIZATION_FREQUENCY.DAILY]: 'Daily',
    [MONETIZATION_FREQUENCY.HALF_ANNUALLY]: 'Half annually',
    [MONETIZATION_FREQUENCY.MONTHLY]: 'Monthly',
    [MONETIZATION_FREQUENCY.WEEKLY]: 'Weekly',
    [MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT]: 'One time',
}

export const MonetizationAutocomplete = memo((props: Props) => {
    const { formik } = props
    const { organization } = useOrganization()
    const [t] = useTranslation()

    const monetizations: Monetization[] = organization?.sharedMonetizations || []

    useEffect(() => {
        if (monetizations.length === 0) {
            organization?.getSharedMonetizations().then(() => {
                monetizations.push(...(organization?.sharedMonetizations) || [])
            })
        }
    }, [])

    const fieldProps = formik.getFieldProps('monetization')

    const renderName = (mon?: Monetization) => {
        return `${mon?.getName} (${mon?.getFrequency && frequencyMap[mon.getFrequency]})`
    }

    return (
        <Autocomplete
            id="monetization-input"
            options={monetizations}
            getOptionLabel={m => renderName(m)}
            value={fieldProps.value}
            onChange={(event, value: Monetization) => {
                formik.setFieldValue('monetization', value)
                formik.setFieldValue('video', null)
                if (value?.getType === 'subscription') {
                    const { count, unit } = getDayJsUnitFromFrequency(value)
                    formik.setFieldValue('endDate', dayjs(formik.values.startDate).add(count, unit).toDate())
                }
            }}
            onBlur={() => {
                formik.setFieldTouched('monetization')
            }}
            renderInput={(params) => <TextField
                {...params}
                label={t('Monetization')}
                variant="outlined"
                error={formik.touched.monetization && Boolean(formik.errors.monetization)}
                helperText={formik.touched.monetization && formik.errors.monetization}
            />}
        />
    )
})
