import { Container, Divider } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../store/Notification'
import { NotificationSubstitution } from '../../../store/NotificationSubstitution'
import { Template } from '../../../store/Template'
import { Column } from '../../uiLayout/Column'

import { EmailSettingsSection } from './shared/EmailSettingsSection'
import { Substitution } from './substitutions/Substitution'
import { TemplatePicker } from './TemplatePicker'
import { SubjectPreHeader } from './content/SubjectPreHeader'


interface Props {
    substitutions: NotificationSubstitution[];
    templates: Template[];
    notification: TivioNotification;
    getSubstitutionValue: (key: string) => string;
}

export const NotificationContent = ({ notification, substitutions, templates }: Props) => {
    const [t] = useTranslation()
    return (
        <Container maxWidth="lg">
            <TemplatePicker
                templates={templates}
                notification={notification}
            />
            <Divider />
            <SubjectPreHeader notification={notification} />
            <EmailSettingsSection label={t('Substitutions')}>
                <Column spacing={1}>
                    {substitutions.map((substitution) => (
                        <Substitution
                            key={substitution.key}
                            substitution={substitution}
                        />
                    ))}
                </Column>
            </EmailSettingsSection>
        </Container>
    )
}
