import { Box, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../static/enum'

import type Deploy from '../../store/Deploy'
import type Device from '../../store/Device'
import type Environment from '../../store/Environment'
import type Target from '../../store/Target'


const useStyles = makeStyles((theme) => ({
    deviceHeader: {
        fontSize: '1rem',
        color: COLORS.SECONDARY2,
        fontWeight: 400,
        textTransform: 'capitalize',
        cursor: 'pointer',
        '&:hover': {
            color: COLORS.SECONDARY2_HOVER,
        },
    },
    targetHeader: {
        fontSize: '1.2rem',
        fontWeight: 400,
        textTransform: 'capitalize',
    },
    versionHeader: {
        color: COLORS.SECONDARY2,
        fontSize: '1rem',
    },
    dividerHeader: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    dividerTarget: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    environmentHeader: {
        fontSize: '.98rem',
        textTransform: 'capitalize',
        alignSelf: 'flex-end',
    },
    divider: {
        height: 1,
    },
    addButton: {
        marginBottom: theme.spacing(3),
    },
    deviceWrapper: {
        flexWrap: 'nowrap',
        marginBottom: theme.spacing(5),
    },
}))

const ROW_HEIGHT = '2.8rem'

type Props = {
    target?: Target
    deploy: Deploy
    environments: Environment[]
    visibleEnvironmentsLabels?: boolean
    hasTopDivider: boolean
}

export const TargetInfo: FC<Props> = observer((props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const {
        target,
        environments,
        visibleEnvironmentsLabels,
        hasTopDivider,
    } = props

    if (!target) {
        return (<>Loading...</>)
    }

    const renderVersions = (device: Device) => environments.map((environment) => {
        return (
            <Grid
                item
                xs
                key={environment?.id}
            >
                <Box height={ROW_HEIGHT}>
                    {device.getSecretHashByEnv(environment.id!) ?? 'err'}
                </Box>
            </Grid>
        )
    })

    const renderDevices = () => target.getDevices.map((device, idx) => (
        <Box key={idx}>
            <Grid
                container
                spacing={10}
                direction="row"
                key={idx}
            >
                <Grid
                    xs={3}
                    item
                >
                    <Box
                        height={ROW_HEIGHT}
                    >
                        <Typography
                            className={classes.deviceHeader}
                            title={device.description ?? t('No description')}
                        >
                            { device.name }
                        </Typography>
                    </Box>
                </Grid>
                { renderVersions(device) }
            </Grid>
            <Divider className={classes.dividerTarget}/>
        </Box>
    ))

    const renderEnvironments = () => environments.map((environment) => (
        <Grid
            item
            xs
            key={environment?.name}
        >
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <Box
                        minWidth='6rem'
                        display='flex'
                        height={ROW_HEIGHT}
                    >
                        <Typography
                            className={classes.environmentHeader}
                            component="span"
                        >
                            { visibleEnvironmentsLabels ? environment.name ?? (<>&nbsp;</>) : (<>&nbsp;</>) }
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    ))

    return (<>
        { hasTopDivider ? (<Divider className={classes.dividerHeader}/>) : '' }

        <Grid
            container
            direction="row"
            spacing={10}
        >
            <Grid item xs={3}>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Typography
                            gutterBottom={true}
                            className={classes.targetHeader}
                        >
                            { target.name ?? t('no name') }
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            { renderEnvironments() }
        </Grid>

        <Divider className={classes.dividerHeader}/>

        <Grid
            container
            direction="column"
            className={classes.deviceWrapper}
        >
            <Grid
                item
            >
                { renderDevices() }
            </Grid>
        </Grid>
    </>)
})

export default TargetInfo
