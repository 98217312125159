import { IconButton, makeStyles } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import clsx from 'clsx'
import React from 'react'

import type { IconButtonProps } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    addIconButton: {
        backgroundColor: theme.palette.primary.main,
        transform: 'translateX(-50%) translateY(-50%)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))

export const AddIconButton = ({ className, ...props }: IconButtonProps) => {
    const classes = useStyles()
    return (
        <IconButton
            size="small"
            className={clsx(classes.addIconButton, className)}
            {...props}
        >
            <Add />
        </IconButton>
    )
}
