import { useCallback, useEffect, useState } from 'react'

import { useLoading } from './useLoading'


interface LoaderResponse<TResult, TError> {
    loading: boolean,
    result: TResult | undefined
    error: TError | undefined
    refresh: () => void
}

export const useLoader = <
        TResponse,
        TError extends Error,
        TDependencies extends Array<unknown>
    >(
        promiseFn: (...deps: TDependencies) => Promise<TResponse>,
        dependencies: TDependencies,
    ): LoaderResponse<TResponse, TError> => {
    const [force, forceUpdate] = useState({})
    const { loading, startLoading, stopLoading } = useLoading(false)
    const [result, setResult] = useState<TResponse | undefined>(undefined)
    const [error, setError] = useState<TError | undefined>(undefined)

    const refresh = useCallback(() => forceUpdate({}), [])
    const callback = useCallback(() => promiseFn(...dependencies), [...dependencies, force])
    useEffect(() => {
        let active = true

        const fetchData = async () => {
            try {
                startLoading()
                const data = await callback()
                if (active) {
                    setResult(data)
                }
            } catch (error) {
                if (active) {
                    setError(error)
                }
            } finally {
                if (active) {
                    stopLoading()
                }
            }
        }

        fetchData()

        return () => {
            active = false
        }
    }, [callback])

    return { loading, result, error, refresh }
}
