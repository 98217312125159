const notEmptyFilter = <T>(value: T | null | undefined): value is T => {
    return value !== null && value !== undefined
}

const arraysDiff = <T>(oldArray: T[], newArray: T[]) => {
    const oldArraySet = new Set(oldArray)
    const newArraySet = new Set(newArray)
    const removal = [...newArray].filter((item: T) => !oldArraySet.has(item))
    const addition = [...oldArray].filter((item: T) => !newArraySet.has(item))
    return { removal, addition }
}

const getUniqueArray = <T=string>(arr: T[], fn?: ((item: T) => any)): T[] => {
    if (!fn) {
        return Array.from(new Set(arr))
    } else {
        return Array.from(
            arr.reduce((acc, item) => acc.set(fn(item), item), new Map()).values(),
        )
    }
}

/**
 * Asynchronously checks if at least one element in the array satisfies the provided testing function.
 * @param array The array to test.
 * @param predicate An async function to test for each element.
 * @returns A promise that resolves to `true` if the predicate function returns a truthy value for at least one element; otherwise, `false`.
 */
export async function asyncSome<T>(
    array: T[],
    predicate: (value: T, index: number, array: T[]) => Promise<boolean>
  ): Promise<boolean> {
    for (let index = 0; index < array.length; index++) {
      if (await predicate(array[index], index, array)) {
        return true;
      }
    }
    return false;
  }

const isEmpty = (arr: any[]): boolean => {
    return !arr || arr.length === 0
}

const isNotEmpty = (arr: any[]): boolean => !isEmpty(arr)

export {
    notEmptyFilter,
    arraysDiff,
    getUniqueArray,
    isEmpty,
    isNotEmpty,
}
