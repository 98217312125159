import {
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'


interface Props {
    onClick?: () => void
    commingSoonTooltip?: boolean
    caption: string
    Icon: any
}

const useStyles = makeStyles((theme) => ({
    dashboardIcon: {
        fontSize: theme.spacing(1),
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
}))

const MenuLink: React.FC<Props> = (props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    return (
        <ListItem
            onClick={() => props.onClick?.()}
            button
        >
            <ListItemIcon>
                <props.Icon/>
            </ListItemIcon>
            <ListItemText>
                <Typography variant="body2">
                    {props.caption}
                    {
                        props.commingSoonTooltip && (
                            <Tooltip
                                placement="top"
                                title={t('Coming soon')}
                            >
                                <FiberManualRecord
                                    color="secondary"
                                    className={classes.dashboardIcon}
                                />
                            </Tooltip>
                        )
                    }
                </Typography>
            </ListItemText>
        </ListItem>
    )
}

export {
    MenuLink,
}
