import {
    Box,
    Button,
    Grid,
    Link,
    makeStyles,
    Typography,
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import i18n from 'i18next'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as Yup from 'yup'

import Logger from '../../logger'
import store from '../../store'
import { alertError } from '../../utils/alert.utils'
import { goAnalyticsPage } from '../../utils/history.utils'
import AppInput from '../AppInput'

import { AUTH_TABS } from './AuthTabs'


type TLoginUserForm = {
    email: string,
    password: string
}

type TProps = {
    setCurrentTab: (tab: AUTH_TABS) => void
}

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(7),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(5),
        paddingLeft: theme.spacing(8),
    },
    link: {
        cursor: 'pointer',
        textTransform: 'uppercase',
    },
}))

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email required').email('Enter valid email'),
    password: Yup.string().required('Password required'),
})

const initialValues: TLoginUserForm = {
    email: '',
    password: '',
}

const logger = new Logger('AuthLogin')

/**
 * Login form component
 * Serves for user logging in into application
 */
const AuthLogin = observer((props: TProps) => {
    const [ loading, setLoading ] = useState(false)
    const { login } = store
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleSubmit = async (values: TLoginUserForm) => {
        setLoading(true)

        try {
            await login(values.email, values.password)

            goAnalyticsPage()
        }
        catch (e) {
            alertError(i18n.t('Wrong email or password'))
            logger.error(e)
        }
        finally {
            setLoading(false)
        }
    }

    const goToForgottenPassword = (event: any) => {
        event.preventDefault()

        props.setCurrentTab(AUTH_TABS.FORGOTTEN_PASSWORD)
    }

    return (
        <div className={classes.container}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                <Form>
                    <Grid
                        spacing={3}
                        container
                        direction="column"
                    >
                        <Grid item>
                            <AppInput
                                dataE2e="login-email"
                                label={t('Email')}
                                fullWidth
                                name="email"
                            />
                        </Grid>
                        <Grid item>
                            <AppInput
                                dataE2e="login-password"
                                label={t('Password')}
                                fullWidth
                                name="password"
                                secured
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                data-e2e="login-submit"
                                color="primary"
                                fullWidth
                                disabled={loading}
                                type="submit"
                                variant="contained"
                            >
                                {t('Sign in')}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption">
                                <Box
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    <Link
                                        className={classes.link}
                                        onClick={goToForgottenPassword}
                                        href="#"
                                        color="inherit"
                                    >
                                        {t('Forgot password?')}
                                    </Link>
                                </Box>
                            </Typography>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    )
})

export default AuthLogin
