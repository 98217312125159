import { JsonEditor } from 'json-edit-react'
import { set } from 'lodash'
import { useTranslation } from 'react-i18next/hooks'
import React, { useEffect } from 'react'

import { getFirestore } from '../../firebase/app'
import { DefaultPageHeader } from '../DefaultPageHeader'
import { StyledContainer } from '../DefaultPageContainer'

interface DocumentEditorProps<TPrintableDocument, TLoadedDocument> {
    collectionName: string
    documentId: string
    trimLoadedData: (data: TLoadedDocument) => TPrintableDocument
}

export const DocumentEditor = <TPrintableDocument, TLoadedDocument>({
    collectionName,
    documentId,
    trimLoadedData,
}: DocumentEditorProps<TPrintableDocument, TLoadedDocument>) => {
    const [t] = useTranslation()

    const [document, setDocument] = React.useState<Partial<TPrintableDocument> | null >(null)

    const loadDocument = async (id: string) => {
        const loadedDocument = await getFirestore().collection(collectionName).doc(id).get()
        const loadedDocumentData = loadedDocument.data() as TLoadedDocument

        setDocument(trimLoadedData(loadedDocumentData))
    }

    const updateDocument = async (newData: Partial<TLoadedDocument>) => {
        await getFirestore().collection(collectionName).doc(documentId).update(newData)
    }

    useEffect(() => {
        if (documentId) {
            loadDocument(documentId)
        }
    }, [documentId])

    return (
        <>
            <DefaultPageHeader title={t('Object Editor')} />
            <StyledContainer
                maxWidth="lg"
                $bottomGutter={true}
            >
                {
                    document ?
                        <JsonEditor
                            data={document}
                            onUpdate={({ newValue, path }) => {
                                updateDocument(set({}, path, newValue))
                            }}
                        />
                        : <p>{t('Loading...')}</p>
                }
            </StyledContainer>
        </>
    )
}
