import { createLogger } from '@tivio/common'
import { autorun } from 'mobx'
import { useEffect, useState } from 'react'


const logger = createLogger('useMobxStore')
logger.off()

/**
 * Subscribe to mobx store observable property
 * @param observableProperty - observable property
 */
const useObservable = <T>(observableProperty: () => T) => {
    const [state, setState] = useState<T>(observableProperty())

    useEffect(() => {
        logger.info('start observing', observableProperty)

        const disposer = autorun(() => {
            logger.info('observing value change', observableProperty())
            setState(observableProperty())
        })

        return () => {
            logger.info('stop observing', observableProperty)
            disposer()
        }
    }, [])

    return [state]
}

export { useObservable }
