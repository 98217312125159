import { Dialog, DialogContent, DialogTitle, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import React, { PropsWithChildren, useState } from 'react'
import styled from 'styled-components'

import { AppThemeProvider } from '../../AppThemeProvider'
import { DialogCloseButton } from '../DialogCloseButton'


type Props = {
    open: boolean
    onClose: () => void
    tabs: Array<{
        title: string,
        children: React.ReactNode
    }>
    contentWidth?: string
    dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const DialogContentStyled = styled(DialogContent) <{ width?: string }>`
    width: ${props => props.width ? props.width : '500px'};
`

export const MultiTabDialog = (props: PropsWithChildren<Props>) => {
    const [selectedTabIndex, setTabIndex] = useState<number>(0)

    const onTabChange = (event: any, newValue: string) => {
        setTabIndex(Number(newValue))
    }

    const { open, onClose, tabs, contentWidth, dialogWidth } = props
    return (
        <AppThemeProvider type="light">
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={dialogWidth}
            >
                <TabContext value={String(selectedTabIndex)} >
                    <DialogTitle>
                        <TabList
                            onChange={onTabChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {
                                tabs.map((tab, index) => {
                                    return <Tab
                                        key={index}
                                        label={tab.title}
                                        value={String(index)}
                                    />
                                })
                            }
                        </TabList>
                    </DialogTitle>
                    <DialogContentStyled width={contentWidth}>
                        <DialogCloseButton
                            onClick={onClose}
                        />
                        {
                            tabs.map((tab, index) => {
                                return (
                                    <TabPanel
                                        key={index}
                                        value={String(index)}
                                    >
                                        {tab.children}
                                    </TabPanel>
                                )
                            })
                        }
                    </DialogContentStyled>
                </TabContext>
            </Dialog>
        </AppThemeProvider>
    )
}
