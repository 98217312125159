import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import NavigateNext from '@material-ui/icons/NavigateNext'
import React, { useCallback } from 'react'

import { preventMouseActions } from '../../utils/mouse.utils'


export interface EpgControlArrowsProps {
    onGoBefore?: () => void
    onGoNext?: () => void
}

interface Props extends EpgControlArrowsProps {
    disabled: boolean
}

const useStyles = makeStyles((theme) => ({
    beforeButton: {
        position: 'absolute',
        left: -theme.spacing(2),
        top: '50%',
        transform: 'translateY(-50%) translateX(-100%)',
    },
    nextButton: {
        position: 'absolute',
        right: -theme.spacing(2),
        top: '50%',
        transform: 'translateY(-50%) translateX(100%)',
    },
}))

const EpgControlArrows = (props: Props) => {
    const classes = useStyles()

    const handleControlButtonClick = useCallback(
        (e: React.MouseEvent, action?: () => void) => {
            e.stopPropagation()
            action && action()
        },
        [],
    )

    return (
        <div
            onMouseMove={preventMouseActions}
            onMouseUp={preventMouseActions}
            onMouseDown={preventMouseActions}
        >
            <IconButton
                onClick={(e) => handleControlButtonClick(e, props.onGoBefore)}
                className={classes.beforeButton}
                disabled={props.disabled}
            >
                <NavigateBefore/>
            </IconButton>
            <IconButton
                onClick={(e) => handleControlButtonClick(e, props.onGoNext)}
                className={classes.nextButton}
                disabled={props.disabled}
            >
                <NavigateNext/>
            </IconButton>
        </div>
    )
}

export default EpgControlArrows
