import { LangCode } from '@tivio/types'

import { ConversionDataFromCloudFunction } from '../../components/analytics/conversions/types'
import { getFirestore, getFunctions, loggerFirestore } from '../app'


interface GetConversionOptions {
    organizationId: string
    startDate: string
    endDate: string
}

export const getConversions = async ({ organizationId, startDate, endDate }: GetConversionOptions) => {
    try {
        const getConversionAnalytics = getFunctions().httpsCallable('getConversionAnalytics')
        const response: { data: ConversionDataFromCloudFunction[] } = await getConversionAnalytics({
            organizationId,
            startDate,
            endDate,
        })

        loggerFirestore.info('Successfully fetched conversions.')
        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to create invite: ', e)
        throw new Error(e)
    }
}

export const getNamesForScreenIds = async (organizationId: string, defaultLanguage: LangCode) => {
    try {
        const documents = await getFirestore().collection(`organizations/${organizationId}/screens`).get()
        const screenNameMap: { [key: string]: string } = {}
        documents.docs.forEach((doc) => {
            const docData = doc.data()
            screenNameMap[docData.screenId] = docData.name[defaultLanguage]
        })
        return screenNameMap
    } catch (e) {
        loggerFirestore.error(`Failed to get document with id ${organizationId}`, e)
        throw new Error(e)
    }
}
