import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    color: #fff;
    font-family: Helvetica, Arial, sans-serif;
`

const Title = styled.div`
    font-size: 32px;
`

const StyledTime = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-left: 90px;
`

const Dimmed = styled.span`
    opacity: 0.7;
`

const numberToTwoDigits = (number: number) => {
    return (number < 10) ? `0${number}` : number.toString()
}

const formatTime = (ms: number) => {
    const seconds = Math.round(ms / 1000)
    const minutes = Math.floor(seconds / 60)
    const resOfSeconds = numberToTwoDigits(seconds % 60)

    return `${minutes}:${resOfSeconds}`
}

interface TimeProps {
    currentMs: number
    totalMs: number
}

const Time: FC<TimeProps> = ({ currentMs, totalMs }) => {
    if (!totalMs) {
        return null
    }

    const formattedCurrentTime = formatTime(currentMs)
    const formattedTotalTime = formatTime(totalMs)

    return (
        <StyledTime>
            {formattedCurrentTime}&nbsp;/&nbsp;{formattedTotalTime}
        </StyledTime>
    )
}

interface Props {
    name: string
    currentMs: number
    totalMs: number
}

export const TitleBar: FC<Props> = ({ name, currentMs, totalMs}) => {
    return (
        <Container>
            <Title>{ name }</Title>
            <Time currentMs={currentMs} totalMs={totalMs} />
        </Container>
    )
}
