import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'

import { LabelContainer, NodeContainer, NodeSublabel } from './NodeSharedComponents'

import type { Node } from 'reactflow'


const StyledLabel = styled.span`
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
    overflow: hidden;
    font-size: 14px;
    color: #ffffff;
    opacity: 1;
`

export default memo(({ data, selected }: Node) => {
    return (
        <div>
            <NodeContainer selected={data.selected || selected}>
                <Handle
                    type="target"
                    position={Position.Left}
                />
                <div>
                    <LabelContainer>
                        <StyledLabel>{data.label}</StyledLabel>
                    </LabelContainer>
                    <div>
                        <NodeSublabel>{`${data.targetId !== 'unknown' ? data?.totalCount : data.totalCount} / ${data.conversionRate}`}</NodeSublabel>
                    </div>
                </div>
                <Handle
                    type="source"
                    position={Position.Right}
                />
            </NodeContainer>
        </div>
    )
})
