import {
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import { AdProfileType } from '@tivio/firebase'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AdProfileDocumentWithId } from '../../firebase/firestore/converters'
import { ON_EACH_CHAPTER } from '../../static/constants'
import { DEFAULT_MIDROLL_INTERVAL } from '../../store/Monetization'
import { Modal } from '../modal'


const minToMs = (min: number) => min * 60 * 1000

const SELECTABLE_VALUES = [5, 8, 10, 15, 20, 25, 30, 35]

interface SelectIntervalProps {
    initialValue?: number | string
    handleChangeMidRollInterval: (interval: number | string) => void
}

const SelectInterval: FC<SelectIntervalProps> = ({ initialValue, handleChangeMidRollInterval }) => {
    const [ t ] = useTranslation()
    const [value, setValue] = useState(initialValue ?? DEFAULT_MIDROLL_INTERVAL)

    return (
        <Select
            value={value}
            onChange={(e) => {
                setValue(e.target.value as number | string)
                handleChangeMidRollInterval(e.target.value as number | string)
            }}
        >
            {SELECTABLE_VALUES.map((value, index) => (
                <MenuItem key={index} value={minToMs(value as number)}>
                    {`${t('Every')} ${value} ${t('Minutes')}`}
                </MenuItem>
            ))}
            <MenuItem value={ON_EACH_CHAPTER}>
                {t('On each chapter')}
            </MenuItem>
        </Select>
    )
}

const useStyles = makeStyles(() => ({
    icon: {
        fontSize: '1rem',
    },
    strategyRow: {
        padding: '15px 22px 5px 22px',
    },
    strategyAddProfile: {
        marginRight: '18px',
    },
    strategyAddProfileButton: {
        textTransform: 'uppercase',
    },
    strategyType: {
        flexGrow: 1,
    },
    strategyOverview: {
        padding: '0 20px',
        width: '285px',
    },
    addAdProfileItem: {
        cursor: 'pointer',
    },
}))

interface StrategyRowTitleProps {
    dbKey: string
    title: string
    numberOfAds: number
    midRollInterval?: number | string
    showSelectInterval?: boolean
    handleAddAdSlot: (name: string, adSlot: AdProfileDocumentWithId) => void
    // if is null, then disable delete button
    handleDeleteAdConfig: ((name: string) => void) | null
    handleChangeMidRollInterval?: (interval: number | string) => void
    adProfiles: AdProfileDocumentWithId[]
}

export const StrategyRowTitle: FC<StrategyRowTitleProps> = ({
    dbKey,
    title,
    numberOfAds,
    showSelectInterval,
    handleAddAdSlot,
    handleDeleteAdConfig,
    handleChangeMidRollInterval,
    midRollInterval,
    adProfiles,
}) => {
    const [ t ] = useTranslation()
    const classes = useStyles()
    const addButtonRef = useRef<HTMLButtonElement>(null)
    const [ isAddProfileOpen, setIsAddProfileOpen ] = useState(false)

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="baseline"
            className={classes.strategyRow}
        >
            <Grid
                item
                className={classes.strategyType}
            >
                <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid
                item
                className={classes.strategyOverview}
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                >
                    <Grid
                        item
                    >
                        {numberOfAds} {t('Ads')}
                    </Grid>
                    {showSelectInterval && handleChangeMidRollInterval && (
                        <Grid
                            item
                        >
                            <SelectInterval
                                initialValue={midRollInterval}
                                handleChangeMidRollInterval={handleChangeMidRollInterval}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid
                item
            >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        className={classes.strategyAddProfile}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            startIcon={<Add />}
                            innerRef={addButtonRef}
                            onClick={() => setIsAddProfileOpen(!isAddProfileOpen)}
                            className={classes.strategyAddProfileButton}
                            disabled={Boolean(!adProfiles.length)}
                        >{t('AD')}</Button>
                        <Modal
                            isOpen={isAddProfileOpen}
                            hidePopup={() => setIsAddProfileOpen(false)}
                            anchorEl={addButtonRef.current}
                        >
                            <List>
                                {adProfiles
                                    .filter(profile => dbKey === 'replacement' || profile.type !== AdProfileType.STREAM)
                                    .map((adProfile) => (
                                        <ListItem
                                            button
                                            className={classes.addAdProfileItem}
                                            onClick={() => {
                                                handleAddAdSlot(dbKey, adProfile)
                                                setIsAddProfileOpen(false)
                                            }}
                                        >
                                            <ListItemText>
                                                {adProfile.name}
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                            </List>
                        </Modal>
                    </Grid>
                    <Grid item>
                        <IconButton
                            size="small"
                            onClick={() => handleDeleteAdConfig?.(dbKey)}
                            disabled={!handleDeleteAdConfig}
                        >
                            <Delete className={classes.icon}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
