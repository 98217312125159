import { ButtonBase, makeStyles, Typography } from '@material-ui/core'
import { LangCode } from '@tivio/types'
import React from 'react'

import { Article } from '../../../store/Article'
import { Post } from '../../../store/Post'
import { goArticlePage, goVideoPage } from '../../../utils/history.utils'
import { getTranslation } from '../../../utils/translate.utils'

import type { ButtonBaseProps } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    button: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        width: '100%',
        textAlign: 'left',
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
    },
    thumbnail: {
        width: 86,
        height: 48,
        flex: 'none',
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.palette.grey[300],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    articleInfo: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        gap: theme.spacing(0.5),
        width: '100%',
        overflow: 'hidden',
    },
    rightComp: {
        flex: 'none',
    },
    title: {
        fontWeight: 500,
    },
    description: {
        fontSize: 14,
        opacity: 0.54,
    },
}))

interface Props extends ButtonBaseProps {
    article: Article | Post
    languages?: LangCode[]
    rightComp?: React.ReactNode
    onClick?: () => void
}

export const ArticleItem = ({ article, languages, rightComp, onClick }: Props) => {
    const classes = useStyles()
    const handleGoDetail = () => {
        if ('type' in article && article.type === 'VIDEO') {
            goVideoPage(article.id)
        } else {
            goArticlePage(article.id)
        }
    }
    return (
        <ButtonBase
            className={classes.button}
            onClick={onClick ?? handleGoDetail}
        >
            <div
                className={classes.thumbnail}
                style={{ backgroundImage: `url(${article.cover})` }}
            />
            <div className={classes.articleInfo}>
                <Typography
                    className={classes.title}
                    noWrap
                >
                    {getTranslation(article.name, languages)}
                </Typography>
                <Typography
                    className={classes.description}
                    noWrap
                >
                    {getTranslation(article.description ?? {}, languages)}
                </Typography>
            </div>
            <div className={classes.rightComp}>
                {rightComp}
            </div>
        </ButtonBase>
    )
}
