import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { DefaultPageContainer } from '../../DefaultPageContainer'
import { UsersList } from '../../user/UsersList'


export const UsersPage = () => {
    const [ t ] = useTranslation()

    return (
        <DefaultPageContainer
            title={t('Users')}

        >
            <UsersList />
        </DefaultPageContainer>

    )
}
