import { OutlinedInput } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'

import { SubstitutionProps } from './types'


// TODO: We can have LinkSubstitution for validation of URL
export const TextSubstitution = observer(({ substitution }: SubstitutionProps) => {
    return (
        <OutlinedInput
            multiline
            margin="dense"
            value={substitution.value}
            onChange={(event) => {
                substitution.value = event.target.value
            }}
        />
    )
})
