import { Fade, Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React from 'react'

import VirtualMarker from '../../store/VirtualMarker'
import { secondsToMilliseconds } from '../../utils/time.utils'
import { useSeekbarStyles } from '../player/styles.seekbar'

import { SeekbarProgram } from './EpgMarkerEditor'


export interface Props {
    width: number
    program: SeekbarProgram
    markers?: VirtualMarker[]
}

const useStyles = makeStyles({
    emptySpace: {
        opacity: '.5 !important',
    },
    tile: {
        position: 'relative',
        zIndex: 3,
    },
    tileTitle: {
        fontWeight: 'bolder',
    },
    tileCaption: {
        opacity: .5,
    },
    hideOverflow: {
        overflow: 'hidden',
    },
})

const EpgProgram = observer((props: Props) => {
    const { program } = props

    const classes = useStyles()
    const classesSeekbar = useSeekbarStyles()

    return (
        <Grid
            item
            className={classes.hideOverflow}
            style={{
                width: `${props.width}%`,
            }}
        >
            <Fade in={true}>
                <Paper
                    className={
                        `${classesSeekbar.seekbarTile}
                            ${program.getType === 'empty-program' && classes.emptySpace}`
                    }
                >
                    <div className={classes.tile}>
                        <Typography
                            variant="body1"
                            className={classes.tileTitle}
                            noWrap
                        >
                            {program.getName}
                        </Typography>
                        <Grid
                            item
                            className={classes.hideOverflow}
                        >
                            <Typography
                                variant="caption"
                                className={classes.tileCaption}
                            >
                                {dayjs(new Date(secondsToMilliseconds(program.getFrom))).format('HH:mm')}
                            </Typography>
                        </Grid>
                    </div>
                </Paper>
            </Fade>
        </Grid>
    )
})

export default EpgProgram
