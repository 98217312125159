import { MARKER_TYPES } from '../static/enum'
import Marker, { IMarker } from '../store/Marker'
import VirtualMarker from '../store/VirtualMarker'


const NOT_INTERVAL_MARKERS = [
    MARKER_TYPES.START,
    MARKER_TYPES.END,
    MARKER_TYPES.BET,
    MARKER_TYPES.BREAK,
    MARKER_TYPES.TASTING,
]

const NOT_POINT_MARKERS = [
    MARKER_TYPES.INTRO,
    MARKER_TYPES.OUTRO,
]

export const noIntervalMarkersFilter = (markerType: MARKER_TYPES) => {
    return !NOT_POINT_MARKERS.includes(markerType)
}

export const noPointMarkersFilter = (markerType: MARKER_TYPES) => {
    return !NOT_INTERVAL_MARKERS.includes(markerType)
}

export const noChapterMarker = (markerType: MARKER_TYPES) => {
    return markerType !== MARKER_TYPES.CHAPTER
}

/**
 * Filter out all markers that are not suitable for virtual video.
 */
export const noVirtualMarker = (markerType: MARKER_TYPES) => {
    return markerType !== MARKER_TYPES.CUT && markerType !== MARKER_TYPES.TRAILER
}

export const onlyProgramBreak = (marker: IMarker) => {
    return marker.getType === MARKER_TYPES.PROGRAM_BREAK
}

/**
 * Checks if given marker is of EPG's ads.
 *
 * @param type Marker type.
 * @returns True if marker is EPG advertisement.
 */
export const isAdMarker = (type: string) => {
    return type === 'AD_SEGMENT'
        || type === 'SELF_PROMO'
        || type === 'SPONSOR'
}

/**
 * Converts EPG's marker type to human readable string.
 * String remains empty if marker type is not recognized.
 *
 * @param type Marker type.
 * @returns Inverval name for EPG.
 */
export const typeToName = (type?: MARKER_TYPES) => {
    switch (type) {
        case MARKER_TYPES.AD:
            return 'Ads'
        case MARKER_TYPES.AD_SEGMENT:
            return 'Ads'
        case MARKER_TYPES.PROGRAM_BREAK:
            return 'Break'
        case MARKER_TYPES.INTRO:
            return 'Intro'
        case MARKER_TYPES.OUTRO:
            return 'Outro'
        case MARKER_TYPES.START:
            return 'START'
        case MARKER_TYPES.END:
            return 'END'
        case MARKER_TYPES.TASTING:
            return 'Tasting'
        default:
            return ''
    }
}

/**
 * Checks if given marker is virtual meaning it's not Mobx entity.
 *
 * @param marker Object to be checked.
 * @returns True if marker is virtual.
 */
export const isVirtualMarker = (marker?: IMarker): marker is VirtualMarker => {
    return marker instanceof VirtualMarker
}

/**
 * Checks if given marker is Mobx entity meaning it's not virtual marker.
 *
 * @param marker Object to be checked.
 * @returns True if marker is entity of Mobx store.
 */
export const isEntityMarker = (marker?: IMarker): marker is Marker => {
    return marker instanceof Marker
}

export const isPointMarker = (markerType: MARKER_TYPES) => {
    return NOT_INTERVAL_MARKERS.includes(markerType)
}

export const getMarkerMean = (from: number, to: number) => {
    if (from === to) {
        return from
    }
    else {
        return (from + to) / 2
    }
}
