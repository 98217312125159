import { Drawer, Grid, List, makeStyles } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TimelineIcon from '@material-ui/icons/Timeline'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { COLORS } from '../../static/enum'
import {
    goAnalyticsPage,
    goApplicationsPage,
    goDeployPage,
    goScreensPage,
    goServicesPage,
    goUsersPage,
    goVideosPage,
    goWidgetsPage,
} from '../../utils/history.utils'

import MenuApplication from './MenuApplication'
import MenuContent from './MenuContent'
import MenuDivider from './MenuDivider'
import { MenuDropdown } from './MenuDropdown'
import { MenuEpgChannels } from './MenuEpgChannels'
import MenuHeader from './MenuHeader'
import { MenuLink } from './MenuLink'
import MenuMonetization from './MenuMonetization'
import MenuSettings from './MenuSettings'
import MenuUser from './MenuUser'


type TProps = {
    children?: React.ReactElement
}

const MENU_WIDTH = 258

const useStyles = makeStyles({
    appBarShift: {
        width: `calc(100% - ${MENU_WIDTH}px)`,
        marginLeft: MENU_WIDTH,
    },
    drawer: {
        width: MENU_WIDTH,
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        borderWidth: 0,
        boxShadow: '5px 0 23px 0 rgba(0, 0, 0, 0.25)',
        width: MENU_WIDTH,
    },
    divider: {
        backgroundColor: COLORS.DRAWER_BORDER,
    },
})

const GridContainerStyled = styled(Grid)`
    height: 100%;
`

/**
 * Menu component
 * Serves for navigation in app
 */
function Menu(props: TProps) {
    const classes = useStyles()
    const [t] = useTranslation()

    return (
        <>
            <div className={classes.appBarShift}>
                {props.children}
            </div>

            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
                variant="permanent"
            >
                <GridContainerStyled
                    container
                    direction="row"
                    data-e2e="menu-container"
                    alignContent="space-between"
                >
                    <Grid
                        item
                        xs={12}
                    >
                        <MenuHeader />
                        <MenuDivider />

                        <MenuUser />
                        <MenuDivider />

                        <List>
                            <MenuLink
                                onClick={goAnalyticsPage}
                                caption={t('Analytics')}
                                Icon={TimelineIcon}
                            />

                            <MenuContent />

                            <MenuEpgChannels />
                            <MenuMonetization />
                            <MenuApplication />

                            <MenuLink
                                Icon={PeopleAltIcon}
                                caption={t('Users')}
                                onClick={goUsersPage}
                            />
                            <MenuLink
                                Icon={ExitToAppIcon}
                                caption={t('Deploy')}
                                onClick={goDeployPage}
                            />

                            <MenuLink
                                Icon={GroupWorkIcon}
                                caption={t('Notifications')}
                                onClick={goServicesPage}
                            />
                        </List>

                    </Grid>
                    <Grid
                        item
                        xs={12}
                    >
                        <MenuDivider />
                        <MenuSettings />
                    </Grid>
                </GridContainerStyled>
            </Drawer>
        </>
    )
}

export default Menu
