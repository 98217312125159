import { CircularProgress, Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { Row } from '../../uiLayout/Row'

import { ListingItem, ListingRow, ListingRowProps } from './ListingRow'


interface Props extends ListingRowProps {
    items: ListingItem[]
    showLoadingSpinner?: boolean
}

const Description = styled.div`
    color: #FFF8;
    font-style: italic;
`

/**
 * @deprecated Please don't use this component anymore. It's too restrictive and force you to use observer.
 * For styling (item hover effect), you can use useListItemStyles() hook from ../styles.ts.
 */
const Listing: React.FC<Props> = observer((props) => {
    const [ t ] = useTranslation()

    const isEmpty = !props.showLoadingSpinner && props.items?.length === 0

    return (
        isEmpty ? <Description>{t('No items found')}</Description> : (
            <Grid
                container
                spacing={3}
                direction="column"
                data-e2e="listing-container"
            >
                {
                    props.items.map(
                        item => (
                            <Grid
                                key={item.getId}
                                item
                                onClick={() => props.onClick(item)}
                                xs={12}
                            >
                                <ListingRow
                                    key={item.getId}
                                    item={item}
                                    onDeleteClick={props.onDeleteClick}
                                    onUpdateClick={props.onUpdateClick}
                                    onClick={props.onClick}
                                />
                            </Grid>
                        ),
                    )
                }
                {
                    props.showLoadingSpinner && (
                        <Grid
                            item
                            xs
                        >
                            <Row rowProps={{ justifyContent: 'center' }}>
                                <CircularProgress color="inherit"/>
                            </Row>
                        </Grid>
                    )
                }
            </Grid>
        )

    )
},
)

export
{
    Listing,
}

