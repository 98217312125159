import { PricesField } from '@tivio/firebase'
import { useEffect, useState } from 'react'

import Monetization, { ExtendedPaidMonetizationVariant } from '../../../store/Monetization'

/**
 * Use for managing state of updated monetization variants' prices until until updatePrices (in DB) is called
 *
 * @param monetization monetization
 * @param updateProxy function from useUpdatedEntity() hook needed for managing if component is updated
 */
export const useVariantPrices = (monetization: Monetization | null, updateProxy: (callback: () => any) => void) => {
    const [ variantPrices, setVariantPrices ] = useState<{[variantId: string]: PricesField | null}>({})

    /**
     * Updates variants' prices if monetization changes
     */
    useEffect(
        () => {
            if (monetization) {
                const newVariantPrices = (monetization.variants as ExtendedPaidMonetizationVariant[])
                    .map(variant => ({[variant.id]: variant.prices ?? null}))
                    .reduce((a, b) => ({...a, ...b}))
                setVariantPrices(newVariantPrices ?? {})
            }
        },
        [ monetization ],
    )
    /**
     * Receives variants list and pass prices from component state to them. Returns updated list
     * It's needed for showing variants with updated prices.
     */
    const constructVariantsWithUpdatedPrices = (
        variants: ExtendedPaidMonetizationVariant[],
    ): ExtendedPaidMonetizationVariant[] => {
        return variants?.map(variant => ({
            ...variant,
            prices: variantPrices[variant.id] ?? {},
        })) ?? []
    }

    /**
     * Updates prices in DB
     */
    const updatePrices = async () => {
        if (monetization) {
            const updatedVariants = constructVariantsWithUpdatedPrices(monetization.variants)
            await monetization.updateVariants(updatedVariants)
        }
    }

    /**
     * Handles prices change and save it to inner state.
     *
     * @param variantName name of variant which prices were changed
     * @param newPrices new prices value
     */
    const handlePricesChange = (variantName: string, newPrices: PricesField | null) => {
        updateProxy(() => setVariantPrices(oldPrices => ({...oldPrices, [variantName]: newPrices ?? {} })))
    }

    return {
        variantPrices,
        updatePrices,
        constructVariantsWithUpdatedPrices,
        handlePricesChange,
    }
}
