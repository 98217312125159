import { InviteState } from '@tivio/firebase'

import Invite from '../../store/Invite'
import { getFirestore, getFunctions, loggerFirestore } from '../app'

import { inviteConverter } from './converters'

import type { OrganizationDocument, OrganizationMemberRoles } from '@tivio/firebase'
import type firebase from 'firebase/app'


export interface TInviteFirestore {
    email: string
    role: OrganizationMemberRoles
    organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>
    state: InviteState
}

export interface TCreateInviteInput {
    email: string
    organizationId: string
    firstName: string
    lastName: string
}

interface FinishSignUpInput {
    token: string
    fName: string
    lName: string
    email: string
    password: string
}

interface InviteInfoResponse {
    fName: string
    lName: string
    email: string
}

interface FinishSignUpResponse {
    signInToken: string
}

export const getInvitesCollection = () => {
    return getFirestore().collection('invites').withConverter(inviteConverter)
}

export const createInvite = async (inviteInput: TCreateInviteInput) => {
    try {
        const createInvite = getFunctions().httpsCallable('createInvite')

        const response: { data: string } = await createInvite(inviteInput)
        const invite = await getInvitesCollection().doc(response.data).get()

        loggerFirestore.info('Invite created with id: ', invite.id)

        return invite.ref
    }
    catch (e) {
        loggerFirestore.error('Failed to create invite: ', e)
        throw new Error(e)
    }
}

export const deleteInvite = async (invite: Invite) => {
    try {
        await getInvitesCollection().doc(invite.getId).update({ state: InviteState.CLOSED })

        loggerFirestore.info('Invite deleted')
    }
    catch (e) {
        loggerFirestore.error('Failed to delete invite', e)
        throw new Error(e)
    }
}

export const updateInvite = async (invite: Invite, inviteData: Partial<TInviteFirestore>) => {
    try {
        await getInvitesCollection().doc(invite.getId).update(inviteData)

        loggerFirestore.info('Invite updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update invite:', e)
        throw new Error(e)
    }
}

export const finishSignUp = async (inputData: FinishSignUpInput) => {
    try {
        const finishSignUp = getFunctions().httpsCallable('finishSignUp')

        const { data } = await finishSignUp(inputData)
        
        loggerFirestore.info('Invite finished sign up')
        return data as FinishSignUpResponse
    } catch (e) {
        loggerFirestore.error('Failed to finish sign up:', e)
        throw new Error(e)
    }
}

export const getInviteInfo = async (token: string) => {
    try {
        const inviteInfo = getFunctions().httpsCallable('inviteInfo')

        const { data } = await inviteInfo({ token })

        loggerFirestore.info('Invite info received')
        return data as InviteInfoResponse
    } catch (e) {
        loggerFirestore.error('Failed to get invite info:', e)
        throw new Error(e)
    }
}
