import { IconButton, MenuItem, Select } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'

import { Row } from '../../../uiLayout/Row'

import type { SurveyFilterProps } from './types'


interface Props {
    filterProps: SurveyFilterProps
    availableFilters: {
        type: string
        label: string
        component: React.FC<SurveyFilterProps<any>>
    }[]
    type: string
    onChangeType: (type: string) => void
    onRemove: () => void
}

export const FilterWrapper = ({ filterProps, availableFilters, type, onChangeType, onRemove }: Props) => {
    const Component = availableFilters.find((filter) => filter.type === type)?.component
    return (
        <Row spacing={1} rowProps={{ alignItems: 'center' }}>
            <IconButton onClick={onRemove}>
                <Delete />
            </IconButton>
            <Select
                value={type}
                onChange={(e) => onChangeType(e.target.value as string)}
            >
                {availableFilters.map((filter) => (
                    <MenuItem
                        key={filter.type}
                        value={filter.type}
                    >
                        {filter.label}
                    </MenuItem>
                ))}
            </Select>
            {Component && <Component {...filterProps} />}
        </Row>
    )
}
