import { Container, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { goAdProfileCreatePage, goAdProfileDetailPage } from '../../utils/history.utils'
import { Listing } from '../common/listing/Listing'
import { ListingItem } from '../common/listing/ListingRow'
import { ListingRowNameWithColor } from '../common/listing/ListingRowNameWithColor'

import type { AdProfileDocumentWithId } from '../../firebase/firestore/converters'


const useStyles = makeStyles(() => ({
    icon: {
        fontSize: '1rem',
    },
    text: {
        fontSize: '0.8125rem',
        textTransform: 'uppercase',
    },
    container: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))


const AddAdProfile: FC<{ handleAddAdProfile: () => void }> = ({ handleAddAdProfile }) => {
    const classes = useStyles()
    const [ t ] = useTranslation()

    return (
        <div className={classes.container}>
            <IconButton size="small" onClick={handleAddAdProfile}>
                <Add className={classes.icon}/><span className={classes.text}>{t('Add Profile')}</span>
            </IconButton>
        </div>
    )
}

const ListingContainer = styled.div`
    margin-top: 25px;
`

interface AdProfilesTabProps {
    adProfiles: AdProfileDocumentWithId[]
    handleDeleteAdProfile: (id: string) => Promise<void>
}

export const AdProfilesTab: FC<AdProfilesTabProps> = ({ adProfiles, handleDeleteAdProfile }) => {

    return (
        <Container maxWidth="lg">
            <AddAdProfile
                handleAddAdProfile={goAdProfileCreatePage}
            />
            <ListingContainer>
                <Listing
                    items={adProfiles.map((adProfile) => ({
                        getId: adProfile.id,
                        getName: adProfile.name,
                        extraColumns: [
                            adProfile.description ?? '',
                        ],
                        extraProps: {
                            color: adProfile.color,
                        },
                        renderName: (item: ListingItem) => <ListingRowNameWithColor item={item}/>,
                    }))}
                    onClick={(item) => goAdProfileDetailPage(item.getId)}
                    onUpdateClick={(item) => goAdProfileDetailPage(item.getId)}
                    onDeleteClick={(item) => handleDeleteAdProfile(item.getId)}
                />
            </ListingContainer>
        </Container>
    )
}
