import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useIsAdminRole } from '../../../store/hooks/member'
import { DefaultPageTabs, PageTab } from '../../DefaultPageTabs'
import { OrganizationsTab, PresetsTab, TagTypesTab } from '../../settings'


export const SettingsPage = () => {
    const [ t ] = useTranslation()
    const isAdmin = useIsAdminRole()

    const sectionTabs = useMemo(() => [
        isAdmin ? {
            component: <OrganizationsTab />,
            title: t('Organizations'),
            dataE2e: 'settings-tab-organizations',
        } : null,
        {
            component: <PresetsTab />,
            title: t('Asset presets'),
            dataE2e: 'settings-tab-asset-presets',
        },
        {
            component: <TagTypesTab />,
            title: t('Tag types'),
            dataE2e: 'settings-tab-tag-types',
        },
    ].filter(Boolean) as PageTab[], [t, isAdmin])

    return (
        <DefaultPageTabs
            defaultTabIndex={1}
            tabs={sectionTabs}
        />
    )
}
