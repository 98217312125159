import { Box } from '@material-ui/core'
import React, { PropsWithChildren, useCallback } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next/hooks'

import { Logger } from '../logger'

import { useAlert } from './hooks/uiHooks/useAlert'


const logger = new Logger('Dropzone')

interface Props extends PropsWithChildren {
    onFiles: (files: File[]) => void,
    maxSize?: number
    accept?: string[] // file formats to accept eg [ '.mp4', '.jpeg', ... ]
}

/**
 * Dropzone component
 * Serves for handling user files input
 */
const AppDropzone: React.FC<Props> = (props) => {
    const [ t ] = useTranslation()
    const { showSuccess, showError } = useAlert()

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            props.onFiles(acceptedFiles)
            showSuccess(t('Files added'))
        },
        [ props.onFiles ],
    )

    const onDropRejected = useCallback(
        (fileRejections: FileRejection[]) => {
            const fileName = fileRejections[0]?.file.name ?? ''
            const errorMessage = fileRejections[0]?.errors[0]?.message ?? 'File upload failed'

            logger.error('File upload failed', fileRejections)

            // TODO i18n error messages
            showError(`${fileName} ${t(errorMessage)}`)
        },
        [],
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: props.accept,
        onDropRejected,
    })

    return (
        <>
            <input {...getInputProps()}/>
            <Box {...getRootProps()}>
                {props.children}
            </Box>
        </>
    )
}

export default AppDropzone
