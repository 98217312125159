import { tivio } from '@tivio/core-js'
import { GenericOnCallError } from '@tivio/types'
import { useCallback, useState } from 'react'

/**
 * @see usePurchaseRecovery in @tivio/sdk-react
 */
export function usePurchaseRecovery() {
    const [purchaseRecoveryResult, setPurchaseRecoveryResult] = useState<string | undefined>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<GenericOnCallError | undefined>()

    const purchaseRecovery = useCallback((monetizationId: string) => {
        (async () => {
            try {
                setPurchaseRecoveryResult(undefined)
                setError(undefined)
                setLoading(true)
                const result = await tivio.purchaseRecovery(monetizationId)
                setPurchaseRecoveryResult(result)
            } catch (error) {
                setError(error)
            } finally {
                setLoading(false)
            }
        })()
    }, [])

    return {
        purchaseRecoveryResult,
        loading,
        error,
        purchaseRecovery,
    }
}
