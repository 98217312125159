import firebase from 'firebase'

import Organization from '../../store/Organization'
import { FirestoreDocumentReference, getFirestore, loggerFirestore } from '../app'

import { secretsConverter } from './converters'
import { getEnvironmentDoc, TEnvironmentFirestore } from './environment'

import type { FirestoreQuery, FirestoreQueryDocumentSnapshot } from '../app'
import type { DeviceFirestore } from './device'
import type { TVersionFirestore } from './versions'
import type { OrganizationDocument } from '@tivio/firebase'


export type TSecretFirestore = {
    name: string
    description: string
    target: string
    environmentRef: firebase.firestore.DocumentReference<TEnvironmentFirestore>
    deviceRef: firebase.firestore.DocumentReference<DeviceFirestore>
    versionsRefs: firebase.firestore.DocumentReference<TVersionFirestore>[]
}

export const getSecretsCollection = (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
: FirestoreQuery<TSecretFirestore> => {
    return parent.collection('secrets')
        .orderBy('name')
        .withConverter(secretsConverter)
}

export const getSecretsQuery = async (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
: Promise<FirestoreQueryDocumentSnapshot<TSecretFirestore>[]> => {
    const secretsRefs = await getSecretsCollection(parent).get()
    return secretsRefs.docs
}

export const addSecret = async (
    organizationId: string,
    environmentId: string,
    data: Omit<TSecretFirestore, 'environmentRef'>,
): Promise<FirestoreDocumentReference<TSecretFirestore>> => {
    try {
        const environmentDoc = getEnvironmentDoc(organizationId, environmentId)
        if (!environmentDoc) {
            throw new Error('Environment does not exists')
        }
        const environmentRef = (await environmentDoc).ref

        const organizationRef = getFirestore()
            .doc(`organizations/${organizationId}`) as FirestoreDocumentReference<OrganizationDocument>

        const collection = organizationRef
            .collection('secrets')
            .withConverter(secretsConverter)
        const result = await collection.add({ ...data, environmentRef })

        await addSecretIdToAttributesOfOrganization(organizationRef, result.id)

        loggerFirestore.info('Secret has been created')
        return result
    }
    catch (e) {
        loggerFirestore.error('Failed to create Secret', e)
        throw new Error(e)
    }
}

export const updateSecret = async (organizationId: string, secretId: string, data: Partial<TSecretFirestore>)
: Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/secrets`)
        await collection.doc(secretId).set(data, { merge: true })
        loggerFirestore.info('Secret has been updated')
    }
    catch (e) {
        loggerFirestore.error('Failed to update Secret', e)
        throw new Error(e)
    }
}

export const removeSecret = async (organizationId: string, secretId: string)
: Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/secrets`)
        await collection.doc(secretId).delete()
        loggerFirestore.info('Secret has been removed', secretId)
    }
    catch (e) {
        loggerFirestore.error('Failed to remove Secret', e)
        throw new Error(e)
    }
}

export const addVersionRef = async (
    organizationId: string,
    secretId: string,
    ref: FirestoreDocumentReference<TVersionFirestore>,
): Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/secrets`)
        await collection.doc(secretId).update({ versionsRefs: firebase.firestore.FieldValue.arrayUnion(ref) })
        loggerFirestore.info('Secret Version reference has been added')
    }
    catch (e) {
        loggerFirestore.error('Failed to add Secret Version reference', e)
        throw new Error(e)
    }
}

export const removeVersionRef = async (
    organizationId: string,
    secretId: string,
    ref: FirestoreDocumentReference<TVersionFirestore>,
): Promise<void> => {
    try {
        const collection = getFirestore().collection(`organizations/${organizationId}/secrets`)
        await collection.doc(secretId).update({ versionsRefs: firebase.firestore.FieldValue.arrayRemove(ref) })
        loggerFirestore.info('Secret Version reference has been removed')
    }
    catch (e) {
        loggerFirestore.error('Failed to remove Secret Version reference', e)
        throw new Error(e)
    }
}


const addSecretIdToAttributesOfOrganization = async (
    organizationRef: FirestoreDocumentReference<OrganizationDocument>,
    secretId: string,
) => {
    const organizationData = (await organizationRef.get()).data() as OrganizationDocument
    const organization = new Organization(organizationRef, organizationData)
    await organization.addSecret(secretId)
}
