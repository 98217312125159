import { LangCode } from '@tivio/types'

import { useAvailableLanguages, useLanguage, usePlayerWrapper } from './playerHooks'


export const usePlayerLanguage = () => {
    const [playerWrapper] = usePlayerWrapper()
    const currentLanguage = useLanguage()
    const availableLanguages = useAvailableLanguages()

    const changeLanguage = (langCode: LangCode) => {
        return playerWrapper?.changeLanguage(langCode)
    }

    return {
        availableLanguages,
        currentLanguage,
        changeLanguage,
    }
}
