import { useEffect, useState } from 'react'

import Widget from '../../../store/Widget'

import { useOrganization } from './useOrganization'


const useWidget = (widgetId: string) => {
    const { organization } = useOrganization()
    const [ notFound, setNotFound ] = useState(false)
    const [ widget, setWidget ] = useState<Widget | null>(null)

    useEffect(
        () => {
            if (organization) {
                const widget = organization.widgets.find(widget => widget.getId === widgetId)

                if (!widget) {
                    setNotFound(true)
                }
                else {
                    setWidget(widget)
                }
            }
        },
        [ organization ],
    )

    return {
        notFound,
        widget,
    }
}

export {
    useWidget,
}
