import { Container, IconButton } from '@material-ui/core'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import { TvChannelDocument } from '@tivio/firebase'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { getFirestore } from '../../../firebase/app'
import { TV_PROGRAM_DATE_FORMAT, TvProgram } from '../../../store/TvProgram'
import { useTvPrograms } from '../../hooks/dataHooks/useTvPrograms'
import { useDates } from '../../hooks/general'
import { UserLayoutParams } from '../../router/layout/UserLayout'

import { EpgScheduleColumn } from './EpgScheduleColumn'
import { EpgScheduleEditorZoom } from './EpgScheduleEditorZoom'


const OuterContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    margin-top: -33px;
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    margin-bottom: -50px;
    align-items: center;
`

const ColumnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const ZoomContainer = styled.div`
    margin-top: -34px;
    align-self: flex-end;
`


/**
 * Editor of EPG schedule (contains grid of EPG items divided by dates).
 */
export const EpgScheduleEditor = observer(() => {
    const [t] = useTranslation()
    const { epgChannelId } = useParams<UserLayoutParams>()
    const { dates, moveBack: moveDateBack, moveForward: moveDateForward } = useDates(5)
    const [tvChannel, setTvChannel] = useState<TvChannelDocument | null>(null)

    const { tvPrograms } = useTvPrograms({
        from: dates[0],
        to: dates[dates.length - 1].endOf('day'),
        tvChannelIds: [epgChannelId],
        loadVideos: true,
    })

    const programsByDays: { [date: string]: TvProgram[] } = tvPrograms.reduce((programsByDays, tvProgram) => {
        programsByDays[tvProgram.fromDateString] = [
            ...(programsByDays[tvProgram.fromDateString] ?? []),
            tvProgram,
        ]

        // these programs should be shown in two day columns
        // we expect programs over only one midnight
        if (tvProgram.isOverMidnight) {
            programsByDays[tvProgram.toDateString] = [
                // it's ok to not clone tvProgram here
                tvProgram,
                ...(programsByDays[tvProgram.toDateString] ?? []),
            ]
        }

        return programsByDays
    }, {} as { [date: string]: TvProgram[] })

    const loadTvChannel = async () => {
        const tvChannel = await getFirestore().collection('tvChannels').doc(epgChannelId).get()
        setTvChannel(tvChannel.data() as TvChannelDocument ?? null)
    }

    useEffect(() => {
        loadTvChannel()
    }, [])

    return (
        <OuterContainer maxWidth="lg">
            <ZoomContainer>
                <EpgScheduleEditorZoom />
            </ZoomContainer>
            <ButtonsContainer>
                <IconButton
                    size="small"
                    onClick={() => moveDateBack()}
                    title={t('Move date back')}
                >
                    <ChevronLeft fontSize="small" />
                </IconButton>
                <IconButton
                    size="small"
                    onClick={() => moveDateForward()}
                    title={t('Move date forward')}
                >
                    <ChevronRight fontSize="small" />
                </IconButton>
            </ButtonsContainer>
            <ColumnsContainer>
                {dates.map((date, index) => (
                    <EpgScheduleColumn
                        tvChannel={tvChannel}
                        key={date.format(TV_PROGRAM_DATE_FORMAT)}
                        index={index}
                        date={date}
                        tvChannelId={epgChannelId}
                        items={programsByDays[date.format(TV_PROGRAM_DATE_FORMAT)] ?? []}
                        active={date.isSame(dayjs(), 'day')}
                    />
                ))}
            </ColumnsContainer>
        </OuterContainer>
    )
})
