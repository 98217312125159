import { TvProgramVideoDocument, VideoMonetizationField } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router'

import { UserLayoutParams } from '../layout/UserLayout'
import { DocumentEditor } from "../../documentEditor/DocumentEditor"

type TPrintableDocument = Partial<Omit<TvProgramVideoDocument, 'monetizations'> & {
    monetizations: Omit<VideoMonetizationField, 'monetizationRef'>[]
} | null>
type TLoadedDocument = TvProgramVideoDocument
const trimLoadedData = (data: TLoadedDocument): TPrintableDocument => {
    const {
        channelRef,
        sectionRef,
        organizationRef,
        originalVideoRef,
        monetizationAccessRefs,
        organizationAccessRefs,
        allowedRebalancingVideoRefs,
        linkedVideos,
        monetizations,
        created,
        tvChannelRef,
        processing,
        tags,
        ...printableData
    } = data

    return {
        ...printableData,
        monetizations: monetizations?.map((m) => {
            const { monetizationRef, ...loggable } = m
            return loggable
        }),
    }
}

export const VideoEditPage = observer(() => {
    const { videoId } = useParams<UserLayoutParams>()

    return (
        <DocumentEditor<TPrintableDocument, TLoadedDocument>
            collectionName="videos"
            documentId={videoId}
            trimLoadedData={trimLoadedData}
        />
    )
})
