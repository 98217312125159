import { GetVideoAnalyticsRequest, GetVideoAnalyticsResponse, GetVideoAnalyticsViewsRequest, GetVideoAnalyticsViewsResponse } from '@tivio/types'
import dayjs from 'dayjs'

import { getFunctions, loggerFirestore } from '../app'


const CURRENT_VIEWS_INTERVAL_MS = 600_000 as const // 10 minutes
const LIMIT_ANALYTICS_PRE_AGGREGATED_RESULTS = 500 as const
const LIMIT_ERRORS = 20 as const
const ANALYTICS_TIMEOUT = 120_000 as const

export const getVideoAnalyticsViews = async (contentId: string, source: GetVideoAnalyticsViewsRequest['source']) => {
    try {
        const getAnalytics = getFunctions().httpsCallable('getVideoAnalyticsViews')
        const response: { data: GetVideoAnalyticsViewsResponse } = await getAnalytics({
            contentId,
            source,
            currentViewsIntervalMs: CURRENT_VIEWS_INTERVAL_MS,
            timestamp: new Date().getTime(),
        } satisfies GetVideoAnalyticsViewsRequest)

        loggerFirestore.info('Successfully fetched video views analytics.')

        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to fetch video views analytics: ', e)
        throw new Error(e)
    }
}

export const getVideoAnalytics = async (contentId: string, source: GetVideoAnalyticsRequest['source'], userId: GetVideoAnalyticsRequest['userId']) => {
    try {
        const getAnalytics = getFunctions().httpsCallable('getVideoAnalytics', { timeout: ANALYTICS_TIMEOUT })
        const response: { data: GetVideoAnalyticsResponse } = await getAnalytics({
            contentId,
            source,
            userId,
            limit: LIMIT_ANALYTICS_PRE_AGGREGATED_RESULTS,
            errorLimit: LIMIT_ERRORS,
            errorTimestampFrom: dayjs().add(-10, 'minute').toDate().getTime(),
            timestamp: new Date().getTime(),
        } satisfies GetVideoAnalyticsRequest)

        loggerFirestore.info('Successfully fetched video views analytics.')

        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to fetch video views analytics: ', e)
        throw new Error(e)
    }
}
