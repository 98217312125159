import { languages } from '@tivio/types'
import i18n, { InitOptions, Module, TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'


const config: InitOptions = {
    resources: Object.assign({}, ...(languages.map(lang => {
        let translation
        try {
            translation = require(`../static/locales/${lang}/translation.json`)
            return { [lang]: { translation } }
        } catch (e) {
            return undefined
        }
    }))),
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
}

/**
 * @deprecated this doesn't work, as a workaround you could pass translators from end apps as a parameter.
 */
class Translator {
    constructor() {
        this.init()
    }

    t(text = '', options?: TOptions) {
        return i18n.t(text, options)
    }

    init = () => {
        i18n
            .use(initReactI18next as Module)
            .init(config)
    }
}

export default new Translator()
