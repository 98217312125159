import { tivio } from '@tivio/core-js'
import { MONETIZATION_FREQUENCY, PurchasableMonetization } from '@tivio/types'
import { useEffect, useState } from 'react'


// TODO join this hook with useOrganizationSubscriptions hook from core-react-dom!!!
/**
 *
 * @param onlyPurchasableSubscriptions When it is true, it returns only enabled subscriptions.
 * @param videoId If it is not defined returns all subscriptions except one time payment.
 * When it is set, returns only subscriptions which is described in tagIds for video.
 * @returns Array of subscriptions according to set parameters
 */
export const useOrganizationSubscriptions = (onlyPurchasableSubscriptions?: boolean, videoId?: string) => {
    const [subscriptions, setSubscriptions] = useState<PurchasableMonetization[]>([])

    useEffect(
        () => {
            (async () => {
                if (subscriptions.length === 0) {
                    const subscriptions = await tivio.organization?.getSubscriptions()
                    let newSubscriptions = subscriptions

                    if (onlyPurchasableSubscriptions) {
                        newSubscriptions = subscriptions?.filter((subscription) => subscription.purchaseDisabled !== true)
                    }

                    if (!videoId) {
                        newSubscriptions = newSubscriptions?.filter((subscription) => subscription.frequency !== MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT)
                    } else {
                        const videoTagIds = (await tivio.getVideoById(videoId))?.tags.map(tag => tag.id) ?? []
                        newSubscriptions = newSubscriptions?.filter((subscription) =>
                            subscription.tagIds.some(tagId => videoTagIds.includes(tagId)),
                        )
                    }

                    if (newSubscriptions) {
                        setSubscriptions(newSubscriptions)
                    }
                }
            })()
        },
        [],
    )

    return {
        subscriptions,
    }
}
