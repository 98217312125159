import { MenuItem, Select } from '@material-ui/core'
import React, { ChangeEvent } from 'react'

import { MonetizationsPeriod } from '../../types/date'
import { Row } from '../uiLayout/Row'

import { getDateRangeForMonetizationsPeriod, MONETIZATIONS_PERIOD_OPTIONS } from './services'


interface Props {
    isSubscriptionsCount?: boolean;
    monetizationsPeriod: MonetizationsPeriod;
    setMonetizationsPeriod: (monetizationsPeriod: MonetizationsPeriod) => void;
    onChange: (startDate: Date, endDate: Date, period: MonetizationsPeriod) => void;
}

export const MonetizationsPeriodPicker: React.FC<Props> = ({ isSubscriptionsCount, monetizationsPeriod, setMonetizationsPeriod, onChange }) => {
    const handleMonetizationsPeriodChange = (event: ChangeEvent<{ value: unknown }>) => {
        const selectedMonetizationsPeriod = event.target.value as MonetizationsPeriod
        const { startDate: innerStartDate, endDate: innerEndDate } = getDateRangeForMonetizationsPeriod(selectedMonetizationsPeriod)
        setMonetizationsPeriod(selectedMonetizationsPeriod)
        onChange(innerStartDate.toDate(), innerEndDate.toDate(), selectedMonetizationsPeriod)
    }

    return (
        <Row
            spacing={2}
            rowProps={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
            itemProps={{ xs: 2 }}
        >
            <Select
                id="monetizations-period-picker"
                labelId="monetizations-period-picker-label"
                value={monetizationsPeriod}
                onChange={handleMonetizationsPeriodChange}
                fullWidth
            >
                {MONETIZATIONS_PERIOD_OPTIONS.filter((item) => isSubscriptionsCount ? item.isSubscriptionsCount : !item.isSubscriptionsCount).map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Row>
    )
}
