import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import React, { FC, PropsWithChildren } from 'react'

import { lightTheme } from '../../static/theme'


const useStyles = makeStyles(() => ({
    popover: {
        zIndex: 1000,
    },
}))

export interface ModalProps extends PropsWithChildren {
    isOpen: boolean
    hidePopup: () => void
    anchorEl: HTMLDivElement | HTMLButtonElement | null
    placement?: 'top-start' | 'top' | 'top-end' | 'left-start' | 'left' | 'left-end' | 'right-start' | 'right' | 'right-end' | 'bottom-start' | 'bottom' | 'bottom-end'
}

export const Modal: FC<ModalProps> = ({ isOpen, hidePopup, anchorEl, placement, children }) => {
    const classes = useStyles()

    if (!anchorEl) {
        return null
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <Popper
                className={classes.popover}
                anchorEl={anchorEl}
                disablePortal
                open={isOpen}
                transition
                placement={placement}
            >
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={() => hidePopup()}>
                                {children}
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </ThemeProvider>
    )
}
