import {
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react'


interface UseBottomListenerProps {
    onBottom?: () => void
    onCloseToBottom?: () => void
    closeThreshold?: number
}

/**
 * Calls given callbacks {@param onBottom @param onCloseToBottom}
 * when user scrolls to the bottom or close to the bottom of the page or scrollable container.
 * Being close to the bottom is determined by the value of {@param closeThreshold}.
 * Usage:
 * ```
 * useBottomListener(...) // for window scroll
 * const containerRef = useBottomListener(...) // for scrollable container
 * ```
 *
 * @returns Reference to the scrollable container.
 */
const useBottomListener = <T extends HTMLElement>({
    onBottom,
    onCloseToBottom,
    closeThreshold = 400,
}: UseBottomListenerProps): RefObject<T> => {
    const [ isBottom, setIsBottom ] = useState(false)
    const [ isCloseToBottom, setIsCloseToBottom ] = useState(false)
    const containerRef = useRef<T>(null)

    // https://gist.github.com/enqtran/25c6b222a73dc497cc3a64c090fb6700
    const handleScroll = useCallback(
        () => {
            if (containerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current
                const bottom = Math.round(scrollHeight - scrollTop - clientHeight) // Round, coz scrollHeight is not always an integer for some reason.
                setIsBottom(bottom <= 0)
                setIsCloseToBottom(bottom <= closeThreshold)
            } else {
                const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight
                const windowBottom = windowHeight + window.pageYOffset

                const body = document.body
                const html = document.documentElement
                const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)

                setIsBottom(windowBottom >= docHeight)
                setIsCloseToBottom(windowBottom + closeThreshold >= docHeight)
            }
        },
        [ closeThreshold ],
    )

    useEffect(
        () => {
            const scrollElm = containerRef.current

            if (scrollElm) {
                scrollElm.addEventListener(
                    'scroll',
                    handleScroll,
                )
            } else {
                window.addEventListener(
                    'scroll',
                    handleScroll,
                )
            }

            handleScroll() // Initial call after page load.

            return () => {
                if (scrollElm) {
                    scrollElm.removeEventListener(
                        'scroll',
                        handleScroll,
                    )
                } else {
                    window.removeEventListener(
                        'scroll',
                        handleScroll,
                    )
                }
            }
        },
        [ handleScroll, containerRef.current ],
    )

    useEffect(
        () => {
            isCloseToBottom && onCloseToBottom?.()
        },
        [ isCloseToBottom ],
    )

    useEffect(
        () => {
            isBottom && onBottom?.()
        },
        [ isBottom ],
    )

    return containerRef
}

export default useBottomListener
