import { useContext } from 'react'

import { CustomAppHistoryContext } from '../context'


export const useCustomAppHistory = () => {
    const context = useContext(CustomAppHistoryContext)
    if (!context) {
        throw new Error('CustomAppHistoryContext is missing a provider.')
    }

    return context
}
