import { generateConsecutiveDates } from '@tivio/common'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'


const DAY_SHIFT_KEY = 'dayShift'

/**
 * Returns array of consecutive dates (with time 00:00:00) from given start date of given length.
 * Also provides methods to "move" dates array back or forward.
 *
 * @param length length of dates array
 * @param from start date
 */
export const useDates = (length: number, from: Dayjs = dayjs()) => {
    const location = useLocation()
    const initialDates = generateConsecutiveDates(length, from)
    const [ dates, setDates ] = useState(initialDates)
    const query = new URLSearchParams(location.search)

    const getDayShiftFromUrl = () => {
        const dayShiftStr = query.get(DAY_SHIFT_KEY)
        const dayShift = dayShiftStr ? parseInt(dayShiftStr) : 0
        return dayShift
    }

    const shiftDates = (shiftBy: number) => {
        const newDayShift = getDayShiftFromUrl() + shiftBy
        query.set(DAY_SHIFT_KEY, newDayShift.toString())
        location.search = query.toString()
        window.history.replaceState({}, '', `${location.pathname}?${query.toString()}`)
        setDates(dates => dates.map(date => date.add(shiftBy, 'day')))
    }

    useEffect(() => {
        const shiftBy = getDayShiftFromUrl()
        setDates(dates => dates.map(date => date.add(shiftBy, 'day')))
    }, [])

    const moveBack = (daysBack = 1) => shiftDates(-daysBack)
    const moveForward = (daysForward = 1) => shiftDates(daysForward)

    return {
        dates,
        moveBack,
        moveForward,
    }
}
