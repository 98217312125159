import dayjs from 'dayjs'

import { DateRange, MonetizationsPeriod, Period } from '../../types/date'


interface PeriodOption {
    label: string;
    value: Period | MonetizationsPeriod;
    isSubscriptionsCount?: boolean;
}

export const PERIOD_OPTIONS: PeriodOption[] = [
    { label: 'Today', value: Period.TODAY },
    { label: 'Yesterday', value: Period.YESTERDAY },
    { label: 'Last 7 days', value: Period.LAST_SEVEN_DAYS },
    { label: 'Last 30 days', value: Period.LAST_THIRTY_DAYS },
    { label: 'Custom range days', value: Period.CUSTOM_RANGE_DAYS },
]

export const MONETIZATIONS_PERIOD_OPTIONS: PeriodOption[] = [
    { label: 'Last 7 days', value: MonetizationsPeriod.LAST_SEVEN_DAYS },
    { label: 'Last 7 days', value: MonetizationsPeriod.LAST_SEVEN_DAYS, isSubscriptionsCount: true },
    { label: 'Last 30 days', value: MonetizationsPeriod.LAST_THIRTY_DAYS, isSubscriptionsCount: true },
    { label: 'Last 90 days', value: MonetizationsPeriod.LAST_NINETY_DAYS, isSubscriptionsCount: true },
    { label: 'Last 4 weeks', value: MonetizationsPeriod.LAST_FOUR_WEEKS },
    { label: 'Last 52 weeks', value: MonetizationsPeriod.LAST_FIFTY_TWO_WEEKS },
]

export const getNormalizedDateRange = (options: DateRange) => {
    return {
        startDate: dayjs(options.startDate).startOf('day'),
        endDate: dayjs(options.endDate).endOf('day'),
    }
}

export const getDateRangeForPeriod = (period: Period) => {
    const todayDate = dayjs().toISOString()
    switch (period) {
        case Period.YESTERDAY: {
            const yesterdayDate = dayjs().subtract(1, 'day').toISOString()
            return getNormalizedDateRange({ startDate: yesterdayDate, endDate: yesterdayDate })
        }
        case Period.LAST_SEVEN_DAYS: {
            const sevenDaysAgoDate = dayjs().subtract(7, 'day').toISOString()
            return getNormalizedDateRange({ startDate: sevenDaysAgoDate, endDate: todayDate })
        }
        case Period.LAST_THIRTY_DAYS: {
            const thirtyDaysAgoDate = dayjs().subtract(30, 'day').toISOString()
            return getNormalizedDateRange({ startDate: thirtyDaysAgoDate, endDate: todayDate })
        }
        case Period.TODAY:
        case Period.CUSTOM_RANGE_DAYS:
        default:
            return getNormalizedDateRange({ startDate: todayDate, endDate: todayDate })
    }
}

export const getDateRangeForMonetizationsPeriod = (monetizationsPeriod: MonetizationsPeriod) => {
    const todayDate = dayjs().toISOString()
    switch (monetizationsPeriod) {
        case MonetizationsPeriod.LAST_SEVEN_DAYS: {
            const sevenDaysAgoDate = dayjs().subtract(7, 'day').toISOString()
            return getNormalizedDateRange({ startDate: sevenDaysAgoDate, endDate: todayDate })
        }
        case MonetizationsPeriod.LAST_THIRTY_DAYS: {
            const last30DaysDate = dayjs().subtract(30, 'day').toISOString()
            return getNormalizedDateRange({ startDate: last30DaysDate, endDate: todayDate })
        }
        case MonetizationsPeriod.LAST_NINETY_DAYS: {
            const last90DaysDate = dayjs().subtract(90, 'day').toISOString()
            return getNormalizedDateRange({ startDate: last90DaysDate, endDate: todayDate })
        }
        case MonetizationsPeriod.LAST_FIFTY_TWO_WEEKS: {
            const fiftyTwoWeeksAgoDate = dayjs().subtract(52, 'week').startOf('week').toISOString()
            return getNormalizedDateRange({ startDate: fiftyTwoWeeksAgoDate, endDate: todayDate })
        }
        case MonetizationsPeriod.LAST_FOUR_WEEKS:
        default: {
            const thirtyDaysAgoDate = dayjs().subtract(30, 'day').startOf('week').toISOString()
            return getNormalizedDateRange({ startDate: thirtyDaysAgoDate, endDate: todayDate })
        }
    }
}
