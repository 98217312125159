import { VideoController } from '@tivio/core-js'
import { PlayerEngineInterface } from '@tivio/types'
import { createContext } from 'react'


interface PlayerContextValue {
    video: {
        controller: VideoController | null
        engine: PlayerEngineInterface | null
    }
}

export const PlayerContext = createContext<PlayerContextValue>({
    video: {
        controller: null,
        engine: null,
    },
})
