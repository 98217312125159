import { tivio } from '@tivio/core-js'
import { PurchaseStatus } from '@tivio/types'

import { useUser } from './useUser'


const useIsMonetizationPurchased = () => {
    const { user } = useUser()

    const isOneOfSubscriptionPurchased = (subscriptionIds: string[]) => {
        if (!user || !tivio.isSignedIn()) {
            return false
        }

        return user.purchasedSubscriptions.some(purchase =>
            subscriptionIds.some(id => id === purchase.monetizationId),
        )
    }

    return {
        isOneOfSubscriptionPurchased,
    }
}

export {
    useIsMonetizationPurchased,
}
