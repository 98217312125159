import {
    Button,
    Grid,
    makeStyles,
    ThemeProvider,
    Typography,
} from '@material-ui/core'
import { auth } from '@tivio/firebase'
import { Form, Formik } from 'formik'
import i18n from 'i18next'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'
import * as Yup from 'yup'

import { finishSignUp, getInviteInfo } from '../../../firebase/firestore/invite'
import Logger from '../../../logger'
import { lightTheme } from '../../../static/theme'
import { alertError } from '../../../utils/alert.utils'
import { goAnalyticsPage } from '../../../utils/history.utils'
import AppInput from '../../AppInput'
import { useStore } from '../../hooks/dataHooks/useStore'


interface SignUpForm {
    fName: string;
    lName: string;
    email: string;
    password: string;
}

const useStyles = makeStyles((theme) => ({
    center: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    container: {
        backgroundColor: theme.palette.common.white,
        maxWidth: 480,
        padding: theme.spacing(4),
    },
    title: {
        color: 'black',
        fontSize: 24,
        marginBottom: theme.spacing(1),
    },
    subtitle: {
        color: 'black',
        fontSize: 14,
        opacity: 0.65,
        marginBottom: theme.spacing(4),
    },
}))

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email required').email('Enter valid email'),
    password: Yup.string().required('Password required'),
})

const logger = new Logger('SignUpPage')

/**
 * Signup form component
 * Serves to finish registration process
 */
export const SignUpPage = observer(() => {
    const { inviteId } = useParams<{ inviteId: string }>()
    const { store } = useStore()
    const [isLoading, setIsLoading] = useState(false)
    const [initialValues, setInitialValues] = useState<SignUpForm | null>(null)
    const [t] = useTranslation()
    const classes = useStyles()

    const handleSubmit = async (values: SignUpForm) => {
        setIsLoading(true)

        try {
            const { signInToken } = await finishSignUp({
                ...values,
                token: inviteId,
            })

            await store.signInWithCustomToken(signInToken)
            goAnalyticsPage()
        } catch (e) {
            alertError(i18n.t('Your registration failed. Please try again later'))
            logger.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const init = async () => {
            const inviteInfo = await getInviteInfo(inviteId)
            setInitialValues({
                ...inviteInfo,
                password: '',
            })
        }

        init()
    }, [])

    if (initialValues === null) {
        return (
            <div className={classes.center}>
                <div className={classes.container}>
                    <Typography variant="body1" className={classes.subtitle}>{t('Please wait, we are creating your account')}</Typography>
                </div>
            </div>
        )
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <div className={classes.center}>
                <div className={classes.container}>
                    <Typography
                        className={classes.title}
                        variant="h6"
                    >
                        {t('Welcome to Tivio Studio')}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="body1"
                    >
                        {t('To continue, we need information about your company and confirming terms and conditions.')}
                    </Typography>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        validationSchema={validationSchema}
                    >
                        <Form>
                            <Grid
                                spacing={3}
                                container
                                direction="column"
                            >
                                <Grid item>
                                    <AppInput
                                        label={t('First name')}
                                        fullWidth
                                        name="fName"
                                    />
                                </Grid>
                                <Grid item>
                                    <AppInput
                                        label={t('Last name')}
                                        fullWidth
                                        name="lName"
                                    />
                                </Grid>
                                <Grid item>
                                    <AppInput
                                        label={t('Email')}
                                        fullWidth
                                        name="email"
                                    />
                                </Grid>
                                <Grid item>
                                    <AppInput
                                        label={t('Password')}
                                        fullWidth
                                        name="password"
                                        secured
                                        autoComplete="new-password"
                                    />
                                </Grid>
                                <Grid item>
                                    <Button
                                        data-e2e="login-submit"
                                        color="primary"
                                        fullWidth
                                        disabled={isLoading}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {t('Next step')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </Formik>
                </div>
            </div>
        </ThemeProvider>
    )
})
