import { Container } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TagType } from '../../../store/TagType'
import { Header, TActions } from '../../common'
import { useDialogState } from '../../hooks'

import { EditTagTypeDialog } from './EditTagTypeDialog'
import { useTagTypes, useTagTypesCrud } from './hooks'
import { TagTypesList } from './TagTypesList'


export const TagTypesTab = () => {
    const [ t ] = useTranslation()
    const { isDialogOpen, openDialog, closeDialog } = useDialogState()

    const tagTypes = useTagTypes()
    const { create } = useTagTypesCrud()

    const actions: TActions = useMemo(() => (
        {
            create: {
                title: t('Add tag type'),
                onCreate: openDialog,
            },
        }
    ), [])

    return (
        <Container maxWidth="lg">
            <EditTagTypeDialog
                open={isDialogOpen}
                onCancel={closeDialog}
                onConfirm={create}
            />
            <Header
                title={t('Tag types')}
                actions={actions}
            />
            <TagTypesList tagTypes={tagTypes}/>
        </Container>

    )
}
