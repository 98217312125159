import { alpha, Button, makeStyles } from '@material-ui/core'
import React from 'react'

import type { ButtonProps } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({
    changeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        textTransform: 'none',
        padding: theme.spacing(0.5, 1),
        backgroundColor: alpha(theme.palette.common.black, 0.25),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.black, 0.5),
        },
    },
}))

export const ChangeButton = (props: ButtonProps) => {
    const classes = useStyles()
    return (
        <Button
            variant="text"
            size="small"
            className={classes.changeButton}
            {...props}
        >
            {props.children}
        </Button>
    )
}
