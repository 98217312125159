import { Grid, GridProps, GridSpacing } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

import { notEmptyFilter } from '../../utils/array.utils'


interface Props extends PropsWithChildren {
    columnProps?: GridProps
    itemProps?: GridProps
    spacing?: GridSpacing
    getKey?: (index: number) => string
}

const Column: React.FC<Props> = (props) => {
    const { getKey, itemProps, spacing, columnProps, children } = props

    return (
        <Grid
            container
            direction="column"
            spacing={spacing ?? 0}
            {...columnProps}
        >
            {React.Children.map(
                React.Children.toArray(children).filter(notEmptyFilter),
                (child, index) => (
                    <Grid
                        key={getKey?.(index) ?? `${index}-child`}
                        item
                        {...itemProps}
                    >
                        {child}
                    </Grid>
                ),
            )}
        </Grid>
    )
}

export {
    Column,
}
