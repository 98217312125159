import {
    Button,
    Dialog,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import {
    createLogger,
    numberComparator,
    SortDirection,
    stringComparator,
    uniqueFilter,
} from '@tivio/common'
import { TagDocument } from '@tivio/firebase'
import {
    AvailableSeason,
    AvailableSeasonField,
    LangCode,
    TagMetadataFieldDefinition,
    TagMetadataField as TagMetadataFieldInterface,
    TagMetadataFieldType,
    TagSimplicityType,
    Translation,
} from '@tivio/types'
import { useFormik } from 'formik'
import { keyBy } from 'lodash'
import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as Yup from 'yup'

import { AssetResizeResult } from '../../firebase/firestore/types'
import { Tag } from '../../store/Tag'
import { TagType } from '../../store/TagType'
import { enumToOptions } from '../../utils/enum.utils'
import { getInputProps } from '../../utils/formik.utils'
import { AppSelect, appSelectConverter } from '../AppSelect'
import { AppThemeProvider } from '../AppThemeProvider'
import { AssetUploader } from '../asset/assetUploader'
import { MultiFormikField } from '../common/formField/MultiFormikField'
import { useTags } from '../hooks/dataHooks/useTags'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useAllTagTypes } from '../settings/tagTypes/hooks'
import { Column } from '../uiLayout/Column'
import UpdatableTypography from '../UpdatableTypography'

import { TagMetadataField } from './TagMetadataField'
import { TagsAutocomplete } from './TagsAutocomplete'
import { TranslationForm } from './TranslationForm'

import type firebase from 'firebase/app'
import type { FieldProps } from 'formik'


interface Props {
    tag: Tag
    onClose?: (isSaved: boolean) => void
}

interface TagForm {
    tagId: string
    tagTypeId?: string
    metadata: TagMetadataFieldInterface[]
    name: Translation
    description?: string
    simplicity: TagSimplicityType
    simpleTagsRefs: firebase.firestore.DocumentReference<TagDocument>[]
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const DialogTitle = styled(Typography)`
    font-weight: bold;
`

const logger = createLogger('TagDialog')

const validationSchema = Yup.object().shape({
    tagTypeId: Yup.string().required('This field is required'),
    tagId: Yup.string().required('This field is required'),
})

// TODO move that functions to separate file
const getMetadataFieldKey = (field: TagMetadataFieldDefinition | TagMetadataFieldInterface) => {
    return field.key
}

type TagTypeMetadataMap = { [key: string]: TagMetadataFieldDefinition }
type TagMetadataMap = { [key: string]: TagMetadataFieldInterface }

const getMetadataFieldType = (
    key: string,
    tagType: TagType | undefined,
    tag: Tag,
    tagTypeMetadataMap: TagTypeMetadataMap,
    tagMetadataMap: TagMetadataMap,
) => {
    if (!tagTypeMetadataMap[key]) {
        logger.warn(
            `Tag ${tag.name} (id: ${tag.id}) contains metadata field '${key} that is not defined in tag type `
            + `${tagType?.name} (id: ${tagType?.id})'`,
        )
        // key must exist at least in one of tagTypeMetadataMap and tagMetadataMap
        return tagMetadataMap[key]?.type
    } else {
        // this key does not necessarily exist in tagMetadataMap
        // in that case it mean that metadata field just not filled for this tag, but defined in tag type
        return tagTypeMetadataMap[key]?.type
    }
}

const TagDialog: React.FC<Props> = observer(({ tag, onClose }) => {
    const [t] = useTranslation()
    const { findTagsByTagId, findAllNotComposedTags } = useTags()
    const tagTypes = useAllTagTypes()

    const [tagOptions, setTagOptions] = useState<Tag[]>([])

    // composed tags are now not updatable
    const formDisabled = tag?.composed

    const { showError, showSuccess } = useAlert()

    const findTagTypeById = useCallback((id?: string): TagType | undefined => {
        return tagTypes.find(type => type.id === id)
    }, [tagTypes])

    const getMetadata = useCallback((tagTypeId?: string) => {
        if (!tagTypeId) {
            return []
        }
        const tagType = findTagTypeById(tagTypeId)

        const tagTypeMetadataMap = keyBy(tagType?.metadata ?? [], getMetadataFieldKey) as TagTypeMetadataMap
        const tagMetadataMap = keyBy(tag.metadata, getMetadataFieldKey) as TagMetadataMap

        const metadataKeys = [...Object.keys(tagTypeMetadataMap), ...Object.keys(tagMetadataMap)]
            .filter(uniqueFilter)

        return metadataKeys.map((key: string) => ({
            key,
            type: getMetadataFieldType(key, tagType, tag, tagTypeMetadataMap, tagMetadataMap),
            value: tagMetadataMap?.[key]?.value,
        })) as TagMetadataFieldInterface[]
    }, [tag, findTagTypeById])

    const form = useFormik<TagForm>({
        validationSchema,
        validateOnChange: false,
        validate: async (values) => {
            const suspectedTags = (await findTagsByTagId(values.tagId)) || [] as Tag[]
            if (suspectedTags.length > 1 || suspectedTags.some(suspectedTag => suspectedTag.id !== tag.id)) {
                return {
                    tagId: t('Tag with this id already exists'),
                }
            }
        },
        initialValues: {
            tagTypeId: tag.tagTypeRef?.id ?? '',
            tagId: tag.tagId,
            metadata: getMetadata(tag.tagTypeRef?.id ?? ''),
            description: tag.description,
            name: Object.values(LangCode).reduce<Translation>((result, langCode) => {
                result[langCode] = tag.getNameTranslation[langCode] ?? ''
                return result
            }, {}),
            simplicity: tag.simplicity,
            simpleTagsRefs: tag.simpleTagsRefs,
        },
        onSubmit: async (values) => {
            try {
                if (values.simplicity === TagSimplicityType.COMPOSED) {
                    const tagId = values.tagId
                    const { tagTypeId, ...neededValues } = values
                    // Here is not creation of composed tag, but update of originally simple tag to composed.
                    // Because now dummy tags of simple tags (with tagTypeRef) are created on 'Add tag' button click (TODO it will be reworked)
                    // Composed tags don't have tagTypeRef, so we need to delete it from original simple tag.
                    // It's the reason, why 'set' operation is used here
                    await tag.setTag({
                        ...neededValues,
                        tagId,
                        description: '',
                        assets: {},
                        metadata: [],
                    })
                } else {
                    const metadata = values.metadata.reduce((tagMetadata: TagMetadataFieldInterface[], field) => {
                        const innerField = { ...field }
                        if (field.type === TagMetadataFieldType.AVAILABLE_SEASONS) {
                            innerField.value = getAvailableSeasons()
                        }
                        if (innerField.value) {
                            tagMetadata.push(innerField)
                        }
                        return tagMetadata
                    }, [])
                    await tag.updateTag({
                        tagId: values.tagId,
                        tagTypeRef: findTagTypeById(form.values.tagTypeId)?.ref,
                        ...(values.description && { description: values.description }),
                        metadata,
                        name: values.name,
                        simplicity: values.simplicity,
                        simpleTagsRefs: values.simpleTagsRefs,
                    })
                }

                showSuccess(t('Updated'))
                onClose?.(true)
            } catch (e) {
                showError(t('Failed to update tag'))
                console.error(e)
            }
        },
    })

    useEffect(() => {
        form.setFieldValue('metadata', getMetadata(form.values.tagTypeId))
        // if you update dependencies according to eslint it makes infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.tagTypeId])

    /**
     * Generate tag names for composed tag based on simpleTagsRefs
     */
    useEffect(() => {
        if (form.values.simplicity === TagSimplicityType.COMPOSED) {
            const translationNames = form.values.simpleTagsRefs
                .map(ref => tagOptions.find(tagOption => tagOption.id === ref.id)?.name)

            const name = Object.values(LangCode).reduce<Translation>((result, langCode) => {
                const nameByLangCode = translationNames.map(translationName => translationName?.[langCode])
                    .filter(name => !!name) as string[]

                result[langCode] = nameByLangCode.sort(stringComparator).join(' ')
                return result
            }, {})


            form.setFieldValue('name', name)
        }
        // if you update dependencies according to eslint it makes infinity loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.simpleTagsRefs, form.values.simplicity, tagOptions])

    const fetchTagOptions = useCallback(async () => {
        const tags = await findAllNotComposedTags()
        const filteredTagOptions = tags ? tags.filter(t => t.id !== tag.id) : []
        setTagOptions(filteredTagOptions)
    }, [findAllNotComposedTags, tag.id])

    useEffect(() => {
        fetchTagOptions()
        // We want to call only on mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [availableSeasonsString, setAvailableSeasonsString] = useState<string>()

    const handleChangeAvailableSeasons = (availableSeasonsString: string) => {
        setAvailableSeasonsString(availableSeasonsString)
    }

    const getAvailableSeasons = () => {
        const splitInput = availableSeasonsString?.split(',') ?? []

        const availableSeasons = splitInput
            .reduce((seasons: AvailableSeasonField[], actualString: string) => {
                const seasonNumber = parseInt(actualString.trim())
                const canAddSeason = !isNaN(seasonNumber)
                    && !seasons.find(availableSeason => availableSeason.seasonNumber === seasonNumber)
                if (canAddSeason) {
                    seasons.push({ seasonNumber })
                }
                return seasons
            }, [])
            .sort((seasonA, seasonB) =>
                numberComparator(seasonA.seasonNumber, seasonB.seasonNumber, SortDirection.ASC),
            )

        return availableSeasons
    }

    return (
        <AppThemeProvider type="light">
            <Dialog
                open={!!tag}
                onClose={onClose}
            >
                <StyledDialogContent>
                    <CloseIcon
                        onClick={() => {
                            onClose?.(false)
                        }}
                    >
                        <Close />
                    </CloseIcon>
                    <form onSubmit={form.handleSubmit}>
                        <Column spacing={4}>
                            <Column spacing={2}>
                                <DialogTitle variant="h6">
                                    {form.values.name.en}
                                </DialogTitle>
                                <AppSelect
                                    options={appSelectConverter(enumToOptions(TagSimplicityType))}
                                    value={form.values.simplicity}
                                    onChange={e => form.setFieldValue('simplicity', e.target.value)}
                                    label={t('Simplicity')}
                                    disabled={formDisabled}
                                />
                                {form.values.simplicity === TagSimplicityType.SIMPLE && (
                                    <UpdatableTypography
                                        onTextChange={text => form.setFieldValue('description', text)}
                                        editOnClick
                                        typographyProps={{
                                            color: 'textSecondary',
                                            variant: 'caption',
                                        }}
                                    >
                                        {form.values.description ?? t('No description')}
                                    </UpdatableTypography>
                                )}
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    label="ID"
                                    disabled={true}
                                    {...getInputProps(form, 'tagId')}
                                />
                            </Column>
                            {form.values.simplicity === TagSimplicityType.COMPOSED && (
                                <Column spacing={2}>
                                    <DialogTitle variant="h6">
                                        {t('Composed tags')}
                                    </DialogTitle>
                                    <TagsAutocomplete
                                        options={tagOptions}
                                        selectedTags={form.values.simpleTagsRefs}
                                        onTagChange={tags => form.setFieldValue('simpleTagsRefs', tags.map(tag => tag.getRef))}
                                        disabled={formDisabled}
                                    />
                                </Column>
                            )}
                            {form.values.simplicity === TagSimplicityType.SIMPLE && (
                                <>
                                    <Column spacing={2}>
                                        <DialogTitle variant="h6">
                                            {t('Tag type')}
                                        </DialogTitle>
                                        <FormControl
                                            size="small"
                                            fullWidth
                                            variant="outlined"
                                        >
                                            <InputLabel id="tag-type-select">{t('Type')}</InputLabel>
                                            <Select
                                                label={t('Type')}
                                                placeholder={t('Type')}
                                                id="tag-type-select"
                                                {...getInputProps(form, 'tagTypeId')}
                                            >
                                                {tagTypes.map(
                                                    type => (
                                                        <MenuItem
                                                            key={type.id}
                                                            // TODO TIV-692 NICE TO HAVE maybe use path as value here,
                                                            //  then only create ref from path, do not find it in tagTypes list
                                                            value={type.id}
                                                        >
                                                            {t(type.name)}
                                                        </MenuItem>
                                                    ),
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Column>
                                    {form.values.metadata?.length > 0 && (<Column spacing={2}>
                                        <DialogTitle variant="h6">
                                            {t('Metadata')}
                                        </DialogTitle>
                                        <MultiFormikField
                                            name="metadata"
                                            form={form}
                                            renderSingleField={(index: number, { form, field }: FieldProps) => (
                                                <TagMetadataField
                                                    metadataField={field.value}
                                                    onChange={value => form.setFieldValue(field.name, value)}
                                                    onChangeAvailableSeasons={handleChangeAvailableSeasons}
                                                />
                                            )}
                                            disableAddButton
                                        />

                                    </Column>)}
                                    <Column spacing={2}>
                                        <DialogTitle variant="h6">
                                            {t('Name translation')}
                                        </DialogTitle>
                                        <TranslationForm
                                            form={form}
                                            name="name"
                                        />
                                    </Column>
                                    <Column spacing={1}>
                                        <DialogTitle variant="h6">
                                            {t('Assets')}
                                        </DialogTitle>
                                        <AssetUploader
                                            size="avatar"
                                            filter={{ ids: findTagTypeById(form.values.tagTypeId)?.assetPresets.map(preset => preset.id) ?? [] }}
                                            item={{
                                                getAsset: tag.getAsset,
                                                getDocumentPath: () => tag.getRef.path,
                                                getTempFilePath: (preset) => `${tag.getRef.path}/${preset.name}/original.png`,
                                                updateItem: async (resizeResults: AssetResizeResult[], name: string) => {
                                                    tag.saveAssetResizeResults(resizeResults, name)
                                                },
                                            }}
                                        />
                                    </Column>
                                </>
                            )}

                            <Button
                                fullWidth
                                color="primary"
                                type="submit"
                                variant="contained"
                                disabled={formDisabled}
                            >
                                {t('Save')}
                            </Button>
                        </Column>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})

export {
    TagDialog,
}
