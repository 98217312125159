import { PlayerEngineEvent } from '@tivio/types'


// TODO any type for this from lib.dom.d.ts?
const HTML_EVENTS = new Set<PlayerEngineEvent>([
    'abort',
    'canplay',
    'canplaythrough',
    'durationchange',
    'emptied',
    'ended',
    'error',
    'loadeddata',
    'loadedmetadata',
    'loadstart',
    'pause',
    'play',
    'playing',
    'progress',
    'ratechange',
    'seeked',
    'seeking',
    'stalled',
    'suspend',
    'timeupdate',
    'volumechange',
    'waiting',
    'click',
    'fullscreenchange',
])

export const isHtmlEvent = (event: PlayerEngineEvent) => {
    return HTML_EVENTS.has(event)
}
