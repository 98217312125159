import {
    Box,
    Button,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as Yup from 'yup'

import Logger from '../../logger'
import store from '../../store'
import { alertError, alertSuccess } from '../../utils/alert.utils'
import AppInput from '../AppInput'

import { AUTH_TABS } from './AuthTabs'


type TForgottenPasswordForm = {
    email: string,
}

type TProps = {
    setCurrentTab: (tab: AUTH_TABS) => void
}

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email required').email('Enter valid email'),
})

const initialValues: TForgottenPasswordForm = {
    email: '',
}

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(8),
        paddingBottom: 67,
        paddingTop: theme.spacing(10),
    },
    backIcon: {
        position: 'absolute',
        left: 32,
        top: 20,
    },
}))

const logger = new Logger('AuthForgottenPassword')

/**
 * Forgotten password form component
 * Serves for updating user password
 */
function AuthForgottenPassword(props: TProps) {
    const [ loading, setLoading ] = useState(false)
    const { recoverPassword } = store
    const [ t ] = useTranslation()
    const classes = useStyles()

    const handleSubmit = async (values: TForgottenPasswordForm) => {
        setLoading(true)

        try {
            await recoverPassword(values.email)
            props.setCurrentTab(AUTH_TABS.LOGIN)
            alertSuccess(t('Check your email for reset password link'))
        }
        catch (e) {
            alertError(t('Email send error'))
            logger.error(e)
        }
        finally {
            setLoading(false)
        }
    }

    const back = () => {
        props.setCurrentTab(AUTH_TABS.LOGIN)
    }

    return (
        <div className={classes.container}>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={validationSchema}
            >
                <Form>
                    <IconButton
                        aria-label="back"
                        className={classes.backIcon}
                        color="primary"
                        edge="start"
                        onClick={back}
                    >
                        <ArrowBack/>
                    </IconButton>
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                    >
                        <Grid item>
                            <Typography variant="body1">
                                <Box
                                    fontWeight="bold"
                                    textAlign="center"
                                >
                                    {t('We will send you email, where you will be able to regenerate your password.')}
                                </Box>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <AppInput
                                name="email"
                                label={t('Email')}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                fullWidth
                            >
                                {t('Send')}
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            </Formik>
        </div>
    )
}

export default AuthForgottenPassword
