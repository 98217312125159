export interface DateRange {
    startDate: string,
    endDate: string,
}

export enum Period {
    TODAY = 'today',
    YESTERDAY = 'yesterday',
    LAST_SEVEN_DAYS = 'lastSevenDays',
    LAST_THIRTY_DAYS = 'lastThirtyDays',
    CUSTOM_RANGE_DAYS = 'customRangeDays',
}

export enum MonetizationsPeriod {
    LAST_SEVEN_DAYS = 'lastSevenDays',
    LAST_THIRTY_DAYS = 'lastThirtyDays',
    LAST_NINETY_DAYS = 'lastNinetyDays',
    LAST_FOUR_WEEKS = 'lastFourWeeks',
    LAST_FIFTY_TWO_WEEKS = 'lastFiftyTwoWeeks'
}
