import { Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { OrganizationMemberRoles } from '@tivio/firebase'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { COLORS } from '../../../static/enum'
import { ConversionsDiagram } from '../../analytics/conversions/ConversionsDiagram'
import { MonetizationsDashboard } from '../../analytics/monetizations/MonetizationsDashboard'
import { ViewsDashboard } from '../../analytics/views/ViewsDashboard'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import { useRole } from '../../hooks/permissions/permissions'


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
    },
    tileContainer: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: 272,
        position: 'relative',
        borderRadius: theme.spacing(1),
    },
}))

/**
 * Analytics page component
 */
export default function AnalyticsPage() {
    const role = useRole()
    const [t] = useTranslation()
    const classes = useStyles()

    const getTabsAccordingToRole = () => {
        const tabsForAll = [
            {
                title: t('Views'),
                component: <ViewsDashboard />,
            },
            {
                title: t('Conversion'),
                component: <ConversionsDiagram />,
            },
        ]
        if (role && role >= OrganizationMemberRoles.ADMIN) {
            tabsForAll.push(
                {
                    title: t('Monetizations'),
                    component: <MonetizationsDashboard />,
                })
        }

        return tabsForAll
    }

    return (
        <Container
            maxWidth="lg"
            className={classes.container}
        >
            <DefaultPageTabs tabs={getTabsAccordingToRole()} />
        </Container>
    )
}
