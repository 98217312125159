import { Button, IconButton } from '@material-ui/core'
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import OrganizationAdmin from '../../store/OrganizationAdmin'
import AppTile from '../AppTile'
import { ClickableFileInput } from '../common/input'
import { getContentPlace } from '../section/SectionElementInterface'


interface TProps {
    organization: OrganizationAdmin
}

const OrganizationTile = (props: TProps) => {
    const { organization } = props
    const [ loading, setLoading ] = useState(false)
    const [ t ] = useTranslation()

    const handleUpdateCover = useCallback(async (files: File[]) => {
        setLoading(true)
        const file = files[0]

        await organization.updateCover(file)

        setLoading(false)
    }, [ organization, setLoading ])

    return (
        <AppTile
            size="small"
            loading={loading}
            cover={organization.profilePhoto}
            contentPlace={getContentPlace(organization.profilePhoto)}
        >
            <ClickableFileInput onFiles={handleUpdateCover}>
                {
                    organization.profilePhoto
                        ? (
                            <IconButton component="span">
                                <AddPhotoAlternate/>
                            </IconButton>
                        )
                        : (
                            <Button
                                variant="text"
                                size="small"
                                component="span"
                                startIcon={<AddPhotoAlternate/>}
                            >
                                {t('Add logo')}
                            </Button>
                        )
                }
            </ClickableFileInput>
        </AppTile>
    )
}

export default OrganizationTile
