import { Collapse, Grid } from '@material-ui/core'
import { useObserver } from 'mobx-react'
import React, { FC, useState } from 'react'
import styled from 'styled-components'

import Logger from '../../logger'
import { ON_EACH_CHAPTER } from '../../static/constants'
import Monetization, { DEFAULT_VARIANT_ID } from '../../store/Monetization'
import { useStickyState } from '../hooks/uiHooks/useStickyState'

import { AdSlotOverview } from './AdSlotOverview'
import { StrategyHeader } from './StrategyHeader'
import { StrategyRow } from './StrategyRow'

import type { AdProfileDocumentWithId } from '../../firebase/firestore/converters'
import type { PlacementChangeHandler } from './PlacementDialog'
import type { MonetizationAdVariantField, MonetizationPlacementField } from '@tivio/firebase'


const StyledHeaderTitleGrid = styled(Grid)`
    background: rgba(216,216,216,0.07);
    border-radius: 8px;
    padding: 8px 16px;
`

interface StrategyRowInfo {
    title: string
    dbKey: string
    adSlots: AdProfileDocumentWithId[]
    showSelectInterval?: boolean
    midRollInterval?: number | string | undefined
}

interface Props {
    id: string
    title: string
    placements?: MonetizationPlacementField
    monetization: Monetization
    handleAddAdSlot: (name: string, adSlot: AdProfileDocumentWithId, variantId: string) => void
    handleChangeAdSlotsOrder: (name: string, adSlots: AdProfileDocumentWithId[], variantId: string) => void
    handleDeleteStrategy: () => void
    handleDeleteAdSlot: (name: string, index: number, variantId: string, slotName?: string) => void
    handleDeleteAdConfig: (name: string, variantId: string) => void
    handleChangeMidRollInterval: (interval: number | string, variantId: string) => void
    handleEditStrategyTitle: (title: string) => void
    handleSubmitPlacement: PlacementChangeHandler
    adProfiles: AdProfileDocumentWithId[]
}

export const STRATEGY_NAMES_MAPPING: {[name: string]: string} = {
    'preRoll': 'Pre-roll',
    'midRoll': 'Mid-roll',
    'chapter': 'Mid-roll',
    'replacement': 'Replace-roll',
    'postRoll': 'Post-roll',
}

const logger = new Logger('Strategy')

export const Strategy: FC<Props> = ({
    id,
    title,
    placements,
    handleAddAdSlot,
    handleChangeAdSlotsOrder,
    monetization,
    handleDeleteStrategy,
    handleDeleteAdSlot,
    handleDeleteAdConfig,
    handleChangeMidRollInterval,
    handleEditStrategyTitle,
    handleSubmitPlacement,
    adProfiles,
}) => {
    const [ isCollapse, setIsCollapse ] = useStickyState(true, `strategy-row-is-collapsed-${id}`)

    const [ selectedVariantId, setSelectedVariantId] = useState<string>(DEFAULT_VARIANT_ID)

    let rows: StrategyRowInfo[] = []

    // TODO NICE TO HAVE rework this solution, because useObserver hook is deprecated. AND It causes unnecessary re-renders
    //  I think (this code should be executed only of monetization or selectedVariantId changes)
    useObserver(() => {
        if (!selectedVariantId) {
            logger.error(`selectedVariantId is ${selectedVariantId} for monetization ${monetization.getId}`)
            return
        }
        const variant = monetization.getVariantById(selectedVariantId)
        if (!variant) {
            logger.error(`Variant ${selectedVariantId} was not found for monetization ${monetization.getId}`)
            return
        }

        const strategies = (variant as MonetizationAdVariantField).strategies ?? {}
        rows = [
            {
                title: STRATEGY_NAMES_MAPPING['preRoll'],
                dbKey: 'preRoll',
                adSlots: strategies.preRoll?.adSlots ?? [],
            },
            {
                title: STRATEGY_NAMES_MAPPING['midRoll'], // the same for chapter and midRoll
                dbKey: (strategies.chapter) ? 'chapter' : 'midRoll',
                adSlots: [ ...strategies.midRoll?.adSlots ?? [], ...strategies.chapter?.adSlots ?? [] ],
                showSelectInterval: true,
                midRollInterval: (() => {
                    if (strategies.midRoll) {
                        return strategies.midRoll?.minWithoutAdMs
                    }
                    if (strategies.chapter) {
                        return ON_EACH_CHAPTER
                    }
                    return undefined
                })(),
            },
            {
                title: STRATEGY_NAMES_MAPPING['replacement'],
                dbKey: 'replacement',
                adSlots: strategies.replacement?.adSlots ?? [],
            },
            {
                title: STRATEGY_NAMES_MAPPING['postRoll'],
                dbKey: 'postRoll',
                adSlots: strategies.postRoll?.adSlots ?? [],
            },
        ]
    })

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
        >
            <Grid item>
                <StyledHeaderTitleGrid
                    container
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="stretch"
                >
                    <Grid item>
                        <StrategyHeader
                            id={id}
                            title={title}
                            placements={placements}
                            monetization={monetization}
                            collapse={isCollapse}
                            toggleCollapse={setIsCollapse}
                            handleDeleteStrategy={handleDeleteStrategy}
                            handleEditStrategyTitle={handleEditStrategyTitle}
                            handleSubmitPlacement={handleSubmitPlacement}
                            selectedVariantId={selectedVariantId}
                            onVariantSelect={setSelectedVariantId}
                        />
                    </Grid>
                    <Grid item>
                        {rows.map((row: StrategyRowInfo) => (
                            <AdSlotOverview
                                key={row.dbKey}
                                title={row.title}
                                adSlots={row.adSlots}
                            />
                        ))}
                    </Grid>
                </StyledHeaderTitleGrid>
            </Grid>
            <Grid item>
                <Collapse
                    in={isCollapse}
                >
                    {rows.map((row: StrategyRowInfo, index: number) => (
                        <StrategyRow
                            key={row.dbKey}
                            dbKey={row.dbKey}
                            title={row.title}
                            adSlots={row.adSlots}
                            handleAddAdSlot={(name, slot) => handleAddAdSlot(name, slot, selectedVariantId)}
                            handleChangeAdSlotsOrder={(name, adSlots) => handleChangeAdSlotsOrder(name, adSlots, selectedVariantId)}
                            handleDeleteAdSlot={(name, index, slotName) => handleDeleteAdSlot(name, index, selectedVariantId, slotName)}
                            handleDeleteAdConfig={(name) => handleDeleteAdConfig(name, selectedVariantId)}
                            showSelectInterval={row.showSelectInterval}
                            handleChangeMidRollInterval={(interval) => handleChangeMidRollInterval(interval, selectedVariantId)}
                            midRollInterval={row.midRollInterval}
                            adProfiles={adProfiles}
                            isLast={(index === rows.length - 1)}
                        />
                    ))}
                </Collapse>
            </Grid>
        </Grid>
    )
}
