import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import React from 'react'


export interface TCreateAction {
    onCreate: (event?: any) => any
    title: string
}

export interface TActions {
    create?: TCreateAction | null
}

interface Props {
    actions?: TActions | null
    title: string
    rightContent?: React.ReactNode
}

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: theme.spacing(2),
    },
    headerContainer: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(3),
    },
}))

const Actions = (props: TActions) => {
    const { create } = props

    if (create) {
        return (
            <Grid item>
                <Button
                    startIcon={<Add/>}
                    onClick={create.onCreate}
                    size="small"
                >
                    {create.title}
                </Button>
            </Grid>
        )
    }

    return null
}

/**
 * Common reusable section header with action buttons.
 */
const Header: React.FC<Props> = (props) => {
    const classes = useStyles()
    const { actions, rightContent, title } = props

    return (
        <div className={classes.headerContainer}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Typography variant="h5">
                        {title}
                    </Typography>
                </Grid>
                {actions && <Actions {...actions} />}
                {rightContent && <Grid item>{rightContent}</Grid>}
            </Grid>
            <Divider className={classes.divider} />
        </div>
    )
}

Header.displayName = 'Header'

export { Header }
