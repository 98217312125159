import { Checkbox, FormControlLabel } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Purchase from '../../store/Purchase'
import { useVideo } from '../hooks/dataHooks/useVideo'


interface InnerProps {
    isNonDrmEnabled: boolean
    videoId: string
    toggleNonDrmEnabled: () => Promise<void>
}

const UserItemNonDrmInner = ({ isNonDrmEnabled, videoId, toggleNonDrmEnabled }: InnerProps) => {
    const [t] = useTranslation()
    const { video } = useVideo(videoId)
    const [isLoading, setIsLoading] = useState(false)
    const handleCheckboxChange = async () => {
        setIsLoading(true)
        await toggleNonDrmEnabled()
        setIsLoading(false)
    }
    if (video?.sources.some(({ encryption }) => encryption !== 'none')) {
        return (
            <FormControlLabel
                label={t('Allow non-DRM sources')}
                disabled={isLoading}
                control={<Checkbox
                    checked={isNonDrmEnabled}
                    onChange={handleCheckboxChange}
                    name="enabled"
                    color="primary"
                />}
            />
        )
    }

    return null
}

interface Props {
    purchase: Purchase
}

export const UserItemNonDrm = observer(({ purchase }: Props) => {
    const { videoId } = purchase
    const isNonDrmEnabled = Boolean(purchase.forcedCapabilities?.length)

    return videoId ? <UserItemNonDrmInner
        isNonDrmEnabled={isNonDrmEnabled}
        videoId={videoId}
        toggleNonDrmEnabled={purchase.toggleDrmEnabled}
    /> : null
})
