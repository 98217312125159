import { GetViewAnalyticsResponse } from '@tivio/types'

import { getFunctions, loggerFirestore } from '../app'


interface GetConversionOptions {
    organizationId: string
    startDate: string
    endDate: string
}
export const getViewsAnalytics = async ({ organizationId, startDate, endDate }: GetConversionOptions) => {
    try {
        const getViewsAnalytics = getFunctions().httpsCallable('getViewsAnalytics')
        const response: { data: GetViewAnalyticsResponse } = await getViewsAnalytics({
            organizationId,
            startDate,
            endDate,
        })

        loggerFirestore.info('Successfully fetched views analytics.')
        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to create invite: ', e)
        throw new Error(e)
    }
}

