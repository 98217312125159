import React, { createContext, PropsWithChildren, useRef, useState } from 'react'


interface ConfirmActionContextState {
    action: () => any
    actionTitle?: string | null
    actionMessage?: string | null
    confirmButtonMessage?: string | null
    cancelButtonMessage?: string | null
    isOpened: boolean
    closeDialog: () => void
    setActionToConfirm: (
        action: () => any, 
        actionMessage?: string, 
        actionTitle?: string, 
        confirmButtonMessage?: string,
        cancelButtonMessage?: string,
    ) => void
}

const ConfirmActionContext = createContext<ConfirmActionContextState>({
    action: () => null,
    actionTitle: null,
    actionMessage: null,
    confirmButtonMessage: null,
    cancelButtonMessage: null,
    setActionToConfirm: () => null,
    closeDialog: () => null,
    isOpened: false,
})

const ConfirmActionContextProvider: React.FC<PropsWithChildren> = (props) => {
    const actionRef = useRef<() => any>(() => null)
    const [ isOpened, setIsOpened ] = useState(false)
    const [ actionMessage, setActionMessage ] = useState<string|null>(null)
    const [ actionTitle, setActionTitle ] = useState<string|null>(null)
    const [ confirmButtonMessage, setConfirmButtonMessage ] = useState<string|null>(null)
    const [ cancelButtonMessage, setCancelButtonMessage ] = useState<string|null>(null)

    const setActionToConfirm = (
        action: () => any,
        actionMessage?: string,
        actionTitle?: string,
        confirmButtonMessage?: string,
        cancelButtonMessage?: string,
    ) => {
        actionRef.current = action
        setIsOpened(true)
        setActionMessage(actionMessage ?? null)
        setActionTitle(actionTitle ?? null)
        setConfirmButtonMessage(confirmButtonMessage ?? null)
        setCancelButtonMessage(cancelButtonMessage ?? null)
    }

    const handleClose = () => {
        setIsOpened(false)
        actionRef.current = () => null
    }

    return (
        <ConfirmActionContext.Provider
            value={{
                setActionToConfirm,
                isOpened,
                actionTitle,
                actionMessage,
                confirmButtonMessage,
                cancelButtonMessage,
                closeDialog: handleClose,
                action: actionRef.current,
            }}
        >
            {props.children}
        </ConfirmActionContext.Provider>
    )
}

export {
    ConfirmActionContextProvider,
    ConfirmActionContext,
}
