import { Box } from '@material-ui/core'
import React from 'react'


type TTabPanelProps = {
    children: React.ReactElement,
    currentTabName: string,
    tabName: string,
}

/**
 * Authorization tab panel
 */
function AuthTabPanel(props: TTabPanelProps) {
    const { children, currentTabName, tabName } = props

    if (currentTabName !== tabName) {
        return null
    }

    return (
        <Box>
            {children}
        </Box>
    )
}

export default AuthTabPanel
