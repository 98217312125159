import { Button, Grid, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import FastForward from '@material-ui/icons/FastForward'
import FastRewind from '@material-ui/icons/FastRewind'
import Pause from '@material-ui/icons/Pause'
import PlayArrow from '@material-ui/icons/PlayArrow'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { SECOND } from '../../utils/time.utils'


export interface EpgPlayerControlsProps {
    isPlaying: boolean
    onPause: () => void
    onPlay: () => void
    onSeekWithControls: (step: number) => void
}

const useStyles = makeStyles({
    button: {
        fontWeight: 'bold',
    },
})

const PlayerControls = observer((props: EpgPlayerControlsProps) => {
    const classes = useStyles()
    const [ t ] = useTranslation()

    return (
        <Grid
            container
            justifyContent="center"
            direction="row"
            alignItems="center"
            spacing={1}
        >
            <Grid item>
                <Button
                    data-e2e="player-ctrl-rewind-10s"
                    className={classes.button}
                    size="small"
                    startIcon={<FastRewind/>}
                    onClick={() => props.onSeekWithControls(SECOND * -10)}
                >
                    {t('-10 SEC')}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    data-e2e="player-ctrl-rewind-1s"
                    className={classes.button}
                    size="small"
                    startIcon={<FastRewind/>}
                    onClick={() => props.onSeekWithControls(-SECOND)}
                >
                    {t('-1 SEC')}
                </Button>
            </Grid>
            <Grid item>
                {
                    props.isPlaying
                        ? (
                            <IconButton
                                data-e2e="player-ctrl-pause"
                                onClick={props.onPause}
                                color="inherit"
                                size="small"
                            >
                                <Pause fontSize="small"/>
                            </IconButton>
                        )
                        : (
                            <IconButton
                                data-e2e="player-ctrl-play"
                                onClick={props.onPlay}
                                color="inherit"
                                size="small"
                            >
                                <PlayArrow fontSize="small"/>
                            </IconButton>
                        )
                }
            </Grid>
            <Grid item>
                <Button
                    data-e2e="player-ctrl-forward-1s"
                    className={classes.button}
                    size="small"
                    endIcon={<FastForward/>}
                    onClick={() => props.onSeekWithControls(SECOND)}
                >
                    {t('+1 SEC')}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    data-e2e="player-ctrl-forward-10s"
                    className={classes.button}
                    size="small"
                    endIcon={<FastForward/>}
                    onClick={() => props.onSeekWithControls(SECOND * 10)}
                >
                    {t('+10 SEC')}
                </Button>
            </Grid>
        </Grid>
    )
})

export default PlayerControls
