import React, { FC } from 'react'
import styled from 'styled-components'

import { ColorCircle } from '../color'

import type { AdProfileDocumentWithId } from '../../firebase/firestore/converters'


const ProfileOverviewContainer = styled.div`
    display: inline-block;
    margin-top: 9px;
    margin-right: 20px;

    & > div {
        margin-right: 5px;
    }
`

const ProfileOverviewTitle = styled.div`
    display: inline-block;
    vertical-align: text-bottom;
`

interface AdSlotOverviewProps {
    title: string
    adSlots?: AdProfileDocumentWithId[]
}

export const AdSlotOverview: FC<AdSlotOverviewProps> = ({ title, adSlots }) => {
    if (!adSlots || !adSlots.length) {
        return null
    }

    return (
        <ProfileOverviewContainer>
            <ProfileOverviewTitle>{title}</ProfileOverviewTitle>
            {adSlots.map((adSlot, index) => (
                // if IDs are the same, there may be a glitch where these components
                // do not re-render but that should not be a problem, since same ID implies same color
                <ColorCircle key={`${adSlot.id}-${index}`} color={adSlot.color}/>
            ))}
        </ProfileOverviewContainer>
    )
}
