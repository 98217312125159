import { createContext, useContext } from "react"
import { TivioNotification } from "../../../store/Notification"

interface NotificationContextProps {
    notification: TivioNotification | undefined
}

export const NotificationContext = createContext<NotificationContextProps>({
    notification: undefined,
})

export const useNotificationContext = () => {
    const context = useContext(NotificationContext)
    if (!context) {
        throw new Error('useNotificationContext must be used within a NotificationContextProvider')
    }
    return context
}
