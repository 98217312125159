import { MenuItem, Select } from '@material-ui/core'
import { PurchaseStatusChangeField } from '@tivio/firebase'
import { PurchaseStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../../store/Notification'
import { InputWrapper } from '../shared/InputWrapper'


const getKey = (purchaseStatusChange?: PurchaseStatusChangeField) => {
    if (!purchaseStatusChange) {
        return ''
    }
    const { before, after } = purchaseStatusChange
    return `${before}-${after}`
}

const purchaseStatusOptions: PurchaseStatusChangeField[] = [
    {
        before: PurchaseStatus.NEW,
        after: PurchaseStatus.PAID,
    },
    {
        before: PurchaseStatus.PAID,
        after: PurchaseStatus.CANCELLING,
    },
    {
        before: PurchaseStatus.PAID,
        after: PurchaseStatus.EXPIRED,
    },
    {
        before: PurchaseStatus.CANCELLING,
        after: PurchaseStatus.CANCELLED,
    },
]

interface Props {
    notification: TivioNotification
}

export const PurchaseStatusChangeSelect = observer(({ notification }: Props) => {
    const [t] = useTranslation()
    const handleChange = (e: ChangeEvent<{ value: unknown }>) => {
        const [before, after] = (e.target.value as string).split('-')
        const purchaseStatusChange = purchaseStatusOptions.find((value) => value.before === before && value.after === after)
        notification.purchaseStatusChange = purchaseStatusChange
    }
    return (
        <InputWrapper label={t('Purchase status change')}>
            {/* TODO: A component is changing the uncontrolled value state of Select to be controlled. */}
            <Select
                value={getKey(notification.purchaseStatusChange)}
                onChange={handleChange}
            >
                {purchaseStatusOptions.map((value) => {
                    const { before, after } = value
                    return (
                        <MenuItem
                            key={getKey(value)}
                            value={getKey(value)}
                        >
                            {before} -&gt; {after}
                        </MenuItem>
                    )
                })}
            </Select>
        </InputWrapper>
    )
})
