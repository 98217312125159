import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Organization from '../../../store/Organization'
import Widget from '../../../store/Widget'
import { goWidgetPage } from '../../../utils/history.utils'
import { Listing } from '../../common/listing/Listing'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'


type Props = {
    organization: Organization
}

export const WidgetsPage = observer((props: Props) => {
    const { organization } = props
    const data: Widget[] = organization.widgets
    const [ widgets, setWidgets ] = useState<Widget[]>(data)
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()

    useEffect(
        () => {
            (async () => {
                const data: Widget[] = organization.widgets
                setWidgets(data)
            })()
        },
        [ organization ],
    )

    const handleAddWidget = async () => {
        await organization.addWidget({
            name: `New widget ${organization.widgets.length + 1}`,
            widgetId: '',
            autoChannels: false,
            channelsOrder: [],
            enabled: false,
        })

        const data: Widget[] = organization.widgets
        setWidgets(data)
    }

    const handleRemoveWidget = async (id: string) => {
        await organization.removeWidget(id)
        const data: Widget[] = organization.widgets

        setWidgets(data)
    }

    return (
        <DefaultPageContainer
            title={t('Widgets')}
            buttons={[
                <Button
                    key="button-add-ad"
                    startIcon={<Add/>}
                    component="span"
                    size="small"
                    onClick={handleAddWidget}
                >
                    {t('Add widget')}
                </Button>,
            ]}
        >
            <Listing
                items={widgets}
                onClick={item => goWidgetPage(item.getId)}
                onUpdateClick={item => goWidgetPage(item.getId)}
                onDeleteClick={
                    item => confirmAction(
                        () => handleRemoveWidget(item.getId),
                        t(`Are you sure you want to delete the widget "${item.getName}"?`),
                    )
                }
            />
        </DefaultPageContainer>
    )
})

export default WidgetsPage
