import React from 'react'
import styled from 'styled-components'

import { Row } from '../uiLayout/Row'


enum ValueType {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    ZERO = 'zero',
}

type ColorPalette = {
    [keyof in ValueType]: {
        backgroundColor: string,
        fontColor: string,
    }
}

const GAIN_PALETTE: ColorPalette = {
    [ValueType.POSITIVE]: {
        backgroundColor: 'rgba(111, 214, 0, 0.25)',
        fontColor: '#6dd400',
    },
    [ValueType.NEGATIVE]: {
        backgroundColor: 'rgba(214, 14, 0, 0.25)',
        fontColor: '#d40e00',
    },
    [ValueType.ZERO]: {
        backgroundColor: 'rgba(212, 212, 212, 0.25)',
        fontColor: '#d3d3d3',
    },
}

const GainChipStyled = styled.div<{ valueType: ValueType }>`
    font-size: 11px;
    border-radius: 4px;
    padding: 1px 8px;
    background-color: ${props => GAIN_PALETTE[props.valueType].backgroundColor};
    color: ${props => GAIN_PALETTE[props.valueType].fontColor};
`

interface Props {
    value: number
}

export const GainChip: React.FC<Props> = (props) => {
    const { value } = props

    const gainString = value > 0 ? `+${value}%` : `${value}%`
    const valueType = (() => {
        if (value > 0) {
            return ValueType.POSITIVE
        }
        if (value < 0) {
            return ValueType.NEGATIVE
        }
        return ValueType.ZERO
    })()

    return (
        <Row
            spacing={2}
            rowProps={{ alignItems: 'center', justifyContent: 'flex-start' }}
        >
            <GainChipStyled valueType={valueType}>
                {gainString}
            </GainChipStyled>
        </Row>
    )
}
