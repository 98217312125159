import { tivio } from '@tivio/core-js'
import { PaginationInterface, PaginationOptions, Row } from '@tivio/types'
import { useEffect, useState } from 'react'

import { useError } from './useError'


const ROWS_IN_SCREEN_LIMIT = 3

/**
 * @deprecated use Screen and Row entities instead
 */
const useRowsInScreen = (
    screenId: string,
    options: PaginationOptions = {
        noLimit: false,
        limit: ROWS_IN_SCREEN_LIMIT,
    },
) => {
    const [ pagination, setPagination ] = useState<PaginationInterface<Row> | null>(null)
    const { error, raiseError } = useError()

    useEffect(
        () => {
            let isMounted = true

            const disposer = tivio.subscribeToRowsInScreen(
                screenId,
                (error, data) => {
                    if (!isMounted) {
                        return
                    }

                    if (error) {
                        raiseError(error)
                    }

                    if (data) {
                        setPagination({
                            fetchMore: data.fetchMore,
                            items: data.items,
                            hasNextPage: data.hasNextPage,
                            loading: data.loading,
                        })
                    }
                },
                options,
            )

            return () => {
                isMounted = false
                disposer()
            }
        },
        [screenId],
    )

    return {
        pagination,
        error,
    }
}

export {
    useRowsInScreen,
}
