import { tivio } from '@tivio/core-js'
import { Tag } from '@tivio/types'
import { useCallback, useEffect, useState } from 'react'


const useTag = (tagId: string) => {
    const [tag, setTag] = useState<Tag | null>(null)

    const findTag = useCallback(async () => {
        const tag = await tivio.getTagById(tagId)

        setTag(tag)
    }, [tagId])

    useEffect(
        () => {
            findTag()
        },
        [tagId],
    )

    return {
        tag,
    }
}

export {
    useTag,
}
