import { Tooltip } from '@material-ui/core'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import { Logger } from '@tivio/common'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { tvChannelExists } from '../../firebase/firestore/tvChannels'


const logger = new Logger('ChannelAvailability')

const Indicator = styled.div<{ radius: number, color: string }>`
    width: ${props => props.radius}px;
    height: ${props => props.radius}px;
    border-radius: ${props => props.radius}px;
    background-color: ${props => props.color};
    opacity: 0.4;
    transition: background-color 0.2s linear;
    cursor: pointer;
`

const StyledCheck = styled(Check)`
    color: ${props => props.theme.palette.text.primary};
`

const StyledCross = styled(Clear)`
    position: relative;
    left: 1px;
    top: 1px;
    color: ${props => props.theme.palette.text.primary};
`

interface Props {
    channelKey: string | null
}

const getColor = (available: boolean | null) => {
    if (available) {
        return 'green'
    } else if (available === false) {
        return 'red'
    } else {
        return 'grey'
    }
}

const getTitle = (available: boolean | null) => {
    if (available) {
        return 'Stream from Nangu.TV platform is available'
    } else if (available === false) {
        return 'Stream from Nangu.TV platform is not available'
    } else {
        return 'Checking stream availability on Nangu.TV platform'
    }
}

const exists = debounce((channelKey: string, setIsAvailable: (available: boolean) => void) => {
    logger.info(`Checking channel availability of TV channel by channel key: ${channelKey}...`)

    return tvChannelExists(channelKey)
        .then((available) => {
            logger.info(`TV channel with key: ${channelKey} is ${available ? 'available' : 'not available'}`)

            setIsAvailable(available)
        })
}, 500, { trailing: true })

export const ChannelAvailability = (props: Props) => {
    const { channelKey } = props
    const [isAvailable, setIsAvailable] = useState<boolean|null>(null)

    const check = useCallback(() => {
        setIsAvailable(null)

        if (channelKey) {
            exists(channelKey, setIsAvailable)
        }
    }, [channelKey])

    useEffect(check, [check])

    return (
        <Tooltip title={getTitle(isAvailable)} placement="top">
            <Indicator
                radius={26}
                color={getColor(isAvailable)}
                onClick={check}
            >
                {isAvailable && <StyledCheck />}
                {isAvailable === false && <StyledCross />}
            </Indicator>
        </Tooltip>
    )
}
