import { Tivio } from '@tivio/core-js'


const useAuth = () => {
    const { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, deleteUser, signInWithPopUp, updateEmail } = Tivio

    return {
        signInWithEmailAndPassword,
        createUserWithEmailAndPassword,
        signOut,
        deleteUser,
        signInWithPopUp,
        updateEmail,
    }
}

export {
    useAuth,
}
