import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../../store/Notification'
import { TargetingTypeEnum } from '../constants'
import { useSubscriptions } from '../../../hooks/dataHooks/useSubscriptions'
import { useMemberships } from '../../../hooks/dataHooks/useMemberships'


interface Props {
    notification: TivioNotification;
    onChange: (value: TargetingTypeEnum) => void;
    value: TargetingTypeEnum;
}

export const MembershipOrSubscription = observer(({ notification, onChange, value }: Props) => {
    const [t] = useTranslation()
    const { memberships } = useMemberships()
    const { subscriptions } = useSubscriptions()
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value as TargetingTypeEnum
        switch (value) {
            case TargetingTypeEnum.NONE:
                notification.targeting = null
                break
            case TargetingTypeEnum.MEMBERSHIP:
                notification.targeting = {
                    monetizationRef: null,
                    membershipRef: memberships[0].getRef ?? null,
                    location: null,
                    survey: null,
                    isActive: notification.targeting?.isActive ?? true,
                    type: 'membership',
                    isVoucher: notification.targeting?.isVoucher ?? false,
                }
                break
            case TargetingTypeEnum.SUBSCRIPTION:
                notification.targeting = {
                    membershipRef: null,
                    monetizationRef: subscriptions[0].getRef ?? null,
                    location: null,
                    survey: null,
                    isActive: notification.targeting?.isActive ?? true,
                    type: 'monetization',
                    isVoucher: notification.targeting?.isVoucher ?? false,
                }
                break
            case TargetingTypeEnum.SURVEY:
                notification.targeting = {
                    membershipRef: null,
                    monetizationRef: null,
                    location: null,
                    survey: null,
                    isActive: notification.targeting?.isActive ?? true,
                    type: 'survey',
                    isVoucher: notification.targeting?.isVoucher ?? false,
                }
                break
            case TargetingTypeEnum.LOCATION:
                notification.targeting = {
                    membershipRef: null,
                    monetizationRef: null,
                    location: null,
                    survey: null,
                    isActive: notification.targeting?.isActive ?? true,
                    type: 'location',
                    isVoucher: notification.targeting?.isVoucher ?? false,
                }
                break
        }

        onChange(value)
    }

    return (
        <RadioGroup
            row
            value={value}
            onChange={handleChange}
        >
            {memberships.length > 0 && <FormControlLabel
                value={TargetingTypeEnum.MEMBERSHIP}
                control={<Radio color="primary" />}
                label={t('Membership')}
            />}
            {subscriptions.length > 0 && <FormControlLabel
                value={TargetingTypeEnum.SUBSCRIPTION}
                control={<Radio color="primary" />}
                label={t('Subscription')}
            />}
            {(notification.triggerType === 'manual' || notification.triggerType === 'scheduled') && (
                <>
                    <FormControlLabel
                        value={TargetingTypeEnum.SURVEY}
                        control={<Radio color="primary" />}
                        label={t('Survey')}
                    />
                    <FormControlLabel
                        value={TargetingTypeEnum.LOCATION}
                        control={<Radio color="primary" />}
                        label={t('Location')}
                    />
                </>
            )}
        </RadioGroup>
    )
})
