import { PurchasableMonetization, TvChannel, Video } from '@tivio/types'
import { useCallback } from 'react'

import { itemIsVideo } from '../../utils'


type Props = {
    goLoginCallback?: () => void
    goVodCallback?: (vod: Video) => void
    goLiveCallback?: (tvChannel: TvChannel) => void
    goMonetizationSelectCallback?: (
        monetizations: PurchasableMonetization[],
        context?: {
            /**
             * Item from which monetization select was opened.
             * Note: does not necessarily mean that result purchase will be transaction.
             */
            item?: Video | TvChannel
        },
    ) => void
    goPurchaseCallback?: (monetization: PurchasableMonetization, item: Video | TvChannel) => void
}

export function usePlayVideo({
    goVodCallback,
    goLiveCallback,
    goMonetizationSelectCallback,
    goPurchaseCallback,
}: Props = {}) {
    const onPlayVideo = useCallback((item: Video | TvChannel) => {
        // TODO TIV-2402 what if video is not fully loaded and returns null as price, but really it's not free? We need wait for video loading

        const isVideo = itemIsVideo(item)
        if (isVideo) {
            item.loadLinkedVideos()
        }

        if (!item.price || (item as Video).tasting) {
            if (itemIsVideo(item)) {
                goVodCallback?.(item)
            } else {
                goLiveCallback?.(item)
            }
            return
        }

        const monetizations = item.getPurchasableMonetizations({ includeMonetizationsWithPurchaseDisabled: false })

        // show payment qr code when video has only one transaction
        if (monetizations.length === 1 && monetizations[0].type === 'transaction') {
            goPurchaseCallback?.(monetizations[0], item)
        } else {
            goMonetizationSelectCallback?.(monetizations, {
                item,
            })
        }
    }, [goLiveCallback, goPurchaseCallback, goVodCallback, goMonetizationSelectCallback])

    return { onPlayVideo }
}
