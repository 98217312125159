import { alpha, ButtonBase, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

import { AddIconButton } from '../components/AddIconButton'

import type { ButtonBaseProps, Theme } from '@material-ui/core'


interface StyleProps {
    height?: number | string
    position?: 'absolute' | 'relative'
}

const useStyles = makeStyles<Theme, StyleProps>({
    container: {
        backgroundColor: alpha('#0190c8', 0.14),
        width: '100%',
        height: ({ height }) => height,
        position: ({ position }) => position,
    },
    fakeButton: {
        pointerEvents: 'none',
    },
})

type Props = StyleProps & ButtonBaseProps

export const ContentPlaceholderButton = ({ position = 'relative', height = '39vh', className, ...props }: Props) => {
    const classes = useStyles({ height, position })

    return (
        <ButtonBase
            className={clsx(classes.container, className)}
            {...props}
        >
            <AddIconButton className={classes.fakeButton} />
        </ButtonBase>
    )
}
