import { Container, makeStyles, ThemeProvider } from '@material-ui/core'
import React from 'react'

import { lightTheme } from '../../../static/theme'
import AppLogo from '../../AppLogo'
import AuthTabs from '../../auth/AuthTabs'


const useStyles = makeStyles({
    page: {
        height: '80vh',
        paddingTop: '17vh',
    },
})

/**
 * Login page component
 * Serves for logging in into application or creating new user
 */
function LoginPage() {
    const classes = useStyles()

    return (
        <Container
            className={classes.page}
            maxWidth="sm"
        >
            <ThemeProvider theme={lightTheme}>
                <AppLogo/>
                <AuthTabs/>
            </ThemeProvider>
        </Container>
    )
}

export default LoginPage
