import { CircularProgress, Fade, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'

import { COLORS } from '../static/enum'

import type { GridItemsAlignment, GridJustification } from '@material-ui/core/Grid/Grid'
import type { FC, PropsWithChildren } from 'react'


export interface ContentPlace {
    alignItems: GridItemsAlignment,
    justifyContent: GridJustification,
}

export type TGetContentPlace = (cover: string | undefined) => ContentPlace

export interface AppTileProps {
    loading?: boolean
    progress?: number
}

type TileSize = 'mini' | 'small' | 'large' | 'avatar'

const getHeight = (size: TileSize) => {
    switch (size) {
        case 'avatar':
            return 45
        case 'mini':
            return 90
        case 'small':
            return 107
        default:
            return 152
    }
}

const getWidth = (size: TileSize) => {
    switch (size) {
        case 'avatar':
            return 45
        case 'mini':
            return 154
        case 'small':
            return 190
        default:
            return 270
    }
}

interface Props extends AppTileProps, PropsWithChildren {
    cover?: string | null
    contentPlace?: ContentPlace
    size: TileSize
    onMouseEnter?: () => void
    onMouseLeave?: () => void
}

const useStyles = makeStyles((theme) => ({
    tileContainer: {
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        backgroundImage: (props: Props) => `url(${props.cover})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
        borderRadius: theme.spacing(1),
        height: (props: Props) => getHeight(props.size),
        width: (props: Props) => getWidth(props.size),
    },
    tileProgress: {
        height: 4,
        position: 'absolute',
        left: 0,
        bottom: 0,
        backgroundColor: COLORS.PRIMARY,
        borderRadius: '0 0 4px 4px',
        transition: '.2s ease-in-out',
    },
}))

const AppTile: FC<Props> = (props) => {
    const classes = useStyles(props)
    const [hideProgress, setHideProgress] = useState(false)

    useEffect(
        () => {
            if (props.progress === 100) {
                // TODO handle un-mount during timeout
                setTimeout(
                    () => {
                        setHideProgress(true)
                    },
                    1000,
                )
            }
        },
        [props.progress],
    )

    const getUploadButtonPlace = (): ContentPlace => {
        if (props.loading) {
            return {
                justifyContent: 'center',
                alignItems: 'center',
            }
        }

        if (props.contentPlace) {
            return props.contentPlace
        }

        return {
            alignItems: 'center',
            justifyContent: 'center',
        }
    }

    return (
        <Grid
            container
            className={classes.tileContainer}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            {...getUploadButtonPlace()}
        >
            <Fade in={!hideProgress}>
                <div
                    className={classes.tileProgress}
                    style={{
                        right: props.progress ? `${100 - props.progress}%` : '100%',
                    }}
                />
            </Fade>
            <Grid item>
                {props.loading && <CircularProgress/>}
                {!props.loading && props.children}
            </Grid>
        </Grid>
    )
}

export default AppTile

export type {
    TileSize,
}
