import { makeStyles, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { observer } from 'mobx-react'
import React from 'react'

import { useAlert } from './hooks/uiHooks/useAlert'

import type { AlertProps } from '@material-ui/lab/Alert/Alert'


const useStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: 'pre-wrap',
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}))

/**
 * App notification component
 * Serves for throwing app notification.
 */
const AppAlert = observer(() => {
    const classes = useStyles()
    const {
        isOpen,
        type,
        text,
        close,
    } = useAlert()

    return (
        <div className={classes.root}>
            <Snackbar
                open={isOpen}
                autoHideDuration={6000}
                onClose={close}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
            >
                <Alert
                    onClose={close}
                    severity={type}
                >
                    {text}
                </Alert>
            </Snackbar>
        </div>
    )
})

function Alert (props: AlertProps) {
    return (
        <MuiAlert
            elevation={6}
            variant="filled"
            {...props}
        />
    )
}

export default AppAlert
