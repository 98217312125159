import { Grid, MenuItem, Select } from '@material-ui/core'
import { PurchaseStatus } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { UserPurchaseIcon } from './UserPurchaseIcon'


interface Props {
    value: PurchaseStatus
    onChange: (value: PurchaseStatus) => void
    itemIsDisabled?: (value: PurchaseStatus) => boolean
}

export const PurchaseStatusSelect = ({ value, onChange, itemIsDisabled }: Props) => {
    const [t] = useTranslation()
    return (
        <Select
            fullWidth
            value={value}
            onChange={(e) => onChange(e.target.value as PurchaseStatus)}
        >
            {Object.keys(PurchaseStatus).map((st) => {
                const status = PurchaseStatus[st as PurchaseStatus]
                return (
                    <MenuItem
                        key={status}
                        value={status}
                        disabled={itemIsDisabled?.(status)}
                    >
                        <Grid
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            container
                        >
                            <Grid item>
                                <UserPurchaseIcon purchaseStatus={status} />
                            </Grid>
                            <Grid item>{t(status)}</Grid>
                        </Grid>
                    </MenuItem>
                )
            })}
        </Select>
    )
}
