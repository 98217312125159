import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'


interface TProps {
    onEmailChange: (email: string) => void
    onFirstNameChange: (firstName: string) => void
    onLastNameChange: (lastName: string) => void
    error: string
    loading: boolean
    email: string
    firstName: string
    lastName: string
}

const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    paperList: {
        marginTop: theme.spacing(1),
    },
    option: {
        position: 'relative',
    },
}))

const OrganizationInvite = (props: TProps) => {
    const classes = useStyles()
    const [t] = useTranslation()
    const { onEmailChange, error, loading, onFirstNameChange, onLastNameChange } = props

    const renderInput = () => {
        return (
            <InputsWrapper>
                <TextField
                    variant="outlined"
                    onChange={(e) => onEmailChange(e.target.value)}
                    label="Email"
                    value={props.email}
                    error={!!error}
                    fullWidth
                    disabled={loading}
                    helperText={error}
                    placeholder="Email"
                />
                <TextField
                    variant="outlined"
                    onChange={(e) => onFirstNameChange(e.target.value)}
                    label="First name"
                    value={props.firstName}
                    error={!!error}
                    fullWidth
                    disabled={loading}
                    helperText={error}
                    placeholder="First name"
                />
                <TextField
                    variant="outlined"
                    onChange={(e) => onLastNameChange(e.target.value)}
                    label="Last name"
                    value={props.lastName}
                    error={!!error}
                    fullWidth
                    disabled={loading}
                    helperText={error}
                    placeholder="Last name"
                />
            </InputsWrapper>
        )
    }

    return (
        <>
            <Typography
                variant="body2"
                className={classes.title}
            >
                {t('Add users')}
            </Typography>
            {renderInput()}
        </>
    )
}

export default OrganizationInvite
