import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'


interface TProps {
    children?: React.ReactElement
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}))

/**
 * App loading fullscreen cover
 */
function AppLoading(props: TProps) {
    const classes = useStyles()

    return (
        <Backdrop
            className={classes.backdrop}
            open={true}
        >
            {props.children ?? <CircularProgress color="inherit" data-e2e="app-loading"/>}
        </Backdrop>
    )
}

export default AppLoading
