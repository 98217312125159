import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    width: 110px;
    height: 110px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Svg = styled.svg`
    animation: rotate 2s linear infinite;
    width: 50px;
    height: 50px;
    @keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}
`

const Circle = styled.circle`
    animation: dash 1.5s ease-in-out infinite;
    @keyframes dash {
        0% {
            stroke-dasharray: 1, 150;
            stroke-dashoffset: 0;
        }
        50% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -35;
        }
        100% {
            stroke-dasharray: 90, 150;
            stroke-dashoffset: -124;
        }
    }
`

export interface SpinnerProps {
    className?: string
}

export const Spinner = ({ className }: SpinnerProps) => {
    return (
        <Container className={className}>
            <Svg viewBox="0 0 50 50">
                <Circle
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    stroke="white"
                    strokeWidth="4"
                    strokeLinecap="round"
                />
            </Svg>
        </Container>
    )
}
