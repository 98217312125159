import { IconButton, InputLabel } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'


const StyledSelectWrapper = styled.div`
    display: grid;
    grid-template-columns: 200px minmax(0, 400px) max-content;
    grid-gap: 1rem;
    align-items: center;
    margin-bottom: 1rem;
`

interface Props extends PropsWithChildren {
    label?: string
    onClear?: () => void
}

export const InputWrapper = ({ label, onClear, children }: Props) => {
    return (
        <StyledSelectWrapper>
            {label && <InputLabel>{label}</InputLabel>}
            {children}
            {onClear && (
                <IconButton onClick={onClear}>
                    <ClearIcon />
                </IconButton>
            )}
        </StyledSelectWrapper>
    )
}
