import { Grid } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import { ColorCircle } from '../../color'

import { ListingItem, ListingRowName } from './ListingRow'


const ColorGrid = styled(Grid)`
    height: 20px;
    margin-right: 15px
`

interface Props {
    item: ListingItem
}

/**
 * Color circle + name
 */
export const ListingRowNameWithColor = ({item}: Props) => (
    <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
    >
        <ColorGrid>
            <ColorCircle color={item.extraProps?.color}/>
        </ColorGrid>
        <Grid item>
            <ListingRowName>{item.getName}</ListingRowName>
        </Grid>
    </Grid>
)
