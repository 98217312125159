import { Box, Container, makeStyles } from '@material-ui/core'
import { VideoSourceField } from '@tivio/types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import Video from '../../../store/Video'
import { validators } from '../../../utils/seekbar.utils'
import { PlayerController } from '../../player/Player'
import PlayerContainer from '../../player/PlayerContainer'
import PlayerControls from '../../player/PlayerControls'

import { SourceSelect } from './SourceSelect'
import { TrackSelect } from './TrackSelect'

import type { Track } from 'shaka-player'


const useStyles = makeStyles((theme) => ({
    playerContainer: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(2),
    },
    divider: {
        marginBottom: theme.spacing(2),
        height: 2,
    },
    controlContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '864px',
        marginTop: theme.spacing(2),
        margin: 'auto',
    },
    control: {
        marginLeft: '5px',
    },
}))

interface State {
    source: VideoSourceField,
    selectedTrack?: Track,
    availableTracks?: Track[],
}

interface Props {
    video: Video
}

export const VideoAnalyticsPlayer = ({ video }: Props) => {
    const classes = useStyles()
    const [isPlaying, setIsPlaying] = useState(false)
    const [contentDuration, setContentDuration] = useState(0)
    const [playingPosition, setPlayingPosition] = useState(0)
    const playerRef = useRef<PlayerController | null>(null)
    const [state, setState] = useState<State>({
        source: video.sources[0],
        selectedTrack: undefined,
    })

    useEffect(() => {
        (async () => {
            if (!state.source) {
                return
            }

            await playerRef.current?.load({
                source: video.sources.find(s => s.url === state.source.url)!,
                documentType: 'video',
                positionSec: 0,
                documentId: video.ref.id,
                sourceHistory: video.sources.filter(s => s.url !== state.source.url).map(s => s.url),
            })

            const availableTracks = playerRef.current?.getTracks()
            const track = playerRef.current?.getCurrentTrack()
            track && setState(state => ({ ...state, selectedTrack: track, availableTracks: availableTracks }))
        })()
    }, [video.ref.id, state.source, video.sources])

    const toggleVideo = useCallback(() => playerRef?.current?.toggle(), [playerRef])

    const onSetSource = useCallback((source?: VideoSourceField) => {
        setState(state => ({ ...state, source: source! }))
        setIsPlaying(false)
    }, [setState])

    const onSetTrack = useCallback((resolution: Track) => {
        playerRef.current?.selectTrack(resolution!)
        setState(state => ({ ...state, selectedTrack: resolution }))
    }, [setState])

    const seekPlayer = (sec: number) => {
        playerRef.current?.seek(sec)
    }

    const handleSeek = (sec: number) => {
        setPlayingPosition(sec)
        seekPlayer(sec)
    }

    const handleUpdatePlayingPosition = (step: number) => {
        const newFromPosition = playingPosition + step

        if (
            validators.isEqualToZero(playingPosition) ||
            !validators.isGreaterThanZero(newFromPosition) ||
            !validators.isLesserThanDuration(newFromPosition, contentDuration)
        ) {
            return
        }

        handleSeek(newFromPosition)
    }

    return (
        <Container maxWidth="lg">
            <PlayerContainer
                error={{ error: !state.source }}
                onDuration={setContentDuration}
                onPause={() => setIsPlaying(false)}
                onPlay={() => setIsPlaying(true)}
                onTimeUpdate={setPlayingPosition}
                onClick={toggleVideo}
                playerRef={playerRef}
            />
            <Box
                className={classes.controlContainer}
            >
                <PlayerControls
                    isPlaying={isPlaying}
                    onPause={() => playerRef.current?.pause()}
                    onPlay={() => playerRef.current?.play()}
                    onSeekWithControls={handleUpdatePlayingPosition}
                />
                <Box
                    className={classes.control}
                >
                    <SourceSelect
                        selectedSource={state.source}
                        groupedSources={{ [video.getName]: video.sources }}
                        onChange={onSetSource}
                        variant="outlined"
                    />
                </Box>
                <Box
                    className={classes.control}
                >
                    {
                        state.selectedTrack && state.availableTracks?.length
                            ? <TrackSelect
                                selectedTrack={state.selectedTrack}
                                tracks={state.availableTracks}
                                onChange={onSetTrack}
                            />
                            : null
                    }
                </Box>
            </Box>
        </Container>
    )
}
