import { MenuItem, Select } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { useOrganization } from '../../../hooks'
import { Row } from '../../../uiLayout/Row'

import type { SurveyFilterProps } from './types'
import type { AgeRange, NotificationSurveyFilterType } from '@tivio/types'


// shamelessly stolen from core-react/src/userProfiles.ts, please merge this function later
export function getAgeRangeText(t: (key: string) => string, ageRange: AgeRange) {
    let ageRangeText = ageRange.kidsOnly ? `${t('Child')} ` : ''

    ageRangeText += ageRange.from

    if (ageRange.to) {
        ageRangeText += ` - ${ageRange.to}`
    } else {
        ageRangeText += ' +'
    }

    return ageRangeText
}

export const SurveyFilter: React.FC<SurveyFilterProps<NotificationSurveyFilterType>> = ({ value, onChange }) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const ageRanges = organization?.userProfileConfiguration?.survey?.age?.values
    const gendersWithAll = useMemo(() => ['all', 'm', 'f'], [])

    return (
        <Row spacing={1}>
            <Select
                value={value.gender}
                onChange={(e) => onChange({ ...value, gender: e.target.value as NotificationSurveyFilterType['value']['gender'] })}
            >
                {gendersWithAll.map((gender) => (
                    <MenuItem
                        key={gender}
                        value={gender}
                    >
                        {gender}
                    </MenuItem>
                ))}
            </Select>
            {ageRanges && (
                <Select
                    value={ageRanges.findIndex((ageRange) => ageRange.from === value.ageFrom && ageRange.to === value.ageTo)}
                    onChange={(e) => {
                        const index = e.target.value as number
                        const ageRange = ageRanges[index]
                        onChange({ ...value, ageFrom: ageRange?.from, ageTo: ageRange?.to })
                    }}
                >
                    {ageRanges.map((ageRange, index) => (
                        <MenuItem
                            key={getAgeRangeText(t, ageRange)}
                            value={index}
                        >
                            {getAgeRangeText(t, ageRange)}
                        </MenuItem>
                    ))}
                </Select>
            )}
        </Row>
    )
}
