import { storeNotReady } from '..'
import { Article } from '../../../store/Article'

import { useOrganization } from './useOrganization'


export const useArticles = () => {
    const { organization } = useOrganization()

    if (!organization) {
        return {
            isLoading: true,
            fetchMore: storeNotReady,
            articles: [] as Article[],
        }
    }

    return {
        isLoading: organization.globalArticlesPagination.isLoading,
        fetchMore: organization.globalArticlesPagination.fetchMore,
        articles: organization.globalArticlesPagination.getItems,
    }
}
