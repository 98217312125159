import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { Skeleton } from '@material-ui/lab'
import { ApplicationType } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { createTranslation } from '../../../creator/translation.creator'
import { ApplicationsList } from '../../applications'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useApplications } from '../../hooks/dataHooks/useApplications'
import { Repeater } from '../../Repeater'
import { BigButton } from '../../styled'


const ApplicationsZeroState = styled(Skeleton)`
    margin-top: 16px;
    height: 30px;
`

const APPLICATION_ZERO_STATES_COUNT = 2

export const ApplicationsPage = observer(() => {
    const [t] = useTranslation()
    const { applications, isLoading, addApplication } = useApplications()

    const handleCreateApplication = () => {
        const applicationsCount = applications.length + 1

        addApplication({
            name: createTranslation(`New application ${applicationsCount}`),
            type: ApplicationType.ORGANIZATION,
        })
    }

    return (
        <>
            <DefaultPageContainer
                title={t('Applications')}
                buttons={
                    isLoading
                        ? []
                        : [
                            <Button
                                key="button-add-application"
                                startIcon={<Add />}
                                component="span"
                                size="small"
                                onClick={handleCreateApplication}
                            >
                                {t('Add application')}
                            </Button>,
                        ]
                }
            >
                {isLoading ? (
                    <Repeater count={APPLICATION_ZERO_STATES_COUNT}>
                        <ApplicationsZeroState animation="wave" />
                    </Repeater>
                ) : (
                    <>
                        <ApplicationsList applications={applications} />
                        <BigButton
                            fullWidth
                            variant="outlined"
                            onClick={handleCreateApplication}
                        >
                            {t('Add new application')}
                        </BigButton>
                    </>
                )}
            </DefaultPageContainer>
        </>
    )
})
