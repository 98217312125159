import { StrategyName } from '@tivio/firebase'
import { TvStreamType } from '@tivio/types'

import { AdProfileDocumentWithId } from '../../../firebase/firestore/converters'
import { createLogger } from '../../../logger'

import { useOrganization } from './useOrganization'


const logger = createLogger('useAds')

// TODO maybe delete this totally and use org directly
const useAds = () => {
    const { organization } = useOrganization()

    const addAd = async () => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        const adsCount = organization.ads.length + 1
        await organization.addAd({
            title: `Ad ${adsCount}`,
            variants: [{type: 'default', strategies: {}}],
        })
    }

    const removeAd = async (adId: string) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.removeMonetization(adId)
    }

    const addAdSlot = async (monetizationId: string, variantId: string, strategyName: StrategyName, adSlot: AdProfileDocumentWithId) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.addAdSlot(monetizationId, variantId, strategyName, adSlot)
    }

    const changeOrderOfAdSlots = async (monetizationId: string, variantId: string, strategyName: StrategyName, adSlots: AdProfileDocumentWithId[]) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.changeOrderOfAdSlots(monetizationId, variantId, strategyName, adSlots)
    }

    const deleteAdSlot = async (monetizationId: string, variantId: string, strategyName: StrategyName, index: number) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.deleteAdSlot(monetizationId, variantId, strategyName, index)
    }

    const deleteAdConfig = async (monetizationId: string, variantId: string, strategyName: StrategyName) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.deleteAdConfig(monetizationId, variantId, strategyName)
    }

    const changeMidRollInterval = async (monetizationId: string, variantId: string, interval: number | string) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.changeMidRollInterval(monetizationId, variantId, interval)
    }

    const setPlacement = async (
        monetizationId: string,
        checkedTvChannels: string[],
        checkedVodChannels: string[],
        checkedSections: string[],
        checkedVideos: string[],
        tvStream: TvStreamType,
    ) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.setMonetizationPlacement(
            monetizationId,
            checkedTvChannels,
            checkedVodChannels,
            checkedSections,
            checkedVideos,
            tvStream,
        )
    }

    const updateAdTitle = async (
        monetizationId: string,
        title: string,
    ) => {
        if (!organization) {
            logger.error('organization not ready')
            return
        }

        await organization.updateMonetizationTitle(monetizationId, title)
    }

    return {
        ads: organization?.ads ?? [],
        addAd,
        removeAd,
        addAdSlot,
        changeOrderOfAdSlots,
        deleteAdSlot,
        deleteAdConfig,
        changeMidRollInterval,
        setPlacement,
        updateAdTitle,
    }
}

export {
    useAds,
}
