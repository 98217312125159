import { CachedPagination, Comment, tivio } from '@tivio/core-js'
import { upsertComment } from '@tivio/firebase'
import { autorun } from 'mobx'
import { useCallback, useEffect, useState } from 'react'


import Translator from '../../services/Translator'

import { useError } from './useError'

import type { CommentDocument, EditCommentRequest } from '@tivio/firebase'
import type {
    AddCommentOptions,
    Comment as CommentType,
    Disposer,
    PaginationInterface,
    UseComments,
} from '@tivio/types'


export const useComments: UseComments = ({ videoId, mainParentId, autoInit }) => {
    const [pagination, setPagination] = useState<
        PaginationInterface<CommentType>
    >({
        fetchMore: () => {},
        items: [],
        hasNextPage: false,
        loading: false,
    })

    const { error, raiseError } = useError()

    useEffect(() => {
        let disposer: Disposer | null = null

        const loadData = () => {
            try {
                tivio.subscribeToContentComments(
                    mainParentId ? { mainParentId } : { contentId: videoId },
                    (pagination: CachedPagination<CommentDocument, Comment>) => {
                        disposer = autorun(() => {
                            setPagination({
                                fetchMore: pagination.fetchMore,
                                items: pagination.items,
                                hasNextPage: pagination.hasNextPage,
                                loading: pagination.loading,
                            })
                        })
                    }
                )
            } catch (error) {
                raiseError(error)
            }
        }

        if (autoInit) {
            loadData()
        }

        return () => {
            if (disposer) {
                disposer()
            }
        }
    }, [videoId, mainParentId, autoInit])

    const addComment = useCallback(
        async (data: AddCommentOptions) => {
            const result = await upsertComment({
                ...data,
                ...(data.parentCommentId &&
                    { parentPath: `comments/${data.parentCommentId}` }),
                contentPath: `videos/${videoId}`,
            })

            if ('id' in result) {
                return {
                    id: result.id,
                }
            } else {
                return {
                    error: Translator.t('There was an error adding the comment. Please try again.'),
                }
            }
        },
        [videoId],
    )

    const editComment = useCallback(
        async (data: EditCommentRequest) => {
            const result = await upsertComment({
                ...data,
                commentId: data.commentId,
            })

            if ('id' in result) {
                return {
                    id: result.id,
                }
            } else {
                return {
                    error: Translator.t('There was an error editing the comment. Please try again.'),
                }
            }
        },
        [],
    )

    const deleteComment = useCallback(
        async (commentId: string) => {
            const result = await upsertComment({
                commentId,
                isDeleted: true,
            })

            if ('deleted' in result) {
                return {
                    deleted: true,
                }
            } else {
                return {
                    error: Translator.t('There was an error deleting the comment. Please try again.'),
                }
            }
        },
        [],
    )

    return {
        pagination,
        error,
        addComment,
        editComment,
        deleteComment,
    }
}
