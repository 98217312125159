import { Box, Grid, IconButton, makeStyles } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioScreen } from '../../store/TivioScreen'
import { alertSuccess } from '../../utils/alert.utils'
import { goScreenPage } from '../../utils/history.utils'
import { useListItemStyles } from '../common/styles'


type ScreensItemProps = {
    screen: TivioScreen,
    onRename: (screen: TivioScreen) => void,
    onDelete: (screen: TivioScreen) => void,
}

const useStyles = makeStyles(theme => ({
    screenMain: {
        fontSize: '1rem',
    },
    screenInfo: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
        maxWidth: 270,
        width: 270,
        flexBasis: 270,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    blockIcon: {
        display: 'block',
    },
}))

export const ScreensItem: React.FC<ScreensItemProps> = ({ screen, onRename, onDelete }) => {
    const listItemClasses = useListItemStyles()
    const classes = useStyles()
    const [t] = useTranslation()
    const [isHovering, setIsHovering] = useState(false)

    const handleMouseEnter = () => {
        setIsHovering(true)
    }

    const handleMouseLeave = () => {
        setIsHovering(false)
    }

    const handleItemClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        goScreenPage(screen.id)
    }

    const handleRenameClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onRename(screen)
    }

    const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        e.preventDefault()

        onDelete(screen)
    }

    const handleIdClick = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()

        navigator.clipboard.writeText(screen.screenId)
        alertSuccess(t('Screen ID copied to clipboard.'))
    }

    return (
        <Box my={2}>
            <Grid
                container
                alignItems="center"
                spacing={2}
                justifyContent="space-between"
                className={listItemClasses.root}
                component="a"
                href={`/screens/${screen.id}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleItemClick}
                title={t('Show screen content')}
            >
                <Grid
                    item
                    className={classes.screenMain}
                    xs={4}
                >
                    {screen.name.en}
                </Grid>
                <Grid
                    item
                    className={classes.screenInfo}
                >
                    {screen.description?.en}
                </Grid>
                <Grid
                    item
                    className={classes.screenInfo}
                    xs={4}
                    onClick={handleIdClick}
                    title={t('Copy screen ID to clipboard')}
                >
                    {screen.screenId}
                </Grid>
                <Grid
                    item
                >
                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        className={clsx(listItemClasses.showOnHover, { isHover: isHovering })}
                    >
                        <Grid item>
                            <IconButton
                                size="small"
                                title={t('Rename screen')}
                                onClick={handleRenameClick}
                                className={classes.blockIcon}
                            >
                                <EditIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton
                                size="small"
                                title={t('Delete screen')}
                                onClick={handleDeleteClick}
                                className={classes.blockIcon}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Box>
    )
}
