import { useEffect } from 'react'

import { useOrganization } from './useOrganization'


const useScreen = (screenId: string) => {
    const { organization } = useOrganization()

    useEffect(() => {
        organization?.loadScreenById(screenId, { loadRows: true })
    }, [screenId])

    return {
        screen: organization?.getScreenById(screenId),
        loading: organization?.screensState.loading ||
            organization?.screensState.data.some((screen) =>
                screen.rowsState.loading,
            ),
        errors: organization?.screensState.errors,
    }
}

export { useScreen }
