import { makeAutoObservable } from 'mobx'

import type { Color } from '@material-ui/lab'


class Alert {
    private text = ''
    private type: Color = 'info'
    private open = false

    constructor() {
        makeAutoObservable(this)
    }

    showAlert = (text: string) => {
        this.open = true
        this.text = text
    }

    showSuccessAlert = (text: string) => {
        this.type = 'success'
        this.showAlert(text)
    }

    showErrorAlert = (text: string) => {
        this.type = 'error'
        this.showAlert(text)
    }

    closeAlert = () => {
        this.open = false
    }

    get getText() {
        return this.text
    }

    get getType() {
        return this.type
    }

    get isOpen() {
        return this.open
    }
}

export default Alert
