import { Box, Container, Fade, Grid } from '@material-ui/core'
import { PublishedStatus, VideoType } from '@tivio/types'
import { observer } from 'mobx-react'
import { nanoid } from 'nanoid'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next/hooks'
import { Prompt } from 'react-router'

import { firebaseTimestampFromDate } from '../../firebase/app'
import Logger from '../../logger'
import Video from '../../store/Video'
import { VideoUploaderInterface } from '../../store/VideoUploader'
import { alertError } from '../../utils/alert.utils'
import { onDragEndUtil } from '../../utils/order.utils'
import { getVideoInfo, VideoToVideoInterface } from '../../utils/videoEditor.utils'
import AppDropzone from '../AppDropzone'

import SectionDropzoneContainer, { DropzoneContainerProps } from './SectionDropzoneContainer'
import SectionElementInterface from './SectionElementInterface'

import type { ElementInterface, SectionElementInterfaceProps } from './SectionElementInterface'


interface Props extends DropzoneContainerProps {
    uploader?: VideoUploaderInterface
    /**
     * Acceptable file types
     * @default ['.mp4']
     */
    accept?: string[]
    videoInterfaces: VideoInterface[]
    onVideosChange?: (videos: VideoInterface[]) => void
    playlistProps?: {
        playlistInstance: Video | null
        onDelete: (video: VideoInterface) => void
        onVideosOrderChange: (videos: VideoInterface[]) => void
        requestPlaylist?: () => Promise<Video>
    }
}

export interface VideoInterface extends ElementInterface {
    originalName?: string
    videoFile?: File
    videoInstance?: Video
    onProgress?: (id: string, progress: number) => void
    onVideo?: (video: Video) => void
}

export interface Progress {
    [key: string]: number
}

const logger = new Logger('SectionVideosEditor')

/**
 * Section videos editor component
 * Serves for preparing/updating videos data before upload/update
 */
const SectionVideosEditor = observer((props: Props) => {
    const [t] = useTranslation()
    const [videosInterfaces, setVideosInterfaces] = useState<VideoInterface[]>(props.videoInterfaces)
    const [progresses, setProgresses] = useState<Progress>({})
    const videosRef = useRef(videosInterfaces)
    const progressesRef = useRef(progresses)

    const canLeave = useMemo(() => Object.values(progresses).every((progress) => progress === 100), [progresses])

    useEffect(() => {
        videosRef.current = videosInterfaces
        props.onVideosChange?.(videosInterfaces)
    }, [videosInterfaces])

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (!canLeave) {
                e.preventDefault()
                e.returnValue = ''
            }
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [canLeave])

    const handleElementChange = async (updatedVideoInterface: VideoInterface) => {
        if (updatedVideoInterface.videoInstance) {
            try {
                const { videoInstance, ...updatedVideoInterfaceWithoutInstance } = updatedVideoInterface
                await videoInstance.update({
                    ...updatedVideoInterfaceWithoutInstance,
                    name: updatedVideoInterface.name,
                    description: updatedVideoInterface.description,
                    created: updatedVideoInterface.created,
                })
            } catch (e) {
                logger.error(e)
            }
        }

        setVideosInterfaces(
            videosInterfaces.map((videoInterface) => {
                if (videoInterface.id === updatedVideoInterface.id) {
                    return updatedVideoInterface
                }

                return videoInterface
            }),
        )
    }

    const handleDelete = (videoInterface: VideoInterface) => {
        props.playlistProps?.onDelete(videoInterface)

        if (videoInterface.videoInstance) {
            props.uploader?.deleteElement(videoInterface.videoInstance)
        }

        setVideosInterfaces(videosInterfaces.filter((video) => video.id !== videoInterface.id))
    }

    const handleNewVideoInterface = (videoInterface: VideoInterface) => {
        const newVideoInterfaces = videosRef.current.concat([videoInterface])

        setVideosInterfaces(newVideoInterfaces)
    }

    const handleUpdateProgress = (id: string, progress: number) => {
        const newProgress = {
            ...progressesRef.current,
            [id]: progress,
        }

        progressesRef.current = newProgress
        setProgresses(newProgress)
    }

    const handleNewVideo = (video: Video) => {
        const videoInterface = VideoToVideoInterface(video)
        handleNewVideoInterface(videoInterface)
    }

    const handleNewFiles = async (files: File[]) => {
        try {
            // @ts-expect-error
            const newVideosInterfaces: VideoInterface[] = await Promise.all(
                files.map(async (file) => {
                    const { duration, name } = await getVideoInfo(file)

                    return {
                        id: nanoid(),
                        name: name,
                        originalName: file.name,
                        hide: !!props.playlistProps,
                        description: t('Video description'),
                        created: firebaseTimestampFromDate(),
                        publishedStatus: PublishedStatus.DRAFT,
                        videoFile: file,
                        type: VideoType.VIDEO,
                        duration: duration,
                        onProgress: handleUpdateProgress,
                        onVideo: handleNewVideo,
                        organizationRef: props.uploader?.getOrganizationRef,
                    }
                }),
            )

            if (props.playlistProps) {
                let playlistInstance = props.playlistProps.playlistInstance

                if (!playlistInstance && props.playlistProps.requestPlaylist) {
                    playlistInstance = await props.playlistProps.requestPlaylist()
                }

                if (playlistInstance) {
                    await playlistInstance.addVideos(newVideosInterfaces)
                }
            } else {
                await props.uploader?.uploadVideos(newVideosInterfaces, false)
            }
        } catch (e) {
            alertError(t('Failed to add new videos'))
            logger.error(e)
        }
    }

    const handleChangeOrder = (result: DropResult) => {
        const newVideos = onDragEndUtil(result, videosInterfaces)

        if (newVideos) {
            setVideosInterfaces(newVideos)
            props.playlistProps?.onVideosOrderChange(newVideos)
        }
    }

    const renderOrderedFilesPreview = (getSectionElementProps: (video: VideoInterface) => SectionElementInterfaceProps) => {
        return (
            <DragDropContext onDragEnd={handleChangeOrder}>
                <Droppable droppableId="sections">
                    {(provided) => (
                        <Grid
                            {...provided.droppableProps}
                            container
                            direction="column"
                            spacing={2}
                            innerRef={provided.innerRef}
                        >
                            {videosInterfaces.map((video, index) => (
                                <Draggable
                                    draggableId={video.id}
                                    key={video.id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <Fade in={true}>
                                            <Grid
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                item
                                                key={video.id}
                                                innerRef={provided.innerRef}
                                            >
                                                <SectionElementInterface {...getSectionElementProps(video)} />
                                            </Grid>
                                        </Fade>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </Grid>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    const renderFilesPreview = (getSectionElementProps: (video: VideoInterface) => SectionElementInterfaceProps) => {
        return (
            <Grid
                container
                direction="column"
                spacing={2}
            >
                {videosInterfaces.map((video) => (
                    <Fade
                        in={true}
                        key={video.id}
                    >
                        <Grid item>
                            <SectionElementInterface {...getSectionElementProps(video)} />
                        </Grid>
                    </Fade>
                ))}
            </Grid>
        )
    }

    const renderFiles = () => {
        const inPlaylist = !!props.playlistProps

        const getSectionElementProps: (video: VideoInterface) => SectionElementInterfaceProps = (video: VideoInterface) => {
            return {
                element: video,
                inPlaylist,
                loading: false,
                onChange: handleElementChange,
                progress: progresses[video.id],
                videoInstance: video.videoInstance,
                ...(inPlaylist
                    ? {
                        onDelete: handleDelete,
                    }
                    : {
                        onDetailClick: () => video.videoInstance?.goSectionUpdatePage(),
                    }),
            }
        }

        return inPlaylist ? renderOrderedFilesPreview(getSectionElementProps) : renderFilesPreview(getSectionElementProps)
    }

    return (
        <>
            <Prompt
                when={!canLeave}
                message={t('Are you sure you want to leave? Some videos are still uploading.')}
            />
            <Container maxWidth="lg">
                {videosInterfaces.length !== 0 && <Box pb={5}>{renderFiles()}</Box>}
                <Box>
                    <AppDropzone
                        onFiles={handleNewFiles}
                        accept={props.accept ?? ['.mp4']}
                    >
                        <SectionDropzoneContainer
                            dropzoneCaption={props.dropzoneCaption}
                            dropzoneTitle={props.dropzoneTitle}
                        />
                    </AppDropzone>
                </Box>
            </Container>
        </>
    )
})

export default SectionVideosEditor
