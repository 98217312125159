import { FormControlLabel, makeStyles, Switch, Typography } from '@material-ui/core'
import React, {
    FC,
    Fragment,
    ReactNode,
    useEffect,
    useState,
} from 'react'

import { Column } from '../uiLayout/Column'

import { PlacementRow } from './PlacementRow'


const useStyles = makeStyles(() => ({
    label: {
        fontWeight: 'bold',
    },
    child: {
        marginLeft: '20px',
    },
}))

type TransformId = (id: string) => string

interface Element {
    getId: string
    getName: string
}

interface CheckedPlacementProps {
    label: string
    elements: Element[]
    checkedElements: string[]
    changeCheckedElements: (ids: string[]) => void
    renderChecked?: (elementId: string) => ReactNode
    transformId?: TransformId
    uncheckOnlyNested?: boolean
    expandByDefault?: boolean
}

const isAnyCheckedElementInElements = (checkedElements: string[], elements: Element[], transformId: TransformId) =>
    Boolean(checkedElements
        .find((elementId) => elements
            .find((element) => transformId(element.getId) === elementId)))


// TODO rename this to PlacementGroup? now it looks like it is related to video sections but it's not
export const PlacementSection: FC<CheckedPlacementProps> = ({
    label,
    elements,
    checkedElements,
    changeCheckedElements,
    renderChecked,
    transformId = (arg: string) => arg,
    uncheckOnlyNested,
    expandByDefault,
}) => {
    const classes = useStyles()
    const [ checked, setChecked ] = useState(() => (!expandByDefault)
        ? isAnyCheckedElementInElements(checkedElements, elements, transformId)
        : true,
    )
    const onCheck = (id: string) => changeCheckedElements([...checkedElements, id])
    const unCheck = (id: string) => changeCheckedElements(checkedElements.filter((checked) => checked !== id))

    useEffect(() => {
        if (!expandByDefault) {
            setChecked(isAnyCheckedElementInElements(checkedElements, elements, transformId))
        }
    }, [checkedElements, elements])

    return (
        <Column spacing={1}>
            <FormControlLabel
                control={
                    <Switch
                        disabled={false}
                        checked={checked}
                        onChange={() => {
                            setChecked(!checked)
                            if (checked) {
                                if (uncheckOnlyNested) {
                                    changeCheckedElements(checkedElements
                                        .filter((elementId) => !elements
                                            .find((element) => transformId(element.getId) === elementId)))
                                } else {
                                    changeCheckedElements([])
                                }
                            }
                        }}
                        color="primary"
                    />
                }
                label={<Typography variant="h6" className={classes.label}>{label}</Typography>}
            />
            {checked && (
                <Column spacing={1} columnProps={{className: classes.child}}>
                    {elements.map((element) => (
                        <Fragment key={element.getId}>
                            <PlacementRow
                                element={{
                                    getId: transformId(element.getId),
                                    getName: element.getName,
                                }}
                                onCheck={() => onCheck(transformId(element.getId))}
                                onUncheck={() => unCheck(transformId(element.getId))}
                                checked={checkedElements.includes(transformId(element.getId))}
                            />
                            {(renderChecked) && (
                                <div className={classes.child}>
                                    {renderChecked(transformId(element.getId))}
                                </div>
                            )}
                        </Fragment>
                    ))}
                </Column>
            )}
        </Column>
    )
}
