import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Monetization from '../../../store/Monetization'
import { goTransactionPage } from '../../../utils/history.utils'
import { Listing } from '../../common/listing/Listing'
import { ListingItem } from '../../common/listing/ListingRow'
import { DefaultPageContainer } from '../../DefaultPageContainer'
import { useTransactions } from '../../hooks/dataHooks/useTransactions'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'
import { formatPlacementDescription } from '../../monetization/MonetizationPlacementsList'


const formatPricesDescription = (monetization: Monetization) => {
    const prices = monetization.getPrices
    let description = ''
    if (prices.CZK) {
        description += `${prices.CZK} Kč`
    }
    if (prices.EUR || prices.USD) {
        description += ` (${[
            prices.EUR && `${prices.EUR} €`,
            prices.USD && `${prices.USD} $`,
        ].filter((item) => !!item).join(', ')})`
    }
    return description
}

const formatPeriodDescription = (monetization: Monetization) => {
    const period = monetization.getDurationDays

    if (!period) {
        return ''
    }

    let description = ''
    const hours = period * 24
    description += ` ${hours} hour${period > 3 ? 's' : ''}`
    return description
}

const transactionsConverter = (transaction: Monetization): ListingItem => ({
    getId: transaction.getId,
    getName: transaction.getName,
    extraColumns: [
        formatPlacementDescription(transaction),
        formatPricesDescription(transaction),
        formatPeriodDescription(transaction),
    ],
})

export const TransactionsPage = observer(() => {
    const { transactions, addTransaction, removeTransaction } = useTransactions()
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()

    const handleDeleteTransaction = (name: string, id: string) => {
        confirmAction(
            () => removeTransaction(id),
            t(`Are you sure you want to delete the monetization "${name}"?`),
        )
    }

    return (
        <DefaultPageContainer
            title={t('Transactions')}
            buttons={[
                <Button
                    key="button-add-ad"
                    startIcon={<Add/>}
                    component="span"
                    size="small"
                    onClick={addTransaction}
                >
                    {t('Add purchase')}
                </Button> ]}
        >
            <Listing
                items={transactions.map(transactionsConverter)}
                onClick={transaction => goTransactionPage(transaction.getId)}
                onUpdateClick={transaction => goTransactionPage(transaction.getId)}
                onDeleteClick={transaction => handleDeleteTransaction(transaction.getName, transaction.getId)}
            />
        </DefaultPageContainer>
    )
})

export default TransactionsPage
