import { UserDocument } from '@tivio/firebase'
import { observer } from 'mobx-react'
import React from 'react'
import { useParams } from 'react-router'

import { UserLayoutParams } from '../layout/UserLayout'
import { DocumentEditor } from "../../documentEditor/DocumentEditor"

type TPrintableDocument = Partial<UserDocument | null>
type TLoadedDocument = UserDocument

const trimLoadedData = (data: TLoadedDocument): TPrintableDocument => {
    const {
        organizationRef,
        referralRef,
        followedOrganizationRefs,
        tivioUserRef,
        watchHistory,
        watchPositions,
        favorites,
        availablePromotions,
        ...printableData
    } = data

    return {
        ...printableData,
    }
}

export const UserEditPage = observer(() => {
    const { userId } = useParams<UserLayoutParams>()

    return (
        <DocumentEditor<TPrintableDocument, TLoadedDocument>
            collectionName="users"
            documentId={userId}
            trimLoadedData={trimLoadedData}
        />
    )
})
