import { useT } from '@tivio/common'
import React, { FC, useCallback } from 'react'

import { getSkipButtonData } from '../utils'

import { useAd, useCurrentTime } from './player'


type SkipButtonProps = {
    disabled?: boolean
    title?: string
    onPress?: () => void // react-native event
    onClick?: () => void // react event
}

type Props = {
    Button: React.ComponentType<SkipButtonProps>
    Label: React.ComponentType<{ children?: React.ReactNode }>
    Container: React.ComponentType<{ children?: React.ReactNode }>
}

const SkipAdButton: FC<Props> = ({ Button, Label, Container }) => {
    const t = useT()
    const [ ad ] = useAd()
    const currentTime = useCurrentTime()
    const action = useCallback(() => {
        if (ad?.isSkippable && ad?.canSkip) {
            ad?.skip()
        }
    }, [ ad, currentTime ])

    if (!ad) {
        return null
    }

    const { text, label, enabled } = getSkipButtonData(t, ad)

    return (
        <Container>
            {(!enabled && label) && (
                <Label>
                    {label}
                </Label>
            )}
            <Button
                disabled={!enabled}
                title={text}
                onClick={action}
                onPress={action}
            />
        </Container>
    )
}

export {
    SkipAdButton,
}

export type {
    SkipButtonProps,
}
