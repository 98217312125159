import { Button, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Target from '../../store/Target'

import DialogBase from './DialogBase'

import type { TDialogProps } from './DialogBase'


interface TProps extends TDialogProps<void> {
    /* organization: Organization */
    target: Target | null
}

const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        fontWeight: 'bold',
    },
    paper: {
        width: 560,
    },
    tabs: {
        marginTop: '-2rem',
        marginBottom: '2rem',
        boxShadow: '0px -1px 0px #d3d3d3 inset',
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
    addButton: {
        marginTop: theme.spacing(8),
    },
}))

const DialogAddDevice = observer((props: TProps) => {
    const { target, onConfirm } = props

    if (!target) {
        return null
    }

    const classes = useStyles({ size: 'small' })
    const [ t ] = useTranslation()

    const [ name, setName ] = useState<string>('')
    const [ description, setDescription ] = useState<string>('')
    const [ valid, setValid ] = useState(true)

    const handleAdd = async () => {
        await target.addDevice(name, description)
        onConfirm?.()
    }

    useEffect(() => {
        const isValid = name.length > 0
        if (isValid !== valid) {
            setValid(isValid)
        }
    }, [name, description])

    return (
        <DialogBase
            onCancel={props.onCancel}
            open={props.open}
            showCloseIcon={true}
            hideActionsButtons={true}
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <Grid
                container
                direction="column"
                spacing={2}
            >
                <Grid item>
                    <TextField
                        label={t('Name')}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label={t('Description')}
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                    />
                </Grid>
                <Grid item>
                    <Button
                        className={classes.addButton}
                        color="primary"
                        variant="contained"
                        disabled={!valid}
                        fullWidth
                        onClick={handleAdd}
                    >
                        {t('Add')}
                    </Button>
                </Grid>
            </Grid>
        </DialogBase>
    )
})

export default DialogAddDevice
