import Button from '@material-ui/core/Button'
import { ApplicationType, NotificationTriggerType } from '@tivio/types'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useParams } from 'react-router'

import { getFunctions } from '../../../firebase/app'
import { TivioNotification } from '../../../store/Notification'
import { Template } from '../../../store/Template'
import AppLoading from '../../AppLoading'
import { DefaultPageHeader } from '../../DefaultPageHeader'
import { DefaultPageTabs, PageTab } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { useApplications } from '../../hooks/dataHooks/useApplications'
import { useNotification } from '../../hooks/dataHooks/useNotification'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../../hooks/uiHooks/useConfirmAction'

import { NotificationContent } from './NotificationContent'
import { NotificationContext } from './NotificationContext'
import { NotificationSettings } from './NotificationSettings'
import { TestSendDialog } from './TestSendDialog'

import type { NotificationTargeting, SendTivioTemplateEmailRequest, Translation } from '@tivio/types'



export interface EmailNotificationForm {
    name: string
    templateId: string
    subject: Translation
    preHeader: Translation
    sender: string
    targeting: NotificationTargeting | null
    triggerType: NotificationTriggerType
    date?: Dayjs
    time?: Dayjs
}

export const EmailNotification = observer(() => {
    const { confirmAction } = useConfirmAction()
    const params = useParams<{ notificationId: string }>()
    const [t] = useTranslation()
    const { showSuccess, showError } = useAlert()
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [isTestSendOpen, setIsTestSendOpen] = useState(false)

    const { organization } = useOrganization()
    const { notification } = useNotification(params.notificationId)
    const { applications } = useApplications()

    const templates = organization?.emailTemplates ?? null
    const isTivioPro = applications.some((application) => application.type === ApplicationType.TIVIO_PRO)

    // TODO: This is subscribing and unsubscribing, then subscribing again. This is not necessary.
    useEffect(() => {
        if (notification) {
            notification.subscribe()
            return () => {
                notification.unsubscribe()
            }
        }
    }, [notification])

    const handleScheduleNotification = async () => {
        if (notification?.triggerType === 'scheduled') {
            const body = {
                notificationPath: notification.ref.path,
            }
            const scheduleNotification = getFunctions().httpsCallable('scheduleNotification')
            await scheduleNotification(body)
            showSuccess(t('Notification scheduled'))
        }
    }

    const handleSaveNotification = async () => {
        if (!notification) {
            return
        }
        setIsLoading(true)
        try {
            // check this out if it's correct
            // if (isTivioPro
            //         && !['manual', 'scheduled', 'userRegistration'].includes(notification.triggerType)
            //         && (!notification.targeting?.monetizationRef && !notification.targeting?.membershipRef)) {
            //     showError(t('Please select a monetization or membership'))
            //     return
            // }
            await notification.save()
            if (notification.triggerType === 'scheduled') {
                if (!notification.scheduleTime) {
                    showError(t('Please select a date'))
                    return
                }
                await handleScheduleNotification()
            }
            showSuccess(t('Notification saved'))

            return true
        } catch (error) {
            console.error('Error saving notification:', error)
            showError(error.message)
        } finally {
            setIsLoading(false)
        }
    }

    const handleSendTestNotification = async (email: string) => {
        try {
            const isSaved = await handleSaveNotification()
            if (!isSaved) {
                return
            }
            // TODO: Better validation
            if (!email || !email.includes('@')) {
                showError(t('Please enter an email address'))
                return
            }
            if (notification && organization) {
                const body: SendTivioTemplateEmailRequest = {
                    notificationId: notification.id,
                    organizationId: organization.id,
                    testEmailAddress: email,
                }
                const sendTestNotification = getFunctions().httpsCallable('sendTivioTemplateEmail')
                await sendTestNotification(body)
                showSuccess(t('Test email sent successfully'))
            }
        } catch (error) {
            console.error('An error occurred when sending test email.', error)
            showError('An error occurred when sending test email.')
        }
    }

    const handleSendNotification = async () => {
        try {
            const isSaved = await handleSaveNotification()
            if (!isSaved) {
                return
            }
            if (notification && organization) {
                confirmAction(async () => {
                    const body = {
                        notificationId: notification.id,
                        organizationId: organization.id,
                    }
                    const sendTivioTemplateEmail = getFunctions().httpsCallable('sendTivioTemplateEmail')
                    await sendTivioTemplateEmail(body)
                    showSuccess('Newsletter emails sent successfully')
                }, `${t('Are you sure you want to send emails')}?`)
            }
        } catch (error) {
            console.error('An error occurred when sending newsletter emails.', error)
            showError('An error occurred when sending newsletter emails.')
        }
    }

    const getSubstitutionValue = (substitutionKey: string) => {
        return notification?.substitutions.find((substitution) => substitution.key === substitutionKey)?.value ?? ''
    }

    const pageTabs = (notification: TivioNotification, templates: Template[]): PageTab[] => [
        {
            title: t('Settings'),
            component: <NotificationSettings
                templates={templates}
                notification={notification}
            />,
        },
        {
            title: t('Content'),
            component: <NotificationContent
                templates={templates}
                notification={notification}
                substitutions={notification.substitutions}
                getSubstitutionValue={getSubstitutionValue}
            />,
        },
    ]

    const renderContent = () => {
        if (!notification) {
            return <AppLoading />
        }

        return templates ? <DefaultPageTabs
            tabs={pageTabs(notification, templates)}
            currentTabIndex={activeTabIndex}
            onChange={setActiveTabIndex}
        /> : <AppLoading />
    }

    const buttons = [
        ...(notification?.triggerType === 'manual' ? [
            <Button
                key="send-test-notification"
                variant="outlined"
                onClick={() => setIsTestSendOpen(true)}
            >
                {t('Test send')}
            </Button>,
            <Button
                key="send-notification"
                variant="outlined"
                onClick={handleSendNotification}
            >
                {t('Send')}
            </Button>,
        ] : []),
        <Button
            key="save-notification"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={handleSaveNotification}
        >
            {t('Save')}
        </Button>,
    ]

    return (
        <NotificationContext.Provider value={{ notification }}>
            <DefaultPageHeader
                title={notification?.name ?? t('New notification')}
                buttons={buttons}
            />
            {renderContent()}
            <TestSendDialog
                open={isTestSendOpen}
                onSubmit={handleSendTestNotification}
                onClose={() => setIsTestSendOpen(false)}
            />
        </NotificationContext.Provider>
    )
})
