import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { useOrganization } from '../../hooks'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'

import { MonetizationIncomeDetail } from './types'


const StyledBoldTypography = styled(Typography)`
    font-weight: bold;
`

const StyledSubtitleTypography = styled(Typography) <{ $textAlign?: string }>`
    font-size: 0.75em;
    ${props => props.$textAlign && `text-align: ${props.$textAlign};`}
`

interface Props {
    monetizationIncomeDetails: MonetizationIncomeDetail[]
}

export const TotalMonetizationSummaryList: React.FC<Props> = (props) => {
    const { monetizationIncomeDetails } = props

    const { organization } = useOrganization()
    const [t] = useTranslation()

    const getMonetizationTypeString = (monetizationIncomeDetail: MonetizationIncomeDetail) => {
        return monetizationIncomeDetail.monetizationType === 'subscription' ? t('Subscription') : t('PPV')
    }

    return (
        <Column spacing={2}>
            <Typography variant="h6">
                Income list
            </Typography>
            <Row
                spacing={2}
                itemProps={{ xs: 12 }}
            >
                {monetizationIncomeDetails.map(monetizationIncomeDetail => (
                    <Row
                        key={monetizationIncomeDetail.monetizationId}
                        rowProps={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                        itemProps={{ xs: 6 }}
                    >
                        <Row
                            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
                            itemProps={{ xs: 6 }}
                        >
                            <StyledBoldTypography variant="caption">
                                {monetizationIncomeDetail.title}
                            </StyledBoldTypography>
                            <StyledSubtitleTypography color="textSecondary">
                                {getMonetizationTypeString(monetizationIncomeDetail)}
                            </StyledSubtitleTypography>
                        </Row>
                        <Row
                            rowProps={{ alignItems: 'flex-end', alignContent: 'flex-end', justifyContent: 'center' }}
                            itemProps={{ xs: 4 }}
                        >
                            <StyledSubtitleTypography
                                color="textSecondary"
                                $textAlign="end"
                            >
                                {`${monetizationIncomeDetail.percentage.toLocaleString('cs-CZ')} %`}
                            </StyledSubtitleTypography>
                            <StyledSubtitleTypography
                                color="textSecondary"
                                $textAlign="end"
                            >
                                {`${monetizationIncomeDetail.purchaseCount.toLocaleString('cs-CZ')} ${t('purchases')}`}
                            </StyledSubtitleTypography>
                            <StyledSubtitleTypography
                                color="textSecondary"
                                $textAlign="end"
                            >
                                {`${monetizationIncomeDetail.totalIncome.toLocaleString('cs-CZ')} ${organization?.currencySymbol}`}
                            </StyledSubtitleTypography>
                        </Row>
                    </Row>
                ))}
            </Row>
        </Column>
    )
}
