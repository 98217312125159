import { addHours } from '@tivio/common'
import { TvChannelDocument } from '@tivio/firebase'
import i18n from 'i18next'

import Logger from '../../logger'
import Marker from '../../store/Marker'
import Organization from '../../store/Organization'
import TvChannel from '../../store/TvChannel'
import { notEmptyFilter } from '../../utils/array.utils'
import { getCurrentFirebaseTimestamp, getFirestore, getFunctions, loggerFirestore } from '../app'

import { oneWeekAgo } from './epg'
import { getEpgMarkersCollection } from './markerEpg'

import type { FirebaseDocumentData, FirestoreDocumentSnapshot } from '../app'
import type { Disposer } from './types'
import type firebase from 'firebase/app'


const logger = new Logger('tvChannels')

export interface Uri {
    uri: string
    id: string
}

export interface StreamUriResponse {
    data: {
        uris: Uri[]
    }
}

const tvChannelsConverter = {
    fromFirestore: (snapshot: FirestoreDocumentSnapshot) => {
        const data = snapshot.data()

        const convertedData = {
            ...data,
            created: data?.created?.toDate() ?? null,
            updated: data?.updated?.toDate() ?? null,
        } as unknown as TvChannelDocument

        return new TvChannel(snapshot.ref as firebase.firestore.DocumentReference<any>, convertedData)
    },
    toFirestore: (tvChannel: TvChannel): FirebaseDocumentData => {
        return {
            name: tvChannel.getName,
            channelKey: tvChannel.getChannelKey,
            filters: tvChannel.filters,
            publishedStatus: tvChannel.getPublishedStatus,
            type: tvChannel.type,
            created: tvChannel.created,
            updated: getCurrentFirebaseTimestamp(),
            organizationRef: tvChannel.organizationRef,
        }
    },
}


const getTvChannelsCollection = () => {
    return getFirestore().collection('tvChannels')
        .withConverter(tvChannelsConverter)
}

export const addTvChannel = async (tvChannelData: TvChannelDocument) => {
    try {
        const tempTvChannel = new TvChannel(
            // @ts-ignore
            null,
            { ...tvChannelData, created: new Date() },
        )

        const tvChannelRef = await getTvChannelsCollection().add(tempTvChannel)

        loggerFirestore.info(`TV channel created (id: ${tvChannelRef.id})`, tvChannelData)

        return tvChannelRef
    }
    catch (e) {
        loggerFirestore.error('Failed to create channel:', e, tvChannelData)
        throw e
    }
}

export const updateTvChannel = async (tvChannel: TvChannel, tvChannelData: Partial<TvChannelDocument>) => {
    const id = tvChannel.getId

    try {
        await getTvChannelsCollection().doc(id).update(tvChannelData)

        loggerFirestore.info(`TV channel updated (id: ${id})`, tvChannelData)
    }
    catch (e) {
        loggerFirestore.error(`Failed to update tv channel (id: ${id})`, e, tvChannelData)
        throw e
    }
}

export const removeTvChannel = async (id: string) => {
    try {
        await getTvChannelsCollection().doc(id).delete()

        loggerFirestore.info(`TV channel deleted (id: ${id})`)
    }
    catch (e) {
        loggerFirestore.error(`Failed to deleted TV channel (id: ${id}):`, e)
        throw e
    }
}

export const listenTvChannels = async (
    organization: Organization,
    callback: (data: TvChannel[]) => any,
): Promise<Disposer> => {
    const tvChannelsRefs = organization.tvChannelsRefs
    const tvChannelsByRefsRaw = await Promise.all(tvChannelsRefs.map(async (ref) => {
        const tvChannelSnapshot = await ref.get()
        const tvChannelData = tvChannelSnapshot.data()

        if (!tvChannelData) {
            return null
        }

        return new TvChannel(
            ref,
            tvChannelSnapshot.data(),
        )
    }))
    const tvChannelsByRefs = tvChannelsByRefsRaw.filter(notEmptyFilter)

    return getTvChannelsCollection()
        .where('organizationRef', '==', organization.ref)
        .orderBy('name')
        .onSnapshot({
            next: (snapshots) => {
                const tvChannels = snapshots.docs.map(doc => doc.data())
                const tvChannelsByRefsFiltered = tvChannelsByRefs.filter(
                    (tvChannelByRef) =>  !tvChannels.some(tvChannel => tvChannel.id === tvChannelByRef.id),
                )

                logger.info('TV Channels changed', tvChannels)

                callback(tvChannels.concat(tvChannelsByRefsFiltered))
            },
        })
}

export const listenAllTvChannels = (
    callback: (data: TvChannel[]) => any,
): Disposer => {
    return getTvChannelsCollection()
        .orderBy('name')
        .onSnapshot({
            next: (snapshots) => {
                const tvChannels = snapshots.docs.map(doc => doc.data())

                // logger.info('All TV Channels changed', tvChannels)

                callback(tvChannels)
            },
        })
}

const tvChannelExists = async (channelKey: string) => {
    const to = addHours(new Date(), -2)
    const from = addHours(to, -1)

    try {
        await getStreamUri(channelKey, from.getTime(), to.getTime())
        return true
    } catch (error) {
        return false
    }
}

/**
 *
 * @param channelKey nangu.TV O2 production channel key
 * @param fromTimestamp ms
 * @param toTimestamp ms
 * @returns
 */
const getStreamUri = async (channelKey: string, fromTimestamp: number, toTimestamp: number) => {
    loggerFirestore.info('Fetching stream uri for: ', channelKey)

    try {
        const getStreamUri = await getFunctions().httpsCallable('asTimeshiftUrisMethod')

        const response: StreamUriResponse = await getStreamUri({
            channelKey,
            fromTimestamp,
            toTimestamp,
        })

        if (response.data.uris.length === 0) {
            throw new Error(i18n.t('Stream not found'))
        }

        const [uri] = response.data.uris

        return uri.uri
    }
    catch (e) {
        loggerFirestore.error(e)
        throw e
    }
}

const listMarkers = async (tvChannel: TvChannel) => {
    loggerFirestore.info('Fetching markers for channel ', tvChannel.getName)
    const markersSnapshots = await getEpgMarkersCollection(tvChannel)
        .where('from', '>=', oneWeekAgo())
        .get()
    loggerFirestore.info(`Fetched ${markersSnapshots.size} markers`)


    return markersSnapshots.docs.map(
        markerSnapshot => {
            const markerData = markerSnapshot.data()
            return new Marker(markerSnapshot.ref, markerData, tvChannel)
        },
    )
}

export {
    getTvChannelsCollection,
    getStreamUri,
    tvChannelExists,
    listMarkers,
}
