import styled from 'styled-components'


export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
`

export const NodeSublabel = styled.span`
    font-size: 10px;
    color: #ffffff;
    opacity: 0.6;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
    overflow: hidden;
`

export const NodeContainer = styled.div<{ selected: boolean, width?: number }>`
    padding: 10px;
    color: '#f9f9f9';
    border: 3px solid ${(props: any) => (props.selected ? '#2196f3' : '#888')};
    border-radius: 10px;
    font-size: 12px;
    width: ${({ width }) => width ? width : 150}px;
    height: 80px;

    .react-flow__handle {
        width: 0px;
        height: 0px;
        opacity: 0;
    }
`
