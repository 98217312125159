import { getMinsSecs, useT } from '@tivio/common'
import { AdExternal } from '@tivio/types'


const getOrder = (t: ReturnType<typeof useT>, count?: number | null, order?: number | null) => {
    if (count == null || order == null) {
        return ''
    }

    return `${order} ${t('of')} ${count}`
}

const getSkipText = (t: ReturnType<typeof useT>, isSkippable?: boolean, secToSkip?: number | null) => {
    let text = ''

    if (!isSkippable) {
        if (secToSkip != null) {
            text = `${t('Continue in')} ${secToSkip} s`
        } else {
            text = `${t('Ad')}`
        }
    }
    else if (isSkippable && secToSkip && secToSkip > 0) {
        text = `${t('Skip in')} ${getMinsSecs(secToSkip * 1000).textWordy}`
    }
    else {
        text = t('Skip')
    }

    return text
}

const getCountText = (t: ReturnType<typeof useT>, count?: number | null, order?: number | null) => {
    return `${t('Advertisement')} ${getOrder(t, count, order)}`
}

const getSkipButtonData = (
    t: ReturnType<typeof useT>,
    ad?: AdExternal | null,
): {
    text: string,
    label: string,
    enabled: boolean,
} => {
    const text = getSkipText(t, ad?.isSkippable, ad?.secondsToSkippable)
    const label = getCountText(t, ad?.totalCount, ad?.order)
    const enabled = !!ad?.isSkippable && ad.secondsToSkippable === 0

    return {
        text,
        label,
        enabled,
    }
}

export {
    getSkipButtonData,
}
