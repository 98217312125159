import { Button } from '@material-ui/core'
import Add from '@material-ui/icons/Add'
import { NotificationStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { firebaseTimestampFromDate } from '../../../firebase/app'
import { getTemplateRef } from '../../../firebase/firestore/emailing'
import { DefaultPageTabs } from '../../DefaultPageTabs'
import { useOrganization } from '../../hooks'
import { useNotifications } from '../../hooks/dataHooks/useNotifications'
import { useAlert } from '../../hooks/uiHooks/useAlert'
import { NotificationListWrapper } from '../../services/NotificationListWrapper'

/**
 * Services page component
 */
const ServicesPage = observer(() => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { showSuccess } = useAlert()

    const template = organization?.emailTemplates?.[0]

    const { notifications, addNotification, removeNotification } = useNotifications()

    const handleAddNotification = async () => {
        try {
            const notificationsCount = notifications.length + 1

            if (organization?.id && template) {
                await addNotification({
                    created: firebaseTimestampFromDate(),
                    enabled: false,
                    sentCount: 0,
                    name: `${t('New notification')} ${notificationsCount}`,
                    targeting: null,
                    templateRef: getTemplateRef(template.id),
                    triggerType: 'manual',
                    status: NotificationStatus.WAITING,
                    subject: template.subject,
                    substitutions: [],
                    filters: [],
                })
                showSuccess(t('Notification created'))
            }
        } catch (error) {
            console.error('An error occurred:', error)
        }
    }

    const tabs = [
        {
            title: t('Notifications'),
            component: <NotificationListWrapper
                handleCreateNotification={handleAddNotification}
                loading={false}
                disabledAddNotification={!organization?.id || !template}
                notifications={notifications}
                removeNotification={removeNotification}
            />,
            buttons: [
                <Button
                    key="add-notification-button"
                    startIcon={<Add/>}
                    component="span"
                    disabled={!organization?.id || !template}
                    size="small"
                    onClick={handleAddNotification}
                >
                    {t('Add notification')}
                </Button>,
            ],
        },
    ]

    return <DefaultPageTabs tabs={tabs}/>
})

export default ServicesPage
