import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { PromotionDocument, Timestamp } from '@tivio/firebase'
import { PatreonCreatorData } from '@tivio/types'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as Yup from 'yup'

import { createPromotion, updatePromotion } from '../../firebase/firestore/promotion'
import { getInputProps } from '../../utils/formik.utils'
import { AppThemeProvider } from '../AppThemeProvider'
import { useOrganization } from '../hooks'
import { useSubscriptions } from '../hooks/dataHooks/useSubscriptions'
import { Column } from '../uiLayout/Column'

import SimplePriceInput from './SimplePriceInput'
import { DateTimePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import { secondsToFirebaseTimestamp } from '../../utils/time.utils'


export interface PatreonDialogProps {
    onClose: () => void
    promotion?: PromotionDocument
    patreonCampaign?: PatreonCreatorData
    loadCreatorCampaign: () => void
    patreonCampaignLoading?: boolean
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const DialogTitle = styled(Typography)`
    font-weight: bold;
`

const PatreonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`

const PatreonBox = styled.div`
    flex: 1;
    padding: 15px;
    background-color: #ededed;
    border-radius: 8px;
`

export const PatreonDialog: React.FC<PatreonDialogProps> = observer(({
    onClose,
    promotion,
    patreonCampaign,
    loadCreatorCampaign,
    patreonCampaignLoading,
}) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { subscriptions } = useSubscriptions()

    const form = useFormik<Omit<PromotionDocument, 'monetizationRef'> & { monetizationId?: string }>({
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('This field is required')),
            prices: Yup.object({
                'CZK': Yup.number(),
                'EUR': Yup.number(),
                'USD': Yup.number(),
            }).required(t('This field is required')),
            external: Yup.object({
                id: Yup.string(),
                name: Yup.string(),
            }).required(t('This field is required')),
            monetizationId: Yup.string().required(t('This field is required')),
        }),
        onSubmit: async ({ monetizationId, id, expiration, ...values }) => {
            if (organization?.id) {
                const monetizationRef = subscriptions.find((s) => s.getId === monetizationId)?.getRef
                if (promotion?.id && monetizationRef) {
                    await updatePromotion(promotion.id, organization.id, {
                        ...values,
                        monetizationRef: monetizationRef,
                        patreonCampaignId: patreonCampaign?.campaign,
                        ...(expiration ? { expiration: secondsToFirebaseTimestamp(dayjs(expiration.toDate()).unix())} : {}),
                    })
                } else {
                    await createPromotion(organization.id, {
                        ...values,
                        monetizationRef: monetizationRef,
                        ...(patreonCampaign?.campaign ? { patreonCampaignId: patreonCampaign?.campaign } : {}),
                        ...(expiration ? { expiration: secondsToFirebaseTimestamp(dayjs(expiration.toDate()).unix())} : {}),
                    })
                }
            }
            onClose()
        },
        initialValues: {
            id: '',
            name: ' ',
            prices: {
                'CZK': 0,
                'EUR': 0,
                'USD': 0,
            },
            external: {
                id: '',
                name: 'classic',
            },
            monetizationId: ''
        },
    })

    useEffect(() => {
        if (promotion) {
            form.setValues(promotion)
            form.setFieldValue('monetizationId', promotion.monetizationRef.id)
        }
    }, [promotion])

    return (
        <AppThemeProvider type="light">
            <Dialog
                open={true}
                onClose={onClose}
            >
                <StyledDialogContent>
                    <CloseIcon onClick={onClose}>
                        <Close />
                    </CloseIcon>
                    <form onSubmit={form.handleSubmit}>
                        <Column spacing={4}>
                            <DialogTitle variant="h6">
                                {promotion?.name || t('Edit promotion')}
                            </DialogTitle>
                            <Box>
                                <DialogTitle variant="h6">
                                    {t('Type')}
                                </DialogTitle>
                                <FormControl fullWidth>
                                    <Select
                                        value={form.values.external?.name}
                                        onChange={(e) => form.setFieldValue('external.name', e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem
                                            value="classic"
                                        >
                                            {t('Classic')}
                                        </MenuItem>
                                        <MenuItem
                                            value="patreon"
                                        >
                                            {t('Patreon')}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {
                                form.values.external?.name === 'patreon' &&
                                <>
                                    <DialogTitle variant="h6">
                                        {t('Patreon memberships')}
                                    </DialogTitle>
                                    {patreonCampaign?.tiers ?
                                        <FormControl fullWidth>
                                            <Select
                                                value={form.values.external?.id}
                                                onChange={(e) => form.setFieldValue('external.id', e.target.value as string)}
                                                displayEmpty
                                            >
                                                {patreonCampaign.tiers?.map(tier => (
                                                    <MenuItem
                                                        key={tier.id}
                                                        value={tier.id}
                                                    >{`$${tier.attributes.amount_cents / 100} ${tier.attributes.title} - ${tier.attributes.description}`}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        :
                                        patreonCampaignLoading ?
                                            <CircularProgress
                                                color="inherit"
                                                data-e2e="app-loading"
                                            />
                                            :
                                            <Button
                                                fullWidth
                                                color="primary"
                                                variant="contained"
                                                onClick={loadCreatorCampaign}
                                            >
                                                {t('Load Patreon memberships')}
                                            </Button>}
                                </>
                            }
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                label={t('Name')}
                                {...getInputProps(form, 'name')}
                            />
                            <SimplePriceInput
                                value={form.values.prices}
                                onChange={newPrices => {
                                    form.setFieldValue('prices', newPrices)
                                }}
                            />
                            {
                                form.values.external?.name === 'classic' &&
                                <Box>
                                    <DateTimePicker
                                        ampm={false}
                                        value={form.values.expiration?.toDate()}
                                        onChange={(date: Dayjs | null) => {
                                            if (date) {
                                                form.setFieldValue('expiration', date)
                                            }
                                        }}
                                        showTodayButton
                                        variant="dialog"
                                        label={t('Created')}
                                        inputVariant="outlined"
                                    />
                                </Box>
                            }
                            <Box>
                                <DialogTitle variant="h6">
                                    {t('Subscriptions')}
                                </DialogTitle>
                                <FormControl fullWidth>
                                    <Select
                                        value={form.values.monetizationId}
                                        onChange={(e) => form.setFieldValue('monetizationId', e.target.value)}
                                        displayEmpty
                                    >
                                        {subscriptions.map(m => (
                                            <MenuItem
                                                key={m.getId}
                                                value={m.getId}
                                            >{m.getName}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Button
                                fullWidth
                                color="primary"
                                type="submit"
                                variant="contained"
                            >
                                {t('Save')}
                            </Button>
                        </Column>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})
