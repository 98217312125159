import { PLATFORM, Translation } from '@tivio/types'

import { ChartSeriesDataPoint } from '../../../types/chart'
import { Period } from '../../../types/date'


export interface DeviceViewCountDetail {
    deviceType: PLATFORM
    gain?: number
    viewCount: number
}

export interface TopWatchedVideo {
    videoId: string
    name: Translation | string
    description: Translation | string
    coverImage: string
    viewCount: number
}

export interface ViewsPeaks {
    // unix seconds date: total views count
    [date: number]: number
}

export interface ChartData {
    [monetizationId: string]: {
        monetizationId: string
        data: { [date: number]: ChartSeriesDataPoint }
    }
}

export interface ViewsPeak {
    date: number
    viewCount: number
}

export interface ViewsAggregationMatrix {
    chartData: ChartData
    viewsPeaks: ViewsPeaks
    devices: {
        [platform: string]: DeviceViewCountDetail
    }
    viewsTotalCount: number
}

type PeriodsStrings = {
    [keyof in Period]: string
}

/**
 * Based on PeriodPicker
 * TODO it will be reworked with new PeriodPicker
 */
export const previousPeriodStringMap: PeriodsStrings = {
    [Period.TODAY]: 'Compared to yesterday',
    [Period.YESTERDAY]: 'Compared to day before',
    [Period.LAST_SEVEN_DAYS]: 'Compared to previous 7 days',
    [Period.LAST_THIRTY_DAYS]: 'Compared to previous 30 days',
    [Period.CUSTOM_RANGE_DAYS]: 'Compared to period before',
}

export interface ViewsCountDetail {
    currentPeriodViewCount: number,
    gainViewCount?: number
}

export interface ViewsPeakDetail {
    currentPeriodViewsPeak: ViewsPeak,
    previousPeriodViewsPeak: ViewsPeak,
    gainViewsPeak?: number
}
