import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioScreen } from '../../store/TivioScreen'
import { useOrganization } from '../hooks/dataHooks/useOrganization'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { ScreenDialog } from '../screen/ScreenDialog'

import { ScreensItem } from './ScreensItem'


type ScreensListProps = {
    screens: TivioScreen[]
}

export const ScreensList: React.FC<ScreensListProps> = ({ screens }) => {
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()
    const [ screen, setScreen ] = useState<TivioScreen | null>(null)
    const [ t ] = useTranslation()

    const handleRenameClick = (screen: TivioScreen) => {
        setScreen(screen)
    }

    const handleDeleteClick = (screen: TivioScreen) => {
        confirmAction(
            () => {
                organization?.removeScreen(screen)
            },
            t('Are you sure you want to delete this screen?'),
        )
    }

    return (
        <>
            {screen && (
                <ScreenDialog
                    screen={screen}
                    dialogProps={{
                        open: true,
                        onClose: () => setScreen(null),
                    }}
                />
            )}
            {screens.length === 0 && <Typography color="textSecondary">{t('No screens yet')}</Typography>}
            {screens.map(screen => (
                <ScreensItem
                    key={screen.id}
                    screen={screen}
                    onRename={handleRenameClick}
                    onDelete={handleDeleteClick}
                />
            ))}
        </>
    )
}
