import { tivio } from '@tivio/core-js'
import { QerkoCancellationInfo } from '@tivio/types'
import { useState } from 'react'

/**
 * Hook for cancelling more subscriptions at once.
 * TODO ideally join it with useCancelSubscription or reuse common logic
 *  (there is big difference - this hook returns cancelSubscriptions function that requires param,
 *  but hook useCancelSubscription requires param and returns function using this param)
 */
export function useCancelSubscriptions() {
    const [cancellationInfos, setCancellationInfos] = useState<QerkoCancellationInfo[]>([])
    const [errors, setErrors] = useState<string[]>([])

    const cancelSubscriptions = async (subscriptionIds: string[]) => {
        setErrors([])

        for (const subscriptionId of subscriptionIds) {
            try {
                const cancellationInfo = await tivio.cancelSubscriptionWithQerko(subscriptionId)
                setCancellationInfos(cancellationInfos => [...cancellationInfos, cancellationInfo])
            } catch (e) {
                if (e instanceof Error) {
                    setErrors(errors => [...errors, (e as Error).message])
                }
            }
        }
    }

    return { cancelSubscriptions, cancellationInfos, errors }
}
