import { numberComparator, round, SortDirection } from '@tivio/common'
import { MonetizationDocument } from '@tivio/firebase'
import { ViewCountItem } from '@tivio/types'
import dayjs from 'dayjs'

import { getDocumentsByIds } from '../../../firebase/firestore/row'
import { VIEWS_CHART_COLOR_PALETTE } from '../../../static/constants'
import { ChartSeries, ChartSeriesDataPoint } from '../../../types/chart'
import { Period } from '../../../types/date'
import { percentageWithDecimals } from '../../../utils/math.utils'

import {
    ChartData,
    DeviceViewCountDetail,
    TopWatchedVideo,
    ViewsAggregationMatrix,
    ViewsCountDetail,
    ViewsPeak,
    ViewsPeakDetail,
    ViewsPeaks,
} from './types'


export type ChartIntervals = [number, number][]

const NO_MONETIZATION_ID = 'Free'

export const viewCountComparator = (itemA: DeviceViewCountDetail | TopWatchedVideo,
    itemB: DeviceViewCountDetail | TopWatchedVideo,
    sortDirection: SortDirection = SortDirection.DESC) => {
    return numberComparator(itemA.viewCount, itemB.viewCount, sortDirection)
}

/**
 * Method take viewCountAnalytics (raw data from BigQuery database)
 * and aggregate them according to predefined intervals.
 *
 * @param viewCountAnalytics - An array of view count records from database
 * @param chartIntervals - An array of arrays intervals in unix timestamp ([[ 1683849600, 1683860400 ],...])
 *
 * @returns {ViewsAggregationMatrix} An object data from aggregation to the next processing
 */
export const getAggregatedData = (viewCountAnalytics: ViewCountItem[], chartIntervals: ChartIntervals): ViewsAggregationMatrix => {

    const viewsAggregationMatrix: ViewsAggregationMatrix = {
        chartData: {},
        viewsPeaks: {},
        devices: {},
        viewsTotalCount: 0,
    }

    for (const [startDateInterval, endDateInterval] of chartIntervals) {
        const measurementDate = startDateInterval * 1000
        const viewCountAnalyticsForInterval = viewCountAnalytics.filter(viewCountRecord =>
            viewCountRecord.date >= startDateInterval &&
            viewCountRecord.date < endDateInterval,
        )
        for (const viewCountRecord of viewCountAnalyticsForInterval) {
            const monetizationId = viewCountRecord.monetizationId ?? NO_MONETIZATION_ID
            const viewCountValue = viewCountRecord.totalCount
            const deviceType = viewCountRecord.deviceType
            viewsAggregationMatrix.viewsTotalCount += viewCountValue

            // aggregating chart data
            if (viewsAggregationMatrix.chartData[monetizationId]) {
                const prevValue = viewsAggregationMatrix.chartData[monetizationId].data[measurementDate]?.y ?? 0
                viewsAggregationMatrix.chartData[monetizationId].data[measurementDate] = {
                    x: measurementDate,
                    y: prevValue + viewCountValue,
                }
            } else {
                viewsAggregationMatrix.chartData[monetizationId] = {
                    monetizationId: monetizationId,
                    data: {
                        [measurementDate]: {
                            x: measurementDate,
                            y: viewCountValue,
                        },
                    },
                }
            }

            // aggregating viewPeaks
            if (viewsAggregationMatrix.viewsPeaks[measurementDate]) {
                viewsAggregationMatrix.viewsPeaks[measurementDate] += viewCountValue
            } else {
                viewsAggregationMatrix.viewsPeaks[measurementDate] = viewCountValue
            }

            // aggregating devices
            if (viewsAggregationMatrix.devices[deviceType]) {
                const prevValue = viewsAggregationMatrix.devices[deviceType]?.viewCount ?? 0
                viewsAggregationMatrix.devices[deviceType] = {
                    deviceType,
                    viewCount: prevValue + viewCountValue,
                }
            } else {
                viewsAggregationMatrix.devices[deviceType] = {
                    deviceType,
                    viewCount: viewCountValue,
                }
            }
        }
    }
    return viewsAggregationMatrix
}

export const getSeriesData = async (chartData: ChartData, chartIntervals: ChartIntervals) => {
    const monetizationIds = Object.keys(chartData).filter(monetizationId => monetizationId !== NO_MONETIZATION_ID)
    const monetizationDetails = (await getDocumentsByIds('monetizations', monetizationIds))
        .flatMap(monetizationSnapshots =>
            monetizationSnapshots.docs.map(doc => ({ id: doc.id, title: (doc.data() as MonetizationDocument).title })),
        )

    const seriesData: ChartSeries[] = Object.values(chartData)
        .sort((a, b) => b.monetizationId.localeCompare(a.monetizationId))
        .flatMap((monetizationSeries, index) => {
            const monetizationId = monetizationSeries.monetizationId
            const monetizationTitle = monetizationDetails.find(monetizationDetail => monetizationDetail.id === monetizationId)?.title
            const name = monetizationTitle ?? (monetizationId === NO_MONETIZATION_ID ? NO_MONETIZATION_ID : `Unknown ${index}`)

            // we need add zero points for correct representation data in chart
            const data: ChartSeriesDataPoint[] = chartIntervals.map(([startDateInterval, endDateInterval]) => {
                const measurementDate = startDateInterval * 1000
                const dataPoint = monetizationSeries.data[measurementDate]
                return {
                    x: measurementDate,
                    y: dataPoint ? round(dataPoint.y, 2) : 0,
                }
            })

            const series: ChartSeries = {
                id: monetizationId,
                name,
                color: VIEWS_CHART_COLOR_PALETTE[index % VIEWS_CHART_COLOR_PALETTE.length],
                data,
            }
            return series
        })
    return seriesData
}

export const getViewCountDetails = (currentViewsCount: number, previousViewsCount: number): ViewsCountDetail => {
    return {
        currentPeriodViewCount: currentViewsCount,
        gainViewCount: percentageWithDecimals(currentViewsCount - previousViewsCount, previousViewsCount),
    }
}

export const getViewsPeakMaximum = (viewsPeaks: ViewsPeaks): ViewsPeak => {
    return Object.entries(viewsPeaks)
        .reduce((max: ViewsPeak, [dateString, viewCountValue]) => {
            if (max.viewCount > viewCountValue) { return max }
            return { date: parseInt(dateString), viewCount: viewCountValue }
        }, { date: 0, viewCount: 0 })
}

export const getViewPeakDetails = (currentViewsPeaks: ViewsPeaks, previousViewsPeaks: ViewsPeaks): ViewsPeakDetail => {
    const currentViewsPeak = getViewsPeakMaximum(currentViewsPeaks)
    const previousViewsPeak = getViewsPeakMaximum(previousViewsPeaks)
    return {
        currentPeriodViewsPeak: currentViewsPeak,
        previousPeriodViewsPeak: previousViewsPeak,
        gainViewsPeak: percentageWithDecimals(currentViewsPeak.viewCount - previousViewsPeak.viewCount, previousViewsPeak.viewCount),
    }
}

export const getDaysForPeriod = (startDate: string, endDate: string, period: Period): number => {
    switch (period) {
        case Period.TODAY:
        case Period.YESTERDAY:
            return 1
        case Period.LAST_SEVEN_DAYS:
            return 7
        case Period.LAST_THIRTY_DAYS:
            return 30
        case Period.CUSTOM_RANGE_DAYS:
        default: {
            const diffInDays = dayjs(endDate).diff(startDate, 'day')
            return diffInDays < 1 ? 1 : diffInDays
        }
    }
}


