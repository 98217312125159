import React from 'react'

import { VideoBlock } from '../../../articles/blocks/VideoBlock/VideoBlock'
import { InputWrapper } from '../shared/InputWrapper'

import { ImageSubstitution } from './ImageSubstitution'
import { TextSubstitution } from './TextSubstitution'
import { SubstitutionProps } from './types'


export const Substitution = ({ substitution }: SubstitutionProps) => {
    const renderSubstitution = () => {
        switch (substitution.type) {
            case 'text':
            case 'html':
                return <TextSubstitution
                    substitution={substitution}
                />
            case 'image':
                return <ImageSubstitution
                    substitution={substitution}
                />
            case 'video':
                return <VideoBlock
                    height="20vh"
                    block={substitution}
                />
            default:
                return null
        }
    }

    return <InputWrapper label={substitution.name}>
        {renderSubstitution()}
    </InputWrapper>
}
