import { makeAutoObservable } from 'mobx'

import type { ConsentDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


/**
 * Consent document from an organization containing Terms of Service.
 */
export class Consent {
    constructor(
    private _ref: firebase.firestore.DocumentReference<ConsentDocument>,
    private data: ConsentDocument,
    ) {
        makeAutoObservable(this)
    }

    get ref() {
        return this._ref
    }

    get name() {
        return this.data.name
    }

    get id() {
        return this._ref.id
    }

    get text() {
        return this.data.text
    }

    get organizationRef() {
        return this.data.organizationRef
    }
}
