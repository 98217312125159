import { getFirestore } from '../app'

import { environmentsConverter } from './converters'

import type { OrganizationDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export type TEnvironmentFirestore = {
    name: string
    description: string
}

export const getEnvironments = (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
    : firebase.firestore.Query<TEnvironmentFirestore> => {
    return parent.collection('environments')
        .orderBy('name')
        .withConverter(environmentsConverter)
}

export const getEnvironmentDoc = async (organizationId: string, environmentId: string)
    : Promise<firebase.firestore.DocumentSnapshot<TEnvironmentFirestore>> => {
    const collection = getFirestore().collection(`organizations/${organizationId}/environments`)
    return collection.doc(environmentId)
        .withConverter(environmentsConverter)
        .get()
}

export const getEnvironmentsQuery = async (parent: firebase.firestore.DocumentReference<OrganizationDocument>)
    : Promise<firebase.firestore.QueryDocumentSnapshot<TEnvironmentFirestore>[]> => {
    const environmentsRefs = await getEnvironments(parent).get()
    return environmentsRefs.docs
}
