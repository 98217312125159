import { storeNotReady } from '..'
import { Article } from '../../../store/Article'

import { useOrganization } from './useOrganization'


export const usePosts = () => {
    const { organization } = useOrganization()

    if (!organization) {
        return {
            isLoading: true,
            fetchMore: storeNotReady,
            posts: [] as Article[],
        }
    }

    return {
        isLoading: organization.globalPostsPagination.isLoading,
        fetchMore: organization.globalPostsPagination.fetchMore,
        posts: organization.globalPostsPagination.getItems,
    }
}
