import {
    Avatar,
    Button,
    Container,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import DiscordLogo from '../../../assets/discord-logo.jpeg'
import PatronLogo from '../../../assets/patreon-logo.png'
import { COLORS } from '../../../static/enum'
import { useOrganization } from '../../hooks'


const DISCORD_URL = `${process.env.REACT_APP_DISCORD_BOT_URL}`


const StyledPaper = styled(Paper)`
    padding: 20px;
    margin-bottom: 20px;
    background-color: ${COLORS.DRAWER_BACKGROUND};
`

const StyledAvatar = styled(Avatar) <{ $isConnected?: boolean }>`
    filter: ${({ $isConnected }) => $isConnected ? 'brightness(1)' : 'brightness(0.3)'};
`

const StyledDescription = styled(Typography)`
    padding-right: 1rem;
`

const StyledIntegrationItem = styled(Grid)`
    display: flex;
    align-items: center;
`

interface IntegrationItemProps {
    logo: string;
    name: string;
    description: string;
    onConnect: () => void;
    isConnected?: boolean;
    disabled?: boolean;
}

const IntegrationItem: React.FC<IntegrationItemProps> = observer(({ logo, name, description, onConnect, isConnected, disabled }) => {
    const [t] = useTranslation()
    return (
        <StyledIntegrationItem
            container
            spacing={2}
            alignItems="center"
        >
            <Grid item>
                <StyledAvatar
                    $isConnected={isConnected}
                    src={logo}
                />
            </Grid>
            <Grid
                item
                xs
            >
                <Typography variant="h6">{name}</Typography>
                <StyledDescription variant="subtitle2">{description}</StyledDescription>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={isConnected || disabled}
                    onClick={onConnect}
                >
                    {isConnected ? t('Connected') : t('Connect')}
                </Button>
            </Grid>
        </StyledIntegrationItem>
    )
})

export const IntegrationsTab: React.FC = () => {

    const [t] = useTranslation()
    const { organization } = useOrganization()

    const handleDiscordIntegration = () => {
        if (!organization) {
            throw new Error('Organization not found')
        }
        window.location.href = `${DISCORD_URL}&state=${organization.id}`
    }

    return (
        <Container maxWidth="lg">
            <StyledPaper
                elevation={3}
                style={{ padding: '20px', marginBottom: '20px' }}
            >
                <IntegrationItem
                    isConnected={Boolean(organization?.discordGuildId)}
                    logo={DiscordLogo}
                    name="Discord"
                    // eslint-disable-next-line max-len
                    description={t('By connecting to Discord, you connect Tivio Bot to your Discord server, which automatically manages your users subscriptions. Enjoy worry-free community management with our bot that ensures only those with valid subscriptions have access.')}
                    onConnect={handleDiscordIntegration}
                />
            </StyledPaper>
            {/* <StyledPaper
                elevation={3}
                style={{ padding: '20px', marginBottom: '20px' }}>
                <IntegrationItem
                    isConnected={false}
                    logo={PatronLogo}
                    name="Patreon | Coming soon!"
                    disabled={true}
                    // eslint-disable-next-line max-len
                    description={t('By linking with Patreon, you get the opportunity to offer users a discounted or free subscription.')}
                    onConnect={() => console.log('Připojuji Patreon...')}
                />
            </StyledPaper> */}
        </Container>
    )
}
