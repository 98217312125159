import { Screen, tivio } from '@tivio/core-js'
import { useEffect, useState } from 'react'



export const useScreen = (screenId: string | null) => {
    const [screen, setScreen] = useState<Screen | null>(
        screenId ? tivio.getCachedScreenById(screenId) ?? null : null,
    )

    useEffect(() => {
        if (screenId) {
            tivio.getScreenById(screenId).then((screen) => setScreen(screen ?? null))
        }
    }, [screenId])

    return screen
}
