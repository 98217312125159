import { AssetPresetPlacement } from '@tivio/firebase'
import { action, computed, makeObservable, observable } from 'mobx'

import { getFirestore } from '../firebase/app'
import { isGreaterOrEqual } from '../utils/image.utils'

import type { Size } from '../utils/image.utils'
import type { AssetPresetDocument } from '@tivio/firebase'
import type { Scale } from '@tivio/types'


export class AssetPreset implements AssetPresetDocument {
    _updated: Date
    isGlobal = false

    constructor(
        private _id: string,
        public name: string,
        public description: string,
        public width: number,
        public height: number,
        public mode: 'fit' | 'fill' | 'stretch' | 'inside',
        public scaling: Scale[],
        public placement: AssetPresetPlacement,
        updated: Date,
    ) {
        this._updated = updated

        makeObservable(this, {
            _updated: observable,
            updated: computed,
            update: action,
        })
    }

    update() {
        this._updated = new Date()
    }

    get updated() {
        return this._updated
    }

    // TODO TIV-692 NICE TO HAVE add ref as constructor param (now this method is workaround to avoid refactoring)
    getRef(organizationId?: string) {
        if (!this.isGlobal && organizationId){
            return getFirestore().doc(`organizations/${organizationId}/assetPresets/${this.id}`)
        } else {
            return getFirestore().doc(`globalAssetPresets/${this.id}`)
        }
    }

    get id() {
        return this._id
    }

    get errors() {
        const result = []

        if (typeof this._id !== 'string') {
            result.push('id(string) missing')
        }

        if (typeof this.name !== 'string') {
            result.push('name(string) missing')
        }

        if (typeof this.description !== 'string') {
            result.push('description(string) missing')
        }

        if (typeof this.width !== 'number') {
            result.push('width(number) missing')
        }

        if (typeof this.height !== 'number') {
            result.push('height(number) missing')
        }

        if (!['fit', 'fill', 'inside', 'stretch'].includes(this.mode)) {
            result.push('mode not in fit, fill, inside, stretch')
        }

        if (!Array.isArray(this.scaling)) {
            result.push('scaling(array) is missing')
        }

        if (this.scaling.some(value => !/@[0-9]/.test(value))) {
            result.push('some scaling value is invalid, should be @[0-9]')
        }

        if (typeof this.placement !== 'string') {
            result.push('placement(string) missing')
        }

        return result
    }

    get valid() {
        return this.errors.length === 0
    }

    getMaxScalingNumber() {
        return Math.max(...this.scaling.map(scaleToNumber))
    }

    /**
     * Minimum required size of asset e.g. 100x100 pixels.
     * Corresponds to the @1 quality.
     * Although better qualities can be recommended, it is still possible to upload asset of lowest quality
     */
    getMinRequiredSize() {
        return {
            width: this.width,
            height: this.height,
        } as Size
    }

    /**
     * Minimum recommended size of asset e.g. 200x200 pixels.
     * Corresponds to the {@link getMaxScalingNumber} quality.
     */
    getMinRecommendedSize() {
        const scale = this.getMaxScalingNumber()

        return {
            width: this.width * scale,
            height: this.height * scale,
        } as Size
    }

    /**
     * Return preset scales to which asset of given size can be resized.
     * Used when uploaded asset is smaller than recommended size to find possible scales to resize.
     * (Example: preset scaling: ['@1', '@2', '@3'], preset size: 100x100, uploaded asset size 200x200 -> possible scales are ['@1', '@2'])
     * @param size asset size
     */
    getPossibleScalesToResize(size: Size) {
        return this.scaling.filter(scale => {
            const scaledSize = getScaledSize({ width: this.width, height: this.height }, scale)
            return isGreaterOrEqual(size, scaledSize)
        })
    }

    getQualityText = (times = 1, spacer = '') => {
        return qualityText(this.width, this.height, times, spacer)
    }

    getScaleQualityText = (scale: Scale, spacer = '') => {
        return qualityText(this.width, this.height, scaleToNumber(scale), spacer)
    }

    getMultiQualityText = () => {
        return multiQualityText(this)
    }

    getPlacementText = () => {
        return getPlacementText(this.placement)
    }
}

export const scaleToNumber = (scale: Scale) => {
    return parseInt(scale.replace('@', ''))
}

export const numberToScale = (scaleNumber: number) => {
    return `@${scaleNumber}`
}

/**
 * Returns size scaled to given scale
 */
export const getScaledSize = (size: Size, scale: Scale): Size => {
    const scaleNumber = scaleToNumber(scale)
    return { width: size.width * scaleNumber, height: size.height * scaleNumber }
}

export const getScaling = (double: boolean, triple: boolean): Scale[] => {
    if (triple) {
        return [ '@1', '@2', '@3' ]
    }

    if (double) {
        return [ '@1', '@2' ]
    }

    return [ '@1' ]
}

export const qualityText = (width: number, height: number, times = 1, spacer = '') => {
    return `${width * times}${spacer}x${spacer}${height * times}`
}

export const multiQualityText = (preset: AssetPreset) => {
    const { width, height, scaling } = preset
    return scaling.map(scale => {
        const times = Number(scale.slice(1))
        return qualityText(width, height, times)
    }).join(', ')
}

export const getPlacementText = (placement: AssetPresetPlacement) => {
    if (placement === AssetPresetPlacement.TV_CHANNEL) {
        return 'TV Channel'
    }

    return placement
}
