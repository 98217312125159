import React, { FC } from 'react'
import styled from 'styled-components'


const Container = styled.div`
    position: absolute;
    background-color: rgba(116, 151, 175, 0.7);
    width: 54px;
    height: 54px;
    border-radius: 27px;
    @keyframes PlayButton_grow {
        0%   { transform: scale(0.2) }
        90%   { transform: scale(1.1) }
    }
    animation: PlayButton_grow .2s;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    top: 33px;
`

const PlayIcon = styled.div`
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid white;
    margin-left: 5px;

    @keyframes PlayIcon_grow {
        0%   { transform: scale(0.2) }
        90%   { transform: scale(1.1) }
    }

    animation: PlayIcon_grow .1s;
`

const PauseBar = styled.div`
    display: inline-block;
    width: 5px;
    height: 20px;
    margin: 0 4px;
    background-color: white;

    @keyframes PlayIcon_grow {
        0%   { transform: scale(0.2) }
        90%   { transform: scale(1.1) }
    }

    animation: PlayIcon_grow .1s;
`

function PauseIcon() {
    return (
        <div>
            <PauseBar/>
            <PauseBar/>
        </div>
    )
}

interface Props {
    isPlaying: boolean
    onClick: any
}

export const PlayButton: FC<Props> = React.memo(function PlayButton({ isPlaying, onClick }) {
    return (
        <Container onClick={onClick}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </Container>
    )
})
