import {
    Box,
    Button,
    Checkbox,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Popover,
    ThemeProvider,
    Typography,
} from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { lightTheme } from '../../../static/theme'
import { Column } from '../../uiLayout/Column'


const StyledPaper = styled(Paper)`
    padding: 1rem 1.5rem;
`

interface Props {
    title: string
    values: string[]
    possibleValues?: string[]
    getLabel: (value: string) => string
    onChange: (values: string[]) => void
}

export const MenuDropdown = ({ title, values, possibleValues, getLabel, onChange }: Props) => {
    const [t] = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [tempValues, setTempValues] = React.useState<string[]>([])

    const isOpen = Boolean(anchorEl)

    const handleAddLanguageClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setTempValues(values)
            setAnchorEl(event.currentTarget)
        },
        [values],
    )

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleApply = useCallback(() => {
        setAnchorEl(null)
        onChange(tempValues)
    }, [tempValues, onChange])

    return (
        <>
            <Button
                startIcon={<Add />}
                onClick={handleAddLanguageClick}
            >
                {title}
            </Button>
            <ThemeProvider theme={lightTheme}>
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: -10,
                        horizontal: 'right',
                    }}
                    onClose={handleClose}
                >
                    <StyledPaper>
                        <Column>
                            <Box
                                width="250px"
                                mr="-1rem"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography>{title}</Typography>
                                <IconButton onClick={handleClose}>
                                    <Close />
                                </IconButton>
                            </Box>
                            <List>
                                {possibleValues?.map((value) => {
                                    const labelId = `checkbox-list-label-${value}`

                                    return (
                                        <ListItem
                                            key={value}
                                            role={undefined}
                                            dense
                                            button
                                            onClick={() => {
                                                if (tempValues.includes(value)) {
                                                    setTempValues(tempValues.filter((v) => v !== value))
                                                } else {
                                                    setTempValues([...tempValues, value])
                                                }
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    color="primary"
                                                    edge="start"
                                                    checked={tempValues.includes(value)}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                id={labelId}
                                                primary={getLabel(value)}
                                            />
                                        </ListItem>
                                    )
                                })}
                            </List>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleApply}
                            >
                                {t('Apply')}
                            </Button>
                        </Column>
                    </StyledPaper>
                </Popover>
            </ThemeProvider>
        </>
    )
}
