import React from 'react'

import { ImageInput } from '../common/image'
import { useAssetResize } from '../hooks/dataHooks/useAssetResize'
import { useValidateFile } from '../hooks/uiHooks/useValidateFile'

import type { AssetUploaderItem } from './assetUploader'
import type { AssetPreset } from '../../store/AssetPreset'
import type { TileSize } from '../AppTile'


type ProgressState = 'uploading' | 'resizing' | 'saving' | 'success' | 'error'

export type ProgressEvent = {
    state: ProgressState
    message?: string
    error?: string
}

interface Props {
    preset: AssetPreset
    item: AssetUploaderItem
    size?: TileSize
    image?: string | null
    onProgress?: (event: ProgressEvent) => any
    disabled?: boolean
}

/**
 * Component that shows an image input for a single image.
 * This image will be uploaded to storage and then resized according
 * to asset presets
 * TODO TIV-546 style background-size: contain;
 *  use new background image if it comes via props
 */
export const AssetImageInput = ({ preset, item, size, image, onProgress, disabled = false }: Props) => {
    const { resizeAsset } = useAssetResize({
        preset,
        item,
        onProgress,
    })

    const validateFile = useValidateFile(preset)

    return (
        <ImageInput
            onFile={resizeAsset}
            validateFile={validateFile}
            size={size}
            image={image}
            disabled={disabled}
        />
    )
}
