import { DatePicker } from '@material-ui/pickers'
import dayjs, { Dayjs } from 'dayjs'
import React, { useEffect, useState } from 'react'

import { DateRange } from '../../types/date'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { DateReadOnlyInput } from './DateReadOnlyInput'


const TODAY_START_DAY = dayjs().startOf('day')
const TODAY_END_DAY = dayjs().endOf('day')

interface Props {
    value: DateRange
    onChange: (startDate: Date, endDate: Date) => void
}

export const DateRangePicker: React.FC<Props> = (props) => {
    const { value, onChange } = props

    const [startDate, setStartDate] = useState<Dayjs>(TODAY_START_DAY)
    const [endDate, setEndDate] = useState<Dayjs>(TODAY_END_DAY)


    useEffect(() => {
        if (value.startDate > value.endDate) {
            setStartDate(TODAY_START_DAY)
            setEndDate(TODAY_END_DAY)
            return
        }

        setStartDate(dayjs(value.startDate).startOf('day'))
        setEndDate(dayjs(value.endDate).endOf('day'))
        // We want to call only on mounted
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleStartDateChange = (date: Dayjs | null) => {
        const setValue = dayjs(date).startOf('day')
        setStartDate(setValue)
        onChange(setValue.toDate(), endDate.toDate())
    }

    const handleEndDateChange = (date: Dayjs | null) => {
        const setValue = dayjs(date).endOf('day')
        setEndDate(setValue)
        onChange(startDate.toDate(), setValue.toDate())
    }

    return (
        <Row
            spacing={2}
            rowProps={{ alignItems: 'stretch', justifyContent: 'flex-start' }}
            itemProps={{ xs: 6 }}
        >
            <Column>
                <DateReadOnlyInput
                    label="From"
                    value={startDate}
                    showIcon
                />
                <DatePicker
                    onChange={handleStartDateChange}
                    variant="static"
                    disableFuture
                    value={startDate}
                    maxDate={endDate}
                    disableToolbar
                />
            </Column>
            <Column>
                <DateReadOnlyInput
                    label="To"
                    value={endDate}
                    showIcon
                />
                <DatePicker
                    onChange={handleEndDateChange}
                    variant="static"
                    disableFuture
                    value={endDate}
                    minDate={startDate}
                    disableToolbar
                />
            </Column>
        </Row>
    )
}
