import { useRef, useState } from 'react'

/**
 * @public
 */
const useLoading = (initialLoading = false) => {
    const [loading, setLoading] = useState(initialLoading)
    const loadingRef = useRef(loading)

    const updateLoading = (isLoading: boolean) => {
        setLoading(isLoading)
        loadingRef.current = isLoading
    }

    const startLoading = () => {
        if (!loadingRef.current) {
            updateLoading(true)
        }
    }

    const stopLoading = () => {
        if (loadingRef.current) {
            updateLoading(false)
        }
    }

    return {
        loading,
        startLoading,
        stopLoading,
    }
}

export {
    useLoading,
}
