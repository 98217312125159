import React from 'react'

import { usePosts } from '../hooks/dataHooks/usePosts'
import { VideoListSkeleton } from '../video/VideoListSkeleton'

import { ArticleItem } from './components'
import { useOrganization } from '../hooks'


export const PostsList = () => {
    const {organization} = useOrganization()
    const { isLoading, posts } = usePosts()

    if (isLoading) {
        return <VideoListSkeleton />
    }

    return (
        <>
            {posts.map((post) => (
                <ArticleItem
                    key={post.id}
                    article={post}
                    languages={organization?.languages}
                />
            ))}
        </>
    )
}
