import { Box, debounce, Popper, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { Autocomplete } from '@material-ui/lab'
import React, { memo, useEffect, useMemo, useState } from 'react'

import { useOrganization } from '../../hooks'
import { useLoader } from '../../hooks/general/useLoader'

import { FilterState } from './types'
import { toVideoAnalyticsUser } from './util'

import type { PopperProps } from '@material-ui/core'


const DEBOUNCE_FILTER_MS = 300 as const

const useStyles = makeStyles((theme) => ({
    searchIcon: {
        paddingTop: '0.5rem',
    },
}))

interface Props {
    filter: FilterState,
    setFilter: (state: React.SetStateAction<FilterState>) => void
}

export const UserAutocomplete = memo(({ filter, setFilter }: Props) => {
    const classes = useStyles()
    const [userEmail, setUserEmail] = useState('')

    const onSetUserEmail = (event: React.ChangeEvent<HTMLInputElement>) => setUserEmail(event.target.value)
    const debounceSetUserEmail = useMemo(() => debounce(onSetUserEmail, DEBOUNCE_FILTER_MS), [])

    const { organization } = useOrganization()
    const { loading, result: users, error } = useLoader(organization!.getUsersByEmail, [userEmail])

    useEffect(() => {
        return () => {
            debounceSetUserEmail.clear()
        }
    }, [debounceSetUserEmail])

    return (
        <Autocomplete
            fullWidth
            loading={loading}
            options={users?.map(toVideoAnalyticsUser) ?? []}
            getOptionLabel={u => u?.email}
            onChange={(_, user) => setFilter(state => ({ ...state, user }))}
            value={filter.user}
            PopperComponent={FitWidthPopper}
            getOptionSelected={(option, u) => option.id === u.id}
            onInputChange={(event, value, reason) => {
                if (reason === 'clear' || reason === 'reset') {
                    setFilter(state => ({ ...state, user: null, userEmail: '' }))
                }
            }}
            renderInput={(params) => <Box display="flex">
                <SearchIcon className={classes.searchIcon} />
                <TextField
                    {...params}
                    fullWidth
                    InputProps={{ ...params.InputProps, disableUnderline: true }}
                    placeholder="Search users..."
                    onChange={debounceSetUserEmail}
                />
            </Box>}
        />
    )
})

const FitWidthPopper = (props: PopperProps) => {
    return (
        <Popper
            {...props}
            style={{ width: 'fit-content' }}
            placement="bottom-start"
        >
            {props.children}
        </Popper>
    )
}
