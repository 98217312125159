import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { Skeleton } from '@material-ui/lab'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { COLORS } from '../static/enum'
import { goBack } from '../utils/history.utils'

import { Row } from './uiLayout/Row'


interface Props {
    title?: string
    buttons?: React.ReactNode[]
    badge?: React.ReactNode
}

const Header = styled(AppBar)`
    background-color: ${COLORS.DRAWER_BACKGROUND};
`

const DefaultPageHeader: React.FC<Props> = observer((props) => {
    const [ t ] = useTranslation()

    return (
        <Header position="relative">
            <Toolbar>
                <Row
                    rowProps={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        startIcon={<ArrowBackIos/>}
                        onClick={goBack}
                    >
                        {t('Back')}
                    </Button>
                    <Typography
                        variant="body1"
                        color="textSecondary"
                        noWrap
                    >
                        {props.title || <Skeleton width={350} />}
                        {props.badge}
                    </Typography>
                    <Row spacing={2}>
                        {props.buttons}
                    </Row>
                </Row>
            </Toolbar>
        </Header>
    )
})

DefaultPageHeader.displayName = 'DefaultPageHeader'

export {
    DefaultPageHeader,
}
