import { PersonalizationJSON } from '@sendgrid/helpers/classes/personalization'
import { CustomerId, EmailTemplate } from '@tivio/types'

import { getFirestore, getFunctions, loggerFirestore } from '../app'

import type firebase from 'firebase/app'
import type { OrganizationDocument, TemplateDocument } from '@tivio/firebase'
import { Template } from '../../store/Template'


interface Props {
    templateId: string
    customer: string
    from: string
    personalizations: PersonalizationJSON[]
    replyToList?: string[]
}

const getTemplatesCollection = getFirestore().collection('templates').withConverter({
    fromFirestore: (snapshot) => {
        return snapshot.data() as TemplateDocument
    },
    toFirestore: (template) => {
        return template
    },
})

export const getEmailTemplates = async (organizationRef: firebase.firestore.DocumentReference<OrganizationDocument>) => {
    const templates = await getTemplatesCollection
        .where('organizationRef', 'in', [organizationRef, false])
        .get()

    return templates.docs.map((doc) => new Template(doc.ref, doc.data()))
}

export const getTemplateRef = (templateId: string) => {
    return getTemplatesCollection.doc(templateId)
}

// export const sendNewsletterEmail = async ({
//     templateId,
//     from,
//     personalizations,
//     replyToList,
// }: Props) => {
//     try {
//         const sendEmailWithTemplate = getFunctions().httpsCallable('sendEmailWithTemplate')
//         const response: { data: EmailTemplate[] } = await sendEmailWithTemplate({
//             templateId,
//             from,
//             personalizations,
//             replyToList,
//         })
//         return response.data
//     } catch (e) {
//         loggerFirestore.error('Failed to fetch templates from SenGrid: ', e?.message)
//         throw new Error(e)
//     }
// }
