import dayjs from 'dayjs'

import { MARKER_TYPES } from '../static/enum'


export interface Interval {
    /**
     * Chosen lower bound time
     */
    from: number
    /**
     * Chosen upper bound time
     */
    to: number
    /**
     * Really fetched upper bound time
     */
    latestFetchedTime?: number
}

export type TimelineProgramType = 'program' | 'empty-program'

const DEFAULT_EPG_MARKERS = [
    MARKER_TYPES.AD,
    MARKER_TYPES.START,
    MARKER_TYPES.END,
    MARKER_TYPES.INTRO,
    MARKER_TYPES.OUTRO,
    MARKER_TYPES.BET,
    MARKER_TYPES.BREAK,
]

const getDuration = (from: number, to: number) => {
    return to - from
}

const getIntervalDuration = (interval: Interval) => {
    return interval.to - interval.from
}

const getProgramSize = (programTimeline: Interval, windowTimeline: Interval) => {
    const programDuration = getIntervalDuration(programTimeline)
    const windowDuration = getIntervalDuration(windowTimeline)

    return (programDuration / (windowDuration)) * 100
}

const getTimelineCenter = (timeline: Interval) => {
    return ((timeline.to - timeline.from) / 2) + timeline.from
}

const getInitTimeline = () => {
    const selectedDate = dayjs()

    const from = selectedDate
        .startOf('day')
        .unix()

    const to = selectedDate
        .endOf('day')
        .unix()

    return {
        from,
        to,
    }
}

const createInterval = (from: number, to: number, latestFetchedTime?: number) => ({
    from,
    to,
    latestFetchedTime,
})

export {
    DEFAULT_EPG_MARKERS,
    getInitTimeline,
    getProgramSize,
    getIntervalDuration,
    getTimelineCenter,
    createInterval,
    getDuration,
}
