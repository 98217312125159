import { Logger } from '@tivio/common'
import { analytics, NewVoucher, PurchaseVoucher, tivio } from '@tivio/core-js'
import {
    AnalyticsConversionStatus,
    MONETIZATION_FREQUENCY,
    PurchasableMonetization,
    PurchaseStatus,
    QerkoPaymentInfo,
    QerkoTransaction,
} from '@tivio/types'
import { useObserver } from 'mobx-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Translator from '../../../services/Translator'
import { useUser } from '../useUser'

import { validateVoucher } from './utils/qerko.utils'


const logger = new Logger('usePurchaseSubscription')

type Options = {
    onPaid?: () => void
    onError?: () => void
    email?: string
}

/**
 * @deprecated use purchaseSubscription function instead.
 * Note that the return values are different, so you might need to adjust the purchaseSubscription function if you really need to
 */
export function usePurchaseSubscription(monetizationId: string, voucher?: NewVoucher, options: Options = {}): QerkoTransaction & { caption: string } {
    const [paymentInfo, setPaymentInfo] = useState<QerkoPaymentInfo | null>(null)
    const [paymentError, setPaymentError] = useState('')
    const [monetization, setMonetization] = useState<PurchasableMonetization | null>(null)
    const prevMonetizationId = useRef<string>()
    const prevEmail = useRef<string>()
    const { user } = useUser()

    useEffect(() => {
        (async () => {
            if (!tivio.organization) {
                logger.warn('Organization is not set, cat not get monetization')
                return
            }

            const monetizations = await tivio.organization.getSubscriptions()
            const monetization = monetizations.find(item => item.id === monetizationId)

            if (monetization) {
                setMonetization(monetization)
            }
        })()
    }, [])

    const getPaymentInfo = useCallback(async () => {
        setPaymentError('')

        try {
            if (!tivio.purchaseSubscriptionWithQerko) {
                throw new Error('purchaseSubscriptionWithQerko not in bundle')
            }

            if (prevMonetizationId.current === monetizationId && prevEmail.current === options.email) {
                logger.info('getPaymentInfo: already called, not going to call purchaseSubscriptionWithQerko again')
                return
            }

            validateVoucher(voucher)
            const result = await tivio.purchaseSubscriptionWithQerko(monetizationId, voucher as PurchaseVoucher, options.email)

            prevMonetizationId.current = monetizationId
            prevEmail.current = options.email

            setPaymentInfo(result)
        } catch (e) {
            if (e instanceof Error) {
                setPaymentError(e.message)
            }
        }
    }, [voucher, monetizationId, options.email, user])

    const findPurchase = useCallback(() => {
        // TODO hacky, allPurchases does not filter out voucher
        //  (mb parametrize .purchases and change it to function, or make new getter)
        // TODO mb copy these changes to useTransactionPayment
        return (voucher ? user?.allPurchases : user?.purchases)?.find(
            (purchase) => {
                return (
                    purchase.id === paymentInfo?.purchaseId
                )
            },
        )
    }, [user, paymentInfo, voucher])

    const paymentStatus = useObserver(() => {
        if (user) {
            const purchase = findPurchase()

            if (purchase) {
                return purchase.status
            }
        }

        return PurchaseStatus.NEW
    })

    const voucherId = useObserver(() => {
        if (paymentInfo && user) {
            return findPurchase()?.voucherId ?? undefined
        }
    })

    const caption = useMemo(() => {
        if (monetization && paymentInfo) {
            const frequencyString = monetization.frequency !== MONETIZATION_FREQUENCY.ONE_TIME_PAYMENT ? ` / ${Translator.t(monetization.frequency)}` : ''
            return `${monetization.name} - ${paymentInfo.amount} ${paymentInfo.currency}${frequencyString}`
        }

        return ''
    }, [monetization?.name, paymentInfo?.amount, paymentInfo?.currency, paymentInfo?.monetizationFrequency])

    useEffect(() => {
        switch (paymentStatus) {
            case PurchaseStatus.ERROR:
                options.onError?.()
                // It could make false positive, but we resolve later
                analytics.completeMonetizationPurchase(AnalyticsConversionStatus.FAILED, monetizationId)
                break
            case PurchaseStatus.PAID:
                options.onPaid?.()
                analytics.completeMonetizationPurchase(AnalyticsConversionStatus.SUCCESS, monetizationId)
                break
        }
    }, [paymentStatus])

    useEffect(() => {
        getPaymentInfo()
    }, [getPaymentInfo, user?.isSignedIn])

    return {
        paymentError,
        paymentInfo,
        caption,
        paymentStatus,
        getPaymentInfo,
        voucherId,
    }
}

