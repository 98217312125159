import React, { useEffect, useState } from 'react'
import { Router } from 'react-router-dom'

import { isAuthorized, observeAuthorizationActions } from '../firebase/auth'
import history from '../utils/history.utils'

import AppAlert from './AppAlert'
import GuestLayout from './router/layout/GuestLayout'
import UserLayout from './router/layout/UserLayout'


/**
 * App router component
 * Decides which layout to render based on user authorization state
 */
function AppRouter() {
    const [ authorized, setAuthorized ] = useState(isAuthorized())

    useEffect(
        () => {
            return observeAuthorizationActions(setAuthorized)
        },
        [],
    )

    const renderLayout = () => {
        const Layout = authorized ? UserLayout : GuestLayout

        return (
            <Layout/>
        )
    }

    return (
        <Router history={history}>
            <AppAlert/>
            {renderLayout()}
        </Router>
    )
}

export default AppRouter
