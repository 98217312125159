import { capitalizeText } from '@tivio/common'

import { getMonetizationDocumentById } from '../../../firebase/firestore/monetization'

import { CreatePageNodeProps, PercentageMap, Statistic, Target } from './types'

import type { Node } from 'reactflow'


export const createConversionNode = (conversionIndex: number, conversionType: string, conversionData: {
    conversionTotalCount: number,
    conversionSuccessCount: number,
}) => {

    let conversionSublabel
    if (conversionData.conversionTotalCount === 0) {
        conversionSublabel = '0 / 0 %'
    } else {
        conversionSublabel = `${conversionData.conversionSuccessCount} / ${Math.round(
            (conversionData.conversionSuccessCount / conversionData.conversionTotalCount) * 100,
        )} %`
    }

    return {
        id: `conversion-${conversionType}`,
        type: 'conversion',
        data: {
            label: capitalizeText(conversionType),
            sublabel: conversionSublabel,
            conversionType,
        },
        position: {
            x: 250,
            y: conversionIndex * 90,
        },
        selected: conversionIndex === 0,
        selectable: true,
    }
}

export const createDeviceNode = (device: Statistic, deviceIndex: number, percentagePerDevice: PercentageMap, conversionType: string) => {
    const deviceId = `device-${device.technology}-${deviceIndex}-${device.os}-${conversionType}`
    return {
        id: deviceId,
        type: 'device',
        data: {
            appName: `${device.technology} ${device.os}`,
            technology: device.technology,
            deviceOs: device.os,
            conversionRate: `${percentagePerDevice[deviceId].value} %`,
            successCount: device.details.successCount,
            totalCount: device.details.totalCount,
            conversionType,
        },
        position: {
            x: 0,
            y: percentagePerDevice[deviceId].position * 90,
        },
        selected: conversionType === 'purchase',
        selectable: false,
        hidden: conversionType === 'visit',
    }
}

interface CreateTargetNodeProps {
    targetId: string
    translatedTargetName?: string
    targetIndex: number
    targetsTotalCount: number
    conversionType: string
    successCount: number
    totalCount: number
}

export const createTargetNode = async ({
    targetId,
    translatedTargetName,
    targetIndex,
    targetsTotalCount,
    conversionType,
    successCount,
    totalCount,
}: CreateTargetNodeProps): Promise<Node> => {
    const targetNodeId = `target-${targetId}-${conversionType}`
    const conversionRate = Math.round((totalCount / targetsTotalCount) * 100)
    const label = await getTargetLabel({
        targetId,
        conversionType,
        translatedTargetName,
    })

    return {
        id: targetNodeId,
        type: 'target',
        data: {
            label,
            type: 'target',
            targetId,
            successCount,
            totalCount,
            conversionRate: `${conversionRate} %`,
            conversionType,
        },
        position: {
            x: 500,
            y: targetIndex * 90,
        },
        selected: targetIndex === 0 && conversionType === 'purchase',
        hidden: conversionType === 'visit',
    }
}

const getTargetLabel = async ({
    targetId,
    conversionType,
    translatedTargetName,
}: {
    targetId: string
    conversionType: string
    translatedTargetName?: string
}) => {
    let label = ''
    if (conversionType === 'visit') {
        if (targetId === 'unknown') {
            return targetId
        } else {
            label = translatedTargetName ? translatedTargetName : targetId
        }
    } else if (conversionType === 'purchase') {
        if (targetId === 'unknown') {
            return 'Not completed'
        }
        const data = await getMonetizationDocumentById(targetId)
        label = data?.title
    }
    return label
}

export const createPageNode = ({
    page,
    parentId,
    targetIndex,
    translatedScreenName,
    conversionType,
    percentMap,
}: CreatePageNodeProps) => {
    let pageLabel: string
    switch (page.path) {
        case 'videos':
            pageLabel = 'Video detail'
            break
        case 'series':
            pageLabel = 'Series detail'
            break
        case 'screens':
            pageLabel = translatedScreenName ? translatedScreenName : `screens/${page.pathParam}`
            break
        default:
            pageLabel = capitalizeText(page.path)
            break
    }

    return {
        id: `page-${page.path}-${page.pathParam}-${targetIndex}-${conversionType}`,
        type: 'page',
        data: {
            label: pageLabel,
            sublabel: page.name,
            path: page.path,
            successCount: page.successCount,
            totalCount: page.totalCount,
            conversionRate: `${percentMap.value} %`,
            parentId,
            conversionType,
        },
        position: {
            x: 750,
            y: (percentMap.position - 1) * 90,
        },
        selected: conversionType === 'purchase',
        selectable: false,
        hidden: conversionType === 'visit',
    }
}
