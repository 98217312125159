import { Container } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { DefaultPageHeader } from '../../DefaultPageHeader'
import { MultiUpload } from '../../multiupload'


export const MultiUploadPage = () => {
    const [t] = useTranslation()
    return (
        <>
            <DefaultPageHeader title={t('Upload multiple videos')} />
            <Container maxWidth="lg">
                <MultiUpload />
            </Container>
        </>
    )
}
