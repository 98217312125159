import { createLogger } from '../../logger'


const logger = createLogger('hooks')

const storeNotReady = () => logger.info('store not ready')
const notReady = () => logger.info('function not ready')

export {
    storeNotReady,
    notReady,
}

export * from './dialog'
export * from './dataHooks'
