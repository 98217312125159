import { MenuItem, OutlinedInput, Select } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { ChangeEvent, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { TivioNotification } from '../../../../store/Notification'
import { Row } from '../../../uiLayout/Row'
import { InputWrapper } from '../shared/InputWrapper'

import type { NotificationDurationField } from '@tivio/firebase'


const getMaxDuration = (unit?: NotificationDurationField['unit']) => {
    switch (unit) {
        case 'week':
            return 52 * 2
        case 'month':
            return 24
        case 'day':
        default:
            return 365 * 2
    }
}

interface Props {
    notification: TivioNotification
}

export const DurationTargeting = observer(({ notification }: Props) => {
    const [t] = useTranslation()
    const durationUnitOptions = useMemo(
        () => ({
            day: t('Day'),
            week: t('Week'),
            month: t('Month'),
        }),
        [t],
    )
    const handleChange = (newDuration: Partial<NotificationDurationField>) => {
        notification.duration = {
            value: newDuration.value ?? notification.duration?.value ?? 1,
            unit: newDuration.unit ?? notification.duration?.unit ?? 'day',
        }
    }
    useEffect(() => {
        const { duration } = notification
        if (!duration?.value || !duration?.unit) {
            notification.duration = {
                value: duration?.value ?? 1,
                unit: duration?.unit ?? 'day',
            }
        }
    }, [notification])
    return (
        <InputWrapper label="Duration targeting">
            <Row
                spacing={1}
                rowProps={{
                    alignItems: 'center',
                }}
            >
                <OutlinedInput
                    margin="dense"
                    value={notification.duration?.value ?? ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        handleChange({
                            value: e.target.valueAsNumber,
                        })
                    }}
                    type="number"
                    inputProps={{
                        min: 1,
                        max: getMaxDuration(notification.duration?.unit),
                    }}
                />
                <Select
                    value={notification.duration?.unit ?? ''}
                    onChange={(e) => {
                        handleChange({
                            unit: e.target.value as NotificationDurationField['unit'],
                        })
                    }}
                    displayEmpty
                    renderValue={(value) => (value as string) ?? 'Select period'}
                >
                    {Object.entries(durationUnitOptions).map(([unit, label]) => (
                        <MenuItem
                            key={unit}
                            value={unit}
                        >
                            {label}
                        </MenuItem>
                    ))}
                </Select>
            </Row>
        </InputWrapper>
    )
})
