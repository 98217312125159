import { useCallback, useState } from 'react'


/**
 * Hook for displaying errors in UI components
 */
const useError = () => {
    const [error, setError] = useState<Error | null>(null)

    const raiseError = useCallback((message: string) => {
        setError(new Error(message))
    }, [])

    return {
        error,
        raiseError,
        resetError: () => setError(null),
    }
}

export {
    useError,
}
