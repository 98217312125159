import { Typography } from '@material-ui/core'
import { truncateText } from '@tivio/common'
import { LangCode, Translation } from '@tivio/types'
import React from 'react'

import { getTranslation } from '../../../utils/translate.utils'
import { Column } from '../../uiLayout/Column'
import { Row } from '../../uiLayout/Row'
import { VideoCover } from '../../video/VideoCover'

import { TopWatchedVideo } from './types'


interface Props {
    videos: TopWatchedVideo[]
}

export const MostViewedVideosList: React.FC<Props> = (props) => {
    const { videos } = props

    const getTrimmedStringWithDots = (translation: Translation | string, defaultLangCode = LangCode.EN, stringLengthLimit = 110) => {
        const translatedString = getTranslation(translation, [defaultLangCode])
        return truncateText(translatedString, stringLengthLimit)
    }

    return (
        <Column spacing={2}>
            <Typography variant="h6">
                Most viewed video
            </Typography>
            <Column
                spacing={3}
            >
                {videos.map(video => (
                    <Row
                        key={video.videoId}
                        rowProps={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Row
                            spacing={2}
                            rowProps={{ alignItems: 'center', justifyContent: 'flex-start' }}
                        >
                            <VideoCover cover={video.coverImage} />
                            <Column spacing={1}>
                                <Typography variant="h6">
                                    {getTrimmedStringWithDots(video.name, LangCode.EN)}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="textSecondary"
                                >
                                    {getTrimmedStringWithDots(video.description, LangCode.CS)}
                                </Typography>
                            </Column>
                        </Row>
                        <Typography variant="subtitle1">
                            {`${video.viewCount} views`}
                        </Typography>
                    </Row>
                ))}
            </Column>
        </Column>
    )
}
