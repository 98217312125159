import { useContext } from 'react'

import { CustomerContext } from '../context'


const useCustomer = () => {
    const customerContext = useContext(CustomerContext)

    if (!customerContext) {
        throw new Error('App customer is not set')
    }

    return {
        customer: customerContext.customer,
        platform: customerContext.platform,
        paletteCustomerId: customerContext.paletteCustomerId,
    }
}

export {
    useCustomer,
}
