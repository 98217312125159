import {
    Box,
    Button,
    fade,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core'
import PlayCircleOutline from '@material-ui/icons/PlayCircleOutline'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'


export interface DropzoneContainerProps {
    dropzoneTitle: string;
    dropzoneCaption: string;
}

const useStyles = makeStyles((theme) => ({
    dropzone: {
        borderWidth: 2,
        cursor: 'pointer',
        borderStyle: 'dashed',
        borderColor: fade(theme.palette.common.white, 0.12),
        borderRadius: theme.spacing(1),
        height: 240,
        marginBottom: theme.spacing(5),
    },
    titleWrapper: {
        gap: theme.spacing(1),
    },
    dropzoneGrid: {
        height: '100%',
    },
}))

/**
 * Section dropzone container component
 * Serves for dropping content to upload
 */
const SectionDropzoneContainer = (props: DropzoneContainerProps) => {
    const [t] = useTranslation()
    const classes = useStyles()

    return (
        <div className={classes.dropzone}>
            <Grid
                container
                className={classes.dropzoneGrid}
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <Box textAlign="center">
                        <Box
                            display="flex"
                            alignItems="center"
                            className={classes.titleWrapper}
                        >
                            <PlayCircleOutline />
                            <Typography variant="h6">{props.dropzoneTitle}</Typography>
                        </Box>
                        <Box p={1}>
                            <Typography variant="caption">{props.dropzoneCaption}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item>
                    <Button
                        color="primary"
                        variant="contained"
                    >
                        {t('Upload')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default SectionDropzoneContainer
