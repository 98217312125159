import { useStore } from '../dataHooks/useStore'


export const useAlert = () => {
    const { store } = useStore()
    const { getAlert: alert } = store

    return {
        text: alert.getText,
        type: alert.getType,
        isOpen: alert.isOpen,
        showSuccess: alert.showSuccessAlert,
        showError: alert.showErrorAlert,
        close: alert.closeAlert,
    }
}
