import React, { PropsWithChildren } from 'react'

import { InitialDataContext } from './InitialDataContext'
import { InitialDataContextValue } from './types'


interface Props extends PropsWithChildren {
    value: InitialDataContextValue
}

export const InitialDataProvider = ({ value, children }: Props) => {
    return <InitialDataContext.Provider value={value}>{children}</InitialDataContext.Provider>
}
