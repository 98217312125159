import Marker from '../../store/Marker'
import Video from '../../store/Video'
import { secondsToMilliseconds } from '../../utils/time.utils'
import { FirebaseDocumentData, getFirestore, loggerFirestore } from '../app'

import { markerVodConverter } from './converters'
import { MarkerData } from './markerEpg'


const getVodMarkersCollection = (element: Video | FirebaseFirestore.DocumentReference) => {
    if (element instanceof Video) {
        return getFirestore().collection(`videos/${element.getId}/markers`).withConverter(markerVodConverter)
    } else {
        return getFirestore().collection(`videos/${element.id}/markers`).withConverter(markerVodConverter)
    }
}

const addVodMarker = async (element: Video, markerData: MarkerData) => {
    try {
        const markerRef = await getVodMarkersCollection(element).add(markerData)

        loggerFirestore.info('Marker added with id: ', markerRef.id)

        return markerRef
    }
    catch (e) {
        loggerFirestore.error('Failed to add markerEntity: ', e)
        throw new Error(e)
    }
}

/**
 * Adds many markers to the video.
 *
 * @param videoRef Firestore reference to video.
 * @param markers Array of all markers you want to add to the video.
 */
const addVodMarkers = async (videoRef: FirebaseFirestore.DocumentReference, markers: MarkerData[]) => {
    if (markers.length === 0) {
        return
    }

    try {
        if (markers.length > 500) {
            throw new Error('Too many markers to copy to virtual video.')
        }
        const batch = getFirestore().batch()
        const markersCollection = getVodMarkersCollection(videoRef)

        markers.forEach(marker => {
            batch.set(markersCollection.doc(), marker)
        })
        await batch.commit()
    }
    catch (e) {
        loggerFirestore.error(e.message)
        throw new Error(e)
    }
}

const deleteVodMarker = async (element: Video, marker: Marker) => {
    try {
        await getVodMarkersCollection(element).doc(marker.getId).delete()

        loggerFirestore.info('Marker deleted')
    }
    catch (e) {
        throw new Error(e)
    }
}


const updateVodMarker = async (marker: Marker, markerData: Partial<MarkerData>) => {
    try {
        if (marker.getSectionElement) {
            const updateModel: FirebaseDocumentData = {}

            if (markerData.type) {
                updateModel.type = markerData.type
            }

            if (markerData.from !== undefined) {
                updateModel.fromMs = secondsToMilliseconds(markerData.from)
            }

            if (markerData.to) {
                updateModel.toMs = secondsToMilliseconds(markerData.to)
            }

            if (markerData.name) {
                updateModel.name = markerData.name
            }

            await getVodMarkersCollection(marker.getSectionElement).doc(marker.getId).update(updateModel)

            loggerFirestore.info('Marker updated')
        }
    }
    catch (e) {
        loggerFirestore.error('Failed to update markerEntity', e)
        throw new Error(e)
    }
}

export {
    addVodMarker,
    addVodMarkers,
    getVodMarkersCollection,
    updateVodMarker,
    deleteVodMarker,
}
