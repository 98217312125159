import { mapIndexedAlgoliaObjects, tivio } from '@tivio/core-js'
import {
    ALGOLIA_INDEX_NAME,
    PaginationInterface,
    UseSearch,
    UseSearchOptions,
    UseSearchResult,
} from '@tivio/types'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { loadApplicationsFromAlgoliaHits } from '../../utils'

import { getFiltersByIndex, useAlgoliaSearch } from './algolia'
import { useError } from './useError'


/**
 * Full text search that returns entities based on search query.
 * @param indexName - algolia index that search would be performed on ('videos', 'tags', etc.)
 * @param options - search and pagination options
 */
export const useSearch: UseSearch = <T extends ALGOLIA_INDEX_NAME>(
    indexName: T,
    options?: UseSearchOptions<T>,
) => {
    const optionsWithFallbacks: UseSearchOptions<T> = useMemo(() => ({
        limit: 5,
        minQueryLength: 3,
        ...options,
    }), [JSON.stringify(options)])

    const { search: algoliaSearch } = useAlgoliaSearch(indexName)

    // Internal state (needed for fetchMore calls)
    const [queryState, setQueryState] = useState<{ query: string, lastQuery: string, page: number }>({
        query: '',
        lastQuery: '',
        page: 0,
    })
    // External state
    const [pagination, setPagination] = useState<PaginationInterface<UseSearchResult<T>> | null>(null)
    // TODO loading is part of pagination interface, use it instead of defining new state
    const [isLoading, setIsLoading] = useState(false)
    const { error, raiseError, resetError } = useError()

    const getFetchMore = (hasNextPage: boolean) => () => {
        if (!hasNextPage) {
            return
        }

        setQueryState(prev => ({ ...prev, page: prev.page + 1 }))
    }

    const fetchResults = useCallback(async () => {
        resetError()

        if ((queryState.query?.length ?? 0) < (optionsWithFallbacks?.minQueryLength ?? 0)) {
            setPagination(null)
            return
        }

        setIsLoading(true)

        try {
            // Get indexed object from algolia
            const response = await algoliaSearch(queryState.query, {
                filters: getFiltersByIndex(indexName),
                hitsPerPage: optionsWithFallbacks.limit,
                page: queryState.page,
            })

            if (tivio.organization?.application?.organization?.isTivioPro) {
                await loadApplicationsFromAlgoliaHits(response.hits)
            }

            // Get full document data from tivio db
            const results = await mapIndexedAlgoliaObjects<T>({
                indexName,
                indexedObjects: response.hits,
                options: optionsWithFallbacks,
            })

            setPagination(prevPagination => {
                if (queryState.page === 0) {
                    const hasNextPage = response.nbPages > 1
                    return {
                        items: results,
                        fetchMore: getFetchMore(hasNextPage),
                        hasNextPage,
                    }
                }

                if (prevPagination) {
                    const hasNextPage = response.nbPages > queryState.page + 1
                    return {
                        items: prevPagination.items.concat(results),
                        fetchMore: getFetchMore(hasNextPage),
                        hasNextPage,
                    }
                } else {
                    return null
                }
            })
        } catch (error) {
            raiseError(error)
            setPagination(null)
        } finally {
            setIsLoading(false)
            setQueryState(prev => ({
                ...prev,
                lastQuery: prev.query.trim(),
            }))
        }
    }, [resetError, queryState.query, queryState.page, optionsWithFallbacks, algoliaSearch, indexName, raiseError])

    const search = debounce((query: string) => {
        setQueryState(prev => ({
            ...prev,
            query: query.trim(),
            page: 0,
        }))
    }, 500)

    useEffect(() => {
        fetchResults()
    }, [fetchResults, queryState.query, queryState.page])

    return {
        search,
        pagination,
        isLoading,
        error,
        // Value of last query string
        lastQuery: queryState.lastQuery,
    }
}
