import { nanoid } from 'nanoid'

import Organization from '../../store/Organization'
import { TivioScreen } from '../../store/TivioScreen'
import { FirebaseDocumentData, FirestoreDocumentSnapshot, getFirestore, loggerFirestore } from '../app'

import type { ScreenDocument, ScreenDocumentCreation } from '@tivio/firebase'
import type firebase from 'firebase/app'


export const getScreenCollection = (organizationId: string) => getFirestore()
    .collection(`organizations/${organizationId}/screens`)
    .withConverter({
        fromFirestore: (snapshot: FirestoreDocumentSnapshot): ScreenDocument => {
            return snapshot.data() as ScreenDocument
        },
        toFirestore: (screenFirestore: ScreenDocument): FirebaseDocumentData => {
            return screenFirestore
        },
    })

/**
 * Loads screens by organization id.
 *
 * @param organization organization store
 */
export const getScreensByOrganization = async (organization: Organization): Promise<TivioScreen[]> => {
    const screensSnapshots = await getScreenCollection(organization.id)
        .orderBy('created')
        .get()
    return screensSnapshots.docs.map(
        screenSnapshot => new TivioScreen(screenSnapshot.ref, screenSnapshot.data(), organization),
    )
}

/**
 * Loads screen by organization id and screen id.
 * Throws error when screen is not found
 *
 * @param organization organization store
 * @param screenId screen document id
 */
export const getScreenById = async (organization: Organization, screenId: string): Promise<TivioScreen> => {
    const screenSnapshot = await getScreenCollection(organization.id).doc(screenId).get()

    if (!screenSnapshot.exists || screenSnapshot.data() == null) {
        throw Error(`Screen with id ${screenId} was not found`)
    }

    return new TivioScreen(screenSnapshot.ref, screenSnapshot.data()!, organization)
}

/**
 * Creates new screen (in firebase) with generated screenId for provided organization.
 * Returns constructed TivioScreen entity.
 *
 * @param organization id of organization
 * @param screenData new screen data
 */
export const addTivioScreen = async (
    organization: Organization,
    screenData: ScreenDocumentCreation,
): Promise<TivioScreen> => {
    const newScreenData = { ...screenData, screenId: `screen-${nanoid()}` }
    const ref = await addScreenToFirestore(organization.id, newScreenData)

    return new TivioScreen(ref, newScreenData, organization)
}

/**
 * Adds new screen to screens collection of organization (firebase document) with provided id.
 * Returns reference to created screen.
 *
 * @param organizationId id of organization
 * @param screenData new screen data
 */
const addScreenToFirestore = async (
    organizationId: string,
    screenData: ScreenDocument,
): Promise<firebase.firestore.DocumentReference<ScreenDocument>> => {
    const ref = await getScreenCollection(organizationId).add(screenData)

    loggerFirestore.info(`screen with id ${ref.id} created`)
    return ref
}
