import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import React from 'react'


type DialogCloseButtonProps = {
    onClick: React.MouseEventHandler<HTMLButtonElement>
}

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}))

export const DialogCloseButton = React.forwardRef<HTMLButtonElement, DialogCloseButtonProps>((props, ref) => {
    const { onClick } = props
    const classes = useStyles()

    return (
        <IconButton
            className={classes.root}
            ref={ref}
            onClick={onClick}
        >
            <CloseIcon />
        </IconButton>
    )
})
