import { Container } from '@material-ui/core'
import { Currency, LangCode } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { mapLangCodeToLanguage } from '../../../utils/translate.utils'

import { DragSection } from './DragSection'
import { useOrganization } from '../../hooks'
import { observer } from 'mobx-react'


const getLabelLanguage = (value: string) => {
    return mapLangCodeToLanguage(value as LangCode)
}


const CURRENCIES: {
    code: Currency,
    label: string,
}[] = [
    {
        code: 'CZK',
        label: 'Czech crown',
    },
    {
        code: 'EUR',
        label: 'Euro',
    },
    {
        code: 'USD',
        label: 'US dollar',
    },
]

const getLabelCurrency = (value: string) => {
    const valueCurrency = value as Currency
    const currency = CURRENCIES.find(({ code }) => code === valueCurrency)
    return currency?.label ?? ''
}

export const LocalizationTab = observer(() => {
    const [t] = useTranslation()

    const { organization } = useOrganization()

    return (
        <Container maxWidth="lg">
            <DragSection
                droppableId="languages"
                possibleValues={Object.values(LangCode)}
                getLabel={getLabelLanguage}
                title={t('Languages')}
                menuTitle={t('Add language')}
                values={organization?.languages ?? []}
                onChange={(newValues) => {
                    organization?.updateLanguages(newValues as LangCode[])
                }}
            />
            <DragSection
                droppableId="currencies"
                possibleValues={CURRENCIES.map(({ code }) => code)}
                getLabel={getLabelCurrency}
                title={t('Currencies')}
                menuTitle={t('Add currency')}
                values={organization?.currencies ?? []}
                onChange={(newValues) => {
                    organization?.updateCurrencies(newValues as Currency[])
                }}
            />
        </Container>
    )
})
