import { Tooltip } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'

import type { TooltipProps } from '@material-ui/core'


type Props = Omit<TooltipProps, 'title'> & {
    title?: TooltipProps['title']
}

/**
 * Wrapper for tooltip. Have the same props as Tooltip, but renders it only if title are not empty, otherwise renders only children
 */
export const TooltipWrapper = (props: PropsWithChildren<Props>) => {
    const { children, ...tooltipProps } = props

    return tooltipProps?.title ? (
        <Tooltip
            {...tooltipProps}
            title={tooltipProps.title}
        >
            {children}
        </Tooltip>
    ) : (
        <>
            {children}
        </>
    )
}
