import { GetMonetizationsAnalyticsResponse } from '@tivio/types'

import { getFunctions, loggerFirestore } from '../app'


interface GetConversionOptions {
    organizationId: string
    startDate: string
    endDate: string
}
export const getMonetizationsAnalytics = async ({ organizationId, startDate, endDate }: GetConversionOptions) => {
    try {
        const getMonetizationsAnalytics = getFunctions().httpsCallable('getMonetizationsAnalytics')
        const response: { data: GetMonetizationsAnalyticsResponse } = await getMonetizationsAnalytics({
            organizationId,
            startDate,
            endDate,
        })

        loggerFirestore.info('Successfully fetched monetization analytics.')
        return response.data
    } catch (e) {
        loggerFirestore.error('Failed to create invite: ', e)
        throw new Error(e)
    }
}

