import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React, { FC } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { isNotEmpty } from '../../utils/array.utils'
import { onDragEndUtil } from '../../utils/order.utils'

import { StrategyRowAdSlot } from './StrategyRowAdSlot'
import { StrategyRowTitle } from './StrategyRowTitle'

import type { AdProfileDocumentWithId } from '../../firebase/firestore/converters'


const useStyles = makeStyles(() => ({
    strategyRowNotLast: {
        borderBottom: '1px solid rgba(255,255,255,0.2)',
        paddingBottom: '15px',
    },
}))

interface StrategyRowProps {
    dbKey: string
    title: string
    adSlots?: AdProfileDocumentWithId[]
    isLast?: boolean
    midRollInterval?: number | string
    showSelectInterval?: boolean
    handleAddAdSlot: (strategyName: string, adSlot: AdProfileDocumentWithId) => void
    handleChangeAdSlotsOrder: (strategyName: string, adSlots: AdProfileDocumentWithId[]) => void
    handleDeleteAdSlot: (strategyName: string, index: number, slotName?: string) => void
    handleDeleteAdConfig: (strategyName: string) => void
    handleChangeMidRollInterval?: (interval: number | string) => void
    adProfiles: AdProfileDocumentWithId[]
}

export const StrategyRow: FC<StrategyRowProps> = ({
    dbKey,
    title,
    adSlots = [],
    isLast,
    showSelectInterval,
    handleAddAdSlot,
    handleChangeAdSlotsOrder,
    handleDeleteAdSlot,
    handleDeleteAdConfig,
    handleChangeMidRollInterval,
    midRollInterval,
    adProfiles,
}) => {
    const classes = useStyles()

    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            className={clsx((!isLast) ? classes.strategyRowNotLast : null)}
        >
            <Grid
                item
            >
                <StrategyRowTitle
                    dbKey={dbKey}
                    title={title}
                    numberOfAds={adSlots.length}
                    midRollInterval={midRollInterval}
                    showSelectInterval={showSelectInterval}
                    handleAddAdSlot={handleAddAdSlot}
                    handleDeleteAdConfig={isNotEmpty(adSlots) ? handleDeleteAdConfig : null}
                    handleChangeMidRollInterval={handleChangeMidRollInterval}
                    adProfiles={adProfiles}
                />
            </Grid>
            <Grid
                item
            >
                <DragDropContext onDragEnd={(result) => {
                    const sorted = onDragEndUtil(result, adSlots)
                    if (sorted) {
                        handleChangeAdSlotsOrder(dbKey, sorted)
                    }
                }}>
                    <Droppable droppableId="providers">
                        {provided => (
                            <Grid
                                container
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="stretch"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {adSlots.map((adSlot, index) => (
                                    <StrategyRowAdSlot
                                        id={adSlot.id}
                                        name={adSlot.name}
                                        color={adSlot.color}
                                        type={adSlot.type}
                                        index={index}
                                        handleDeleteAdSlot={() => handleDeleteAdSlot(dbKey, index, adSlot.name)}
                                    />
                                ))}
                                <Grid
                                    item
                                >
                                    {provided.placeholder}
                                </Grid>
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            </Grid>
        </Grid>
    )
}
