import { Fade } from '@material-ui/core'
import { PublishedStatus, VideoType } from '@tivio/types'
import React from 'react'
import styled from 'styled-components'

import { COLORS } from '../../static/enum'


type BadgeVariant = 'warning' | 'info' | 'disabled'

const getBadgeColor = (variant: BadgeVariant) => {
    switch (variant) {
        case 'warning':
            return COLORS.STATUS_UNLISTED
        case 'info':
            return COLORS.PRIMARY
        case 'disabled':
            return COLORS.INACTIVE_CHIP
    }
}

export const BlankVideoCover = styled.div`
    position: relative;
    width: 190px;
    height: 107px;
    background-color: ${COLORS.DRAWER_BACKGROUND};
    border-radius: 8px;
    background-position: center;
    background-size: cover;
`

const VideoProgress = styled.div.attrs<{ progress: number }>((props) => {
    return {
        style: {
            right: `${100 - props.progress}%`,
            borderBottomRightRadius: props.progress > 99 && '8px',
        },
    }
})<{ progress: number }>`
    background-color: ${(props) => props.theme.palette.primary.main};
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    border-bottom-left-radius: 8px;
    transition: 0.2s ease-out all;
`

const Badges = styled.div`
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
`

const Badge = styled.div<{ variant: BadgeVariant }>`
    padding: 2px 4px;
    border-radius: 4px;
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-weight: 500;
    background-color: ${(props) => getBadgeColor(props.variant)};
`

interface Props {
    cover?: string | null
    showProgress?: boolean
    publishedStatus?: PublishedStatus
    progress?: number
    videoType?: VideoType
    showBadges?: boolean
}

export const VideoCover = ({ cover, publishedStatus, showProgress, progress, showBadges, videoType }: Props) => {
    return (
        <BlankVideoCover style={cover ? { backgroundImage: `url(${cover})` } : undefined}>
            <Fade in={showProgress}>
                <VideoProgress progress={progress ?? 0} />
            </Fade>
            {showBadges && <Badges>
                {publishedStatus && publishedStatus !== PublishedStatus.PUBLISHED && (
                    <Badge variant={publishedStatus === PublishedStatus.DRAFT ? 'warning' : 'disabled'}>
                        {publishedStatus}
                    </Badge>
                )}
                {videoType && [VideoType.TEASER, VideoType.TASTING].includes(videoType) && (
                    <Badge variant="info">{videoType.toLocaleUpperCase()}</Badge>
                )}
            </Badges>}
        </BlankVideoCover>
    )
}
