import type { TMemberFirestore } from '../firebase/firestore/member'
import type { OrganizationMemberRoles } from '@tivio/firebase'
import type firebase from 'firebase/app'


class MemberAdmin {
    private ref: firebase.firestore.DocumentReference<TMemberFirestore>
    private firestoreData: TMemberFirestore
    private readonly role: OrganizationMemberRoles

    constructor(
        ref: firebase.firestore.DocumentReference<TMemberFirestore>,
        firestoreData: TMemberFirestore,
        role: OrganizationMemberRoles,
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.role = role
    }

    get getRef() {
        return this.ref
    }

    get getId() {
        return this.ref.id
    }

    get getAvatar() {
        return this.firestoreData.avatar
    }

    get getFName() {
        return this.firestoreData.fName
    }

    get getLName() {
        return this.firestoreData.lName
    }

    get getEmail() {
        return this.firestoreData.email
    }

    get getCurrentRole() {
        return this.role
    }

    get getUid() {
        return this.firestoreData.uid
    }
}

export default MemberAdmin
