import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, {
    forwardRef,
    Ref,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import { MARKER_TYPES } from '../../static/enum'
import Marker, { IMarker } from '../../store/Marker'
import { Interval } from '../../utils/epg.utils'
import { getMarkerMean } from '../../utils/markers.utils'
import { percentage, percentToValue } from '../../utils/math.utils'
import { SECOND } from '../../utils/time.utils'
import { noChaptersFilter } from '../../utils/vod.utils'
import useMouseActions, { AppMouseEvent } from '../hooks/uiHooks/useMouseActions'

import { PlayerInterval } from './PlayerInterval'
import { PlayerMarkerSelector } from './PlayerMarkerSelector'
import { PlayerSeekbarCaret } from './PlayerSeekbarCaret'
import { PlayerSeekbarMarker } from './PlayerSeekbarMarker'


export type HandleContainerMouseEvent = (position: number) => void

export interface PlayerSeekbarProps {
    contentDuration: number
    playingPosition: number
    isMarkerFormOpen: boolean
}

interface Props extends PlayerSeekbarProps {
    children: React.ReactElement
    markers: IMarker[]
    onMove: HandleContainerMouseEvent
    onMouseUp: (marker: MarkerData) => void
    containerClassName?: string
    epgInterval?: Interval
    disabled?: boolean
}

export interface SeekbarController {
    resetInterval: () => void
    setInterval: (interval: Interval) => void
}

const useStyles = makeStyles({
    container: {
        position: 'relative',
    },
})

/**
 * Consumes content duration and transforms cursor position on this container to seconds based on container width
 */
const PlayerSeekbar = (props: Props, ref?: Ref<SeekbarController>) => {
    const seekbarRef = useRef<HTMLDivElement | null>(null)
    const classes = useStyles()
    const [markerMenuAnchorEl, setMarkerMenuAnchorEl] = useState<null | HTMLElement>(null)
    const [closestMarkers, setCloseMarkers] = useState<IMarker[]>([])
    const [forceInterval, setForceInterval] = useState<Interval>()
    const {
        handleMouseDown,
        handleMouseUp,
        mouseCurrentPos,
        mouseDownPos,
        resetInterval,
    } = useMouseActions()

    useImperativeHandle(
        ref,
        () => ({
            resetInterval: () => {
                resetInterval()
                setForceInterval(undefined)
            },
            setInterval: (interval) => {
                setForceInterval(interval)
            },
        }),
    )

    const getCaretPosition = useCallback(
        () => {
            return percentage(props.playingPosition, props.contentDuration)
        },
        [props.playingPosition, props.contentDuration],
    )

    const getIntervalPosition = useCallback(
        (e?: AppMouseEvent) => {
            if (seekbarRef.current && e) {
                return mousePositionToPercent(e)
            }

            return 0
        },
        [],
    )

    const mousePositionToPercent = useCallback(
        <T extends AppMouseEvent>(e: T) => {
            if (seekbarRef.current) {
                const mousePos = e.clientX
                const containerBounds = seekbarRef.current.getBoundingClientRect()

                const mousePosOnContainer = mousePos - containerBounds.left
                const percent = percentage(mousePosOnContainer, containerBounds.width)

                // 100% is max possible value here
                return percent > 100 ? 100 : percent
            }

            return 0
        },
        [],
    )

    const handleContainerMove = useCallback(
        (e: React.MouseEvent) => {
            if (!props.isMarkerFormOpen) {
                props.onMove(mousePositionToPercent(e))
            }
        },
        [props.onMove, props.isMarkerFormOpen],
    )

    const getInterval = useCallback(
        () => {
            if (mouseDownPos && mouseCurrentPos) {
                const mouseDownPercent = percentToValue(props.contentDuration, mousePositionToPercent(mouseDownPos))
                const mouseUpPercent = percentToValue(props.contentDuration, mousePositionToPercent(mouseCurrentPos))

                if (mouseUpPercent < mouseDownPercent) {
                    return {
                        from: mouseUpPercent,
                        to: mouseDownPercent,
                    }
                }

                return {
                    from: mouseDownPercent,
                    to: mouseUpPercent,
                }
            }

            return {
                from: 0,
                to: 0,
            }
        },
        [mouseCurrentPos, mouseDownPos, props.contentDuration],
    )

    const handleContainerMouseUp = useCallback(
        (e: React.MouseEvent) => {
            handleMouseUp(e)

            if (mouseCurrentPos && mouseDownPos) {
                props.onMouseUp({
                    type: MARKER_TYPES.AD,
                    ...getInterval(),
                })
            }
            else {
                props.onMouseUp({
                    type: MARKER_TYPES.AD,
                    from: percentToValue(props.contentDuration, mousePositionToPercent(e)),
                    to: percentToValue(props.contentDuration, mousePositionToPercent(e)),
                })
            }
        },
        [mouseCurrentPos, mouseDownPos],
    )

    const handleCaretClick = () => {
        props.onMouseUp({
            type: MARKER_TYPES.AD,
            from: props.playingPosition,
            to: props.playingPosition,
        })
    }

    const handleMarkerClick = (markerData: MarkerData, anchorEl: HTMLElement) => {
        setMarkerMenuAnchorEl(anchorEl)

        const markerPosition = getMarkerMean(markerData.from, markerData.to)
        const markerIntervalSize = props.epgInterval
            ? (120 * SECOND)
            : 10 * SECOND

        const closeMarkersInterval: Interval = {
            from: markerPosition - markerIntervalSize + (props.epgInterval ? props.epgInterval.from : 0),
            to: markerPosition + markerIntervalSize + (props.epgInterval ? props.epgInterval.from : 0),
        }

        if (markerData.markerEntity) {
            const closeMarkers = props.markers
                .filter(noChaptersFilter)
                .filter(
                    marker => {
                        const currentMarkerPosition = getMarkerMean(marker.getFrom, marker.getTo)

                        if (
                            currentMarkerPosition > closeMarkersInterval.from &&
                            currentMarkerPosition < closeMarkersInterval.to
                        ) {
                            return true
                        }
                    },
                )

            if (closeMarkers.length > 1) {
                setCloseMarkers(closeMarkers)
            }
            else {
                props.onMouseUp(markerData)
            }
        }
    }

    const handleSelectMarker = (marker: Marker) => {
        handleResetCloseMarkers()

        props.onMouseUp({
            from: marker.getFrom - (props.epgInterval ? props.epgInterval.from : 0),
            to: marker.getTo - (props.epgInterval ? props.epgInterval.from : 0),
            name: marker.getName,
            type: marker.getType,
            markerEntity: marker,
        })
    }

    const handleResetCloseMarkers = () => {
        setMarkerMenuAnchorEl(null)
        setCloseMarkers([])
    }

    const markers = useMemo(
        () => {
            return props.markers
                .filter(noChaptersFilter)
                .map(
                    marker => (
                        <PlayerSeekbarMarker
                            key={marker.getId}
                            duration={props.contentDuration}
                            epgInterval={props.epgInterval}
                            marker={marker}
                            onMarkerClick={handleMarkerClick}
                            disabled={props.disabled}
                        />
                    ),
                )
        },
        [props.markers, props.epgInterval],
    )

    const caret = useMemo(
        () => {
            return (
                <PlayerSeekbarCaret
                    left={getCaretPosition()}
                    onCaretClick={handleCaretClick}
                    disabled={props.disabled}
                />
            )
        },
        [props.playingPosition],
    )

    const interval = useMemo(
        () => {
            if (forceInterval && forceInterval.from !== forceInterval.to) {
                return (
                    <PlayerInterval
                        from={percentage(forceInterval.from, props.contentDuration)}
                        to={percentage(forceInterval.to, props.contentDuration)}
                    />
                )
            }

            if (mouseCurrentPos) {
                return (
                    <PlayerInterval
                        from={getIntervalPosition(mouseDownPos)}
                        to={getIntervalPosition(mouseCurrentPos)}
                    />
                )
            }
        },
        [mouseDownPos, mouseCurrentPos, forceInterval],
    )

    const markerSelector = useMemo(
        () => {
            if (markerMenuAnchorEl && closestMarkers.length > 0) {
                return (
                    <PlayerMarkerSelector
                        anchorEl={markerMenuAnchorEl}
                        markers={closestMarkers}
                        onClose={handleResetCloseMarkers}
                        onMarkerSelect={handleSelectMarker}
                    />
                )
            }

            return null
        },
        [markerMenuAnchorEl, closestMarkers],
    )

    return (
        <div
            className={clsx([
                classes.container,
                props.containerClassName,
            ])}
            onMouseDown={!props.disabled ? handleMouseDown : undefined}
            onMouseMove={handleContainerMove}
            onMouseUp={!props.disabled ? handleContainerMouseUp : undefined}
            ref={seekbarRef}
        >
            {props.children}
            {markerSelector}
            {markers}
            {caret}
            {interval}
        </div>
    )
}

export default forwardRef(PlayerSeekbar)
