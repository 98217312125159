import { IconButton } from '@material-ui/core'
import Create from '@material-ui/icons/Create'
import Delete from '@material-ui/icons/Delete'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import type { MouseEvent } from 'react'


const StyledIconButton = styled(IconButton)<{ scale: number }>`
    ${props => props.scale && 'transform: scale(' + props.scale + ');'}
    padding: ${props => props.scale * 12}px;
    ${props => props.scale < 1 && 'margin: -' + props.scale * 12  + 'px;'}
`

export type Size = 'mini' | 'small' | 'medium' | 'large'

interface Props {
    onDelete?: (event: MouseEvent) => any
    onEdit?: (event: MouseEvent) => any
    deleteTitle?: string
    editTitle?: string
    size?: Size
    className?: string
}

const getFontSize = (size: Size) => {
    if (size === 'mini') {
        return 'small'
    }

    return size
}

const getScale = (size: Size) => {
    if (size === 'mini') {
        return 0.65
    }

    return 1
}

export const ActionButtons = (props: Props) => {
    const {
        editTitle,
        deleteTitle,
        size = 'medium',
        className,
    } = props
    const [ t ] = useTranslation()

    const onEdit = useCallback((event: MouseEvent) => {
        props.onEdit?.(event)

        event.stopPropagation()
    }, [props.onEdit])

    const onDelete = useCallback((event: MouseEvent) => {
        props.onDelete?.(event)

        event.stopPropagation()
    }, [props.onDelete])

    return (
        <span className={className}>
            {props.onEdit &&
                <StyledIconButton
                    scale={getScale(size)}
                    onClick={onEdit}
                    title={editTitle ?? t('Edit')}
                    data-e2e="edit-action"
                >
                    <Create fontSize={getFontSize(size)} />
                </StyledIconButton>
            }
            {props.onDelete &&
                <StyledIconButton
                    scale={getScale(size)}
                    onClick={onDelete}
                    title={deleteTitle ?? t('Delete')}
                    data-e2e="delete-action"
                >
                    <Delete fontSize={getFontSize(size)} />
                </StyledIconButton>
            }
        </span>
    )
}
