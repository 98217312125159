import { Typography } from '@material-ui/core'
import { round } from '@tivio/common'
import React from 'react'

import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { AnalyticCard } from './AnalyticCard'
import { GainChip } from './GainChip'


interface Props {
    title: string
    value: string | number
    valueSuffix?: string
    subvalue?: string | number
    gain?: number
    gainInterval?: string
    selectColor?: string
    onClick?: () => void
}

export const OverviewCard: React.FC<Props> = (props) => {
    const {
        title,
        value,
        subvalue,
        valueSuffix,
        gain,
        gainInterval,
        selectColor,
        onClick,
    } = props

    const innerValue = typeof value === 'number' ? round(value, 2).toLocaleString('cs-CZ') : value
    const innerSubValue = typeof subvalue === 'number' ? round(subvalue, 2) : subvalue

    return (
        <AnalyticCard
            title={title}
            selectColor={selectColor}
            onClick={onClick}
        >
            <Column>
                <Typography variant="h5">{innerValue}{valueSuffix && (` ${valueSuffix}`)}</Typography>
                {subvalue && (<Typography variant="subtitle2">{`${innerSubValue}`}</Typography>)}
                {gain &&
                    (<Row
                        spacing={2}
                        rowProps={{ alignItems: 'center', justifyContent: 'flex-start' }}
                    >
                        <GainChip value={gain} />
                        <Typography
                            variant="caption"
                            color="textSecondary"
                        >
                            {gainInterval}
                        </Typography>
                    </Row>)
                }
            </Column>
        </AnalyticCard>
    )
}
