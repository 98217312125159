import { MARKER_TYPES } from '../static/enum'
import Marker from '../store/Marker'
import VirtualMarker from '../store/VirtualMarker'

import { Interval } from './epg.utils'
import { isAdMarker } from './markers.utils'


interface Item {
    getFrom: number
    getTo: number
}

export const validators = {
    isEqualToZero: (position: number) => {
        return position === 0
    },
    isIntersect: (from: number, to: number) => {
        return from > to
    },
    isGreaterThanZero: (position: number) => {
        return position > 0
    },
    isLesserThanDuration: (position: number, duration: number) => {
        return position < duration
    },
    isEqualToContentDuration: (position: number, duration: number) => {
        return Math.floor(position) === Math.floor(duration)
    },
}

export const fillEmptyHoles = <T extends Item, G extends Item>(
    items: (T | G)[],
    interval: Interval,
    newEmptyItem: (index: number, startPosition: number, endPosition: number) => G,
) => {
    const newItems: (T | G)[] = []
    const lastItemIndex = items.length - 1

    items.reduce( // file empty spaces
        (
            previousItem,
            currentItem,
            index,
            source,
        ) => {

            // Before first program
            if (index === 0 && currentItem.getFrom > interval.from) {
                newItems.push(newEmptyItem(index, interval.from, currentItem.getFrom))
            }

            // Between two programs
            if (index > 0 && source[index - 1] && currentItem.getFrom !== source[index - 1].getTo) {
                newItems.push(newEmptyItem(index, source[index - 1].getTo, currentItem.getFrom))
            }

            newItems.push(currentItem)

            // After last program
            if (index === lastItemIndex && currentItem.getTo < interval.to) {
                newItems.push(newEmptyItem(index, currentItem.getTo, interval.to))
            }

            return previousItem
        },
        [],
    )

    return newItems
}

export const filterEpgIntervalElements = <T extends Item>(elements: T[], epgInterval: Interval) => {
    return elements.filter(
        element =>
            (// getFrom is inside currentEpg range
                element.getFrom >= epgInterval.from
                && element.getFrom < epgInterval.to
            ) ||
            (// getTo is inside epgInterval range
                element.getTo > epgInterval.from
                && element.getTo <= epgInterval.to
            ) ||
            (
                // getFrom is before epgInterval and simultaneously getTo is after epgInterval
                // (element is bigger than epgInterval range)
                element.getFrom <= epgInterval.from
                && element.getTo >= epgInterval.to
            ),
    )
}

/**
 * Converts set of real EPG markers to set of virtual markers. All ad segments will be joined to one virtual ad segment.
 * All markers between one program's end and other program's start will be joined as one virtual break segment.
 *
 * @param markers Markers to be joined.
 * @returns Set of virtual markers computed from given "real" markers.
 */
export const joinEpgAdSegments = (markers: Marker[]): VirtualMarker[] => {
    const adMarkers: VirtualMarker[] = [] // Ad markers
    const otherMarkers: VirtualMarker[] = [] // Other than AD markers
    let isProgramBreak = false // True when iterating over "program break" segment (after END, before START)

    if (!markers.length) { // No markers, return empty array.
        return adMarkers
    }

    // First, we must sort markers by their start position and convert all of them to virtual markers:
    const sortedMarkers = markers
        .sort((a, b) => a.from - b.from)
        .map(marker => new VirtualMarker(marker))

    // Then, we iterate over every virtual marker:
    for (let i = 0; i < sortedMarkers.length; i++) {
        const currentMarker = sortedMarkers[i] // Currently iterated marker
        const lastAdMarker = adMarkers.length ? adMarkers[adMarkers.length - 1] : null // Last AD marker in stack

        // Let's solve all non-AD markers here. First, if it's CONTINUE we do nothing (throw it away) and continue with next iteration.
        // If it's program break (between END and START) we start or end break interval.
        // If it's any other non-AD marker we add it to stack of "other" markers.
        if (!isAdMarker(currentMarker.getType)) {
            if (currentMarker.getType !== 'CONTINUE') {
                if (currentMarker.getType === 'END') { // Let's start program break.
                    currentMarker.setType = MARKER_TYPES.PROGRAM_BREAK
                    adMarkers.push(currentMarker)
                    isProgramBreak = true
                } else if (currentMarker.getType === 'START') { // Let's end program break.
                    if (lastAdMarker) {
                        lastAdMarker.to = currentMarker.from
                    }
                    isProgramBreak = false
                } else {
                    otherMarkers.push(currentMarker)
                }
            }
            continue // This marker is non-AD so we can move to next iteration
        } else if (!lastAdMarker) { // If there is no AD marker in stack yet, create first one.
            currentMarker.setType = MARKER_TYPES.AD
            adMarkers.push(currentMarker)
            continue // Just created first AD marker in stack so we can move to next iteration.
        }

        if (isProgramBreak) { // If we are in between programs, we throw all ADs away and continue with next iteration.
            continue
        }

        // If last marker in stack ends before next marker starts it's separate AD block
        if (lastAdMarker.to < currentMarker.from) { // we've added 1000 ms because markers are always 1 second away from each other
            currentMarker.setType = MARKER_TYPES.AD
            adMarkers.push(currentMarker)
        } else {
            lastAdMarker.to = currentMarker.to
        }
    }

    return [...adMarkers, ...otherMarkers]
}
