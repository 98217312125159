import { PlayerEngineFactory } from '@tivio/types'

import { FairplayEngine, HTMLVideoElementWebKit } from './fairplay'
import { ShakaEngine } from './shaka'


export const createShakaEngine: PlayerEngineFactory = (videoElement: HTMLVideoElement) => {
    return Promise.resolve(new ShakaEngine(videoElement, { seekDebounceMs: 400 }))
}

export const createFairplayEngine: PlayerEngineFactory = (videoElement: HTMLVideoElement) => {
    return Promise.resolve(new FairplayEngine(videoElement as HTMLVideoElementWebKit, { seekDebounceMs: 400 }))
}

