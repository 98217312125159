import { Grid, IconButton, makeStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import User from '../../store/User'
import { goUserPage } from '../../utils/history.utils'
import { useListItemStyles } from '../common/styles'


const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    userMain: {
        fontSize: '1rem',
    },
    userInfo: {
        fontSize: '0.75rem',
        color: theme.palette.text.secondary,
    },
    showOnHover: {
        display: 'none',

        '&:hover': {
            display: 'block',
        },
    },
}))

type UsersItemProps = {
    user: User
}

export const UsersItem: React.FC<UsersItemProps> = ({ user }) => {
    const classes = useStyles()
    const listItemClasses = useListItemStyles()
    const [ t ] = useTranslation()

    const handleUserClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        goUserPage(user)
    }


    return (
        <Grid
            container
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
            className={clsx(listItemClasses.root, classes.root)}
            component="a"
            href={`/users/${user.id}`}
            onClick={handleUserClick}
        >
            <Grid
                item
                className={classes.userMain}
                xs={4}
            >
                {user.email}
            </Grid>
            <Grid
                item
                className={classes.userInfo}
                xs={2}
            >
                {user.id}
            </Grid>
            <Grid
                item
                className={classes.userInfo}
                xs={2}
            >
                {user.externalId || '-'}
            </Grid>
            <Grid
                item
                className={classes.showOnHover}
            >
                <IconButton
                    size="small"
                    title={t('Edit user')}
                >
                    <EditIcon />
                </IconButton>
            </Grid>
        </Grid>
    )
}
