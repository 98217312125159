import { createLogger } from '../../../logger'

import { useOrganization } from './useOrganization'


const logger = createLogger('useTransactions')

const useTransactions = () => {
    const { organization } = useOrganization()

    const addTransaction = async () => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        const transactionsCount = organization.transactions.length + 1
        await organization.addTransaction({
            title: `Transaction ${transactionsCount}`,
        })
    }

    const removeTransaction = async (transactionId: string) => {
        if (!organization) {
            logger.info('organization not ready')
            return
        }

        await organization.removeMonetization(transactionId)
    }

    return {
        transactions: organization?.transactions ?? [],
        addTransaction,
        removeTransaction,
    }
}

export {
    useTransactions,
}
