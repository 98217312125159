import { tivio } from '@tivio/core-js'
import { Article } from '@tivio/types'
import { useEffect, useState } from 'react'


export const useArticle = (articleId: string) => {
    const [article, setArticle] = useState<Article | null>(null)

    useEffect(() => {
        tivio.getArticleById(articleId).then(setArticle)
    }, [articleId])

    return article
}
