import { Grid } from '@material-ui/core'
import { observer } from 'mobx-react'
import React, { Ref, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { MarkerData } from '../../firebase/firestore/markerEpg'
import Logger from '../../logger'
import { COLORS } from '../../static/enum'
import TvChannel from '../../store/TvChannel'
import { notEmptyFilter } from '../../utils/array.utils'
import { Interval } from '../../utils/epg.utils'
import { onlyProgramBreak } from '../../utils/markers.utils'
import { percentage, percentToValue } from '../../utils/math.utils'
import { filterEpgIntervalElements, joinEpgAdSegments } from '../../utils/seekbar.utils'
import { StreamStatus } from '../../utils/stream.utils'
import PlayerSeekbar, { HandleContainerMouseEvent, PlayerSeekbarProps, SeekbarController } from '../player/PlayerSeekbar'
import { SEEKBAR_MIN_HEIGHT, useSeekbarStyles } from '../player/styles.seekbar'

import EpgControlArrows, { EpgControlArrowsProps } from './EpgControlArrows'
import { SeekbarProgram } from './EpgMarkerEditor'
import EpgProgram from './EpgProgram'


interface Props extends PlayerSeekbarProps, EpgControlArrowsProps {
    tvChannel: TvChannel
    programs: SeekbarProgram[]
    stream: { epgInterval: Interval, streamStatus: StreamStatus }
    onSeek: (playingPosition: number) => void
    onSeekbarClick: (marker: MarkerData) => void
    mouseContainerRef?: Ref<SeekbarController>
}

const StyledUnavailableStreamOverlayContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: 100%;
    min-height: ${SEEKBAR_MIN_HEIGHT};
`

const StyledUnavailableStreamOverlay = styled.div<{ width: number | string }>`
    width: ${(props) => props.width};
    height: 100%;
    min-height: ${SEEKBAR_MIN_HEIGHT};
    background-color: ${COLORS.DISABLED_OVERLAY};
`

const log = new Logger('EpgSeekbarWrapper')

const EpgSeekbarWrapper = observer((props: Props) => {
    const classesSeekbar = useSeekbarStyles()
    const containerRef = useRef<HTMLDivElement>()
    const { epgInterval, streamStatus } = props.stream

    const unavailableStreamOverlayWidth = useMemo(() => {
        const FALLBACK_VALUE = '0%'
        if (!epgInterval) {
            return FALLBACK_VALUE
        }

        const { from, to, latestFetchedTime } = epgInterval

        switch (streamStatus) {
            case StreamStatus.LOADING:
            case StreamStatus.FULLY_AVAILABLE:
                return '0%'
            case StreamStatus.NOT_YET_AVAILABLE:
            case StreamStatus.FETCH_FAILED:
                return '100%'
            case StreamStatus.PARTLY_AVAILABLE:
                if (!latestFetchedTime) {
                    log.error(`Stream status is ${streamStatus}, but latestFetchedTime is not present.
                    Maybe just problem due to asynchronous updates, and will fix with the next update tick`)
                    return FALLBACK_VALUE
                }
                return percentage(to - latestFetchedTime, to - from) + '%'
            default:
                log.error(`Status ${streamStatus} is unknown, overlay won't be rendered`)
                return FALLBACK_VALUE
        }
    }, [props.stream])

    const handleSeekbarMove: HandleContainerMouseEvent = useCallback(
        (position) => {
            if (!props.isMarkerFormOpen) {
                props.onSeek(percentToValue(props.contentDuration, position))
            }
        },
        [props.contentDuration],
    )

    const getMarkers = useCallback(
        () => {
            const filteredElements = filterEpgIntervalElements(
                props.tvChannel.getMarkers,
                epgInterval,
            )
            return joinEpgAdSegments(filteredElements)
        },
        [epgInterval, props.tvChannel.getMarkers],
    )

    const programs = useMemo(
        () => {
            const Programs = props.programs
                .map(
                    program => {
                        let programTimeline = program.getTimeline

                        if (program.getFrom < epgInterval.from) {
                            programTimeline = {
                                from: epgInterval.from,
                                to: program.getTo,
                            }
                        }

                        if (program.getTo > epgInterval.to) {
                            programTimeline = {
                                from: program.getFrom,
                                to: epgInterval.to,
                            }
                        }

                        const width = percentage(programTimeline.to - programTimeline.from, props.contentDuration)

                        return (
                            <EpgProgram
                                key={program.getId}
                                width={width}
                                program={program}
                                markers={getMarkers().filter(onlyProgramBreak)}
                            />
                        )
                    },
                )
                .filter(notEmptyFilter)

            return (
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    wrap="nowrap"
                    innerRef={containerRef}
                    spacing={1}
                >
                    {Programs}
                </Grid>
            )
        },
        [props.programs, epgInterval, props.contentDuration],
    )

    const EpgControlArrowsMemo = useMemo(
        () => {
            return (
                <EpgControlArrows
                    onGoBefore={props.onGoBefore}
                    onGoNext={props.onGoNext}
                    disabled={props.isMarkerFormOpen}
                />
            )
        },
        [props.onGoBefore, props.onGoNext],
    )

    const UnavailableStreamOverlay = unavailableStreamOverlayWidth !== '0%' ? (
        <StyledUnavailableStreamOverlayContainer>
            <StyledUnavailableStreamOverlay width={unavailableStreamOverlayWidth} />
        </StyledUnavailableStreamOverlayContainer>
    ) : null

    return (
        <PlayerSeekbar
            containerClassName={classesSeekbar.seekbarContainer}
            contentDuration={props.contentDuration}
            isMarkerFormOpen={props.isMarkerFormOpen}
            epgInterval={epgInterval}
            markers={getMarkers()}
            onMouseUp={props.onSeekbarClick}
            onMove={handleSeekbarMove}
            playingPosition={props.playingPosition}
            ref={props.mouseContainerRef}
        >
            <>
                {UnavailableStreamOverlay}
                {EpgControlArrowsMemo}
                {programs}
            </>
        </PlayerSeekbar>
    )
})

export default EpgSeekbarWrapper
