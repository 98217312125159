import Tv from '@material-ui/icons/Tv'
import { observer } from 'mobx-react'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { firebaseTimestampFromDate } from '../../firebase/app'
import TvChannel from '../../store/TvChannel'
import { goEpgChannelPage } from '../../utils/history.utils'
import { DialogEditTvChannel, FormResult } from '../epg/DialogEditTvChannel'
import { useEpgChannels } from '../hooks/dataHooks/useEpgChannels'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../hooks/uiHooks/useDialog'

import { MenuDropdown, MenuDropdownItem } from './MenuDropdown'



const useEditDialogUtils = () => {
    const { createTvChannel, updateTvChannel } = useEpgChannels()
    const [ t ] = useTranslation()
    const { isDialogOpen, openDialog, closeDialog: closeEditDialog } = useDialog()
    const [editedChannel, setEditedChannel] = useState<TvChannel|null>(null)
    const { showSuccess } = useAlert()

    const closeDialog = () => {
        closeEditDialog()
        setEditedChannel(null)
    }

    const onConfirmDialog = useCallback((formData: FormResult) => {
        if (formData.isNew) {
            createTvChannel({
                sources: [],
                ...formData.data,
                // TODO ideally this conversion should take place after we send data to firebase (we need separate types for that)
                created: firebaseTimestampFromDate(new Date()),
            })
            showSuccess(t('TV Channel created'))
        } else if (editedChannel) {
            updateTvChannel(editedChannel, {
                ...formData.data,
                // TODO ideally this conversion should take place after we send data to firebase (we need separate types for that)
                updated: firebaseTimestampFromDate(new Date()),
            })
            showSuccess(t('TV Channel updated'))
        }

        closeDialog()
    }, [editedChannel])

    return {
        onConfirmDialog,
        isDialogOpen,
        openDialog,
        closeDialog,
        setEditedChannel,
        editedChannel,
    }
}

/**
 * Menu item - TV channels
 * Serves for navigation between TV channels
 */
const MenuEpgChannels = observer(() => {
    const { channels, removeTvChannel } = useEpgChannels()
    const [ t ] = useTranslation()
    const { confirmAction } = useConfirmAction()
    const {
        onConfirmDialog,
        isDialogOpen,
        openDialog,
        closeDialog,
        setEditedChannel,
        editedChannel,
    } = useEditDialogUtils()

    const items: MenuDropdownItem[] = useMemo(() => {
        return channels.map(channel => {
            const onEdit = () => {
                setEditedChannel(channel)
                openDialog()
            }

            const onDelete = () => {
                confirmAction(() => {
                    removeTvChannel(channel.getId)
                }, t('Remove') + ` ${channel.getName}?`)
            }

            return {
                name: channel.getName,
                id: channel.getId,
                action: () => goEpgChannelPage(channel.getId),
                onEdit,
                onDelete,
            }
        })
    }, [channels])

    return (
        <>
            {isDialogOpen && <DialogEditTvChannel
                tvChannel={editedChannel}
                onCancel={closeDialog}
                onConfirm={onConfirmDialog}
                open={isDialogOpen}
            />}
            <MenuDropdown
                icon={<Tv/>}
                name={t('Tv Channels')}
                items={items}
                dropDownAction={{
                    action: openDialog,
                    name: t('Add channel'),
                }}
            />
        </>
    )
})

export {
    MenuEpgChannels,
}
