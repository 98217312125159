import React from 'react'


const preventMouseActions = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
}

const stopPropagationClick = (e: React.MouseEvent, callback: () => any) => {
    e.stopPropagation()
    callback()
}

export {
    preventMouseActions,
    stopPropagationClick,
}
