import { Chip } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import React from 'react'

import { COLORS, MARKER_TYPES } from '../../static/enum'
import Marker, { IMarker } from '../../store/Marker'
import { isEntityMarker, isVirtualMarker, typeToName } from '../../utils/markers.utils'
import { preventMouseActions } from '../../utils/mouse.utils'


interface Props {
    from: number
    to: number
    marker?: IMarker
    onMarkerClick?: (e: React.MouseEvent<HTMLElement>, marker?: Marker) => void
    onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const useStyles = makeStyles((theme) => ({
    interval: {
        position: 'absolute',
        top: -theme.spacing(1),
        bottom: -theme.spacing(1),
        backgroundColor: fade(theme.palette.common.white, .05),
        zIndex: 2,
        borderLeft: `1px solid ${COLORS.MARKER_COLOR}`,
        borderRight: `1px solid ${COLORS.MARKER_COLOR}`,

        '&:hover': {
            zIndex: 4,
        },
    },
    intervalContainer: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    intervalChip: {
        position: 'absolute',
        bottom: -theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%) translateY(100%)',
        backgroundColor: COLORS.DRAWER_BACKGROUND,
        opacity: .7,
    },
    intervalBreak: {
        opacity: 0.8,
        backgroundSize: '10px 10px',
        backgroundImage: `
            repeating-linear-gradient(-45deg, ${COLORS.BACKGROUND} 0, ${COLORS.BACKGROUND} 1px, transparent 0, transparent 50%)
        `,
    },
    markerCaption: {
        position: 'absolute',
        bottom: -theme.spacing(2),
        left: '50%',
        transform: 'translateX(-50%) translateY(100%)',
        opacity: .7,
    },
}))

export const PlayerInterval = (props: Props) => {
    const { from: fromTimestamp, to: toTimestamp, marker } = props
    const handleMarkerClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!props.marker || !isEntityMarker(props.marker)) {
            return
        }
        props.onMarkerClick && props.onMarkerClick(e, props.marker)
    }

    const classes = useStyles()

    const getIntervalPosition = () => {
        let newLeft: number
        let newRight: number

        if (fromTimestamp > toTimestamp) {
            newLeft = toTimestamp
            newRight = 100 - fromTimestamp
        }
        else {
            newLeft = fromTimestamp
            newRight = 100 - toTimestamp
        }

        if (newLeft < 0) {
            newLeft = 0
        }

        if (newRight < 0) {
            newRight = 0
        }

        return {
            left: `${newLeft}%`,
            right: `${newRight}%`,
        }
    }

    return (
        <div
            className={clsx({
                [classes.interval]: true,
                [classes.intervalBreak]: marker?.getType === MARKER_TYPES.PROGRAM_BREAK,
            })}
            onMouseUp={props.onMouseUp}
            style={getIntervalPosition()}
        >
            <div className={classes.intervalContainer}>
                {isEntityMarker(marker) && (
                    (marker?.getName || marker.getType) && (
                        <Chip
                            onClick={handleMarkerClick}
                            onMouseDown={preventMouseActions}
                            onMouseMove={preventMouseActions}
                            onMouseUp={preventMouseActions}
                            clickable
                            className={classes.intervalChip}
                            label={marker.getName || typeToName(marker.getType)}
                        />
                    )
                )}
                {isVirtualMarker(marker) && (
                    <div className={classes.markerCaption}>{typeToName(marker.getType)}</div>
                )}
            </div>
        </div>
    )
}
