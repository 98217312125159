export const GB = 1024 ** 3

export const UNIX_HOUR = 60 * 60

export const ON_EACH_CHAPTER = 'on-each-chapter'


export const VIEWS_CHART_COLOR_PALETTE = [
    '#07456b',
    '#0190c8',
    '#44d7b6',
    '#5b808d',
    '#01b7ff',
    '#87bd9d',
    '#c28452',
    '#6a6ebe',
]

export const MONETIZATION_CHART_COLOR_PALETTE = [
    '#019eff',
    '#006086',
    '#505cff',
    '#00ad88',
    '#71ffc4',
    '#ffa06a',
    '#d46000',
    '#be6abe',
]

/**
 * Key for the previous scroll position in local storage
 */
export const PREVIOUS_SCROLL_POSITION_KEY = 'previous-scroll-position'
