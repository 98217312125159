import {
    Box,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core'
import { PublishedStatus } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { ContentInterface } from '../../types/content'


interface Captions {
    description: string
    draft: string
    unlisted: string
    published: string
}

const CAPTIONS: { [key: string]: Captions } = {
    video: {
        description: 'Published status determines video visibility in the applications (e.g. in screens, rows, when searching). It also determines whether users can play it.',
        draft: 'Draft - viewers can\'t see or play this video.',
        unlisted: 'Unlisted - viewers can\'t see this video, but can play it if they, for example, directly paste video link in their browser.',
        published: 'Published - viewers can see and play this video.',
    },
    content: {
        description: 'Published status determines content visibility in the applications (e.g. in screens, rows, when searching).',
        draft: 'Draft - viewers can\'t see this content.',
        unlisted: 'Unlisted - viewers can\'t see this content, but can access it if they, for example, directly paste content link in their browser.',
        published: 'Published - viewers can see this content.',
    },
}

interface Props {
    content: ContentInterface
    disabled: boolean
    isVideo?: boolean
}

export const ContentPublishStatus: React.FC<Props> = observer(({ content, disabled, isVideo }) => {
    const [t] = useTranslation()
    const captions = CAPTIONS[isVideo ? 'video' : 'content']
    return (
        <Box mb={3}>
            <Typography variant="h6">{t('Published Status')}</Typography>
            <Typography
                component="p"
                color="textSecondary"
            >
                {t(captions.description)}
            </Typography>
            <div>
                <RadioGroup
                    name="visibility"
                    value={content.getPublishedStatus}
                    onChange={({ target: { value } }) => content.updatePublishedStatus(value as PublishedStatus)}
                >
                    <FormControlLabel
                        value={PublishedStatus.DRAFT}
                        control={<Radio color="primary" />}
                        label={t(captions.draft)}
                        disabled={disabled}
                    />
                    <FormControlLabel
                        value={PublishedStatus.UNLISTED}
                        control={<Radio color="primary" />}
                        label={t(captions.unlisted)}
                        disabled={disabled}
                    />
                    <FormControlLabel
                        value={PublishedStatus.PUBLISHED}
                        control={<Radio color="primary" />}
                        label={captions.published}
                        disabled={disabled}
                    />
                </RadioGroup>
            </div>
        </Box>
    )
})
