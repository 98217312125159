import { Fade, Menu, MenuItem } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { lightTheme } from '../../static/theme'
import Marker, { IMarker } from '../../store/Marker'
import { isEntityMarker } from '../../utils/markers.utils'
import { preventMouseActions } from '../../utils/mouse.utils'
import { secondsToTimeString } from '../../utils/time.utils'


interface Props {
    anchorEl: HTMLElement
    markers: IMarker[]
    onClose: () => void
    onMarkerSelect: (marker: Marker) => void
}

export const PlayerMarkerSelector = observer((props: Props) => {
    const [t] = useTranslation()

    const renderMarkers = () => {
        return props.markers.map(
            marker => (
                <MenuItem
                    key={marker.getId}
                    onClick={() => isEntityMarker(marker) ? props.onMarkerSelect(marker) : undefined}
                >
                    {marker.getName || t(marker.getType)} - {secondsToTimeString(marker.getFrom, true)}
                </MenuItem>
            ),
        )
    }

    return (
        <ThemeProvider theme={lightTheme}>
            <Menu
                open={true}
                anchorEl={props.anchorEl}
                onMouseMove={preventMouseActions}
                onMouseUp={preventMouseActions}
                onMouseDown={preventMouseActions}
                onClose={props.onClose}
                TransitionComponent={Fade}
            >
                {renderMarkers()}
            </Menu>
        </ThemeProvider>
    )
})
