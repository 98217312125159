import EuroSymbol from '@material-ui/icons/EuroSymbol'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import {
    goAdsPage,
    goMembershipsPage,
    goPromotionsPage,
    goSubscriptionsPage,
    goTransactionsPage,
} from '../../utils/history.utils'

import { MenuDropdown, MenuDropdownItem } from './MenuDropdown'


/**
 * Menu Monetization component
 */
const MenuMonetization = observer(() => {
    const [ t ] = useTranslation()
    const menuItems: MenuDropdownItem[] = [
        {
            name: t('Advertisements'),
            id: 'ads',
            action: goAdsPage,
        },
        {
            name: t('Memberships'),
            id: 'memberships',
            action: goMembershipsPage,
        },
        {
            name: t('Subscriptions'),
            id: 'subscriptions',
            action: goSubscriptionsPage,
        },
        {
            name: t('Transactions'),
            id: 'transactions',
            action: goTransactionsPage,
        },
        {
            name: t('Promotions'),
            id: 'promotions',
            action: goPromotionsPage,
        },
    ]

    return (
        <MenuDropdown
            icon={<EuroSymbol/>}
            name={t('Monetizations')}
            items={menuItems}
        />
    )
})

export default MenuMonetization
