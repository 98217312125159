import React, { FC } from 'react'

import { SkipButton } from './SkipButton'


interface Props {
    onClick?: () => any
}

export const SkipIntroButton: FC<Props> = ({ onClick }) => {
    // TIV-274 cs:Přeskočit
    return (
        <SkipButton enabled={true} onClick={onClick} text={'Skip intro'} />
    )
}
