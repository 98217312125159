import { makeAutoObservable } from 'mobx'

import type { AssetPresetDocument, TagTypeDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export class TagType implements TagTypeDocument {
    constructor(
        readonly ref: firebase.firestore.DocumentReference<TagTypeDocument>,
        private data: TagTypeDocument,
    ) {
        makeAutoObservable(this)
    }

    get id() {
        return this.ref.id
    }

    get tagTypeId() {
        return this.data.tagTypeId
    }

    get name() {
        return this.data.name
    }

    get description() {
        return this.data.description ?? ''
    }

    get color() {
        return this.data.color
    }

    get metadata() {
        return this.data.metadata ?? []
    }

    get assetPresets(): firebase.firestore.DocumentReference<AssetPresetDocument>[] {
        return this.data.assetPresets ?? []
    }

    get isGlobal() {
        return this.data.isGlobal
    }
}
