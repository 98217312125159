import { TextField } from '@material-ui/core'
import { TagMetadataFieldDefinition, TagMetadataFieldType } from '@tivio/types'
import React  from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AppSelect } from '../../AppSelect'
import { Row } from '../../uiLayout/Row'

// TODO use appSelectConverter(enumToOptions(TagMetadataFieldType)) when all types will be enabled
const TAG_METADATA_FIELD_TYPE_OPTIONS = [
    { value: TagMetadataFieldType.STRING, optionLabel: TagMetadataFieldType.STRING },
]

export type ExtendedTagMetadataFieldDefinition = TagMetadataFieldDefinition & {disabled: boolean}

interface Props {
    value: ExtendedTagMetadataFieldDefinition
    onChange: (value: ExtendedTagMetadataFieldDefinition) => void
}

export const TagMetadataFieldDefinitionField = ({ value, onChange }: Props) => {
    const [ t ] = useTranslation()

    const handleChangeKey = (event: any) => {
        onChange({ ...value, key: event.target.value })
    }

    const handleChangeType = (event: any) => {
        onChange({ ...value, type: event.target.value as TagMetadataFieldType })
    }

    return (
        <Row spacing={2}>
            <TextField
                label={t('Variable')}
                fullWidth
                variant="outlined"
                size="small"
                value={value?.key}
                onChange={handleChangeKey}
                disabled={value.disabled}
            />
            <AppSelect
                label={t('Type')}
                fullWidth
                value={value?.type}
                onChange={handleChangeType}
                options={TAG_METADATA_FIELD_TYPE_OPTIONS}
                disabled={value.disabled}
            />
        </Row>
    )
}
