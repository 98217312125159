import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'

import { LabelContainer, NodeContainer, NodeSublabel } from './NodeSharedComponents'

import type { Node } from 'reactflow'


export default memo(({ data, selected }: Node) => {
    return (
        <NodeContainer
            selected={data.selected || selected}
            width={200}
        >
            <Handle
                type="target"
                position={Position.Left}
            />
            <div>
                <LabelContainer>
                    <span>{data.label}</span>
                </LabelContainer>
                <NodeSublabel>{data.sublabel}</NodeSublabel>
                <NodeSublabel>{`${data.totalCount} / ${data.conversionRate}`}</NodeSublabel>
            </div>
            <Handle
                type="source"
                position={Position.Right}
            />
        </NodeContainer>
    )
})
