import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { round } from 'lodash'


dayjs.extend(isoWeek)


import { ChartSeriesDataPointDictionary } from '../components/analytics/monetizations/types'
import { MONETIZATION_CHART_COLOR_PALETTE } from '../static/constants'
import { ChartIntervals, ChartSeriesDataPoint } from '../types/chart'

/**
 * Method create an array of arrays intervals [startInterval,endInterval]
 * in unix timestamp. Interval will be generated like for example "last seven days"
 * independent on calendar dividing of time.
 * Array has length of numberOfPoints and intervals are in hours.
 *
 * @param endDate - Date in string (best in UTC)
 * @param period - period in days
 * @param numberOfPoints - expected number of points in chart which declare array length
 *
 * @returns {ChartIntervals} Array of arrays intervals in unix timestamp
 *
 * @example
 * [[ 1683849600, 1683860400 ], [ 1683860400, 1683871200 ],[ 1683871200, 1683882000 ], ...]
 *
 */
export const getChartIntervals = (endDate: string, period: number, numberOfPoints: number): ChartIntervals => {
    const hoursInterval = (period * 24) / numberOfPoints
    //Example [[ 1683849600, 1683860400 ], [ 1683860400, 1683871200 ],[ 1683871200, 1683882000 ], ...]
    const chartIntervals: ChartIntervals = Array(numberOfPoints)
        .fill(1)
        .map((_, index) => {
            const endPeriod = dayjs.utc(endDate).subtract(index * hoursInterval, 'hour')
            return [endPeriod.subtract(hoursInterval, 'hour').add(1, 'second').unix(), endPeriod.unix()]
        })
    return chartIntervals
}

/**
 * Method create an array of arrays intervals [startInterval,endInterval]
 * in unix timestamp. Interval will correspond to calendar week.
 * Array has length of numberOfPoints.
 *
 * @param endDate - Date in string (best in UTC)
 * @param numberOfPoints - expected number of points in chart which declare array length
 *
 * @returns {ChartIntervals} Array of arrays intervals in unix timestamp
 *
 * @example
 * [[ 1683849600, 1683860400 ], [ 1683860400, 1683871200 ],[ 1683871200, 1683882000 ], ...]
 *
 */
export const getChartCalendarWeekIntervals = (endDate: string, numberOfPoints: number): ChartIntervals => {
    //Example [[ 1683849600, 1683860400 ], [ 1683860400, 1683871200 ],[ 1683871200, 1683882000 ], ...]
    const chartIntervals: ChartIntervals = Array(numberOfPoints)
        .fill(1)
        .map((_, index) => {
            const endPeriod = dayjs.utc(endDate).endOf('isoWeek').endOf('day').subtract(1 * index, 'week')
            return [endPeriod.subtract(1, 'week').unix(), endPeriod.unix()]
        })
    return chartIntervals
}

export const getNormalizedChartData = (chartIntervals: ChartIntervals, chartData: ChartSeriesDataPointDictionary): ChartSeriesDataPoint[] => {
    return chartIntervals.map(([startDateInterval, endDateInterval]) => {
        const measurementDate = startDateInterval * 1000
        const dataPoint = chartData[measurementDate]
        return {
            x: measurementDate,
            y: dataPoint ? round(dataPoint.y, 2) : 0,
        }
    })
}


export interface GetColorPaletteByIdOptions<T> {
    data: T[]
    colorPalette?: string[]
    getKey?: (index: number, dataDetail: T) => string
}

export const getColorPaletteById = <T>(options: GetColorPaletteByIdOptions<T>) => {
    const { data, colorPalette, getKey } = options
    const colorPaletteArray = colorPalette?.length ? colorPalette : MONETIZATION_CHART_COLOR_PALETTE
    const getKeyInner = getKey ?? ((index: number, dataDetail: T) => index.toString())
    const resultPaletteDictionary: { [key: string]: string } = {}
    for (let index = 0; index < data.length; index++) {
        // data[index]?.[key as keyof T]?.toString() ?? index.toString()
        const keyValue = getKeyInner(index, data[index])
        const paletteIndex = index % colorPaletteArray.length
        resultPaletteDictionary[keyValue] = colorPaletteArray[paletteIndex]
    }
    return resultPaletteDictionary
}
