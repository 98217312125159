import { Button } from '@material-ui/core'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { ArticleItem } from '../../articles'
import { useOrganization } from '../../hooks'
import { useArticles } from '../../hooks/dataHooks/useArticles'
import { MultiTabDialog } from '../dialog/MultiTabDialog'
import { ItemSearch, ItemSearchProps } from '../ItemSearch'


const ItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`

type Props = Omit<ItemSearchProps, 'index' | 'buttonText' | 'searchIndex'> & {
    filterIds: string[];
    open: boolean;
    onClose: () => void;
};

// TODO: Remove once we have indexed contents on Algolia
export const ArticlePickDialog: React.FC<Props> = (props) => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const { open, onAdd, onClose } = props

    const { articles } = useArticles()

    return (
        <MultiTabDialog
            tabs={[
                {
                    title: t('Articles'),
                    children: <ItemsWrapper>
                        {
                            articles
                                .filter((article) => !props.filterIds.includes(article.id))
                                .map((article) => (
                                    <ArticleItem
                                        key={article.id}
                                        article={article}
                                        languages={organization?.languages}
                                        onClick={() => {
                                            onAdd(article.ref.path)
                                        }}
                                        rightComp={
                                            <Button
                                                size="small"
                                                onClick={() => {
                                                    onAdd(article.ref.path)
                                                }}
                                            >
                                                {t('Add')}
                                            </Button>
                                        }
                                    />
                                ))}
                    </ItemsWrapper>,
                },
                {
                    title: t('Videos'),
                    children: <ItemSearch
                        onAdd={(itemPath: string) => {
                            onAdd(itemPath)
                            onClose()
                        }}
                        filterType="internal"
                        buttonText={t('Add')}
                        searchIndex={ALGOLIA_INDEX_NAME.VIDEOS}
                    />,
                },
            ]}
            open={open}
            onClose={onClose}
        />
    )
}
