import {
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    IconButton,
    TextField,
    Typography,
} from '@material-ui/core'
import DialogContent from '@material-ui/core/DialogContent'
import Close from '@material-ui/icons/Close'
import { useFormik } from 'formik'
import { FormikConfig } from 'formik/dist/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'
import * as Yup from 'yup'

import { Membership } from '../../store/Membership'
import Monetization from '../../store/Monetization'
import { getInputProps } from '../../utils/formik.utils'
import { AppThemeProvider } from '../AppThemeProvider'
import { Column } from '../uiLayout/Column'


export interface MembershipDialogProps {
    // we pass null if membership is being added
    membership: Membership | null
    memberships: Membership[]
    subscriptions: Monetization[]
    onSubmit: FormikConfig<MembershipForm>['onSubmit']
    onClose: () => void
}

interface MembershipFormSubscription {
    id: string
    setMembershipRef: boolean
}

interface MembershipForm {
    name: string
    subscriptionsDiff: MembershipFormSubscription[]
}

const CloseIcon = styled(IconButton)`
    position: absolute;
    right: 1rem;
    top: 1rem;
`

const StyledDialogContent = styled(DialogContent)`
    width: 560px;
    padding: 3.5rem !important;
    position: relative;
`

const DialogTitle = styled(Typography)`
    font-weight: bold;
`

export const MembershipDialog: React.FC<MembershipDialogProps> = observer(({
    membership,
    memberships,
    subscriptions,
    onSubmit,
    onClose,
}) => {
    const updatingMembership = !!membership

    const [ t ] = useTranslation()

    const form = useFormik<MembershipForm>({
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('This field is required')),
        }),
        validateOnChange: false,
        validate: (values) => {
            const sameNameExists = memberships.some(m => {
                const sameName = m.getName === values.name
                const sameMembership = updatingMembership && m.getId === membership.getId
                return sameName && !sameMembership
            })
            if (sameNameExists) {
                return {
                    name: t('Membership with this name already exists'),
                }
            }
        },
        initialValues: {
            name: updatingMembership ? membership.getName : `Membership ${memberships.length + 1}`,
            subscriptionsDiff: [],
        },
        onSubmit,
    })

    const isChecked = (s: Monetization): boolean => {
        const diff = form.values.subscriptionsDiff.find(sd => sd.id === s.getId)
        if (diff) {
            return diff.setMembershipRef
        } else {
            return !!s.getMembershipRef && s.getMembershipRef?.id === membership?.getId
        }
    }

    const onCheck = (checked: boolean, subscriptionId: string) => {
        const diff = form.values.subscriptionsDiff.find(sd => sd.id === subscriptionId)
        if (!diff) {
            form.values.subscriptionsDiff.push({
                id: subscriptionId,
                setMembershipRef: checked,
            })
        } else {
            form.values.subscriptionsDiff = form.values.subscriptionsDiff.filter(sd => sd.id !== subscriptionId)
        }
    }

    const hasDifferentMembership = (s: Monetization): boolean => {
        return !!s.getMembershipRef && s.getMembershipRef.id !== membership?.getId
    }

    return (
        <AppThemeProvider type="light">
            <Dialog
                open={true}
                onClose={onClose}
            >
                <StyledDialogContent>
                    <CloseIcon onClick={onClose}>
                        <Close/>
                    </CloseIcon>
                    <form onSubmit={form.handleSubmit}>
                        <Column spacing={4}>
                            <DialogTitle variant="h6">
                                {updatingMembership ? t('Update Membership') : t('Add Membership')}
                            </DialogTitle>
                            <TextField
                                variant="outlined"
                                size="small"
                                fullWidth
                                label="Name"
                                {...getInputProps(form, 'name')}
                            />
                            <DialogTitle variant="h6">
                                {t('Subscriptions')}
                            </DialogTitle>
                            {subscriptions.map(subscription => (
                                <FormGroup key={subscription.getId}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                defaultChecked={isChecked(subscription)}
                                                onChange={(e) => onCheck(e.target.checked, subscription.getId)}
                                                size="small"
                                                color="primary"
                                            />
                                        }
                                        label={subscription.getName}
                                    />
                                    {hasDifferentMembership(subscription) && (
                                        <FormHelperText>
                                            {t('This subscription is assigned to a different membership')}
                                        </FormHelperText>
                                    )}
                                </FormGroup>
                            ))}
                            <Button
                                fullWidth
                                color="primary"
                                type="submit"
                                variant="contained"
                            >
                                {t('Save')}
                            </Button>
                        </Column>
                    </form>
                </StyledDialogContent>
            </Dialog>
        </AppThemeProvider>
    )
})
