import { useFormik } from 'formik'

import type { FormikErrors } from 'formik'
import type { ValidationError } from 'yup'


export type FormikForm = ReturnType<typeof useFormik>

export type FormikFieldErrors = string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined

const getError = (errors: FormikFieldErrors) => {
    let error

    if (typeof errors === 'string') {
        error = errors
    }
    if (Array.isArray(errors) && typeof errors[0] === 'string') {
        error = errors.join('; ')
    }

    return error
}

/**
 * @param form {FormikForm}
 * @param name {string}
 */
export const getInputProps = (form: any, ...name: string[]) => {
    const getValueByPath = (inputObject: any) => {
        return name.reduce(
            (previousValue, currentValue) => {
                if (!previousValue) {
                    return
                }

                return previousValue[currentValue]
            },
            inputObject,
        )
    }

    const value = getValueByPath(form.values)
    const errors = getValueByPath(form.errors)
    const error = getError(errors)

    return {
        name: name.join('.'),
        value,
        error: Boolean(errors),
        'data-e2e-input': name,
        'data-e2e-error': error,
        onChange: form.handleChange,
        title: error,
        // For material-ui input
        helperText: error,
    }
}

type FieldError = string

export type DeepRequired<T> = {
    [K in keyof T]-?: NonNullable<DeepRequired<T[K]>>;
};

type IsAny<T> = 0 extends (1 & T) ? true : false
type FieldValues = Record<string, any>

export type Merge<A, B> = {
    [K in keyof A | keyof B]?: K extends keyof A & keyof B ? [A[K], B[K]] extends [object, object] ? Merge<A[K], B[K]> : A[K] | B[K] : K extends keyof A ? A[K] : K extends keyof B ? B[K] : never;
};

export type FieldErrorsImpl<T extends FieldValues = FieldValues> = {
    [K in keyof T]?: T[K] extends object ? Merge<FieldError, FieldErrorsImpl<T[K]>> : FieldError;
};

export type FieldErrors<T extends FieldValues = FieldValues> = Partial<FieldValues extends IsAny<FieldValues> ? any : FieldErrorsImpl<DeepRequired<T>>>

// Recursive keys, all are string
export type GenericTypeToErrorType<T> = {
    [K in keyof T]: T[K] extends Record<string, unknown> ? GenericTypeToErrorType<T[K]> : string
}

/**
 * TransformYupErrorsIntoObject
 *
 * @description Transform the yup error into a useable validation object
 * @param {ValidationError} errors Yup validation errors
 * @returns {Record<string, string>} Validation errors
 */
export const transformYupErrorsIntoObject = <T>(errors: ValidationError): GenericTypeToErrorType<T> => {
    const validationErrors: GenericTypeToErrorType<T> = {} as GenericTypeToErrorType<T>

    errors.inner.forEach((error: any) => {
        if (error.path !== undefined) {
            validationErrors[error.path as keyof T] = error.message
        }
    })

    return validationErrors
}
