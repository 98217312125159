import React, { ReactNode } from 'react';


interface Props {
    /**
     * Number of repeated components
     */
    count: number

    /**
     * Components to be repeated ${count} times
     */
    children: ReactNode
}

/**
 * Repeats component's children ${count} times
 */
export const Repeater = ({ count, children }: Props) => {
    return (
        <>
            {Array.from(Array(count), () =>(
                <>
                    {children}
                </>
            ))}
        </>
    )
}
