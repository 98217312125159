import { observer } from "mobx-react"
import { TivioNotification } from "../../../../store/Notification"
import { EmailSettingsSection } from "../shared/EmailSettingsSection"
import { Column } from "../../../uiLayout/Column"
import { OutlinedInput, Typography } from "@material-ui/core"
import styled from 'styled-components'
import { useTranslation } from "react-i18next/hooks"
import { InputWrapper } from "../shared/InputWrapper"


const BoldTypography = styled(Typography)`
    font-weight: bold;
`

interface Props {
    notification: TivioNotification
}

export const SubjectPreHeader = observer(({ notification }: Props) => {
    const [t] = useTranslation()
    // TODO: add this to the store, based on notification type, we can have i.e. "monetizationName", "creatorName" (only for Stargaze mode)
    // provide description for each key
    const providedReplacementKeys = ['name']
    return <EmailSettingsSection label={t('Subject & Pre-Header')}>
        <Column spacing={1}>
            <div>
                <Typography
                    variant="body2"
                    color="textSecondary"
                >
                    {t('You can use the following replacements in the subject, pre-header and substitution fields:')}
                </Typography>
                <BoldTypography
                    variant="body2"
                    color="textSecondary"
                >
                    {providedReplacementKeys.join(', ')}
                </BoldTypography>
            </div>
            <InputWrapper label={t('Subject')}>
                <OutlinedInput
                    margin="dense"
                    name="subject"
                    value={notification.subject}
                    onChange={(e) => {
                        notification.subject = e.target.value
                    }}
                    // disabled={disabled}
                />
            </InputWrapper>
            <InputWrapper label={t('Pre-Header')}>
                <OutlinedInput
                    margin="dense"
                    name="preHeader"
                    value={notification.preHeader}
                    onChange={(e) => {
                        notification.preHeader = e.target.value
                    }}
                    // disabled={disabled}
                />
            </InputWrapper>
        </Column>
    </EmailSettingsSection>
})
