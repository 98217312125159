import { VideoType } from '@tivio/types'
import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { createVideo } from '../creator/video.creator'
import { addVideo, deleteElement } from '../firebase/firestore/element'
import Logger from '../logger'
import { alertError, alertSuccess } from '../utils/alert.utils'
import { getTranslation } from '../utils/translate.utils'
import { uploadVideoFile } from '../firebase/firestore/video'

import type { VideoInterface } from '../components/section/SectionVideoEditor'
import type Organization from './Organization'
import type Video from './Video'
import type { OrganizationDocument, VideoDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


export interface VideoUploaderInterface {
    getOrganizationRef: firebase.firestore.DocumentReference<OrganizationDocument>
    uploadVideos: (videoInterfaces: VideoInterface[], isInPlaylist?: boolean) => Promise<Video[]>
    deleteElement: (elementToDelete: Video) => Promise<void>
}


const logger = new Logger('Section')

class VideoUploader implements VideoUploaderInterface {
    private videos: (Video)[] = []

    constructor(
        private organization: Organization,
    ) {
        makeAutoObservable(this)
    }

    uploadVideo = async (videoInterface: VideoInterface) => {
        try {
            const videoData: VideoDocument = {
                name: videoInterface.name,
                defaultName: getTranslation(videoInterface.name, this.organization.languages),
                hide: false,
                organizationRef: this.organization.ref,
                description: videoInterface.description,
                duration: videoInterface.duration,
                created: videoInterface.created,
                publishedStatus: videoInterface.publishedStatus,
                type: VideoType.VIDEO,
                tags: [],
            }

            const videoRef = await addVideo(videoData) as firebase.firestore.DocumentReference<VideoDocument>
            const video = createVideo(videoRef, videoData, this.organization)

            this.organization.globalVideosPagination.addItemToStart(video, videoRef)

            videoInterface.onVideo && videoInterface.onVideo(video)

            if (videoInterface.videoFile) {
                const file = videoInterface.videoFile

                await uploadVideoFile(file, video, progress => {
                    videoInterface.onProgress && videoInterface.onProgress(videoRef.id, progress)
                })
            }

            return video
        } catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    uploadVideos = async (videoInterfaces: VideoInterface[]) => {
        try {
            const videos = await Promise.all(
                videoInterfaces.map((video) => this.uploadVideo(video)),
            )

            await this.updateVideos(this.getVideos.concat(videos))

            return videos
        } catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    async updateVideos(elements: (Video)[]) {
        const tempElements = this.getVideos

        try {
            this.setElements = elements
        } catch (e) {
            logger.error(e)
            this.setElements = tempElements
            throw new Error(e)
        }
    }

    handleDeleteElement = async (element: Video) => {
        await deleteElement(element)
    }

    // TODO: Rename element methods, must be same name as in Section class
    async deleteElement(elementToDelete: Video) {
        try {
            await this.handleDeleteElement(elementToDelete)
            await this.updateVideos(this.getVideos.filter(element => elementToDelete.id !== element.id))

            const message = i18n.t('Video deleted')

            alertSuccess(message)
        } catch (e) {
            alertError(i18n.t('Failed to delete'))
            logger.error(e)
        }
    }

    get getOrganizationRef() {
        return this.organization.ref
    }

    get getVideos() {
        return this.videos
    }

    set setElements(elements: (Video)[]) {
        this.videos = elements
    }

}

export default VideoUploader
