import { Divider, makeStyles, Tab, Tabs } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { StyledContainer } from './DefaultPageContainer'
import { Row } from './uiLayout/Row'


interface ActionButton {
    caption: string
    onClick: () => void
}

interface PageTab {
    /**
     * Tab title.
     */
    title: string
    /**
     * Tab content.
     */
    component: React.ReactNode
    /**
     * Buttons that should be rendered in the tabs area.
     */
    buttons?: React.ReactNode[]
    /**
     * If true, tab will be disabled.
     */
    disabled?: boolean
    /**
     * Tab description. Will be shown in the tab tooltip.
     */
    description?: string
}

interface DefaultPageTabsProps {
    tabs: PageTab[]
    /**
     * Defaul tab index to show when component is mounted.
     * If changed, component will be re-rendered with new
     * default tabIndex open.
     */
    defaultTabIndex?: number
    /**
     * Callback called after tab is changed. Current tab index
     * is passed as an argument.
     */
    afterChange?: (index: number) => void
    /**
     * Callback for tab change. Clicked tab is passed as an argument.
     * If undefined, internal (uncontrolled) tab change handler is used.
     */
    onChange?: (index: number) => void
    /**
     * Index of active (displayed) tab.
     * If undefined, internal (uncontrolled) tab change handler is used.
     */
    currentTabIndex?: number
    onTabChange?: (index: number) => void
}

const TabsDivider = styled(Divider)`
    margin-bottom: 2rem;
`

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'none',
        fontSize: '1.5rem',
    },
}))

/**
 * Component that creates a page with content divided into tabs. Each tab is passed as an object with tab title and tab content.
 * Tabs can be controlled by setting `onChange` and `currentTabIndex` props. Otherwise, internal (uncontrolled) tab change handler is used.
 *
 * @returns React component.
 */
const DefaultPageTabs: React.FC<DefaultPageTabsProps> = ({ tabs, defaultTabIndex = 0, afterChange, onChange, currentTabIndex, onTabChange }) => {
    // If "onChange" and "currentTabIndex" are not provided we use internal state to handle tab switching.
    const [ uncontrolledCurrentTabIndex, setUncontrolledCurrentTabIndex ] = useState<number>(defaultTabIndex)
    const classes = useStyles()
    const isControlled = Number.isInteger(currentTabIndex) && typeof onChange === 'function'
    const computedTabIndex = isControlled ? currentTabIndex! : uncontrolledCurrentTabIndex

    const handleChange = (index: number) => () => {
        if (isControlled) {
            onChange(index)
        } else {
            setUncontrolledCurrentTabIndex(index)
            if (onTabChange) {
                onTabChange(index)
            }
        }
    
        if (typeof afterChange === 'function') {
            afterChange(index)
        }
    }
    

    const renderButtons = () => {
        const currentTab = tabs[computedTabIndex]

        if (!currentTab || !currentTab.buttons) {
            return null
        }

        return (
            <Row>
                {currentTab.buttons}
            </Row>
        )
    }

    const renderTabs = () => {
        return (
            <Tabs
                value={computedTabIndex}
                TabIndicatorProps={{
                    style: {
                        backgroundColor: 'white',
                    },
                }}
            >
                {
                    tabs.map(
                        (item, index) => (
                            <Tab
                                key={`header-${index}`}
                                label={item.title}
                                onClick={handleChange(index)}
                                disabled={item.disabled}
                                title={item.description}
                                classes={{
                                    root: classes.root,
                                }}
                            />
                        ),
                    )
                }
            </Tabs>
        )
    }

    const renderTabComponent = () => {
        if (!tabs) {
            return null
        }

        const tab = tabs[computedTabIndex]

        if (!tab) {
            return null
        }

        return tab.component
    }

    // When defaultTabIndex is changed, re-render component with new tabIndex
    useEffect(() => {
        setUncontrolledCurrentTabIndex(defaultTabIndex)
    }, [ defaultTabIndex ])

    return (
        <>
            <StyledContainer maxWidth="lg">
                <Row
                    rowProps={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Row
                        spacing={2}
                        rowProps={{ alignItems: 'center' }}
                    >
                        {renderTabs()}
                    </Row>
                    <Row
                        spacing={2}
                        rowProps={{ alignItems: 'center' }}
                    >
                        {renderButtons()}
                    </Row>
                </Row>
                <TabsDivider/>
            </StyledContainer>
            {renderTabComponent()}
        </>
    )
}

export {
    DefaultPageTabs,
}

export type {
    ActionButton,
    PageTab,
}
