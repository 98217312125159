import { createLogger } from '@tivio/common'
import { useEffect, useState } from 'react'

import { createVideo } from '../../../creator/video.creator'
import { getOrganizationVideoById } from '../../../firebase/firestore/video'
import Video from '../../../store/Video'
import { useError } from '../uiHooks/useError'

import { useOrganization } from './useOrganization'


const logger = createLogger('useVideo')

const useVideo = (videoId: string) => {
    const { organization } = useOrganization()
    const [video, setVideo] = useState<Video | null>(null)
    const { error, raiseError } = useError()

    useEffect(
        () => {
            if (!organization?.ref) {
                logger.error('Organization is not defined')
                return
            }
            (async () => {
                try {
                    const { data: videoData, ref: videoRef } = (await getOrganizationVideoById(videoId, organization.ref)) || {}

                    if (!videoData || !videoRef) {
                        raiseError('video-not-found')
                        return
                    }

                    const innerVideo = createVideo(videoRef, videoData, organization)
                    setVideo(innerVideo)
                }
                catch (e) {
                    raiseError('failed-to-find-video')
                    logger.error(e)
                }
            })()
        },
        [videoId, organization, error, raiseError],
    )

    return {
        error,
        video,
    }
}

export {
    useVideo,
}
