import {
    Box,
    Container,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import SearchIcon from '@material-ui/icons/Search'
import { ALGOLIA_INDEX_NAME } from '@tivio/types'
import { observer } from 'mobx-react'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { checkCanDeleteVideo } from '../../firebase/firestore/video'
import Video from '../../store/Video'
import { useIndexedSearch } from '../hooks/dataHooks/useIndexedSearch'
import { useOrganization } from '../hooks/dataHooks/useOrganization'
import useBottomScrollListener from '../hooks/uiHooks/useBottomScrollListener'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { Column } from '../uiLayout/Column'

import { VideoItem } from './VideoItem'
import { VideoListSkeleton } from './VideoListSkeleton'


const VideosListItemContainer = styled.a`
    display: block;
    padding: .5rem;
    margin: -.5rem;
    cursor: pointer;
    border-radius: .5rem;
    transition: all .2s ease-in-out;
    text-decoration: none;
    color: inherit;

    :hover {
        background-color: rgba(255, 255, 255, 0.08);
    }
`


interface Props {
    isExternalVideos?: boolean
}

export const VideosList: React.FC<Props> = observer(({ isExternalVideos = false }) => {
    const { organization } = useOrganization()
    const { confirmAction } = useConfirmAction()
    const { push } = useHistory()
    const [t] = useTranslation()
    const [searchText, setSearchText] = React.useState(organization?.lastVideoSearch ?? '')
    const search = useIndexedSearch(ALGOLIA_INDEX_NAME.VIDEOSDESC, { limit: 20, defaultQuery: searchText }, isExternalVideos)

    const handleBottomScroll = () => {
        if (search?.pagination?.hasNextPage && !search?.isLoading) {
            search.pagination?.fetchMore()
        }
    }
    useBottomScrollListener(handleBottomScroll, { offset: 500, triggerOnNoScroll: true })

    const handleDeleteClick = async (e: React.MouseEvent, video: Video) => {
        e.preventDefault()
        e.stopPropagation()

        const canDelete = await checkCanDeleteVideo(video, true)

        if (!canDelete) {
            return
        }

        confirmAction(
            () => {
                organization?.deleteVideo(video)
                if (search.pagination) {
                    search.pagination.items = search.pagination.items.filter(filterVideo => filterVideo.id !== video.id)
                }
            },
            `${t('Are you sure you want to delete video ')} ${video.getName}?`,
        )
    }

    const handleEditClick = (evt: React.MouseEvent, videoPath: string) => {
        evt.preventDefault()
        evt.stopPropagation()

        push(videoPath)
    }

    const handleSearchChange = (evt?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchText = evt?.target?.value ?? ''
        setSearchText(searchText)
        search.search(searchText)
        if (organization) {
            organization.lastVideoSearch = searchText
        }
    }

    const handleResetSearch = () => {
        handleSearchChange()
    }

    return (
        <>
            <Container maxWidth="lg">
                <Box mb={2}>
                    <TextField
                        onChange={handleSearchChange}
                        value={searchText}
                        variant="outlined"
                        size="small"
                        fullWidth
                        InputProps={{
                            'startAdornment': (
                                <InputAdornment position="start">
                                    <SearchIcon/>
                                </InputAdornment>
                            ),
                            'endAdornment': searchText ? (
                                <InputAdornment position="end">
                                    <IconButton
                                        size="small"
                                        title={t('Clear search')}
                                        onClick={handleResetSearch}
                                    >
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        }}
                        placeholder={t('Search in videos')}
                    />
                </Box>
                <Column spacing={2}>
                    {search?.pagination?.items?.map(video => (
                        <VideosListItemContainer
                            href={video.getDocumentPath}
                            key={video.id}
                            onClick={e => handleEditClick(e, video.getDocumentPath)}
                        >
                            <VideoItem
                                video={video}
                                buttons={!isExternalVideos ? [
                                    <IconButton
                                        size="small"
                                        key={`${video.id}-edit`}
                                        onClick={e => handleEditClick(e, video.getDocumentPath)}
                                        title={t('Edit video')}
                                    >
                                        <CreateIcon fontSize="small" />
                                    </IconButton>,
                                    <IconButton
                                        size="small"
                                        key={`${video.id}-delete`}
                                        onClick={(e) => handleDeleteClick(e, video)}
                                        title={t('Delete video')}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>,
                                ] : []}
                            />
                        </VideosListItemContainer>
                    ))}
                    {search.isLoading && (
                        <>
                            <VideoListSkeleton/>
                            <VideoListSkeleton alt={true}/>
                            <VideoListSkeleton/>
                            <VideoListSkeleton alt={true}/>
                            <VideoListSkeleton/>
                            <VideoListSkeleton alt={true}/>
                        </>
                    )}
                    {search.isEmptyResult && (
                        <Box
                            mt={2}
                            textAlign="center"
                        >
                            <Typography variant="body1">{t('No videos found.')}</Typography>
                        </Box>
                    )}
                    {search.error && (
                        <Box
                            mt={2}
                            textAlign="center"
                        >
                            <Typography
                                variant="body1"
                                color="error"
                            >{search.error.message}</Typography>
                        </Box>
                    )}
                </Column>
            </Container>
            <div></div>
        </>
    )

})
