import { Button, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { TagTypeDocument } from '@tivio/firebase'
import { TagMetadataFieldDefinition, TagMetadataFieldType } from '@tivio/types'
import { FieldProps, useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import * as yup from 'yup'

import { TagType } from '../../../store/TagType'
import { getInputProps } from '../../../utils/formik.utils'
import { ColorPicker } from '../../color'
import { MultiFormikField } from '../../common/formField/MultiFormikField'
import DialogBase, { TDialogProps } from '../../dialog/DialogBase'

import { AssetPresetsAutocomplete } from './AssetPresetsAutocomplete'
import { useTagTypes } from './hooks'
import { ExtendedTagMetadataFieldDefinition, TagMetadataFieldDefinitionField } from './TagMetadataFieldDefinitionField'

import type { AssetPresetDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const useStyles = makeStyles((theme) => ({
    title: {
        textTransform: 'uppercase',
        paddingBottom: theme.spacing(2),
        fontWeight: 'bold',
    },
    paper: {
        width: 840,
    },
    dialogContent: {
        padding: `${theme.spacing(8)}px !important`,
        paddingBottom: `${theme.spacing(5)}px !important`,
    },
}))

const INITIAL_VALUES = {
    tagTypeId: '',
    name: '',
    description: '',
    color: '',
    assetPresets: new Array<firebase.firestore.DocumentReference<AssetPresetDocument>>(),
    metadata: new Array<ExtendedTagMetadataFieldDefinition>(),
}

type FormValues = typeof INITIAL_VALUES

const getFormValues = (tagTypesCount: number, tagType?: TagType): FormValues => {
    return tagType ?
        {
            tagTypeId: tagType.tagTypeId,
            name: tagType.name,
            description: tagType.description,
            color: tagType.color,
            assetPresets: tagType.assetPresets,
            metadata: tagType.metadata.map((field: TagMetadataFieldDefinition) => ({ ...field, disabled: true })),
        } : { ...INITIAL_VALUES, tagTypeId: `tagType-${tagTypesCount + 1}` }
}

interface Props extends TDialogProps<TagTypeDocument> {
    tagType?: TagType
}

/**
 * This component can be used for both Edit and Create modes.
 *
 * @param tagType edited tag type or nothing for create mode
 * @param dialogProps props for DialogBase
 */
export const EditTagTypeDialog = ({ tagType, ...dialogProps }: Props) => {
    const [ t ] = useTranslation()
    const classes = useStyles()

    const tagTypes = useTagTypes()

    useEffect(() => {
        if (!dialogProps.open) {
            form.resetForm()
        }
    }, [dialogProps.open])

    const form = useFormik({
        initialValues: getFormValues(tagTypes.length, tagType),
        validate: (values) => {
            if (tagTypes.some(
                someTagType => (
                    someTagType.tagTypeId === values.tagTypeId &&
                    someTagType.id !== tagType?.id
                ),
            )) {
                return {
                    tagId: t('Tag with this id already exists'),
                }
            }
        },
        onSubmit: (values: FormValues) => {
            const confirmedValues = {
                ...values,
                metadata: values.metadata.map(field => {
                    const { disabled, ...rest } = field
                    return rest
                }),
            }

            dialogProps.onConfirm?.(confirmedValues)
            dialogProps.onCancel?.() // TODO TIV-692 NICE TO HAVE use onClose here instead
        },
        validationSchema: yup.object().shape({
            tagTypeId: yup.string().required()
                .test(
                    'uniqueTagTypeId',
                    t('Tag type with this id already exists'),
                    (tagTypeId) => {
                        return tagTypes.every(
                            someTagType => (
                                someTagType.tagTypeId !== tagTypeId ||
                                someTagType.id === tagType?.id
                            ),
                        )
                    },
                ),
            name: yup.string().required(),
            description: yup.string(),
        }),
        enableReinitialize: true,
    })

    /**
     * dialogProps has type TDialogProps<TagTypeDocument>, so dialogProps.onConfirm is (args: TagTypeDocument) => any,
     * but DialogBase has type TDialogProps<void> and needs onConfirm: () => any.
     * DialogBase.onConfirm won't be used, because its action buttons are hidden, so we remove onConfirm from dialogProps
     * to avoid type conflict.
     */
    const getDialogBaseProps = () => {
        const { onConfirm, ...dialogBaseProps } = dialogProps
        return dialogBaseProps
    }


    return (
        <DialogBase
            {...getDialogBaseProps()}
            showCloseIcon
            hideActionsButtons
            classes={{
                paper: classes.paper,
            }}
            contentProps={{
                classes: {
                    root: classes.dialogContent,
                },
            }}
        >
            <form
                onSubmit={form.handleSubmit}
                data-e2e="edit-tag-type"
            >
                {/*TODO TIV-692 NICE TO HAVE use uiLayout/Column here*/}
                <Grid
                    container
                    direction="column"
                    spacing={2}
                >
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Details')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'tagTypeId')}
                            label={t('ID')}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'name')}
                            label={t('Name')}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            {...getInputProps(form, 'description')}
                            label={t('Description')}
                            fullWidth
                            variant="outlined"
                            size="small"
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Color')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ColorPicker
                            color={form.values.color}
                            onColorChange={(color) => form.setFieldValue('color', color)}
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Metadata')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <MultiFormikField
                            name="metadata"
                            renderSingleField={(index: number, { form, field }: FieldProps) => (
                                <TagMetadataFieldDefinitionField
                                    value={field.value}
                                    onChange={(value) => form.setFieldValue(field.name, value)}
                                />
                            )}
                            form={form}
                            defaultValue={{ key: '', type: TagMetadataFieldType.STRING }}
                            addButtonText={t('Add Variable')}
                        />
                    </Grid>
                    <Grid item>
                        <Typography
                            variant="body2"
                            className={classes.title}
                        >
                            {t('Asset presets')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <AssetPresetsAutocomplete
                            defaultValue={form.values.assetPresets}
                            onChange={(e, value) => form.setFieldValue('assetPresets', value)}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                        >
                            {t('Save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </DialogBase>
    )
}
