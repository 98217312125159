import { MARKER_TYPES } from '../static/enum'
import Marker, { IMarker } from '../store/Marker'


const DEFAULT_VOD_MARKERS = [
    MARKER_TYPES.CHAPTER,
    MARKER_TYPES.AD,
    MARKER_TYPES.START,
    MARKER_TYPES.END,
    MARKER_TYPES.INTRO,
    MARKER_TYPES.OUTRO,
    MARKER_TYPES.CUT,
    MARKER_TYPES.TRAILER,
    MARKER_TYPES.TASTING,
]

const onlyChaptersFilter = (marker: Marker) => {
    return marker.getType === MARKER_TYPES.CHAPTER
}

const noChaptersFilter = (marker: IMarker) => {
    return marker.getType !== MARKER_TYPES.CHAPTER
}

const parentChapterFilter = (chapter: Marker, from: number, to: number) => {
    if (chapter.getType !== MARKER_TYPES.CHAPTER) {
        return false
    }

    return (
        chapter.getFrom <= from &&
        chapter.getTo >= to
    )
}

const findParentChapter = (chapters: Marker[], from: number, to: number) => {
    return chapters.find(
        (chapter) => parentChapterFilter(
            chapter,
            from,
            to,
        ),
    )
}

export {
    DEFAULT_VOD_MARKERS,
    parentChapterFilter,
    onlyChaptersFilter,
    noChaptersFilter,
    findParentChapter,
}
