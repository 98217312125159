import { Box, Typography } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { Dayjs } from 'dayjs'
import { observer } from 'mobx-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { ContentInterface } from '../../types/content'
import { secondsToFirebaseTimestamp } from '../../utils/time.utils'


interface Props {
    content: ContentInterface
    disabled: boolean
}

export const ContentOtherSettings = observer(({ content, disabled }: Props) => {
    const [t] = useTranslation()

    const handleDateChange = useCallback(
        async (date: Dayjs | null) => {
            if (!date) {
                return
            }

            await content.update({
                created: secondsToFirebaseTimestamp(date.unix()),
            })

            if (content.feedVisible) {
                // calling setIsFeedVisible will update video.created
                // TODO: Remove once we have merged videos and contents
                await content.setIsFeedVisible(true)
            }
        },
        [content],
    )

    return (
        <Box>
            <Typography variant="h6">{t('Other settings')}</Typography>
            <Box mt={1}>
                <DateTimePicker
                    ampm={false}
                    value={content.createdDate}
                    onChange={handleDateChange}
                    showTodayButton
                    variant="dialog"
                    label={t('Created')}
                    inputVariant="outlined"
                    disabled={disabled}
                />
            </Box>
        </Box>
    )
})
