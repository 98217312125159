import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { alertError } from '../../../utils/alert.utils'
import { goAdsPage } from '../../../utils/history.utils'
import { AdProfileForm } from '../../adProfile'
import { useOrganization } from '../../hooks/dataHooks/useOrganization'
import { UserLayoutParams } from '../layout/UserLayout'

import type { AdProfileFirestoreForForm } from '../../../firebase/firestore/adProfile'


const AdProfileDetailPage = () => {
    const params = useParams<UserLayoutParams>()
    const { organization } = useOrganization()

    const [ isLoaded, setIsLoaded ] = useState(false)
    const [ hasError, setHasError ] = useState(false)
    const [ adProfile, setAdProfile ] = useState<AdProfileFirestoreForForm|undefined>()

    useEffect(() => {
        organization?.getAdProfile(params.adProfileId)
            .then((profile) => {
                // @ts-ignore // TODO type
                setAdProfile(profile)
            })
            .catch((error) => {
                alertError(error)
                setHasError(true)
            }).finally(() => {
                setIsLoaded(true)
            })
    }, [params.adProfileId])

    return (
        <AdProfileForm
            isLoaded={isLoaded}
            hasError={hasError}
            adProfile={adProfile}
            onSubmit={async (adProfile) => {
                await organization?.updateAdProfile(params.adProfileId, adProfile)
                goAdsPage()
            }}
        />
    )
}

export default AdProfileDetailPage
