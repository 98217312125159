import { IconButton, InputAdornment, TextField } from '@material-ui/core'
import Menu from '@material-ui/icons/Menu'
import OpenInNew from '@material-ui/icons/OpenInNew'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import { PAGES } from '../../static/enum'
import { Row } from '../uiLayout/Row'

import type { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'


const Wrapper = styled.div`
    background-color: rgba(255, 255, 255, 0.75);
    padding: 4px;
    border-radius: 4px;
`

export interface DialogScreenRowProps {
    screenId: string
    name: string
    path: string
    dragHandle?: DraggableProvidedDragHandleProps | null
    onNameChange?: (name: string) => void
}

export const DialogScreenRow = ({ name, path, dragHandle, screenId, onNameChange }: DialogScreenRowProps) => {
    const [t] = useTranslation()
    return (
        <Wrapper>
            <Row
                rowProps={{ justify: 'space-between', alignItems: 'center' }}
                spacing={1}
            >
                <Row rowProps={{ alignItems: 'center' }}>
                    <IconButton
                        {...dragHandle}
                        size="small"
                    >
                        <Menu
                            color="disabled"
                            fontSize="inherit"
                        />
                    </IconButton>
                </Row>
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={t('Screen')}
                    value={name}
                    disabled={!onNameChange}
                    onChange={(event) => onNameChange && onNameChange(event.target.value)}
                    onBlur={(event) => onNameChange && onNameChange(event.target.value.trim())}
                />
                <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={t('Path')}
                    value={path}
                    disabled
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="end"
                                component="a"
                                href={`${PAGES.SCREENS}/${screenId}`}
                                target="_blank"
                            >
                                <IconButton
                                    aria-label="open screen configuration"
                                    edge="end"
                                    size="small"
                                >
                                    <OpenInNew fontSize="inherit" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Row>
        </Wrapper>
    )
}
