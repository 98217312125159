import { Button, FormControl, TextField } from '@material-ui/core'
import { GlobalTagTypeId } from '@tivio/firebase'
import { useFormik } from 'formik'
import { observer } from 'mobx-react'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Video from '../../store/Video'
import { Header } from '../common'
import { useStore } from '../hooks/dataHooks/useStore'
import { Row } from '../uiLayout/Row'


interface Form {
    seasonNumber?: number
    episodeNumber?: number
}

interface Props {
    video: Video
}

export const VideoSeries = observer(({ video }: Props) => {
    const [t] = useTranslation()
    const { store } = useStore()

    const seriesGlobalTagType = useMemo(() => store.globalTagTypes.find((tag) => tag.tagTypeId === GlobalTagTypeId.Series), [store.globalTagTypes])
    const seriesTag = useMemo(
        () => (seriesGlobalTagType ? video.tags.find((tag) => tag.tagTypeRef?.id === seriesGlobalTagType.ref.id) : undefined),
        [video.tags, seriesGlobalTagType],
    )

    const formik = useFormik<Form>({
        initialValues: {
            seasonNumber: video.seasonNumber,
            episodeNumber: video.episodeNumber,
        },
        onSubmit: (values) => {
            if (!seriesTag) {
                return
            }
            video.setSeriesInfo(seriesTag, values.seasonNumber!, values.episodeNumber!)
        },
    })

    if (!seriesTag) {
        return null
    }

    return (
        <>
            <Header title={t('Series')} />
            <form onSubmit={formik.handleSubmit}>
                <Row
                    spacing={1}
                    rowProps={{
                        alignItems: 'center',
                    }}
                >
                    <FormControl variant="outlined">
                        <TextField
                            type="number"
                            name="seasonNumber"
                            label={t('Season #')}
                            variant="outlined"
                            inputProps={{
                                min: 1,
                            }}
                            size="small"
                            value={formik.values.seasonNumber}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <FormControl variant="outlined">
                        <TextField
                            variant="outlined"
                            name="episodeNumber"
                            size="small"
                            type="number"
                            inputProps={{
                                min: 1,
                            }}
                            label={t('Episode #')}
                            value={formik.values.episodeNumber}
                            onChange={formik.handleChange}
                        />
                    </FormControl>
                    <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        role="button"
                    >
                        {t('Save')}
                    </Button>
                </Row>
            </form>
        </>
    )
})
