import React, { useEffect } from 'react'

import { isAuthorized } from '../../../firebase/auth'
import { goAnalyticsPage, goLoginPage } from '../../../utils/history.utils'


/**
 * Root page component
 * Serves for redirecting user to page based on authorization state
 */
const RootPage = () => {

    useEffect(
        () => {
            const go = isAuthorized() ? goAnalyticsPage : goLoginPage

            go()
        },
        [],
    )

    return null
}

export default RootPage
