import { round } from '@tivio/common'


export const percentage = (partialValue: number, totalValue: number) => {
    return (partialValue / totalValue) * 100
}

export const percentToValue = (totalValue: number, percent: number) => {
    return totalValue * (percent / 100)
}

export const clampRectangle = (width: number, height: number, maxWidth: number, maxHeight: number) => {
    const containerAspectRatio = maxWidth / maxHeight
    const aspectRatio = width / height

    if (aspectRatio > containerAspectRatio) {
        return {
            width: maxWidth,
            height: maxWidth / aspectRatio,
        }
    } else {
        return {
            width: maxHeight * aspectRatio,
            height: maxHeight,
        }
    }
}

export const percentageWithDecimals = (numerator: number, denominator: number, decimalPlaces = 0) => {
    if (denominator === 0 || !isFinite(numerator / denominator)) {
        return undefined
    }
    return round((numerator / denominator) * 100, decimalPlaces)
}
