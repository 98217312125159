import { Box, Dialog, DialogContent, Typography } from '@material-ui/core'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

import { AppThemeProvider } from '../../AppThemeProvider'
import { DialogCloseButton } from '../DialogCloseButton'


type Props = {
    open: boolean
    onClose: () => void
    title: string
    contentWidth?: string
    dialogWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
}

const DialogContentStyled = styled(DialogContent) <{ width?: string }>`
    width: ${props => props.width ? props.width : '500px'};
`

/**
 * General dialog component with title and close button.
 * You can customize dialog content by passing custom children.
 */
export const GeneralDialog = (props: PropsWithChildren<Props>) => {
    const { open, onClose, title, children, contentWidth, dialogWidth } = props
    return (
        <AppThemeProvider type="light">
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth={dialogWidth}
            >
                <DialogContentStyled width={contentWidth}>
                    <DialogCloseButton
                        onClick={onClose}
                    />
                    <Box mb={2}>
                        <Typography variant="h6">{title}</Typography>
                    </Box>
                    {children}
                </DialogContentStyled>
            </Dialog>
        </AppThemeProvider>
    )
}
