import { app } from '@tivio/core-js'
import { useEffect, useMemo, useState } from 'react'

import { useSubscribeToSeriesEpisodesWithArticles } from './useSubscribeToSeriesEpisodesWithArticles'

import type { SubscribeToSeriesEpisodesOptions, SubscribeToTaggedVideosOptions, UseEpisodesQueryOptions } from '@tivio/types'

/**
 * Returns videos with given tag ids
 * @param tagIds - tag ids
 * @param options - subscription options
 */
export const useSeriesEpisodesWithArticles = (
    seriesTag: string,
    seriesRef: any,
    organizationPath: string,
    seasonNumber: number,
    options: SubscribeToSeriesEpisodesOptions = {},
) => {
    const { limit, fetchTags = true, orderBy } = options
    const [queryOptions, setQueryOptions] = useState<SubscribeToSeriesEpisodesOptions | null>(constructQueryOptions(seasonNumber, limit, fetchTags, orderBy))

    useEffect(() => {
        if (seasonNumber !== null && seriesRef) {
            setQueryOptions(constructQueryOptions(seasonNumber, limit, fetchTags, orderBy))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- orderBy is an array of objects so it needs to be stringified to not trigger a infinite loop
    }, [seasonNumber, limit, fetchTags, seriesRef, JSON.stringify(orderBy)])

    const { pagination } = useSubscribeToSeriesEpisodesWithArticles(
        seriesTag,
        seriesRef,
        organizationPath,
        queryOptions || {},
    )


    const result = useMemo(() => {
        return {
            episodes: pagination?.items ?? [],
            fetchMoreEpisodes: pagination?.fetchMore,
            episodesLoading: pagination?.loading ?? true,
            hasMoreEpisodes: pagination?.hasNextPage ?? false,
        }
    }, [pagination])

    return result
}

const constructQueryOptions = (
    seasonNumber: number,
    limit: number | undefined,
    fetchTags: boolean,
    orderBy?: UseEpisodesQueryOptions['orderBy'],
): SubscribeToTaggedVideosOptions => {
    return {
        limit,
        fetchTags,
        where: [
            { field: 'seasonNumber', operator: '==', value: seasonNumber },
        ],
        orderBy: orderBy ?? [
            { field: 'episodeNumber', directionStr: app.organization?.seriesConfiguration?.episodes?.orderByDirection ?? 'asc' },
        ],
    }
}