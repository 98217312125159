import Translator from '../services/Translator'

// Maps firebase auth codes to user-readable error messages.
// The codes could be found here:
// 1. login                 https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
// 2. registration          https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
// 3. generic api errors    https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error

// TODO some of the registration errors thrown by signUpWithTenant cloud function are not covered here (from 'firebase-admin' package).
// TODO propagate FirebaseAdminAuthErrorCode enum from cloud function here, and use it in switch case (probably through '@tivio/types')

// TODO also will be nice to use AuthErrorCodes enum from '@firebase/auth' package instead of listing error codes here by hand


// Not all of them are mapped, add new ones according to your needs.
export function mapFirebaseAuthErrorCodeToMessage(code: string) {
    switch (code) {
        // login
        case 'auth/invalid-email':
            return Translator.t('Invalid email format')
        case 'auth/user-disabled':
            return Translator.t('User has been disabled')
        case 'auth/user-not-found':
        case 'auth/wrong-password':
            return Translator.t('Invalid email and password combination')
        // registration
        case 'auth/email-already-exists':
            return Translator.t('Email is already in use')
        case 'auth/weak-password':
            return Translator.t('Password is not strong enough')
        // generic api errors
        case 'auth/too-many-requests':
            return Translator.t('Login temporarily disabled due to high amount of attempts, please try again in a minute')
        default:
            return Translator.t('Unexpected error, please try again later')
    }
}
