import { useCallback, useState } from 'react'


export const useDialogState = () => {
    const [ isDialogOpen, setIsOpen ] = useState(false)

    const openDialog = useCallback(() => { setIsOpen(true) }, [])
    const closeDialog = useCallback(() => { setIsOpen(false) }, [])
    const toggleDialog = useCallback(() => { setIsOpen(!isDialogOpen) }, [isDialogOpen])

    return {
        isDialogOpen,
        openDialog,
        closeDialog,
        toggleDialog,
    }
}
