import { PropsWithChildren, useCallback, useState } from 'react'
import styled from 'styled-components'

import { ActionButtons } from './actionButtons'

import type { Size } from './actionButtons'
import type { MouseEvent } from 'react'


interface Props extends PropsWithChildren {
    onDelete?: (event: MouseEvent) => any
    onEdit?: (event: MouseEvent) => any
    deleteTitle?: string
    editTitle?: string
    buttonSize?: Size
    className?: string
}

const ButtonContainer = styled.span<{ visible: boolean }>`
    opacity: ${props => props.visible ? '1' : '0'};
    transition: opacity 0.2s linear;
    ${props => !props.visible && 'pointer-events: none;'}
    margin-left: 5px;
`

export const Actionable: React.FC<Props> = (props) => {
    const {
        onDelete,
        onEdit,
        editTitle,
        deleteTitle,
        buttonSize,
        className,
    } = props

    const [hovering, setHovering] = useState(false)

    const setHoveringTrue = useCallback(() => {
        setHovering(true)
    }, [])

    const setHoveringFalse = useCallback(() => {
        setHovering(false)
    }, [])

    return (
        <div
            className={className}
            onMouseOver={setHoveringTrue}
            onMouseLeave={setHoveringFalse}
        >
            {props.children}
            <ButtonContainer visible={hovering}>
                <ActionButtons
                    size={buttonSize}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    editTitle={editTitle}
                    deleteTitle={deleteTitle}
                />
            </ButtonContainer>
        </div>
    )
}
