import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next/hooks'
import styled from 'styled-components'

import VideoUploader from '../../store/VideoUploader'
import { useOrganization } from '../hooks'
import SectionVideosEditor from '../section/SectionVideoEditor'


const Wrapper = styled.div`
    padding-top: 32px;
`

export const MultiUpload = () => {
    const [t] = useTranslation()
    const { organization } = useOrganization()
    const videoUploader = useMemo(() => (organization ? new VideoUploader(organization) : undefined), [organization])
    if (!videoUploader) {
        return null
    }
    return (
        <Wrapper>
            <SectionVideosEditor
                accept={['video/mp4', 'video/quicktime']}
                dropzoneCaption={t('Drag & Drop videos here')}
                dropzoneTitle={t('Upload videos')}
                uploader={videoUploader}
                videoInterfaces={[]}
            />
        </Wrapper>
    )
}
