import { Box, Grid, TextField } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'


const StyledInput = styled(TextField)`
    width: 100% & > input {
        padding: 10px;
    }
`

interface InputGroupProps {
    label: string
    name: string
    value: string
    multiline?: boolean
    error?: string
    onChange: (value: string) => void
}

export const InputGroup = ({ label, multiline, name, value, error, onChange }: InputGroupProps) => {
    return (
        <Grid
            container
            alignItems="center"
        >
            <Grid
                item
                xs={4}
            >
                <Box>{label}</Box>
            </Grid>
            <Grid
                item
                xs={8}
            >
                <StyledInput
                    fullWidth
                    variant="outlined"
                    size="small"
                    multiline={multiline}
                    name={name}
                    value={value}
                    error={!!error}
                    onChange={(e) => onChange(e.target.value)}
                />
            </Grid>
        </Grid>
    )
}