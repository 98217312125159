import { app } from '@tivio/core-js'
import { SubscribeToSeriesEpisodesOptions, UseEpisodesQueryOptions, UseEpisodesResult } from '@tivio/types'
import { useEffect, useState } from 'react'

import { useSubscribeToSeriesEpisodes } from './useSubscribeToSeriesEpisodes'


export const useSeriesEpisodes = (seriesRef: any, seasonNumber: number | null, options: UseEpisodesQueryOptions): UseEpisodesResult => {
    const [queryOptions, setQueryOptions] = useState<SubscribeToSeriesEpisodesOptions>()
    const { limit, fetchTags = true, orderBy } = options

    useEffect(() => {
        if (seasonNumber !== null && seriesRef) {
            setQueryOptions(constructQueryOptions(seasonNumber, limit, fetchTags, orderBy))
        }
    }, [seasonNumber, limit, fetchTags, seriesRef, orderBy])

    const { pagination } = useSubscribeToSeriesEpisodes(seriesRef, queryOptions)

    return {
        episodes: pagination?.items ?? [],
        fetchMoreEpisodes: pagination?.fetchMore,
        episodesLoading: pagination?.loading ?? true,
        hasMoreEpisodes: pagination?.hasNextPage ?? false,
    }
}

/**
 * Construct query filter based on provided seasonNumber and limit.
 *
 * @param seasonNumber number of series season
 * @param limit pagination limit
 */
const constructQueryOptions = (
    seasonNumber: number,
    limit: number,
    fetchTags: boolean,
    orderBy?: UseEpisodesQueryOptions['orderBy'],
): SubscribeToSeriesEpisodesOptions => {
    return {
        limit,
        fetchTags,
        where: [
            { field: 'seasonNumber', operator: '==', value: seasonNumber },
        ],
        orderBy: orderBy ?? [
            { field: 'episodeNumber', directionStr: app.organization?.seriesConfiguration?.episodes?.orderByDirection ?? 'asc' },
        ],
    }
}
