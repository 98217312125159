import {
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AdProfileAdProviderField, AdProfileType, OnlineSkippability, StreamSkippability } from '@tivio/firebase'
import { VAST_PROVIDERS } from '@tivio/types'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { alertError } from '../../utils/alert.utils'
import { ColorPicker, DEFAULT_COLOR } from '../color'
import { useIsSuperAdmin } from '../hooks/permissions/permissions'
import { useUpdatedEntity } from '../hooks/uiHooks/useUpdatedEntity'
import AdvertisementHeader from '../monetization/MonetizationPageHeader'
import UpdatableTypography from '../UpdatableTypography'

import AdsProviders from './AdsProviders'

import type { AdProfileFirestoreForForm } from '../../firebase/firestore/adProfile'
import type { Provider } from './AdsProviders'


const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(8),
    },
    divider: {
        margin: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
    },
    smallDivider: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
    typeTypography: {
        marginBottom: '22px',
    },
    behaviorContainer: {
        marginTop: '18px',
    },
    behaviorTextContainer: {
        width: '111px',
    },
    skippabilitySec: {
        marginLeft: '32px',
    },
}))

const Spinner = () => {
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item>
                <CircularProgress/>
            </Grid>
        </Grid>
    )
}

const Error = () => {
    const [ t ] = useTranslation()

    return (
        <Typography variant="h6">
            {t('Error while loading ad profile')}
        </Typography>
    )
}

const transformProviders = (profileAdProviders: AdProfileAdProviderField[], allowDevelopmentValues: boolean) => {
    const selectedProviders = profileAdProviders
        .sort((a, b) => a.priority - b.priority)
        .map((provider) => {
            const definitionOfProvider = VAST_PROVIDERS.find((definition) => definition.id === provider.adProvider.name)
            return {
                ...definitionOfProvider,
                selected: true,
            } as Provider
        })

    const notSelectedProviders = VAST_PROVIDERS
        .filter((provider) => {
            if (!allowDevelopmentValues && provider.development) {
                return false
            }

            const isSelected = selectedProviders.find((selectedProvider) => selectedProvider.id === provider.id)
            return !isSelected
        }).map((provider) => ({
            ...provider,
            selected: false,
        }))

    return [...selectedProviders, ...notSelectedProviders]
}

const getSkippabilityValue = (adProfile?: AdProfileFirestoreForForm) => {
    let onlineSkippability: any = OnlineSkippability.VAST_BASED
    let streamSkippability: any = StreamSkippability.SKIPPABLE

    if (adProfile?.type === AdProfileType.ONLINE) {
        onlineSkippability = adProfile.skippability.type
    }

    if (adProfile?.type === AdProfileType.STREAM) {
        streamSkippability = adProfile?.skippability.type
    }

    return {
        onlineSkippability,
        streamSkippability,
    }
}

const convertSkippability = (skippability: any) => (skippability?.ms) ? skippability.ms / 1000 : undefined

interface AdProfileProps {
    isLoaded?: boolean
    hasError?: boolean
    adProfile?: AdProfileFirestoreForForm
    onSubmit: (profile: AdProfileFirestoreForForm) => Promise<void>
}

export const AdProfileForm: FC<AdProfileProps> = ({
    isLoaded = true,
    hasError = false,
    adProfile,
    onSubmit,
}) => {
    const classes = useStyles()
    const [ t ] = useTranslation()
    const { updateProxy, isUpdated, resetIsUpdated } = useUpdatedEntity()

    const {
        name: initialName = t('Ad Profile'),
        description: initialDescription = t('Description'),
        color: initialColor = DEFAULT_COLOR,
        type: initialType = AdProfileType.ONLINE,
        adProviders = [],
        skippability,
    } = adProfile ?? {}
    const {
        onlineSkippability: initialOnlineSkippability,
        streamSkippability: initialStreamSkippability,
    } = getSkippabilityValue(adProfile)

    const isSuperAdmin = useIsSuperAdmin()

    const [ name, setName ] = useState(initialName)
    const [ description, setDescription ] = useState(initialDescription)
    const [ color, setColor ] = useState(initialColor)
    const [ type, setType ] = useState(initialType)
    const [ providers, setProviders ] = useState<Provider[]>(() => transformProviders(adProviders, isSuperAdmin))
    const [ onlineSkippability, setOnlineSkippability ] = useState(initialOnlineSkippability)
    const [ streamSkippability, setStreamSkippability ] = useState(initialStreamSkippability)
    const [ skippabilitySec, setSkippabilitySec ] = useState(() => convertSkippability(skippability))

    const adProvidersShown = type !== AdProfileType.STREAM

    useEffect(() => {
        if (adProfile) {
            const {
                name: changedName,
                description: changedDescription,
                color: changedColor,
                type: changedType,
                adProviders,
                skippability,
            } = adProfile
            const {
                onlineSkippability: changedOnlineSkippability,
                streamSkippability: chnagedStreamSkippability,
            } = getSkippabilityValue(adProfile)

            setName(changedName)
            setDescription(changedDescription)
            setColor(changedColor)
            setType(changedType)
            setProviders(transformProviders(adProviders ?? [], isSuperAdmin))
            setOnlineSkippability(changedOnlineSkippability)
            setStreamSkippability(chnagedStreamSkippability)
            setSkippabilitySec(convertSkippability(skippability))
        }
    }, [adProfile])

    const handleUpdateSkippabilitySec = (updatedSkippabilitSec: string) => {
        const parsed = parseInt(updatedSkippabilitSec)
        setSkippabilitySec((!isNaN(parsed)) ? parsed : undefined)
    }

    const handleCreateProfile = async () => {
        let skippabilityType = null
        if (type === AdProfileType.ONLINE) {
            skippabilityType = onlineSkippability
        }
        if (type === AdProfileType.STREAM) {
            skippabilityType = streamSkippability
        }

        const selectedAdProviders = providers.filter((provider) => provider.selected)
        if (adProvidersShown && !selectedAdProviders.length) {
            alertError(t('Select at least one ad provider'))
        } else {
            await onSubmit({
                name: name,
                description: description,
                color: color,
                type: type,
                adProviders: selectedAdProviders.map((provider, index) => ({
                    adProvider: {
                        name: provider.id,
                    },
                    priority: index + 1,
                })),
                skippability: {
                    type: skippabilityType as any,
                    ms: (skippabilitySec) ? skippabilitySec * 1000 : undefined,
                },
            })

            resetIsUpdated()
        }
    }

    return (
        <>
            <AdvertisementHeader
                disabled={!isUpdated}
                onSave={handleCreateProfile}
                title={name}
            />
            <Container
                maxWidth="lg"
                className={classes.container}
            >
                {(hasError) && (
                    <Error/>
                )}
                {(!hasError && !isLoaded) && (
                    <Spinner/>
                )}
                {(!hasError && isLoaded) && (
                    <Grid
                        container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item>
                            <UpdatableTypography
                                onTextChange={(name) => updateProxy(() => setName(name))}
                                typographyProps={{
                                    variant: 'h5',
                                }}
                                editOnClick
                            >
                                {name}
                            </UpdatableTypography>
                        </Grid>
                        <Grid item>
                            <UpdatableTypography
                                onTextChange={(name) => updateProxy(() => setDescription(name))}
                                typographyProps={{
                                    variant: 'body2',
                                }}
                                editOnClick
                            >
                                {description}
                            </UpdatableTypography>
                        </Grid>
                        <Grid item>
                            <ColorPicker
                                color={color}
                                onColorChange={(color) => updateProxy(() => setColor(color))}
                            />
                        </Grid>
                        <Divider className={classes.smallDivider}/>
                        <Grid item>
                            <Typography
                                variant="h6"
                                className={classes.typeTypography}
                            >
                                {t('Type')}
                            </Typography>
                            <FormControl
                                size="small"
                                variant="outlined"
                            >
                                <Select
                                    value={type}
                                    onChange={(e) => updateProxy(() => setType(e.target.value as AdProfileType))}
                                >
                                    <MenuItem value={AdProfileType.ONLINE}>Online</MenuItem>
                                    <MenuItem value={AdProfileType.STREAM}>Stream</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Divider className={classes.divider}/>
                        <Grid item>
                            <Typography variant="h6">
                                {t('Behavior')}
                            </Typography>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                className={classes.behaviorContainer}
                            >
                                <Grid item>
                                    {(type === AdProfileType.ONLINE) && (
                                        <FormControl
                                            size="small"
                                            variant="outlined"
                                        >
                                            <Select
                                                value={onlineSkippability}
                                                onChange={(e) => updateProxy(() => setOnlineSkippability(e.target.value as OnlineSkippability))}
                                            >
                                                <MenuItem value={OnlineSkippability.VAST_BASED}>{t('Vast Based')}</MenuItem>
                                                <MenuItem value={OnlineSkippability.NOT_SKIPPABLE}>{t('Not Skippable')}</MenuItem>
                                                <MenuItem value={OnlineSkippability.SKIPPABLE}>{t('Skippable')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                    {(type === AdProfileType.STREAM) && (
                                        <FormControl
                                            size="small"
                                            variant="outlined"
                                        >
                                            <Select
                                                value={streamSkippability}
                                                onChange={(e) => updateProxy(() => setStreamSkippability(e.target.value as StreamSkippability))}
                                            >
                                                <MenuItem value={StreamSkippability.AUTO_SKIP}>{t('Auto Skip')}</MenuItem>
                                                <MenuItem value={StreamSkippability.NOT_SKIPPABLE}>{t('Not Skippable')}</MenuItem>
                                                <MenuItem value={StreamSkippability.SKIPPABLE}>{t('Skippable')}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    )}
                                </Grid>
                                {(
                                    (type === AdProfileType.ONLINE && onlineSkippability === OnlineSkippability.SKIPPABLE)
                                    || (type === AdProfileType.STREAM && streamSkippability === StreamSkippability.SKIPPABLE)) && (
                                    <Grid
                                        item
                                        className={classes.skippabilitySec}
                                    >
                                        <TextField
                                            label={t('Skippable after sec')}
                                            fullWidth
                                            variant="outlined"
                                            onChange={(e) => updateProxy(() => handleUpdateSkippabilitySec(e.target.value))}
                                            value={skippabilitySec}
                                            size="small"
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {adProvidersShown && (
                            <>
                                <Divider className={classes.divider}/>
                                <Grid item>
                                    <AdsProviders
                                        providers={providers}
                                        onEdit={(providers) => updateProxy(() => setProviders(providers))}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
            </Container>
        </>
    )
}
