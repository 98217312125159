import { Error as AuthError } from '@firebase/auth-types'
import { useFormik } from 'formik'
import { useCallback, useState } from 'react'
import * as Yup from 'yup'

import Translator from '../../../services/Translator'
import { mapFirebaseAuthErrorCodeToMessage } from '../../../utils/auth.utils'
import { useAuth } from '../useAuth'


type RegistrationForm = {
    name: string,
    email: string,
    password: string
    confirmPassword: string
}

type Props = {
    onSubmit: (values: RegistrationForm) => void
    onSuccess: () => void
    onError: () => void
}

const validationSchema = Yup.object()
    .shape({
        name: Yup.string().required(Translator.t('Name required')),
        email: Yup.string().required(Translator.t('Email required')).email(Translator.t('Enter valid email')),
        password: Yup.string().min(6, Translator.t('Password is not strong enough')).required(Translator.t('Password required')),
        confirmPassword: Yup.string()
            .oneOf([ Yup.ref('password'), null ], Translator.t('Passwords must match'))
            .required(Translator.t('Password confirmation is required')),
    })

const useRegistrationForm = ({ onError, onSuccess, onSubmit }: Partial<Props> = {}) => {
    const { createUserWithEmailAndPassword } = useAuth()
    const [ loading, setLoading ] = useState(false)
    const [ registrationError, setRegistrationError ] = useState<string | null>(null)
    const { values, errors, touched, handleChange, handleSubmit, handleBlur } = useFormik<RegistrationForm>({
        initialValues: {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        onSubmit: async (values) => {
            onSubmit?.(values)
            setLoading(true)

            try {
                await createUserWithEmailAndPassword(values.email, values.password, values.name)
                onSuccess?.()
            }
            catch (e) {
                onError?.()
                if (e.code) {
                    const firebaseAuthError = e as AuthError
                    const errorMessage = mapFirebaseAuthErrorCodeToMessage(firebaseAuthError.code)
                    setRegistrationError(errorMessage)
                }
            }
            finally {
                setLoading(false)
            }
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema,
    })

    const handleRegister = useCallback(async () => {
        await handleSubmit()
    }, [ handleSubmit ])

    return {
        handleBlur,
        registrationError,
        errors,
        values,
        touched,
        handleChange,
        loading,
        handleRegister,
    }
}

export {
    useRegistrationForm,
}
