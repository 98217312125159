import Channel from '../store/Channel'
import Section from '../store/Section'
import Video from '../store/Video'


const getChannelPath = (channel: Channel) => {
    return {
        organizationId: channel.getOrganization.id,
        channelId: channel.getId,
    }
}

const getSectionPath = (section: Section) => {
    return {
        organizationId: section.getChannel.getOrganization.id,
        channelId: section.getChannel.getId,
        sectionId: section.getId,
    }
}

const getElementPath = (element: Video) => {
    return {
        organizationId: element.section!.getChannel.getOrganization.id,
        channelId: element.section!.getChannel.getId,
        sectionId: element.section!.getId,
        elementId: element.getId,
    }
}

export {
    getChannelPath,
    getSectionPath,
    getElementPath,
}
