import { getRandomItem, truncateText } from '@tivio/common'
import { CampaignStatus } from '@tivio/firebase'
import { VideoType } from '@tivio/types'
import dayjs from 'dayjs'

import { firebaseTimestampFromDayjs, getFirestore } from '../../../firebase/app'
import { createTvProgram, removeTvProgram, updateEpgAfterNewProgramCreating } from '../../../firebase/firestore/tvProgram'
import { TvProgram } from '../../../store/TvProgram'
import Video from '../../../store/Video'
import { alertError, alertSuccess } from '../../../utils/alert.utils'
import { getCurrentOrganizationId } from '../../../utils/organization.utils'

import { useOrganization } from './useOrganization'

import type Monetization from '../../../store/Monetization'
import type {
    CampaignDocument,
    DocumentReference,
    DocumentSnapshot,
    OrganizationDocument,
    QueryDocumentSnapshot,
    QuerySnapshot,
    TvChannelDocument,
    TvProgramDocument,
    VideoDocument,
} from '@tivio/firebase'
import type { Dayjs } from 'dayjs'


const ORGANIZATION_ID_DVTV = '7Nl2DB1JRUfXMpSEpVK6'
const ORGANIZATION_ID_KVIFF = 'Yxs3iHjXmedRJXKyGCvV'
const ORGANIZATION_ID_TIVIO = 'x720GbG3Jys0ZEdLYr2j'

const DVTV_EXTRA_CHANNEL_ID = 'IxLi19VRasgu94bIaXbH'

// TODO: take those from profiles collection
const dvtvProfile = '5YqvGgm6RRFgjxDMvAyW'

const GRACE_PERIOD = 300000 // 5 minutes in ms

// TODO: hack, this isn't sustainable solution for all organizations. mb define new video type?
// NOTE: This is for backwards compatiblity, once DVTV's jingle tag is switched to the jingle tag type
// this becomes obsolute
const JINGLE_TAG_REF = getFirestore().doc(process.env.REACT_APP_JINGLE_TAG_PATH!)

async function getJingleVideo() {
    const currentOrganizationId = getCurrentOrganizationId()

    if (currentOrganizationId === null) {
        return undefined
    }

    const jingleTagRef = await getFirestore().doc('/tags/GeGdbbnwlVdQuO55hPFD')
    const currentOrganizationRef = await getFirestore().doc(`/organizations/${currentOrganizationId}`)

    if (jingleTagRef !== undefined) {
        const jingleVideos = await getFirestore()
            .collection('videos')
            .where('organizationRef', '==', currentOrganizationRef)
            .where('tags', 'array-contains', jingleTagRef)
            .get() as QuerySnapshot<VideoDocument>
    
        const randomJingleVideo = getRandomItem(jingleVideos.docs)
    
        if (!randomJingleVideo) {
            return undefined
        }
    
        const randomJingleVideoDuration = randomJingleVideo.data().duration
    
        if (!randomJingleVideoDuration) {
            console.warn(`Jingle video ${randomJingleVideo.ref.path} has undefined duration, it will not be added to epg`)
            return undefined
        }
    
        return {
            jingleRef: randomJingleVideo.ref,
            jingleDuration: randomJingleVideoDuration,
        }
    }

    return undefined
}

function isInt(n: number) {
    return n % 1 === 0
}

const parseDuration = (duration?: number) => {
    if (!duration) {
        return null
    }
    if (isInt(duration)) {
        return duration
    } else {
        return duration * 1000
    }
}

// Backwards compatiblity for DVTV
async function getDvtvJingleVideo() {
    if (getCurrentOrganizationId() !== JINGLE_TAG_REF.parent.parent!.id) {
        return undefined
    }

    const jingleVideos = await getFirestore()
        .collection('videos')
        .where('tags', 'array-contains', JINGLE_TAG_REF)
        .get() as QuerySnapshot<VideoDocument>

    const randomJingleVideo = getRandomItem(jingleVideos.docs)

    if (!randomJingleVideo) {
        return undefined
    }

    const randomJingle = randomJingleVideo.data()

    const randomJingleVideoDuration = parseDuration(randomJingle?.profiles?.[dvtvProfile]?.duration) ?? randomJingle.duration

    if (!randomJingleVideoDuration) {
        console.warn(`Jingle video ${randomJingleVideo.ref.path} has undefined duration, it will not be added to epg`)
        return undefined
    }

    return {
        jingleRef: randomJingleVideo.ref,
        jingleDuration: randomJingleVideoDuration,
    }
}

export const getTvProgramCampaigns = async (
    organizationRef: DocumentReference<OrganizationDocument>,
    tvChannelRef: DocumentReference<TvChannelDocument>,
) => {
    const now = dayjs()
    const campaignDocuments = await getFirestore()
        .collection('campaigns')
        .where('status', '==', CampaignStatus.IN_PROGRESS)
        .where('organizationRef', '==', organizationRef)
        .get() as QuerySnapshot<CampaignDocument>

    const filteredCampaigns: QueryDocumentSnapshot<CampaignDocument>[] = []

    campaignDocuments.forEach(campaignDocument => {

        if (!campaignDocument.exists) {
            return
        }

        campaignDocument.data().targetings.forEach(targetingGroup => {
            let groupTargetingMatches = 0

            const targetingGroupValues = Object.values(targetingGroup)

            targetingGroupValues.forEach(targetingGroupValue => {
                switch (targetingGroupValue.type) {
                    case 'ORGANIZATION':
                        if (targetingGroupValue.ref.id === organizationRef.id) {
                            groupTargetingMatches++
                        }
                        break
                    case 'TV_CHANNEL':
                        if (targetingGroupValue.ref.id === tvChannelRef.id) {
                            groupTargetingMatches++
                        }
                        break
                }
            })

            if (groupTargetingMatches === targetingGroupValues.length) {
                filteredCampaigns.push(campaignDocument)
            }
        })
    })

    return filteredCampaigns.filter((c) => dayjs(c.data().from.toDate()).isBefore(now) && dayjs(c.data().to.toDate()).isAfter(now))
}

async function getTeaserForTasting(tastingPath: string, tastingData: VideoDocument) {
    if (tastingData.type !== VideoType.TASTING) {
        return undefined
    }

    if (!tastingData.originalVideoRef) {
        console.warn(`Video ${tastingPath} is of type tasting, but has no originalVideoRef, cannot check for potential teaser`)
        return undefined
    }

    const parentVideo = await tastingData.originalVideoRef.get()
    const parentVideoData = parentVideo.data()

    if (!parentVideoData) {
        console.warn(`Video ${tastingPath} originalVideoRef points to non-existing / deleted document, cannot check for potential teaser`)
        return undefined
    }

    const teaserLink = parentVideoData.linkedVideos?.find(
        linkedVideo => linkedVideo.type === 'TEASER',
    )

    if (!teaserLink) {
        return undefined
    }

    const teaser = await teaserLink.videoRef.get()

    const teaserData = teaser.data()

    if (!teaserData) {
        console.warn(
            `Encountered ref ${teaserLink.videoRef.path} in linked videos of document ${parentVideo.ref.path},`
            + ' that points to non-existing / deleted document. Teaser will not be added to epg schedule.',
        )
        return undefined
    }

    if (!teaserData.duration) {
        console.warn(`Teaser ${teaser.ref.path} has undefined duration, it will not be added to epg`)
        return undefined
    }

    return {
        teaserRef: teaser.ref,
        teaserDuration: teaserData.duration,
    }
}

const getRandomAdVideo = async () => {
    const currentOrganizationId = getCurrentOrganizationId()

    if (currentOrganizationId === null) {
        return undefined
    }

    const adTagRef = await getFirestore().doc('/tags/0w1SrXwW3fu6AjErQQNR')
    const currentOrganizationRef = await getFirestore().doc(`/organizations/${currentOrganizationId}`)

    if (adTagRef) {
        const adVideos = await getFirestore()
            .collection('videos')
            .where('organizationRef', '==', currentOrganizationRef)
            .where('tags', 'array-contains', adTagRef)
            .get() as QuerySnapshot<VideoDocument>
    
        const randomAdVideo = getRandomItem(adVideos.docs)
    
        if (!randomAdVideo) {
            return undefined
        }
    
        const randomAdVideoDuration = randomAdVideo.data().duration
    
        if (!randomAdVideoDuration) {
            console.warn(`Ad video ${randomAdVideo.ref.path} has undefined duration, it will not be added to epg`)
            return undefined
        }
    
        return {
            adRef: randomAdVideo.ref,
            adDuration: randomAdVideoDuration,
        }
    }

    return undefined
}

const getAdditionalVideosKviff = async () => {
    const { jingleRef, jingleDuration } = await getJingleVideo() ?? { jingleDuration: 0 }
    const { adRef, adDuration } = await getRandomAdVideo() ?? { adDuration: 0 }

    return {
        additionalVideoRefs: [
            ...(jingleRef ? [jingleRef] : []),
            ...(adRef ? [adRef] : []),
            ...(jingleRef ? [jingleRef] : []),
        ],
        additionalDuration: (jingleDuration * 2) + adDuration,
    }
}

const getAdditionalVideosDvtv = async (videoPath: string, videoData: VideoDocument) => {
    const currentOrganizationId = getCurrentOrganizationId()
    const organizationRef = await getFirestore().doc(`/organizations/${currentOrganizationId}`).get() as DocumentSnapshot<OrganizationDocument>
    const tvChannelRef = await getFirestore().doc(`/tvChannels/${DVTV_EXTRA_CHANNEL_ID}`).get() as DocumentSnapshot<TvChannelDocument>

    const { jingleRef, jingleDuration } = await getDvtvJingleVideo() ?? { jingleDuration: 0 }
    const { teaserRef, teaserDuration } = await getTeaserForTasting(videoPath, videoData) ?? { teaserDuration: 0 }

    const campaigns = await getTvProgramCampaigns(
        organizationRef.ref,
        tvChannelRef.ref,
    )

    const campaignsClips: DocumentSnapshot<VideoDocument>[] = []

    for await (const campaign of campaigns) {
        const campaignData = campaign.data()
        if (campaignData) {
            for await (const contentRef of campaignData.contentRefs) {
                const content = await contentRef.get()
                if (content.exists) {
                    campaignsClips.push(content)
                }
            }
        }
    }   

    const campaignsClipsTotalDuration = campaignsClips.reduce((acc, curr) => {
        const videoData = curr.data() as VideoDocument
        if (videoData) {
            const profileDuration = (videoData.profiles?.[dvtvProfile]?.duration || 0) * 1000
            const videoDuration = videoData?.duration
            return acc + (profileDuration || videoDuration || 0)
        } 
        return acc
    }, 0)

    const mainVideoDuration = (videoData?.profiles?.[dvtvProfile]?.duration || 0) * 1000 || videoData?.duration

    const channelMonetizationSnapshot = await getFirestore().collection('monetizations')
        .where('placements.tvChannelRefs', 'array-contains', tvChannelRef.ref)
        .limit(1)
        .get() as QuerySnapshot<Monetization>

    const channelMonetization = channelMonetizationSnapshot.docs.at(0)?.data()
    // @ts-expect-error
    const minWithoutAdMs = channelMonetization?.variants?.find((variant) => variant?.strategies?.midRoll).strategies.midRoll.minWithoutAdMs
    // @ts-expect-error
    const preroll = channelMonetization?.variants?.some((variant) => !!variant?.strategies?.preRoll)

    return {
        additionalVideoRefs: [
            ...(teaserRef ? [teaserRef] : []),
            ...(jingleRef ? [jingleRef] : []),
        ],
        additionalDuration:
            teaserDuration +
            (preroll ? jingleDuration + campaignsClipsTotalDuration + jingleDuration : 0) + // preroll
            (mainVideoDuration ?
                jingleDuration + campaignsClipsTotalDuration * parseInt(String((mainVideoDuration - GRACE_PERIOD) / minWithoutAdMs)) + jingleDuration
                :
                jingleDuration + campaignsClipsTotalDuration + jingleDuration
            ),
    }
}

const getAdditionalVideos = (videoPath: string, videoData: VideoDocument) => {
    const currentOrganizationId = getCurrentOrganizationId()

    switch (currentOrganizationId) {
        case ORGANIZATION_ID_DVTV:
            return getAdditionalVideosDvtv(videoPath, videoData)
        case ORGANIZATION_ID_KVIFF:
        case ORGANIZATION_ID_TIVIO:
            return getAdditionalVideosKviff()
        default:
            return null
    }
}

/**
 * CRUD Actions for EPG schedule.
 */
export const useEpgActions = () => {
    const { organization } = useOrganization()

    const addVideoToEpg = async (videoPath: string, from: Dayjs, tvChannelId: string, overlappedItem?: TvProgram) => {
        if (!organization) {
            throw new Error('Organization is not loaded')
        }
        const videoDoc = await getFirestore().doc(videoPath).get() as DocumentSnapshot<VideoDocument>
        const videoData = videoDoc.data()

        if (!videoData) {
            throw new Error(`Unexpected error. Video ${videoPath} should exist, because it was chosen from search result, but does not.`)
        }

        const video = new Video(videoDoc.ref, videoData, organization)
        const tvChannelRef = getFirestore().doc(`tvChannels/${tvChannelId}`) as DocumentReference<TvChannelDocument>

        const duration = await video.getVideoProfileDuration(tvChannelRef)

        if (!duration) {
            throw new Error(`Video ${videoPath} has undefined duration, cannot add it to epg.`)
        }

        const originalVideoRef = videoData.originalVideoRef

        const { additionalVideoRefs, additionalDuration } = await getAdditionalVideos(
            videoPath,
            videoData,
        ) ?? { additionalDuration: 0 }

        const tvProgramData: TvProgramDocument = {
            videoRef: videoDoc.ref,
            ...(originalVideoRef && { originalVideoRef }),
            tvChannelRef,
            organizationRef: organization?.ref,
            from: firebaseTimestampFromDayjs(from),
            to: firebaseTimestampFromDayjs(from.add(duration + additionalDuration, 'ms')),
            ...(additionalVideoRefs?.length && { additionalVideoRefs }),
        }

        const newProgramRef = await createTvProgram(tvProgramData)

        await updateEpgAfterNewProgramCreating(
            new TvProgram(newProgramRef, tvProgramData, organization),
            overlappedItem,
        )
    }

    const deleteItemFromEpg = async (item: TvProgram, epgLockDurationInMinutes?: number) => {
        try {
            if (epgLockDurationInMinutes && item.to.isBefore(dayjs().startOf('day').add(epgLockDurationInMinutes, 'minutes'))) {
                return alertError(`Cannot remove tv program that starts in less than ${epgLockDurationInMinutes / 60 / 24} days`)
            }

            await removeTvProgram(item.id)
            alertSuccess(`Tv program ${truncateText(item.video?.getName ?? '', 20)} was deleted`)
        } catch {
            alertError('Failed to remove tv program')
        }
    }

    return {
        addVideoToEpg,
        deleteItemFromEpg,
    }
}
