import i18n from 'i18next'
import { makeAutoObservable } from 'mobx'

import { updateWidget } from '../firebase/firestore/widget'
import Logger from '../logger'
import { alertError } from '../utils/alert.utils'
import { goWidgetPage } from '../utils/history.utils'



import type { WidgetFirestore } from '../firebase/firestore/widget'
import type Channel from './Channel'
import type Organization from './Organization'
import type { ChannelDocument } from '@tivio/firebase'
import type firebase from 'firebase/app'


const logger = new Logger('Widget')

class Widget {
    private readonly ref: firebase.firestore.DocumentReference<WidgetFirestore>
    private firestoreData: WidgetFirestore
    private readonly organization: Organization
    private channels: Channel[] = []

    constructor(
        ref: firebase.firestore.DocumentReference<WidgetFirestore>,
        firestoreData: WidgetFirestore,
        organization: Organization,
        // todo asi channels
    ) {
        this.ref = ref
        this.firestoreData = firestoreData
        this.organization = organization

        makeAutoObservable(this)
    }

    get getRef() {
        return this.ref
    }

    get getId() {
        return this.ref.id
    }

    get getWidgetId() {
        return this.firestoreData.widgetId
    }

    get getName() {
        return this.firestoreData.name
    }

    get getOrganization() {
        return this.organization
    }

    get getChannels() {
        return this.channels
    }

    get getAutoChannels() {
        return this.firestoreData.autoChannels
    }

    get getEnabled() {
        return this.firestoreData.enabled
    }

    get getChannelsOrder() {
        return this.firestoreData.channelsOrder || []
    }

    // todo
    goWidgetPage = () => {
        goWidgetPage(this.getId)
    }

    updateName = async (name: string) => {
        const temp = this.getName

        try {
            this.setName = name

            await updateWidget(this, { name })

            this.getOrganization.widgets = this.getOrganization.widgets.sort(
                (a, b) => a.getName.localeCompare(b.getName),
            )
        }
        catch (e) {
            this.setName = temp
            alertError(i18n.t('Failed to update channel name'))
            logger.error(e)
        }
    }

    updateChannelsOrder = async (channels: Channel[]) => {
        try {
            const channelsRefs = channels.map(channel => channel.getRef)

            await updateWidget(this, { channelsOrder: channelsRefs })

            this.setChannelsOrder = channelsRefs
            this.setChannels = channels
        }
        catch (e) {
            logger.error(e)
            throw new Error(e)
        }
    }

    updateId = async (widgetId: string) => {
        try {
            await updateWidget(this, { widgetId })

            this.setWidgetId = widgetId
        }
        catch (e) {
            alertError(i18n.t('Failed to update a widget ID'))
            logger.error(e)
        }
    }

    updateAutoChannels = async (autoChannels: boolean) => {
        try {
            await updateWidget(this, { autoChannels })

            this.setAutoChannels = autoChannels
        }
        catch (e) {
            alertError(i18n.t('Failed to update a widget Auto Channels attribute'))
            logger.error(e)
        }
    }

    updateEnabled = async (enabled: boolean) => {
        try {
            await updateWidget(this, { enabled })

            this.setEnabled = enabled
        }
        catch (e) {
            alertError(i18n.t('Failed to update a widget Enabled attribute'))
            logger.error(e)
        }
    }

    set setName(name: string) {
        this.firestoreData.name = name
    }

    set setWidgetId(widgetId: string) {
        this.firestoreData.widgetId = widgetId
    }

    set setAutoChannels(autoChannels: boolean) {
        this.firestoreData.autoChannels = autoChannels
    }

    set setEnabled(enabled: boolean) {
        this.firestoreData.enabled = enabled
    }

    set setChannels(channels: Channel[]) {
        this.channels = channels
    }

    set setChannelsOrder(channelsOrder: firebase.firestore.DocumentReference<ChannelDocument>[]) {
        this.firestoreData.channelsOrder = channelsOrder
    }
}

export default Widget
