import { useEffect } from 'react'
import { useTimer } from 'react-timer-hook'


interface Props {
    expiryDate?: Date
}

/**
 * Returns how much time is left until given expiryDate in (days, hours, minutes, seconds) units.
 * @param expiryDate - js Date object when countdown should be considered expired (all time units will be 0, isOver will be true).
 *                     if undefined is passed, countdown is considered expired
 */
export function useCountdown({
    expiryDate,
}: Props) {
    const { days, hours, minutes, seconds, restart } = useTimer({
        // In reality new Date() fallback will not be used, we pass it so useTimer doesn't write warnings
        expiryTimestamp: expiryDate ?? new Date(),
        autoStart: false,
    })

    // useTimer hook does not react to changing expiryTimestamp,
    // so we need to restart it "manually" here on expiryTimestamp change
    useEffect(() => {
        if (expiryDate) {
            restart(expiryDate)
        }
        // restart function changes on every call for some reason, we cannot add it here
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ expiryDate ])

    return {
        days,
        hours,
        minutes,
        seconds,
        isOver: days === 0 && hours === 0 && minutes === 0 && seconds === 0,
    }
}
