import { GetVideoAnalyticsResult } from '@tivio/types'
import dayjs from 'dayjs'
import React, { memo } from 'react'

import { COLORS } from '../../../static/enum'
import { Chart } from '../../externalComponents/Chart'

import { toChartData } from './util'


const BAR_COLOR_PALETTE = [
    '#17BCFA',
    '#0092cd',
    '#006493',
    '#00466e',
    '#838ca7',
]

const EMPTY_BAR_COLOR = '#242b43'

interface Props {
    metrics?: GetVideoAnalyticsResult
}

export const VideoAnalyticsChart = memo(({ metrics }: Props) => {
    const hasData = Boolean(metrics && Object.keys(metrics).length)
    const data = toChartData(metrics)

    return (
        <Chart
            type="bar"
            series={data?.series ?? []}
            options={{
                selection: { enabled: false },
                zoom: { enabled: false },
                chart: {
                    toolbar: {
                        show: false,
                        offsetX: 0,
                        offsetY: 0,
                        tools: {
                            download: false,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true,
                        },
                    },
                    selection: { enabled: hasData },
                    type: 'bar',
                    stacked: true,
                    stackType: '100%',
                    width: '100%',
                },
                grid: {
                    show: false,
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        // For unknown reasons, there is still a padding on the left
                        left: -10,
                    },
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    show: false,
                },
                plotOptions: {
                    bar: {
                        distributed: false,
                        horizontal: false,
                        colors: {
                            backgroundBarColors: [EMPTY_BAR_COLOR],
                        },
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: data?.categories ?? [],
                    axisTicks: { show: false },
                    labels: {
                        show: false,
                    },
                },
                yaxis: {
                    labels: { show: false },
                },
                tooltip: {
                    enabled: hasData,
                    x: {
                        show: true,
                        formatter: function(value: unknown) {
                            return dayjs(Number(value)).toDate().toLocaleString()
                        },
                    },
                    y: {
                        show: true,
                        formatter: function(value: unknown) {
                            return `${value}%`
                        },
                    },
                },
                colors: hasData
                    ? BAR_COLOR_PALETTE
                    : [COLORS.SECONDARY],
            }}
            height="220"
            width="100%"
        />
    )
})
