import { DatePicker, TimePicker } from '@material-ui/pickers'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'

import { TivioNotification } from '../../../../store/Notification'
import { InputWrapper } from '../shared/InputWrapper'


const PickerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 1rem;
    grid-column: 2;
    width: fit-content;
`

interface Props {
    notification: TivioNotification
    label: string
    disabled: boolean
}

export const ScheduleDatePicker = observer(({ notification, label, disabled }: Props) => {
    return (
        <InputWrapper label={label}>
            <PickerWrapper>
                <DatePicker
                    id="date"
                    name="date"
                    minDate={dayjs()}
                    value={notification.scheduleTime}
                    onChange={(e) => {
                        notification.scheduleTime = e?.toDate()
                    }}
                    disabled={disabled}
                />
                <TimePicker
                    id="time"
                    name="time"
                    value={notification.scheduleTime}
                    onChange={(e) => {
                        notification.scheduleTime = e?.toDate()
                    }}
                    disabled={disabled}
                    minutesStep={1}
                />
            </PickerWrapper>
        </InputWrapper>
    )
})
