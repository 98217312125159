import { CircularProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'


const StyledLoader = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
`


export const CenteredLoader: React.FC = () => {
    return (
        <StyledLoader>
            <CircularProgress color="inherit" />
        </StyledLoader>
    )
}
