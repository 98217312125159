export class Logger {
    static enabled = true
    private static prefix = 'admin'

    private isEnabled = true

    constructor(private name: string) {
    }

    error(...args: any[]) {
        this.log('error', ...args)
    }

    info(...args: any[]) {
        this.log('info', ...args)
    }

    off() {
        this.isEnabled = false
    }

    on() {
        this.isEnabled = true
    }

    warn(...args: any[]) {
        this.log('warn', ...args)
    }

    private log(level: 'error' | 'info' | 'warn', ...args: any[]) {
        if (Logger.enabled && this.isEnabled) {
            console[level](`[${Logger.prefix}:${this.name}]`, ...args)
        }
    }
}

const createLogger = (name: string) => new Logger(name)

export default Logger

export {
    createLogger,
}
