import {
    Box,
    Button,
    CircularProgress,
    IconButton,
    Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { LangCode, VideoType } from '@tivio/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next/hooks'

import Video from '../../store/Video'
import { mapLangCodeToLanguage } from '../../utils/translate.utils'
import { Header } from '../common'
import { useAlert } from '../hooks/uiHooks/useAlert'
import { useConfirmAction } from '../hooks/uiHooks/useConfirmAction'
import { useDialog } from '../hooks/uiHooks/useDialog'

import { VideoSubtitlesDialog } from './VideoSubtitlesDialog'


interface Props {
    video: Video
    disabled?: boolean
}

export const VideoSubtitles: React.FC<Props> = ({ video, disabled = false }) => {
    const [t] = useTranslation()
    const { openDialog, isDialogOpen, closeDialog } = useDialog()
    const [dialogLanguage, setDialogLanguage] = useState<LangCode>()
    const { confirmAction } = useConfirmAction()
    const { showSuccess, showError } = useAlert()

    const handleAddSubtitlesClick = () => {
        setDialogLanguage(undefined)
        openDialog()
    }

    const handleReUploadClick = (language: LangCode) => () => {
        setDialogLanguage(language)
        openDialog()
    }

    const handleSubtitlesDelete = (language: LangCode) => () => {
        confirmAction(async () => {
            try {
                await video.deleteUnprocessedSubtitles(language)
                showSuccess(t('Subtitles removed.'))
            } catch {
                showError(t('Unknown error occurred while removing subtitles.'))
            }
        }, t('Are you sure you want to remove these subtitles?'))
    }

    const isTrailerOrCut = video.getType ? [VideoType.CUT, VideoType.TRAILER].includes(video.getType) : false

    return (
        <>
            <VideoSubtitlesDialog
                opened={isDialogOpen}
                onClose={closeDialog}
                video={video}
                onFileSelect={video.uploadSubtitles}
                initialLanguage={dialogLanguage}
            />

            <Header
                title={t('Subtitles')}
                actions={{
                    create: (!video.isTranscoding && !disabled) ? {
                        title: t('Add subtitles'),
                        onCreate: handleAddSubtitlesClick,
                    } : undefined,
                }}
            />

            {video.subtitles.length === 0 && (
                <Typography color="textSecondary">
                    {!video.isTranscoding ? t('No subtitles') : t('You cannot edit subtitles while video is being transcoded.')}
                </Typography>)
            }

            <Box mb={5}>
                {video.subtitles.map((subtitles) => (
                    <Box
                        key={subtitles.language}
                        mt={1}
                        mb={1}
                        display="flex"
                        alignItems="center"
                    >
                        <Box
                            width="200px"
                            fontWeight="bolder"
                        >
                            {mapLangCodeToLanguage(subtitles.language)}
                        </Box>

                        <Box width="300px">
                            <Typography
                                color="textSecondary"
                                variant="body2"
                            >
                                {t('Uploaded {{date}}', { date: subtitles.createdAt.toDate().toLocaleString() })}
                            </Typography>
                        </Box>

                        <Box
                            marginLeft="auto"
                            display="flex"
                            alignItems="center"
                        >
                            {!subtitles.draft && (
                                <Typography
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    {t('Subtitles are already processed and cannot be changed')}
                                </Typography>
                            )}
                            {subtitles.draft && (
                                video.isTranscoding || video.isUploaded ? (
                                    <>
                                        <Box mr={1}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {t('Subtitles are being processed')}
                                            </Typography>
                                        </Box>
                                        <CircularProgress size={18}/>
                                    </>
                                ) : (
                                    <>
                                        <Box mr={1}>
                                            <Typography
                                                color="textSecondary"
                                                variant="body2"
                                            >
                                                {t('Please upload video to process new subtitles.')}
                                            </Typography>
                                        </Box>
                                        <Box mr={1}>
                                            {
                                                !disabled && (
                                                    <Button
                                                        variant="outlined"
                                                        color="primary"
                                                        onClick={handleReUploadClick(subtitles.language)}
                                                        disabled={isTrailerOrCut}
                                                    >
                                                        {t('Reupload Subtitles')}
                                                    </Button>
                                                )
                                            }
                                        </Box>
                                        {(!isTrailerOrCut && !disabled) && (<Box>
                                            <IconButton
                                                size="small"
                                                onClick={handleSubtitlesDelete(subtitles.language)}
                                                title={t('Delete')}
                                            >
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Box>)}
                                    </>
                                )
                            )}
                        </Box>
                    </Box>
                ))}
            </Box>
        </>
    )
}
