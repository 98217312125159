import { Button } from '@material-ui/core'
import { DateRange as DateRangeIcon } from '@material-ui/icons'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { DateRange } from '../../types/date'
import { GeneralDialog } from '../common/dialog/GeneralDialog'
import { useDialog } from '../hooks/uiHooks/useDialog'
import { Column } from '../uiLayout/Column'
import { Row } from '../uiLayout/Row'

import { DateRangePicker } from './DateRangePicker'


const StyledDateRangeIcon = styled(DateRangeIcon) <{ disabled: boolean }>`
     cursor: pointer;
     color: ${props => props.disabled ? props.theme.palette.text.disabled : props.theme.palette.text.primary};
`

const DEFAULT_DATE_RANGE: DateRange = {
    startDate: dayjs().startOf('day').toISOString(),
    endDate: dayjs().endOf('day').toISOString(),
}

interface Props {
    value: DateRange
    disabled?: boolean
    onChange: (startDate: Date, endDate: Date) => void;
}

export const DateRangeInput: React.FC<Props> = (props) => {
    const { value, disabled = false, onChange } = props

    const [dialogDateRange, setDialogDateRange] = useState<DateRange>(DEFAULT_DATE_RANGE)
    const { isDialogOpen, openDialog, closeDialog } = useDialog()

    useEffect(() => {
        if (value.startDate > value.endDate) {
            setDialogDateRange(DEFAULT_DATE_RANGE)
            return
        }

        const innerDateRange: DateRange = {
            startDate: dayjs(value.startDate).startOf('day').toISOString(),
            endDate: dayjs(value.endDate).endOf('day').toISOString(),
        }
        setDialogDateRange(innerDateRange)
    }, [value.endDate, value.startDate])

    const handleDateRangeChange = (startDate: Date, endDate: Date) => {
        const innerDateRange: DateRange = {
            startDate: dayjs(startDate).startOf('day').toISOString(),
            endDate: dayjs(endDate).startOf('day').toISOString(),
        }
        setDialogDateRange(innerDateRange)
    }

    const handleAcceptDateRange = () => {
        const innerStartDate = dayjs(dialogDateRange.startDate)
        const innerEndDate = dayjs(dialogDateRange.endDate)

        onChange(innerStartDate.toDate(), innerEndDate.toDate())
        closeDialog()
    }

    return (
        <>
            <Row
                rowProps={{ alignItems: 'center', alignContent: 'center', justifyContent: 'flex-end' }}
                itemProp={{
                    index: 0,
                    props: {
                        xs: 2,
                    },
                }}
                onClick={openDialog}
            >
                <StyledDateRangeIcon disabled={disabled} />
            </Row >
            <GeneralDialog
                title="Select date range"
                open={isDialogOpen}
                onClose={closeDialog}
                dialogWidth="lg"
                contentWidth="auto"
            >
                <Column>
                    <DateRangePicker
                        value={dialogDateRange}
                        onChange={handleDateRangeChange}
                    />
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        onClick={handleAcceptDateRange}
                    >
                        OK
                    </Button>
                </Column>
            </GeneralDialog>
        </>
    )
}
