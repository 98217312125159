import type { EpgProgram } from '../firebase/firestore/epg'
import type { TimelineProgramType } from '../utils/epg.utils'
import type { ProgramInterface } from './Program'


class EmptyProgram implements ProgramInterface {
    private readonly firestoreData: EpgProgram
    private readonly type: TimelineProgramType = 'empty-program'

    constructor(
        firestoreData: EpgProgram,
    ) {
        this.firestoreData = firestoreData
    }

    get getFrom() {
        return this.firestoreData.timeline.from
    }

    get getTo() {
        return this.firestoreData.timeline.to
    }

    get getTimeline() {
        return this.firestoreData.timeline
    }

    get getPlaytime() {
        return this.firestoreData.playTime
    }

    get getName() {
        return this.firestoreData.name
    }

    get getId() {
        return this.firestoreData.id
    }

    get getContentId() {
        return this.firestoreData.id
    }

    get getType() {
        return this.type
    }
}

export default EmptyProgram
