import { TextField } from '@material-ui/core'
import { TagMetadataField as TagMetadataFieldInterface, TagMetadataFieldType } from '@tivio/types'
import React from 'react'

import Logger from '../../logger'

import { AvailableSeasonsTagMetadataField } from './AvailableSeasonsTagMetadataField'


const logger = new Logger('TagMetadataField')

interface Props {
    metadataField: TagMetadataFieldInterface
    onChange: (value: TagMetadataFieldInterface) => void
    onChangeAvailableSeasons: (value: string) => void
}

export const TagMetadataField = ({ metadataField, onChange, onChangeAvailableSeasons }: Props) => {

    switch (metadataField.type) {
        case TagMetadataFieldType.STRING: {
            const handleChange = (event: any) => {
                onChange({ ...metadataField, value: event.target.value })
            }
            return <TextField
                variant="outlined"
                size="small"
                label={metadataField.key}
                fullWidth
                value={metadataField.value}
                onChange={handleChange}
            />
        }
        case TagMetadataFieldType.AVAILABLE_SEASONS: {
            return <AvailableSeasonsTagMetadataField
                metadataField={metadataField}
                onChange={onChangeAvailableSeasons}
            />
        }
        default: {
            logger.error(`Unsupported TagMetadataField ${metadataField.type}`)
            return <p>Unknown input</p>
        }
    }
}
