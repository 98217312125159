import { DesktopMac, Smartphone } from '@material-ui/icons'
import { capitalizeText } from '@tivio/common'
import React, { memo } from 'react'
import { Handle, Position } from 'reactflow'
import styled from 'styled-components'

import { NodeContainer } from './NodeSharedComponents'

import type { Node } from 'reactflow'


const StyledLabel = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
`

const StyledSublabel = styled.span`
    font-size: 10px;
    color: #ffffff;
    opacity: 0.6;
`

export default memo(({ data, selected }: Node) => {
    return (
        <NodeContainer selected={data.selected || selected}>
            <Handle
                type="target"
                position={Position.Left}
            />
            <StyledLabel>
                {RenderIcon(data.technology)}
                <span>{capitalizeText(data.technology)}</span>
            </StyledLabel>
            <StyledSublabel>{`${data.deviceOs} | ${data.totalCount} / ${data.conversionRate}`}</StyledSublabel>
            <Handle
                type="source"
                position={Position.Right}
            />
        </NodeContainer>
    )
})


const RenderIcon = (deviceType: string) => {
    switch (deviceType) {
        case 'WEB':
            return <DesktopMac
                style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                }}
            />
        case 'MOBILE':
            return <Smartphone
                style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '5px',
                }}
            />
        default:
            return <></>
    }
}
