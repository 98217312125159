import { InputBase, makeStyles } from '@material-ui/core'
import { LangCode } from '@tivio/types'
import { observer } from 'mobx-react'
import React, { ChangeEventHandler, useCallback, useEffect, useRef } from 'react'

import { ArticleBlock } from '../../../store/ArticleBlock'


const useStyles = makeStyles((theme) => ({
    input: {
        border: 'none',
        fontSize: theme.typography.h5.fontSize,
        fontWeight: 600,
    },
}))


interface Props {
    language: LangCode
    block: ArticleBlock
}

export const HeadingBlock = observer(({ language, block }: Props) => {
    const classes = useStyles()
    const inputRef = useRef<HTMLInputElement>(null)
    const prevLanguageRef = useRef<LangCode>(language)
    const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        block.updateTranslationField('textContent', language, event.target.value)
    }, [language, block])

    const value = block.textContent[language]

    useEffect(() => {
        if (prevLanguageRef.current !== language && inputRef.current) {
            inputRef.current.focus()
            const value = inputRef.current.value
            inputRef.current.setSelectionRange(value.length, value.length)
        }
        prevLanguageRef.current = language
    }, [inputRef, language])

    return <InputBase
        className={classes.input}
        value={value}
        placeholder={value ? undefined : block.textContent[LangCode.EN]}
        onChange={handleChange}
        margin="dense"
        inputRef={inputRef}
        fullWidth
    />
})
