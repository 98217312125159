import { Typography } from '@material-ui/core'
import { alphabeticComparator } from '@tivio/common'
import { AssetPresetPlacement } from '@tivio/firebase'
import { Scale } from '@tivio/types'
import React from 'react'
import { useTranslation } from 'react-i18next/hooks'

import { AssetResizeResult } from '../../firebase/firestore/types'
import { AssetPreset } from '../../store/AssetPreset'
import { ResizeImageType } from '../../utils/image.utils'
import { TileSize } from '../AppTile'
import { AssetPresetFilter, useAllAssetPresets } from '../settings/presets/hooks'
import { Column } from '../uiLayout/Column'

import { AssetRow } from './assetRow'


interface AssetUploaderItem {
    keepOriginal?: boolean
    quality?: number
    getAsset: (presetName: string, scale?: Scale) => string | null
    getTempFilePath: (preset: AssetPreset, ext: ResizeImageType) => string
    getDocumentPath: () => string
    updateItem?: (uris: AssetResizeResult[], name: string) => Promise<void> | void
}

interface Props {
    item: AssetUploaderItem
    size?: TileSize
    filter?: AssetPresetFilter
    disabled?: boolean
    mapPresetName?: (presetName: string) => string
}

export const AssetUploader = ({ item, size, filter = { placement: AssetPresetPlacement.VIDEO }, disabled = false, mapPresetName }: Props) => {
    const [ t ] = useTranslation()
    const assetPresets = useAllAssetPresets(filter)

    return (
        <Column spacing={2}>
            {assetPresets.length === 0
                ? <Typography color="textSecondary">{t('No asset presets found')}</Typography>
                : assetPresets
                    .sort(alphabeticComparator)
                    .map(
                        preset => (
                            <AssetRow
                                key={preset.id}
                                preset={preset}
                                item={item}
                                size={size}
                                disabled={disabled}
                                mapPresetName={mapPresetName}
                            />
                        ),
                    )
            }
        </Column>
    )
}

export type {
    AssetUploaderItem,
}
