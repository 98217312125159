import styled from 'styled-components'

import { clampRectangle } from '../../../utils/math.utils'


interface Props {
    width: number
    height: number
}

const Preview = styled.div.attrs<Props>(props => {
    return {
        style: clampRectangle(props.width, props.height, 93, 70),
    }
})<Props>`
    background: #0190c8;
    border-radius: 2px;
`

const Background = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 190px;
    height: 107px;
    background: #202945;
    opacity: 0.5;
    border-radius: 8px;
`

export const Silhouette = (props: Props) => {
    const { width, height } = props

    return (
        <Background>
            <Preview width={width} height={height} />
        </Background>
    )
}
