import { createLogger } from '@tivio/common'
import { useEffect, useState } from 'react'

import User from '../../../store/User'

import { useOrganization } from './useOrganization'


const logger = createLogger('useUser')

export const useUser = (userId: string) => {
    const { organization } = useOrganization()
    const [loading, setLoading] = useState(true)
    const [notFound, setNotFound] = useState(false)
    const [user, setUser] = useState<User | null>(null)

    useEffect(() => {
        // Organization not loaded yet OR users are already being loaded OR user doesn't exist
        if (!organization) {
            return
        }

        setLoading(true)

        organization.getUserById(userId)
            .then(user => {
                if (!user) {
                    setNotFound(true)
                    return
                }

                setUser(user)
            })
            .catch(reason => {
                logger.error(`'Failed to load user with id ${userId}`, reason)
            })
            .finally(() => setLoading(false))
    }, [userId, organization])

    return {
        user,
        loading,
        notFound,
    }
}
