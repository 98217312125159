import type { DropResult } from 'react-beautiful-dnd'


const onDragEndUtil = <T>(result: DropResult, array: T[]): T[] | void => {
    const { destination, source } = result

    if (!destination) {
        return
    }

    if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
    ) {
        return
    }

    const newSections = Array.from(array)

    newSections.splice(source.index, 1)
    newSections.splice(destination.index, 0, array[source.index])

    return newSections
}

export {
    onDragEndUtil,
}
