import {
    Divider,
    makeStyles,
    Paper,
    Popover,
    ThemeProvider,
} from '@material-ui/core'
import { ArticleBlockType } from '@tivio/types'
import React from 'react'

import { lightTheme } from '../../../static/theme'
import { AddIconButton } from '../components/AddIconButton'

import { BlockTypeButtonGroup } from './BlockTypeButtonGroup'

import type { Theme } from '@material-ui/core'


const useStyles = makeStyles<Theme, { isVisible: boolean }>((theme) => ({
    container: {
        opacity: ({ isVisible }) => (isVisible ? 1 : 0),
        height: theme.spacing(5),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        transition: 'opacity 0.2s ease-in-out',
        '&:hover': {
            opacity: 1,
        },
    },
    paper: {
        '> button': {
            padding: theme.spacing(5),
        },
    },
    divider: {
        backgroundColor: '#2196f3',
        flex: 1,
    },
    addMarkerButton: {
        backgroundColor: theme.palette.primary.main,
        transform: 'translateX(-50%) translateY(-50%)',
        position: 'absolute',
        left: '50%',
        top: '50%',
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))

interface Props {
    isDisabled?: boolean
    onAddNewBlock: (blockType: ArticleBlockType) => void
}

export const AddBlockDivider = ({ isDisabled, onAddNewBlock }: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const isOpen = Boolean(anchorEl)
    const classes = useStyles({ isVisible: isOpen })

    const handleAddMarkerClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleButtonGroupButtonClick = (blockType: ArticleBlockType) => {
        setAnchorEl(null)
        onAddNewBlock(blockType)
    }

    return (
        <div className={classes.container}>
            {!isDisabled && (
                <>
                    <Divider className={classes.divider} />
                    <AddIconButton onClick={handleAddMarkerClick} />
                    <ThemeProvider theme={lightTheme}>
                        <Popover
                            open={isOpen}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: -10,
                                horizontal: 'center',
                            }}
                            onClose={() => setAnchorEl(null)}
                        >
                            <Paper className={classes.paper}>
                                <BlockTypeButtonGroup onAddNewBlock={handleButtonGroupButtonClick} />
                            </Paper>
                        </Popover>
                    </ThemeProvider>
                </>
            )}
        </div>
    )
}
